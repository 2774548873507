import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { EnrolmentStatus } from '@hoot/models/api/enums/Enrolments';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { GenericPaginatedResponse } from '@hoot/models/api/pagination';
import { StudentGrade } from '@hoot/models/api/student';

export enum DistrictRepresentativeStudentsUnderLocationQueryKey {
  Name = 'name',
  Number = 'number',
  Grade = 'grade',
}
export interface DistrictRepresentativeStudentsUnderSchoolQuery {
  searchText?: string;
  enrolmentId?: string;
  status?: EnrolmentStatus[];
  grade?: StudentGrade[];

  page: number;
  pageSize: number;
  sortBy: DistrictRepresentativeStudentsUnderLocationQueryKey;
  orderBy: OrderBy;
}

interface StudentEnrolment {
  id: string;
  status: EnrolmentStatus;
  friendlyId: string;
}

export interface DistrictRepresentativeStudentUnderSchoolResponse {
  id: string;
  name: string;
  grade: string | null;
  hfsStudentNumber: string | null;
  enrolments: StudentEnrolment[];
}

async function searchDistrictRepresentativeStudentsUnderSchool(
  districtRepresentativeId: string,
  schoolId: string,
  searchParams: DistrictRepresentativeStudentsUnderSchoolQuery,
): Promise<GenericPaginatedResponse<DistrictRepresentativeStudentUnderSchoolResponse>> {
  const { data } = await axios.get<GenericPaginatedResponse<DistrictRepresentativeStudentUnderSchoolResponse>>(
    `${config.apiUrl}/public/district-representative/${districtRepresentativeId}/school/${schoolId}/students/search`,
    { params: searchParams },
  );

  return data;
}

export default function useSearchDistrictRepresentativeStudentsUnderSchool(
  districtRepresentativeId: string,
  schoolId: string,
  searchParams: DistrictRepresentativeStudentsUnderSchoolQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<DistrictRepresentativeStudentUnderSchoolResponse>, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.SearchDistrictRepresenatativeStudentsUnderLocation, districtRepresentativeId, schoolId, searchParams],
    () => searchDistrictRepresentativeStudentsUnderSchool(districtRepresentativeId, schoolId, searchParams),
    options,
  );
}
