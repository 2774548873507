import { Box, Button, Link, Stack } from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { ErrorInfo } from 'react';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { clearLocalStorage } from '@hoot/utils/localStorage-whiteList';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

const ErrorMessage = (props: { error?: Error; errorInfo?: ErrorInfo }) => {
  const { error } = props;

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleLogout = () => {
    clearLocalStorage();
    window.location.href = '/';
  };

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          '@media (max-height: 700px)': {
            height: '800px',
            justifyContent: 'flex-start',
          },
          width: '100%',
          backgroundImage: 'url("/images/flf-background.webp")',
          backgroundSize: 'cover',
          gap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            background: hootTokens.palette.white,
            padding: '24px',
            borderRadius: '8px',
            width: '100%',
            '@media (max-width: 500px)': {
              maxWidth: '350px',
            },
            '@media (max-width: 400px)': {
              maxWidth: '300px',
            },
            maxWidth: '400px',
            marginTop: '24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <img src="/images/logo-v2.svg" alt="logo" height="62px" width="216px" style={{ marginBottom: 16 }} />
            <Stack textAlign={'center'} justifyContent={'center'} sx={{ marginTop: '8px' }}>
              <HootTypography
                isPII={false}
                variant="bodylarge"
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 700,
                  fontSize: '18px',
                }}
              >
                Whoops! An Unexpected Error Occurred
              </HootTypography>
              <HootTypography
                isPII={false}
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 700,
                  fontSize: '18px',
                  marginTop: '32px',
                }}
              >
                Error Details:
              </HootTypography>
              <HootTypography
                isPII={false}
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  fontSize: '16px',
                  marginTop: '8px',
                  color: hootTokens.palette.error[80],
                }}
              >
                {error?.message}
              </HootTypography>
              <HootTypography
                isPII={false}
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  fontSize: '16px',
                  marginTop: '32px',
                }}
              >
                Please try clearing your session data or refreshing the page using the buttons below.
              </HootTypography>
              <Stack direction={'row'} gap={3} justifyContent={'center'} sx={{ marginTop: '32px' }}>
                <Button
                  variant="outlined"
                  onClick={handleLogout}
                  sx={{
                    width: '150px',
                    height: '62px',
                    color: 'black',
                    borderColor: 'black',
                    textTransform: 'none',
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    '&:hover': {
                      borderColor: 'black',
                    },
                  }}
                >
                  Clear Session
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleRefresh}
                  sx={{
                    width: '150px',
                    height: '62px',
                    color: 'white',
                    backgroundColor: 'black',
                    borderColor: 'black',
                    textTransform: 'none',
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    '&:hover': {
                      borderColor: 'black',
                      backgroundColor: '#333333',
                    },
                  }}
                >
                  Refresh Page
                </Button>
              </Stack>
              <HootTypography
                isPII={false}
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  fontSize: '14px',
                  marginTop: '32px',
                }}
              >
                If this issue persists, please contact{' '}
                <Link sx={{ color: 'black' }} href="mailto:help@hootreading.com">
                  help@hootreading.com
                </Link>{' '}
                with the error details above.
              </HootTypography>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState((prevState) => ({ ...prevState, error, errorInfo }));
  }

  render() {
    if (this.state.hasError) {
      Sentry.captureException(this.state.error);
      return <ErrorMessage error={this.state.error} errorInfo={this.state.errorInfo} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
