import { SvgIcon, SvgIconProps } from '@mui/material';

const ClipboardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 9.25C7.58579 9.25 7.25 9.58579 7.25 10C7.25 10.4142 7.58579 10.75 8 10.75V9.25ZM16 10.75C16.4142 10.75 16.75 10.4142 16.75 10C16.75 9.58579 16.4142 9.25 16 9.25V10.75ZM8 13.25C7.58579 13.25 7.25 13.5858 7.25 14C7.25 14.4142 7.58579 14.75 8 14.75V13.25ZM16 14.75C16.4142 14.75 16.75 14.4142 16.75 14C16.75 13.5858 16.4142 13.25 16 13.25V14.75ZM8 17.25C7.58579 17.25 7.25 17.5858 7.25 18C7.25 18.4142 7.58579 18.75 8 18.75V17.25ZM12 18.75C12.4142 18.75 12.75 18.4142 12.75 18C12.75 17.5858 12.4142 17.25 12 17.25V18.75ZM20.25 8V18H21.75V8H20.25ZM17 21.25H7V22.75H17V21.25ZM3.75 18V8H2.25V18H3.75ZM16 4.75H17V3.25H16V4.75ZM7 4.75H8V3.25H7V4.75ZM7 21.25C5.20507 21.25 3.75 19.7949 3.75 18H2.25C2.25 20.6234 4.37665 22.75 7 22.75V21.25ZM20.25 18C20.25 19.7949 18.7949 21.25 17 21.25V22.75C19.6234 22.75 21.75 20.6234 21.75 18H20.25ZM21.75 8C21.75 5.37665 19.6234 3.25 17 3.25V4.75C18.7949 4.75 20.25 6.20507 20.25 8H21.75ZM3.75 8C3.75 6.20507 5.20507 4.75 7 4.75V3.25C4.37665 3.25 2.25 5.37665 2.25 8H3.75ZM10 2.75H14V1.25H10V2.75ZM14 5.25H10V6.75H14V5.25ZM10 5.25C9.30964 5.25 8.75 4.69036 8.75 4H7.25C7.25 5.51878 8.48122 6.75 10 6.75V5.25ZM15.25 4C15.25 4.69036 14.6904 5.25 14 5.25V6.75C15.5188 6.75 16.75 5.51878 16.75 4H15.25ZM14 2.75C14.6904 2.75 15.25 3.30964 15.25 4H16.75C16.75 2.48122 15.5188 1.25 14 1.25V2.75ZM10 1.25C8.48122 1.25 7.25 2.48122 7.25 4H8.75C8.75 3.30964 9.30964 2.75 10 2.75V1.25ZM8 10.75H16V9.25H8V10.75ZM8 14.75H16V13.25H8V14.75ZM8 18.75H12V17.25H8V18.75Z"
        fill={props.htmlColor ?? 'currentColor'}
      />
    </SvgIcon>
  );
};

export default ClipboardIcon;
