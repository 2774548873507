import { SvgIcon, SvgIconProps } from '@mui/material';

const CloseFilledSquareIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4C0 1.79086 1.79086 0 4 0ZM13.3588 6.64129C13.6517 6.93419 13.6517 7.40906 13.3588 7.70195L11.0607 9.99999L13.3588 12.298C13.6517 12.5909 13.6517 13.0658 13.3588 13.3587C13.0659 13.6516 12.591 13.6516 12.2981 13.3587L10.0001 11.0607L7.70191 13.3588C7.40901 13.6517 6.93414 13.6517 6.64125 13.3588C6.34835 13.0659 6.34835 12.591 6.64125 12.2981L8.9394 9.99999L6.64125 7.70185C6.34836 7.40895 6.34836 6.93408 6.64125 6.64118C6.93415 6.34829 7.40902 6.34829 7.70191 6.64118L10.0001 8.93933L12.2981 6.64129C12.591 6.3484 13.0659 6.3484 13.3588 6.64129Z"
      fill={props.htmlColor ?? '#BF3A1C'}
    />
  </SvgIcon>
);

export default CloseFilledSquareIcon;
