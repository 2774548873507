import { useEffect, useState } from 'react';
import { EventType } from '../events/eventType';
import { useSocket } from '../ui/context/SocketContext';

interface UseSocketSubscriptionProps<T> {
  isEnabled?: boolean;
  onEventReceived?: (response: T) => void;
}

export function useSocketSubscription<T>(eventType: EventType, options?: UseSocketSubscriptionProps<T>) {
  const isEnabled = options?.isEnabled !== undefined ? options.isEnabled : true;
  const { socket } = useSocket();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [data, setData] = useState<T>();

  useEffect(() => {
    function handleResponse(response: T) {
      setData(response);

      if (!isLoaded) {
        setIsLoaded(true);
      }
      console.log(`Received message of type: ${eventType}`);
      options?.onEventReceived?.(response);
    }

    if (isEnabled) {
      socket.on(eventType, handleResponse);
    }

    return () => {
      socket.off(eventType, handleResponse);
    };
  }, [socket, eventType, isLoaded, options, isEnabled]);

  return {
    data,
    isLoaded,
  };
}
