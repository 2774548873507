import { Box, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { zIndexes } from '@hoot/constants/zIndices';
import StudentLessonReaderPage from '@hoot/ui/pages/v2/student/lesson/library/reader/StudentLessonReaderPage';
import PMATextUnit from '@hoot/ui/pages/v2/student/progress-monitoring-assessment/PMATextUnit';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

//Todo Implement Magic Finger - https://app.shortcut.com/hoot-reading/story/11795/pma-in-lesson-magic-finger

export enum PMAUnitsIdentifierEnum {
  U_1_10 = '1.10',
  U_1_20 = '1.20',
  U_1_30 = '1.30',
  U_1_40 = '1.40',
  U_1_50 = '1.50',
  U_2_10 = '2.10',
  U_2_20 = '2.20',
  U_2_30 = '2.30',
  U_2_40 = '2.40',
  U_2_50 = '2.50',
  U_2_60 = '2.60',
  U_3_10 = '3.10',
  U_3_20 = '3.20',
  U_3_30 = '3.30',
  U_3_40 = '3.40',
  U_3_50 = '3.50',
  U_3_60 = '3.60',
  U_4_10 = '4.10',
  U_4_20 = '4.20',
  U_4_30 = '4.30',
  U_4_40 = '4.40',
  U_END = 'END',
}

const PMAContainer = (props: PropsWithChildren<any>) => {
  const { children } = props;

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        position: 'relative',
        userSelect: 'none',
        zIndex: zIndexes.bookPageOnReader,
        height: '100%',
        width: '100%',
      }}
    >
      {children}
    </Stack>
  );
};

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  isThumbnailView?: boolean;
}

const PMAImage = (imgProps: ImageProps) => {
  const { isThumbnailView, ...rest } = imgProps;
  return (
    <Box
      component="img"
      sx={{
        objectFit: 'contain',
        WebkitTouchCallout: 'none', // disabling the save/copy option menu for images when holding your finger on it
        boxShadow: hootTokens.elevation.elevation1,
        borderRadius: '10px',
        maxWidth: '100%',
        maxHeight: '100%',
        // A note about how these images render:
        // The book page (AKA the image of the book page) needs to _only_ fill as much space as it needs for its
        // width, but must fill the height of its parent container. This will ensure that, regardless of window
        // dimensions, users are _always_ seeing the same scaled images with the correct aspect ratio. This is ultra
        // important b/c we need to ensure that the magic finger coordinates always point "at the same spot" for
        // both users in a lesson (the magic finger container wraps the visible pages (one or two pages depending on
        // if we're in single-page mode or double-page mode).
        width: isThumbnailView ? '100%' : 'auto',
        // width: widthOverride ? widthOverride : 'auto', // todo j; do we need the width override like we do for BookPageV2?
        height: isThumbnailView ? '100%' : 'auto',
        backgroundColor: '#FFF',
      }}
      {...rest}
    />
  );
};

interface PMAUnitProps {
  unitIdentifier: PMAUnitsIdentifierEnum;
  isThumbnailView?: boolean;
  text?: string;
}

const PMAUnit = (props: PMAUnitProps) => {
  const { unitIdentifier, isThumbnailView, text } = props;

  switch (unitIdentifier) {
    case PMAUnitsIdentifierEnum.U_1_10:
      return (
        <PMAContainer>
          <PMAImage src={'/images/progress-monitoring-assessment/segmentation.svg'} isThumbnailView={isThumbnailView} />
        </PMAContainer>
      );
    case PMAUnitsIdentifierEnum.U_1_20:
      return (
        <PMAContainer>
          <PMAImage src={'/images/progress-monitoring-assessment/blending.svg'} isThumbnailView={isThumbnailView} />
        </PMAContainer>
      );
    case PMAUnitsIdentifierEnum.U_1_30:
      return (
        <PMAContainer>
          <PMAImage src={'/images/progress-monitoring-assessment/deletion.svg'} isThumbnailView={isThumbnailView} />
        </PMAContainer>
      );
    case PMAUnitsIdentifierEnum.U_1_40:
      return (
        <PMAContainer>
          <PMAImage src={'/images/progress-monitoring-assessment/substitution.svg'} isThumbnailView={isThumbnailView} />
        </PMAContainer>
      );
    case PMAUnitsIdentifierEnum.U_1_50:
      return (
        <PMAContainer>
          <PMATextUnit text={text ?? ''} isThumbnailView={!!isThumbnailView} isLetterUnit={true} />
        </PMAContainer>
      );
    case PMAUnitsIdentifierEnum.U_2_10:
    case PMAUnitsIdentifierEnum.U_2_20:
    case PMAUnitsIdentifierEnum.U_2_30:
    case PMAUnitsIdentifierEnum.U_2_40:
    case PMAUnitsIdentifierEnum.U_2_50:
    case PMAUnitsIdentifierEnum.U_2_60:
    case PMAUnitsIdentifierEnum.U_3_10:
    case PMAUnitsIdentifierEnum.U_3_20:
    case PMAUnitsIdentifierEnum.U_3_30:
    case PMAUnitsIdentifierEnum.U_3_40:
    case PMAUnitsIdentifierEnum.U_3_50:
    case PMAUnitsIdentifierEnum.U_3_60:
      return (
        <PMAContainer>
          <PMATextUnit text={text ?? ''} isThumbnailView={!!isThumbnailView} />
        </PMAContainer>
      );
    case PMAUnitsIdentifierEnum.U_4_10:
    case PMAUnitsIdentifierEnum.U_4_20:
    case PMAUnitsIdentifierEnum.U_4_30:
    case PMAUnitsIdentifierEnum.U_4_40:
      return isThumbnailView ? null : <StudentLessonReaderPage />;
    case PMAUnitsIdentifierEnum.U_END:
      return (
        <PMAContainer>
          <PMAImage src={'/images/progress-monitoring-assessment/way-to-go.png'} isThumbnailView={isThumbnailView} />
        </PMAContainer>
      );
  }
};

interface Props {
  isThumbnailView?: boolean;
}

const StudentPMAPage = (props: Props) => {
  const { isThumbnailView } = props;

  return (
    <Stack sx={{ height: '100%', padding: 2 }}>
      <PMAUnit unitIdentifier={PMAUnitsIdentifierEnum.U_1_10} isThumbnailView={isThumbnailView} text="A" />
    </Stack>
  );
};

export default StudentPMAPage;
