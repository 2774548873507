import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { SortableQuery } from '@hoot/models/api/order-by';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/models/api/pagination';
import { QueryKey } from '../queryKeys';

export interface HootTextReadingResponse {
  assessmentId: string;
  startDate: number | undefined;
  bookId: string | undefined;
  bookTitle: string | undefined;
  bookLevel: string | undefined;
  fluency: string | undefined;
  vocabulary: string | undefined;
  textSpecificKnowledge: string | undefined;
  verbalReasoning: string | undefined;
  textStructure: string | undefined;
  sentenceAnalysis: string | undefined;
}

export enum StudentTextReadingsQuerySortKeyEnum {
  StartDate = 'StartDate',
}

export type GetStudentTextReadingsQuery = PaginatableQuery & SortableQuery<StudentTextReadingsQuerySortKeyEnum>;

export function useGetTextReadings(
  studentProfileId: string,
  query: GetStudentTextReadingsQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<HootTextReadingResponse>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetStudentTextReadings, studentProfileId, query],
    async () => {
      return (
        await axios.get<GenericPaginatedResponse<HootTextReadingResponse>>(
          `${config.apiUrl}/public/hoot-assessment/text-reading/student/${studentProfileId}`,
          {
            params: query,
          },
        )
      ).data;
    },
    options,
  );
}
