import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { BookResponse } from '@hoot/events/interfaces/book-response';
import { LessonWhiteboardTemplatesState } from '@hoot/events/interfaces/lesson-whiteboard-templates.state';
import { LibraryState } from '@hoot/events/interfaces/library-state';
import { ToggleFavouriteBookResponseMessage } from '@hoot/events/messages/toggle-favourite-book-response.message';
import { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import { LessonWhiteboardTemplateResponse } from '@hoot/hooks/api/whiteboard/useGetLessonWhiteboardTemplates';
import { ShelfType } from '@hoot/models/api/enums/shelf-type-enum';
import { BookSearch } from '../../events/interfaces/book-search';
import { LibrarySearchResultsMessage } from '../../events/messages/library-search-results.message';
import { AppDispatch, RootState } from '../store';

export const DEFAULT_PAGE = 1;
export const DEFAULT_LIBRARY_GRID_VIEW_PAGE_SIZE = 96;
export const DEFAULT_LIBRARY_LIST_VIEW_PAGE_SIZE = 10;

export enum PaginationMode {
  Replace = 'REPLACE',
  Append = 'APPEND',
}

export enum LibraryContext {
  SandboxLibrary = 'SandboxLibrary',
  StudentSpecificLibrary = 'StudentSpecificLibrary',
  InLessonLibrary = 'InLessonLibrary',
}

export enum LibraryTab {
  AllBooks = 'ALL_BOOKS',
  Favorites = 'FAVORITES',
  LessonPlan = 'LESSON_PLAN',
  Whiteboard = 'WHITEBOARD',
}

export const libraryTabToShelfTypeDictionary: Record<LibraryTab, ShelfType | undefined> = {
  [LibraryTab.AllBooks]: ShelfType.AllBooks,
  [LibraryTab.Favorites]: ShelfType.Favorites,
  [LibraryTab.LessonPlan]: ShelfType.LessonPlan,
  [LibraryTab.Whiteboard]: undefined,
};
export const shelfTypeToLibraryTabDictionary: Record<ShelfType, LibraryTab> = {
  [ShelfType.AllBooks]: LibraryTab.AllBooks,
  [ShelfType.Favorites]: LibraryTab.Favorites,
  [ShelfType.LessonPlan]: LibraryTab.LessonPlan,
};

export interface LibrariesState {
  sandboxLibrary: Library;
  studentSpecificLibrary: StudentSpecificLibrary;
  inLessonLibrary: InLessonLibrary;
}

export const libraryKeyLookup: Record<LibraryContext, keyof LibrariesState> = {
  [LibraryContext.SandboxLibrary]: 'sandboxLibrary',
  [LibraryContext.StudentSpecificLibrary]: 'studentSpecificLibrary',
  [LibraryContext.InLessonLibrary]: 'inLessonLibrary',
};

interface Library {
  // Query is initially null until the first library search request is sent in a lesson by the teacher.
  query: QueryLibraryV2 | null;
  results: LibraryResults;
  paginationMode: PaginationMode;
  previewBook: BookResponse | null;
}

interface StudentSpecificLibrary extends Library {
  studentProfileId: string | null;
}

interface InLessonLibrary extends StudentSpecificLibrary {
  lessonId: string | null;
  // Note about `selectedTab`:
  // We only keep track of this property while in-lesson since there is a tab for whiteboards which does not exist
  // out-of-lesson. The whiteboards tab can not be used to query the library (it's not a "ShelfType").
  // When out-of-lesson, we can determine the selected tab by referencing the library query's "shelfType".
  selectedTab: LibraryTab;
  isLoading: boolean;
  // This may seem like an odd place for this, but we show whiteboard templates within the context of the library.
  lessonWhiteboardTemplates: {
    isLoading: boolean;
    query?: string;
    results: LessonWhiteboardTemplateResponse[];
    previewWhiteboardTemplate: LessonWhiteboardTemplateResponse | null;
  };
}

const initialLibraryState: Library = {
  query: null,
  results: {
    page: 1,
    pageSize: 0,
    books: [],
    total: 0,
  },
  paginationMode: PaginationMode.Append,
  previewBook: null,
};

const initialLibrariesState: LibrariesState = {
  sandboxLibrary: {
    ...initialLibraryState,
  },
  studentSpecificLibrary: {
    ...initialLibraryState,
    studentProfileId: null,
  },
  inLessonLibrary: {
    ...initialLibraryState,
    studentProfileId: null,
    lessonId: null,
    selectedTab: LibraryTab.AllBooks,
    isLoading: false,
    lessonWhiteboardTemplates: {
      isLoading: false,
      query: undefined,
      results: [],
      previewWhiteboardTemplate: null,
    },
  },
};

interface LibraryResults {
  page: number;
  pageSize: number;
  books: BookSearch[];
  total: number;
}

export const librarySlice = createSlice({
  name: 'library',
  initialState: {
    ...initialLibrariesState,
  },
  reducers: {
    resetAllLibraries: () => {
      return { ...initialLibrariesState };
    },
    resetLibrary: (state, action: PayloadAction<LibraryContext>) => {
      if (action.payload === LibraryContext.SandboxLibrary) {
        state.sandboxLibrary = { ...initialLibrariesState.sandboxLibrary };
      } else if (action.payload === LibraryContext.StudentSpecificLibrary) {
        state.studentSpecificLibrary = { ...initialLibrariesState.studentSpecificLibrary };
      } else if (action.payload === LibraryContext.InLessonLibrary) {
        state.inLessonLibrary = { ...initialLibrariesState.inLessonLibrary };
      }
    },
    setLibraryQuery: (
      state,
      action: PayloadAction<{
        libraryContext: LibraryContext;
        query: QueryLibraryV2 | null;
      }>,
    ) => {
      const { libraryContext, query } = action.payload;
      const key = libraryKeyLookup[libraryContext];
      state[key].query = query ? { ...query } : null;
    },
    setLibraryResults: (state, action: PayloadAction<{ libraryContext: LibraryContext; results: LibraryResults }>) => {
      const { libraryContext, results } = action.payload;
      const key = libraryKeyLookup[libraryContext];
      state[key].results = { ...results };
    },
    clearLibraryResults: (state, action: PayloadAction<LibraryContext>) => {
      if (action.payload === LibraryContext.SandboxLibrary) {
        state.sandboxLibrary.results = { ...initialLibrariesState.sandboxLibrary.results };
      } else if (action.payload === LibraryContext.StudentSpecificLibrary) {
        state.studentSpecificLibrary.results = { ...initialLibrariesState.studentSpecificLibrary.results };
      } else if (action.payload === LibraryContext.InLessonLibrary) {
        state.inLessonLibrary.results = { ...initialLibrariesState.inLessonLibrary.results };
      }
    },
    setPaginationMode: (
      state,
      action: PayloadAction<{
        libraryContext: LibraryContext;
        paginationMode: PaginationMode;
      }>,
    ) => {
      const { libraryContext, paginationMode } = action.payload;
      const key = libraryKeyLookup[libraryContext];
      state[key].paginationMode = paginationMode;
    },
    setPreviewBook: (
      state,
      action: PayloadAction<{
        libraryContext: LibraryContext;
        previewBook: BookResponse | null;
      }>,
    ) => {
      const { libraryContext, previewBook } = action.payload;
      const key = libraryKeyLookup[libraryContext];
      state[key].previewBook = previewBook;
    },
    handleSyncLessonLibraryState: (state, action: PayloadAction<LibraryState>) => {
      const { lessonId, studentProfileId, selectedTab, query, libraryResults, paginationMode, previewBook } = action.payload;

      state.inLessonLibrary = {
        lessonId,
        selectedTab,
        query,
        results: libraryResults,
        studentProfileId,
        paginationMode,
        previewBook,
        isLoading: false,
        lessonWhiteboardTemplates: state.inLessonLibrary.lessonWhiteboardTemplates,
      };
    },
    setLessonLibraryLoading: (state, action: PayloadAction<boolean>) => {
      state.inLessonLibrary.isLoading = action.payload;
    },
    setLessonLibraryTab: (state, action: PayloadAction<LibraryTab>) => {
      state.inLessonLibrary.selectedTab = action.payload;
    },
    setWhiteboardTemplateLoading: (state, action: PayloadAction<boolean>) => {
      state.inLessonLibrary.lessonWhiteboardTemplates = {
        ...state.inLessonLibrary.lessonWhiteboardTemplates,
        isLoading: action.payload,
      };
    },
    setInLessonWhiteboardTemplateQuery: (state, action: PayloadAction<{ query: string | undefined }>) => {
      const { query } = action.payload;
      state.inLessonLibrary.lessonWhiteboardTemplates = {
        ...state.inLessonLibrary.lessonWhiteboardTemplates,
        query,
      };
    },
    handleSyncLessonWhiteboardTemplatesState: (state, action: PayloadAction<LessonWhiteboardTemplatesState>) => {
      const { query, results, paginationMode, previewWhiteboardTemplate } = action.payload;

      state.inLessonLibrary.lessonWhiteboardTemplates = {
        isLoading: false,
        query,
        results,
        previewWhiteboardTemplate,
      };
      state.inLessonLibrary.paginationMode = paginationMode;
    },
    clearInLessonWhiteboardTemplateResults: (state) => {
      state.inLessonLibrary.lessonWhiteboardTemplates = {
        ...state.inLessonLibrary.lessonWhiteboardTemplates,
        results: [],
      };
    },
    setPreviewBookWhiteboardTemplate: (state, action: PayloadAction<LessonWhiteboardTemplateResponse | null>) => {
      state.inLessonLibrary.lessonWhiteboardTemplates = {
        ...state.inLessonLibrary.lessonWhiteboardTemplates,
        previewWhiteboardTemplate: action.payload,
      };
    },
    handleTabChange: (state, action: PayloadAction<LibraryTab>) => {
      state.inLessonLibrary = {
        ...state.inLessonLibrary,
        selectedTab: action.payload,
      };
    },
  },
});

export const {
  resetAllLibraries,
  resetLibrary,
  setLibraryQuery,
  setLibraryResults,
  clearLibraryResults,
  setPaginationMode,
  setPreviewBook,
  handleSyncLessonLibraryState,
  setLessonLibraryLoading,
  setLessonLibraryTab,
  setWhiteboardTemplateLoading,
  setInLessonWhiteboardTemplateQuery,
  handleSyncLessonWhiteboardTemplatesState,
  clearInLessonWhiteboardTemplateResults,
  setPreviewBookWhiteboardTemplate,
  handleTabChange,
} = librarySlice.actions;

export const handleToggledFavouriteLibraryBookResponse = createAsyncThunk<
  void,
  { libraryContext: LibraryContext; response: ToggleFavouriteBookResponseMessage },
  { dispatch: AppDispatch; state: RootState }
>('library/handleToggledFav', async ({ libraryContext, response }, thunkApi) => {
  const state = thunkApi.getState();

  const key = libraryKeyLookup[libraryContext];
  const library = state.libraries[key];

  // If the preview book is the book that was just favourited, then update the preview book.
  if (library.previewBook?.id === response.bookId) {
    const updatedBook: BookResponse = {
      ...library.previewBook,
      shelf: response.updatedShelf,
    };
    thunkApi.dispatch(setPreviewBook({ libraryContext, previewBook: updatedBook }));
  }
});

export const handleLibrarySearchResultsMessage = createAsyncThunk<
  void,
  { libraryContext: LibraryContext; librarySearchResultsMessage: LibrarySearchResultsMessage },
  { dispatch: AppDispatch; state: RootState }
>('library/librarySearchResults', async ({ libraryContext, librarySearchResultsMessage }, thunkApi) => {
  const state = thunkApi.getState();

  const key = libraryKeyLookup[libraryContext];
  const library = state.libraries[key];

  let updatedBooks: BookSearch[];

  if (librarySearchResultsMessage.page === 1 || library.paginationMode === PaginationMode.Replace) {
    updatedBooks = librarySearchResultsMessage.books;
  } else {
    // If we're fetching any page after page 1, then just append the data.
    // Note: We're converting the full list of books into a map and then back to a list again as a safe-guard in case
    // we end up appending book(s) with the same ID(s) for _some_ reason.
    const booksDictionary = new Map([...library.results.books, ...librarySearchResultsMessage.books].map((x) => [x.id, x]));
    updatedBooks = Array.from(booksDictionary.values());
  }
  thunkApi.dispatch(
    setLibraryResults({
      libraryContext,
      results: {
        ...librarySearchResultsMessage,
        books: updatedBooks,
      },
    }),
  );
});

export const useLibraries = () => {
  return useSelector((state: RootState) => state.libraries);
};

export default librarySlice.reducer;
