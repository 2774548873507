import Grid from '@mui/material/Grid2';
import { useParams } from 'react-router-dom';
import useDistrictRepGetEnrolmentSnippet from '@hoot/hooks/api/district-rep/useDistrictRepresentativeGetEnrolment';
import Page from '@hoot/ui/components/v2/core/Page';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import EnrolmentDetailsCard from '@hoot/ui/pages/v2/district-rep/enrolments/enrolment/details/HFSEnrolmentDetailsCard';
import EnrolmentProductCard from '@hoot/ui/pages/v2/district-rep/enrolments/enrolment/details/HFSEnrolmentProductCard';
import EnrolmentStatusCard from '@hoot/ui/pages/v2/district-rep/enrolments/enrolment/details/HFSEnrolmentStatusCard';

const DistrictRepEnrolmentDetailsPage = () => {
  const { enrolmentId } = useParams();

  const { data, isFetching } = useDistrictRepGetEnrolmentSnippet(enrolmentId!, {
    enabled: !!enrolmentId,
  });

  return (
    <Page pageTitle="District Representative Enrolment Details | Hoot Reading" noMaxWidth>
      <PageLayout RootBoxProps={{ p: 0 }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 4 }}>
            <EnrolmentStatusCard enrolment={data} isLoading={isFetching} />
          </Grid>
          <Grid container size={{ sm: 12, md: 8 }} spacing={2}>
            <Grid size={{ xs: 12 }}>
              <EnrolmentDetailsCard enrolment={data} isLoading={isFetching} />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <EnrolmentProductCard enrolment={data} isLoading={isFetching} />
            </Grid>
          </Grid>
        </Grid>
      </PageLayout>
    </Page>
  );
};

export default DistrictRepEnrolmentDetailsPage;
