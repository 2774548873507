import { ScheduledLessonLanguage } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { BeamerPost } from '@hoot/hooks/api/app-notification/useGetAppNotifications';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import Chevron from '@hoot/ui/components/v2/icons/Chevron';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { getColors } from '@hoot/ui/theme/v2/util';
import { formatDateFromIso } from '@hoot/utils/date';
import { toProperCase } from '@hoot/utils/text-display';

const BEAMER_POST_LIMIT = 5;

export enum BeamerCategory {
  Announcement = 'ANNOUNCEMENT',
  Improvement = 'IMPROVEMENT',
  NewFeature = 'NEWFEATURE',
  Notification = 'NOTIFICATION',
}

const formatText = (notification: string): { firstLine: string; secondLine: string } => {
  const isTextLong = notification.length > 200;
  const text = isTextLong ? notification.substring(0, 200) : notification;
  const firstExclamationIndex = text.indexOf('!');
  let textBeforeExclamation = text.substring(0, firstExclamationIndex + 1);
  let textAfterExclamation = text.substring(firstExclamationIndex + 1);

  if (textAfterExclamation) {
    const lastSpaceIndex = textAfterExclamation.lastIndexOf(' ');
    textAfterExclamation = `${textAfterExclamation.substring(0, lastSpaceIndex)}${isTextLong ? '...' : ''}`;
  } else {
    const lastSpaceIndex = textBeforeExclamation.lastIndexOf(' ');
    textBeforeExclamation = `${textBeforeExclamation.substring(0, lastSpaceIndex)}${isTextLong ? '...' : ''}`;
  }

  return { firstLine: textBeforeExclamation, secondLine: textAfterExclamation };
};

const AnnouncementsCarousel = (props: { announcements: BeamerPost[] | undefined }) => {
  const { announcements } = props;

  const [postIndex, setPostIndex] = useState<number>(0);

  const handleOnClick = (postUrl: string) => {
    window.open(postUrl, '_blank');
  };

  const showPrevious = () => {
    setPostIndex((idx) => (idx === 0 ? BEAMER_POST_LIMIT - 1 : idx - 1));
  };

  const showNext = () => {
    setPostIndex((idx) => (idx === BEAMER_POST_LIMIT - 1 ? 0 : idx + 1));
  };

  const getTagColour = (category: string) => {
    const formattedCategory = category.replace(/\s/g, '').toUpperCase();
    switch (formattedCategory) {
      case BeamerCategory.Announcement:
        return getColors('warning').palette[140];
      case BeamerCategory.Improvement:
        return getColors('primary').palette[140];
      case BeamerCategory.NewFeature:
        return getColors('success').palette[140];
      case BeamerCategory.Notification:
        return getColors('secondary').palette[140];
      default:
        return hootTokens.palette.neutral['140'];
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', overflow: 'hidden', flexWrap: 'nowrap' }}>
        {announcements?.slice(0, BEAMER_POST_LIMIT).map((x) => {
          const englishText = x.translations.find((t) => t.language === ScheduledLessonLanguage.English);
          if (!englishText) return null;
          const { firstLine, secondLine } = formatText(englishText.content);

          return (
            <Stack
              key={x.id}
              minHeight={200}
              width="100%"
              minWidth="100%"
              gap={2}
              sx={{ padding: '1px', transform: `translate(-${postIndex * 100}%)`, transition: '350ms ease 0s' }}
            >
              <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                <Stack direction="row" gap={1} justifyContent="flex-start" alignItems="center">
                  <Icon name="hoot_wheel" />
                  <HootTypography isPII={false} variant="titlemedium">
                    {englishText.title}
                  </HootTypography>
                </Stack>
                <HootTypography
                  isPII={false}
                  variant="labelsmall"
                  sx={{
                    padding: 1,
                    backgroundColor: getTagColour(x.category),
                    borderRadius: '8px',
                  }}
                >
                  {toProperCase(x.category)}
                </HootTypography>
              </Stack>
              <HootTypography isPII={false} variant="bodysmall">
                {formatDateFromIso(x.date, 'LLL dd, yyyy')}
              </HootTypography>
              <span>
                <HootTypography isPII={false} variant="bodylarge">
                  {firstLine}
                </HootTypography>
                <HootTypography isPII={false} variant="bodylarge">
                  {secondLine}
                </HootTypography>
              </span>
              <Button variant="outlined" size="small" sx={{ width: 'fit-content' }} onClick={() => handleOnClick(englishText.postUrl)}>
                Read More
              </Button>
            </Stack>
          );
        })}
      </Box>
      <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
        <IconButton onClick={showPrevious}>
          <Chevron />
        </IconButton>
        <HootTypography isPII={false} variant="bodysmall" m={1}>
          {postIndex + 1} / {BEAMER_POST_LIMIT}
        </HootTypography>
        <IconButton onClick={showNext}>
          <Chevron sx={{ rotate: '180deg' }} />
        </IconButton>
      </Stack>
    </>
  );
};

export default AnnouncementsCarousel;
