import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';
import { Unit } from './useGetAssessmentUnit';

async function getEntryPointUnit(studentProfileId: string): Promise<Unit> {
  const { data } = await axios.get<Unit>(`${config.apiUrl}/public/hoot-assessment/student/${studentProfileId}/unit/entry-point`);
  return data;
}

export function useGetEntryPointUnit(studentProfileId: string, options?: Omit<UseQueryOptions<Unit>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetFirstAssessmentUnit, studentProfileId], () => getEntryPointUnit(studentProfileId), options);
}
