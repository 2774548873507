import { SvgIcon, SvgIconProps } from '@mui/material';

const AccountFilledIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0.000244141H4C1.79086 0.000244141 0 1.79111 0 4.00024V16.0002C0 17.8645 1.27532 19.4309 3.00111 19.8745C3.32039 19.9566 3.6551 20.0002 4 20.0002H16C16.3449 20.0002 16.6796 19.9566 16.9989 19.8745C18.7247 19.4309 20 17.8645 20 16.0002V4.00024C20 1.79111 18.2091 0.000244141 16 0.000244141ZM10 11.0002C12.4617 11.0002 14.5783 12.6064 15.5115 14.9074C15.9491 15.9862 15.0067 17.0002 13.8425 17.0002H6.15752C4.99332 17.0002 4.05092 15.9862 4.48849 14.9074C5.42174 12.6064 7.53834 11.0002 10 11.0002ZM10 3.00024C11.6569 3.00024 13 4.34339 13 6.00024C13 7.6571 11.6569 9.00024 10 9.00024C8.34315 9.00024 7 7.6571 7 6.00024C7 4.34339 8.34315 3.00024 10 3.00024Z"
      fill={props.htmlColor ?? 'currentColor'}
    />
  </SvgIcon>
);

export default AccountFilledIcon;
