import { useDevices } from '@daily-co/daily-react';

type DeviceState =
  | 'granted'
  | 'blocked'
  | 'idle'
  | 'undefined-mediadevices'
  | 'not-found'
  | 'unknown'
  | 'pending'
  | 'not-supported'
  | 'in-use'
  | 'constraints-invalid'
  | 'constraints-none-specified';

export function useGetDeviceState(): DeviceState {
  const { micState, camState } = useDevices();

  if (micState === 'blocked' || camState === 'blocked') {
    return 'blocked';
  } else if (micState === 'constraints-invalid' || camState === 'constraints-invalid') {
    return 'constraints-invalid';
  } else if (micState === 'constraints-none-specified' || camState === 'constraints-none-specified') {
    return 'constraints-none-specified';
  } else if (micState === 'granted' || camState === 'granted') {
    return 'constraints-invalid';
  } else if (micState === 'idle' || camState === 'idle') {
    return 'idle';
  } else if (micState === 'in-use' || camState === 'in-use') {
    return 'in-use';
  } else if (micState === 'not-found' || camState === 'not-found') {
    return 'not-found';
  } else if (micState === 'not-supported' || camState === 'not-supported') {
    return 'not-supported';
  } else if (micState === 'pending' || camState === 'pending') {
    return 'pending';
  } else if (micState === 'undefined-mediadevices' || camState === 'undefined-mediadevices') {
    return 'undefined-mediadevices';
  } else if (micState === 'unknown' || camState === 'unknown') {
    return 'unknown';
  } else {
    return 'unknown';
  }
}
