import { ScheduledLessonStatus, lessonStatusesLookup } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { InputAdornment, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ReaderLesson } from '@hoot/models/api/lessons';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import Tag, { TagColor } from '@hoot/ui/components/v2/core/Tag';
import TextField from '@hoot/ui/components/v2/core/TextField';
import { useGetReadyV2Dialog } from '@hoot/ui/context/GetReadyV2DialogProvider';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface UpcomingLessonProps {
  lesson: ReaderLesson;
}

const UpcomingLesson = (props: UpcomingLessonProps) => {
  const { lesson } = props;

  const { startGetReady } = useGetReadyV2Dialog();

  const lessonTimeLabel = useMemo(() => {
    const startDateTime = DateTime.fromMillis(lesson.startTime);
    const endDateTime = startDateTime.plus({ minute: lesson.durationInMinutes });
    return `${startDateTime.toFormat('h:mma')} - ${endDateTime.toFormat('h:mma')} (${endDateTime.toFormat('ZZZZ')})`;
  }, [lesson.startTime, lesson.durationInMinutes]);

  const statusLabel = useMemo(() => {
    switch (lesson.status) {
      case ScheduledLessonStatus.Open:
      case ScheduledLessonStatus.OpenWaitingForStudent:
      case ScheduledLessonStatus.OpenWaitingForTeacher:
        return lessonStatusesLookup[ScheduledLessonStatus.Open];
      default:
        return lessonStatusesLookup[lesson.status];
    }
  }, [lesson.status]);

  const isInProgress = statusLabel === lessonStatusesLookup[ScheduledLessonStatus.InProgress];
  const isOpen = statusLabel === lessonStatusesLookup[ScheduledLessonStatus.Open];
  const isCancelled = statusLabel === lessonStatusesLookup[ScheduledLessonStatus.Cancelled];

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const onStartGetReady = () => {
    startGetReady({ lessonId: lesson.lessonId, lessonNumber: lesson.lessonNumber });
  };

  const statusColour: ColorPaletteOption = isInProgress ? 'success.190' : isOpen ? 'secondary.190' : isCancelled ? 'error.190' : 'neutral.190';
  const iconColour: ColorPaletteOption = isInProgress ? 'success.100' : isOpen ? 'secondary.100' : 'neutral.0';

  return (
    <Card
      title={
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Icon name="lesson_icon" color={iconColour} />
            <span>{lessonTimeLabel}</span>
          </Stack>
          <Tag
            label={statusLabel}
            color={isInProgress ? TagColor.Success : isOpen ? TagColor.Info : isCancelled ? TagColor.Error : TagColor.Neutral}
          />
        </Stack>
      }
      sx={{ background: hootTokens.palette.neutral['195'] }}
      elevation={2}
    >
      <Stack gap={2} mt={2}>
        <TextField
          variant="filled"
          readOnly
          label="Student"
          filledColour={statusColour}
          value={`${lesson.studentNumber} (${lesson.studentName})`}
          InputProps={{
            endAdornment: (
              <Tooltip title="View Student">
                <Link to={routesDictionary.myStudents.details.profile.url(lesson.studentId)}>
                  <InputAdornment position="end">
                    <Icon name="account_filled" />
                  </InputAdornment>
                </Link>
              </Tooltip>
            ),
          }}
        />
        <TextField variant="filled" readOnly label="Type" filledColour={statusColour} value={capitalCase(lesson.type)} />

        {/* FYI - We don't show this button on mobile devices (phones). */}
        {(isOpen || isInProgress) && isDesktop && (
          <Button color={'success.60'} variant="contained" onClick={onStartGetReady}>
            Join Lesson
          </Button>
        )}
      </Stack>
    </Card>
  );
};

export default UpcomingLesson;
