import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface AssessmentBookResponse {
  id: string;
  name: string;
  wordCount: number | null;
}

async function getAssessmentBook(assessmentId: string, studentProfileId: string): Promise<AssessmentBookResponse> {
  const { data } = await axios.get<AssessmentBookResponse>(
    `${config.apiUrl}/public/hoot-assessment/${assessmentId}/student/${studentProfileId}/text-reading`,
  );
  return data;
}

export default function useGetAssessmentBook(
  assessmentId: string,
  studentProfileId: string,
  options?: Omit<UseQueryOptions<AssessmentBookResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetAssessmentBook, assessmentId, studentProfileId], () => getAssessmentBook(assessmentId, studentProfileId), options);
}
