import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { QueryKey } from '../queryKeys';
import { DistrictRepresentativeStatus } from './useGetDistrictRepresentativeDetails';

export interface DistrictRepresentativeTeamMembersQuery {
  name?: string;
  enrolmentId?: string;
  status?: DistrictRepresentativeStatus[];
  page: number;
  pageSize: number;
  sortBy: string;
  orderBy: OrderBy;
}

export interface Enrolment {
  id: string;
  friendlyId: string;
}

export interface DistrictRepresentativeTeamMember {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  enrolments: Enrolment[];
  status: string;
}

export interface DistrictRepresentativeTeamMembersResponse {
  teamMembers: DistrictRepresentativeTeamMember[];
  count: number;
  page: number;
  pageSize: number;
}

async function searchDistrictRepresentativeTeamMembers(
  districtRepresentativeId: string,
  searchParams: DistrictRepresentativeTeamMembersQuery,
): Promise<DistrictRepresentativeTeamMembersResponse> {
  const { data } = await axios.get<DistrictRepresentativeTeamMembersResponse>(
    `${config.apiUrl}/public/district-representative/${districtRepresentativeId}/team-members`,
    { params: searchParams },
  );

  return data;
}

export default function useSearchDistrictRepresentativeTeamMembers(
  districtRepresentativeId: string,
  searchParams: DistrictRepresentativeTeamMembersQuery,
  options?: Omit<UseQueryOptions<DistrictRepresentativeTeamMembersResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.SearchDistrictRepresentativeTeamMembers, districtRepresentativeId, searchParams],
    () => searchDistrictRepresentativeTeamMembers(districtRepresentativeId, searchParams),
    options,
  );
}
