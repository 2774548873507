import { Box } from '@mui/material';
import { Stack } from '@mui/system';
import { ControllerFieldState } from 'react-hook-form';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const enum Feeling {
  DoNotLikeIt = 'DO_NOT_LIKE_IT',
  ItsOk = 'ITS_OK',
  LikeIt = 'LIKE_IT',
  LoveIt = 'LOVE_IT',
}

interface IFeedbackFieldProps {
  label: string;
  emoticon: string;
  isSelected?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

interface IATRProps {
  name: string;
  label?: string;
  onChange: (val: Feeling) => void;
  value?: string;
  fieldState: ControllerFieldState;
}

const Feedback = (props: IFeedbackFieldProps) => {
  const { label, emoticon, isSelected, onClick } = props;

  return (
    <Box
      onClick={onClick}
      sx={{
        outline: isSelected ? `solid 4px ${hootTokens.palette.secondary[100]}` : undefined,
        boxSizing: 'border-box',
        display: 'flex',
        height: '112px',
        width: '100%',
        cursor: 'pointer',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        backgroundColor: isSelected ? `${hootTokens.palette.secondary[180]}` : hootTokens.surface[2],
      }}
    >
      <Box sx={{ fontSize: '50px', lineHeight: '58px' }}>{emoticon}</Box>
      <HootTypography isPII={false} variant="bodysmall">
        {label}
      </HootTypography>
    </Box>
  );
};

const ATR = (props: IATRProps) => {
  const { label, value, fieldState, onChange } = props;

  const handleClick = (val: Feeling) => () => {
    onChange(val);
  };

  return (
    <Stack
      sx={{
        margin: '40px 0px',
        outline: `${fieldState.error ? `2px solid ${hootTokens.palette.error[80]}` : 'unset'}`,
        padding: `${fieldState.error ? '8px' : 'unset'}`,
        borderRadius: `${fieldState.error ? '8px' : 'unset'}`,
        '&:first-of-type': {
          margin: '0px',
        },
        '&:last-of-type': {
          marginTop: '0px',
        },
      }}
    >
      <HootTypography isPII={false} variant="titlesmall" sx={{ color: fieldState.error ? hootTokens.palette.error[80] : 'inherit' }}>
        {label}
      </HootTypography>
      <Box sx={{ marginTop: '8px', display: 'flex', gap: 2 }}>
        <Feedback onClick={handleClick(Feeling.DoNotLikeIt)} isSelected={value === Feeling.DoNotLikeIt} label="I don’t like it!" emoticon="😞" />
        <Feedback onClick={handleClick(Feeling.ItsOk)} isSelected={value === Feeling.ItsOk} label="It’s okay." emoticon="🙁" />
        <Feedback onClick={handleClick(Feeling.LikeIt)} isSelected={value === Feeling.LikeIt} label="I like it." emoticon="🙂" />
        <Feedback onClick={handleClick(Feeling.LoveIt)} isSelected={value === Feeling.LoveIt} label="I love it!" emoticon="😀" />
      </Box>
      {fieldState.error ? (
        <HootTypography isPII={false} variant="bodysmall" color={hootTokens.palette.error[80]} mt={1}>
          *A selection is required
        </HootTypography>
      ) : null}
    </Stack>
  );
};

export default ATR;
