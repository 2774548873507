import { SvgIcon, SvgIconProps } from '@mui/material';

const MicOnIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C14.2091 2 16 3.79086 16 6V12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12V6C8 3.79086 9.79086 2 12 2ZM18 10.25C17.5858 10.25 17.25 10.5858 17.25 11V12C17.25 14.8995 14.8995 17.25 12 17.25C9.1005 17.25 6.75 14.8995 6.75 12V11C6.75 10.5858 6.41421 10.25 6 10.25C5.58579 10.25 5.25 10.5858 5.25 11V12C5.25 15.4744 7.87504 18.3357 11.25 18.7088V20.5H9C8.58579 20.5 8.25 20.8358 8.25 21.25C8.25 21.6642 8.58579 22 9 22H15C15.4142 22 15.75 21.6642 15.75 21.25C15.75 20.8358 15.4142 20.5 15 20.5H12.75V18.7088C16.125 18.3357 18.75 15.4744 18.75 12V11C18.75 10.5858 18.4142 10.25 18 10.25Z"
        fill={props.htmlColor ?? 'currentColor'}
      />
    </SvgIcon>
  );
};

export default MicOnIcon;
