import { createContext, useContext } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import useGetStudentProfileSnippet, { StudentProfileSnippetResponse } from '@hoot/hooks/api/user/useGetStudentProfileSnippet';
import { routesDictionary } from '@hoot/routes/routesDictionary';

interface StudentDetailsContextProps {
  studentProfileSnippet: StudentProfileSnippetResponse;
}

const StudentDetailsContext = createContext<StudentDetailsContextProps>(undefined!);

const StudentDetailsContextProvider = () => {
  const { studentProfileId } = useParams();
  const navigate = useNavigate();

  const { data: studentProfileSnippet } = useGetStudentProfileSnippet(studentProfileId!, {
    retry: false,
    onError: (err) => {
      console.error(err);
      // If we don't have permission to view this student, then redirect out of this page.
      if (err.response?.status === 403) {
        navigate(routesDictionary.myStudents.url, { replace: true });
      }
    },
  });

  return (
    <StudentDetailsContext.Provider
      value={{
        // We're kinda lying here. `studentProfileSnippet` is actually null until its loaded, but we won't render any
        // children (tab contents) until then, so this is actually safe.
        studentProfileSnippet: studentProfileSnippet!,
      }}
    >
      {studentProfileSnippet ? <Outlet /> : null}
    </StudentDetailsContext.Provider>
  );
};

export const useStudentDetailsContext = () => {
  const context = useContext(StudentDetailsContext);

  if (context === undefined) {
    throw new Error('useStudentDetailsContext must be used within a StudentDetailsContextProvider');
  }
  return context;
};

export default StudentDetailsContextProvider;
