import { EventType } from '@hoot/events/eventType';
import { ScheduledLesson } from '@hoot/events/interfaces/scheduled-lesson';
import { LeaveLessonMessage } from '@hoot/events/messages/leave-lesson.message';
import { LessonBookPageChangeRequestMessage } from '@hoot/events/messages/lesson-book-page-change-request.message';
import { PreviewLessonLibraryBookRequestMessage } from '@hoot/events/messages/preview-lesson-library-book-request-message';
import { PreviewLessonWhiteboardTemplateRequestMessage } from '@hoot/events/messages/preview-lesson-whiteboard-template-request.message';
import { RefreshLessonRequestMessage } from '@hoot/events/messages/refresh-lesson-request.message';
import { ToggleFavouriteBookRequestMessage } from '@hoot/events/messages/toggle-favourite-book-request.message';
import { useStudentMediaState } from '@hoot/hooks/daily/useDailyMediaState';
import { useEmit } from '@hoot/hooks/useEmit';
import { WhiteboardTemplateTypeEnum } from '@hoot/models/api/enums/whiteboard-template-type-enum';
import { UserProfileType } from '@hoot/models/api/user';
import { useProfileState } from '@hoot/redux/reducers/profileSlice';
import { getNextPageId, getPreviousPageId, useReaders } from '@hoot/redux/reducers/readingSlice';

interface UseStudentLessonControllerProps {
  inLesson: ScheduledLesson;
}

const useStudentLessonController = (props: UseStudentLessonControllerProps) => {
  const { inLesson } = props;

  const { profile } = useProfileState();

  const { isLocked: studentLocked } = useStudentMediaState();

  const readerState = useReaders().inLessonReader;

  const refreshLessonRequestStateEmitter = useEmit<RefreshLessonRequestMessage>(EventType.RefreshLessonRequest, undefined, { enabled: false });
  const setPreviewBookEmitter = useEmit<PreviewLessonLibraryBookRequestMessage>(EventType.PreviewLessonLibraryBookRequest, undefined, {
    enabled: false,
  });
  const bookPageChangeRequestEmitter = useEmit<LessonBookPageChangeRequestMessage>(EventType.LessonBookPageChangeRequest, undefined, {
    enabled: false,
  });
  const toggleFavouriteEmitter = useEmit<ToggleFavouriteBookRequestMessage | undefined>(EventType.ToggleFavouriteBookRequest, undefined, {
    enabled: false,
  });
  const previewWhiteboardTemplateEmitter = useEmit<PreviewLessonWhiteboardTemplateRequestMessage>(
    EventType.PreviewLessonWhiteboardTemplateRequest,
    undefined,
    {
      enabled: false,
    },
  );
  const leaveLessonEmitter = useEmit<LeaveLessonMessage | undefined>(EventType.LeaveLesson, undefined, { enabled: false });

  const refreshLessonState = () => {
    refreshLessonRequestStateEmitter.queueMessage({
      lessonId: inLesson.lessonId,
    });
  };

  const selectBook = (bookId: string) => {
    if (studentLocked) {
      return;
    }
    setPreviewBookEmitter.queueMessage({
      bookId,
      lessonId: inLesson.lessonId,
    });
  };

  const previousPage = () => {
    if (studentLocked) {
      return;
    }
    const { book, isDoublePage, pageIndex } = readerState;
    if (!book) {
      throw new Error('Can not go to previous page. No book has been loaded.');
    }
    const newPageId = getPreviousPageId(book, isDoublePage, pageIndex);
    onGoToPageWithId(newPageId);
  };

  const nextPage = () => {
    if (studentLocked) {
      return;
    }
    const { book, isDoublePage, pageIndex } = readerState;
    if (!book) {
      throw new Error('Can not go to previous page. No book has been loaded.');
    }
    const newPageId = getNextPageId(book, isDoublePage, pageIndex);
    onGoToPageWithId(newPageId);
  };

  const onGoToPageWithId = (pageId: string) => {
    bookPageChangeRequestEmitter.queueMessage({
      lessonId: inLesson.lessonId,
      pageId,
    });
  };

  const toggleFavouritedBook = (bookId: string) => {
    if (!studentLocked) {
      const toggleBookAsFavouriteMessage: ToggleFavouriteBookRequestMessage = {
        bookId: bookId,
        shelf: 'student',
        profileId: profile!.id,
        profileType: UserProfileType.Student,
      };
      toggleFavouriteEmitter.queueMessage(toggleBookAsFavouriteMessage);
    }
  };

  const selectWhiteboardTemplate = (whiteboardTemplateType: WhiteboardTemplateTypeEnum) => {
    if (studentLocked) {
      return;
    }
    previewWhiteboardTemplateEmitter.queueMessage({
      lessonId: inLesson.lessonId,
      whiteboardTemplateType,
    });
  };

  const leaveLesson = () => {
    if (inLesson) {
      leaveLessonEmitter.emit({
        lessonId: inLesson.lessonId,
        profileId: profile!.id,
        profileName: profile!.name,
        forcedDisconnect: false,
      });
    }
  };

  return {
    refreshLessonState,
    selectBook,
    previousPage,
    nextPage,
    toggleFavouritedBook,
    selectWhiteboardTemplate,
    leaveLesson,
  };
};

export default useStudentLessonController;
