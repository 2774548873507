import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { SectionField } from '@hoot/hooks/api/assessment/useGetAssessmentUnit';
import { HootAssessmentFieldType, StepTestAnswer } from '@hoot/models/api/enums/hoot-reading-assessment';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { useHootAssessmentContext } from '@hoot/ui/pages/v2/teacher/hoot-reading-assessment/HootAssessmentContext';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { Step } from './Step';

export function stepTestValidation(val: string[], formField: SectionField) {
  if (formField.unfurl) {
    const { segments } = formField.unfurl;
    for (const segment of segments) {
      const segmentSlice = val.slice(segment.start, segment.end);
      const isInvalid = segmentSlice.some((answer) => answer === StepTestAnswer.NoAnswer);

      if (isInvalid) {
        return false;
      } else if (segmentSlice.filter((answer) => answer === StepTestAnswer.Correct).length < formField.unfurl.whenCorrect) {
        break;
      }
    }
  }
  return true;
}

interface IStepTestProps {
  sectionField: SectionField;
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
}

export const calculateStepTestQuestionsToShow = (sectionField: SectionField, formFieldValue: string[] | undefined) => {
  if (sectionField.type !== HootAssessmentFieldType.StepTest) {
    return undefined;
  }
  const allowedValues = sectionField.allowedValues as string[];

  const value: StepTestAnswer[] = formFieldValue ?? new Array(allowedValues.length).fill(StepTestAnswer.NoAnswer);
  const valuesCopy = Array.isArray(value) ? [...value] : new Array(allowedValues.length).fill(StepTestAnswer.NoAnswer);

  const unfurl = sectionField.unfurl!;

  const segments = unfurl.segments.map((segment) => valuesCopy.splice(0, segment.amount));
  let segmentIndex = 0;
  for (const segment of segments) {
    const isSegmentCorrect = segment.filter((answer) => answer === StepTestAnswer.Correct).length >= unfurl.whenCorrect;
    if (!isSegmentCorrect) {
      break;
    }
    segmentIndex += 1;
  }
  return unfurl.segments.slice(0, segmentIndex + 1).reduce((a, b) => a + b.amount, 0);
};

const StepTest = (props: IStepTestProps) => {
  const allowedValues = props.sectionField.allowedValues as string[];

  const { sectionField, field, fieldState } = props;
  const { focusedField, setFocusedField, unit } = useHootAssessmentContext();

  const value: StepTestAnswer[] = field.value ?? new Array(allowedValues.length).fill(StepTestAnswer.NoAnswer);

  const selectedIndex = focusedField?.currentStepperIndex;
  const showAmount = calculateStepTestQuestionsToShow(sectionField, field.value)!;

  // When we've "unfurled" more questions, default all the newly shown questions to have no answer.
  useEffect(() => {
    const updatedValue = value.map((val, idx) => (idx < showAmount ? val : StepTestAnswer.NoAnswer));
    field.onChange(updatedValue);
    // eslint-disable-next-line
  }, [showAmount]);

  const onSelectedIndexChanged = (newIndex: number) => {
    setFocusedField({
      sectionField,
      currentStepperIndex: newIndex,
    });
  };

  // Split the Questions into 2 chunks for the UI
  const allowedValuesMax = Math.floor(allowedValues.length / 2);
  const [firstChunk, secondChunk] = [allowedValues.slice(0, allowedValuesMax), allowedValues.slice(allowedValuesMax, allowedValues.length)];

  return (
    <Stack gap={2}>
      <Stack direction="row" sx={{ justifyContent: 'space-evenly' }}>
        {firstChunk.map((val, idx) => (
          <Step
            key={val}
            currentIndex={idx}
            selectedIndex={selectedIndex}
            onSelectedIndexChanged={onSelectedIndexChanged}
            currentValue={val}
            currentAnswer={value[idx]}
            answers={value}
            stepLength={showAmount}
            unit={unit}
          />
        ))}
      </Stack>
      {showAmount > allowedValuesMax && (
        <Stack direction="row" sx={{ justifyContent: 'space-evenly' }}>
          {secondChunk.map((val, idx) => (
            <Step
              key={val}
              currentIndex={idx + firstChunk.length}
              selectedIndex={selectedIndex}
              onSelectedIndexChanged={onSelectedIndexChanged}
              currentValue={val}
              currentAnswer={value[idx + firstChunk.length]}
              answers={value}
              stepLength={showAmount}
              unit={unit}
            />
          ))}
        </Stack>
      )}
      {fieldState.error && (
        <HootTypography isPII={false} color={hootTokens.palette.error[80]} textAlign="center" variant="bodymedium">
          This section must be completed
        </HootTypography>
      )}
    </Stack>
  );
};

export default StepTest;
