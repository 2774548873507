import { createTheme } from '@mui/material/styles';
import components from '@hoot/ui/theme/v2/components';
import palette from '@hoot/ui/theme/v2/palette';
import typography from '@hoot/ui/theme/v2/typography';
import { hootTokens } from './tokens';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    tablet: false;
    laptop: false;
    desktop: false;
  }
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    mobile: 700,
  },
};

export const hootThemeV2 = createTheme({
  typography,
  components,
  palette,
  breakpoints,
});

export const hootThemeV2Public = createTheme(
  {
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: hootTokens.palette.error[80],
          },
        },
      },

      MuiCssBaseline: {
        styleOverrides: {
          a: {
            color: hootTokens.palette.black,
            '&:visited': {
              color: hootTokens.palette.black,
            },
          },
          body: {
            backgroundColor: hootTokens.surface[1],
          },
        },
      },
    },
  },
  hootThemeV2,
);

export default hootThemeV2;
