import { Stack } from '@mui/system';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';

interface LeavePMAProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  confirmLeave: () => void;
}

const LeavePMADialog = (props: LeavePMAProps) => {
  const { open, setOpen, confirmLeave } = props;

  return (
    <BasicAlertDialog
      show={open}
      title={'Close Confirmation'}
      content={
        <Stack gap={3}>
          <HootTypography isPII={false}>Are you sure you want to exit the assessment?</HootTypography>
          <HootTypography isPII={false}>You will lose information submitted on the current unit.</HootTypography>
        </Stack>
      }
      primaryAction={{
        label: 'Yes',
        onClick: confirmLeave,
        props: { color: 'error.80' },
      }}
      secondaryAction={{
        label: 'No',
        onClick: () => setOpen(false),
      }}
      onDismiss={() => setOpen(false)}
    />
  );
};

export default LeavePMADialog;
