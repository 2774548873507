import { SvgIcon, SvgIconProps } from '@mui/material';

const FilterDetailedFilledIcon = (props: SvgIconProps) => {
  const { ...rest } = props;
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.75 2C10.75 1.58579 10.4142 1.25 10 1.25C9.58579 1.25 9.25 1.58579 9.25 2V4C9.25 4.41421 9.58579 4.75 10 4.75C10.4142 4.75 10.75 4.41421 10.75 4V2ZM14.75 5C14.75 4.58579 14.4142 4.25 14 4.25C13.5858 4.25 13.25 4.58579 13.25 5V7C13.25 7.41421 13.5858 7.75 14 7.75C14.4142 7.75 14.75 7.41421 14.75 7V5ZM7.21458 6.7186C7.61148 6.60009 7.83716 6.18227 7.71865 5.78537C7.60014 5.38848 7.18232 5.1628 6.78542 5.2813C5.82265 5.56877 4.97974 5.9478 4.36076 6.41753C3.75193 6.87957 3.25 7.5195 3.25 8.32619C3.25 12.1598 5.2668 15.5164 8.25 17.0838V20.0109C8.25 22.0525 10.4071 23.3637 12.228 22.4594L14.228 21.4661C15.1589 21.0038 15.75 20.0568 15.75 19.0176V17.0838C18.7332 15.5164 20.75 12.1598 20.75 8.32619C20.75 7.5195 20.2481 6.87957 19.6392 6.41753C19.0203 5.9478 18.1774 5.56877 17.2146 5.2813C16.8177 5.1628 16.3999 5.38848 16.2814 5.78537C16.1628 6.18227 16.3885 6.60009 16.7854 6.7186C17.6516 6.97722 18.3087 7.29081 18.7325 7.61242C19.1664 7.94172 19.25 8.19187 19.25 8.32619C19.25 8.44407 19.1885 8.64772 18.8757 8.9232C18.564 9.19763 18.07 9.47898 17.3951 9.73039C16.0508 10.2311 14.1435 10.556 12 10.556C9.8565 10.556 7.94921 10.2311 6.60493 9.73039C5.92995 9.47898 5.43598 9.19763 5.12435 8.9232C4.81152 8.64772 4.75 8.44407 4.75 8.32619C4.75 8.19187 4.83361 7.94172 5.26754 7.61242C5.69133 7.29081 6.34842 6.97722 7.21458 6.7186ZM10 6.25C10.4142 6.25 10.75 6.58579 10.75 7V8C10.75 8.41421 10.4142 8.75 10 8.75C9.58579 8.75 9.25 8.41421 9.25 8V7C9.25 6.58579 9.58579 6.25 10 6.25Z"
        fill={props.htmlColor ?? 'black'}
      />
    </SvgIcon>
  );
};

export default FilterDetailedFilledIcon;
