import { DailyVideo, useAudioTrack, useDaily, useDailyEvent, useLocalSessionId, useVideoTrack } from '@daily-co/daily-react';
import { Box, Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { TEACHER_VIDEO_HEIGHT, TEACHER_VIDEO_WIDTH } from '@hoot/constants/daily/videoDimensions';
import { zIndexes } from '@hoot/constants/zIndices';
import { useRouteMatch } from '@hoot/hooks/useRouteMatch';
import { RootState } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { DailyMediaState, useTeacherMediaState } from '../../../../hooks/daily/useDailyMediaState';
import { useAudioVideoSettingsDialog } from '../../../context/AudioVideoSettingsDialogContext';
import HootTypography from '../core/HootTypography';
import DailyAudioVisualizer from '../video/DailyAudioVisualizer';
import { CameraOff } from './ui-components/CameraOff';
import LeaveLessonButton from './ui-components/LeaveLessonButton';
import NetworkVisualizer from './ui-components/NetworkVisualizer';
import { CameraButton, MenuButton, MicButton } from './ui-components/VideoComponents';

const LocalTeacherTile = () => {
  const dailyCallObj = useDaily();
  const localSessionId = useLocalSessionId();
  const localVideoTrack = useVideoTrack(localSessionId);
  const localAudioTrack = useAudioTrack(localSessionId);
  const isOnStudentLibrary = useRouteMatch([`${routesDictionary.myStudents.details.library.absolutePath}`]);

  const teacherName = useSelector((state: RootState) => state.activeLesson.inLesson?.teacherName);
  const book = useSelector((state: RootState) => state.readers.inLessonReader.book);

  const { toggleCamera, toggleMicrophone } = useTeacherMediaState();
  const { onShow } = useAudioVideoSettingsDialog();

  const isVideoEnabled = !localVideoTrack.isOff;
  const isAudioEnabled = !localAudioTrack.isOff;

  useDailyEvent('meeting-session-state-updated', (event) => {
    const { teacherCameraOn, teacherMicrophoneOn } = event.meetingSessionState.data as DailyMediaState;
    if (dailyCallObj) {
      dailyCallObj.setLocalVideo(teacherCameraOn ?? !localVideoTrack.isOff);
      dailyCallObj.setLocalAudio(teacherMicrophoneOn ?? !localAudioTrack.isOff);
    }
  });

  const handleOptionsClick = () => {
    onShow();
  };

  return (
    <Box
      sx={{
        padding: '16px',
        borderRadius: '8px',
        boxShadow: hootTokens.elevation.elevation5,
        backgroundColor: hootTokens.palette.white,
        zIndex: !!isOnStudentLibrary && !!book ? zIndexes.video : undefined,
      }}
    >
      <HootTypography isPII variant="bodysmall" textAlign="center">
        {teacherName}
      </HootTypography>

      <Stack gap={1} sx={{ position: 'relative' }}>
        {localSessionId && isVideoEnabled ? (
          <DailyVideo
            sessionId={localSessionId}
            type={'video'}
            automirror={true}
            width={TEACHER_VIDEO_WIDTH}
            height={TEACHER_VIDEO_HEIGHT}
            fit="cover"
            style={{
              borderRadius: '8px',
              transform: 'scale(-1, 1)',
            }}
          />
        ) : null}

        {!isVideoEnabled && <CameraOff />}

        <Stack flexDirection="row" position="absolute" top={8} right={8} columnGap={2}>
          {isVideoEnabled && <DailyAudioVisualizer audioTrack={localAudioTrack} />}
          {isVideoEnabled && <NetworkVisualizer />}
        </Stack>

        <Stack justifyContent="center" direction="row" columnGap={1} mb={1}>
          <CameraButton isOn={isVideoEnabled} onClick={toggleCamera} />

          <MicButton isOn={isAudioEnabled} onClick={toggleMicrophone} />

          <MenuButton onClick={handleOptionsClick} />
        </Stack>
        <LeaveLessonButton />
      </Stack>
    </Box>
  );
};

export default LocalTeacherTile;
