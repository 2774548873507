import Grid from '@mui/material/Grid2';
import { ParentResponse } from '@hoot/hooks/api/user/useGetStudentProfileV2';
import Card from '@hoot/ui/components/v2/core/Card';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';

interface ParentDetailsCardProps {
  parent: ParentResponse;
}

const ParentDetailsCard = (props: ParentDetailsCardProps) => {
  const { parent } = props;

  const emptyLabel = '---';

  return (
    <Card title="Parent Details">
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <ReadOnlyTextField label="Parent Name" body={parent.name} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <ReadOnlyTextField label="Parent Email" body={parent.email ?? emptyLabel} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <ReadOnlyTextField label="Phone" body={parent.phone ?? emptyLabel} />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <ReadOnlyTextField label="Timezone" body={parent?.timezone ?? emptyLabel} />
        </Grid>
        <Grid size={12}>
          <ReadOnlyTextField label="Parent Notes" body={parent.parentNotes ?? emptyLabel} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default ParentDetailsCard;
