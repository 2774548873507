import { ScheduledLessonLanguage, ScheduledLessonSubject, ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { UseGetOptions } from '@hoot/models/api/axios-options';
import { QueryKey } from '../queryKeys';

export interface TeacherLessonResponse {
  lessonId: string;
  lessonType: ScheduledLessonType;
  lessonStartTime: number;
  subject: ScheduledLessonSubject;
  language: ScheduledLessonLanguage;
  endsAt: number;
  student: {
    id: string;
    name: string;
  };
}

const useGetTeacherLessonById = (lessonId: string, options?: UseGetOptions<TeacherLessonResponse>) => {
  return useQuery(
    [QueryKey.GetTeacherLesson, lessonId],
    async () => {
      return (await axios.get<TeacherLessonResponse>(`${config.apiUrl}/public/teacher/lessons/${lessonId}`)).data;
    },
    options,
  );
};

export default useGetTeacherLessonById;
