import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface FlfScheduleRequestDto {
  requestLessonAt: number;
  studentProfileId: string;
}

export default function useFlfScheduleRequest() {
  return useMutation<void, AxiosError, FlfScheduleRequestDto>(async (request) => {
    return (await axios.post(`${config.apiUrl}/free-lesson/v2/schedule`, request)).data;
  });
}
