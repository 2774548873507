import { useState } from 'react';
import { StepTestAnswer } from '@hoot/models/api/enums/hoot-reading-assessment';
import StepTest, { IStepAnswer } from './StepTest';

const UNIT_1_5 = () => {
  const letterOptions = [
    'm',
    't',
    'a',
    's',
    'i',
    'p',
    'e',
    'r',
    'd',
    'f',
    'o',
    'l',
    'g',
    'h',
    'u',
    'c',
    'n',
    'b',
    'j',
    'k',
    'y',
    'w',
    'v',
    'qu',
    'x',
    'z',
  ];

  const defaultAnswerValues: IStepAnswer[] = letterOptions.map((a) => ({ value: a, answer: StepTestAnswer.NoAnswer }));
  const [answers, setAnswers] = useState<IStepAnswer[]>(defaultAnswerValues);

  return <StepTest prompt={'Tell me what sound this letter makes:'} allowedValues={letterOptions} answers={answers} setAnswers={setAnswers} />;
};

export default UNIT_1_5;
