import { InputAdornment, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps, styled } from '@mui/material';
import React, { useRef, useState } from 'react';
import { IconButton } from '@hoot/ui/components/v2/core/IconButton';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { getColors } from '@hoot/ui/theme/v2/util';
import { Icon } from './Icon';

export type TextFieldProps = {
  label: string;
  useSearchIcon?: boolean;
  clearable?: boolean;
  onClearButtonClicked?: () => void;
  readOnly?: boolean;
} & Omit<MuiTextFieldProps, 'label' | 'hiddenLabel'>;

interface StyledTextFieldProps extends TextFieldProps {
  filledColour?: ColorPaletteOption; // The background colour for Textfields w/ variant="filled".
  emphasizeLabel?: boolean; // Swaps font-sizes with the value and label
}

const TextField = (props: StyledTextFieldProps) => {
  const {
    useSearchIcon = false,
    clearable = false,
    onClearButtonClicked,
    type,
    readOnly = false,
    filledColour,
    emphasizeLabel = false,
    InputProps,
    InputLabelProps,
    ...textFieldProps
  } = props;
  const { startAdornment, endAdornment, sx: sxInputProps, ...restInputProps } = InputProps ?? {};
  const { sx: sxLabelProps } = InputLabelProps ?? {};

  const inputRef = useRef<any>();

  const [showPassword, setShowPassword] = useState(false);
  const [hasUncontrolledInput, setHasUncontrolledInput] = useState(false);

  const SearchIconAdornment = () => (
    <InputAdornment position="start">
      <Icon name="search" />
    </InputAdornment>
  );

  const ClearIconAdornment = () => (
    <InputAdornment position="end" sx={{ visibility: hasInput ? 'visible' : 'hidden' }}>
      <IconButton variant="standard" onMouseDown={handleMouseDownPassword} onClick={_onClearButtonClicked} tabIndex={-1}>
        <Icon name="cancel" />
      </IconButton>
    </InputAdornment>
  );

  const ShowPasswordIconAdornment = () => (
    <InputAdornment position="end">
      <IconButton variant="standard" onMouseDown={handleMouseDownPassword} onClick={onToggleShowPassword} tabIndex={-1}>
        {showPassword ? <Icon name="show" /> : <Icon name="hide" />}
      </IconButton>
    </InputAdornment>
  );

  const _onChange: TextFieldProps['onChange'] = (event) => {
    if (!props.value) {
      setHasUncontrolledInput(event.target.value.length > 0);
    }
    props.onChange?.(event);
  };

  const onToggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const _onClearButtonClicked = () => {
    // If this is an uncontrolled input, then use the input ref to clear the text.
    if (!props.value) {
      inputRef.current.value = '';
      setHasUncontrolledInput(false);
    }
    onClearButtonClicked?.();
  };

  const hasInput = !!props?.value ? props.value.toString().length > 0 : hasUncontrolledInput;

  return (
    <StyledMuiTextField
      inputRef={inputRef}
      onChange={_onChange}
      type={showPassword ? 'text' : type}
      InputProps={{
        startAdornment: props.multiline ? undefined : useSearchIcon ? <SearchIconAdornment /> : startAdornment,
        endAdornment: props.multiline ? undefined : clearable ? (
          <ClearIconAdornment />
        ) : props.type === 'password' ? (
          <ShowPasswordIconAdornment />
        ) : (
          endAdornment
        ),
        readOnly: readOnly,
        sx: { ...sxInputProps },
        ...restInputProps,
      }}
      InputLabelProps={{
        sx: { ...sxLabelProps },
      }}
      filledColour={filledColour}
      emphasizeLabel={emphasizeLabel}
      {...textFieldProps}
    />
  );
};

// Custom styled TextField using MUI's TextField. Can use this when you want to extend MUI's TextField props.
// If adding new props to 'interface StyledTextFieldProps', add them to the array below in the shouldForwardProp function.
const StyledMuiTextField = styled(MuiTextField, {
  shouldForwardProp: (propName) => !['filledColour', 'emphasizeLabel'].includes(propName as string),
})<StyledTextFieldProps>(({ sx, filledColour, emphasizeLabel, variant, error, InputProps }) => ({
  ...(filledColour &&
    variant === 'filled' && {
      '& .MuiFilledInput-root': {
        backgroundColor: `${getColors(filledColour).base}`,
        color: `${getColors(filledColour).font}`,
      },
      '& .MuiInputLabel-root': {
        fontSize: emphasizeLabel ? hootTokens.text.labellarge.fontSize : hootTokens.text.labelsmall.fontSize,
        lineHeight: emphasizeLabel ? hootTokens.text.labellarge.lineHeight : hootTokens.text.labelsmall.lineHeight,
        color: `${getColors(filledColour).font}`,
      },
      '& .MuiFilledInput-input': {
        padding: emphasizeLabel && InputProps?.startAdornment ? '31px 16px 10px 0px' : emphasizeLabel ? '31px 16px 10px 16px' : '25px 16px 10px 16px',
        fontSize: emphasizeLabel ? hootTokens.text.bodysmall.fontSize : hootTokens.text.bodylarge.fontSize,
        lineHeight: emphasizeLabel ? hootTokens.text.bodysmall.lineHeight : hootTokens.text.bodylarge.lineHeight,
      },
      '& .MuiFilledInput-root:hover': {
        backgroundColor: error ? hootTokens.palette.error['190'] : `${getColors(filledColour).hover}`,
      },
      '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor: error ? hootTokens.palette.error['190'] : `${getColors(filledColour).focus}`,
        color: error ? hootTokens.palette.error['80'] : `${getColors(filledColour).palette[60]}`,
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: error ? hootTokens.palette.error['80'] : `${getColors(filledColour).palette[60]}`,
      },
      '& .MuiFilledInput-root.Mui-disabled': {
        backgroundColor: `${getColors(filledColour).palette[190]}`,
        color: `${getColors(filledColour).palette[140]}`,
      },
      '& .MuiInputBase-readOnly:focus': {
        backgroundColor: `${getColors(filledColour).base}`,
      },
      '& .MuiInputBase-readOnly': {
        cursor: 'default',
      },
    }),
  sx,
}));

export default TextField;
