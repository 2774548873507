import { Box } from '@mui/material';
import { Stack } from '@mui/system';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { CredentialType } from '@hoot/hooks/api/auth/useLogin';
import useFreeLessonFlowCreatePasswordRequest, {
  FlfCreatePasswordRequestDto,
} from '@hoot/hooks/api/free-lesson-flow/useFreeLessonFlowCreatePassword';
import { ErrorResponseDto } from '@hoot/models/api/error-response-dto';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { googleAnalytics } from '@hoot/telemetry/google-analytics';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import TextField from '@hoot/ui/components/v2/core/TextField';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { FlfWizardStep } from '../FreeLessonRegistrationWizard';

interface Form {
  password: string;
}

const FreeLessonCreatePassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const verificationToken = searchParams.get('verificationToken') ?? '?';
  const accountFromSearchParams = searchParams.get('account') ?? '?';
  const referralCodeFromSearchParams = searchParams.get('referralCode') ?? '';

  const [isSettingPassword, setIsSettingPassword] = useState(false);

  const createPasswordMutation = useFreeLessonFlowCreatePasswordRequest();
  const { login } = useAuth();

  const { control, handleSubmit } = useForm<Form>({
    defaultValues: {
      password: '',
    },
  });

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<Form> = (data) => {
    const request: FlfCreatePasswordRequestDto = {
      password: data.password,
      verificationToken: verificationToken,
      account: accountFromSearchParams,
    };

    setIsSettingPassword(true);
    createPasswordMutation.mutate(request, {
      onSuccess: (response) => {
        login({
          credentials: response.email,
          password: data.password,
          credentialType: CredentialType.Email,
          cb: () => {
            setSearchParams({
              step: `${FlfWizardStep.CreatePassword + 1}`,
              referralCode: referralCodeFromSearchParams,
            });
          },
        });
        googleAnalytics.completedFLFStep2();
      },
      onError: (err) => {
        console.error(err);
        if (isAxiosError<ErrorResponseDto>(err)) {
          dispatch(createFlashMessage({ variant: 'error', message: err.response?.data.message ?? 'An error occurred while creating password.' }));
        } else {
          dispatch(createFlashMessage({ variant: 'error', message: 'An error occurred while creating password.' }));
        }
        setIsSettingPassword(false);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <HootTypography isPII={false} variant="titlelarge">
          Create a Password
        </HootTypography>
        <HootTypography isPII={false} mt={3}>
          Please create a strong password for your account
        </HootTypography>
        <Controller
          control={control}
          name="password"
          rules={{
            required: true,
            minLength: 8,
          }}
          render={({ field, fieldState }) => (
            <TextField
              type="password"
              onChange={field.onChange}
              error={!!fieldState.error}
              value={field.value}
              sx={(theme) => ({
                marginTop: '16px',
                maxWidth: '330px',
                [theme.breakpoints.down('md')]: {
                  maxWidth: '100%',
                },
              })}
              required
              fullWidth
              variant="outlined"
              label="Password"
              helperText={
                <Stack>
                  <HootTypography isPII={false} variant="labelsmall">
                    Password must have the following:
                    <Box component="ul" sx={{ margin: 0 }}>
                      <li>8 or more characters</li>
                    </Box>
                  </HootTypography>
                </Stack>
              }
            />
          )}
        />
        <Box>
          <Button type="submit" sx={{ marginTop: '16px' }} variant="contained" isLoading={isSettingPassword}>
            Next
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default FreeLessonCreatePassword;
