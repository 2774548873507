import { SvgIcon, SvgIconProps } from '@mui/material';

const HeartFilledIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4541 2.52435L11.2191 1.70241C13.3318 -0.567307 16.757 -0.567307 18.8696 1.70241C20.9823 3.97212 20.9823 7.65206 18.8696 9.92178L11.9842 17.3192C11.1391 18.2271 9.76905 18.2271 8.924 17.3192L2.03857 9.92178C-0.0740546 7.65206 -0.0740535 3.97212 2.03857 1.70241C4.15119 -0.567308 7.57643 -0.567306 9.68905 1.70241L10.4541 2.52435ZM15.4541 2.25012C15.0399 2.25012 14.7041 2.58591 14.7041 3.00012C14.7041 3.41434 15.0399 3.75012 15.4541 3.75012C16.1445 3.75012 16.7041 4.30977 16.7041 5.00012C16.7041 5.41434 17.0399 5.75012 17.4541 5.75012C17.8683 5.75012 18.2041 5.41434 18.2041 5.00012C18.2041 3.48134 16.9729 2.25012 15.4541 2.25012Z"
        fill={props.htmlColor ?? '#F5917B'}
      />
    </SvgIcon>
  );
};

export default HeartFilledIcon;
