import { useEffect } from 'react';
import { BookResponse } from '@hoot/events/interfaces/book-response';
import { LessonWhiteboardTemplateResponse } from '@hoot/hooks/api/whiteboard/useGetLessonWhiteboardTemplates';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { UserProfileType } from '@hoot/models/api/user';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { LibraryTab, PaginationMode, useLibraries } from '@hoot/redux/reducers/librarySlice';
import useTeacherInLessonLibraryController from '@hoot/ui/pages/v2/teacher/lesson/library/useTeacherInLessonLibraryController';
import TeacherLibrary from '@hoot/ui/pages/v2/teacher/library/TeacherLibrary';
import { getDefaultLibraryQuery } from '@hoot/ui/pages/v2/teacher/library/TeacherLibraryFilterBar';

const TeacherInLessonLibraryPage = () => {
  usePageTitle('Lesson | Hoot Reading');

  const { inLessonLibrary } = useLibraries();

  const {
    selectedTab,
    query: libraryQuery,
    paginationMode,
    results: libraryResults,
    previewBook,
    isLoading: isLibraryLoading,
    lessonWhiteboardTemplates: {
      query: whiteboardTemplateQuery,
      results: whiteboardTemplateResults,
      previewWhiteboardTemplate,
      isLoading: isWhiteboardTemplatesLoading,
    },
  } = inLessonLibrary;

  const lessonState = useActiveLessonState();

  const libraryController = useTeacherInLessonLibraryController({
    // This _must_ exist for this page to even render, so we know it exists.
    inLesson: lessonState.inLesson!,
  });

  // This _must_ exist for this page to even render, so we know it exists.
  const inLesson = lessonState.inLesson!;

  // When teacher _initially_ joins the lesson, there will be no query nor library results.
  // In this case, send the initial library search request so that the lesson participants have something to look at.
  useEffect(() => {
    if (!libraryQuery) {
      libraryController.onSearchLibrary(getDefaultLibraryQuery(PaginationMode.Append));
    }
  }, [libraryQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const onToggleFavouriteBook = (bookId: string, _profileId: string, profileType: UserProfileType, markAsFavourite: boolean) => {
    if (profileType !== UserProfileType.Student) {
      console.error('Can only toggle student favorite books while in a lesson.');
      return;
    }
    libraryController.onToggleStudentFavourite(bookId, markAsFavourite);
  };

  const onTabChanged = (tab: LibraryTab) => {
    libraryController.onTabChanged(tab);
  };

  const onOpenBook = (book: BookResponse, defaultPageId?: string) => {
    libraryController.onOpenBook(book.id, defaultPageId);
  };

  const onLessonWhiteboardTemplateClicked = (wt: LessonWhiteboardTemplateResponse) => {
    libraryController.onPreviewWhiteboardTemplate(wt.whiteboardTemplate.templateType);
  };

  const isLoading = selectedTab === LibraryTab.Whiteboard ? isWhiteboardTemplatesLoading : isLibraryLoading;

  return (
    <TeacherLibrary
      libraryQuery={libraryQuery ?? getDefaultLibraryQuery(paginationMode)}
      selectedTab={selectedTab}
      isLoading={isLoading}
      librarySearchResults={libraryResults}
      paginationMode={paginationMode}
      studentProfileId={inLesson.studentId}
      isInLesson={true}
      previewBook={previewBook ?? undefined}
      onPaginationModeChanged={libraryController.onPaginationModeChanged}
      onLessonWhiteboardTemplateQueryChanged={libraryController.onSearchWhiteboardTemplates}
      onTabChanged={onTabChanged}
      onLibraryQueryChanged={libraryController.onSearchLibrary}
      onFetchNextPage={libraryController.onFetchNextPage}
      onPreviewBook={libraryController.onPreviewBook}
      onDismissPreviewBook={libraryController.onDismissBookPreview}
      onToggleFavouriteBook={onToggleFavouriteBook}
      onOpenBook={onOpenBook}
      lessonWhiteboardTemplates={{
        query: whiteboardTemplateQuery,
        results: whiteboardTemplateResults,
        previewWhiteboardTemplate,
        onLessonWhiteboardTemplateClicked,
        onDismissWhiteboardTemplatePreview: libraryController.onDismissWhiteboardTemplatePreview,
        onOpenWhiteboard: libraryController.onOpenWhiteboard,
      }}
    />
  );
};

export default TeacherInLessonLibraryPage;
