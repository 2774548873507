import React, { PropsWithChildren, ReactNode } from 'react';
import ViewStateIllustration, { IllustrationEnum, ViewStateIllustrationProps } from '@hoot/ui/components/v2/ViewStateIllustration';

export enum ViewStateEnum {
  Loading,
  Results,
  EmptyState,
  NoResults,
  Error,
  EmptyStateInbox,
}

interface OverridableViewStateIllustrationProps extends Omit<ViewStateIllustrationProps, 'illustration'> {
  illustration?: IllustrationEnum;
  showBorder?: boolean;
}

export interface ViewStateProps extends PropsWithChildren<any> {
  state: ViewStateEnum;
  loadingContent?: ReactNode;
  EmptyStateIllustrationProps?: OverridableViewStateIllustrationProps;
  NoResultsIllustrationProps?: OverridableViewStateIllustrationProps;
  ErrorStateIllustrationProps?: OverridableViewStateIllustrationProps;
}

const ViewState = (props: ViewStateProps) => {
  const { state, children: results, loadingContent, NoResultsIllustrationProps, ErrorStateIllustrationProps, EmptyStateIllustrationProps } = props;

  const renderContent = () => {
    switch (state) {
      case ViewStateEnum.Loading:
        return loadingContent ?? results;
      case ViewStateEnum.EmptyState:
        return <ViewStateIllustration illustration={IllustrationEnum.Coffee} {...EmptyStateIllustrationProps} />;
      case ViewStateEnum.EmptyStateInbox:
        return <ViewStateIllustration illustration={IllustrationEnum.EmptyInbox} {...EmptyStateIllustrationProps} />;
      case ViewStateEnum.NoResults:
        return <ViewStateIllustration illustration={IllustrationEnum.NoResults} {...NoResultsIllustrationProps} />;
      case ViewStateEnum.Error:
        return <ViewStateIllustration illustration={IllustrationEnum.Error} {...ErrorStateIllustrationProps} />;
      case ViewStateEnum.Results:
        return results;
    }
  };
  return <>{renderContent()}</>;
};

export default ViewState;
