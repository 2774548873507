import React, { useEffect, useState } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { CookieConsentStatus } from '../../models/api/auth';

interface Values {
  handleAnalyticalCookiesClick: () => void;
  handleAcceptClick: () => void;
  enableAnalyticalCookies: boolean;
  cookieConsent: CookieConsentStatus;
  updateCookieConsent: (val: CookieConsentStatus, cb?: () => void) => void;
  showModal: () => void;
  openModal: boolean;
}

interface Props {
  children: React.ReactNode;
}

const CookieConsentContext = React.createContext<Values>(undefined!);

export const CookieConsentProvider = (props: Props) => {
  const [cookieConsent, setCookieConsent] = useLocalStorage<CookieConsentStatus>('HOOT_READING_COOKIE_CONSENT', CookieConsentStatus.Unknown);
  const [enableAnalyticalCookies, setEnableAnalyticalCookies] = useState<boolean>(
    cookieConsent === CookieConsentStatus.AllowAll || cookieConsent === CookieConsentStatus.Unknown,
  );
  const [cookies, setCookies] = useState<CookieConsentStatus>(CookieConsentStatus.AllowAll);
  const [openModal, setOpenModal] = useState<boolean>(cookieConsent === CookieConsentStatus.Unknown);

  const handleAcceptClick = () => {
    setCookieConsent(cookies);
    setOpenModal(false);
  };

  const handleAnalyticalCookiesClick = () => {
    setEnableAnalyticalCookies((state) => !state);
  };

  const updateCookieConsent = (val: CookieConsentStatus, cb?: () => void) => {
    setCookieConsent(val);
    if (cb) {
      cb();
    }
    setOpenModal(false);
  };

  const showModal = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    if (enableAnalyticalCookies) {
      setCookies(CookieConsentStatus.AllowAll);
    } else {
      setCookies(CookieConsentStatus.StrictlyNecessary);
    }
  }, [enableAnalyticalCookies]);

  const { children } = props;

  return (
    <CookieConsentContext.Provider
      value={{
        handleAcceptClick,
        handleAnalyticalCookiesClick,
        enableAnalyticalCookies,
        cookieConsent,
        updateCookieConsent: updateCookieConsent,
        openModal,
        showModal,
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};

export const useCookieConsent = () => {
  const context = React.useContext(CookieConsentContext);

  if (context === undefined) {
    throw new Error('useCookieConsent must be used within a CookieConsentProvider');
  }

  return context;
};
