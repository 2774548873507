import { SvgIcon, SvgIconProps } from '@mui/material';

const PageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 11L12 11" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M8 16H16" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M21 9V18C21 20.2091 19.2091 22 17 22H7C4.79086 22 3 20.2091 3 18V6C3 3.79086 4.79086 2 7 2H14M21 9L14 2M21 9H18C15.7909 9 14 7.20914 14 5V2"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
        fill={props.htmlColor ?? '#000'}
      />
    </SvgIcon>
  );
};

export default PageIcon;
