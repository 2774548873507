import { Stack, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import Card from '@hoot/ui/components/v2/core/Card';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';

const NoStudentProfiles = () => {
  return (
    <>
      <Card
        isLoading={false}
        title={
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <span>Today's Upcoming Lessons</span>
            <Tooltip title="View My Schedule">
              <Link to={routesDictionary.schedule.url}>
                <IconButton>
                  <Icon name="calendar" />
                </IconButton>
              </Link>
            </Tooltip>
          </Stack>
        }
        sx={{
          position: { md: 'sticky' },
          top: { md: '24px' },
        }}
      >
        <ViewState
          state={ViewStateEnum.EmptyState}
          EmptyStateIllustrationProps={{
            title: 'You do not have any enabled student profiles on your account.',
            subtitle: 'Please contact customer service at help@hootreading.com',
            illustration: IllustrationEnum.EmptyState,
          }}
        />
      </Card>
    </>
  );
};

export default NoStudentProfiles;
