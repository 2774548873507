import { useEffect } from 'react';
import { useStudentMediaState } from '@hoot/hooks/daily/useDailyMediaState';
import { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieButton from '@hoot/ui/components/v2/lottie/LottieButton';

interface PreviousPageButtonProps {
  onPreviousPage: () => void;
}

const PreviousPageButton = (props: PreviousPageButtonProps) => {
  const { onPreviousPage } = props;

  const { isLocked: studentLocked } = useStudentMediaState();

  useEffect(() => {
    const keyPressHandler = ({ key }: KeyboardEvent) => {
      switch (key) {
        case 'ArrowLeft':
          if (!studentLocked) {
            onPreviousPage();
          }
          break;
      }
    };
    window.addEventListener('keydown', keyPressHandler);

    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
  }, [studentLocked, onPreviousPage]);

  return (
    <LottieButton
      disabled={studentLocked}
      lottieFile={LottieFile.ArrowLeft}
      tooltipLabel="Previous page"
      aria-label="Previous page"
      onClick={onPreviousPage}
    />
  );
};

export default PreviousPageButton;
