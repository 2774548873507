import axios from 'axios';
import { useQuery } from 'react-query';
import { MonthlyLessonDatesStats } from '@hoot/models/api/lessons';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface MonthlyScheduledLessonsQuery {
  dateInMonth: number;
  studentProfileFilter?: string;
}

export interface MonthlyScheduledLessonsQueryResponse {
  lessonDates: MonthlyLessonDatesStats[];
}

export async function fetchMonthlyScheduledLessonsList(query: MonthlyScheduledLessonsQuery) {
  const { data } = await axios.post<MonthlyScheduledLessonsQueryResponse>(
    `${config.apiUrl}/public/scheduler/monthly-scheduled-lessons/search`,
    query,
  );
  return data;
}

export default function useMonthlyScheduledLessonsQuery(monthlyScheduledLessonsQuery: MonthlyScheduledLessonsQuery | undefined) {
  return useQuery(
    [QueryKey.MonthlySchedulesLessons, monthlyScheduledLessonsQuery],
    () => fetchMonthlyScheduledLessonsList(monthlyScheduledLessonsQuery!),
    { enabled: !!monthlyScheduledLessonsQuery },
  );
}
