import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import {
  Box,
  List,
  ListItemButton,
  ListItemButtonProps as MuiListItemBtnProps,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UpcomingLesson } from '@hoot/models/api/lessons';
import { UserProfileType, userProfileLabel } from '@hoot/models/api/user';
import { RootState } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { useGetReadyV2Dialog } from '@hoot/ui/context/GetReadyV2DialogProvider';
import { useUpcomingLessons } from '@hoot/ui/context/UpcomingLessonsProvider';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { HootColor, hootTokens } from '@hoot/ui/theme/v2/tokens';
import { getColors } from '@hoot/ui/theme/v2/util';
import { timeFromUnixMillisUpperCase } from '@hoot/utils/date';
import { Button } from './Button';
import HootTypography from './HootTypography';
import { Icon } from './Icon';
import NavMenuItem from './NavMenuItem';

// Must have either a navigation link or a sub list. Cannot have both.
export type NavList =
  | NavItems
  | {
      label: string;
      url?: never;
      badgeCount?: number;
      subList: NavItems[];
      icon?: JSX.Element;
      disabled?: boolean;
      hideOnMobile?: boolean;
    };

export interface NavItems {
  label: string;
  url: string;
  badgeCount?: number;
  subList?: never;
  icon?: JSX.Element;
  external?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  hideOnMobile?: boolean;
}

interface Props {
  navList: NavList[];
  profileHeaderProps: ProfileHeaderProps;
  onClose: () => void;
}

export interface SubListPage {
  header: string;
  list: NavItems[];
}

type NavMenuListItemBtnProps = Omit<MuiListItemBtnProps, 'size'> & {
  isActive: boolean;
  color?: Omit<keyof HootColor, 'black' | 'white'>;
  isDisabled?: boolean;
};

export function navListApplyStyles(props: NavMenuListItemBtnProps): SxProps<Theme> {
  const { color = 'primary', isActive, isDisabled } = props;
  const colors = getColors(color as ColorPaletteOption);

  const activeHoverStyle: SxProps<Theme> = {
    backgroundColor: colors.palette[140],
  };

  const activeFocusStyle: SxProps<Theme> = {
    backgroundColor: colors.palette[120],
  };

  const activePressedStyle: SxProps<Theme> = {
    backgroundColor: colors.palette[120],
  };

  const inactiveHoverStyle: SxProps<Theme> = {
    backgroundColor: colors.palette[190],
  };

  const inactiveFocusStyle: SxProps<Theme> = {
    backgroundColor: colors.palette[180],
  };

  const inactivePressedStyle: SxProps<Theme> = {
    backgroundColor: colors.palette[180],
  };

  const styles: SxProps<Theme> = {
    ...hootTokens.text.labellarge,
    backgroundColor: isActive ? colors.palette[180] : hootTokens.palette.white,
    color: hootTokens.palette.black,
    borderRadius: '8px',
    ':hover': isActive ? activeHoverStyle : inactiveHoverStyle,
    ':focus': isActive ? activeFocusStyle : inactiveFocusStyle,
    ':active': isActive ? activePressedStyle : inactivePressedStyle,
    cursor: isDisabled ? 'not-allowed' : undefined,
  };

  return styles;
}

const NavMenuList = (props: Props) => {
  const { navList, profileHeaderProps, onClose } = props;
  const [subListLoaded, setSubListLoaded] = useState<SubListPage | null>(null);

  const handleLeaveSublist = () => {
    setSubListLoaded(null);
  };

  return (
    <Stack width="inherit" direction="row" sx={{ overflowX: 'hidden' }}>
      <Box
        sx={{
          display: 'flex',
          transform: subListLoaded ? 'translateX(-50%)' : '',
          transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        }}
      >
        <Box sx={{ width: '312px' }}>
          <Box mt={3}>
            <ProfileHeader {...profileHeaderProps} />
            <JoinLessonButtonGroup onNavClose={onClose} />
          </Box>

          <List
            sx={{
              mt: 3,
              position: 'relative',
              zIndex: 5,
            }}
            disablePadding
          >
            {navList.map((item, idx) => (
              <NavMenuItem key={idx} navItem={item} onClose={onClose} setSubListLoaded={setSubListLoaded} />
            ))}
          </List>
        </Box>
        {subListLoaded ? (
          <Box sx={{ minWidth: '312px' }}>
            <Stack>
              <Box sx={{ mt: 2 }}>
                <ListItemButton
                  onClick={handleLeaveSublist}
                  sx={{
                    ...navListApplyStyles({ isActive: false }),
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 2,
                    px: 0,
                  }}
                >
                  <Icon name="chevron" />
                  Back
                </ListItemButton>
              </Box>
              <HootTypography isPII={false} variant="titlelarge" sx={{ mt: '30px' }}>
                {subListLoaded.header}
              </HootTypography>
              <List>
                {subListLoaded.list.map((item, idx) => (
                  <NavMenuItem key={idx} navItem={item} onClose={onClose} setSubListLoaded={setSubListLoaded} />
                ))}
              </List>
            </Stack>
          </Box>
        ) : null}
      </Box>
    </Stack>
  );
};

export interface ProfileHeaderProps {
  profileName?: string;
  profileType?: UserProfileType;
}

const ProfileHeader = (props: ProfileHeaderProps) => {
  const { profileType, profileName } = props;
  const { logout } = useAuth();
  const navigate = useNavigate();

  const navigateToProfileSelect = () => {
    navigate(routesDictionary.selectProfile.url);
  };

  if (!profileName || !profileType) {
    return null;
  }

  const renderProfileSelectionLink = () => {
    return (
      <Tooltip title="Return to Profile Selection" placement="right-start">
        <Stack direction="row" alignItems="center">
          <Button onClick={navigateToProfileSelect} sx={{ padding: '4px' }}>
            <Icon name="account_circle_filled" color="primary" />
            <Stack ml="10px" alignItems="flex-start">
              <HootTypography isPII={true} variant="bodymedium">
                {profileName}
              </HootTypography>
              <HootTypography isPII={false} variant="bodysmall">
                {userProfileLabel[profileType] ?? ''}
              </HootTypography>
            </Stack>
          </Button>
        </Stack>
      </Tooltip>
    );
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      {profileType === UserProfileType.Parent && renderProfileSelectionLink()}
      {profileType !== UserProfileType.Parent && (
        <Stack direction="row" alignItems="center">
          <Icon name="account_circle_filled" color="primary" />
          <Stack ml="10px" alignItems="flex-start">
            <HootTypography isPII={true} variant="bodymedium">
              {profileName}
            </HootTypography>
            <HootTypography isPII={false} variant="bodysmall">
              {userProfileLabel[profileType] ?? ''}
            </HootTypography>
          </Stack>
        </Stack>
      )}

      <Button variant="text" size="small">
        <HootTypography isPII={false} variant="labelsmall" sx={{ color: hootTokens.palette.secondary[60] }} onClick={() => logout()}>
          Sign Out
        </HootTypography>
      </Button>
    </Stack>
  );
};

const JoinLessonButtonGroup = (props: { onNavClose: () => void }) => {
  const { onNavClose } = props;
  const { openLesson, nextOpenLesson } = useUpcomingLessons();
  const { startGetReady } = useGetReadyV2Dialog();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);

  if (!openLesson || !isDesktop || inLesson) {
    return null;
  }

  const handleJoinClick = (lesson: UpcomingLesson) => {
    startGetReady({ lessonId: lesson.lessonId, lessonNumber: lesson.lessonNumber });
    onNavClose();
  };

  return (
    <Stack>
      <Stack
        direction="row"
        padding={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          mt: 3,
          backgroundColor: hootTokens.palette.success['180'],
          borderRadius: '8px',
        }}
      >
        <HootTypography isPII={false} variant="titlesmall">{`Lesson ${
          openLesson.status === ScheduledLessonStatus.InProgress ? 'In Progress' : 'Is Open'
        }`}</HootTypography>
        <Stack direction="row" gap={1} pl={3} flexWrap="wrap" justifyContent="center">
          <Button variant="contained" size="small" color="success.60" onClick={() => handleJoinClick(openLesson)}>
            Join
          </Button>
        </Stack>
      </Stack>
      {nextOpenLesson ? (
        <Stack
          direction="row"
          padding={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            mt: 3,
            backgroundColor: hootTokens.palette.success['140'],
            borderRadius: '8px',
          }}
        >
          <HootTypography isPII={false} variant="titlesmall">{`Next lesson ${timeFromUnixMillisUpperCase(
            Number(nextOpenLesson.startTime),
          )}`}</HootTypography>
          <Stack direction="row" gap={1} pl={3} flexWrap="wrap" justifyContent="center">
            <Button variant="contained" size="small" color="success.60" onClick={() => handleJoinClick(nextOpenLesson)}>
              Join
            </Button>
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
};

export default NavMenuList;
