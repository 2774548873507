import { Divider, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { DefaultColorStyle, DefaultFontStyle, DefaultSizeStyle, Editor, useValue } from 'tldraw';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import PopoverMenu, { MenuItem } from '@hoot/ui/components/v2/core/PopoverMenu';
import ColourOption from '@hoot/ui/components/v2/icons/ColourOption';
import Copy from '@hoot/ui/components/v2/icons/Copy';
import { useFullscreen } from '@hoot/ui/context/FullscreenContext';
import { useToolsContext } from '@hoot/ui/context/ToolsContext';

enum WhiteboardTools {
  Select = 'select',
  Draw = 'draw',
  Eraser = 'eraser',
  Text = 'text',
}

enum WhiteboardColours {
  Black = 'black',
  Green = 'green',
  Blue = 'blue',
  Orange = 'orange',
  Red = 'red',
}

interface TeacherInLessonWhiteboardHeaderProps {
  isWhiteboardMounted: boolean;
  editorRef: MutableRefObject<Editor | undefined>;
  onCloseWhiteboard: () => void;
  onResetWhiteboard: () => void;
}

const TeacherInLessonWhiteboardHeader = (props: TeacherInLessonWhiteboardHeaderProps) => {
  const { editorRef, isWhiteboardMounted, onCloseWhiteboard, onResetWhiteboard } = props;
  const editor = editorRef.current;

  const isShapeSelected = useValue('isShapeSelected', () => (editor?.getSelectedShapeIds().length ?? 0) > 0, [editor]);
  const canUndo = useValue('canUndo', () => !!editor?.getCanUndo(), [editor]);
  const canRedo = useValue('canRedo', () => !!editor?.getCanRedo(), [editor]);

  const [currentTool, setCurrentTool] = useState<WhiteboardTools>(WhiteboardTools.Select);
  const [currentColour, setCurrentColour] = useState<WhiteboardColours>(WhiteboardColours.Black);
  const [showColourMenu, setShowColourMenu] = useState<boolean>(false);
  const [toolsIconButtonElement, setToolsIconButtonElement] = useState<null | HTMLElement>(null);

  // Select default tool when WB is mounted and ready.
  useEffect(() => {
    if (isWhiteboardMounted) {
      onToolChange(WhiteboardTools.Select);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWhiteboardMounted]);

  const { toggleFullscreen, isFullscreen } = useFullscreen();

  const {
    isCelebrationsDialogVisible,
    onShowCelebrationsDialog,
    onHideCelebrationsDialog,
    isNotepadVisible,
    onShowNotepad,
    onHideNotepad,
    isTimerVisible,
    onShowTimer,
    onHideTimer,
  } = useToolsContext();

  const toolsMenuItems = (): MenuItem[] => {
    return [
      { id: 'timer', label: 'Timer', icon: <Icon name="clock" />, onClick: toggleShowTimer },
      {
        id: 'go-fullscreen',
        label: isFullscreen ? 'Exit Fullscreen' : 'Go Fullscreen',
        icon: <Icon name={isFullscreen ? 'exit_full_screen' : 'enter_full_screen'} />,
        onClick: onToggleFullscreen,
      },
      {
        id: 'notepad',
        label: 'Notepad',
        icon: <Icon name="pen" />,
        onClick: toggleShowNotepad,
      },
      {
        id: 'celebrations',
        label: 'Celebrations',
        icon: <Icon name="hoot_wheel" />,
        onClick: toggleCelebrationsDialog,
      },
    ];
  };

  const onToggleFullscreen = async () => {
    dismissToolsMenu();
    await toggleFullscreen();
  };

  const toggleShowTimer = () => {
    if (isTimerVisible) {
      onHideTimer();
    } else {
      onShowTimer();
    }
    dismissToolsMenu();
  };

  const toggleShowNotepad = () => {
    if (isNotepadVisible) {
      onHideNotepad();
    } else {
      onShowNotepad();
    }
    dismissToolsMenu();
  };

  const toggleCelebrationsDialog = () => {
    if (isCelebrationsDialogVisible) {
      onHideCelebrationsDialog();
    } else {
      onShowCelebrationsDialog();
    }
    dismissToolsMenu();
  };

  const onToolChange = (selectedTool: WhiteboardTools) => {
    if (!selectedTool) return;

    setCurrentTool(selectedTool);
    editor?.setCurrentTool(selectedTool);

    if (selectedTool === WhiteboardTools.Draw) setShowColourMenu(true);
    else setShowColourMenu(false);

    if (selectedTool === WhiteboardTools.Text || selectedTool === WhiteboardTools.Select) {
      // Might not need to explicitly set all of these, but it makes sure that the text stays consistent.
      editor?.setStyleForNextShapes(DefaultColorStyle, WhiteboardColours.Black);
      editor?.setStyleForNextShapes(DefaultSizeStyle, 'xl');
      editor?.setStyleForNextShapes(DefaultFontStyle, 'sans');
    } else if (selectedTool === WhiteboardTools.Draw) {
      editor?.setStyleForNextShapes(DefaultSizeStyle, 'l');
      editor?.setStyleForNextShapes(DefaultColorStyle, currentColour);
    }
  };

  const onColourChange = (selectedColour: WhiteboardColours) => {
    if (!selectedColour) return;

    setCurrentColour(selectedColour);
    editor?.setStyleForNextShapes(DefaultColorStyle, selectedColour);
  };

  const handleUndoClick = () => editor?.undo();
  const handleRedoClick = () => editor?.redo();
  const handleCopyClick = () =>
    editor?.duplicateShapes(editor?.getSelectedShapeIds(), {
      x: 32,
      y: 32,
    });

  const showToolsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setToolsIconButtonElement(event.currentTarget);
  };

  const dismissToolsMenu = () => {
    setToolsIconButtonElement(null);
  };

  const resetCamera = () => {
    editor?.zoomToFit();
    dismissToolsMenu();
  };

  return (
    <Card
      sx={{
        borderRadius: '8px',
      }}
      contentSx={{
        padding: 0,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" padding={2}>
        <HootTypography variant="titlelarge" isPII={false}>
          Whiteboard
        </HootTypography>
        <Stack direction="row" gap={1} alignItems="center">
          <Button variant="outlined" size="small" onClick={onResetWhiteboard}>
            Reset
          </Button>
          <Button variant="outlined" size="small" onClick={resetCamera}>
            Re-center
          </Button>
          <Button variant="outlined" size="small" onClick={handleUndoClick} disabled={!canUndo}>
            Undo
          </Button>
          <Button variant="outlined" size="small" onClick={handleRedoClick} disabled={!canRedo}>
            Redo
          </Button>
          <Stack direction="row" alignItems="center" gap={1}>
            <Tooltip title="Show Tools Menu">
              <IconButton onClick={showToolsMenu}>
                <Icon name="divider_caliper" />
              </IconButton>
            </Tooltip>
            <PopoverMenu
              id="tools-menu"
              variant="dark"
              anchorEl={toolsIconButtonElement}
              open={!!toolsIconButtonElement}
              onClose={dismissToolsMenu}
              items={toolsMenuItems()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            />
            <Tooltip title="Return to Library">
              <span>
                <IconButton onClick={onCloseWhiteboard}>
                  <Icon name="close_filled_square" />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </Stack>
      </Box>
      <Divider />
      {/* Sub-Menu */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} paddingX={2} paddingY={0.25}>
        <Box>
          {/* Select tool options */}
          {currentTool === WhiteboardTools.Select && (
            <Tooltip title="Duplicate selected shape(s)" enterDelay={500}>
              <span>
                <IconButton onClick={handleCopyClick} disabled={!isShapeSelected}>
                  <Copy />
                </IconButton>
              </span>
            </Tooltip>
          )}

          {/* Colour options */}
          {showColourMenu && (
            <>
              <ToggleButtonGroup
                value={currentColour}
                orientation="horizontal"
                exclusive
                onChange={(_e, value) => onColourChange(value)}
                sx={{
                  gap: 2,
                  '& > button.Mui-selected, & > button:hover': {
                    backgroundColor: '#DADADA',
                    borderRadius: '8px',
                  },
                  '& > button': {
                    border: 'unset',
                    padding: '10px',
                  },
                }}
              >
                <ToggleButton value={WhiteboardColours.Black} aria-label={WhiteboardColours.Black}>
                  <ColourOption fill={WhiteboardColours.Black} />
                </ToggleButton>
                <ToggleButton value={WhiteboardColours.Green} aria-label={WhiteboardColours.Green}>
                  <ColourOption fill={WhiteboardColours.Green} />
                </ToggleButton>
                <ToggleButton value={WhiteboardColours.Blue} aria-label={WhiteboardColours.Blue}>
                  <ColourOption fill={WhiteboardColours.Blue} />
                </ToggleButton>
                <ToggleButton value={WhiteboardColours.Orange} aria-label={WhiteboardColours.Orange}>
                  <ColourOption fill={WhiteboardColours.Orange} />
                </ToggleButton>
                <ToggleButton value={WhiteboardColours.Red} aria-label={WhiteboardColours.Red}>
                  <ColourOption fill={WhiteboardColours.Red} />
                </ToggleButton>
              </ToggleButtonGroup>
            </>
          )}
        </Box>
        <Stack direction="row" alignItems="center" gap={2}>
          <Tooltip title="Select and Drag" enterDelay={800}>
            <IconButton
              value={WhiteboardTools.Select}
              aria-label="select"
              variant={currentTool === WhiteboardTools.Select ? 'contained' : 'standard'}
              onClick={() => onToolChange(WhiteboardTools.Select)}
            >
              <Icon name="drag" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Draw" enterDelay={800}>
            <IconButton
              value={WhiteboardTools.Draw}
              aria-label="draw"
              variant={currentTool === WhiteboardTools.Draw ? 'contained' : 'standard'}
              onClick={() => onToolChange(WhiteboardTools.Draw)}
            >
              <Icon name="pen" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Erase" enterDelay={800}>
            <IconButton
              value={WhiteboardTools.Eraser}
              aria-label="eraser"
              variant={currentTool === WhiteboardTools.Eraser ? 'contained' : 'standard'}
              onClick={() => onToolChange(WhiteboardTools.Eraser)}
            >
              <Icon name="eraser" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Type" enterDelay={800}>
            <IconButton
              value={WhiteboardTools.Text}
              aria-label="text"
              variant={currentTool === WhiteboardTools.Text ? 'contained' : 'standard'}
              onClick={() => onToolChange(WhiteboardTools.Text)}
            >
              <Icon name="type_letters" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Card>
  );
};

export default TeacherInLessonWhiteboardHeader;
