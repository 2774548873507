import Grid from '@mui/material/Grid2';
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import Card from '@hoot/ui/components/v2/core/Card';
import TextField from '@hoot/ui/components/v2/core/TextField';
import { TeacherProfileForm } from './TeacherProfile';

interface Props {
  control: Control<TeacherProfileForm, object>;
  watch: UseFormWatch<TeacherProfileForm>;
}

const BankingDetailsEmpty = (props: Props) => {
  const { control, watch } = props;

  const watchBranch = watch('branchNumber');
  const watchInstutition = watch('institutionNumber');
  const watchAccount = watch('accountNumber');
  const watchCountry = watch('country');
  const watchSocial = watch('identificationNumber');
  const watchDetailsRequired = !!watchBranch || !!watchInstutition || !!watchAccount || !!watchSocial;

  return (
    <Card sx={{ width: '100%' }} title="Banking Details">
      <Grid container rowGap={'16px'} columnSpacing={'16px'}>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="branchNumber"
            control={control}
            rules={{
              required: {
                value: watchDetailsRequired,
                message: 'Required',
              },
              pattern: {
                value: /^\d+$/,
                message: 'Numbers only. Please try again.',
              },
            }}
            render={({ field: { onChange, value }, fieldState }) => (
              <TextField
                variant="outlined"
                fullWidth
                id="branchNumber"
                label="Transit Number"
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message ?? ''}
              />
            )}
          />
        </Grid>
        {watchCountry !== 'US' ? (
          <Grid size={{ xs: 12, lg: 6 }}>
            <Controller
              name="institutionNumber"
              control={control}
              rules={{
                required: {
                  value: watchDetailsRequired,
                  message: 'Required',
                },
                pattern: {
                  value: /^\d+$/,
                  message: 'Numbers only. Please try again.',
                },
                validate: (value) => !watchDetailsRequired || (value && value.trim().length === 3) || 'Must be 3 digits. Please try again.',
              }}
              render={({ field: { onChange, value }, fieldState }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="institutionNumber"
                  label="Institution Number"
                  value={value}
                  onChange={onChange}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message ?? ''}
                />
              )}
            />
          </Grid>
        ) : null}
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="accountNumber"
            control={control}
            rules={{
              required: {
                value: watchDetailsRequired,
                message: 'Required field',
              },
              pattern: {
                value: /^\d+$/,
                message: 'Numbers only. Please try again.',
              },
              validate: (value) =>
                !watchDetailsRequired || (value && value.trim().length >= 7 && value.trim().length <= 15) || 'Must be 7-15 digits. Please try again.',
            }}
            render={({ field: { onChange, value }, fieldState }) => (
              <TextField
                variant="outlined"
                fullWidth
                id="accountNumber"
                label="Account Number"
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message ?? ''}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="identificationNumber"
            control={control}
            rules={{
              required: {
                value: watchDetailsRequired,
                message: 'Required field',
              },
              pattern: {
                value: /^\d+$/,
                message: 'Numbers only. Please try again.',
              },
              validate: (value) => !value || (value && value.trim().length === 9) || 'Must be 9 digits. Please try again.',
            }}
            render={({ field: { onChange, value }, fieldState }) => (
              <TextField
                variant="outlined"
                fullWidth
                id="identificationNumber"
                label="Social Security Number"
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message ?? ''}
              />
            )}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default BankingDetailsEmpty;
