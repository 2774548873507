import { Box, Skeleton, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import { capitalCase } from 'change-case';
import { RefObject } from 'react';
import { UseMutationResult } from 'react-query';
import { InvoiceDetailsResponse } from '@hoot/hooks/api/invoice/useGetInvoiceDetails';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Tag, { TagColor } from '@hoot/ui/components/v2/core/Tag';
import { handleTagColor } from '@hoot/ui/pages/v2/teacher/invoicing/util';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { formatPhoneNumber } from '@hoot/utils/text-display';
import AdditionalServices from './AdditionalServices';
import BillableLessons from './BillableLessons';
import BillableTasks from './BillableTasks';
import InvoiceDetailsSummary from './InvoiceDetailsSummary';

interface IProps {
  invoiceDetails: InvoiceDetailsResponse | undefined;
  viewState: ViewStateEnum;
  setShowConfirmSubmitDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setShowConfirmRetractDialog: React.Dispatch<React.SetStateAction<boolean>>;
  submitInvoiceLoading: boolean;
  retractInvoiceLoading: boolean;
  setShowPartiallySubmittedBanner: React.Dispatch<React.SetStateAction<boolean>>;
  resubmitLessonsMutation: UseMutationResult<void, AxiosError, string>;
  billableLessonsScrollToRefProp: RefObject<HTMLDivElement>;
  isCurrentPeriod: boolean;
  isSubmitted: boolean;
  isInvoicePeriodSubmitted: boolean;
}

const InvoiceDetails = (props: IProps) => {
  const {
    invoiceDetails,
    viewState,
    setShowConfirmSubmitDialog,
    setShowConfirmRetractDialog,
    submitInvoiceLoading,
    retractInvoiceLoading,
    setShowPartiallySubmittedBanner,
    resubmitLessonsMutation,
    billableLessonsScrollToRefProp,
    isCurrentPeriod,
    isSubmitted,
    isInvoicePeriodSubmitted,
  } = props;

  const isPartiallySubmitted = isSubmitted && invoiceDetails?.isInvoicePartiallySubmitted;

  const submissionDateInfo = () => {
    if (isSubmitted) {
      return (
        <Box>
          <HootTypography isPII={false} variant="labellarge">
            Submitted On:
          </HootTypography>
          <HootTypography isPII={false} variant="bodysmall">
            {invoiceDetails?.invoiceLastSubmittedAt}
          </HootTypography>
        </Box>
      );
    } else {
      if (!isCurrentPeriod) {
        return null;
      }
      return (
        <Box>
          <HootTypography isPII={false} variant="labellarge">
            Submit By:
          </HootTypography>
          <HootTypography isPII={false} variant="bodysmall">
            {invoiceDetails?.invoicePeriodEnd}
          </HootTypography>
          <HootTypography isPII={false} variant="bodysmall" color={hootTokens.palette.success[60]}>
            *You have {invoiceDetails?.daysLeftInPeriod} days left to submit this invoice
          </HootTypography>
        </Box>
      );
    }
  };

  return (
    <Card contentSx={{ padding: '12px 16px 0px 16px' }}>
      <HootTypography isPII={true} variant="displaysmall">
        Invoice #{invoiceDetails?.invoiceNumber}
      </HootTypography>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)">
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridColumn={{ xs: 'span 12' }}
          sx={{ '@media print': { display: 'flex', justifyContent: 'space-between' } }}
        >
          <Box gridColumn={{ md: 'span 6', xs: 'span 12' }}>
            <Card
              title={'Invoice Details'}
              titleVariant="titlelarge"
              sx={{ border: 'none', boxShadow: 'none' }}
              contentSx={{ padding: '12px 0px 16px 0px' }}
            >
              <ViewState state={viewState} loadingContent={<InvoiceDetailsSkeletonItems />}>
                <Stack spacing={2}>
                  <Box>
                    <HootTypography isPII={true} variant="labellarge">
                      Invoice Number:
                    </HootTypography>
                    <HootTypography isPII={false} variant="bodysmall">
                      {invoiceDetails?.invoiceNumber}
                    </HootTypography>
                  </Box>
                  <Box>
                    <HootTypography isPII={false} variant="labellarge">
                      Invoice Period:
                    </HootTypography>
                    <HootTypography isPII={false} variant="bodysmall">
                      {invoiceDetails?.invoicePeriodStart} to {invoiceDetails?.invoicePeriodEnd}
                    </HootTypography>
                    {!isCurrentPeriod && (
                      <HootTypography isPII={false} variant="bodysmall" color={hootTokens.palette.error[60]}>
                        *This invoice period has closed.
                      </HootTypography>
                    )}
                  </Box>
                  {submissionDateInfo()}
                  <Box display={'flex'} flexDirection={'column'} gap={1}>
                    <HootTypography isPII={false} variant="labellarge">
                      Status:
                    </HootTypography>
                    <Box marginBottom={1}>
                      <Tag
                        label={isPartiallySubmitted ? 'Partially Submitted' : capitalCase(invoiceDetails?.status ?? '')}
                        color={isPartiallySubmitted ? TagColor.Warning : handleTagColor(invoiceDetails?.status)}
                        sx={{ ...hootTokens.text.labellarge }}
                      />
                    </Box>
                  </Box>
                </Stack>
              </ViewState>
            </Card>
          </Box>
          <Box gridColumn={{ md: 'span 6', xs: 'span 12' }}>
            <Card
              title={'Teacher Details'}
              titleVariant="titlelarge"
              sx={{ border: 'none', boxShadow: 'none' }}
              contentSx={{ padding: '12px 0px 16px 0px' }}
            >
              <ViewState state={viewState} loadingContent={<TeacherDetailsSkeletonItems />}>
                <Stack spacing={2}>
                  <Box>
                    <HootTypography isPII={false} variant="labellarge">
                      Name:
                    </HootTypography>
                    <HootTypography isPII={true} variant="bodysmall">
                      {invoiceDetails?.teacherDetails.name}
                    </HootTypography>
                  </Box>
                  <Box>
                    <HootTypography isPII={false} variant="labellarge">
                      Address:
                    </HootTypography>
                    <HootTypography isPII={true} variant="bodysmall">
                      {invoiceDetails?.teacherDetails.address ?? 'Not Submitted'}
                    </HootTypography>
                  </Box>
                  <Box>
                    <HootTypography isPII={false} variant="labellarge">
                      Phone:
                    </HootTypography>
                    <HootTypography isPII={true} variant="bodysmall">
                      {formatPhoneNumber(invoiceDetails?.teacherDetails.phoneNumber ?? '')}
                    </HootTypography>
                  </Box>
                </Stack>
              </ViewState>
            </Card>
          </Box>
        </Box>
        <Box gridColumn={{ xs: 'span 12' }} maxWidth={'100%'}>
          <BillableLessons
            sx={{ border: 'none', boxShadow: 'none' }}
            invoiceDetails={invoiceDetails}
            resubmitLessonsMutation={resubmitLessonsMutation}
            setShowPartiallySubmittedBanner={setShowPartiallySubmittedBanner}
            billableLessonsScrollToRefProp={billableLessonsScrollToRefProp}
            isCurrentPeriod={isCurrentPeriod}
          />
        </Box>
        {isInvoicePeriodSubmitted ? (
          <Box gridColumn={{ xs: 'span 12' }} maxWidth={'100%'}>
            <AdditionalServices sx={{ border: 'none', boxShadow: 'none' }} />
          </Box>
        ) : null}
        <Box gridColumn={{ xs: 'span 12' }}>
          <BillableTasks
            sx={{ border: 'none', boxShadow: 'none' }}
            invoiceDetails={invoiceDetails}
            isCurrentPeriod={isCurrentPeriod}
            isSubmitted={isSubmitted}
            isInvoicePeriodSubmitted={isInvoicePeriodSubmitted}
          />
        </Box>
        <Box gridColumn={{ xs: 'span 12', sm: 'span 6', md: 'span 5' }}>
          <InvoiceDetailsSummary
            sx={{ border: 'none', boxShadow: 'none' }}
            invoiceDetails={invoiceDetails}
            viewStateInvoiceDetails={viewState}
            setShowConfirmSubmitDialog={setShowConfirmSubmitDialog}
            setShowConfirmRetractDialog={setShowConfirmRetractDialog}
            submitInvoiceLoading={submitInvoiceLoading}
            retractInvoiceLoading={retractInvoiceLoading}
            isCurrentPeriod={isCurrentPeriod}
            isSubmitted={isSubmitted}
            isInvoicePeriodSubmitted={isInvoicePeriodSubmitted}
          />
        </Box>
      </Box>
    </Card>
  );
};

const InvoiceDetailsSkeletonItems = () => (
  <Stack gap={3}>
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Skeleton variant="rounded" sx={{ maxWidth: '150px', ...hootTokens.text.bodysmall }} />
      <Skeleton variant="rounded" sx={{ maxWidth: '100px', ...hootTokens.text.bodysmall }} />
    </Box>
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Skeleton variant="rounded" sx={{ maxWidth: '100px', ...hootTokens.text.bodysmall }} />
      <Skeleton variant="rounded" sx={{ maxWidth: '150px', ...hootTokens.text.bodysmall }} />
    </Box>
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Skeleton variant="rounded" sx={{ maxWidth: '120px', ...hootTokens.text.bodysmall }} />
      <Skeleton variant="rounded" sx={{ maxWidth: '100px', ...hootTokens.text.bodysmall }} />
      <Skeleton variant="rounded" sx={{ maxWidth: '250px', ...hootTokens.text.bodysmall }} />
    </Box>
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Skeleton variant="rounded" sx={{ maxWidth: '50px', ...hootTokens.text.bodysmall }} />
      <Skeleton variant="rounded" sx={{ height: '25px', maxWidth: '100px', ...hootTokens.text.bodysmall }} />
    </Box>
  </Stack>
);

const TeacherDetailsSkeletonItems = () => (
  <Stack gap={3}>
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Skeleton variant="rounded" sx={{ maxWidth: '75px', ...hootTokens.text.bodysmall }} />
      <Skeleton variant="rounded" sx={{ maxWidth: '125px', ...hootTokens.text.bodysmall }} />
    </Box>
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Skeleton variant="rounded" sx={{ maxWidth: '75px', ...hootTokens.text.bodysmall }} />
      <Skeleton variant="rounded" sx={{ maxWidth: '125px', ...hootTokens.text.bodysmall }} />
    </Box>
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Skeleton variant="rounded" sx={{ maxWidth: '75px', ...hootTokens.text.bodysmall }} />
      <Skeleton variant="rounded" sx={{ maxWidth: '125px', ...hootTokens.text.bodysmall }} />
    </Box>
  </Stack>
);

export default InvoiceDetails;
