import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';

export interface SetPasswordRequest {
  password: string;
  confirmPassword: string;
}

async function setStudentPassword(districtRepresentativeId: string, studentProfileId: string, setPasswordRequest: SetPasswordRequest): Promise<void> {
  await axios.put<void>(
    `${config.apiUrl}/public/district-representative/${districtRepresentativeId}/student-profile/${studentProfileId}/set-password`,
    setPasswordRequest,
  );
}

export function useSetStudentPassword(
  districtRepresentativeId: string,
  studentProfileId: string,
  options?: Omit<UseMutationOptions<void, AxiosError, SetPasswordRequest>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation<void, AxiosError, SetPasswordRequest>(
    (request) => setStudentPassword(districtRepresentativeId, studentProfileId, request),
    options,
  );
}
