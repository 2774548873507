import { EventType } from '@hoot/events/eventType';
import { WhiteboardCloseRequestMessage } from '@hoot/events/messages/whiteboard-close-request.message';
import { WhiteboardResetRequestMessage } from '@hoot/events/messages/whiteboard-reset-request.message';
import { useEmit } from '@hoot/hooks/useEmit';

interface UseTeacherInLessonWhiteboardControllerProps {
  lessonId: string;
  whiteboardId: string;
}

const useTeacherInLessonWhiteboardController = (props: UseTeacherInLessonWhiteboardControllerProps) => {
  const { lessonId, whiteboardId } = props;

  const closeWhiteboardEmitter = useEmit<WhiteboardCloseRequestMessage>(EventType.WhiteboardCloseRequest, undefined, {
    enabled: false,
  });
  const resetWhiteboardEmitter = useEmit<WhiteboardResetRequestMessage>(EventType.WhiteboardResetRequest, undefined, {
    enabled: false,
  });

  const closeWhiteboard = () => {
    closeWhiteboardEmitter.queueMessage({
      lessonId,
    });
  };

  const onResetWhiteboard = () => {
    resetWhiteboardEmitter.queueMessage({
      lessonId,
      whiteboardId,
    });
  };

  return {
    closeWhiteboard,
    onResetWhiteboard,
  };
};

export default useTeacherInLessonWhiteboardController;
