import { useDevices } from '@daily-co/daily-react';
import { Select } from '../../../core/Select';

export function DailyAudioInputSelect() {
  const { currentMic, microphones, setMicrophone } = useDevices();

  const handleAudioInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMicrophone(e.target.value);
  };

  return (
    <Select label="Audio Input" value={currentMic?.device.deviceId} onChange={handleAudioInputChange} required>
      {!currentMic ? (
        <option disabled value="">
          Select
        </option>
      ) : null}
      {microphones.map((mic) => (
        <option key={`mic-${mic.device.deviceId}`} value={mic.device.deviceId}>
          {mic.device.label.trim() === '' ? 'No Audio Device(s) Detected' : mic.device.label.trim()}
        </option>
      ))}
    </Select>
  );
}
