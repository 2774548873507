import { Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { ResourceState } from '@hoot/models/api/library';
import AsyncImage from '@hoot/ui/components/v2/AsyncImage';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import theme from '@hoot/ui/theme/v2';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface Props {
  book: BookDisplayBook;
  studentProfileId?: string;
  handleBookClick?: (bookId: string) => void;
}

export interface BookDisplayBook {
  id: string;
  coverUrl: string | null;
  title: string;
  resourceState?: ResourceState;
  publisherName?: string;
}

const BookStatusIcon = (props: { resourceState: ResourceState }) => {
  const { resourceState } = props;

  // Note: width and height require to overwrite default 24 x 24 SvgIcon component, must be combined with inheritViewBox on the SvgIcon
  if (resourceState === ResourceState.Read) {
    return (
      <Tooltip title={'Book read'}>
        <div style={{ position: 'absolute', top: 8, right: 16 }}>
          <Icon name="read" sx={{ width: 8, height: 8 }} />
        </div>
      </Tooltip>
    );
  } else if (resourceState === ResourceState.InProgress) {
    return (
      <Tooltip title={'Book in-progress'}>
        <div style={{ position: 'absolute', top: 8, right: 16 }}>
          <Icon name="in_progress" sx={{ width: 8, height: 8 }} />
        </div>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={'Book not started'}>
        <div style={{ position: 'absolute', top: 8, right: 16 }}>
          <Icon name="not_started" sx={{ width: 8, height: 8 }} />
        </div>
      </Tooltip>
    );
  }
};

const BookDisplay = (props: React.ImgHTMLAttributes<HTMLImageElement> & Props) => {
  const { book, studentProfileId, handleBookClick } = props;
  const isLibraryBook = !!handleBookClick;
  const resourceState = book.resourceState;

  const handleClick = () => {
    if (isLibraryBook) {
      handleBookClick(book.id);
    }
  };

  return (
    <Box
      onClick={handleClick}
      tabIndex={0}
      sx={(theme) => ({
        position: 'relative',
        backgroundColor: !studentProfileId ? hootTokens.palette.secondary[195] : hootTokens.surface[0],
        borderRadius: theme.spacing(1),
        display: 'flex',
        flexFlow: 'column',
        padding: isLibraryBook ? theme.spacing(3) : undefined,
        alignItems: 'center',
        cursor: isLibraryBook ? 'pointer' : undefined,
        ...(isLibraryBook ? { ...hootTokens.elevation.get(1) } : {}),
      })}
    >
      {!!studentProfileId && !!resourceState ? <BookStatusIcon resourceState={resourceState} /> : null}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          borderRadius: theme.spacing(1),
          marginBottom: theme.spacing(1),
          overflow: 'hidden',
        }}
      >
        <AsyncImage
          draggable={false}
          width="216px"
          style={{
            objectFit: 'contain',
            width: '100%',
            borderRadius: '8px',
            minHeight: '216px',
          }}
          src={book.coverUrl ?? undefined}
          alt={book.title}
        />
      </Box>
      {isLibraryBook && (
        <HootTypography isPII={false} variant="bodysmall" sx={{ textAlign: 'center' }}>
          {book.title}
        </HootTypography>
      )}
    </Box>
  );
};

export default BookDisplay;
