import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';

export interface ResetPasswordRequest {
  password: string;
  passwordResetToken: string;
}

async function resetPassword(resetPassword: ResetPasswordRequest): Promise<void> {
  await axios.put<void>(`${config.apiUrl}/auth/reset-password`, resetPassword);
}

export function useResetPassword(options?: Omit<UseMutationOptions<void, AxiosError, ResetPasswordRequest>, 'mutationKey' | 'mutationFn'>) {
  return useMutation((request) => resetPassword(request), options);
}
