import { Box, BoxProps, Tab as MuiTab, TabProps as MuiTabProps } from '@mui/material';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { getColors } from '@hoot/ui/theme/v2/util';

export type TabProps = MuiTabProps & { isSelected: boolean; contentSx?: BoxProps['sx'] };

export function Tab(props: TabProps) {
  const { isSelected, label, value, contentSx, ...tabProps } = props;

  const colors = getColors(isSelected ? 'secondary.0' : 'primary.190');

  return (
    <MuiTab
      {...tabProps}
      label={
        <Box
          component="span"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'solid 1px transparent',
            boxSizing: 'border-box',
            borderRadius: '8px',
            height: '37px',
            minWidth: '150px',
            ...hootTokens.text.titlesmall,
            backgroundColor: colors.base,
            boxShadow: isSelected
              ? undefined
              : '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
            color: colors.font,
            ':disabled': {
              color: hootTokens.palette.white,
              backgroundColor: hootTokens.palette.neutral[140],
            },
            '&:focus-visible': {
              backgroundColor: colors.focus,
              border: `solid 1px ${colors.base}`,
            },
            ':hover': {
              backgroundColor: colors.hover,
              boxShadow: hootTokens.elevation.elevation3.boxShadow,
            },
            ':active': {
              backgroundColor: colors.base,
            },
            ...contentSx,
          }}
        >
          {label}
        </Box>
      }
      value={value}
    />
  );
}
