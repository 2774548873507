import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  FormativeAssessmentSearchResult,
  GetStudentFormativeAssessmentsQuery,
  StudentFormativeAssessmentsQuerySortKeyEnum,
  useGetFormativeAssessments,
} from '@hoot/hooks/api/assessment/useGetStudentFormativeAssessments';
import { FormativeAssessmentType } from '@hoot/models/api/enums/lesson-review-enums';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/models/api/pagination';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import FormativeAssessmentsNoteModal from './FormativeAssessmentsNotesModal';

interface FormativeAssessmentTableRow {
  date: string;
  resourceUsed: string;
  score: string;
  action: React.ReactNode;
}

const headers: HeaderData<FormativeAssessmentTableRow>[] = [
  { name: 'Date', property: 'date', isSortable: true, width: '30%' },
  { name: 'Resource Used', property: 'resourceUsed', isSortable: true, width: '30%' },
  { name: 'Score', property: 'score', isSortable: true, width: '30%' },
  { name: 'Lesson Note', property: 'action', isSortable: false, width: '10%' },
];

const FormativeAssessmentHistoryCard = () => {
  const { studentProfileId } = useParams();

  const [query, setQuery] = useState<GetStudentFormativeAssessmentsQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    orderBy: StudentFormativeAssessmentsQuerySortKeyEnum.Date,
    sortDirection: OrderBy.Desc,
  });

  const [results, setResults] = useState<GenericPaginatedResponse<FormativeAssessmentTableRow>>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    data: [],
    count: 0,
  });

  const [showFormativeAssessmentNoteModal, setShowFormativeAssessmentNoteModal] = useState(false);
  const [selectedFormativeAssessment, setSelectedFormativeAssessment] = useState<FormativeAssessmentSearchResult>();

  const getFormativeAssessmentsRequest = useGetFormativeAssessments(studentProfileId!, query, {
    enabled: !!studentProfileId,
    onSuccess: (response) => {
      setResults({
        ...response,
        data: mapAssessmentResponseToTableRows(response.data),
      });
    },
  });

  const handleScore = (resource: FormativeAssessmentSearchResult): string => {
    const { formativeAssessmentType, accuracy, wordsCorrectPerMinute, numWords, numWordsCorrectly, numLettersCorrectly, numLetters } = resource;
    if (formativeAssessmentType === FormativeAssessmentType.TextReading) {
      return `${accuracy}% Accuracy, ${wordsCorrectPerMinute} WCPM`;
    } else if (formativeAssessmentType === FormativeAssessmentType.WordReading) {
      return `${numWordsCorrectly?.toString()}/${numWords?.toString()}`;
    } else {
      return `${numLettersCorrectly?.toString()}/${numLetters?.toString()}`;
    }
  };

  const mapAssessmentResponseToTableRows = (formativeAssessments: FormativeAssessmentSearchResult[]): FormativeAssessmentTableRow[] => {
    return formativeAssessments.map<FormativeAssessmentTableRow>((formativeAssessment) => {
      const lastUpdatedAtFormatted = DateTime.fromMillis(formativeAssessment.lastUpdatedAt).toFormat('cccc, LLLL dd, yyyy');
      return {
        date: lastUpdatedAtFormatted,
        resourceUsed: formativeAssessment.bookTitle,
        score: handleScore(formativeAssessment),
        action: (
          <Button variant="outlined" size="small" onClick={() => onShowLessonNotesForReviewId(formativeAssessment)}>
            View
          </Button>
        ),
      };
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize }));
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleSortBy = (selectedColumn: keyof FormativeAssessmentTableRow) => {
    const getQueryOrderByColumn = (): StudentFormativeAssessmentsQuerySortKeyEnum => {
      switch (selectedColumn) {
        case 'date':
          return StudentFormativeAssessmentsQuerySortKeyEnum.Date;
        case 'resourceUsed':
          return StudentFormativeAssessmentsQuerySortKeyEnum.ResourceUsed;
        case 'score':
          return StudentFormativeAssessmentsQuerySortKeyEnum.Score;
        default:
          return StudentFormativeAssessmentsQuerySortKeyEnum.Date;
      }
    };
    const queryOrderByColumn = getQueryOrderByColumn();
    setQuery((currentState) => ({
      ...currentState,
      orderBy: queryOrderByColumn,
      sortDirection:
        queryOrderByColumn !== currentState.orderBy ? OrderBy.Asc : currentState.sortDirection === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
    }));
  };

  function orderByColumn(): keyof FormativeAssessmentTableRow {
    switch (query.orderBy) {
      case StudentFormativeAssessmentsQuerySortKeyEnum.Date:
        return 'date';
      case StudentFormativeAssessmentsQuerySortKeyEnum.ResourceUsed:
        return 'resourceUsed';
      case StudentFormativeAssessmentsQuerySortKeyEnum.Score:
        return 'score';
      default:
        return 'date';
    }
  }

  const onShowLessonNotesForReviewId = (formativeAssessment: FormativeAssessmentSearchResult) => {
    setSelectedFormativeAssessment(formativeAssessment);
    setShowFormativeAssessmentNoteModal(true);
  };

  const onDismissAssessmentDetailsModal = () => {
    setShowFormativeAssessmentNoteModal(false);
  };

  return (
    <>
      <Card isLoading={getFormativeAssessmentsRequest.isFetching}>
        <Stack gap={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <HootTypography isPII={false} variant="titlemedium">
              Formative Assessment History
            </HootTypography>
          </Stack>
          <TableV2
            isPaginated
            isSortable
            data={results.data}
            headers={headers}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={results.count}
            page={query.page - 1}
            onPageChange={handleChangePage}
            onSortBy={handleSortBy}
            sortOrder={query.sortDirection ?? OrderBy.Asc}
            sortBy={orderByColumn()}
            emptyViewState={<ViewStateIllustration illustration={IllustrationEnum.EmptyState} />}
          />
        </Stack>
      </Card>
      <FormativeAssessmentsNoteModal
        open={showFormativeAssessmentNoteModal}
        lessonId={selectedFormativeAssessment?.lessonId}
        onClose={onDismissAssessmentDetailsModal}
      />
    </>
  );
};

export default FormativeAssessmentHistoryCard;
