import { isAndroid, isChrome, isEdge, isFirefox, isIOS, isMacOs, isSafari } from 'react-device-detect';
import { CHROME_ANDROID, CHROME_DESKTOP, CHROME_IOS, EDGE, FIREFOX, SAFARI_DESKTOP, SAFARI_IOS } from '@hoot/constants/audioVideoSupportLinks';

export function getAudioVideoSupportLink() {
  if (isSafari) {
    if (isIOS) return SAFARI_IOS;
    return SAFARI_DESKTOP;
  }

  if (isFirefox) return FIREFOX;

  if (isEdge) return EDGE;

  if (isChrome) {
    if (isAndroid) return CHROME_ANDROID;
    if (isIOS) return CHROME_IOS;
    if (isMacOs) return CHROME_DESKTOP;
  }

  return CHROME_DESKTOP;
}
