import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EventType } from '@hoot/events/eventType';
import { EndLessonMessage } from '@hoot/events/messages/end-lesson.message';
import { RootState } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import { useSocket } from '@hoot/ui/context/SocketContext';
import { timeFromUnixMillis } from '@hoot/utils/date';

interface Props {
  closeModal: () => void;
}

const DisconnectFromSession = (props: Props) => {
  const { closeModal } = props;
  const { socket } = useSocket();
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const currTime = DateTime.now().toLocal().toMillis();
  const navigate = useNavigate();

  if (!inLesson) {
    return null;
  }

  const isBeforeStartTime = currTime < inLesson!.startsAt;
  const isAfterStartTime = currTime > inLesson!.startsAt;
  const isBeforeEndTime = currTime < inLesson!.endsAt;
  const isAfterEndTime = currTime > inLesson!.endsAt;
  const isBetweenStartAndEnd = isAfterStartTime && isBeforeEndTime;

  const handleClose = () => {
    closeModal();
  };

  const handleDisconnectSession = () => {
    if (isAfterEndTime) {
      const EndLessonMessage: EndLessonMessage = {
        lessonId: inLesson!.lessonId,
        lessonNumber: inLesson!.lessonNumber,
      };
      console.log(`Emitting EndLesson via DisconnectFromSession`);
      socket.emit(EventType.EndLesson, EndLessonMessage);
    } else {
      // lesson is left via unmount of TeacherInLessonLayout
      navigate(routesDictionary.home.url);
    }

    closeModal();
  };

  const message = (): string => {
    if (isBeforeStartTime) {
      return `This lesson starts at ${timeFromUnixMillis(
        inLesson.startsAt,
      )}—for help, use the Live Chat tool in the App Menu. If you leave the lesson, you'll be able to rejoin later.`;
    }
    if (isBetweenStartAndEnd) {
      return `This lesson isn't over until ${timeFromUnixMillis(
        inLesson.endsAt,
      )}. For help, use the Live Chat tool in the App Menu. If you leave the lesson, you'll be able to rejoin later.`;
    }
    if (isAfterEndTime) {
      return `This lesson has been completed! If you leave this lesson, you will not be able to rejoin it.`;
    }
    return '';
  };

  return (
    <BasicAlertDialog
      paperProps={inLesson ? { sx: { marginLeft: '288px', marginBottom: '128px' } } : undefined} // TODO: Remove a some point, just a hack right now to get it to work
      show={true}
      onDismiss={handleClose}
      title="Leave Lesson?"
      content={message()}
      primaryAction={{
        onClick: handleDisconnectSession,
        label: 'Leave Lesson',
      }}
      secondaryAction={{
        onClick: handleClose,
        label: 'Stay in Lesson',
      }}
    />
  );
};

export default DisconnectFromSession;
