import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface DistrictRepStudentProfileSnippetResponse {
  id: string;
  number: number;
  name: string;
}

export default function useDistrictRepGetStudentProfileSnippet(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<DistrictRepStudentProfileSnippetResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetDistrictRepStudentProfileSnippet, studentProfileId],
    async () => {
      return (
        await axios.get<DistrictRepStudentProfileSnippetResponse>(
          `${config.apiUrl}/public/district-representative/student-profile/${studentProfileId}/snippet`,
        )
      ).data;
    },
    options,
  );
}
