import axios, { AxiosError } from 'axios';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { AvailabilityExceptionLessonImpact } from '@hoot/models/api/enums';
import { DayOfWeek } from '@hoot/utils/date';
import { QueryKey } from '../queryKeys';

export interface Timeblock {
  id: string;
  dayOfWeek: DayOfWeek;
  startTime: string;
  endTime: string;
}

export interface GetTeacherAvailabilityResponse {
  timezone: string;
  timeblocks: Timeblock[];
}

export interface ImpactedLesson {
  lessonId: string;
  status: AvailabilityExceptionLessonImpact;
}

export interface ImpactedShift {
  shiftId: string;
}

export interface CreateTeacherAvailabilityExceptionRequest {
  timeBlockId?: string;
  startsAt: number;
  endsAt: number;
  impactedLessons: ImpactedLesson[];
  impactedShifts: ImpactedShift[];
}

export interface GetTeacherAvailabilityExceptionConflictRequest {
  teacherAccountId: string;
  startsAt: number;
  endsAt: number;
}

export interface GetTeacherAvailabilityExceptionsResponse {
  id: string;
  teacherAccountId: string;
  startsAt: number;
  endsAt: number;
  createdAt: number;
  impacts: ImpactResponse[];
  isDraft: boolean;
}

export interface ImpactResponse {
  exceptionId: string;
  scheduledLessonId: string;
  status: AvailabilityExceptionLessonImpact;
}

export interface GetTeacherAvailabilityExceptionConflictsResponse {
  lessonId: string;
  lessonNumber: number;
  teacherId: string;
  teacherName: string;
  studentId: string;
  studentName: string;
  startsAt: number;
  endsAt: number;
  subject: string;
}

async function getTeacherAvailability() {
  const { data } = await axios.get<GetTeacherAvailabilityResponse>(`${config.apiUrl}/public/availability`);
  return data;
}

export default function useTeacherAvailabilityQuery(options?: Omit<UseQueryOptions<GetTeacherAvailabilityResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetTeacherAvailability], () => getTeacherAvailability(), options);
}

async function createTeacherAvailabilityException(request: CreateTeacherAvailabilityExceptionRequest): Promise<void> {
  const { data } = await axios.post<void>(`${config.apiUrl}/public/availability-exception`, request);
  return data;
}

export function useCreateTeacherAvailabilityException(
  options?: Omit<UseMutationOptions<void, AxiosError, CreateTeacherAvailabilityExceptionRequest>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation((request) => createTeacherAvailabilityException(request), options);
}

async function getTeacherAvailabilityExceptionConflicts(params: GetTeacherAvailabilityExceptionConflictRequest) {
  const { data } = await axios.get<GetTeacherAvailabilityExceptionConflictsResponse[]>(`${config.apiUrl}/public/availability-exception`, { params });
  return data;
}

export function useGetTeacherAvailabilityExceptionConflicts(
  params: GetTeacherAvailabilityExceptionConflictRequest,
  options?: Omit<UseQueryOptions<GetTeacherAvailabilityExceptionConflictsResponse[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetTeacherAvailabilityExceptionConflicts], () => getTeacherAvailabilityExceptionConflicts(params), options);
}

async function getTeacherAvailabilityExceptions() {
  const { data } = await axios.get<GetTeacherAvailabilityExceptionsResponse[]>(`${config.apiUrl}/public/availability-exception/exceptions`);
  return data;
}

export function useGetTeacherAvailabilityExceptions(
  options?: Omit<UseQueryOptions<GetTeacherAvailabilityExceptionsResponse[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetTeacherAvailabilityExceptions], () => getTeacherAvailabilityExceptions(), options);
}
