import { Fade, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PageZoom } from '@hoot/events/interfaces/page-zoom';
import { defaultPageZoom, getNextPageId, getPreviousPageId, useReaders } from '@hoot/redux/reducers/readingSlice';
import Page from '@hoot/ui/components/v2/core/Page';
import StudentReaderWithControls from '@hoot/ui/pages/v2/student/lesson/library/reader/StudentReaderWithControls';
import useStudentSoloReaderController from '@hoot/ui/pages/v2/student/library/reader/useStudentSoloReaderController';

const StudentInLessonSoloBookReaderPage = () => {
  const { bookId: bookIdPath } = useParams() as { bookId: string };

  const readerController = useStudentSoloReaderController({ bookId: bookIdPath });

  const { book: loadedBook, pageIndex } = useReaders().studentSpecificLibraryReader;

  // Just enforce single-page view in this context. There is no way for the student to change single vs double page mode.
  const isDoublePage = false;

  // There is no page-zoom feature in this context, so just resort to the default.
  const pageZoom: PageZoom = {
    ...defaultPageZoom,
  };

  const loadedBookId = loadedBook?.id;
  const readerBook = bookIdPath === loadedBookId ? loadedBook : null;

  useEffect(() => {
    // If the reader book hasn't been loaded, or if the loaded book's ID doesn't match the book ID in the current path,
    // then load the book.
    if (bookIdPath !== loadedBookId) {
      readerController.loadBook();
    }
  }, [bookIdPath, loadedBookId]);

  const onGoToNextPage = () => {
    const newPageId = getNextPageId(readerBook!, isDoublePage, pageIndex);
    readerController.onGoToPageWithId(newPageId);
  };

  const onGoToPreviousPage = () => {
    const newPageId = getPreviousPageId(readerBook!, isDoublePage, pageIndex);
    readerController.onGoToPageWithId(newPageId);
  };

  return (
    <Page
      pageTitle="Hoot Reading | Lesson"
      RootBoxProps={{
        sx: (theme) => ({
          height: '100%',
          position: 'relative',
          p: 2,
          [theme.breakpoints.down('xl')]: {
            p: 1,
          },
        }),
      }}
      sx={{ height: '100%' }}
    >
      {!readerBook && <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} />}
      {readerBook && (
        <Fade in>
          <Box
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <StudentReaderWithControls
              book={readerBook}
              pageIndex={pageIndex}
              pageZoom={pageZoom}
              isDoublePage={isDoublePage}
              previousPage={onGoToPreviousPage}
              nextPage={onGoToNextPage}
            />
          </Box>
        </Fade>
      )}
    </Page>
  );
};

export default StudentInLessonSoloBookReaderPage;
