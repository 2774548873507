import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import TeacherInLessonReaderAppBar from '@hoot/ui/pages/v2/teacher/lesson/library/reader/TeacherInLessonReaderAppBar';

const TeacherInLessonReaderAppBarLayout = () => {
  return (
    <Stack sx={{ height: '100%' }}>
      <TeacherInLessonReaderAppBar />
      <Stack
        position="relative"
        direction="column"
        justifyContent="center"
        flex={1}
        sx={{
          maxHeight: '100%',
          maxWidth: '100%',
          overflow: 'hidden',
        }}
      >
        <Outlet />
      </Stack>
    </Stack>
  );
};

export default TeacherInLessonReaderAppBarLayout;
