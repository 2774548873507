import { SvgIcon, SvgIconProps } from '@mui/material';

const ZoomInIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 6.50012V14.5001M14.5 10.5001H6.5M21 21.0001L17.5 17.5001M20 10.5001C20 15.7468 15.7467 20.0001 10.5 20.0001C5.25329 20.0001 1 15.7468 1 10.5001C1 5.25342 5.25329 1.00012 10.5 1.00012C15.7467 1.00012 20 5.25342 20 10.5001Z"
        stroke={props.htmlColor ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ZoomInIcon;
