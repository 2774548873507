import { SvgIcon, SvgIconProps } from '@mui/material';

/**
 * Setting the `fill` prop will change the fill colour
 */
const CollectionsFilled = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill={props.fill ?? 'none'} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.4"
        d="M4 3.00024H15C16.6569 3.00024 18 4.34339 18 6.00024C18 7.65709 16.6569 9.00024 15 9.00024H4V3.00024Z"
        fill={props.fill ?? '#000'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 3.00024C1.25 2.58603 1.58579 2.25024 2 2.25024H15C17.0711 2.25024 18.75 3.92917 18.75 6.00024C18.75 8.07131 17.0711 9.75024 15 9.75024H2C1.58579 9.75024 1.25 9.41445 1.25 9.00024C1.25 8.58603 1.58579 8.25024 2 8.25024H15C16.2426 8.25024 17.25 7.24288 17.25 6.00024C17.25 4.7576 16.2426 3.75024 15 3.75024H2C1.58579 3.75024 1.25 3.41445 1.25 3.00024Z"
        fill={props.fill ?? '#000'}
      />
      <path
        opacity="0.4"
        d="M4 15.0002H15C16.6569 15.0002 18 16.3433 18 18.0002C18 19.6571 16.6569 21.0002 15 21.0002H4V15.0002Z"
        fill={props.fill ?? '#000'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 15.0002C1.25 14.586 1.58579 14.2502 2 14.2502H15C17.0711 14.2502 18.75 15.9291 18.75 18.0002C18.75 20.0713 17.0711 21.7502 15 21.7502H2C1.58579 21.7502 1.25 21.4144 1.25 21.0002C1.25 20.586 1.58579 20.2502 2 20.2502H15C16.2426 20.2502 17.25 19.2428 17.25 18.0002C17.25 16.7576 16.2426 15.7502 15 15.7502H2C1.58579 15.7502 1.25 15.4144 1.25 15.0002Z"
        fill={props.fill ?? '#000'}
      />
      <path
        opacity="0.4"
        d="M20 9.00024H9C7.34315 9.00024 6 10.3433 6 12.0002C6 13.6571 7.34315 15.0002 9 15.0002H20V9.00024Z"
        fill={props.fill ?? '#000'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.75 9.00024C22.75 8.58603 22.4142 8.25024 22 8.25024H9C6.92893 8.25024 5.25 9.92917 5.25 12.0002C5.25 14.0713 6.92893 15.7502 9 15.7502H22C22.4142 15.7502 22.75 15.4144 22.75 15.0002C22.75 14.586 22.4142 14.2502 22 14.2502H9C7.75736 14.2502 6.75 13.2428 6.75 12.0002C6.75 10.7576 7.75736 9.75024 9 9.75024H22C22.4142 9.75024 22.75 9.41445 22.75 9.00024Z"
        fill={props.fill ?? '#000'}
      />
    </SvgIcon>
  );
};

export default CollectionsFilled;
