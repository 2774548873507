import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { NotificationMessage } from '@hoot/events/messages/notification-message';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/models/api/pagination';
import { RootState } from '@hoot/redux/store';

export interface NotificationsState {
  paginatedNotifications: GenericPaginatedResponse<NotificationMessage>;
  bookmarkedNotifications: NotificationMessage[];
}

const initialState: NotificationsState = {
  paginatedNotifications: {
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1,
    data: [],
    count: 0,
  },
  bookmarkedNotifications: [],
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setPaginatedNotifications: (state, action: PayloadAction<GenericPaginatedResponse<NotificationMessage>>) => {
      state.paginatedNotifications = action.payload;
    },
    setBookmarkedNotifications: (state, action: PayloadAction<NotificationMessage[]>) => {
      state.bookmarkedNotifications = action.payload;
    },
    bookmarkNotification: (state, action: PayloadAction<{ notificationId: string }>) => {
      const notifications = state.paginatedNotifications.data;
      const bookmarks = state.bookmarkedNotifications;

      const notificationIndex = notifications.findIndex((n) => n.id === action.payload.notificationId);

      if (notificationIndex >= 0) {
        notifications[notificationIndex].isBookmarked = true;

        bookmarks.push(notifications[notificationIndex]);
        bookmarks.sort((a, b) => (a.sentAt > b.sentAt ? -1 : 1));

        state.bookmarkedNotifications = bookmarks;
        state.paginatedNotifications = {
          ...state.paginatedNotifications,
          data: notifications,
        };
      }
    },
    removeBookmarkedNotification: (state, action: PayloadAction<{ notificationId: string }>) => {
      const notifications = state.paginatedNotifications.data;
      const bookmarks = state.bookmarkedNotifications;

      // Remove from bookmarks.
      const updatedBookmarks = bookmarks.filter((x) => {
        return x.id !== action.payload.notificationId;
      });
      updatedBookmarks.sort((a, b) => (a.sentAt > b.sentAt ? -1 : 1));

      state.bookmarkedNotifications = updatedBookmarks;
      state.paginatedNotifications = {
        ...state.paginatedNotifications,
        data: notifications,
      };

      // If the notification exists in the notifications slice, then un-bookmark it.
      const notificationIndex = notifications.findIndex((n) => n.id === action.payload.notificationId);

      if (notificationIndex >= 0) {
        notifications[notificationIndex].isBookmarked = false;
      }
    },
  },
});

export const useNotifications = () => {
  return useSelector<RootState, GenericPaginatedResponse<NotificationMessage>>((state) => state.notifications.paginatedNotifications);
};

export const useBookmarkedNotifications = () => {
  return useSelector<RootState, NotificationMessage[]>((state) => state.notifications.bookmarkedNotifications);
};

export const { setPaginatedNotifications, setBookmarkedNotifications, bookmarkNotification, removeBookmarkedNotification } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
