import { Box, Stack, Tooltip } from '@mui/material';
import { ReactNode } from 'react';
import { Button, ButtonProps } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { IconButton } from '@hoot/ui/components/v2/core/IconButton';
import { maxPageWidthPx } from '@hoot/ui/components/v2/core/PageLayout';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { getColors } from '@hoot/ui/theme/v2/util';

export interface BannerProps {
  message: string;
  backgroundColor: ColorPaletteOption;
  primaryAction: {
    label: string;
    onClick: () => void;
    props?: ButtonProps;
    toolTip?: string | ReactNode;
  };
  secondaryAction?: {
    label: string;
    onClick: () => void;
    props?: ButtonProps;
  };
  showClose?: boolean;
  onClose?: () => void;
}

const Banner = (props: BannerProps) => {
  const { onClose, message, backgroundColor, showClose, primaryAction, secondaryAction } = props;
  const colors = getColors(backgroundColor);

  return (
    <Box padding={1} sx={{ backgroundColor: colors.base }}>
      <Box sx={{ maxWidth: maxPageWidthPx, margin: 'auto' }}>
        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
            <HootTypography isPII={false} variant="titlesmall" color={colors.font}>
              {message}
            </HootTypography>
            <Stack direction="row" gap={1} pl={3} flexWrap="wrap" justifyContent="center">
              <Tooltip title={primaryAction.toolTip}>
                <div>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={primaryAction.onClick}
                    {...(primaryAction.props ?? {})}
                    color={primaryAction.props?.color}
                  >
                    {primaryAction.label}
                  </Button>
                </div>
              </Tooltip>
              {secondaryAction && (
                <Button variant="contained" size="small" onClick={secondaryAction.onClick} {...(secondaryAction.props ?? {})}>
                  {secondaryAction.label}
                </Button>
              )}
            </Stack>
          </Stack>
          {showClose ? (
            <IconButton onClick={onClose}>
              <Icon name="close" />
            </IconButton>
          ) : null}
        </Stack>
      </Box>
    </Box>
  );
};

export default Banner;
