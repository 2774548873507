import { ButtonBaseProps } from '@mui/material';
import React, { useState } from 'react';
import PlainButton from '@hoot/ui/components/v2/core/PlainButton';
import Lottie, { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';

type LottieMultiSelectButtonProps = ButtonBaseProps & {
  isSelected: boolean;
};

export function LottieMultiSelectButton(props: LottieMultiSelectButtonProps) {
  const { onClick, isSelected = false, children, ...buttonProps } = props;

  // Animations will play in two scenarios:
  // 1. You've clicked on the button.
  // 2. The default value was selected.
  const [playAnimation, setPlayAnimation] = useState(isSelected);

  const _onClick: LottieMultiSelectButtonProps['onClick'] = (event) => {
    setPlayAnimation(true);
    onClick?.(event);
  };

  return (
    <PlainButton
      onClick={_onClick}
      sx={{
        justifyContent: 'flex-start',
        gap: 3,
      }}
      {...buttonProps}
    >
      <Lottie
        lottieFile={LottieFile.Star}
        style={{ width: '64px', height: '64px' }}
        loop={false}
        play={playAnimation}
        direction={isSelected ? 1 : -1}
      />
      {children}
    </PlainButton>
  );
}
