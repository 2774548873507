import { Alert as MuiAlert, AlertProps as MuiAlertProps } from '@mui/material';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import BellSolidIcon from '../icons/BellSolidIcon';
import HootTypography from './HootTypography';

const HootAlert = (props: MuiAlertProps) => {
  return (
    <MuiAlert
      {...props}
      variant={props.variant ?? 'filled'}
      severity={props.severity}
      icon={<BellSolidIcon htmlColor="#FFF" />}
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '8px',
        boxShadow: hootTokens.elevation.elevation3,
        gap: 2,
        padding: 2,
        '& .MuiAlert-message': {
          padding: 0,
        },
        '& .MuiAlert-icon': {
          marginRight: 0,
        },
      }}
    >
      {props.title && (
        <HootTypography variant="titlelarge" color="#FFF" isPII={false}>
          {props.title}
        </HootTypography>
      )}
      <HootTypography isPII={false}>{props.children}</HootTypography>
    </MuiAlert>
  );
};

export default HootAlert;
