import { Box, Stack, useMediaQuery } from '@mui/material';
import { useMemo } from 'react';
import { isIOS } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import useProfile from '@hoot/hooks/useProfile';
import { Profile, UserProfileType } from '@hoot/models/api/user';
import { SessionStorageKey } from '@hoot/models/sessionStorageKey';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieButton from '@hoot/ui/components/v2/lottie/LottieButton';
import { useAudioVideoSettingsDialog } from '@hoot/ui/context/AudioVideoSettingsDialogContext';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { useFullscreen } from '@hoot/ui/context/FullscreenContext';
import UserAvatarCard from '@hoot/ui/pages/v2/no-profile/UserAvatarCard';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { zendeskCloseLiveChat } from '@hoot/utils/zendesk-methods';

const SelectProfilePage = () => {
  usePageTitle('Select Profile | Hoot Reading');

  const { assumeProfile, getUser, isAssumingProfileId, logout } = useAuth();
  const user = getUser();
  const navigate = useNavigate();
  const { profile } = useProfile();
  const currProfileExists = !!profile;
  const { onShow: showAudioVideoSettingsModal } = useAudioVideoSettingsDialog();
  const { toggleFullscreen } = useFullscreen();

  // From ticket SC-8634: the window width must be at least 720px wide in order to select a student or teacher profile
  // due layout dimension requirements while in a lesson.
  const isMobile = useMediaQuery('(max-width:720px)');

  const profilesDictionary = useMemo(() => {
    const activeProfiles = user.profiles.filter((x) => x.isEnabled);
    return {
      studentProfiles: activeProfiles?.filter((x) => x.type === UserProfileType.Student),
      // Special case:
      // Teachers can log into the app no matter what their "teacher" status is.
      // As long as the base user account is enabled, teachers can log into the app.
      teacherProfile: user.profiles?.find((x) => x.type === UserProfileType.Teacher),
      parentProfile: activeProfiles?.find((x) => x.type === UserProfileType.Parent),
      districtRep: activeProfiles?.find((x) => x.type === UserProfileType.DistrictRep),
    };
  }, [user.profiles]);

  const _assumeProfile = async (profileToAssume: Profile) => {
    assumeProfile(profileToAssume, async () => {
      const redirectUrl = window.sessionStorage.getItem(SessionStorageKey.LOGIN_REDIRECT_URL);
      navigate(redirectUrl ?? routesDictionary.home.url);

      if (profileToAssume.type === UserProfileType.Student && !isIOS) {
        await toggleFullscreen();
      }
    });
  };

  const assumeTeacherProfile = () => {
    _assumeProfile(profilesDictionary.teacherProfile!);
  };

  const assumeParentProfile = () => {
    _assumeProfile(profilesDictionary.parentProfile!);
  };

  const assumeDistrictRepProfile = () => {
    _assumeProfile(profilesDictionary.districtRep!);
  };

  const handleLogoutClick = () => {
    zendeskCloseLiveChat();
    logout();
  };

  return (
    <>
      {/* Black background layer. */}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '100vh',
          position: 'fixed',
          zIndex: '-1',
          backgroundColor: hootTokens.palette.black,
        }}
      >
        {/* Background image layer. */}
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            overflow: 'hidden',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            '@media (max-height: 700px)': {
              height: '800px',
              justifyContent: 'flex-start',
            },
            backgroundImage: 'url("/images/flf-background.webp")',
            gap: '24px',
            filter: 'blur(8px)',
          }}
        >
          {/* Translucence layer. */}
          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'fixed',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            }}
          />
        </Box>
      </Box>
      <PageLayout
        RootBoxProps={{
          px: 3,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        sx={{ height: '100%' }}
      >
        <Stack height={'100%'} justifyContent={'center'}>
          <Stack direction={'row'} width={'100%'} justifyContent={'space-between'}>
            <LottieButton
              tooltipLabel="Logout"
              data-hoot-canary-id="logoutButton"
              sx={{ width: '44px', height: '44px', p: 0, transform: 'scale(-1, 1)' }}
              lottieFile={LottieFile.Exit}
              aria-label="Cancel"
              variant="plain"
              onClick={handleLogoutClick}
            />
            {!isMobile && currProfileExists && (
              <LottieButton
                tooltipLabel="Settings"
                sx={{ width: '44px', height: '44px', p: 0 }}
                lottieFile={LottieFile.Gear}
                aria-label="Cancel"
                variant="plain"
                onClick={showAudioVideoSettingsModal}
              />
            )}
          </Stack>
          <Stack gap={3} alignItems="center" my={'auto'}>
            {!currProfileExists ? (
              <HootTypography isPII={false} variant="displaymedium" sx={{ color: hootTokens.palette.white, textAlign: 'center' }}>
                Choose your profile
              </HootTypography>
            ) : null}
            <Stack direction="row" alignItems={'baseline'} sx={{ flex: 1 }} gap={3} py={4}>
              {isMobile ? (
                <HootTypography
                  isPII={false}
                  variant="labellarge"
                  sx={{ color: hootTokens.palette.error['120'], maxWidth: '500px', textAlign: 'center' }}
                >
                  To ensure the best experience with Hoot, student profiles can only be accessed on a device with a larger screen.
                </HootTypography>
              ) : (profilesDictionary.studentProfiles?.length ?? 0) === 0 ? (
                <HootTypography
                  isPII={false}
                  variant="labellarge"
                  sx={{ color: hootTokens.palette.error['120'], maxWidth: '500px', textAlign: 'center' }}
                >
                  You do not have any enabled student profiles on your account. Please contact customer service at help@hootreading.com
                </HootTypography>
              ) : (
                profilesDictionary.studentProfiles!.map((p, index) => (
                  <Stack key={p.id} flex={1} alignItems="center" justifyContent={'center'} height={'100%'}>
                    <UserAvatarCard profile={p} index={index} onProfileSelected={() => _assumeProfile(p)} />
                  </Stack>
                ))
              )}
            </Stack>
            <Stack direction="column" gap={3} alignItems={'center'}>
              {profilesDictionary.parentProfile && (
                <Button
                  variant="outlined"
                  color={'neutral.200'}
                  sx={{ width: 'max-content' }}
                  onClick={assumeParentProfile}
                  isLoading={profilesDictionary.parentProfile.id === isAssumingProfileId}
                  data-hoot-canary-id="choose-parent-profile-btn"
                >
                  Go to Parent Profile
                </Button>
              )}
              {profilesDictionary.teacherProfile && (
                <Button
                  variant="outlined"
                  color={'neutral.200'}
                  sx={{ width: 'max-content' }}
                  onClick={assumeTeacherProfile}
                  isLoading={profilesDictionary.teacherProfile.id === isAssumingProfileId}
                >
                  Go to Teacher Profile
                </Button>
              )}
              {profilesDictionary.districtRep && (
                <Button
                  variant="outlined"
                  color={'neutral.200'}
                  sx={{ width: 'max-content' }}
                  onClick={assumeDistrictRepProfile}
                  isLoading={profilesDictionary.districtRep.id === isAssumingProfileId}
                >
                  Go to District Rep Profile
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      </PageLayout>
    </>
  );
};

export default SelectProfilePage;
