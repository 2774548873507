import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface SubmitInvoiceDto {
  invoiceId: string;
}

export default function useSubmitInvoice() {
  return useMutation<void, AxiosError, SubmitInvoiceDto>(async (request) => {
    return (await axios.post(`${config.apiUrl}/public/teacher-invoicing/submit-invoice`, request)).data;
  });
}
