import { SvgIcon, SvgIconProps } from '@mui/material';

const EraserIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5335 16.4665L22 8.99777L15.3326 2.3326L2 15.6652L6.70759 20.3728L8.00223 21.6674H22V20.3728H10.6272L14.5335 16.4665ZM8.30804 20.3728L3.60045 15.6652L8.66741 10.5982L13.7344 15.6652L9.02679 20.3728H8.30804Z"
      fill={props.htmlColor ?? 'currentColor'}
    />
  </SvgIcon>
);

export default EraserIcon;
