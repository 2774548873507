import React, { PropsWithChildren, useEffect, useState } from 'react';
import { EventType } from '@hoot/events/eventType';
import { CheckForActiveSessionResponseMessage } from '@hoot/events/messages/check-for-active-session.message';
import { JoinLessonMessage } from '@hoot/events/messages/join-lesson.message';
import useProfile from '@hoot/hooks/useProfile';
import { useAudioVideoSettingsDialog } from '@hoot/ui/context/AudioVideoSettingsDialogContext';
import AlreadyInLessonDialog from '../components/v2/dialogs/AlreadyInLessonDialog';
import { useSocket } from './SocketContext';

interface GetReadyLessonIdentifiers {
  lessonId: string;
  lessonNumber: number;
}

interface GetReadyV2DialogContextProviderProps {
  lessonToJoin?: GetReadyLessonIdentifiers;
  startGetReady: (lessonToJoin?: GetReadyLessonIdentifiers) => void;
  isJoiningLesson: boolean;
  startStudentGetReady: (lessonToJoin?: GetReadyLessonIdentifiers) => void;
  joinLesson: () => void;
  dismiss: () => void;
  dismissStudent: () => void;
}

/** Daily Context Provider */

const DailyGetReadyV2DialogContext = React.createContext<GetReadyV2DialogContextProviderProps>(undefined!);

const DailyGetReadyV2DialogContextProvider = (props: PropsWithChildren<any>) => {
  const { children } = props;

  const { onShow, onDismiss: onDismissAudioVideoSettingsDialog, showStudent, onShowStudent } = useAudioVideoSettingsDialog();
  const { socket } = useSocket();
  const { isStudent, isTeacher } = useProfile();

  const [lessonToJoin, setLessonToJoin] = useState<GetReadyLessonIdentifiers>();
  const [isJoiningLesson, setIsJoiningLesson] = useState<boolean>(false);
  const [alreadyInLessonId, setAlreadyInLessonId] = useState<string>();

  const isEnabled = isStudent || isTeacher;

  const onDismiss = () => {
    setIsJoiningLesson(false);
    onDismissAudioVideoSettingsDialog();
    if (!showStudent) {
      setLessonToJoin(undefined);
    }
  };

  const onDismissStudent = () => {
    setLessonToJoin(undefined);
  };

  const startGetReady = (lessonIdentifiers?: GetReadyLessonIdentifiers) => {
    setIsJoiningLesson(true);
    setLessonToJoin(lessonIdentifiers);
    onShow();
  };

  const startStudentGetReady = (lessonIdentifiers?: GetReadyLessonIdentifiers) => {
    setLessonToJoin(lessonIdentifiers);
    onShowStudent();
  };

  const joinLessonIfPresent = (_audioDeviceId?: string, _videoDeviceId?: string) => {
    if (lessonToJoin) {
      const joinLessonMessage: JoinLessonMessage = {
        lessonId: lessonToJoin.lessonId,
      };
      socket.emit(EventType.JoinLesson, joinLessonMessage);
    }
  };

  // Not a fan of handling this here in the GetReady logic.
  // Probably an In-Lesson-Context or something would be better in the future.
  useEffect(() => {
    if (isEnabled) {
      socket.on(EventType.CheckForActiveSession, (message: CheckForActiveSessionResponseMessage) => {
        if (message.isInLesson) {
          setAlreadyInLessonId(message.lessonId);
        } else {
          const joinLessonMessage: JoinLessonMessage = {
            lessonId: message.lessonId,
          };
          socket.emit(EventType.JoinLesson, joinLessonMessage);
        }
      });
    }
    return () => {
      socket.off(EventType.CheckForActiveSession);
    };
  }, [socket, isEnabled]);

  return (
    <DailyGetReadyV2DialogContext.Provider
      value={{
        lessonToJoin,
        startGetReady,
        isJoiningLesson,
        startStudentGetReady,
        dismiss: onDismiss,
        dismissStudent: onDismissStudent,
        joinLesson: joinLessonIfPresent,
      }}
    >
      {children}

      {alreadyInLessonId ? <AlreadyInLessonDialog closeModal={() => setAlreadyInLessonId(undefined)} lessonId={alreadyInLessonId} /> : null}
    </DailyGetReadyV2DialogContext.Provider>
  );
};

export const useGetReadyV2Dialog = () => {
  const context = React.useContext(DailyGetReadyV2DialogContext);

  if (context === undefined) {
    throw new Error(`useGetReadyV2Dialog must be used within a DailyGetReadyV2DialogContextProvider`);
  }
  return context;
};

export default DailyGetReadyV2DialogContextProvider;
