import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSearchDistrictRepresentativeSchoolsResponse, {
  DistrictRepresentativeSchoolsQuery,
} from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeSchools';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { RootState } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Page from '@hoot/ui/components/v2/core/Page';
import SearchTextField, { SearchTextFieldProps } from '@hoot/ui/components/v2/core/SearchTextField';
import { Snackbar } from '@hoot/ui/components/v2/core/Snackbar';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';

export interface SchoolRow {
  id: string;
  name: React.ReactNode;
}

export interface DistrictRepresentativeSchools {
  count: number;
  page: number;
  pageSize: number;
  schools: SchoolRow[];
}

const headers: HeaderData<SchoolRow>[] = [{ name: 'Name', property: 'name', isSortable: true }];

export function HFSSchools() {
  const districtRepresentativeId = useSelector((state: RootState) => state.profile.profile?.id);
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState<string>('');
  const [data, setData] = useState<DistrictRepresentativeSchools>({ count: 0, page: 1, pageSize: 10, schools: [] });
  const [showError, setShowError] = useState<string>('');
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const [query, setQuery] = useState<DistrictRepresentativeSchoolsQuery>({
    page: 1,
    pageSize: 10,
    sortBy: 'name',
    orderBy: OrderBy.Asc,
  });

  const { isLoading } = useSearchDistrictRepresentativeSchoolsResponse(districtRepresentativeId!, query, {
    enabled: !!districtRepresentativeId,
    keepPreviousData: true,
    onSuccess: (data) => {
      const schools: SchoolRow[] = data.schools.map((school) => ({
        id: school.id,
        name: (
          <p onClick={() => handleOpenSchool(school.id)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
            {school.name}
          </p>
        ),
      }));

      setData({
        schools: schools,
        count: data.count,
        page: query.page,
        pageSize: query.pageSize,
      });
    },
  });

  const handleOpenSchool = (schoolId: string) => {
    navigate(routesDictionary.schools.school.students.url(schoolId));
  };

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchText(text);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (text) => {
    setQuery((prev) => ({
      ...prev,
      name: text.length > 0 ? text : undefined,
    }));
  };

  const onClearSearchInput = () => {
    setSearchText('');
    setQuery((prev) => ({
      ...prev,
      name: undefined,
    }));
  };

  const handleClose = () => {
    setShowDialog(false);
    setShowError('');
  };

  return (
    <>
      <SubHeader title={{ label: 'Schools', isPII: false }} />

      <Page pageTitle="Hoot for Schools | Hoot Reading">
        <Stack gap={2}>
          <Card isLoading={isLoading}>
            <Stack gap={2}>
              <Stack gap={2}>
                <SearchTextField
                  label="School Search"
                  searchInput={searchText}
                  onSearchInputChanged={onSearchInputChanged}
                  onSearchInputDebounced={onSearchInputDebounced}
                  onClearButtonClicked={onClearSearchInput}
                />
                {query.name ? (
                  <HootTypography isPII={false} variant="titlesmall">
                    Showing {data.schools.length} results for "{query.name}"
                  </HootTypography>
                ) : null}
              </Stack>
              <TableV2
                allowRowsPerPage
                isPaginated
                isSortable
                count={data.count}
                data={data.schools}
                headers={headers}
                onPageChange={(event, page) => {
                  setQuery((prevState) => ({ ...prevState, page: page + 1 }));
                }}
                onRowsPerPageChange={(event) => {
                  setQuery((prevState) => ({ ...prevState, page: 1, pageSize: +event.target.value }));
                }}
                onSortBy={(val: any) => {
                  setQuery((prevState) => ({
                    ...prevState,
                    page: 1,
                    sortBy: val,
                    orderBy: prevState.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
                  }));
                }}
                page={data?.page ? data.page - 1 : 0}
                sortOrder={query.orderBy}
                sortBy={query.sortBy as any}
                rowsPerPage={query.pageSize}
              />
            </Stack>
          </Card>
        </Stack>
        <Snackbar
          variant="error"
          message={'An unexpected error occurred'}
          actionLabel="View Error"
          open={!!showError}
          onAction={() => setShowDialog(true)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
        <BasicAlertDialog
          show={showDialog}
          onDismiss={handleClose}
          title={'Error'}
          content={showError}
          primaryAction={{
            onClick: handleClose,
            label: 'OK',
          }}
        />
      </Page>
    </>
  );
}
