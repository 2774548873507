import { Control, useController } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { AllowedValue, SectionField, UnitSection } from '@hoot/hooks/api/assessment/useGetAssessmentUnit';
import { HootAssessmentFieldType } from '@hoot/models/api/enums/hoot-reading-assessment';
import { InstructionalFocus } from '@hoot/models/api/enums/instructional-focus';
import { Dropdown } from '@hoot/ui/pages/v2/teacher/hoot-reading-assessment/hra-fields/Dropdown';
import ATR from './ATR';
import { BookAutoSuggest } from './BookAutoSuggest';
import { Calculated } from './Calculated';
import { CheckBoxGroup } from './CheckBoxGroup';
import { NumberField } from './NumberField';
import RadioButtonGroup from './RadioButtonGroup';
import SecondsTimePicker from './SecondsTimePicker';
import Segment from './Segment';
import StepTest, { stepTestValidation } from './StepTest';

interface IFieldProps {
  sectionField: SectionField;
  name: string;
  control: Control;
  setValue: UseFormSetValue<any>;
  watching: Record<string, any>;
  section: UnitSection;
}

export const fieldCustomValidation = (val: any, formField: SectionField) => {
  if (formField.type === HootAssessmentFieldType.StepTest) {
    return stepTestValidation(val, formField);
  }
  return undefined;
};

export const Field = (props: IFieldProps) => {
  const { sectionField, name, control, watching, section, setValue } = props;

  const { field, fieldState } = useController({
    name,
    control,
    rules: {
      ...sectionField.validations,
      validate: (val) => fieldCustomValidation(val, sectionField),
    },
  });

  switch (sectionField.type) {
    case HootAssessmentFieldType.Segment:
      return <Segment sectionField={sectionField} section={section} />;
    case HootAssessmentFieldType.StepTest:
      return <StepTest sectionField={sectionField} field={field} fieldState={fieldState} />;
    case HootAssessmentFieldType.Attitude:
      return <ATR name={sectionField.name} onChange={field.onChange} label={sectionField.label} value={field.value} fieldState={fieldState} />;
    case HootAssessmentFieldType.Checkbox:
      return (
        <CheckBoxGroup
          name={sectionField.name}
          onChange={field.onChange}
          value={field.value || []}
          options={(sectionField.allowedValues as AllowedValue[]) || []}
          label={sectionField.label}
        />
      );
    case HootAssessmentFieldType.Radio:
      return (
        <RadioButtonGroup
          name={sectionField.name}
          label={sectionField.label}
          onChange={field.onChange}
          options={(sectionField.allowedValues as AllowedValue[]) || []}
          value={field.value}
          fieldState={fieldState}
        />
      );
    case HootAssessmentFieldType.DropdownSingle:
      return (
        <Dropdown
          name={name}
          onChange={field.onChange}
          options={(sectionField.allowedValues as AllowedValue[]) || []}
          value={field.value ?? ''}
          label={sectionField.label}
        />
      );
    case HootAssessmentFieldType.DropdownMulti:
      return (
        <Dropdown name={name} onChange={field.onChange} options={(sectionField.allowedValues as AllowedValue[]) || []} value={field.value} multiple />
      );
    case HootAssessmentFieldType.Number:
      return (
        <NumberField name={sectionField.name} label={sectionField.label} onChange={field.onChange} value={field.value} fieldState={fieldState} />
      );
    case HootAssessmentFieldType.TimePicker:
      return <SecondsTimePicker sectionField={sectionField} onChange={field.onChange} value={field.value ?? null} fieldState={fieldState} />;
    case HootAssessmentFieldType.BookSelector:
      return (
        <BookAutoSuggest
          name={sectionField.name}
          label={sectionField.label}
          watching={watching}
          control={control}
          setValue={setValue}
          value={field.value}
          instructionalFocusIds={[InstructionalFocus.AssessmentTextReading]}
        />
      );
    case HootAssessmentFieldType.Calculated:
      return (
        <Calculated
          calculated={sectionField.calculation}
          watching={watching}
          label={sectionField.label}
          onChange={field.onChange}
          value={field.value}
        />
      );
    case HootAssessmentFieldType.CalculatedPercentage:
      return (
        <Calculated
          calculated={sectionField.calculation}
          watching={watching}
          label={sectionField.label}
          onChange={field.onChange}
          value={field.value}
          percentage
        />
      );

    default:
      return null;
  }
};
