import { SvgIcon, SvgIconProps } from '@mui/material';

const UnlockedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8156 5.12554C14.2526 4.15216 13.202 3.5 12 3.5C10.205 3.5 8.74997 4.95507 8.74997 6.75V8H16C18.2091 8 20 9.79086 20 12V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V12C4 10.0472 5.39934 8.42127 7.24997 8.0702V6.75C7.24997 4.12665 9.37662 2 12 2C13.7591 2 15.2939 2.95663 16.114 4.37446C16.3215 4.733 16.1989 5.1918 15.8404 5.3992C15.4818 5.60661 15.023 5.48409 14.8156 5.12554ZM12 13.25C11.5858 13.25 11.25 13.5858 11.25 14V16C11.25 16.4142 11.5858 16.75 12 16.75C12.4142 16.75 12.75 16.4142 12.75 16V14C12.75 13.5858 12.4142 13.25 12 13.25Z"
        fill={props.htmlColor ?? 'currentColor'}
      />
    </SvgIcon>
  );
};

export default UnlockedIcon;
