import { Stack } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import TeacherStudentSpecificReaderAppBar from '@hoot/ui/pages/v2/teacher/my-students/student-details/library/reader/TeacherStudentSpecificReaderAppBar';

const TeacherStudentSpecificReaderAppBarLayout = () => {
  const { studentProfileId } = useParams() as { studentProfileId: string };
  return (
    <Stack sx={{ height: '100%', padding: 3 }}>
      <TeacherStudentSpecificReaderAppBar studentProfileId={studentProfileId} />
      <Stack
        position="relative"
        direction="column"
        justifyContent="center"
        flex={1}
        sx={{
          maxHeight: '100%',
          maxWidth: '100%',
          overflow: 'hidden',
        }}
      >
        <Outlet />
      </Stack>
    </Stack>
  );
};

export default TeacherStudentSpecificReaderAppBarLayout;
