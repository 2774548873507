import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface DebugState {
  messages: string[];
}

const initialState: DebugState = {
  messages: [],
};

export const debugSlice = createSlice({
  name: 'debug',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<string>) => {
      state.messages.push(action.payload);
    },
  },
});

export const { addMessage } = debugSlice.actions;

export default debugSlice.reducer;
