export enum NotificationTagType {
  EnrolmentId = 'ENROLMENT_ID',
  SchoolName = 'SCHOOL_NAME',
  SchoolId = 'SCHOOL_ID',
  StudentName = 'STUDENT_NAME',
  StudentId = 'STUDENT_ID',
  LessonId = 'LESSON_ID',
  LessonDate = 'LESSON_DATE',
  LessonSetId = 'LESSON_SET_ID',
}

export interface NotificationTagMessage {
  notificationId: string;
  type: NotificationTagType;
  tag: string;
}
