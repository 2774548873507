import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { StudentGrade } from '@hoot/models/api/student';

export interface FlfStudentProfileResponseDto {
  selectedStudentProfile: FlfStudentProfileResponse | undefined;
}

export interface FlfStudentProfileResponse {
  id: string;
  studentName: string;
  pronouns?: string;
  grade?: StudentGrade;
  additionalInformation?: string;
  isCoppaConsentGranted: boolean;
}

export default function useGetFlfStudentProfileRequest(options?: Omit<UseQueryOptions<FlfStudentProfileResponseDto>, 'queryKey' | 'queryFn'>) {
  return useQuery(
    [QueryKey.GetFlfStudentProfile],
    async () => {
      return (await axios.get(`${config.apiUrl}/free-lesson/v2/student-profile`)).data;
    },
    options,
  );
}
