import { Stack } from '@mui/material';
import { useState } from 'react';
import { featureFlags } from '@hoot/constants/featureFlags';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import useGetInstructionalUnitsFilterOptions from '@hoot/hooks/api/library/useGetInstructionalUnitsFilter';
import useGetReadingLevels from '@hoot/hooks/api/library/useGetLevels';
import { LibraryOrderByEnum, QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import { ReadingLevelType } from '@hoot/models/api/enums';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { ShelfType } from '@hoot/models/api/enums/shelf-type-enum';
import { BookType } from '@hoot/models/api/library';
import { Option } from '@hoot/models/formOption';
import { DEFAULT_PAGE } from '@hoot/redux/reducers/librarySlice';
import { Chip } from '@hoot/ui/components/v2/core/Chip';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { HeaderData } from '@hoot/ui/components/v2/core/Table';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';
import FormativeAssessmentBooksFilterDialog, {
  FormativeAssessmentBooksFilterForm,
} from '../formative-assessments/FormativeAssessmentBooksFilterDialog';
import BookPickerModal, { BookSelectorTableRow } from './BookPickerModal';

export interface FormativeAssessmentBookPickerProps {
  show: boolean;
  onDismiss: () => void;
  onApply: (selectedBook: BookSearch) => void;
  isLoading?: boolean;
  existingBookIds: string[];
  isFormativeAssessment?: boolean;
  isInstructionalFocus?: boolean;
  scheduledLessonId?: string;
}

const PAGE_SIZE = 5;

export enum FilterType {
  BookLevel = 'bookLevel',
  BookUnit = 'bookUnit',
  BookNumber = 'bookNumber',
}

const FormativeAssessmentBookPicker = (props: FormativeAssessmentBookPickerProps) => {
  const { show, onDismiss, onApply, isLoading = false, existingBookIds } = props;

  const libraryFilters: QueryLibraryV2 = {
    shelfType: ShelfType.AllBooks,
    page: DEFAULT_PAGE,
    pageSize: PAGE_SIZE,
    resourceType: BookType.FormativeAssessment,
    title: '',
    orderColumn: LibraryOrderByEnum.Title,
    orderBy: OrderBy.Asc,
  };

  const [libraryQuery, setLibraryQuery] = useState<QueryLibraryV2>(libraryFilters);

  const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
  const [filteredBookUnit, setFilteredBookUnit] = useState<string>();
  const [filteredBookLevel, setFilteredBookLevel] = useState<string>();
  const [filteredBookNumber, setFilteredBookNumber] = useState<string>();

  const { data } = useGetReadingLevels({ readingLevelType: [ReadingLevelType.Decodables], grade: undefined });
  const readingLevelsData: Option[] = data?.categorizedReadingLevels[0].listItems ?? [];

  const { data: instructionalUnitsData } = useGetInstructionalUnitsFilterOptions();

  const { isFeatureEnabled } = useFeatureFlags();
  const isAssessmentsV2Enabled = isFeatureEnabled(featureFlags.SC_11213_AssessmentSuite_v2);

  const onApplyFilter = (form: FormativeAssessmentBooksFilterForm) => {
    if (form.bookLevel) {
      const selectedBookLevel = readingLevelsData.find((l) => l.value === form.bookLevel);
      if (!selectedBookLevel) {
        return;
      }
      setFilteredBookLevel(selectedBookLevel.label);
    } else {
      setFilteredBookLevel(undefined);
    }

    if (form.bookUnit) {
      setFilteredBookUnit(form.bookUnit);
    } else {
      setFilteredBookUnit(undefined);
    }

    if (form.bookNumber) {
      setFilteredBookNumber(form.bookNumber);
    } else {
      setFilteredBookNumber(undefined);
    }

    setLibraryQuery((prevState) => {
      return {
        ...prevState,
        instructionalUnitIds: form.bookUnit ? [form.bookUnit] : undefined,
        readingLevelIds: form.bookLevel ? [form.bookLevel] : undefined,
        sequenceNumber: form.bookNumber ? Number(form.bookNumber) : undefined,
        page: 1,
      };
    });
  };

  const onClearFilter = (filterType: FilterType) => {
    if (filterType === FilterType.BookUnit) {
      setFilteredBookUnit(undefined);
    } else if (filterType === FilterType.BookLevel) {
      setFilteredBookLevel(undefined);
    } else {
      setFilteredBookNumber(undefined);
    }
    setLibraryQuery((prevState) => {
      return {
        ...prevState,
        instructionalUnitIds: filterType === FilterType.BookUnit ? undefined : prevState.instructionalUnitIds,
        readingLevelIds: filterType === FilterType.BookLevel ? undefined : prevState.readingLevelIds,
        sequenceNumber: filterType === FilterType.BookNumber ? undefined : prevState.sequenceNumber,
      };
    });
  };

  const headers: HeaderData<BookSelectorTableRow>[] = [
    { name: 'Cover', property: 'cover', isSortable: false },
    { name: 'Book Title', property: 'title', isSortable: true },
    isAssessmentsV2Enabled
      ? { name: 'Instructional Unit', property: 'instructionalUnit', isSortable: true }
      : { name: 'Book Level', property: 'bookLevel', isSortable: true },
    { name: 'Book Number', property: 'bookNumber', isSortable: true },
  ];

  const FilterChips = () => {
    const bookUnitLabel = instructionalUnitsData?.instructionalUnits.find((unit) => unit.value === filteredBookUnit)?.label;
    return (
      <Stack direction="row" gap={2}>
        <Chip label={'Unit: Formative Assessment'} />
        {filteredBookLevel ? (
          <Chip label={`Book Level: ${filteredBookLevel}`} onDelete={() => onClearFilter(FilterType.BookLevel)} deleteIcon={<Icon name="close" />} />
        ) : null}
        {filteredBookUnit ? (
          <Chip
            label={`Instructional Unit: ${bookUnitLabel}`}
            onDelete={() => onClearFilter(FilterType.BookUnit)}
            deleteIcon={<Icon name="close" />}
          />
        ) : null}
        {filteredBookNumber ? (
          <Chip
            label={`Book Number: ${filteredBookNumber}`}
            onDelete={() => onClearFilter(FilterType.BookNumber)}
            deleteIcon={<Icon name="close" />}
          />
        ) : null}
      </Stack>
    );
  };

  return (
    <BookPickerModal
      key={`formative-assessment-book-picker-${show}`}
      show={show}
      onApply={onApply}
      onDismiss={onDismiss}
      libraryQuery={libraryQuery}
      setLibraryQuery={setLibraryQuery}
      headers={headers}
      isLoading={isLoading}
      existingBookIds={existingBookIds}
      filter={
        <FormativeAssessmentBooksFilterDialog
          show={showFilterDialog}
          onDismiss={() => setShowFilterDialog(false)}
          onApply={onApplyFilter}
          bookUnitsOptions={instructionalUnitsData?.instructionalUnits ?? []}
          bookUnit={filteredBookUnit}
          bookLevel={filteredBookLevel}
          bookNumber={filteredBookNumber}
          readingLevelsData={readingLevelsData}
          isAssessmentsV2Enabled={isAssessmentsV2Enabled}
        />
      }
      setShowFilterDialog={setShowFilterDialog}
      filterChips={<FilterChips />}
    />
  );
};

export default FormativeAssessmentBookPicker;
