import { FormControl, FormHelperText, InputLabel, NativeSelect, NativeSelectProps, OutlinedInput } from '@mui/material';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { Icon } from './Icon';

export type SelectProps = NativeSelectProps & { label?: string; helperText?: string };

export function Select(props: SelectProps) {
  const { helperText, sx: rootSx = {}, ...rest } = props;

  return (
    <FormControl
      sx={{
        minWidth: '160px',
        '& .MuiFormLabel-root.Mui-focused': {
          color: hootTokens.palette.secondary[60],
        },
        '& .MuiSvgIcon-root': {
          color: hootTokens.palette.black,
          ...(props.disabled && {
            color: hootTokens.palette.neutral[140],
          }),
          ...(props.error && {
            color: hootTokens.palette.error[80],
          }),
        },
        '& .MuiOutlinedInput-root.Mui-focused fieldset': {
          borderColor: hootTokens.palette.secondary[60],
        },
        ...rootSx,
      }}
    >
      <InputLabel
        sx={{
          ...hootTokens.text.labelsmall,
          color: hootTokens.palette.black,
          ...(props.disabled && {
            color: hootTokens.palette.neutral[140],
          }),
          ...(props.error && {
            color: hootTokens.palette.error[80],
          }),
        }}
      >
        {props.label}
        <span style={{ color: hootTokens.palette.error['80'] }}>{rest.required ? '\u00A0*' : ''}</span>
      </InputLabel>
      <NativeSelect
        sx={{
          '& > select': {
            margin: 0,
            padding: '24px 16px 8px',
            color: props.error ? hootTokens.palette.error[80] : hootTokens.palette.black,
          },
        }}
        IconComponent={(props) => <Icon name="chevron" sx={{ rotate: '270deg' }} {...props} />}
        input={<OutlinedInput />}
        {...rest}
      />
      {helperText ? (
        <FormHelperText
          sx={{
            ...hootTokens.text.labelsmall,
            color: hootTokens.palette.black,
            ...(props.disabled && {
              color: hootTokens.palette.neutral[140],
            }),
            ...(props.error && {
              color: hootTokens.palette.error[80],
            }),
          }}
        >
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
