import { SvgIcon, SvgIconProps } from '@mui/material';

const LockedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 4.75C11.25 2.95507 9.79493 1.5 8 1.5C6.20507 1.5 4.75 2.95507 4.75 4.75V6H11.25V4.75ZM12.75 6.0702V4.75C12.75 2.12665 10.6234 0 8 0C5.37665 0 3.25 2.12665 3.25 4.75V6.0702C1.39935 6.42125 0 8.04721 0 10V16C0 18.2091 1.79086 20 4 20H12C14.2091 20 16 18.2091 16 16V10C16 8.04721 14.6006 6.42125 12.75 6.0702ZM8 11.25C7.58579 11.25 7.25 11.5858 7.25 12V14C7.25 14.4142 7.58579 14.75 8 14.75C8.41421 14.75 8.75 14.4142 8.75 14V12C8.75 11.5858 8.41421 11.25 8 11.25Z"
        fill={props.htmlColor ?? '#F78F1F'}
      />
    </SvgIcon>
  );
};

export default LockedIcon;
