import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { BookCollection } from '@hoot/hooks/api/library/useGetBookCollections';
import { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import CheckList, { BaseCheckListItem, CheckListItem } from '@hoot/ui/components/v2/CheckList';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { IconButton } from '@hoot/ui/components/v2/core/IconButton';

export interface Props {
  show: boolean;
  query: QueryLibraryV2;
  onDismiss: () => void;
  dispatchCollectionsFilter: (bookCollections?: string[]) => void;
  bookCollectionsList: BookCollection[];
}

const BookCollectionsFilterModal = (props: Props) => {
  const { show, query, onDismiss, dispatchCollectionsFilter, bookCollectionsList } = props;

  const [selectedBookCollections, setSelectedBookCollections] = useState<string[]>(query.bookCollectionIds ?? []);
  const filterCount = selectedBookCollections.length - (query.bookCollectionIds ?? []).length;

  const bookCollectionOptions: BaseCheckListItem[] = bookCollectionsList.map((c) => ({ value: c.id, label: c.name }));

  useEffect(() => {
    setSelectedBookCollections(query.bookCollectionIds ?? []);
  }, [query.bookCollectionIds]);

  const handleApplyFilter = () => {
    dispatchCollectionsFilter(selectedBookCollections);
    onDismiss();
  };

  const handleClearClick = () => {
    setSelectedBookCollections([]);
  };

  const handleCloseClick = () => {
    onDismiss();
    setSelectedBookCollections(query.bookCollectionIds ?? []);
  };

  const toggleChecklistOption = (option: CheckListItem) => {
    if ((selectedBookCollections as string[]).includes(option.value)) {
      setSelectedBookCollections((prevValues) => [...prevValues.filter((pv) => pv !== option.value)]);
    } else {
      setSelectedBookCollections((prevValues) => [...prevValues, option.value]);
    }
  };

  return (
    <Dialog open={show} onClose={handleCloseClick} fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Select Book Collection
        <IconButton onClick={handleCloseClick}>
          <Icon name="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" gap={2} mb={2}>
          <CheckList
            title={''}
            items={bookCollectionOptions}
            toggleOption={toggleChecklistOption}
            selectedValues={selectedBookCollections as string[]}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClearClick} variant="outlined" color="error.80">
          Clear
        </Button>
        <Button onClick={handleCloseClick} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleApplyFilter} variant="contained">
          {`Apply ${filterCount > 0 ? `(${filterCount})` : ``}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookCollectionsFilterModal;
