import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useState } from 'react';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Page from '@hoot/ui/components/v2/core/Page';
import ChangePasswordDialog from '@hoot/ui/components/v2/dialogs/ChangePasswordDialog';
import { useCookieConsent } from '@hoot/ui/context/CookieConsentContext';

const ParentAccountSettingsPage = () => {
  const { showModal: onShowCookieConsentModal } = useCookieConsent();

  const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);

  const onShowChangePasswordDialog = () => {
    setShowChangePasswordDialog(true);
  };

  const onDismissChangePasswordDialog = () => {
    setShowChangePasswordDialog(false);
  };

  return (
    <>
      <SubHeader title={{ label: 'Account Settings', isPII: false }} />

      <Page pageTitle="Settings | Hoot Reading">
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 8 }}>
            <Card title="Cookies Preferences">
              <Stack gap={2}>
                <HootTypography isPII={false} variant="bodylarge">
                  We use cookies and other identifiers to ensure the functionality of our app and offer the best possible in-app experience. You can
                  choose for each category to opt in or out and can view your settings. You can learn more about how we use cookies and other
                  identifiers in the Cookies section of our Web App Privacy Policy.
                </HootTypography>
                <Button
                  variant="contained"
                  onClick={onShowCookieConsentModal}
                  sx={(theme) => ({
                    [theme.breakpoints.up('md')]: {
                      width: 'max-content',
                    },
                  })}
                >
                  Manage Cookies
                </Button>
              </Stack>
            </Card>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Card title="Password">
              <Button variant="contained" fullWidth color={'error.80'} onClick={onShowChangePasswordDialog}>
                Change Password
              </Button>
            </Card>
          </Grid>
        </Grid>
        <ChangePasswordDialog show={showChangePasswordDialog} onClose={onDismissChangePasswordDialog} />
      </Page>
    </>
  );
};

export default ParentAccountSettingsPage;
