import { Paper, Skeleton, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { NotificationMessage } from '@hoot/events/messages/notification-message';
import useGetBookmarkedNotifications from '@hoot/hooks/api/notification/useGetBookmarkedNotifications';
import { error } from '@hoot/redux/reducers/alertSlice';
import { setBookmarkedNotifications, useBookmarkedNotifications } from '@hoot/redux/reducers/notificationsSlice';
import { useAppDispatch } from '@hoot/redux/store';
import BookmarkedNotification from '@hoot/ui/components/v2/BookmarkedNotification';
import GrowList from '@hoot/ui/components/v2/GrowList';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import Card from '@hoot/ui/components/v2/core/Card';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const BookmarkedNotifications = () => {
  const dispatch = useAppDispatch();

  const bookmarkedNotifications = useBookmarkedNotifications();

  const getBookmarkedNotificationsRequest = useGetBookmarkedNotifications({
    enabled: true,
    retry: false,
    onSuccess: (data) => {
      dispatch(setBookmarkedNotifications(data));
    },
    onError: (err) => {
      console.error(err);
      dispatch(error('An error occurred while fetching bookmarks.'));
    },
  });

  const viewState = useMemo<ViewStateEnum>(() => {
    // Only show skeleton items while fetching if there isn't already some real data to show.
    if (getBookmarkedNotificationsRequest.isFetching && bookmarkedNotifications.length === 0) {
      return ViewStateEnum.Loading;
    } else if (getBookmarkedNotificationsRequest.isError) {
      return ViewStateEnum.Error;
    } else if (bookmarkedNotifications.length === 0) {
      return ViewStateEnum.EmptyState;
    } else {
      return ViewStateEnum.Results;
    }
  }, [getBookmarkedNotificationsRequest.isFetching, getBookmarkedNotificationsRequest.isError, bookmarkedNotifications.length]);

  return (
    <Card title="Bookmarks" isLoading={getBookmarkedNotificationsRequest.isFetching}>
      <Stack>
        <ViewState
          state={viewState}
          loadingContent={<SkeletonItems />}
          EmptyStateIllustrationProps={{
            title: 'Your Bookmark card is empty',
            subtitle: 'All saved notifications will be listed here',
          }}
        >
          <GrowList<NotificationMessage>
            StackProps={{ gap: 2 }}
            items={bookmarkedNotifications}
            getKey={(x) => x.id}
            renderItem={(notification) => <BookmarkedNotification notification={notification} />}
          />
        </ViewState>
      </Stack>
    </Card>
  );
};

const SkeletonItems = () => (
  <Stack gap={2}>
    {[...Array(3)].map((_, index) => (
      <Paper key={`skeleton-${index}`} sx={{ padding: 2 }}>
        <Skeleton variant="text" sx={{ maxWidth: '140px', ...hootTokens.text.bodylarge }} />
        <Skeleton variant="text" sx={{ ...hootTokens.text.bodysmall, mt: 2 }} />
        <Skeleton variant="text" sx={{ maxWidth: '140px', ...hootTokens.text.bodysmall }} />
        <Stack direction="row" gap={2} mt={2}>
          <Skeleton variant="rounded" sx={{ maxWidth: '62px', width: '100%' }} />
          <Skeleton variant="rounded" sx={{ maxWidth: '200px', width: '100%' }} />
        </Stack>
        <Skeleton variant="text" sx={{ maxWidth: '50px', ...hootTokens.text.bodysmall, mt: 2 }} />
      </Paper>
    ))}
  </Stack>
);

export default BookmarkedNotifications;
