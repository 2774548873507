import { SvgIcon, SvgIconProps } from '@mui/material';

const PhoneIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_5049_7022)">
        <path
          d="M18.5 16.0002V14.3543C18.5 13.5365 18.0021 12.8011 17.2428 12.4974L15.2086 11.6837C14.2429 11.2974 13.1422 11.7159 12.677 12.6462L12.5 13.0002C12.5 13.0002 10 12.5002 8 10.5002C6 8.50024 5.5 6.00024 5.5 6.00024L5.85402 5.82324C6.78438 5.35805 7.20285 4.25738 6.81654 3.2916L6.00289 1.25746C5.69916 0.498147 4.96374 0.000244141 4.14593 0.000244141H2.5C1.39543 0.000244141 0.5 0.895675 0.5 2.00024C0.5 10.8368 7.66344 18.0002 16.5 18.0002C17.6046 18.0002 18.5 17.1048 18.5 16.0002Z"
          fill={props.htmlColor ?? 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_5049_7022">
          <rect width="18" height="18" fill={props.htmlColor ?? 'white'} transform="translate(0.5 0.000244141)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default PhoneIcon;
