import { SvgIcon, SvgIconProps } from '@mui/material';

const FilterDetailedIcon = (props: SvgIconProps) => {
  const { ...rest } = props;
  return (
    <SvgIcon width="18" height="22" viewBox="0 0 18 22" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M17 7.32624C17 8.97193 13.4183 10.306 9 10.306C4.58172 10.306 1 8.97193 1 7.32624M17 7.32624C17 11.0778 14.9318 14.2897 12 15.6158V18.0176C12 18.7701 11.572 19.458 10.8944 19.7945L8.89443 20.7877C7.56462 21.4481 6 20.4877 6 19.0109V15.6158C3.06817 14.2897 1 11.0778 1 7.32624M17 7.32624C17 6.38522 15.8289 5.54609 14 5M1 7.32624C1 6.38522 2.17107 5.54609 4 5M11 4V6M7 6V7M7 1V3"
        fill={props.htmlColor ?? 'none'}
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default FilterDetailedIcon;
