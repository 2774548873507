import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { zIndexes } from '@hoot/constants/zIndices';
import { updateSendCelebration, useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { CelebrationType } from '../dialogs/CelebrationsDialog';
import Lottie, { LottieFile } from '../lottie/Lottie';

const CelebrationsDisplay = () => {
  const { sendCelebration } = useActiveLessonState();
  const [transitionIn, setTransitionIn] = useState<boolean>(!!sendCelebration);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!sendCelebration) {
      setTransitionIn(true);
    }
  }, [sendCelebration]);

  const endCelebration = () => {
    setTransitionIn(false);
  };

  const celebrationLottieFile = () => {
    switch (sendCelebration) {
      case CelebrationType.Confetti:
        return LottieFile.Confetti;
      case CelebrationType.Star:
        return LottieFile.CelebrationStar;
      case CelebrationType.Thumb:
        return LottieFile.ThumbsUp;
      default:
        return null;
    }
  };

  const handleAnimationEnd = () => {
    dispatch(updateSendCelebration(null));
  };
  return (
    <Box
      sx={{
        display: sendCelebration ? 'flex' : 'none',
        position: 'fixed',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        pointerEvents: 'none',
        zIndex: zIndexes.inLessonCelebrations,
      }}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        width={'100%'}
        height={'100%'}
        onAnimationEnd={transitionIn ? undefined : () => handleAnimationEnd()}
        sx={{
          animation: transitionIn ? `800ms transitionIn linear` : `800ms transitionOut linear`,
          '@keyframes transitionIn': {
            '0%': { transform: 'scale(0)' },
            '50%': { transform: 'scale(.5)' },
            '100%': { transform: 'scale(1)' },
          },
          '@keyframes transitionOut': {
            '0%': { transform: 'scale(1)' },
            '50%': { transform: 'scale(.5)' },
            '100%': { transform: 'scale(0)' },
          },
        }}
      >
        {!!celebrationLottieFile() ? (
          <Lottie
            lottieFile={celebrationLottieFile() as LottieFile}
            loop
            play={true}
            onLoopComplete={endCelebration}
            style={{
              width: '100%',
              height: '100%',
              position: 'relative',
            }}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default CelebrationsDisplay;
