export const zIndexes = {
  floatingDialog: 1300,
  theirMagicFinger: 19000,
  myMagicFinger: 20000,
  // The library filter drawer needs to render above all dialogs (MUI default z-index of 1300) since it can be opened from them.
  libraryFilterDrawer: 1301,
  // These modals are launched from the context of the library filter drawer, so must be rendered above it.
  libraryFilterDrawerOptionsModal: 1302,
  video: 1600,
  // This is one level less than the MUI <Dialog> z-index.
  dialogBackdrop: 1299,
  studentJoinLessonReminder: 1000,
  bookPageOnReader: 1000,
  inLessonCelebrations: 1001, //this has to be higher than the bookPageOnReader
};
