import { AlertColor } from '@mui/material';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AlertState {
  banner: BannerMessage | undefined;
  toast: ToastMessage | undefined;
}

const initialState: AlertState = {
  banner: undefined,
  toast: undefined,
};

enum AlertBannerSeverity {
  Success = 'success',
  Info = 'info',
  Error = 'error',
}

interface BannerMessage {
  message?: string;
  severity: AlertBannerSeverity;
}

interface ToastMessage {
  message?: string;
  severity: AlertColor;
  isIndefinite?: boolean;
}

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    createBanner: (state, action: PayloadAction<BannerMessage>) => {
      return {
        ...state,
        banner: { ...action.payload },
      };
    },
    clearBanner: (state) => {
      return {
        ...state,
        banner: undefined,
      };
    },
    createToast: (state, action: PayloadAction<ToastMessage>) => {
      return {
        ...state,
        toast: { ...action.payload },
      };
    },
    clearToast: (state) => {
      return {
        ...state,
        toast: undefined,
      };
    },
    info: (state, action: PayloadAction<string | undefined>) => {
      return {
        ...state,
        toast: {
          severity: 'info',
          message: action.payload,
        },
      };
    },
    warning: (state, action: PayloadAction<string | undefined>) => {
      return {
        ...state,
        toast: {
          severity: 'warning',
          message: action.payload,
        },
      };
    },
    error: (state, action: PayloadAction<string | undefined>) => {
      return {
        ...state,
        toast: {
          severity: 'error',
          message: action.payload,
        },
      };
    },
  },
});

export const { createBanner, clearBanner, createToast, clearToast } = alertSlice.actions;

// NOTE: Was going to replace all callers of these with `createToast` but there are a ton of usages. This is just a workaround.
export const success = (message: string) => {
  return createToast({ message, severity: 'success' });
};
export const info = (message: string) => {
  return createToast({ message, severity: 'info' });
};
export const warning = (message: string) => {
  return createToast({ message, severity: 'warning' });
};
export const error = (message: string) => {
  return createToast({ message, severity: 'error' });
};

export default alertSlice.reducer;
