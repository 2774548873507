import { DailyVideo, useDaily, useDailyError, useDevices, useLocalSessionId, useMeetingState, useParticipantProperty } from '@daily-co/daily-react';
import { Box, Dialog, DialogContent, DialogTitle, Skeleton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import PlainIconButton from '@hoot/ui/components/v2/core/PlainIconButton';
import { useAudioVideoSettingsDialog } from '@hoot/ui/context/AudioVideoSettingsDialogContext';
import { useGetReadyV2Dialog } from '@hoot/ui/context/GetReadyV2DialogProvider';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { LottieFile } from '../../../lottie/Lottie';
import LottieButton from '../../../lottie/LottieButton';
import DailyDevicesNotFound from './DailyDevicesNotFound';

interface Props {
  isOpen: boolean;
}

const DailyStudentGetReadyDialog = (props: Props) => {
  const { isOpen } = props;

  const { joinLesson, lessonToJoin } = useGetReadyV2Dialog();
  const { onDismissStudent, onShow } = useAudioVideoSettingsDialog();

  const handleApplyStudent = () => {
    setIsLoading(true);
    joinLesson();
  };

  const dailyCallObj = useDaily();
  const { currentCam, micState, camState, cameras, microphones } = useDevices();

  const { meetingError } = useDailyError();

  const localSessionId = useLocalSessionId();
  const localParticipantTracks = useParticipantProperty(localSessionId, 'tracks');
  const meetingState = useMeetingState();

  const { inLesson } = useActiveLessonState();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const videoIsPlayable = localParticipantTracks?.video?.state === 'playable';
  const isGetReadyDialog = !!lessonToJoin;

  useEffect(() => {
    if (inLesson) {
      setIsLoading(false);
    }
  }, [inLesson]);

  useEffect(() => {
    if (meetingError) {
      setIsLoading(false);
    }
  }, [meetingError]);

  useEffect(() => {
    if (!dailyCallObj) {
      console.log('[DailyStudentGetReadyDialog]: No call object, bail');
      return;
    }

    if (meetingState === 'new' || meetingState === 'left-meeting') {
      dailyCallObj
        .startCamera({})
        .then(() => {
          console.log('Called startCamera()');
          dailyCallObj.setLocalAudio(true);
          dailyCallObj.setLocalVideo(true);
        })
        .catch((err) => console.error('Failed to start Camera()', err));
    }
  }, [dailyCallObj, meetingState]);

  const permissionsGranted = camState === 'granted' && micState === 'granted';

  const handleDismiss = () => {
    if (isGetReadyDialog && dailyCallObj) {
      dailyCallObj.leave();
    }
    setIsLoading(true);
    onDismissStudent();
  };

  const handleShowAVSettingClick = () => {
    onShow();
  };

  if (permissionsGranted && (cameras.length === 0 || microphones.length === 0)) {
    return <DailyDevicesNotFound />;
  }

  return (
    <Dialog open={isOpen} onClose={handleDismiss} TransitionProps={{ onExited: handleDismiss }}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '24px 28px 32px 48px',
          ...hootTokens.text.displaysmall,
        }}
      >
        <span>Get Ready</span>
        <LottieButton
          tooltipLabel="Cancel"
          lottieFile={LottieFile.ErrorCross}
          aria-label={'close'}
          sx={{ boxShadow: undefined, background: 'primary.190', padding: 0, margin: 0 }}
          onClick={handleDismiss}
          lottieWidth={80}
          lottieHeight={80}
        />
      </DialogTitle>
      <DialogContent>
        <Stack padding={'0px 32px 16px 32px'} gap={'10px'}>
          {!!currentCam && localSessionId && videoIsPlayable ? (
            <Box
              sx={{
                background: hootTokens.palette.black,
                padding: 2,
                borderRadius: '8px',
                width: '320px',
                height: '210px',
                position: 'relative',
              }}
            >
              <DailyVideo
                sessionId={localSessionId}
                type={'video'}
                automirror={true}
                autoPlay={true}
                muted={true}
                width="288px"
                height="178px"
                fit="cover"
                style={{
                  borderRadius: '8px',
                }}
              />
            </Box>
          ) : (
            <Skeleton variant="rounded" width={320} height={210} sx={{ borderRadius: '8px' }} />
          )}
          <Stack gap={6} sx={{ paddingBottom: 4 }}>
            <PlainIconButton onClick={handleShowAVSettingClick} icon="gear" tooltipLabel="Device settings" sx={{ width: '100%' }} />
            <LottieButton
              id={`student-join-lesson-${lessonToJoin?.lessonNumber}`}
              fullWidth
              lottieFile={LottieFile.Checkmark}
              tooltipLabel="Join lesson"
              aria-label="Join lesson"
              lottieWidth={80}
              lottieHeight={80}
              variant="plain"
              sx={{
                backgroundColor: hootTokens.palette.success[190],
                '&:hover': {
                  backgroundColor: hootTokens.palette.success[180],
                },
              }}
              onClick={handleApplyStudent}
              isLoading={isLoading}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DailyStudentGetReadyDialog;
