import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../config';
import { QueryKey } from './queryKeys';

export interface FeatureFlags {
  features: FeatureFlag[];
}

export interface FeatureFlag {
  name: string;
  isEnabled: boolean;
}

export async function fetchFeatureFlags(): Promise<FeatureFlags> {
  const { data } = await axios.get<FeatureFlags>(`${config.apiUrl}/feature-flags`);
  return data;
}

export default function useFetchFeatureFlags(options?: Omit<UseQueryOptions<FeatureFlags>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.FeatureFlags], () => fetchFeatureFlags(), options);
}
