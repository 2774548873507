import { zIndexes } from '@hoot/constants/zIndices';
import DetailedChipGroup, { DetailedChipGroupProps } from '@hoot/ui/components/v2/core/DetailedChipGroup';

type LibraryFilterChipGroupProps = DetailedChipGroupProps;

const LibraryFilterChipGroup = (props: LibraryFilterChipGroupProps) => {
  const { dialogSx = {}, ...detailedChipGroupProps } = props;
  return (
    <DetailedChipGroup
      dialogSx={{
        // These dialogs need to be overlaid on top of the library filter drawer from where these dialogs are opened.
        zIndex: zIndexes.libraryFilterDrawerOptionsModal,
        ...dialogSx,
      }}
      {...detailedChipGroupProps}
    />
  );
};

export default LibraryFilterChipGroup;
