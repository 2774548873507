import { SvgIcon, SvgIconProps } from '@mui/material';

const DragIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M15 18L12 21M12 21L9 18M12 21V3M12 3L15 6M12 3L9 6"
        stroke={props.htmlColor ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 15L3 12M3 12L6 9M3 12H21M21 12L18 15M21 12L18 9"
        stroke={props.htmlColor ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default DragIcon;
