import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { LocationTypeEnum } from '@hoot/models/api/enums/location-type-enum';
import { StudentGrade, StudentStatusEnum } from '@hoot/models/api/student';

export interface DistrictRepresentativeStudentDistrictResponse {
  id: string;
  name: string;
  friendlyId: string;
  type?: LocationTypeEnum;
}

export interface DistrictRepresentativeStudentLocationResponse {
  id: string;
  name: string;
  friendlyId: string;
  type: LocationTypeEnum;
  district?: DistrictRepresentativeStudentDistrictResponse;
}

export interface DistrictRepGetStudentProfileResponse {
  id: string;
  name: string;
  number: number;
  status: StudentStatusEnum;
  grade: StudentGrade | null;
  pronouns: string | null;
  hfsLocations: DistrictRepresentativeStudentLocationResponse[] | null;
}

async function getStudentProfile(studentProfileId: string): Promise<DistrictRepGetStudentProfileResponse> {
  const { data } = await axios.get<DistrictRepGetStudentProfileResponse>(
    `${config.apiUrl}/public/district-representative/student-profile/${studentProfileId}`,
  );
  return data;
}

export default function useDistrictRepresentativeGetStudent(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<DistrictRepGetStudentProfileResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetStudentForDistrictRep, studentProfileId], () => getStudentProfile(studentProfileId), options);
}
