import { Box, Stack, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import axios, { AxiosError } from 'axios';
import { useEffect } from 'react';
import { isIOS } from 'react-device-detect';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { VALID_EMAIL_REGEX } from '@hoot/constants/constants';
import useFlfSignupRequest from '@hoot/hooks/api/free-lesson-flow/useFlfSignupRequest';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { googleAnalytics } from '@hoot/telemetry/google-analytics';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import Card from '../../../../components/v2/core/Card';
import TextField from '../../../../components/v2/core/TextField';
import theme from '../../../../theme/v2';

interface Form {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phone: string;
  agreeToReceiveEmail: boolean;
  agreeToTerms: boolean;
}

export function FreeLessonSignUp() {
  usePageTitle('Free Lesson Signup | Hoot Reading');

  useEffect(() => {
    googleAnalytics.startedFreeLessonFlow();
  }, []);

  const { isAuthenticated, logout } = useAuth();

  if (isAuthenticated) {
    logout(false, false);
  }

  const [searchParams] = useSearchParams();
  const accountFromSearchParams = searchParams.get('account') ?? '';
  const emailFromSearchParams = searchParams.get('email') ?? '';
  const referralCodeFromSearchParams = searchParams.get('referralCode') ?? '';

  const { control, handleSubmit } = useForm<Form>({
    defaultValues: {
      firstName: '',
      lastName: '',
      emailAddress: emailFromSearchParams,
      phone: '',
      agreeToReceiveEmail: true,
      agreeToTerms: false,
    },
  });

  const signupRequest = useFlfSignupRequest();

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const onSubmit = async (data: Form) => {
    const { emailAddress, firstName, lastName, phone, agreeToReceiveEmail, agreeToTerms } = data;

    await signupRequest.mutate(
      {
        email: emailAddress,
        firstName,
        lastName,
        phone,
        agreeToMarketing: agreeToReceiveEmail,
        agreeToPrivacyPolicy: agreeToTerms,
      },
      {
        onSuccess: () => {
          navigate({
            pathname: routesDictionary.freeLesson.registration.url,
            search: createSearchParams({
              name: `${firstName} ${lastName}`,
              email: emailAddress,
              account: accountFromSearchParams,
              referralCode: referralCodeFromSearchParams,
            }).toString(),
          });
          googleAnalytics.completedFLFStep1();
        },
        onError: (err) => {
          // If the API returns a conflict error, then we know an account with the provided email already exists.
          // Redirect the user to the login page in this case.
          if (axios.isAxiosError(err) && (err as AxiosError).response?.status === 409) {
            navigate({
              pathname: routesDictionary.freeLesson.login.url,
              search: createSearchParams({
                email: emailAddress,
                account: accountFromSearchParams,
                referralCode: referralCodeFromSearchParams,
              }).toString(),
            });
          } else {
            dispatch(createFlashMessage({ variant: 'error', message: 'Whoops! It looks like an error occurred.' }));
            throw err;
          }
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack width={isDesktop ? '800px' : undefined} maxWidth={isDesktop ? '800px' : undefined}>
        <Card
          elevation={1}
          sx={{
            marginTop: '24px',
            width: '100%',
          }}
          contentSx={{
            padding: isDesktop ? '24px' : '24px 16px 16px 16px',
          }}
          title="Book A Free Lesson!"
          titleVariant="displaysmall"
        >
          <Grid container size={12}>
            <HootTypography isPII={false} variant="bodylarge">
              Your child’s first lesson is completely free; no obligations or credit card required. All we need is a little bit of info to get you
              started.
            </HootTypography>
          </Grid>

          <Grid sx={{ marginTop: '16px' }} container size={12}>
            <Grid
              sx={{
                [theme.breakpoints.up('md')]: {
                  paddingRight: '8px',
                },
              }}
              size={{ xs: 12, md: 6 }}
              container
            >
              <Controller
                control={control}
                name="firstName"
                rules={{
                  required: true,
                }}
                render={({ field, fieldState }) => (
                  <TextField id="fist-name" required error={!!fieldState.error} value={field.value} onChange={field.onChange} label="First name" />
                )}
              />
            </Grid>
            <Grid
              sx={{
                [theme.breakpoints.down('md')]: {
                  marginTop: '16px',
                },

                [theme.breakpoints.up('md')]: {
                  paddingLeft: '8px',
                },
              }}
              container
              size={{ xs: 12, md: 6 }}
            >
              <Controller
                control={control}
                name="lastName"
                rules={{
                  required: true,
                }}
                render={({ field, fieldState }) => (
                  <TextField id="last-name" required error={!!fieldState.error} value={field.value} onChange={field.onChange} label="Last name" />
                )}
              />
            </Grid>
          </Grid>
          <Grid sx={{ marginTop: '16px' }} container size={12}>
            <Controller
              control={control}
              name="emailAddress"
              rules={{
                required: 'Please enter a valid email address',
                pattern: {
                  value: VALID_EMAIL_REGEX,
                  message: 'Not a valid email address',
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  id="email"
                  type="email"
                  helperText={fieldState.error?.message}
                  required
                  error={!!fieldState.error}
                  value={field.value}
                  onChange={field.onChange}
                  label="Email address"
                />
              )}
            />
          </Grid>
          <Grid sx={{ marginTop: '16px' }} container size={12}>
            <Controller
              control={control}
              name="phone"
              rules={{
                required: true,
                pattern: {
                  value: /^1\s?\d{3}\s?\d{3}\s?\d{4}$/,
                  message: 'Not a valid phone number.',
                },
              }}
              render={({ field, fieldState }) =>
                isIOS ? (
                  <TextField
                    required
                    type="tel"
                    id="phone"
                    error={!!fieldState.error}
                    value={field.value}
                    onChange={field.onChange}
                    helperText={
                      <Stack>
                        <HootTypography isPII={false} variant="labelsmall">
                          We'll use your number only for scheduling updates.
                          <Box component="ul" sx={{ margin: 0 }}>
                            <li>Must be in format 1##########</li>
                          </Box>
                        </HootTypography>
                      </Stack>
                    }
                    label="Phone"
                  />
                ) : (
                  <InputMask mask="1 999 999 9999" onChange={field.onChange} value={field.value}>
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        required
                        type="tel"
                        error={!!fieldState.error}
                        value={field.value}
                        onChange={field.onChange}
                        helperText={
                          <Stack>
                            <HootTypography isPII={false} variant="labelsmall">
                              We'll use your number only for scheduling updates.
                              <Box component="ul" sx={{ margin: 0 }}>
                                <li>Must be in format "1 ### ### ####"</li>
                              </Box>
                            </HootTypography>
                          </Stack>
                        }
                        label="Phone"
                      />
                    )}
                  </InputMask>
                )
              }
            />
          </Grid>
          <Grid sx={{ marginTop: '16px' }} container size={12}>
            <Controller
              control={control}
              name="agreeToReceiveEmail"
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onChange={field.onChange}
                  label="I agree to receive emails from Hoot, which may include updates, news, and promotions."
                />
              )}
            />
          </Grid>
          <Grid sx={{ marginTop: '16px' }} container size={12}>
            <Controller
              control={control}
              name="agreeToTerms"
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => (
                <Checkbox
                  error={!!fieldState.error}
                  checked={field.value}
                  onChange={field.onChange}
                  label={
                    <span>
                      I agree to Hoot Reading’s{' '}
                      <a href="https://www.hootreading.com/privacy" target="_blank" rel="noreferrer">
                        Privacy Policies
                      </a>{' '}
                      and{' '}
                      <a href="https://www.hootreading.com/terms-of-service" target="_blank" rel="noreferrer">
                        Terms of Service
                      </a>
                      .
                      <Box component="span" sx={{ color: hootTokens.palette.error[80] }}>
                        *
                      </Box>
                    </span>
                  }
                />
              )}
            />
          </Grid>
          <Grid sx={{ marginTop: '16px' }} container size={12}>
            <Button type="submit" fullWidth={!isDesktop} color="primary" size="medium" variant="contained" isLoading={signupRequest.isLoading}>
              Next
            </Button>
          </Grid>
        </Card>
      </Stack>
      <Stack width={isDesktop ? '800px' : undefined} maxWidth={isDesktop ? '800px' : undefined}>
        <Card
          elevation={1}
          sx={{
            marginTop: '12px',
            width: '100%',
            backgroundColor: hootTokens.palette.warning[180],
          }}
          contentSx={{
            padding: isDesktop ? '24px' : '24px 16px 16px 16px',
          }}
        >
          <HootTypography isPII={false} sx={{ textAlign: 'center' }} variant="bodylarge">
            <strong>School</strong> or <strong>School District</strong>? Click{' '}
            <strong>
              <a href="https://www.hootreading.com/get-in-touch" target="_blank" rel="noreferrer">
                here
              </a>
            </strong>{' '}
            to chat with our team.
          </HootTypography>
        </Card>
      </Stack>
    </form>
  );
}
