import { PaletteColorOptions, ThemeOptions } from '@mui/material';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { hootTokens } from './tokens';
import { getColors } from './util';

declare module '@mui/material/styles' {
  interface CustomPalette extends Partial<Record<ColorPaletteOption, PaletteColorOptions>> {}

  interface Palette extends CustomPalette {}

  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides extends Record<ColorPaletteOption, true> {}
}

declare module '@mui/material/IconButton' {
  interface ButtonPropsColorOverrides extends Record<ColorPaletteOption, true> {}
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    header: true;
  }
}

const { palette } = hootTokens;

const components: ThemeOptions['components'] = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        // Any other custom variant not mentioned here will use a <span> tag by default.
        displaylarge: 'h1',
        displaymedium: 'h1',
        displaysmall: 'h3',
        headlinelarge: 'h4',
        headlinemedium: 'h5',
        headlinesmall: 'h6',
        titlelarge: 'h4',
        titlemedium: 'h5',
        titlesmall: 'h6',
        tableheading: 'span',
        tableheadingactive: 'span',
        tablevalue: 'span',
        labellarge: 'span',
        labelmedium: 'span',
        labelsmall: 'span',
        bodylarge: 'p',
        bodymedium: 'p',
        bodysmall: 'p',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        ...hootTokens.text.labelsmall,
        color: hootTokens.palette.black,
      },
    },
  },
  MuiInput: {
    defaultProps: {
      autoComplete: 'off',
    },
    styleOverrides: {
      root: {
        ...hootTokens.text.bodylarge,
      },
    },
  },
  MuiInputLabel: {
    defaultProps: {
      // No animation with our input labels.
      shrink: true,
    },
    styleOverrides: {
      root: {
        ...hootTokens.text.labelsmall,
        transform: 'translate(16px, 8px)',
        color: hootTokens.palette.black,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
        },
      },
      notchedOutline: {
        top: 0,
        borderColor: hootTokens.palette.neutral['100'],
        '& > legend': {
          display: 'none',
        },
        ':focus': {
          borderWidth: '1px',
          borderColor: hootTokens.palette.secondary['60'],
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
      fullWidth: true,
      autoComplete: 'off',
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        ...hootTokens.text.bodylarge,
        '& .MuiFormHelperText-root': {
          marginLeft: theme.spacing(1.5),
          marginRight: theme.spacing(1.5),
        },
        /**
         * Outlined TextField Styles
         */
        ...(ownerState.variant === 'outlined' && {
          // Defaults
          '& .MuiOutlinedInput-root': {
            ...(ownerState.InputProps?.startAdornment && {
              paddingLeft: 0,
            }),
            ...(ownerState.InputProps?.endAdornment && {
              paddingRight: 0,
            }),
            ...(ownerState.multiline && {
              padding: 0,
            }),
          },
          '& .MuiInputLabel-root': {
            ...(ownerState.InputProps?.startAdornment && {
              // NOTE: this is super hacky...
              // When there's a start adornment, then shift the label over.
              paddingLeft: '28px',
            }),
          },
          '& .MuiOutlinedInput-input': {
            padding: '25px 16px 10px 16px',
            width: 'unset',
            flex: 1,
            ...(ownerState.InputProps?.startAdornment && {
              paddingLeft: 0,
            }),
            ...(ownerState.InputProps?.endAdornment && {
              paddingRight: 0,
            }),
          },
          '& .MuiFormHelperText-root': {
            marginLeft: '16px',
            marginRight: '16px',
          },
          // Hover
          ...(!ownerState.disabled && {
            '& .MuiOutlinedInput-root:hover': {
              '& > fieldset': {
                borderColor: hootTokens.palette.black,
              },
            },
          }),
          // Focused
          '& .MuiOutlinedInput-root.Mui-focused fieldset': {
            borderColor: hootTokens.palette.secondary['60'],
            borderWidth: '1px',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: hootTokens.palette.secondary['60'],
          },
          // Disabled
          '& .MuiOutlinedInput-root.Mui-disabled fieldset': {
            borderColor: hootTokens.palette.neutral['140'],
          },
          '& .MuiInputLabel-root.Mui-disabled': {
            color: hootTokens.palette.neutral['140'],
          },
          '& .MuiFormHelperText-root.Mui-disabled': {
            color: hootTokens.palette.neutral['140'],
          },
          // Error
          '& .MuiOutlinedInput-root.Mui-error fieldset': {
            borderColor: hootTokens.palette.error['80'],
          },
          '& .MuiOutlinedInput-root.Mui-error': {
            color: hootTokens.palette.error['80'],
          },
          '& .MuiInputLabel-root.Mui-error': {
            color: hootTokens.palette.error['80'],
          },
          '& .MuiFormHelperText-root.Mui-error': {
            color: hootTokens.palette.error['80'],
          },
          // InputAdornment
          '& .MuiInputAdornment-positionStart': {
            margin: 0,
            display: 'flex',
            justifyContent: 'center',
            // Making an assumption that no icon will be wider than this.
            width: '44px',
            height: '44px',
            maxWidth: 'unset',
            maxHeight: 'unset',
            ...(ownerState.disabled && {
              color: hootTokens.palette.neutral['140'],
              p: {
                color: hootTokens.palette.neutral['140'],
              },
              svg: {
                color: hootTokens.palette.neutral['140'],
              },
            }),
          },
          '& .MuiInputAdornment-positionEnd': {
            margin: 0,
            display: 'flex',
            justifyContent: 'center',
            // Making an assumption that no icon will be wider than this.
            width: '44px',
            height: '44px',
            maxWidth: 'unset',
            maxHeight: 'unset',
            ...(ownerState.disabled && {
              color: hootTokens.palette.neutral['140'],
              svg: {
                color: hootTokens.palette.neutral['140'],
              },
            }),
            ...(ownerState.error && {
              color: hootTokens.palette.error['80'],
              svg: {
                color: hootTokens.palette.error['80'],
              },
            }),
          },
        }),
        /**
         * Filled TextField Styles
         */
        ...(ownerState.variant === 'filled' && {
          // Defaults
          '& .MuiFilledInput-root': {
            border: '1px solid transparent',
            backgroundColor: hootTokens.palette.neutral['190'],
            borderRadius: '4px',
            ...(ownerState.InputProps?.startAdornment && {
              paddingLeft: 0,
            }),
            ...(ownerState.InputProps?.endAdornment && {
              paddingRight: 0,
            }),
          },
          '& .MuiFilledInput-root:before': {
            display: 'none',
          },
          '& .MuiInputLabel-root': {
            ...(ownerState.InputProps?.startAdornment && {
              paddingLeft: '28px',
            }),
          },
          '& .MuiFilledInput-input': {
            padding: '25px 16px 10px 16px',
            width: 'unset',
            flex: 1,
            ...(ownerState.InputProps?.startAdornment && {
              paddingLeft: 0,
            }),
            ...(ownerState.InputProps?.endAdornment && {
              paddingRight: 0,
            }),
          },
          '& .MuiFormHelperText-root': {
            marginLeft: '16px',
            marginRight: '16px',
          },
          // Hover
          ...(!ownerState.disabled && {
            '& .MuiFilledInput-root:hover': {
              backgroundColor: hootTokens.palette.neutral['180'],
            },
          }),
          // Focused
          '& .MuiFilledInput-root.Mui-focused': {
            backgroundColor: hootTokens.palette.neutral['190'],
            color: hootTokens.palette.neutral['60'],
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: hootTokens.palette.neutral['60'],
          },
          '& .MuiFilledInput-root:after': {
            borderBottom: 'unset',
            height: '8px',
            background: hootTokens.palette.success['60'],
            zIndex: -1,
            bottom: '-2px',
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px',
          },
          // Disabled
          '& .MuiFilledInput-root.Mui-disabled': {
            backgroundColor: hootTokens.palette.neutral['190'],
            color: hootTokens.palette.neutral['140'],
          },
          '& .MuiInputLabel-root.Mui-disabled': {
            color: hootTokens.palette.neutral['140'],
          },
          '& .MuiFormHelperText-root.Mui-disabled': {
            color: hootTokens.palette.neutral['140'],
          },
          // Error
          '& .MuiFilledInput-root.Mui-error': {
            backgroundColor: hootTokens.palette.error['190'],
            border: '1px solid',
            borderColor: hootTokens.palette.error['80'],
            color: hootTokens.palette.error['80'],
          },
          '& .MuiInputLabel-root.Mui-error': {
            color: hootTokens.palette.error['80'],
          },
          '& .MuiFilledInput-root.Mui-error:after': {
            borderBottom: 'none',
            backgroundColor: hootTokens.palette.error['80'],
          },
          '& .MuiFormHelperText-root.Mui-error': {
            color: hootTokens.palette.error['80'],
          },
          // InputAdornment
          '& .MuiInputAdornment-positionStart': {
            display: 'flex',
            justifyContent: 'center',
            margin: '0 !important',
            // Making an assumption that no icon will be wider than this.
            width: '44px',
            height: '44px',
            maxWidth: 'unset',
            maxHeight: 'unset',
            ...(ownerState.disabled && {
              color: hootTokens.palette.neutral['140'],
              p: {
                color: hootTokens.palette.neutral['140'],
              },
              svg: {
                color: hootTokens.palette.neutral['140'],
              },
            }),
          },
          '& .MuiInputAdornment-positionEnd': {
            display: 'flex',
            justifyContent: 'center',
            margin: '0 !important',
            // Making an assumption that no icon will be wider than this.
            width: '44px',
            height: '44px',
            maxWidth: 'unset',
            maxHeight: 'unset',
            ...(ownerState.disabled && {
              color: hootTokens.palette.neutral['140'],
              svg: {
                color: hootTokens.palette.neutral['140'],
              },
            }),
            ...(ownerState.error && {
              color: hootTokens.palette.error['80'],
              svg: {
                color: hootTokens.palette.error['80'],
              },
            }),
          },
        }),
      }),
    },
  },
  MuiButton: {
    // Note: cannot apply "cursor: not-allowed" when disabled because of "pointer-events: none". Need to wrap the button in a div and apply there
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        border: 'solid 1px transparent',
        boxSizing: 'border-box',
        borderRadius: '8px',
        height: 'max-content',
      },
      sizeSmall: {
        ...hootTokens.text.labelsmall,
        padding: '8px 16px',
      },
      sizeMedium: {
        ...hootTokens.text.labellarge,
        minWidth: '56px',
        padding: '17px 24px',
      },
      contained: ({ ownerState }) => {
        const colors = getColors(ownerState.color as ColorPaletteOption);
        return {
          backgroundColor: colors.base,
          color: colors.font,
          ':disabled': {
            color: palette.white,
            backgroundColor: palette.neutral[140],
          },
          '&:focus-visible': {
            backgroundColor: colors.focus,
            border: `solid 1px ${colors.base}`,
          },
          ':hover': {
            backgroundColor: colors.hover,
            boxShadow: hootTokens.elevation.elevation3.boxShadow,
          },
          ':active': {
            backgroundColor: colors.base,
          },
        };
      },
      outlined: ({ ownerState }) => {
        const colors = getColors(ownerState.color as ColorPaletteOption);

        return {
          backgroundColor: 'transparent',
          border: `solid 1px ${colors.base}`,
          color: colors.base,
          ':disabled': {
            backgroundColor: palette.white,
          },
          // NOTE: need to add a bit of transparency here. We don't want the button text to clash with the button background color.
          '&:focus-visible': {
            backgroundColor: `${colors.palette[190]}33`,
            border: `solid 1px ${colors.focus}`,
            boxShadow: hootTokens.elevation.elevation3.boxShadow,
          },
          ':hover': {
            backgroundColor: `${colors.palette[190]}33`,
            border: `solid 1px ${colors.hover}`,
            boxShadow: hootTokens.elevation.elevation3.boxShadow,
          },
          ':active': {
            backgroundColor: `${colors.palette[200]}33`,
            border: `solid 1px ${palette.black}`,
          },
        };
      },

      text: ({ ownerState }) => {
        const colors = getColors(ownerState.color as ColorPaletteOption);

        return {
          color: colors.base,
          border: `solid 1px transparent`,

          ':disabled': {
            color: palette.neutral[120],
          },
          '&:focus-visible': {
            backgroundColor: colors.palette[190],
            border: `solid 1px ${colors.focus}`,
          },
          ':hover': {
            backgroundColor: colors.palette[190],
          },
          ':active': {
            backgroundColor: colors.palette[200],
          },
        };
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      elevation0: {
        ...hootTokens.elevation.none,
      },
      elevation1: {
        ...hootTokens.elevation.elevation1,
      },
      elevation2: {
        ...hootTokens.elevation.elevation2,
      },
      elevation3: {
        ...hootTokens.elevation.elevation3,
      },
      elevation4: {
        ...hootTokens.elevation.elevation4,
      },
      elevation5: {
        ...hootTokens.elevation.elevation5,
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      focusRipple: false,
      centerRipple: false,
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        color: hootTokens.palette.primary[0],
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: 0,
        height: '32px',
        width: '59px',
        overflow: 'visible',
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 4,
          transitionDuration: '300ms',
          '&.Mui-disabled': {
            '& + .MuiSwitch-track': {
              border: `solid 1px hootTokens.palette.neutral[120]`,
            },
            '& .MuiSwitch-thumb': {
              backgroundColor: hootTokens.palette.neutral[120],
            },
          },
          '& .MuiSwitch-input': {
            appearance: 'none',
            border: `solid 1px ${hootTokens.palette.neutral[120]}`,
            borderRadius: '100px',
            height: '40px',
            width: '62px',
            top: '-7px',
            left: '-6px',
            opacity: 0,
            backgroundColor: 'transparent',
          },
          '&.Mui-checked': {
            '& .MuiSwitch-input': {
              left: '-32px',
            },
            transform: 'translateX(26px)',
            '&.Mui-disabled': {
              '& + .MuiSwitch-track': {
                backgroundColor: hootTokens.palette.neutral[120],
                border: hootTokens.palette.neutral[120],
              },
              '& .MuiSwitch-thumb:before': {
                backgroundImage: `url("/images/switch-on-disabled.svg")`,
              },
            },
            '& .MuiSwitch-thumb': {
              backgroundColor: hootTokens.palette.white,
              '&:before': {
                backgroundImage: `url("/images/switch-on.svg")`,
              },
            },

            '& + .MuiSwitch-track': {
              backgroundColor: hootTokens.palette.black,
              opacity: 1,
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          backgroundColor: hootTokens.palette.black,
          width: 24,
          height: 24,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),

          '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url("/images/switch-off.svg")`,
          },
        },
        '& .MuiSwitch-track': {
          backgroundColor: hootTokens.palette.white,
          border: `solid 1px ${hootTokens.palette.black}`,
          borderRadius: '100px',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }),
    },
  },
  MuiChip: {
    defaultProps: {},
    styleOverrides: {
      root: {
        backgroundColor: hootTokens.palette.white,
        border: `solid 1px ${hootTokens.palette.black}`,
        borderRadius: '8px',
        height: '40px',
        padding: 0,
        margin: 0,
      },
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.7)',
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        minWidth: '320px',
        ...hootTokens.elevation.elevation4,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(2),
        ...hootTokens.text.titlelarge,
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(0, 2, 0, 2),
        paddingTop: '1px !important', // annoying to do this, but Tables get cut off by the Dialog header
        ...hootTokens.text.bodylarge,
        color: hootTokens.palette.black,
      }),
    },
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: {
        ...hootTokens.text.bodylarge,
        color: hootTokens.palette.black,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(4, 2, 2, 2),
        '& > :not(:first-of-type)': {
          marginLeft: theme.spacing(2),
        },
      }),
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: {
        '.MuiTableRow-root:nth-of-type(odd)': {
          backgroundColor: hootTokens.palette.primary[195],
        },
        '.MuiTableRow-root:nth-of-type(even)': {
          backgroundColor: hootTokens.palette.white,
        },
        '.MuiTableRow-root:hover': {
          backgroundColor: hootTokens.palette.success[195],
        },
      },
    },
  },
  MuiPagination: {
    styleOverrides: {
      root: {
        ...hootTokens.text.bodysmall,
        color: hootTokens.palette.black,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        marginBottom: '8px',
        color: hootTokens.palette.black,
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        padding: '8px 24px 8px 8px',
        ':hover': {
          backgroundColor: !ownerState.disabled ? hootTokens.palette.primary[190] : 'unset',
        },
        '&.Mui-error': {
          '& svg': {
            color: hootTokens.palette.error[80],
          },
          '& .MuiFormControlLabel-label': {
            color: hootTokens.palette.error[80],
          },
          // If we're in an error state, but this particular field is disabled, then it should appear disabled (grey).
          '& .Mui-disabled': {
            color: hootTokens.palette.primary[120],
            '& svg': {
              color: hootTokens.palette.primary[120],
            },
          },
        },
      }),
      label: {
        '&.Mui-disabled': {
          color: hootTokens.palette.primary[120],
        },
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
      disableTouchRipple: true,
    },
    styleOverrides: {
      root: {
        height: '24px',
        width: '24px',
        borderRadius: '4px',
        ':hover': {
          backgroundColor: hootTokens.palette.primary[190],
        },
        ':focus': {
          backgroundColor: hootTokens.palette.primary[180],
        },
        ':active': {
          backgroundColor: hootTokens.palette.primary[180],
        },
        '&.Mui-focusVisible': {
          background: hootTokens.palette.primary['180'],
        },
        '& .MuiSvgIcon-root': {
          color: hootTokens.palette.primary[0],
        },
        '&.Mui-disabled': {
          '& .MuiSvgIcon-root': {
            color: hootTokens.palette.primary[120],
          },
        },
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        padding: '2px',
        borderRadius: '4px',
        marginRight: '16px',
        ':hover': {
          backgroundColor: hootTokens.palette.primary['190'],
        },
        '&.Mui-disabled': {
          '& svg': {
            color: hootTokens.palette.primary['120'],
          },
        },
        '&.Mui-focusVisible': {
          background: hootTokens.palette.primary['180'],
        },
      },
    },
  },
  MuiAppBar: {
    defaultProps: {
      variant: 'header',
    },
    variants: [
      {
        props: { variant: 'header' },
        style: {
          borderBottom: '1px solid black',
          color: hootTokens.palette.primary['0'],
        },
      },
    ],
    styleOverrides: {
      colorPrimary: {
        backgroundColor: hootTokens.palette.primary['200'],
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: ({ theme }) => ({
        minHeight: '0px',

        [theme.breakpoints.up('sm')]: {
          minHeight: '0px',
        },
      }),
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        borderRadius: '8px',
        ...hootTokens.elevation.elevation1,
      },
      list: ({ theme }) => ({
        minWidth: '272px',
        padding: theme.spacing(1),
      }),
    },
  },
  MuiMenuItem: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(1.25, 1, 1.25, 1),
        borderRadius: '4px',
        '& .MuiListItemIcon-root': {
          minWidth: '24px',
          marginRight: theme.spacing(2),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& > svg': {
            width: '24px',
            height: '24px',
          },
        },
        '&:hover': {
          background: hootTokens.palette.primary['190'],
        },
      }),
      divider: {
        borderBottomColor: hootTokens.palette.primary['120'],
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: hootTokens.palette.primary['190'],
      },
    },
  },
  MuiSvgIcon: {
    defaultProps: {
      color: 'primary',
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        fill: ownerState.fill ?? 'currentColor',
      }),
      colorPrimary: {
        color: hootTokens.palette.primary['0'],
      },
    },
  },
  MuiListItemButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        padding: '16px',
      },
    },
  },
  MuiListItemText: {
    defaultProps: {
      primaryTypographyProps: {
        ...hootTokens.text.labellarge,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': {
          outlineColor: hootTokens.palette.success['100'],
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        ...hootTokens.elevation.elevation1,
        padding: '16px',
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      selectLabel: {
        ...hootTokens.text.bodysmall,
      },
      displayedRows: {
        ...hootTokens.text.bodysmall,
      },
      select: {
        ...hootTokens.text.bodysmall,
      },
      selectIcon: {
        color: hootTokens.palette.black,
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        borderTop: `1px solid ${hootTokens.surface[4]}`,
      },
      indicator: {
        '&.MuiTabs-indicator': {
          borderRadius: '100px 0px 100px 0px',
          height: '4px',
        },
      },
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
      tabIndex: 0,
    },
    styleOverrides: {
      root: {
        ...hootTokens.text.titlesmall,
        // Removing border when clicking but not on tabbing
        ':focus': {
          border: `none`,
        },
        ':focus-visible': {
          border: `1px solid ${hootTokens.palette.black}`,
        },
        color: hootTokens.palette.black,
      },
    },
  },
  MuiStepper: {
    styleOverrides: {
      vertical: {
        padding: '0px !important',
      },
    },
  },
  MuiStep: {
    styleOverrides: {
      vertical: {
        width: '100%',
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      horizontal: {
        gap: '8px',
      },
      vertical: {
        gap: '8px',
        padding: 0,
        '&.MuiStepLabel-alternativeLabel': {
          flexDirection: 'row',
        },
        '& .MuiStepLabel-labelContainer': {
          textAlign: 'start',
        },
      },
      label: {
        ...hootTokens.text.labelsmall,
        color: hootTokens.palette.black,
        '&.MuiStepLabel-alternativeLabel': {
          marginTop: '0px',
        },
      },
      iconContainer: {
        '& .MuiStepIcon-root ': {
          color: hootTokens.palette.primary['180'],
        },
        '& .MuiStepIcon-text': {
          fill: hootTokens.palette.black,
        },
        '& .Mui-active': {
          '& .MuiStepIcon-text': {
            fill: hootTokens.palette.white,
          },
        },
      },
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        width: '44px',
        height: '44px',
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      root: {
        // NOTE: Half the icon width/height (44px) is 22px.
        top: '22px',
        left: 'calc(-50% + 22px)',
        right: 'calc(50% + 22px)',
        display: 'flex',
        justifyContent: 'center',
      },
      lineHorizontal: {
        width: '24px',
        background: hootTokens.palette.neutral['120'],
      },
      // Not using MUI defaults for vertical stepper.
      lineVertical: {
        display: 'none',
      },
    },
  },
  // Note: We should only really be using this component for the vertical stepper.
  MuiStepContent: {
    styleOverrides: {
      root: {
        // NOTE: Half the icon width/height (44px) is 22px.
        marginLeft: '22px',
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        padding: '16px',
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: '0',
        margin: `0`,
        boxShadow: 'unset',
        minHeight: '44px',
        '&::before': {
          display: 'none',
        },
        '&.MuiAccordionSummary-root.Mui-expanded ': {
          minHeight: '44px',
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
          margin: 0,
        },
        '& .MuiAccordionSummary-content': {
          margin: 0,
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
};

export default components;
