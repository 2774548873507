import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, SxProps } from '@mui/material';
import { ControllerFieldState } from 'react-hook-form';
import { Option } from '@hoot/models/formOption';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';

interface IRadioBtnGroupProps {
  name: string;
  label: string;
  options: Option[];
  onChange: (val: string) => void;
  value: string;
  fieldState: ControllerFieldState;
  optionSx?: SxProps;
}

const RadioButtonGroup = (props: IRadioBtnGroupProps) => {
  const { name, label, options, onChange, value, fieldState, optionSx } = props;

  const handleChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    onChange(value);
  };

  return (
    <Stack sx={{ gap: 2, pb: 5 }}>
      <HootTypography isPII={false} variant="titlemedium" sx={{ color: fieldState.error ? '#B3261E' : undefined }}>
        {label}
      </HootTypography>
      <Box>
        <FormControl variant="standard">
          <RadioGroup name={name} value={value} onChange={handleChange} sx={{ gap: 1 }}>
            {options.map((option) => {
              const label = <div dangerouslySetInnerHTML={{ __html: option.label }} />;
              return (
                <FormControlLabel
                  sx={{
                    margin: 0,
                    padding: 0.5,
                    ...optionSx,
                  }}
                  value={option.value}
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#397877',
                        },
                      }}
                    />
                  }
                  label={label}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Box>
      {fieldState.error ? (
        <HootTypography isPII={false} color="red">
          * A selection is required
        </HootTypography>
      ) : null}
    </Stack>
  );
};

export default RadioButtonGroup;
