import { SvgIcon, SvgIconProps } from '@mui/material';

const BellSolidIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none" {...props}>
      <g clipPath="url(#clip0_9842_483)">
        <path
          fill={props.htmlColor ?? 'currentColor'}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7772 16.0002H2.22281C0.394434 16.0002 -0.649243 14.1611 0.447786 12.8723C0.933441 12.3018 1.23274 11.6251 1.31328 10.9155L1.76046 6.97543C2.0016 4.85087 3.58766 3.10743 5.73005 2.3739V2.27019C5.73005 1.01653 6.74634 0.000244141 8 0.000244141C9.25366 0.000244141 10.2699 1.01653 10.2699 2.27019V2.3739C12.4123 3.10743 13.9984 4.85087 14.2395 6.97543L14.6867 10.9155C14.7673 11.6251 15.0666 12.3018 15.5522 12.8723C16.6492 14.1611 15.6056 16.0002 13.7772 16.0002ZM10.9721 18.0717C10.5147 19.1994 9.35653 20.0002 8 20.0002C6.64347 20.0002 5.48526 19.1994 5.02789 18.0717C5.00883 18.0247 5 17.9742 5 17.9235C5 17.6897 5.18951 17.5002 5.42329 17.5002H10.5767C10.8105 17.5002 11 17.6897 11 17.9235C11 17.9742 10.9912 18.0247 10.9721 18.0717Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_9842_483">
          <rect width="16" height="20" fill="white" transform="translate(0 0.000244141)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default BellSolidIcon;
