import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '@hoot/redux/store';
import { SnackbarProps } from '@hoot/ui/components/v2/core/Snackbar';

// A "FlashMessage" is just a subset of all the Snackbar props (just the ones that _display_ data). "Actions" are not supported here.
type FlashMessage = Pick<SnackbarProps, 'message' | 'title' | 'isIndefinite' | 'variant' | 'anchorOrigin'>;

interface FlashMessageState {
  flashMessageProperties?: FlashMessage;
}

const initialState: FlashMessageState = {
  flashMessageProperties: undefined,
};

export const flashMessageSlice = createSlice({
  name: 'flashMessage',
  initialState,
  reducers: {
    createFlashMessage: (state, action: PayloadAction<FlashMessage>) => {
      state.flashMessageProperties = action.payload;
    },
    clearFlashMessage: (state) => {
      state.flashMessageProperties = undefined;
    },
  },
});

export const useFlashMessage = () => {
  return useSelector<RootState, FlashMessage | undefined>((state) => state.flashMessage.flashMessageProperties);
};

export const { createFlashMessage, clearFlashMessage } = flashMessageSlice.actions;

export default flashMessageSlice.reducer;
