import React from 'react';
import { BookResponse } from '@hoot/events/interfaces/book-response';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import GrowList from '@hoot/ui/components/v2/GrowList';
import StudentInLessonLibraryBook from '@hoot/ui/pages/v2/student/lesson/library/StudentInLessonLibraryBook';

interface StudentInLessonLibraryProps {
  books: BookSearch[];
  query: QueryLibraryV2 | null;
  selectedBook: BookResponse | null;
  onBookSelected: (book: BookSearch) => void;
}

const StudentInLessonLibrary = (props: StudentInLessonLibraryProps) => {
  const { query, books, selectedBook, onBookSelected } = props;
  return (
    <GrowList<BookSearch>
      StackProps={{
        direction: 'row',
        sx: {
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 2,
          flexWrap: 'wrap',
        },
      }}
      itemRenderTimeoutMillis={100}
      items={books}
      getKey={(book) => book.id}
      renderItem={(book) => <StudentInLessonLibraryBook book={book} isSelected={book.id === selectedBook?.id} onBookSelected={onBookSelected} />}
      // Kinda taking the easy way out here. We're only cascading the first page of books loaded.
      // After the first page, every item just animates in at the same time.
      cascadeAnimate={(query?.page ?? 1) <= 1}
    />
  );
};

export default StudentInLessonLibrary;
