import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useSelector } from 'react-redux';
import { UpcomingLesson } from '@hoot/models/api/lessons';
import { RootState } from '@hoot/redux/store';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { timeFromUnixMillis, timeFromUnixMillisUpperCase } from '@hoot/utils/date';
import { useGetReadyV2Dialog } from '../../../context/GetReadyV2DialogProvider';
import { useUpcomingLessons } from '../../../context/UpcomingLessonsProvider';
import Banner from '../Banner';

export function JoinLessonBanner() {
  const { openLesson, nextOpenLesson } = useUpcomingLessons();
  const { startGetReady, isJoiningLesson } = useGetReadyV2Dialog();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);

  const lessonToolTip = (lesson: UpcomingLesson) => {
    const startTime = timeFromUnixMillis(Number(lesson.startTime));
    const endTime = timeFromUnixMillis(Number(lesson.endTime));
    const studentName = lesson.studentName;
    if (startTime && endTime && studentName) {
      return <HootTypography sx={{ fontSize: 11 }} isPII={true}>{`${studentName}  ${startTime} - ${endTime}`}</HootTypography>;
    }
  };

  if (openLesson && isLargeScreen && !inLesson) {
    return (
      <Grid container>
        <Grid size={{ xs: 12, md: nextOpenLesson ? 8 : 12 }}>
          <Banner
            backgroundColor="success.180"
            message={`Lesson ${openLesson.status === ScheduledLessonStatus.InProgress ? 'In Progress' : 'Is Open'}`}
            primaryAction={{
              label: 'Join Lesson',
              onClick: () => startGetReady(openLesson ? { lessonId: openLesson.lessonId, lessonNumber: openLesson.lessonNumber } : undefined),
              props: {
                variant: 'contained',
                color: 'success.60',
                sx: { minWidth: '121px' },
                isLoading: isJoiningLesson,
              },
              toolTip: lessonToolTip(openLesson),
            }}
          />
        </Grid>
        {nextOpenLesson ? (
          <Grid size={{ xs: 12, md: 4 }}>
            <Banner
              backgroundColor="success.140"
              message={`Next lesson ${timeFromUnixMillisUpperCase(Number(nextOpenLesson.startTime))}`}
              primaryAction={{
                label: 'Join Lesson',
                onClick: () =>
                  startGetReady(nextOpenLesson ? { lessonId: nextOpenLesson.lessonId, lessonNumber: nextOpenLesson.lessonNumber } : undefined),
                props: {
                  variant: 'contained',
                  color: 'success.60',
                  sx: { minWidth: '121px' },
                  isLoading: isJoiningLesson,
                },
                toolTip: lessonToolTip(nextOpenLesson),
              }}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  }
  return null;
}
