import { CircularProgress } from '@mui/material';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useGetDistrictRepPermissions, { DistrictRepAccessLevel } from '@hoot/hooks/api/user/useGetDistrictRepPermissions';
import useProfile from '@hoot/hooks/useProfile';

export const hasDistrictPermission = (requiredPermission: DistrictRepAccessLevel, districtRepPermission?: DistrictRepAccessLevel) => {
  if (!districtRepPermission) {
    return false;
  }
  switch (requiredPermission) {
    case DistrictRepAccessLevel.Everyone:
      return [DistrictRepAccessLevel.Everyone, DistrictRepAccessLevel.SchoolOrDistrict, DistrictRepAccessLevel.BillableEntity].includes(
        districtRepPermission,
      );
    case DistrictRepAccessLevel.SchoolOrDistrict:
      return [DistrictRepAccessLevel.SchoolOrDistrict, DistrictRepAccessLevel.BillableEntity].includes(districtRepPermission);
    case DistrictRepAccessLevel.BillableEntity:
      return districtRepPermission === DistrictRepAccessLevel.BillableEntity;
    case DistrictRepAccessLevel.None:
    default:
      return false;
  }
};

export function ProtectedRoute(props: { requiredPermission: DistrictRepAccessLevel }) {
  const { isDistrictRep, profile } = useProfile();
  const { data: districtRepPermission, isLoading } = useGetDistrictRepPermissions(profile?.id || '', { enabled: isDistrictRep });
  const hasPermissions = hasDistrictPermission(props.requiredPermission, districtRepPermission);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (hasPermissions) {
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
}
