import { ExpandCircleDownRounded } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, useMediaQuery, useTheme } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useParams } from 'react-router-dom';
import { Assessment } from '@hoot/hooks/api/assessment/useGetAssessmentById';
import { AssessmentUnitSubmission, useSubmitAssessment } from '@hoot/hooks/api/assessment/useSubmitAssessment';
import {
  HootAssessmentFieldType,
  HootAssessmentInstructionIndicator,
  HootAssessmentModule,
  SegmentAnswer,
  StepTestAnswer,
  UnitIdentifier,
} from '@hoot/models/api/enums/hoot-reading-assessment';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { Button, ButtonProps } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import ArrowIcon from '@hoot/ui/components/v2/icons/ArrowIcon';
import PlayIcon from '@hoot/ui/components/v2/icons/PlayIcon';
import { RotateDirection } from '@hoot/ui/components/v2/utils/icon-utils';
import { useAuth } from '@hoot/ui/context/AuthContext';
import FocusedSegmentFieldCard from '@hoot/ui/pages/v2/teacher/hoot-reading-assessment/hra-fields/FocusedSegmentFieldCard';
import FocusedStepTestFieldCard from '@hoot/ui/pages/v2/teacher/hoot-reading-assessment/hra-fields/FocusedStepTestFieldCard';
import { FocusedField, useHootAssessmentContext } from './HootAssessmentContext';
import { EditAnswerDialog } from './dialogs/EditAnswerDialog';
import { Section } from './hra-fields/Section';
import UnitCompleteCard from './hra-fields/UnitCompleteCard';

const HRAForm = () => {
  const {
    form,
    unit,
    setFocusedField,
    focusedField,
    hasOutstandingQuestions,
    onAssessmentUpdated,
    navigateToUnit,
    onDismissEditDialog,
    handleSetUnit,
    isUnitSubmitted,
  } = useHootAssessmentContext();

  const { studentProfileId, assessmentId } = useParams();
  const { getUser } = useAuth();
  const user = getUser();

  const submitAssessmentRequest = useSubmitAssessment(assessmentId!, unit.id);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isSub1000px = useMediaQuery(theme.breakpoints.down(1000));

  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    watch,
    setValue,
  } = form;

  const hasModules = (modules: HootAssessmentModule[]) => modules.includes(unit.module);

  const frustrationLevelFieldName = hasModules([HootAssessmentModule.SDQ]) ? unit.sections[0]?.fields[0]?.name : '';

  const onAnswerEdited = (newAnswer: SegmentAnswer | StepTestAnswer, focusedField: FocusedField) => {
    if (focusedField.sectionField.type === HootAssessmentFieldType.Segment) {
      form.setValue(focusedField.sectionField.name, newAnswer, { shouldDirty: true });
    } else if (focusedField.sectionField.type === HootAssessmentFieldType.StepTest) {
      const updatedFormValue = [...form.getValues(focusedField.sectionField.name)];
      updatedFormValue[focusedField.currentStepperIndex!] = newAnswer;
      form.setValue(focusedField.sectionField.name, updatedFormValue, { shouldDirty: true });
    }
    onDismissEditDialog();
  };

  const onSubmit = (data: any) => {
    if (Object.keys(errors)?.length > 0) {
      return;
    }

    const scoreCalculation = (): {
      score?: number;
      instructionIndicator?: HootAssessmentInstructionIndicator;
    } => {
      if (!hasModules([HootAssessmentModule.One, HootAssessmentModule.Two, HootAssessmentModule.Three])) {
        return { score: undefined, instructionIndicator: undefined };
      }
      let score = 0;
      let instructionIndicator;

      unit.sections.forEach((section) => {
        section.fields.forEach((field) => {
          if (typeof data[field.name] === 'string') {
            score = score + (data[field.name] === 'CORRECT' ? 1 : 0);
          }
          if (Array.isArray(data[field.name])) {
            score = score + data[field.name].filter((v: any) => v === 'CORRECT').length;
          }
        });
      });

      unit.instructionIndicatorThresholds?.forEach((i) => {
        if (i.initial <= score && score <= i.end) {
          instructionIndicator = i.instructionLevel;
        }
      });
      return { score, instructionIndicator };
    };

    const { score, instructionIndicator } = scoreCalculation();

    const calculateTransitionScore = () => {
      for (const section of unit.sections) {
        for (const field of section.fields) {
          if (field.unfurl) {
            const answers = data[field.name] as StepTestAnswer[];
            const transitionScore = field.unfurl.segments.reduce((a, b, idx) => {
              const start = b.start > 0 ? b.start - 1 : b.start;
              const end = b.end;
              const correctAnswers = answers.slice(start, end).filter((answer) => answer === StepTestAnswer.Correct).length;
              return a + correctAnswers * Math.pow(10, idx);
            }, 0);
            return transitionScore;
          }
        }
      }
      return score;
    };

    if (user?.teacherAccount) {
      const submit: AssessmentUnitSubmission = {
        submissionId: unit.submissionId,
        studentProfileId: studentProfileId!,
        teacherAccountId: user.teacherAccount.id,
        score: score,
        instructionIndicator: instructionIndicator,
        frustrationLevel: data[frustrationLevelFieldName] ? data[frustrationLevelFieldName][0] : undefined,
        submissionData: data,
        transitionScore: calculateTransitionScore(),
      };

      submitAssessmentRequest.mutate(submit, {
        onSuccess: (data: Assessment) => {
          handleSetUnit(undefined);
          setFocusedField(undefined);
          onAssessmentUpdated(data);
          dispatch(createFlashMessage({ message: 'Submission successfully sent.' }));
        },
        onError: (err) => {
          console.log(err);
          dispatch(createFlashMessage({ message: 'There was a problem saving the submission.' }));
        },
      });
    }
  };

  const handlePreviousUnitClick = () => {
    if (unit.previousUnitId) {
      navigateToUnit(unit.previousUnitId);
    }
  };

  const handleNextUnitClick = () => {
    if (unit.nextUnitId) {
      navigateToUnit(unit.nextUnitId);
    }
  };

  const watchedFields = watch(unit.watch || []);
  const watching = watchedFields.reduce(
    (a, b, idx) => ({
      ...a,
      [unit.watch![idx]]: b,
    }),
    {},
  );

  const ActionButton = (props: ButtonProps) => (
    <Button
      variant="contained"
      type="submit"
      disabled={(!unit.nextUnitId && !isDirty) || submitAssessmentRequest.isLoading}
      startIcon={<PlayIcon htmlColor="#FFF" />}
      sx={{ mt: 3 }}
      isLoading={submitAssessmentRequest.isLoading}
      {...props}
    >
      Submit & Continue to Next Unit
    </Button>
  );

  return (
    <Box display="flex" flexDirection={isSub1000px ? 'column' : 'row'} gap={2}>
      {/* Left Card */}
      <div style={{ flexBasis: '32%', maxWidth: isSub1000px ? '100%' : '32%' }}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandCircleDownRounded />}>
            <Stack>
              <HootTypography isPII={false} variant="titlemedium">
                {unit.name}
              </HootTypography>
              {unit.fieldFormVariant ? (
                <HootTypography isPII={false} variant="titlemedium">
                  Form {unit.fieldFormVariant}
                </HootTypography>
              ) : null}
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Box mt={2}>
              <HootTypography isPII={false} variant="bodylarge" component={Box} dangerouslySetInnerHTML={{ __html: unit.introductoryText }} />
            </Box>
            {unit.previousUnitId && (
              <Button
                onClick={() => handlePreviousUnitClick()}
                variant="contained"
                color="neutral.190"
                fullWidth
                startIcon={<ArrowIcon rotate={RotateDirection.Left} />}
              >
                Back to Previous Unit
              </Button>
            )}
            {unit.nextUnitId && (
              <Button onClick={() => handleNextUnitClick()} variant="contained" color="neutral.190" fullWidth startIcon={<ArrowIcon />}>
                Go to Next Unit
              </Button>
            )}
          </AccordionDetails>
        </Accordion>
      </div>

      {/* Right Card(s) */}
      <Box sx={{ flexBasis: '68%', maxWidth: isSub1000px ? '100%' : '68%' }}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack gap={2}>
            {/* "Segment" and "Step Test" fields render a "focus" card when clicked. Focus card is rendered here. */}
            {focusedField && !isUnitSubmitted && (
              <>
                {focusedField?.sectionField?.type === HootAssessmentFieldType.Segment && hasOutstandingQuestions && (
                  <FocusedSegmentFieldCard sectionField={focusedField.sectionField} />
                )}
                {focusedField?.sectionField?.type === HootAssessmentFieldType.StepTest && hasOutstandingQuestions && (
                  <FocusedStepTestFieldCard sectionField={focusedField.sectionField} currentStepperIndex={focusedField.currentStepperIndex!} />
                )}
              </>
            )}

            {/* If the Unit is complete and has been submitted, we render this Card to proceed to Next Unit. */}
            {(isUnitSubmitted || !hasOutstandingQuestions) && (
              <UnitCompleteCard
                unit={unit}
                hasOutstandingQuestions={hasOutstandingQuestions}
                submitForm={handleSubmit(onSubmit)}
                isSubmitting={submitAssessmentRequest.isLoading}
              />
            )}

            {/* Each root-level section is rendered as a <Card/>. Nested sections (if any) are _not_ rendered as cards. */}
            {unit!.sections.map((section, index) => (
              <Card key={section.id}>
                <Section control={control} section={section} watching={watching} setValue={setValue} />
                {/* Hacky stuff! */}
                {/* If the current unit is 4.1A, then we show a submit button in the first section. */}
                {unit!.identifier === UnitIdentifier.U_4_1A && index === 0 ? (
                  <ActionButton />
                ) : // Else if the current module is SDQ, Four (except unit 4.1A), or ATR, then we show the submit button in the last section.
                (unit!.module === HootAssessmentModule.SDQ ||
                    (unit!.module === HootAssessmentModule.Four && unit!.identifier !== UnitIdentifier.U_4_1A) ||
                    unit!.module === HootAssessmentModule.ATR) &&
                  index === unit!.sections.length - 1 ? (
                  <ActionButton />
                ) : // Else, we don't show a submit button at all. For modules ONE, TWO, and THREE, submissions are handled automatically.
                // Thoughts: It probably would make more sense if the placement/functionality of the "submit" button is customizable like other Fields.
                null}
              </Card>
            ))}
          </Stack>
        </form>
      </Box>

      {/*
       When a unit has been submitted, we no longer show the selected question in the focus card (this applies to
       modules ONE, TWO, and THREE. Instead, when we want to change an answer after a unit has been submitted, we do
       so in a dialog.
      */}
      {!hasOutstandingQuestions &&
        focusedField &&
        [HootAssessmentModule.One, HootAssessmentModule.Two, HootAssessmentModule.Three].some((x) => x === unit.module) && (
          <EditAnswerDialog
            open={!!focusedField}
            questionLabel={
              focusedField.sectionField.type === HootAssessmentFieldType.StepTest
                ? (focusedField.sectionField.allowedValues![focusedField.currentStepperIndex!] as string)
                : focusedField.sectionField.label
            }
            defaultAnswer={
              focusedField.sectionField.type === HootAssessmentFieldType.StepTest
                ? form.getValues(focusedField.sectionField.name)?.[focusedField.currentStepperIndex!] ?? SegmentAnswer.NoAnswer
                : form.getValues(focusedField.sectionField.name)
            }
            onSubmit={(newAnswer) => onAnswerEdited(newAnswer, focusedField)}
            onCancel={onDismissEditDialog}
          />
        )}
    </Box>
  );
};

export default HRAForm;
