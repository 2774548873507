import { useEffect } from 'react';
import { EventType } from '@hoot/events/eventType';
import { DisconnectOtherSessionRequestMessage, DisconnectOtherSessionResponseMessage } from '@hoot/events/messages/disconnect-other-session.message';
import { JoinLessonMessage } from '@hoot/events/messages/join-lesson.message';
import { useSocketSubscription } from '@hoot/hooks/useSocketSubscription';
import { useSocket } from '../../../context/SocketContext';
import HootTypography from '../core/HootTypography';
import BasicAlertDialog from './BasicAlertDialog';

interface AlreadyInLessonProps {
  closeModal: () => void;
  lessonId: string;
}

const AlreadyInLessonDialog = (props: AlreadyInLessonProps) => {
  const { closeModal, lessonId } = props;
  const { socket } = useSocket();

  const { data } = useSocketSubscription<DisconnectOtherSessionResponseMessage>(EventType.DisconnectOtherSessionFromLesson);

  const handleClose = () => {
    closeModal();
  };

  const handleJoinLessonClick = () => {
    const requestMessage: DisconnectOtherSessionRequestMessage = {
      lessonId,
    };

    socket.emit(EventType.DisconnectOtherSessionFromLesson, requestMessage);
  };

  useEffect(() => {
    if (data?.lessonId) {
      const joinLessonMessage: JoinLessonMessage = {
        lessonId: data.lessonId,
      };
      socket.emit(EventType.JoinLesson, joinLessonMessage);
      closeModal();
    }
  }, [closeModal, data?.lessonId, socket]);

  return (
    <BasicAlertDialog
      title={'You have already joined this Lesson'}
      onDismiss={handleClose}
      show={true}
      maxWidth="sm"
      content={
        <HootTypography isPII={false}>
          It looks like you are in this lesson in another window or tab. Would you like to join from this window instead?
        </HootTypography>
      }
      primaryAction={{
        label: 'Yes, Join Lesson',
        onClick: handleJoinLessonClick,
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: handleClose,
      }}
    />
  );
};

export default AlreadyInLessonDialog;
