import { useCallback, useEffect, useRef, useState } from 'react';

const useIntersectionObserver = () => {
  const [element, setElement] = useState<Element | null>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observer = useRef<null | IntersectionObserver>(null);

  const cleanOb = () => {
    if (observer.current) {
      observer.current.disconnect();
    }
  };

  const observerRef = useCallback((node: Element) => {
    setElement(node);
  }, []);

  useEffect(() => {
    if (!element) {
      return;
    }
    cleanOb();

    const ob = (observer.current = new IntersectionObserver(([entry]) => {
      const isElementIntersecting = entry.isIntersecting;
      setIsIntersecting(isElementIntersecting);
    }));
    ob.observe(element);

    return () => {
      cleanOb();
    };
  }, [element]);

  return { observerRef, isIntersecting };
};

export default useIntersectionObserver;
