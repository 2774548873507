import { Fade, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Stack } from '@mui/system';
import { capitalCase } from 'change-case';
import { InstructionalFocus } from '@hoot/hooks/api/lesson-reviews/useGetLessonReview';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import Card from '@hoot/ui/components/v2/core/Card';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';

interface ReviewBookTableRow {
  bookId: string;
  title: string;
  state: string | undefined;
  progress: string | undefined;
  focus: string | undefined;
  unit: string | undefined;
  level: string;
}

interface StudentDetailsCardProps {
  instructionalFocus?: InstructionalFocus;
  isPrimary?: boolean;
  isLoading: boolean;
}

const LessonReviewFocusCard = (props: StudentDetailsCardProps) => {
  const { instructionalFocus, isPrimary, isLoading } = props;
  const theme = useTheme();
  const sub900px = useMediaQuery(theme.breakpoints.down('md'));

  const getViewState = (): ViewStateEnum => {
    if (isLoading) {
      return ViewStateEnum.Loading;
    } else if (instructionalFocus) {
      return ViewStateEnum.Results;
    } else {
      // If not loading, and we don't have a student, then this is an invalid state.
      return ViewStateEnum.Error;
    }
  };

  const emptyLabel = '---';

  const headers: HeaderData<ReviewBookTableRow>[] = [
    { name: 'BookId', property: 'bookId', isHidden: true },
    { name: 'Title', property: 'title' },
    { name: 'State', property: 'state', isHidden: sub900px },
    { name: 'Progress', property: 'progress' },
    { name: 'Focus', property: 'focus' },
    { name: 'Unit', property: 'unit' },
    { name: 'Level', property: 'level', isHidden: sub900px },
  ];

  const rowData =
    instructionalFocus?.books.map<ReviewBookTableRow>((row) => ({
      bookId: row.bookId,
      title: row.title,
      state: capitalCase(row.state ?? ''),
      progress: capitalCase(row.progress ?? ''),
      focus: row.focus,
      unit: row.unit,
      level: row.level,
    })) ?? [];

  return (
    <Card
      title={
        <Stack alignSelf="center" direction="row" gap={1}>
          <Icon name="collections_filled" />
          {isPrimary ? 'Primary' : 'Secondary'} Focus Unit
        </Stack>
      }
      isLoading={isLoading}
    >
      <ViewState state={getViewState()} loadingContent={<SkeletonContent />}>
        <Fade in>
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <ReadOnlyTextField
                  label={`${isPrimary ? 'Primary' : 'Secondary'} Focus`}
                  body={capitalCase(instructionalFocus?.focus ?? '') || emptyLabel}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <ReadOnlyTextField
                  label={`${isPrimary ? 'Primary' : 'Secondary'} Focus Unit`}
                  body={capitalCase(instructionalFocus?.unit ?? '') || emptyLabel}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid size={12}>
                <TableV2
                  data={rowData}
                  headers={headers}
                  count={instructionalFocus?.books.length}
                  emptyViewState={<ViewStateIllustration illustration={IllustrationEnum.EmptyState} />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </ViewState>
    </Card>
  );
};

const SkeletonContent = () => {
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, md: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
    </Grid>
  );
};

export default LessonReviewFocusCard;
