import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { FrustrationLevel, HootAssessmentInstructionIndicator } from '@hoot/models/api/enums/hoot-reading-assessment';
import { Assessment } from './useGetAssessmentById';

export interface AssessmentUnitSubmission {
  submissionId?: string;
  studentProfileId: string;
  teacherAccountId: string;
  score?: number;
  instructionIndicator?: HootAssessmentInstructionIndicator;
  frustrationLevel?: FrustrationLevel;
  submissionData?: any;
  transitionScore?: number;
}

async function submitAssessment(assessmentId: string, unitId: string, submit: AssessmentUnitSubmission): Promise<Assessment> {
  const { data } = await axios.post<Assessment>(`${config.apiUrl}/public/hoot-assessment/${assessmentId}/unit/${unitId}`, submit);
  return data;
}

export function useSubmitAssessment(
  assessmentId: string,
  unitId: string,
  options?: Omit<UseMutationOptions<Assessment, AxiosError, AssessmentUnitSubmission>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation((submit) => submitAssessment(assessmentId, unitId, submit), options);
}
