import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

async function pickUpLessonOpportunity(lessonId: string): Promise<void> {
  await axios.put<void>(`${config.apiUrl}/public/lesson-opportunity/pick-up/${lessonId}`);
}

export default function usePickUpLessonOpportunity(options?: Omit<UseMutationOptions<void, AxiosError, string>, 'mutationKey' | 'mutationFn'>) {
  return useMutation<void, AxiosError, string>((lessonId) => pickUpLessonOpportunity(lessonId), options);
}
