import { CustomerAccount } from './customer';
import { StudentProfile } from './student';
import { TeacherAccount } from './teacher';

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isEnabled: boolean;
  profiles: Profile[];
  studentProfiles: StudentProfile[];
  parentProfiles: ParentProfile[];
  timeZone: string;
  customerAccount?: CustomerAccount;
  teacherAccount?: TeacherAccount;
  isOnline: boolean;
  country: string | null;
  provinceState: string | null;
  city: string | null;
  streetAddress: string;
  postalZipCode: string;
}

export interface Profile {
  id: string;
  name: string;
  number: number;
  type: UserProfileType;
  isEnabled: boolean;
  createdAt?: number;
}

export enum UserProfileType {
  Parent = 'PARENT',
  Student = 'STUDENT',
  Teacher = 'TEACHER',
  DistrictRep = 'DISTRICT_REP',
}

export const userProfileLabel: Record<UserProfileType, string> = {
  [UserProfileType.Parent]: 'Parent/Guardian',
  [UserProfileType.Student]: 'Student',
  [UserProfileType.Teacher]: 'Teacher',
  [UserProfileType.DistrictRep]: 'District Rep',
};

export interface ParentProfile {
  id: string;
  name: string;
  number: number;
  type: UserProfileType;
  isEnabled: boolean;
  createdAt: number;
}
