import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Switch, useMediaQuery, useTheme } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { CookieConsentStatus } from '../../../../models/api/auth';
import { useCookieConsent } from '../../../context/CookieConsentContext';
import { Button } from '../core/Button';
import { Checkbox } from '../core/Checkbox';
import HootTypography from '../core/HootTypography';
import { Icon } from '../core/Icon';

interface Form {
  strictlyNecessaryCookies: boolean;
  analyticalCookies: boolean;
}

export function CookieConsentDialog() {
  const { openModal, updateCookieConsent } = useCookieConsent();
  const { control, handleSubmit } = useForm<Form>({
    defaultValues: {
      analyticalCookies: true,
      strictlyNecessaryCookies: true,
    },
  });

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const onClickApply = (data: Form) => {
    if (data.analyticalCookies && data.strictlyNecessaryCookies) {
      updateCookieConsent(CookieConsentStatus.AllowAll);
    } else {
      updateCookieConsent(CookieConsentStatus.StrictlyNecessary);
    }
  };

  if (!openModal) {
    return null;
  }

  return (
    <Dialog open={openModal} maxWidth={'md'}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>How do you like your cookies?</DialogTitle>
      <DialogContent sx={{ paddingBottom: '4px' }}>
        <Stack>
          <HootTypography isPII={false} variant="bodylarge">
            We use cookies to offer the best possible in-app experience.
          </HootTypography>
          <Box sx={{ marginTop: '16px', display: 'flex', alignItems: 'center', gap: '32px' }}>
            <Controller
              name="strictlyNecessaryCookies"
              control={control}
              render={({ field }) => {
                if (isDesktop) {
                  return <Switch disabled onChange={field.onChange} checked={field.value} />;
                } else {
                  return <Checkbox disabled onChange={field.onChange} checked={field.value} sx={{ marginLeft: '8px' }} />;
                }
              }}
            />
            <Stack gap={'8px'}>
              <HootTypography isPII={false} variant="titlesmall">
                Strictly Necessary Cookies
              </HootTypography>
              <HootTypography isPII={false} variant="labelmedium">
                These cookies are required for the Hoot Reading App to be functional. They are set in response to requests made by you. They cannot be
                turned off.
              </HootTypography>
            </Stack>
          </Box>
          <Box sx={{ marginTop: '24px', display: 'flex', alignItems: 'center', gap: '32px' }}>
            <Controller
              name="analyticalCookies"
              control={control}
              render={({ field }) => {
                if (isDesktop) {
                  return <Switch onChange={field.onChange} checked={field.value} />;
                } else {
                  return <Checkbox onChange={field.onChange} checked={field.value} sx={{ marginLeft: '8px' }} />;
                }
              }}
            />
            <Stack gap={'8px'}>
              <HootTypography isPII={false} variant="titlesmall">
                Analytical Cookies
              </HootTypography>
              <HootTypography isPII={false} variant="labelmedium">
                These cookies collect information to help us understand how our website is being used and to improve and customize your experience.
              </HootTypography>
            </Stack>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        {isDesktop ? (
          <>
            <Button
              onClick={() => window.open('https://www.hootreading.com/app-privacy', '_blank')}
              variant="outlined"
              sx={{ display: 'flex', gap: '8px' }}
            >
              <Icon name="new_tab" /> View Privacy Policy
            </Button>
            <Button data-hoot-canary-id={'btnAccept'} onClick={handleSubmit(onClickApply)} variant="contained">
              Accept
            </Button>
          </>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
            <Button
              onClick={() => window.open('https://www.hootreading.com/app-privacy', '_blank')}
              variant="outlined"
              sx={{ display: 'flex', gap: '8px' }}
              fullWidth
            >
              <Icon name="new_tab" /> View Privacy Policy
            </Button>
            <Button data-hoot-canary-id={'btnAccept'} onClick={handleSubmit(onClickApply)} variant="contained" fullWidth>
              Accept
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
}
