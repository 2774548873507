import { Box, IconButton, Stack, Typography, keyframes, styled } from '@mui/material';
import { DateTime } from 'luxon';
import { DAYS_OF_WEEK } from '../../../../constants/constants';
import { spliceChunk } from '../../../../utils/array';
import { hootTokens } from '../../../theme/v2/tokens';
import { Button } from '../core/Button';
import { Checkbox } from '../core/Checkbox';
import { Icon } from '../core/Icon';

const SCHEDULED_COLOR = hootTokens.palette.secondary[120];
const CANCELLED_COLOR = hootTokens.palette.error[120];
const COMPLETED_COLOR = hootTokens.palette.success[120];

export function CalendarV3(props: {
  month: DateTime;
  onMonthChange: (month: DateTime) => void;
  renderCalendarDate: (date: DateTime) => React.ReactNode;
  onDateClick: (date: DateTime) => () => void;
  showCancelledEvents: boolean;
  onShowCancelledEventsClick: () => void;
  onNewEventClick?: () => void;
  isLoading?: boolean;
}) {
  const now = DateTime.now();

  const startDate = props.month.startOf('week').minus({ day: 1 });
  const endDate = props.month.endOf('month').endOf('week').minus({ day: 1 });
  const dates = spliceChunk(generateDateRange(startDate, endDate), 7);

  const handlePrevClick = () => {
    props.onMonthChange(props.month.minus({ month: 1 }));
  };

  const handleNextClick = () => {
    props.onMonthChange(props.month.plus({ month: 1 }));
  };

  return (
    <Stack position="relative">
      <Stack position="relative">
        {props.isLoading ? <LoadingAnimation /> : null}
        <Calendar sx={{ marginTop: '32px', position: 'relative' }}>
          <CalendarHeader
            sx={{
              position: 'sticky',
              backgroundColor: hootTokens.palette.white,
              top: 0,
              boxShadow: '0 4px 8px -4px rgba(0, 0, 0, 0.15), 0 4px 3px -2px rgba(0, 0, 0, 0.30)',
            }}
          >
            <CalendarRow>
              <CalendarHeaderCell colSpan={7}>
                <Header
                  onNextClick={handleNextClick}
                  onPrevClick={handlePrevClick}
                  currentMonth={props.month}
                  onNewEventClick={props.onNewEventClick}
                />
              </CalendarHeaderCell>
            </CalendarRow>
            <CalendarRow>
              {DAYS_OF_WEEK.map((header) => (
                <CalendarHeaderCell
                  sx={{
                    borderBottom: `solid 1px red`,
                  }}
                >
                  <Typography variant="titlesmall">{header}</Typography>
                </CalendarHeaderCell>
              ))}
            </CalendarRow>
          </CalendarHeader>
          <CalendarBody>
            {dates.map((dateRow) => (
              <CalendarRow>
                {dateRow.map((date) => (
                  <CalendarCell onClick={props.onDateClick(date)}>
                    <Stack padding="16px" sx={{ height: '100%', width: '100%' }} alignItems="center" justifyContent="flex-start" spacing="8px">
                      {date.toISODate() === now.toISODate() ? (
                        <Typography
                          textAlign="center"
                          sx={{
                            borderRadius: '100px',
                            backgroundColor: hootTokens.palette.black,
                            color: hootTokens.palette.white,
                            width: '37px',
                            height: '33px',
                          }}
                          variant="titlelarge"
                        >
                          {date.toFormat('d')}
                        </Typography>
                      ) : (
                        <Typography variant="titlelarge">{date.toFormat('d')}</Typography>
                      )}
                      {props.renderCalendarDate(date)}
                    </Stack>
                  </CalendarCell>
                ))}
              </CalendarRow>
            ))}
          </CalendarBody>
        </Calendar>
      </Stack>
      <Footer showCancelledEvents={props.showCancelledEvents} onShowCancelledEventsClick={props.onShowCancelledEventsClick} />
    </Stack>
  );
}

function Header(props: { currentMonth: DateTime; onNextClick: () => void; onPrevClick: () => void; onNewEventClick?: () => void }) {
  return (
    <Stack justifyContent="center" direction="row">
      <Box flex={1} />
      <Stack flex={1} direction="row" justifyContent="center" padding="16px">
        <IconButton onClick={props.onPrevClick}>
          <Icon name="chevron" />
        </IconButton>
        <Typography textAlign="center" sx={{ width: '400px' }} variant="displaysmall">
          {props.currentMonth.toFormat('LLLL yyyy')}
        </Typography>
        <IconButton onClick={props.onNextClick}>
          <Icon name="chevron" sx={{ rotate: '180deg' }} />
        </IconButton>
      </Stack>

      <Stack flex={1} direction="row" justifyContent="flex-end">
        {props.onNewEventClick ? (
          <Button onClick={props.onNewEventClick} variant="contained" startIcon={<Icon htmlColor={hootTokens.palette.white} name="add" />}>
            New Event
          </Button>
        ) : null}
      </Stack>
    </Stack>
  );
}

function Footer(props: { showCancelledEvents: boolean; onShowCancelledEventsClick: (e: React.MouseEvent<HTMLButtonElement>) => void }) {
  return (
    <Stack sx={{ marginTop: '24px', marginLeft: '24px' }} direction="row" justifyContent="space-between">
      <Stack direction="row">
        <Checkbox label="Show Cancelled Events" checked={props.showCancelledEvents} onClick={props.onShowCancelledEventsClick} />
      </Stack>
      <Stack direction="row" spacing="16px">
        <Stack direction="row" alignItems="center" spacing="8px">
          <Rectangle color={SCHEDULED_COLOR} />
          <Typography variant="bodysmall">Scheduled</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing="8px">
          <Rectangle color={COMPLETED_COLOR} />
          <Typography variant="bodysmall">Completed</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing="8px">
          <Rectangle color={CANCELLED_COLOR} />
          <Typography variant="bodysmall">Cancelled</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

function Rectangle(props: { color: string }) {
  return <Box sx={{ height: '15px', width: '15px', borderRadius: '4px', backgroundColor: props.color }} />;
}

function generateDateRange(startDate: DateTime, endDate: DateTime) {
  if (endDate < startDate) {
    return [];
  }

  const results: DateTime[] = [];
  let currentDate = startDate;
  while (currentDate < endDate) {
    results.push(currentDate);
    currentDate = currentDate.plus({ day: 1 });
  }

  return results;
}

/**
 * STYLES
 */

const Calendar = styled('table')({
  borderCollapse: 'collapse',
  width: '100%',
  tableLayout: 'fixed',
});
const CalendarHeader = styled('thead')({
  height: '64px',
});
const CalendarHeaderCell = styled('th')({});
const CalendarBody = styled('tbody')({
  borderRadius: '8px',
  borderStyle: 'hidden',
  boxShadow: `0 0 0 1px ${hootTokens.palette.neutral[80]}`,
});
const CalendarRow = styled('tr')({});
const CalendarCell = styled('td')({
  border: `solid 1px ${hootTokens.palette.neutral[80]}`,
  height: '175px',
});

const shimmerAnimation = keyframes`
  to {
     background-position-x: 0%
  }
`;

const LoadingAnimation = styled(Stack)({
  borderRadius: '8px',
  position: 'absolute',
  height: '100%',
  width: '100%',
  background: `linear-gradient(-45deg, ${hootTokens.palette.neutral['180']} 60%, ${hootTokens.palette.neutral['140']} 70%, ${hootTokens.palette.neutral['180']} 80%)`,
  backgroundSize: '300%',
  backgroundPositionX: '100%',
  animation: `${shimmerAnimation} 1s infinite linear`,
  opacity: 0.5,
});
