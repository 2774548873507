import { Card, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useSelector } from 'react-redux';
import { RootState } from '@hoot/redux/store';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import Page from '@hoot/ui/components/v2/core/Page';
import BookmarkedNotifications from '@hoot/ui/pages/v2/district-rep/dashboard/BookmarkedNotifications';
import Notifications from '@hoot/ui/pages/v2/district-rep/dashboard/Notifications';
import HootTypography from '../../../../components/v2/core/HootTypography';
import LocationDetails from './LocationDetails';

export function DistrictRepDashboard() {
  const name = useSelector((state: RootState) => state.profile.profile?.name);

  return (
    <>
      <SubHeader
        title={{
          label: 'Dashboard',
          isPII: false,
        }}
      />

      <Page pageTitle="Dashboard | Hoot Reading">
        <Grid container spacing={2}>
          <Grid size={{ lg: 4, md: 5, sm: 12 }}>
            <Stack gap={2}>
              <Card>
                <HootTypography isPII={true} variant="titlemedium">
                  Welcome {name}!
                </HootTypography>
                <HootTypography isPII={false} marginTop="16px" variant="bodylarge">
                  Welcome to the Hoot Reading App.
                </HootTypography>
                <HootTypography isPII={false} marginTop="16px" variant="bodylarge">
                  Here, you can manage enrolments, update student passwords, monitor lessons, and receive real-time updates on lesson activity.
                </HootTypography>
                <HootTypography isPII={false} marginTop="16px" variant="bodylarge">
                  Happy reading!
                </HootTypography>
              </Card>
              <LocationDetails />
              <BookmarkedNotifications />
            </Stack>
          </Grid>
          <Grid size={{ lg: 8, md: 7, sm: 12 }}>
            <Notifications sx={{ flex: 1 }} />
          </Grid>
        </Grid>
      </Page>
    </>
  );
}
