import axios, { AxiosError, AxiosResponse } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { CreateLessonReviewRequest } from '@hoot/hooks/api/lesson-reviews/useCreateLessonReview';
import { ErrorResponseDto } from '@hoot/models/api/error-response-dto';

export default function useUpdateLessonReview(
  lessonId: string,
  options?: Omit<UseMutationOptions<AxiosResponse<void>, AxiosError<ErrorResponseDto>, CreateLessonReviewRequest>, 'mutationKey' | 'mutationFn'>,
) {
  // FYI - This "update" lesson review endpoint takes in the exact same data as the "create" endpoint.
  return useMutation(async (data: CreateLessonReviewRequest) => {
    return await axios.put<void>(`${config.apiUrl}/public/lesson-review/lesson/${lessonId}`, data);
  }, options);
}
