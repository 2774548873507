import { Box, CircularProgress } from '@mui/material';
import * as CSS from 'csstype';
import { Profile } from '@hoot/models/api/user';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import SmileRectangle from '@hoot/ui/components/v2/icons/SmileRectangle';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface AvatarCardProps {
  profile: Profile;
  index: number;
  onProfileSelected: () => void;
}

const avatarColors: CSS.Property.Color[] = [
  hootTokens.palette.success['100'],
  hootTokens.palette.secondary['100'],
  hootTokens.palette.error['100'],
  hootTokens.palette.warning['100'],
];

const UserAvatarCard = (props: AvatarCardProps) => {
  const { profile, index, onProfileSelected } = props;

  const color = avatarColors[index % avatarColors.length];

  const { isAssumingProfileId } = useAuth();

  return (
    <Box
      data-hoot-canary-id="student-profile-avatar"
      onClick={onProfileSelected}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
        width: '256px',
        height: '100%',
        cursor: 'pointer',
        transition: '0.3s',
        '&:hover': {
          opacity: 0.8,
        },
      }}
    >
      <Card
        sx={{
          borderRadius: '8px',
          width: '100%',
          height: '256px',
          background: color,
        }}
        contentSx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
        elevation={5}
      >
        {isAssumingProfileId === profile.id ? (
          <CircularProgress size={100} sx={{ color: hootTokens.palette.white, opacity: 0.4 }} />
        ) : (
          <SmileRectangle />
        )}
      </Card>
      <HootTypography
        isPII={true}
        sx={{
          color: color,
          textAlign: 'center',
        }}
        variant="displaysmall"
      >
        {profile.name}
      </HootTypography>
    </Box>
  );
};

export default UserAvatarCard;
