import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { capitalCase } from 'change-case';
import { UseFormWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useGetDistrictRepPermissions, { DistrictRepAccessLevel } from '@hoot/hooks/api/user/useGetDistrictRepPermissions';
import { RootState } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { hasDistrictPermission } from '@hoot/routes/secure/ProtectedRoute';
import ProtectedLink from '@hoot/ui/components/v2/ProtectedLink';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import Card from '@hoot/ui/components/v2/core/Card';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';
import { StudentDetailsForm } from '@hoot/ui/pages/v2/district-rep/students/details/StudentDetailsPage';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface Props {
  isLoading: boolean;
  isError: boolean;
  watch: UseFormWatch<StudentDetailsForm>;
}

const StudentInfoCard = (props: Props) => {
  const { isLoading, isError, watch } = props;
  const districtRepresentativeId = useSelector((state: RootState) => state.profile.profile?.id);
  const { data: districtRepPermission } = useGetDistrictRepPermissions(districtRepresentativeId || '');
  const hasSchoolOrDistrictPermission = hasDistrictPermission(DistrictRepAccessLevel.SchoolOrDistrict, districtRepPermission);
  const { name, number, grade, status, pronouns, additionalDetails, hfsLocations } = watch();

  const getViewState = (): ViewStateEnum => {
    if (isLoading) {
      return ViewStateEnum.Loading;
    } else if (isError) {
      return ViewStateEnum.Error;
    } else {
      return ViewStateEnum.Results;
    }
  };

  return (
    <>
      <Card title="Student Details" isLoading={isLoading}>
        <ViewState state={getViewState()} loadingContent={<SkeletonContent />}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <ReadOnlyTextField label="Student name" body={name ?? ''} sx={{ background: hootTokens.palette.warning[190] }} />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <ReadOnlyTextField label="Student Number" body={`${number ?? ''}`} sx={{ background: hootTokens.palette.warning[190] }} />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <ReadOnlyTextField label="Status" body={capitalCase(status) ?? ''} sx={{ background: hootTokens.palette.warning[190] }} />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <ReadOnlyTextField label="Grade" body={grade ? capitalCase(grade) : ''} sx={{ background: hootTokens.palette.warning[190] }} />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <ReadOnlyTextField label="Student pronouns" body={pronouns ?? ''} sx={{ background: hootTokens.palette.warning[190] }} />
            </Grid>
            <Grid container size={{ xs: 12, sm: 6 }} spacing={2} direction="column">
              {hfsLocations?.map((location) => {
                return (
                  <Grid size={{ xs: 'auto' }}>
                    <ReadOnlyTextField
                      label="School / Division"
                      body={location?.name ?? ''}
                      sx={{ background: hootTokens.palette.warning[190], minHeight: '58px', alignItems: 'center' }}
                      endAdornment={
                        <ProtectedLink
                          hasPermission={hasSchoolOrDistrictPermission}
                          to={location ? routesDictionary.schools.school.students.url(location?.id) : ''}
                        >
                          {hasSchoolOrDistrictPermission ? <Icon name="new_tab" /> : null}
                        </ProtectedLink>
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Grid size={12}>
              <ReadOnlyTextField label="Additional details" body={additionalDetails ?? ''} sx={{ background: hootTokens.palette.warning[190] }} />
            </Grid>
          </Grid>
        </ViewState>
      </Card>
    </>
  );
};

const SkeletonContent = () => {
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
    </Grid>
  );
};

export default StudentInfoCard;
