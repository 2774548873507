//This file holds wrapper functions on the zendesk messenger library methods
// https://developer.zendesk.com/api-reference/widget-messaging/introduction/
import { config } from '@hoot/config';
import { UserProfileType } from '@hoot/models/api/user';

const scriptId = 'ze-snippet';

export const LIVE_CHAT_TITLE = 'Live Chat Support';

export function zendeskInitializeLiveChatScript(currentProfileType: UserProfileType) {
  if (currentProfileType === UserProfileType.Student) {
    console.warn('Zendesk live chat not supported for student profiles.');
    return;
  }

  const liveChatId = getLiveChatId(currentProfileType);
  const script = document.createElement('script');
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${liveChatId}`;
  script.id = scriptId;

  const existingScript = document.getElementById(scriptId);

  // Need this if we've already loaded as script for an existing profile
  if (existingScript) {
    existingScript.replaceWith(script);
  } else {
    document.body.appendChild(script);
  }
}

export function zendeskLiveChatScriptInitialized(): boolean {
  // @ts-ignore
  return typeof window['zE'] === 'function';
}

function getLiveChatId(currentProfileType: UserProfileType): string | null {
  switch (currentProfileType) {
    case UserProfileType.Teacher:
      return config.zendeskLiveChatIdTeacher;
    case UserProfileType.Parent:
      return config.zendeskLiveChatIdParent;
    case UserProfileType.DistrictRep:
      return config.zendeskLiveChatIdDistrictRep;
  }

  return null;
}

export function zendeskOpenLiveChat() {
  if (zendeskLiveChatScriptInitialized()) {
    (window as any).zE('messenger', 'open');
  } else {
    console.error("Zendesk 'zE' not found. Could not open Zendesk messenger.");
  }
}

export function zendeskCloseLiveChat() {
  if (zendeskLiveChatScriptInitialized()) {
    (window as any).zE('messenger', 'close');
  } else {
    console.error("Zendesk 'zE' not found. Could not close Zendesk messenger.");
  }
}

export function zendeskLoginUser(zendeskToken: string) {
  if (zendeskLiveChatScriptInitialized()) {
    (window as any).zE('messenger', 'loginUser', function (callback: any) {
      callback(zendeskToken);
    });
  } else {
    console.error("Zendesk 'zE' not found. Could not login Zendesk user.");
  }
}

export function zendeskLogoutUser() {
  if (zendeskLiveChatScriptInitialized()) {
    try {
      (window as any).zE('messenger', 'logoutUser');
    } catch (err) {
      console.error(`Failed call to zendesk logoutUser: ${err}`);
    }
  } else {
    console.error("Zendesk 'zE' not found. Could not logout Zendesk user.");
  }
}
