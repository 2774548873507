import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { Control } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { UnitSection } from '@hoot/hooks/api/assessment/useGetAssessmentUnit';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Field } from './Field';

interface ISectionProps {
  section: UnitSection;
  control: Control;
  setValue: UseFormSetValue<any>;
  watching: Record<string, any>;
}

export const Section = (props: ISectionProps) => {
  const { section, control, watching, setValue } = props;

  return (
    <Stack gap={2}>
      {section.title && (
        <HootTypography isPII={false} variant="titlemedium">
          {section.title}
        </HootTypography>
      )}
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: section.direction ?? 'column',
          justifyContent: section.justify,
          gap: section.direction === 'row' ? 2 : 1,
          // Render vertically on smaller screens regardless if the API tells us to render horizontally. Stuff gets too crammed.
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
          },
        })}
      >
        {/* Render section fields. */}
        {section.fields.map((field) => (
          <Field key={field.id} control={control} name={field.name} sectionField={field} watching={watching} section={section} setValue={setValue} />
        ))}
        {/* Render nested sections if there are any. */}
        {section.sections.map((section) => (
          <Section key={section.id} section={section} control={control} watching={watching} setValue={setValue} />
        ))}
      </Box>
    </Stack>
  );
};
