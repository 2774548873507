import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { ErrorResponseDto } from '@hoot/models/api/error-response-dto';
import { config } from '../../../config';

export interface ChangePasswordRequest {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword?: string;
  logoutAllActiveSessions: boolean;
}

async function changePassword(changePasswordRequest: ChangePasswordRequest): Promise<void> {
  await axios.put<void>(`${config.apiUrl}/auth/change-user-password`, changePasswordRequest);
}

export function useChangePassword(
  options?: Omit<UseMutationOptions<void, AxiosError<ErrorResponseDto>, ChangePasswordRequest>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation<void, AxiosError<ErrorResponseDto>, ChangePasswordRequest>((request) => changePassword(request), options);
}
