import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { LocationTypeEnum } from '@hoot/models/api/enums/location-type-enum';
import { StudentGrade } from '@hoot/models/api/student';
import { QueryKey } from '../queryKeys';

export interface StudentProfileV2Response {
  id: string;
  number: number;
  userId: string;
  name: string;
  birthYear: number | undefined;
  grade: StudentGrade | undefined;
  pronouns: string | undefined;
  parent: ParentResponse | undefined;
  hfsLocations: StudentHfsLocationResponse[];
  hootNotes: string | undefined;
}

export interface ParentResponse {
  name: string;
  email: string | undefined;
  phone: string | undefined;
  timezone: string;
  parentNotes: string | undefined;
}

export interface StudentHfsLocationResponse {
  studentAssignedLocationId: string;
  locationType: LocationTypeEnum;
  district?: {
    id: string;
    name: string;
  };
  school?: {
    id: string;
    name: string;
  };
}

export default function useGetStudentProfileV2(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<StudentProfileV2Response, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetStudentProfileV2, studentProfileId],
    async () => {
      return (await axios.get<StudentProfileV2Response>(`${config.apiUrl}/teacher/student-profile/v2/${studentProfileId}`)).data;
    },
    options,
  );
}
