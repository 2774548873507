import { Box, Skeleton, Stack, StackProps } from '@mui/material';
import { Link } from 'react-router-dom';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { Button, ButtonProps } from './core/Button';

interface SubHeaderProps {
  title: {
    label: string;
    /** isPII flag for the label. */
    isPII: boolean;
    /** Shows the loading skeleton for the label. */
    isLoading?: boolean;
  };
  backButton?: {
    show: boolean;
    /** Navigates to this path when back button is clicked. If no path is supplied, then we navigate back one path "segment". e.g. We go from `/user/{id}` to `/user`. */
    navigateBackPath?: string;
    /** For cases where we need to supply a custom void function when clicking the back button. */
    onBackButtonClicked?: () => void;
  };
  breadcrumbs?: Array<string>;
  primaryAction?: {
    label: string;
    onClick?: () => void;
    props?: ButtonProps;
  };
  secondaryAction?: {
    label: string;
    onClick?: () => void;
    props?: ButtonProps;
  };
  tertiaryAction?: {
    label: string;
    onClick?: () => void;
    props?: ButtonProps;
  };
  sx?: StackProps['sx'];
}

const SubHeader = (props: SubHeaderProps) => {
  const {
    title: { label, isPII, isLoading },
    backButton,
    primaryAction,
    secondaryAction,
    tertiaryAction,
    breadcrumbs,
    sx = {},
  } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        paddingX: 3,
        paddingY: 2,
        backgroundColor: hootTokens.palette.white,
        outline: '1px solid',
        outlineColor: hootTokens.surface[4],
        width: '100%',
        minHeight: '74px',
        ...sx,
      }}
    >
      {/* Back Button */}
      {backButton?.show && (
        <Link to={backButton.navigateBackPath ?? '..'} onClick={backButton.onBackButtonClicked}>
          <IconButton variant="contained" sx={{ width: 42, height: 42 }}>
            <Icon name="chevron" />
          </IconButton>
        </Link>
      )}

      {/* Heading & Breadcrumbs */}
      <Stack direction="column">
        {isLoading && <Skeleton variant="text" sx={{ width: '150px', ...hootTokens.text.titlelarge }} />}
        {!isLoading && (
          <HootTypography isPII={isPII} variant="titlelarge">
            {label}
          </HootTypography>
        )}

        {breadcrumbs && (
          <HootTypography isPII={isPII} variant="bodysmall">
            {breadcrumbs.map((bc) => bc).join(' > ')}
          </HootTypography>
        )}
      </Stack>

      {/* Action Buttons */}
      <Stack direction="row" gap={2} marginLeft="auto">
        {primaryAction && (
          <Button variant="contained" onClick={primaryAction?.onClick} {...(primaryAction?.props ?? {})}>
            {primaryAction?.label}
          </Button>
        )}

        {secondaryAction && (
          <Button variant="outlined" onClick={secondaryAction?.onClick} {...(secondaryAction?.props ?? {})}>
            {secondaryAction?.label}
          </Button>
        )}

        {tertiaryAction && (
          <Button variant="text" onClick={tertiaryAction?.onClick} {...(tertiaryAction?.props ?? {})}>
            {tertiaryAction?.label}
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default SubHeader;
