import { SvgIcon, SvgIconProps } from '@mui/material';

const GearIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9545 3.00024H11.0455C9.99109 3.00024 9.13635 3.80613 9.13635 4.80024C9.13635 5.93786 7.91916 6.66113 6.91999 6.11722L6.81851 6.06198C5.90541 5.56493 4.73782 5.8599 4.21064 6.72083L3.25609 8.27967C2.72891 9.1406 3.04176 10.2415 3.95487 10.7385C4.9545 11.2827 4.9545 12.7178 3.95487 13.262C3.04176 13.759 2.72891 14.8599 3.25609 15.7208L4.21064 17.2797C4.73782 18.1406 5.90541 18.4356 6.81851 17.9385L6.91999 17.8833C7.91916 17.3394 9.13635 18.0626 9.13635 19.2002C9.13635 20.1944 9.99109 21.0002 11.0455 21.0002H12.9545C14.0089 21.0002 14.8636 20.1944 14.8636 19.2002C14.8636 18.0626 16.0808 17.3394 17.08 17.8833L17.1815 17.9385C18.0946 18.4356 19.2622 18.1406 19.7894 17.2797L20.7439 15.7208C21.2711 14.8599 20.9582 13.759 20.0451 13.262C19.0455 12.7178 19.0455 11.2827 20.0451 10.7385C20.9582 10.2414 21.2711 9.14059 20.7439 8.27966L19.7894 6.72081C19.2622 5.85989 18.0946 5.56491 17.1815 6.06197L17.08 6.11721C16.0808 6.66112 14.8636 5.93786 14.8636 4.80024C14.8636 3.80613 14.0089 3.00024 12.9545 3.00024ZM12 15.0002C10.3431 15.0002 8.99999 13.6571 8.99999 12.0002C8.99999 10.3434 10.3431 9.00024 12 9.00024C13.6568 9.00024 15 10.3434 15 12.0002C15 13.6571 13.6568 15.0002 12 15.0002Z"
        fill={props.htmlColor ?? 'black'}
      />
    </SvgIcon>
  );
};

export default GearIcon;
