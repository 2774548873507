import { SvgIcon, SvgIconProps } from '@mui/material';

const InProgressIcon = (props: SvgIconProps) => {
  const { ...rest } = props;

  return (
    <SvgIcon width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" inheritViewBox {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4.00024C8 6.20938 6.20914 8.00024 4 8.00024C1.79086 8.00024 0 6.20938 0 4.00024C0 1.79111 1.79086 0.000244141 4 0.000244141C6.20914 0.000244141 8 1.79111 8 4.00024ZM7 4.00024C7 5.6571 5.65685 7.00024 4 7.00024V1.00024C5.65685 1.00024 7 2.34339 7 4.00024Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default InProgressIcon;
