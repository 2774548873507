import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.46852 12.4143C5.14507 12.1556 4.67311 12.208 4.41435 12.5315C4.15559 12.8549 4.20803 13.3269 4.53148 13.5857L5.46852 12.4143ZM8.52642 15.8211L8.0579 16.4068H8.0579L8.52642 15.8211ZM11.2624 15.5973L10.7049 15.0956L11.2624 15.5973ZM19.5575 7.50172C19.8346 7.19384 19.8096 6.71962 19.5017 6.44253C19.1938 6.16544 18.7196 6.19039 18.4425 6.49828L19.5575 7.50172ZM4.53148 13.5857L8.0579 16.4068L8.99494 15.2355L5.46852 12.4143L4.53148 13.5857ZM11.8199 16.0991L19.5575 7.50172L18.4425 6.49828L10.7049 15.0956L11.8199 16.0991ZM8.0579 16.4068C9.19546 17.3168 10.8453 17.1819 11.8199 16.0991L10.7049 15.0956C10.262 15.5878 9.51202 15.6491 8.99494 15.2355L8.0579 16.4068Z"
        fill={props.htmlColor ?? 'currentColor'}
      />
    </SvgIcon>
  );
};

export default CheckIcon;
