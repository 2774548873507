import { useContext, useEffect, useRef, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

export function useBlocker(when = true) {
  const { navigator } = useContext(NavigationContext);

  const [blocked, setBlocked] = useState<boolean>(false);
  const proceed = useRef<() => void>();

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;
    navigator.push = (...args: Parameters<typeof push>) => {
      setBlocked(true);
      proceed.current = () => push(...args);
    };
    return () => {
      navigator.push = push;
    };
  }, [navigator, when]);

  return [blocked, proceed.current] as [boolean, () => void];
}
