import { Box, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Controller, useForm } from 'react-hook-form';
import { VALID_EMAIL_REGEX } from '@hoot/constants/constants';
import { useSendPasswordResetEmail } from '@hoot/hooks/api/auth/useSendPasswordResetEmail';
import { error } from '@hoot/redux/reducers/alertSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import theme from '@hoot/ui/theme/v2';
import Card from '../../../../../components/v2/core/Card';
import TextField from '../../../../../components/v2/core/TextField';

interface Form {
  emailAddress: string;
}

interface IProps {
  setShowForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSuccess: React.Dispatch<React.SetStateAction<string>>;
}

const FreeLessonForgotPassword = (props: IProps) => {
  const { setShowForgotPassword, setShowSuccess } = props;
  const sendPasswordResetEmailMutation = useSendPasswordResetEmail();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const { handleSubmit, setError, reset, control } = useForm<Form>({
    defaultValues: {
      emailAddress: '',
    },
  });

  const onSubmit = async (data: Form) => {
    sendPasswordResetEmailMutation.mutateAsync(data.emailAddress, {
      onSuccess: () => {
        setShowSuccess(data.emailAddress);
        reset();
        setShowForgotPassword(false);
      },
      onError: () => {
        setError('emailAddress', {
          type: 'manual',
          message: 'An unexpected error occurred. Please try again.',
        });
        dispatch(error('Failed to send password reset link.'));
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ position: 'absolute', display: 'flex', top: 0, bottom: 0, left: 0, right: 0 }}>
        <Grid container alignItems={'center'} justifyContent={'center'}>
          <Card
            elevation={1}
            sx={{ width: isDesktop ? '348px' : '100%', borderRadius: '8px', margin: '16px' }}
            title="Password Reset"
            titleVariant="titlelarge"
          >
            <Grid container size={12} rowSpacing={3}>
              <Grid size={12}>
                <HootTypography isPII={false} variant="bodymedium">
                  After you submit your email address, you'll receive an email with a link that will let you change your password.
                </HootTypography>
              </Grid>
              <Grid size={12}>
                <Controller
                  control={control}
                  name="emailAddress"
                  rules={{
                    required: 'This field is required',
                    pattern: {
                      value: VALID_EMAIL_REGEX,
                      message: 'Not a valid email address',
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      type="email"
                      helperText={fieldState.error?.message}
                      required
                      error={!!fieldState.error}
                      value={field.value}
                      onChange={field.onChange}
                      label="Email address"
                    />
                  )}
                />
              </Grid>
              <Grid container size={12} display={'flex'} justifyContent={'flex-end'} gap={2}>
                <Grid size={4}>
                  <Button fullWidth size="medium" variant="outlined" onClick={() => setShowForgotPassword(false)}>
                    Cancel
                  </Button>
                </Grid>
                <Grid size={4}>
                  <Button type="submit" fullWidth color="primary" size="medium" variant="contained">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Box>
    </form>
  );
};

export default FreeLessonForgotPassword;
