import { DialogActions, DialogContent, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { LessonReviewEngagement } from '@hoot/models/api/enums/lesson-review-enums';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import {
  LessonReviewWizardStepEnum,
  useLessonReviewWizardContext,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';

interface LessonReviewStudentEngagementForm {
  studentEngagement: LessonReviewEngagement | undefined;
}

const LessonReviewStudentEngagementStep = () => {
  const {
    lessonToReview,
    studentEngagementState,
    actions: { goToNextStep, goToPreviousStep, attachStudentEngagementData },
  } = useLessonReviewWizardContext();

  const { getValues, control, watch } = useForm<LessonReviewStudentEngagementForm>({
    defaultValues: {
      studentEngagement: studentEngagementState,
    },
  });

  const submitAndGoToNextStep = () => {
    const { studentEngagement } = getValues();
    if (studentEngagement) {
      attachStudentEngagementData(studentEngagement);
      goToNextStep();
    }
  };

  const { studentEngagement } = watch();

  return (
    <>
      <DialogContent>
        <Stack gap={'16px'}>
          <HootTypography isPII={false} variant="titlemedium">
            {' '}
            Step {LessonReviewWizardStepEnum.StudentEngagement} - Student Engagement
          </HootTypography>
          <HootTypography isPII={true} variant="bodymedium">
            How engaged was <strong>{lessonToReview?.student.name}</strong> during the reading lesson?
          </HootTypography>
          <Stack>
            <Controller
              control={control}
              name="studentEngagement"
              render={({ field }) => (
                <RadioGroup defaultValue={studentEngagementState} onChange={field.onChange} sx={{ padding: '8px' }}>
                  <Stack direction={'column'} gap={'16px'}>
                    <FormControlLabel
                      value={LessonReviewEngagement.Throughout}
                      control={<Radio />}
                      label={
                        <HootTypography isPII={true}>
                          <strong>{lessonToReview?.student.name}</strong> was engaged throughout the lesson
                        </HootTypography>
                      }
                    />
                    <FormControlLabel
                      value={LessonReviewEngagement.MoreThanHalf}
                      control={<Radio />}
                      label={
                        <HootTypography isPII={true}>
                          <strong>{lessonToReview?.student.name}</strong> was engaged for more than half of the lesson
                        </HootTypography>
                      }
                    />
                    <FormControlLabel
                      value={LessonReviewEngagement.AboutHalf}
                      control={<Radio />}
                      label={
                        <HootTypography isPII={true}>
                          <strong>{lessonToReview?.student.name}</strong> was engaged for about half of the lesson
                        </HootTypography>
                      }
                    />
                    <FormControlLabel
                      value={LessonReviewEngagement.LessThanHalf}
                      control={<Radio />}
                      label={
                        <HootTypography isPII={true}>
                          <strong>{lessonToReview?.student.name}</strong> was engaged for less than half of the lesson
                        </HootTypography>
                      }
                    />
                    <FormControlLabel
                      value={LessonReviewEngagement.NotEngaged}
                      control={<Radio />}
                      label={
                        <HootTypography isPII={true}>
                          <strong>{lessonToReview?.student.name}</strong> was not engaged in the lesson
                        </HootTypography>
                      }
                    />
                  </Stack>
                </RadioGroup>
              )}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={goToPreviousStep}>
          Go Back
        </Button>
        <Button variant="contained" onClick={submitAndGoToNextStep} disabled={!studentEngagement}>
          Next
        </Button>
      </DialogActions>
    </>
  );
};

export default LessonReviewStudentEngagementStep;
