import * as CSS from 'csstype';

export interface HootPalette {
  0: CSS.Property.Color;
  20: CSS.Property.Color;
  40: CSS.Property.Color;
  60: CSS.Property.Color;
  80: CSS.Property.Color;
  100: CSS.Property.Color;
  120: CSS.Property.Color;
  140: CSS.Property.Color;
  160: CSS.Property.Color;
  180: CSS.Property.Color;
  190: CSS.Property.Color;
  195: CSS.Property.Color;
  200: CSS.Property.Color;
}

export interface HootColor {
  black: CSS.Property.Color;
  white: CSS.Property.Color;
  primary: HootPalette;
  secondary: HootPalette;
  error: HootPalette;
  warning: HootPalette;
  success: HootPalette;
  neutral: HootPalette;
}

export interface TextProps {
  fontSize: CSS.Property.FontSize;
  fontFamily: CSS.Property.FontFamily;
  fontStyle: CSS.Property.FontStyle;
  fontWeight: CSS.Property.FontWeight;
  lineHeight: CSS.Property.LineHeight;
  textDecoration: CSS.Property.TextDecoration;
  textTransform: CSS.Property.TextTransform;
}

export interface HootText {
  displaylarge: TextProps;
  displaymedium: TextProps;
  displaysmall: TextProps;
  headlinelarge: TextProps;
  headlinemedium: TextProps;
  headlinesmall: TextProps;
  titlelarge: TextProps;
  titlemedium: TextProps;
  titlesmall: TextProps;
  tableheading: TextProps;
  tableheadingactive: TextProps;
  tablevalue: TextProps;
  labellarge: TextProps;
  labelmedium: TextProps;
  labelsmall: TextProps;
  bodylarge: TextProps;
  bodymedium: TextProps;
  bodysmall: TextProps;
}

export interface ElevationProps {
  boxShadow: CSS.Property.BoxShadow;
}

export interface HootElevation {
  get: (val?: -5 | -4 | -3 | -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5) => ElevationProps;
  elevationMinus5: ElevationProps;
  elevationMinus4: ElevationProps;
  elevationMinus3: ElevationProps;
  elevationMinus2: ElevationProps;
  elevationMinus1: ElevationProps;
  none: ElevationProps;
  elevation1: ElevationProps;
  elevation2: ElevationProps;
  elevation3: ElevationProps;
  elevation4: ElevationProps;
  elevation5: ElevationProps;
}

export interface Surface {
  0: string;
  1: string;
  2: string;
  3: string;
  4: string;
}

export interface HootDesignTokens {
  palette: HootColor;
  text: HootText;
  elevation: HootElevation;
  surface: Surface;
}

export const hootTokens: HootDesignTokens = {
  palette: {
    black: '#000000',
    white: '#ffffff',
    primary: {
      0: '#000000',
      20: '#1a1a1a',
      40: '#333333',
      60: '#4d4d4d',
      80: '#666666',
      100: '#808080',
      120: '#999999',
      140: '#b2b2b2',
      160: '#cccccc',
      180: '#e5e5e5',
      190: '#f2f2f2',
      195: '#fafafa',
      200: '#ffffff',
    },
    secondary: {
      0: '#000000',
      20: '#022423',
      40: '#054745',
      60: '#076b68',
      80: '#0a8e8a',
      100: '#0cb2ad',
      120: '#3dc1bd',
      140: '#6dd1ce',
      160: '#9ee0de',
      180: '#cef0ef',
      190: '#e7f7f7',
      195: '#f3fbfb',
      200: '#ffffff',
    },
    error: {
      0: '#000000',
      20: '#300e07',
      40: '#601d0e',
      60: '#8f2b15',
      80: '#bf3a1c',
      100: '#ef4823',
      120: '#f26d4f',
      140: '#f5917b',
      160: '#f9b6a7',
      180: '#fcdad3',
      190: '#fdede9',
      195: '#fef6f4',
      200: '#ffffff',
    },
    warning: {
      0: '#000000',
      20: '#311d06',
      40: '#63390c',
      60: '#945613',
      80: '#c67219',
      100: '#f78f1f',
      120: '#f9a54c',
      140: '#fabc79',
      160: '#fcd2a5',
      180: '#fde9d2',
      190: '#fef4e9',
      195: '#fff9f4',
      200: '#ffffff',
    },
    success: {
      0: '#000000',
      20: '#16250e',
      40: '#2b4a1b',
      60: '#416f29',
      80: '#569436',
      100: '#6cb944',
      120: '#89c769',
      140: '#a7d58f',
      160: '#c4e3b4',
      180: '#e2f1da',
      190: '#f0f8ec',
      195: '#f8fbf6',
      200: '#ffffff',
    },
    neutral: {
      0: '#000000',
      20: '#1a1a1a',
      40: '#333333',
      60: '#4d4d4d',
      80: '#666666',
      100: '#808080',
      120: '#999999',
      140: '#b2b2b2',
      160: '#cccccc',
      180: '#e5e5e5',
      190: '#f2f2f2',
      195: '#fafafa',
      200: '#ffffff',
    },
  },
  text: {
    displaylarge: {
      fontSize: '57px',
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
    displaymedium: {
      fontSize: '45px',
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
    displaysmall: {
      fontSize: '32px',
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
    headlinelarge: {
      fontSize: '32px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
    headlinemedium: {
      fontSize: '24px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
    headlinesmall: {
      fontSize: '20px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
    titlelarge: {
      fontSize: '22px',
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
    titlemedium: {
      fontSize: '16px',
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
    titlesmall: {
      fontSize: '14px',
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
    tableheading: {
      fontSize: '12px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
    tableheadingactive: {
      fontSize: '12px',
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'uppercase',
    },
    tablevalue: {
      fontSize: '13px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
    labellarge: {
      fontSize: '16px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
    labelmedium: {
      fontSize: '14px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
    labelsmall: {
      fontSize: '12px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
    bodylarge: {
      fontSize: '16px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
    bodymedium: {
      fontSize: '14px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
    bodysmall: {
      fontSize: '12px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '150%',
      textDecoration: 'none',
      textTransform: 'none',
    },
  },
  elevation: {
    get: (val?: -5 | -4 | -3 | -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5 | undefined): ElevationProps => {
      switch (val) {
        case -5:
          return hootTokens.elevation.elevationMinus5;
        case -4:
          return hootTokens.elevation.elevationMinus4;
        case -3:
          return hootTokens.elevation.elevationMinus3;
        case -2:
          return hootTokens.elevation.elevationMinus2;
        case -1:
          return hootTokens.elevation.elevationMinus1;
        case 0:
          return hootTokens.elevation.none;
        case 1:
          return hootTokens.elevation.elevation1;
        case 2:
          return hootTokens.elevation.elevation2;
        case 3:
          return hootTokens.elevation.elevation3;
        case 4:
          return hootTokens.elevation.elevation4;
        case 5:
          return hootTokens.elevation.elevation5;
        default:
          return hootTokens.elevation.none;
      }
    },
    elevationMinus5: {
      boxShadow: '0px -8px 12px 6px rgba(0, 0, 0, 0.15), 0px -4px 4px rgba(0, 0, 0, 0.3)',
    },
    elevationMinus4: {
      boxShadow: '0px -6px 10px 4px rgba(0, 0, 0, 0.15), 0px -2px 3px rgba(0, 0, 0, 0.3)',
    },
    elevationMinus3: {
      boxShadow: '0px -4px 8px 3px rgba(0, 0, 0, 0.15), 0px -1px 3px rgba(0, 0, 0, 0.3)',
    },
    elevationMinus2: {
      boxShadow: '0px -1px 2px rgba(0, 0, 0, 0.3), 0px -2px 6px 2px rgba(0, 0, 0, 0.15)',
    },
    elevationMinus1: {
      boxShadow: '0px -1px 2px rgba(0, 0, 0, 0.3), 0px -1px 3px 1px rgba(0, 0, 0, 0.15)',
    },
    none: {
      boxShadow: 'none',
    },
    elevation1: {
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    },
    elevation2: {
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    },
    elevation3: {
      boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    },
    elevation4: {
      boxShadow: '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
    },
    elevation5: {
      boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)',
    },
  },
  surface: {
    0: '#FFFFFF',
    1: '#FAFAFA',
    2: '#F2F2F2',
    3: '#E5E5E5',
    4: '#CCCCCC',
  },
};
