import { Box, CircularProgress, Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';
import { ReactNode } from 'react';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';

export type ButtonProps = Omit<MuiButtonProps, 'color'> & { color?: ColorPaletteOption; isLoading?: boolean };

export function Button(props: ButtonProps) {
  const { isLoading = false, children, onClick, disabled, ...buttonProps } = props;

  const _onClick: ButtonProps['onClick'] = (event) => {
    // If we're in "loading" state, then override the onClick listener. Could also just disable the button when in loading
    // state, but that changes the styling of the button.
    if (!isLoading) {
      onClick?.(event);
    }
  };

  return (
    <MuiButton {...buttonProps} disabled={disabled || isLoading} style={{ position: 'relative' }} onClick={_onClick}>
      <Root isLoading={isLoading} children={children} />
      {isLoading && <CircularProgress size={28} sx={{ position: 'absolute', color: 'inherit' }} />}
    </MuiButton>
  );
}

const Root = (props: { children?: ReactNode; isLoading?: boolean }) => {
  const { children, isLoading } = props;

  return <>{!isLoading ? children : <Box visibility={isLoading ? 'hidden' : 'visible'} children={children} />}</>;
};
