const Clever = () => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_12596_2553)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 6.17812C21.8942 2.45201 17.6826 0 12.6731 0C5.66663 0 0 5.35688 0 11.9939C0 18.6309 5.66663 23.9879 12.6731 23.9998C17.6824 23.9998 21.894 21.548 24 17.8217L19.6479 15.5328C18.1995 17.9182 15.8513 19.4221 12.6733 19.4221C8.39156 19.4221 5.04131 16.0582 5.04131 11.9999C5.04131 7.94162 8.39156 4.57769 12.6733 4.57769C15.8513 4.57769 18.1995 6.08141 19.6479 8.46705L24 6.17812Z"
            fill="#1464FF"
          />
        </g>
        <defs>
          <clipPath id="clip0_12596_2553">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <svg width="1" height="24" viewBox="0 0 1 24" fill="none">
        <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="24" stroke="#1464FF" />
      </svg>
    </>
  );
};

export default Clever;
