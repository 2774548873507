import { SvgIcon, SvgIconProps } from '@mui/material';

const CenterCameraIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M8 2H6C3.79086 2 2 3.79086 2 6V8M16 2H18C20.2091 2 22 3.79086 22 6V8M22 16V18C22 20.2091 20.2091 22 18 22H16M8 22H6C3.79086 22 2 20.2091 2 18V16M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z"
        stroke={props.htmlColor ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default CenterCameraIcon;
