import { FormHelperText, Stack } from '@mui/material';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { Chip } from './Chip';
import HootTypography from './HootTypography';

export const trueFalseOptions: ChipGroupProps['items'] = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

type SingleSelect = {
  isMultiSelect?: false;
  value: string | boolean | number | null;
  onChange: (value: string | boolean | number | null) => void;
};

type MultiSelect = {
  isMultiSelect: true;
  value: string[];
  onChange: (value: string[]) => void;
};

export interface ChipGroupProps {
  label: string;
  items: BaseChipGroupItem[];
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  showBorderDecoration?: boolean;
  cannotRemoveSelection?: boolean;
  requiredField?: boolean;
  removePadding?: boolean;
}

interface BaseChipGroupItem {
  value: string | boolean | number;
  label: string;
}

type Props = ChipGroupProps & (SingleSelect | MultiSelect);

const ChipGroup = (props: Props) => {
  const {
    isMultiSelect,
    label,
    items,
    disabled,
    error,
    helperText,
    value,
    onChange,
    showBorderDecoration,
    cannotRemoveSelection,
    requiredField,
    removePadding = false,
  } = props;
  const stateIsArray = Array.isArray(value);

  const getColor = (): string => {
    if (disabled) {
      return hootTokens.palette.neutral[140];
    }
    if (error) {
      return hootTokens.palette.error[80];
    }
    return hootTokens.palette.black;
  };

  const handleOnClick = (selectedItem: BaseChipGroupItem) => () => {
    if (isMultiSelect) {
      if (value.some((v) => v === selectedItem.value)) {
        //deselect
        if (!(requiredField || (cannotRemoveSelection && value.length === 1))) {
          //allowing deselection on last element of the array selection
          onChange(value.filter((v) => v !== selectedItem.value));
        }
      } else {
        //select
        onChange([...value, selectedItem.value as string]);
      }
    } else {
      if (value !== selectedItem.value) {
        //deselect/select
        onChange(selectedItem.value);
      } else if (!cannotRemoveSelection && !requiredField && value === selectedItem.value) {
        //remove selection
        onChange(null);
      }
    }
  };

  return (
    <Stack
      sx={{
        padding: removePadding ? 0 : 2,
        borderRadius: '4px',
        border: showBorderDecoration ? `solid 1px ${getColor()}` : '',
      }}
    >
      {label && (
        <HootTypography isPII={false} variant="labelsmall" mb={1} color={getColor()}>
          {label} {requiredField ? <span style={{ color: hootTokens.palette.error[80] }}> *</span> : ''}
        </HootTypography>
      )}
      <Stack direction="row" gap={1} flexWrap={'wrap'}>
        {items.map((item) => (
          <Chip
            key={`key-${item.value}`}
            label={item.label}
            selected={stateIsArray ? value.some((v) => v === item.value) : value === item.value}
            onClick={handleOnClick(item)}
            error={error}
            disabled={disabled}
          />
        ))}
      </Stack>

      <HelperText getColor={getColor} helperText={helperText} requiredField={requiredField} error={error} />
    </Stack>
  );
};

interface HelperTextProps {
  getColor: () => string;
  helperText?: string;
  requiredField?: boolean;
  error?: boolean;
}

const HelperText = (props: HelperTextProps) => {
  const { getColor, helperText, requiredField, error } = props;

  if (helperText) {
    return (
      <FormHelperText
        sx={{
          margin: 1,
          ...hootTokens.text.labelsmall,
          color: getColor(),
        }}
      >
        {helperText}
      </FormHelperText>
    );
  } else if (requiredField && error) {
    return (
      <FormHelperText
        sx={{
          margin: 1,
          ...hootTokens.text.labelsmall,
          color: getColor(),
        }}
      >
        A selection is required
      </FormHelperText>
    );
  }
  return null;
};

export default ChipGroup;
