export enum ProgressMonitoringAssessmentStatus {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Abandoned = 'ABANDONED',
}

export enum FormVariant {
  A = 'A',
  B = 'B',
}
