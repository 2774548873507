import { Box, BoxProps } from '@mui/material';
import { FunctionComponent } from 'react';

export const maxPageWidthPx = 1920;

export interface PageLayoutProps extends BoxProps {
  RootBoxProps?: BoxProps;
  noPadding?: boolean;
  noMaxWidth?: boolean;
}

const PageLayout: FunctionComponent<PageLayoutProps> = (props) => {
  const { noPadding, noMaxWidth, sx, children, RootBoxProps = {}, ...InnerBoxProps } = props;

  return (
    <Box p={noPadding ? 0 : 2} alignItems="center" {...RootBoxProps}>
      <Box
        sx={{
          width: '100%',
          maxWidth: noMaxWidth ? undefined : maxPageWidthPx,
          margin: 'auto',
          ...sx,
        }}
        {...InnerBoxProps}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PageLayout;
