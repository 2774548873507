import { MenuItem, MenuItemProps, Select, SelectProps, styled } from '@mui/material';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const StudentSelect = styled(Select)<SelectProps>(({ sx }) => ({
  ...hootTokens.text.headlinelarge,
  boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)',
  borderRadius: '4px 0px 0px 4px',
  background: hootTokens.surface['1'],
  border: 'unset',
  paddingRight: '24px',

  '&::before': {
    display: 'none',
  },
  '&::after': {
    display: 'none',
  },
  '&:hover': {
    background: hootTokens.surface['2'],
  },
  '&.Mui-focused': {
    outline: '1px solid',
    outlineColor: hootTokens.palette.secondary['80'],
  },
  '& .MuiSelect-select': {
    height: '104px !important', // Hack
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '24px',
    padding: '0 24px 0 24px',
    borderRadius: 'inherit',
  },
  sx,
}));

const StudentSelectMenuItem = styled(MenuItem)<MenuItemProps>(({ sx }) => ({
  ...hootTokens.text.headlinelarge,
  height: '104px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '24px',
  padding: '0 24px 0 24px',
  '&.Mui-selected': {
    background: hootTokens.palette.primary['160'],
  },
  active: {
    background: hootTokens.palette.primary['160'],
  },
  hover: {
    background: hootTokens.palette.primary['190'],
  },
  sx,
}));

export { StudentSelectMenuItem };
export default StudentSelect;
