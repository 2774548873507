import { SvgIcon, SvgIconProps } from '@mui/material';

const InfoFilledIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4541 22.0001C17.9769 22.0001 22.4541 17.523 22.4541 12.0001C22.4541 6.47728 17.9769 2.00012 12.4541 2.00012C6.93125 2.00012 2.4541 6.47728 2.4541 12.0001C2.4541 17.523 6.93125 22.0001 12.4541 22.0001ZM13.2041 8.00012C13.2041 7.58591 12.8683 7.25012 12.4541 7.25012C12.0399 7.25012 11.7041 7.58591 11.7041 8.00012V9.00012C11.7041 9.41434 12.0399 9.75012 12.4541 9.75012C12.8683 9.75012 13.2041 9.41434 13.2041 9.00012V8.00012ZM13.2041 11.5001C13.2041 11.0859 12.8683 10.7501 12.4541 10.7501C12.0399 10.7501 11.7041 11.0859 11.7041 11.5001V16.0001C11.7041 16.4143 12.0399 16.7501 12.4541 16.7501C12.8683 16.7501 13.2041 16.4143 13.2041 16.0001V11.5001Z"
        fill={props.htmlColor ?? '#6DD1CE'}
      />
    </SvgIcon>
  );
};

export const InfoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M11 7V8M11 10.5V15M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
        stroke={props.htmlColor ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default InfoFilledIcon;
