import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import {
  EventImpactedByAvailabilityException,
  useGetEventsImpactedByAvailabilityExceptionRequest,
} from '../../../../hooks/api/availability/useEventsImpactedByAvailabiltyException';
import {
  ImpactedLesson,
  ImpactedShift,
  useCreateTeacherAvailabilityException,
} from '../../../../hooks/api/availability/useTeacherAvailabilityException';
import { QueryKey } from '../../../../hooks/api/queryKeys';
import { AvailabilityExceptionLessonImpact } from '../../../../models/api/enums';
import { mergeDateAndTime } from '../../../../utils/date';
import { hootTokens } from '../../../theme/v2/tokens';
import { Button } from '../core/Button';
import Card from '../core/Card';
import { Checkbox } from '../core/Checkbox';
import DatePicker from '../core/DatePicker';
import { Icon } from '../core/Icon';
import IconButton from '../core/IconButton';
import ReadOnlyTextField from '../core/ReadOnlyTextField';
import { HeaderData, TableV2 } from '../core/Table';
import TimePicker from '../core/TimePicker';

enum FormState {
  Create = 'create',
  Review = 'review',
}

interface CreateTimeOffRequest {
  startDate: DateTime;
  endDate: DateTime;
  fromTime: DateTime;
  toTime: DateTime;
  allDay: boolean;
}

export function CreateTimeOffRequestDialog(props: { open: boolean; onClose: () => void }) {
  const [formState, setFormState] = useState(FormState.Create);
  const [createTimeOffRequest, setCreateTimeOffRequest] = useState<CreateTimeOffRequest>();

  const updateFormState = (newState: FormState) => {
    setFormState(newState);
  };

  const updateTimeOffRequest = (updatedValues: CreateTimeOffRequest) => {
    setCreateTimeOffRequest(updatedValues);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Create Time Off Request
        <IconButton onClick={props.onClose}>
          <Icon name="close" />
        </IconButton>
      </DialogTitle>
      {formState === FormState.Create ? (
        <CreateRequest
          updateFormState={updateFormState}
          createTimeOffRequest={createTimeOffRequest}
          updateTimeOffRequest={updateTimeOffRequest}
          onClose={props.onClose}
        />
      ) : null}
      {formState === FormState.Review && createTimeOffRequest ? (
        <Review createTimeOffRequest={createTimeOffRequest} updateFormState={updateFormState} onClose={props.onClose} />
      ) : null}
    </Dialog>
  );
}

/** CREATE STEP */

interface Form {
  startDate: DateTime;
  endDate: DateTime;
  fromTime: DateTime;
  toTime: DateTime;
}

function CreateRequest(props: {
  onClose?: () => void;
  updateFormState: (newState: FormState) => void;
  createTimeOffRequest?: CreateTimeOffRequest;
  updateTimeOffRequest: (updatedValue: CreateTimeOffRequest) => void;
}) {
  const [allDay, setAllDay] = useState(props.createTimeOffRequest?.allDay ?? true);

  const defaultStartDate = DateTime.now().plus({ week: 1 }).startOf('day');
  const defaultEndDate = defaultStartDate.plus({ day: 1 }).endOf('day');

  const { control, handleSubmit, getValues } = useForm<Form>({
    defaultValues: {
      startDate: props.createTimeOffRequest?.startDate ?? defaultStartDate,
      endDate: props.createTimeOffRequest?.endDate ?? defaultEndDate,
      fromTime: props.createTimeOffRequest?.fromTime ?? defaultStartDate,
      toTime: props.createTimeOffRequest?.toTime ?? defaultEndDate,
    },
  });

  const handleCancelClick = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleAllDayChange = () => {
    setAllDay((currentState) => !currentState);
  };

  const onHandleSubmit = (form: Form) => {
    props.updateTimeOffRequest({
      startDate: form.startDate,
      endDate: form.endDate,
      fromTime: form.fromTime,
      toTime: form.toTime,
      allDay: allDay,
    });

    props.updateFormState(FormState.Review);
  };

  const minDate = DateTime.now().plus({ week: 1 });
  const maxDate = DateTime.now().plus({ month: 6 });

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}>
      <DialogContent>
        <Stack spacing="16px">
          <Typography variant="titlemedium">Date</Typography>

          <Controller
            name="startDate"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DatePicker
                error={!!error?.message}
                minDate={minDate}
                maxDate={maxDate}
                label="Start Date"
                value={value}
                onChange={onChange}
                helperText={error?.message}
              />
            )}
          />

          <Controller
            name="endDate"
            control={control}
            rules={{
              validate: (val) => {
                const { startDate } = getValues();
                if (startDate > val) {
                  return 'End Date must be greater than or equal to start date';
                }
                return true;
              },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DatePicker
                error={!!error?.message}
                minDate={minDate}
                maxDate={maxDate}
                label="End Date"
                value={value}
                onChange={onChange}
                helperText={error?.message}
              />
            )}
          />

          <Typography variant="titlemedium">Time</Typography>

          <Checkbox label="All Day" checked={allDay} onChange={handleAllDayChange} />

          {allDay ? null : (
            <>
              <Controller
                name="fromTime"
                control={control}
                render={({ field: { value, onChange } }) => <TimePicker label="From Time" value={value} onChange={onChange} />}
              />

              <Controller
                name="toTime"
                control={control}
                render={({ field: { value, onChange } }) => <TimePicker label="To Time" value={value} onChange={onChange} />}
              />
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick} variant="outlined">
          Cancel
        </Button>
        <Button variant="contained" type="submit">
          Continue
        </Button>
      </DialogActions>
    </form>
  );
}

/** REVIEW STEP */

function Review(props: { updateFormState: (newState: FormState) => void; createTimeOffRequest: CreateTimeOffRequest; onClose: () => void }) {
  const teacherAvailabilityExceptionMutation = useCreateTeacherAvailabilityException();

  const queryClient = useQueryClient();

  function startsAt() {
    if (props.createTimeOffRequest.allDay) {
      return props.createTimeOffRequest.startDate.toMillis();
    }
    return mergeDateAndTime(props.createTimeOffRequest.startDate, props.createTimeOffRequest.fromTime).toMillis();
  }

  function endsAt() {
    if (props.createTimeOffRequest.allDay) {
      return props.createTimeOffRequest.endDate.toMillis();
    }
    return mergeDateAndTime(props.createTimeOffRequest.endDate, props.createTimeOffRequest.toTime).toMillis();
  }

  const impactedEventsRequest = useGetEventsImpactedByAvailabilityExceptionRequest({
    startsAt: startsAt(),
    endsAt: endsAt(),
  });

  const handleBackClick = () => {
    props.updateFormState(FormState.Create);
  };

  const handleSubmitClick = () => {
    const impactedLessons =
      impactedEventsRequest.data?.eventsImpacted
        .filter((e) => e.eventType === 'LESSON')
        .map<ImpactedLesson>((e) => ({
          lessonId: e.eventId,
          status: AvailabilityExceptionLessonImpact.Dropped,
        })) ?? [];

    const impactedShifts =
      impactedEventsRequest.data?.eventsImpacted
        .filter((e) => e.eventType === 'SHIFT')
        .map<ImpactedShift>((e) => ({
          shiftId: e.eventId,
        })) ?? [];

    teacherAvailabilityExceptionMutation.mutate(
      {
        startsAt: startsAt(),
        endsAt: endsAt(),
        impactedLessons: impactedLessons,
        impactedShifts: impactedShifts,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKey.GetTeacherShifts);
          queryClient.invalidateQueries(QueryKey.LessonSearch);
          queryClient.invalidateQueries(QueryKey.GetTeacherAvailabilityExceptions);
          queryClient.invalidateQueries(QueryKey.GetTeacherAvailablityExceptionDrafts);
          props.onClose();
        },
      },
    );
  };

  return (
    <>
      <DialogContent>
        <Stack spacing="16px">
          <Typography variant="bodylarge">Review the below information to ensure that it is correct.</Typography>

          <Stack direction="row" spacing="16px">
            <ReadOnlyTextField
              filledColour="primary.190"
              sx={{ width: '100%' }}
              body={props.createTimeOffRequest.startDate.toFormat('d/LL/yyyy')}
              label="Start Date"
            />
            <ReadOnlyTextField
              filledColour="primary.190"
              sx={{ width: '100%' }}
              body={props.createTimeOffRequest.endDate.toFormat('d/LL/yyyy')}
              label="End Date"
            />
          </Stack>
          {props.createTimeOffRequest.allDay ? null : (
            <Stack direction="row" spacing="16px">
              <ReadOnlyTextField
                filledColour="primary.190"
                sx={{ width: '100%' }}
                body={props.createTimeOffRequest.fromTime.toFormat('h:mm a')}
                label="From Time"
              />
              <ReadOnlyTextField
                filledColour="primary.190"
                sx={{ width: '100%' }}
                body={props.createTimeOffRequest.toTime.toFormat('h:mm a')}
                label="To Time"
              />
            </Stack>
          )}

          {impactedEventsRequest.data?.eventsImpacted ? <Exceptions eventsImpacted={impactedEventsRequest.data?.eventsImpacted} /> : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleBackClick} variant="outlined">
          Back
        </Button>
        <Button onClick={handleSubmitClick} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </>
  );
}

function Exceptions(props: { eventsImpacted: EventImpactedByAvailabilityException[] }) {
  const [showEvents, setShowEvents] = useState(false);

  const handleShowEventsClick = () => {
    setShowEvents((currentState) => !currentState);
  };

  const buttonLabel = showEvents ? 'Hide Events' : 'Show Events';

  if (props.eventsImpacted.length === 0) {
    return null;
  }

  return (
    <Stack
      sx={{
        borderRadius: '4px',
        padding: '16px',
        backgroundColor: hootTokens.palette.error[190],
      }}
      spacing="16px"
    >
      <Stack direction="row" alignItems="center" spacing="8px">
        <Typography
          sx={{
            borderRadius: '8px',
            padding: '4px 8px 4px 8px',
            backgroundColor: hootTokens.palette.error[100],
          }}
          variant="bodysmall"
        >
          {props.eventsImpacted.length}
        </Typography>
        <Typography variant="titlemedium">Impacted Lessons</Typography>
      </Stack>

      <Typography variant="bodylarge">
        There are {props.eventsImpacted.length} scheduled events that will be impacted by this Time Off Request. They will be removed from your
        schedule.
      </Typography>
      <Stack spacing="16px">
        <Box>
          <Button
            onClick={handleShowEventsClick}
            startIcon={<Icon name={'chevron'} sx={{ rotate: showEvents ? '90deg' : '270deg' }} />}
            variant="outlined"
          >
            {buttonLabel}
          </Button>
        </Box>
        {showEvents ? <EventsContainer eventsImpacted={props.eventsImpacted} /> : null}
      </Stack>
    </Stack>
  );
}

export interface ImpactedEventRow {
  type: string;
  date: string;
  time: string;
}

const headers: HeaderData<ImpactedEventRow>[] = [
  { name: 'Type', property: 'type' },
  { name: 'Date', property: 'date' },
  { name: 'Time', property: 'time' },
];

function EventsContainer(props: { eventsImpacted: EventImpactedByAvailabilityException[] }) {
  const data: ImpactedEventRow[] = props.eventsImpacted.map((event) => ({
    type: event.eventType,
    date: DateTime.fromMillis(event.startsAt).toFormat('LL/dd/yyyy'),
    time: `${DateTime.fromMillis(event.startsAt).toFormat('h:mm a')} - ${DateTime.fromMillis(event.endsAt).toFormat('h:mm a')}`,
  }));

  const handleChangeRowsPerPage = (_event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {};

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, _newPage: number) => {};

  return (
    <Card>
      <Stack spacing="16px">
        <Typography variant="titlemedium">Events</Typography>
        <TableV2
          data={data}
          headers={headers}
          count={data.length}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          isPaginated
          isSortable={false}
          page={0}
        />
      </Stack>
    </Card>
  );
}
