import { Typography as MuiTypography, TypographyProps as MuiTypographyProps } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { TypographyVariant } from '@hoot/ui/theme/v2/typography';

// Only expose v2 typography variants.
interface TypographyProps extends Omit<MuiTypographyProps, 'variant'> {
  isPII: boolean;
  variant?: TypographyVariant;
}

const HootTypography: FunctionComponent<TypographyProps> = (props) => {
  const { isPII, ...restOfProps } = props;

  if (isPII) {
    // Mask field for Sentry to prevent PII from being captured
    return <MuiTypography {...restOfProps} data-sentry-mask />;
  }

  return <MuiTypography {...restOfProps} />;
};

export default HootTypography;
