import { SvgIcon, SvgIconProps } from '@mui/material';

const MicOffIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5303 2.21967C21.8232 2.51256 21.8232 2.98744 21.5303 3.28033L16 8.81066V12C16 14.2091 14.2091 16 12 16C11.1022 16 10.2734 15.7042 9.60593 15.2047L8.71508 16.0956C9.61444 16.8179 10.7568 17.25 12 17.25C14.8995 17.25 17.25 14.8995 17.25 12V11C17.25 10.5858 17.5858 10.25 18 10.25C18.4142 10.25 18.75 10.5858 18.75 11V12C18.75 15.4744 16.125 18.3357 12.75 18.7088V20.5H15C15.4142 20.5 15.75 20.8358 15.75 21.25C15.75 21.6642 15.4142 22 15 22H9C8.58579 22 8.25 21.6642 8.25 21.25C8.25 20.8358 8.58579 20.5 9 20.5H11.25V18.7088C9.88611 18.558 8.64469 18.0009 7.6495 17.1612L3.53033 21.2803C3.23744 21.5732 2.76256 21.5732 2.46967 21.2803C2.17678 20.9874 2.17678 20.5126 2.46967 20.2197L20.4697 2.21967C20.7626 1.92678 21.2374 1.92678 21.5303 2.21967ZM5.86671 14.8226L7.02072 13.6686C6.84513 13.1444 6.75 12.5833 6.75 12V11C6.75 10.5858 6.41421 10.25 6 10.25C5.58578 10.25 5.25 10.5858 5.25 11V12C5.25 13.0077 5.47082 13.9638 5.86671 14.8226ZM15.8339 4.85548L8.0507 12.6386C8.01734 12.4307 8 12.2174 8 12V6C8 3.79086 9.79086 2 12 2C13.8114 2 15.3416 3.20405 15.8339 4.85548Z"
        fill={props.htmlColor ?? '#EF4823'}
      />
    </SvgIcon>
  );
};

export default MicOffIcon;
