import { useEffect, useState } from 'react';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import BasicAlertDialog, { BasicAlertDialogProps } from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';

export interface CoppaConsentDialogProps extends Pick<BasicAlertDialogProps, 'show' | 'onDismiss'> {
  onApplyCoppaConsent: (isCoppaConsentGranted: boolean) => void;
  defaultChecked?: boolean;
}

const CoppaConsentDialog = (props: CoppaConsentDialogProps) => {
  const { defaultChecked, show, onDismiss, onApplyCoppaConsent } = props;

  const [isCoppaConsentGranted, setIsCoppaConsentGranted] = useState(false);

  useEffect(() => {
    setIsCoppaConsentGranted(!!defaultChecked);
  }, [defaultChecked]);

  const onToggleCoppaConsent = () => {
    setIsCoppaConsentGranted(!isCoppaConsentGranted);
  };

  const _onApplyCoppaConsent = () => {
    onApplyCoppaConsent(isCoppaConsentGranted);
  };

  return (
    <BasicAlertDialog
      show={show}
      onDismiss={onDismiss}
      title="COPPA Consent"
      primaryAction={{
        label: 'Apply',
        onClick: _onApplyCoppaConsent,
        props: {
          disabled: !isCoppaConsentGranted,
        },
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: onDismiss,
      }}
      content={
        <>
          <>
            When your child uses Hoot Reading they connect with a teacher for learning sessions in the Hoot Reading app. During those learning
            sessions, we collect certain personal information from your child in order to deliver the lessons and measure your child’s progress.
            <br />
            <br />
            <b>To help you make an informed decision, here's the information we'll collect from your child:</b>
            <br />
            <ul>
              <li>Video and audio of your child, as part of the video calls they have with the educators.</li>
              <li>Your child’s reading preferences, completed books, and progress.</li>
              <li>The IP address, device type and ID, and browser used for lessons.</li>
            </ul>
            We’ll combine the information listed above with your child’s name, reading level, and the account information you have provided.
            <br />
            <br />
            <b>How we may use and disclose the information:</b>
            <br />
            <ul>
              <li>
                We use this information to provide the lessons to your child, measure and save their progress, allow you to see what your child is
                doing in Hoot Reading, and build progress reports for you.
              </li>
              <li>We may also use this information to monitor and assess the educators as part of a quality and standard assurance program.</li>
              <li>
                We will only share this information with you and with third party service providers who help us deliver certain Hoot Reading features.
                Those third party service providers are not permitted to use the information for any other purpose and are not permitted to share it.
              </li>
            </ul>
            <br />
            For more information about our privacy practices, we encourage you to review our Website and{' '}
            <a target="_blank" href="https://www.hootreading.com/privacy/" rel="noreferrer">
              Web App Privacy Policies
            </a>
            .
            <br />
            <br />
            <b>
              By checking the box below, you are providing us with permission to collect and use the personal information as described above to
              deliver Hoot Reading Services.
            </b>
            <br />
            <br />
            At any time, you may request to review the information that we have collected from your child, request that we collect no additional
            information from your child, or request that we delete the information we have collected. Please review our{' '}
            <a target="_blank" href="https://www.hootreading.com/privacy/" rel="noreferrer">
              privacy policies
            </a>{' '}
            for more information about these and other rights.
            <br />
            <br />
            If you choose to not provide us with permission, we will delete the personal information you have provided about your child from our
            records within 30 days. Your subscription will remain active unless you choose to unsubscribe.
            <br />
            <br />
            <Checkbox checked={isCoppaConsentGranted} onClick={onToggleCoppaConsent} label="I, as the Parent or Guardian, grant my COPPA consent." />
            <br />
            <br />
          </>
        </>
      }
    />
  );
};

export default CoppaConsentDialog;
