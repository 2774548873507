import { HootAssessmentInstructionIndicator, HootAssessmentStatus, HootAssessmentUnitSet } from '@hoot/models/api/enums/hoot-reading-assessment';

export const hootAssessmentStatusLabel: { [key in HootAssessmentStatus]: string } = {
  [HootAssessmentStatus.InProgress]: 'In Progress',
  [HootAssessmentStatus.Completed]: 'Completed',
  // In the UI we show "Ended", but internally we call it "Abandoned".
  [HootAssessmentStatus.Abandoned]: 'Ended',
};

export const hootAssessmentInstructionIndicatorLabel: { [key in HootAssessmentInstructionIndicator]: string } = {
  [HootAssessmentInstructionIndicator.NeedsInstruction]: 'Needs Instruction',
  [HootAssessmentInstructionIndicator.NeedsReview]: 'Review',
  [HootAssessmentInstructionIndicator.Mastered]: 'Mastered',
};

export const hootAssessmentUnitSetLabel: Record<HootAssessmentUnitSet, string> = {
  [HootAssessmentUnitSet.SanDiegoQuick]: 'San Diego Quick Assessment',
  [HootAssessmentUnitSet.PhonologicalAwareness]: 'Phonological Awareness',
  [HootAssessmentUnitSet.PhonemicAwareness]: 'Phonemic Awareness',
  [HootAssessmentUnitSet.LetterSounds]: 'Letter Sounds',
  [HootAssessmentUnitSet.CvcWords]: 'CVC Words',
  [HootAssessmentUnitSet.DigraphsAndBlends]: 'Digraphs & Blends',
  [HootAssessmentUnitSet.BasicVowels]: 'Basic Vowels',
  [HootAssessmentUnitSet.AdvancedVowels]: 'Advanced Vowels',
  [HootAssessmentUnitSet.ComplexWords]: 'Complex Words',
  [HootAssessmentUnitSet.ReadingFluency]: 'Reading Fluency',
  [HootAssessmentUnitSet.ComprehensionStrategies]: 'Comprehension Strategies',
  [HootAssessmentUnitSet.AttitudeTowardsReading]: 'Attitude Towards Reading',
};

export const focusedCardUnits = [
  'U_1_1A',
  'U_1_1B',
  'U_1_1C',
  'U_1_1D',
  'U_1_1E',
  'U_1_2A',
  'U_1_2B',
  'U_1_2C',
  'U_1_2D',
  'U_1_3A',
  'U_2_1A',
  'U_2_2A',
  'U_2_2B',
  'U_2_2C',
  'U_2_3A',
  'U_2_3B',
  'U_2_3C',
  'U_3_1A',
  'U_3_1B',
  'U_3_2A',
  'U_3_2B',
];
