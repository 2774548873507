import { Box, CardProps, Skeleton, Stack, useMediaQuery } from '@mui/material';
import { AxiosError } from 'axios';
import { capitalCase } from 'change-case';
import fileDownloader from 'js-file-download';
import React, { RefObject, useMemo, useState } from 'react';
import { UseMutationResult, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import useGetBillableLessonSummary, {
  BillableLessonsSummaryRow,
  TeacherInvoiceBillableLessonsQuery,
} from '@hoot/hooks/api/invoice/useGetBillableLessonSummary';
import { InvoiceDetailsResponse } from '@hoot/hooks/api/invoice/useGetInvoiceDetails';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import Tag, { TagColor } from '@hoot/ui/components/v2/core/Tag';
import theme from '@hoot/ui/theme/v2';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import useDownloadLessonCSVReport from '../../../../../hooks/api/invoice/useDownloadLessonCSVReport';

interface BillableLessonsProps {
  invoiceDetails: InvoiceDetailsResponse | undefined;
  resubmitLessonsMutation: UseMutationResult<void, AxiosError, string>;
  sx?: CardProps['sx'];
  setShowPartiallySubmittedBanner: React.Dispatch<React.SetStateAction<boolean>>;
  billableLessonsScrollToRefProp: RefObject<HTMLDivElement>;
  isCurrentPeriod: boolean;
}

interface BillableLessonTableRow {
  language: string;
  month: string;
  type: string;
  duration: string;
  quantity: string;
  total: string;
}

interface TableData {
  submittedTableRows: BillableLessonTableRow[];
  unsubmittedTableRows: BillableLessonTableRow[];
  isInvoicePartiallySubmitted: boolean;
}

const BillableLessons = (props: BillableLessonsProps) => {
  const { invoiceDetails, sx, resubmitLessonsMutation, setShowPartiallySubmittedBanner, billableLessonsScrollToRefProp, isCurrentPeriod } = props;
  const { invoiceId } = useParams() as { invoiceId: string };
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const [billableLessonsData, setBillableLessonsData] = useState<TableData>({
    submittedTableRows: [],
    unsubmittedTableRows: [],
    isInvoicePartiallySubmitted: false,
  });
  const [query, setQuery] = useState<TeacherInvoiceBillableLessonsQuery>({
    sortBy: 'language',
    orderBy: OrderBy.Asc,
  });

  const headers: HeaderData<BillableLessonTableRow>[] = [
    { name: 'Language', property: 'language', isSortable: true, isHidden: !isDesktop },
    { name: 'Type', property: 'type', isSortable: true, isHidden: !isDesktop },
    { name: 'Duration', property: 'duration', isSortable: true },
    { name: 'Quantity', property: 'quantity', isSortable: true },
    { name: 'Month', property: 'month', isSortable: true },
    { name: 'Total', property: 'total', isSortable: true },
  ];

  const onResubmitInvoiceLessons = (invoiceId: string) => {
    resubmitLessonsMutation.mutate(invoiceId, {
      onSuccess: () => {
        setShowPartiallySubmittedBanner(false);

        queryClient.invalidateQueries(QueryKey.GetInvoiceDetails);
        queryClient.invalidateQueries(QueryKey.GetInvoiceSummary);
        queryClient.invalidateQueries(QueryKey.GetInvoiceBillableLessonSummary);

        dispatch(createFlashMessage({ message: 'Invoice Successfully Submitted' }));
      },
      onError: () => {
        dispatch(createFlashMessage({ variant: 'error', message: 'There was an error while resubmitting invoice' }));
      },
    });
  };

  const summaryDataRequest = useGetBillableLessonSummary(invoiceId || '', query, {
    retry: false,
    enabled: true,
    onError: (ex) => {
      console.error(ex);
    },
    onSuccess: (data) => {
      function mapResponseRowToTableDisplayData() {
        return (row: BillableLessonsSummaryRow): BillableLessonTableRow => ({
          language: row.language,
          month: row.month,
          type: row.type,
          duration: row.duration.toString(),
          quantity: row.quantity.toString(),
          total: row.total.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
        });
      }

      const submittedRows = data.submittedLessonSummaryRows.map(mapResponseRowToTableDisplayData());
      const unsubmittedRows = data.unsubmittedLessonSummaryRows.map(mapResponseRowToTableDisplayData());

      setBillableLessonsData({
        submittedTableRows: submittedRows,
        unsubmittedTableRows: unsubmittedRows,
        isInvoicePartiallySubmitted: data.isInvoicePartiallySubmitted,
      });
      setShowPartiallySubmittedBanner(data.isInvoicePartiallySubmitted && unsubmittedRows.length > 0 && isCurrentPeriod);
    },
  });

  const lessonsCSVReport = useDownloadLessonCSVReport(invoiceId!, {
    enabled: false,
    onSuccess: (res) => {
      const fileName = `${invoiceDetails?.invoiceMonth}-Invoice-Billable-Lesson-Details.csv`;
      fileDownloader(res.data, fileName);
    },
    onError: (err) => console.error('Error downloading CSV', err),
  });

  const handleDownloadCSV = () => {
    lessonsCSVReport.refetch();
  };

  const viewState = useMemo<ViewStateEnum>(() => {
    if (summaryDataRequest.isFetching || summaryDataRequest.isLoading) {
      return ViewStateEnum.Loading;
    } else if (summaryDataRequest.isError) {
      return ViewStateEnum.Error;
    } else if (
      !billableLessonsData ||
      (billableLessonsData.submittedTableRows.length === 0 && billableLessonsData.unsubmittedTableRows.length === 0)
    ) {
      return ViewStateEnum.EmptyState;
    } else {
      return ViewStateEnum.Results;
    }
  }, [summaryDataRequest, billableLessonsData]);

  return (
    <Card
      sx={{
        height: 'max-content',
        border: 'none',
        ...(sx ?? {}),
      }}
      contentSx={{ padding: '12px 0px 16px 0px' }}
    >
      <Stack spacing={2}>
        {viewState === ViewStateEnum.EmptyState && (
          <HootTypography isPII={false} variant="titlelarge">
            Billable Lessons
          </HootTypography>
        )}
        <ViewState
          state={viewState}
          loadingContent={<SkeletonTableItems />}
          EmptyStateIllustrationProps={{ title: 'No Billable Lessons', subtitle: 'Your billable lessons will be listed here', showBorder: true }}
        >
          {billableLessonsData.submittedTableRows.length > 0 && (
            <>
              <Box display={'flex'} flexDirection={'row'} flexWrap="wrap" gap={1}>
                <HootTypography isPII={false} variant="titlelarge">
                  Billable Lessons
                </HootTypography>
                {billableLessonsData.isInvoicePartiallySubmitted && (
                  <Tag label={capitalCase('Submitted')} color={TagColor.Success} sx={{ ...hootTokens.text.labellarge }} />
                )}
              </Box>

              <TableV2
                isPaginated={false}
                isSortable
                data={billableLessonsData.submittedTableRows}
                headers={headers}
                onSortBy={(val: any) => {
                  setQuery((prevState) => ({
                    ...prevState,
                    sortBy: val,
                    orderBy: prevState.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
                  }));
                }}
                sortOrder={query.orderBy}
                sortBy={query.sortBy as any}
              />
            </>
          )}
          {billableLessonsData.unsubmittedTableRows.length > 0 && isCurrentPeriod && (
            <>
              <div style={{ height: '0px', padding: '0px', margin: '0px' }} ref={billableLessonsScrollToRefProp} />
              <Stack display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" gap={1} paddingTop={3}>
                <Stack display={'flex'} flexDirection={'column'} gap={1}>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
                    <HootTypography isPII={false} variant="titlelarge">
                      Billable Lessons
                    </HootTypography>
                    {billableLessonsData.isInvoicePartiallySubmitted && (
                      <Tag label={capitalCase('Not Submitted')} color={TagColor.Warning} sx={{ minWidth: '180px' }} />
                    )}
                  </Box>
                  {billableLessonsData.isInvoicePartiallySubmitted && (
                    <HootTypography isPII={false} variant="bodysmall">
                      Resubmit your invoice to bill for these line items. If you do not resubmit, these line items will carry into the next pay
                      period.
                    </HootTypography>
                  )}
                </Stack>
                {billableLessonsData.isInvoicePartiallySubmitted && (
                  <Button
                    variant="contained"
                    color="success.140"
                    onClick={() => onResubmitInvoiceLessons(invoiceId)}
                    isLoading={resubmitLessonsMutation.isLoading}
                    sx={{ minWidth: '130px' }}
                  >
                    <Icon name="redo" sx={{ paddingRight: '8px' }} />
                    Resubmit
                  </Button>
                )}
              </Stack>

              <TableV2
                isPaginated={false}
                isSortable
                data={billableLessonsData.unsubmittedTableRows}
                headers={headers}
                onSortBy={(val: any) => {
                  setQuery((prevState) => ({
                    ...prevState,
                    sortBy: val,
                    orderBy: prevState.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
                  }));
                }}
                sortOrder={query.orderBy}
                sortBy={query.sortBy as any}
              />
            </>
          )}
          <Card elevation={0} sx={{ borderRadius: 2, background: hootTokens.palette.secondary[195], displayPrint: 'none' }}>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <HootTypography isPII={false} variant="bodysmall">
                Want more details? Click “Download” for a detailed spreadsheet. You can use Excel or another spreadsheet program to open & view the
                file.
              </HootTypography>
              <Button variant="contained" size="small" onClick={handleDownloadCSV} sx={{ minWidth: '93px' }}>
                Download
              </Button>
            </Stack>
          </Card>
        </ViewState>
      </Stack>
    </Card>
  );
};

const SkeletonTableItems = () => (
  <Stack direction="column" gap={3} sx={{ width: '100%' }}>
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Skeleton variant="rounded" sx={{ minWidth: '200px', width: '100%', minHeight: '60px' }} />
      <Skeleton variant="rounded" sx={{ minWidth: '200px', width: '100%', minHeight: '260px' }} />
      <Skeleton variant="rounded" sx={{ minWidth: '200px', width: '100%', minHeight: '75px' }} />
    </Box>
  </Stack>
);

export default BillableLessons;
