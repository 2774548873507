import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export enum ActionRequiredTaskAction {
  CreateLessonReview = 'CREATE_LESSON_REVIEW',
  GoToHootReadingAssessment = 'GO_TO_HRA',
}

export interface ActionRequiredMeta {
  key: ActionRequiredKey;
  val: string;
}

export enum ActionRequiredKey {
  AssessmentId = 'ASSESSMENT_ID',
}
export interface ActionRequiredTaskResponse {
  header: string;
  taskAt: number;
  comment: string;
  action: ActionRequiredTaskAction | null;
  lessonId: string;
  meta?: ActionRequiredMeta[];
}

export default function useGetRequiredTasksByStudent(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<ActionRequiredTaskResponse[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetRequiredTasksByStudent, studentProfileId],
    async () => {
      return (await axios.get<ActionRequiredTaskResponse[]>(`${config.apiUrl}/my-students/${studentProfileId}/action-required-tasks`)).data;
    },
    options,
  );
}
