import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { RootState } from '@hoot/redux/store';
import StudentJoinLessonProvider from '@hoot/ui/context/StudentJoinLessonContext';
import { useUpcomingLessons } from '@hoot/ui/context/UpcomingLessonsProvider';
import StudentJoinLessonDialog from '../dialogs/StudentJoinLessonDialog';

const StudentLibraryLayout = () => {
  const newAppVersionAvailable = useSelector((state: RootState) => state.application.newAppVersionAvailable);
  const { inLesson } = useActiveLessonState();
  const { openLesson } = useUpcomingLessons();
  useEffect(() => {
    if (newAppVersionAvailable && !inLesson && !openLesson) {
      window.location.reload();
    }
  }, [newAppVersionAvailable, inLesson, openLesson]);

  return (
    <StudentJoinLessonProvider>
      <Outlet />
      <StudentJoinLessonDialog />
    </StudentJoinLessonProvider>
  );
};

export default StudentLibraryLayout;
