import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { HootAssessmentInstructionIndicator, HootAssessmentUnitSet } from '@hoot/models/api/enums/hoot-reading-assessment';
import { QueryKey } from '../queryKeys';

export interface SubmissionResponseData {
  label: string;
  value: string;
  instructionIndicator?: HootAssessmentInstructionIndicator;
  incorrectLetterSounds?: string;
}

export interface GetSubmissionDetailsResponse {
  submissionId: string;
  unit: {
    unitId: string;
    unitName: string;
    unitSet: HootAssessmentUnitSet;
  };
  submissionDetails: SubmissionResponseData[];
  assessedBy: string;
}

async function getSubmissionDetails(assessmentId: string) {
  const { data } = await axios.get<GetSubmissionDetailsResponse[]>(`${config.apiUrl}/public/hoot-assessment/${assessmentId}/details`);
  return data;
}

export function useGetSubmissionDetails(
  assessmentId: string,
  options?: Omit<UseQueryOptions<GetSubmissionDetailsResponse[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetSubmissionDetails], () => getSubmissionDetails(assessmentId), options);
}
