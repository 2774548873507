import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { ReactNode } from 'react';
import HootTypography from '../../../../../../components/v2/core/HootTypography';
import { hootTokens } from '../../../../../../theme/v2/tokens';

export interface Questionnaire {
  title: string;
  subTitle?: string;
  ruberic?: ReactNode;
  options: QuestionnaireOptions[];
}

export interface QuestionnaireOptions {
  id: string;
  text: string;
}

export function UNIT_4_2(props: { questionnaire: Questionnaire[] }) {
  return (
    <Stack direction="row" justifyContent="space-evenly">
      <Form questionnaire={props.questionnaire} />
    </Stack>
  );
}

function Form(props: { questionnaire: Questionnaire[] }) {
  const handleOptionChange = () => {};

  return (
    <Stack flex={1}>
      {props.questionnaire.map((q) => (
        <Stack marginTop="24px">
          <Typography variant="titlesmall">{q.title}</Typography>
          <Typography sx={{ fontStyle: 'italic', fontWeight: 400 }} variant="titlesmall">
            {q.subTitle}
          </Typography>
          {q.ruberic ? (
            <Stack marginY="16px" sx={{ backgroundColor: hootTokens.surface[2], padding: '24px', borderRadius: '8px' }}>
              {q.ruberic}
            </Stack>
          ) : null}
          <RadioGroup onChange={handleOptionChange} name="test">
            {q.options.map((o) => (
              <FormControlLabel
                value={o.id}
                control={<Radio />}
                label={<HootTypography isPII={false}>{o.text}</HootTypography>}
                sx={{ display: 'flex', alignItems: 'center' }}
              />
            ))}
          </RadioGroup>
        </Stack>
      ))}
    </Stack>
  );
}
