import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetEntryPointUnit } from '@hoot/hooks/api/assessment/getEntryPointUnit';
import { Assessment, useGetAssessmentById } from '@hoot/hooks/api/assessment/useGetAssessmentById';
import { SectionField, Unit, useGetAssessmentUnit } from '@hoot/hooks/api/assessment/useGetAssessmentUnit';
import { HootAssessmentFieldType, HootAssessmentStatus } from '@hoot/models/api/enums/hoot-reading-assessment';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { calculateStepTestQuestionsToShow } from '@hoot/ui/pages/v2/teacher/hoot-reading-assessment/hra-fields/StepTest';

// Only used on Segment and Step-Test fields as these require us to display a separate card for user input.
export interface FocusedField {
  sectionField: SectionField;
  currentStepperIndex?: number; // This is only used on Step-Test fields.
}

interface Values {
  form: UseFormReturn;
  focusedField: FocusedField | undefined;
  setFocusedField: React.Dispatch<React.SetStateAction<FocusedField | undefined>>;
  onAssessmentUpdated: (assessment: Assessment) => void;
  navigateToUnit: (unitId: string) => void;
  confirmLeave: () => void;
  transitionFocusToNextField: () => void;
  transitionFocusToNextStepTestQuestion: () => void;
  unit: Unit;
  onDismissEditDialog: () => void;
  handleSetUnit: (unit: Unit | undefined) => void;
  hasOutstandingQuestions: boolean;
  isUnitSubmitted: boolean;
}

const HootAssessmentContext = createContext<Values | undefined>(undefined);

const HootAssessmentContextProvider = (props: { children: React.ReactNode }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { studentProfileId, assessmentId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [assessmentData, setAssessmentData] = useState<Assessment>();
  const [unit, setUnit] = useState<Unit>();
  const [focusedField, setFocusedField] = useState<FocusedField>();
  const [isUnitSubmitted, setIsUnitSubmitted] = useState(false);

  const unitId = searchParams.get('unitId') ?? undefined;

  const form = useForm({
    mode: 'onSubmit',
    shouldUnregister: true,
    defaultValues: unit?.submissionData,
  });
  const formValues: { [key: string]: any } = form.getValues();

  const { refetch: refetchAssessment } = useGetAssessmentById(assessmentId!, {
    enabled: false, // Do not run this by default.
    retry: false, // Do not retry if request fails.
    onSuccess: (data) => {
      setAssessmentData(data);

      // Now that we have the assessment loaded, we can fetch the assessment's current unit. However, we don't need to
      // do this if we already have a unit ID in the URL query args; we would've already loaded it.
      if (!unitId) {
        navigateToUnit(data.currentUnitId);
      }
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: `There was a problem loading assessment ID ${assessmentId}.` }));
    },
  });

  const { refetch: refetchUnit } = useGetAssessmentUnit(assessmentId!, unitId, {
    enabled: false,
    retry: false,
    onSuccess: (unitResponse) => {
      handleSetUnit(unitResponse);
      setIsUnitSubmitted(!!unitResponse.submissionId);

      // If the unit hasn't been submitted, then auto-focus on the first field.
      if (!unitResponse.submissionId) {
        _transitionFocusToNextField(unitResponse);
      } else {
        setFocusedField(undefined);
      }
    },
    onError: (err) => {
      // If received a 403, then we're trying to load a unit that we shouldn't be able to access yet (we can't just skip
      // ahead and load any unit we want to). If this happens, then load the assessment's "current unit" (entrypoint) instead.
      if (err.response?.status === 403) {
        refetchEntryPoint();
        return;
      }
      console.error(err);
      dispatch(createFlashMessage({ message: `There was a problem loading assessment ID ${assessmentData?.id} unit ${unitId}.` }));
    },
  });

  const { refetch: refetchEntryPoint } = useGetEntryPointUnit(studentProfileId!, {
    enabled: false,
    retry: false,
    onSuccess: (data: Unit) => {
      handleSetUnit(data);
      setSearchParams({ unitId: data.id });
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: `There was a problem loading the initial assessment unit.` }));
    },
  });

  // Figure out if there are still questions left to be answered.
  // Note: This won't work for nested sections fields.
  const hasOutstandingQuestions = useMemo(() => {
    if (!unit) {
      return false;
    }
    const sectionFields = unit.sections.flatMap((x) => x.fields);
    const sectionFieldsDictionary = new Map(sectionFields.map((x) => [x.name, x]));

    return Object.entries(formValues).some(([key, value]) => {
      if (!value) {
        return true;
      }
      const section = sectionFieldsDictionary.get(key);
      if (!section) {
        return true;
      }
      // Segment fields have a form value for each question.
      if (section.type === HootAssessmentFieldType.Segment) {
        if (!value) {
          return true;
        }
      }
      // Step-test fields have just one form value, but are made up of an array of answers.
      if (section.type === HootAssessmentFieldType.StepTest) {
        const allStepTestAnswers = value as string[];
        const numUnfurledQuestions = calculateStepTestQuestionsToShow(section, allStepTestAnswers)!;
        const stepTestAnswers: string[] = allStepTestAnswers.slice(0, numUnfurledQuestions);

        // Check if there are still step-test questions to be answered.
        return stepTestAnswers.some((x) => !x);
      }
      return false;
    });
  }, [formValues, unit]);

  // This is only used for "Segment" and "Step-Test" fields. The controls for these types of fields are displayed in a
  // separate card when in focus. Once a response has been provided to one of these fields, we transition over to the
  // next "Segment" or "Step-Test" field.
  const _transitionFocusToNextField = useCallback(
    (unit: Unit) => {
      const fields = unit.sections?.flatMap((x) => x.fields);

      const currentIndex = fields.findIndex((x) => x.id === focusedField?.sectionField?.id);
      const nextField = fields?.[currentIndex + 1];

      setFocusedField(
        nextField
          ? {
              sectionField: nextField,
              currentStepperIndex: nextField?.type === HootAssessmentFieldType.StepTest ? 0 : undefined,
            }
          : undefined,
      );
    },
    [focusedField?.sectionField?.id],
  );

  const transitionFocusToNextField = useCallback(() => {
    if (!unit) {
      return;
    }
    _transitionFocusToNextField(unit);
  }, [_transitionFocusToNextField, unit]);

  const transitionFocusToNextUnansweredStepTestQuestion = useCallback(() => {
    // There should really only be one field.
    const fields = unit!.sections?.flatMap((x) => x.fields);

    const stepTestField = fields.find((x) => x.type === HootAssessmentFieldType.StepTest);

    // If there is no next step-test field, then just focus on the first field. This shouldn't really ever happen though.
    if (!stepTestField || !formValues[stepTestField.name]) {
      transitionFocusToNextField();
      return;
    }
    const stepTestAnswers: string[] = formValues[stepTestField.name];

    const nextUnansweredStepTestQuestionIndex = stepTestAnswers.findIndex((x) => !x) ?? 0;

    setFocusedField({
      sectionField: stepTestField,
      currentStepperIndex: nextUnansweredStepTestQuestionIndex,
    });
  }, [formValues, transitionFocusToNextField, unit]);

  // EDGE CASE:
  // If the unit goes from having all answers completed, to _not_ having all answers completed, this will
  // effectively remove the "unit complete" card. In this case, we need to bring focus back to the first "focusable"
  // field. Note: this can only happen on Step-Test units.
  useEffect(() => {
    if (hasOutstandingQuestions && !focusedField) {
      transitionFocusToNextUnansweredStepTestQuestion();
      setIsUnitSubmitted(false);
    }
  }, [hasOutstandingQuestions, focusedField, transitionFocusToNextUnansweredStepTestQuestion]);

  // When the unit ID changes, then fetch the unit (assessment ID must exist too though else the request will fail).
  useEffect(() => {
    if (assessmentId && unitId) {
      refetchUnit();
    }
  }, [assessmentId, refetchUnit, unitId]);

  // When the assessment ID changes...
  useEffect(() => {
    // If there is an assessment ID, then we're resuming an assessment, so we can just load it.
    if (assessmentId) {
      refetchAssessment();
    } else {
      // Else this is a _new_ assessment. There's some special logic that we follow which tells us where to start in
      // a new assessment.
      refetchEntryPoint();
    }
  }, [assessmentId, refetchAssessment, refetchEntryPoint]);

  // Sets the assessment unit into the current URL's query args. This should trigger a request to fetch the unit.
  const navigateToUnit = (unitId: string) => {
    setSearchParams({ unitId });
  };

  // Sets the assessment ID and current unit into the path and query args. This should trigger a request to fetch the
  // assessment (if not already loaded), and current unit.
  const onAssessmentUpdated = (assessment: Assessment) => {
    setAssessmentData(assessment);

    if (assessment.status === HootAssessmentStatus.Completed) {
      // Bail. We're done.
      setAssessmentData(undefined);
      if (studentProfileId) {
        navigate(routesDictionary.myStudents.details.assessments.url(studentProfileId));
      }
    } else {
      // Else, we're not done. Go to the next unit.
      if (studentProfileId) {
        navigate(routesDictionary.myStudents.details.assessments.resume.url(studentProfileId, assessment.id) + `?unitId=${assessment.currentUnitId}`);
      }
    }
  };

  // This is only used for "Step-Test" Fields. Step-Test fields are made up of multiple questions. This function is
  // called when we want to focus on the next question _within_ the step-test field.
  const transitionFocusToNextStepTestQuestion = () => {
    if (focusedField?.currentStepperIndex === undefined) {
      return;
    }
    const numQuestionsToShow = calculateStepTestQuestionsToShow(focusedField.sectionField, form.getValues(focusedField.sectionField.name))!;
    const nextQuestionIndex = (focusedField?.currentStepperIndex ?? 0) + 1;

    // Focus on the next Q if we have one.
    if (nextQuestionIndex < numQuestionsToShow) {
      setFocusedField({
        sectionField: focusedField.sectionField,
        currentStepperIndex: nextQuestionIndex,
      });
    } else {
      // Else, we're at the end. In this case, focus on the next field instead.
      transitionFocusToNextField();
    }
  };

  const handleSetUnit = (unit: Unit | undefined) => {
    form.reset(unit?.submissionData ?? {});
    setUnit(unit);
  };

  const confirmLeave = () => navigate(routesDictionary.myStudents.details.assessments.url(studentProfileId!));

  return (
    <HootAssessmentContext.Provider
      value={{
        form,
        focusedField,
        setFocusedField,
        onAssessmentUpdated,
        navigateToUnit,
        confirmLeave,
        transitionFocusToNextField,
        transitionFocusToNextStepTestQuestion,
        unit: unit!,
        onDismissEditDialog: () => setFocusedField(undefined),
        handleSetUnit,
        hasOutstandingQuestions,
        isUnitSubmitted,
      }}
    >
      {!!unit ? props.children : null}
    </HootAssessmentContext.Provider>
  );
};

export const useHootAssessmentContext = () => {
  const context = useContext(HootAssessmentContext);

  if (context === undefined) {
    throw new Error('useHootAssessmentContext must be used within a HootAssessmentContextProvider');
  }
  return context;
};

export default HootAssessmentContextProvider;
