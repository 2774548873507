import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { HootAssessmentStatus } from '@hoot/models/api/enums/hoot-reading-assessment';
import { SortableQuery } from '@hoot/models/api/order-by';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/models/api/pagination';
import { QueryKey } from '../queryKeys';

export interface HootAssessmentV2Response {
  id: string;
  status: HootAssessmentStatus;
  createdAt: number;
  updatedAt: number;
  completedAt: number | undefined;
  startedBy: string;
  lastCompletedUnit?: {
    unitId: string;
    unitName: string;
  };
}

export enum StudentAssessmentsQuerySortKeyEnum {
  StartDate = 'StartDate',
  Status = 'Status',
  CompletionDate = 'CompletionDate',
  ExitPoint = 'ExitPoint',
}

export type GetStudentAssessmentsQuery = PaginatableQuery & SortableQuery<StudentAssessmentsQuerySortKeyEnum>;

export function useGetAssessmentsV2(
  studentProfileId: string,
  query: GetStudentAssessmentsQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<HootAssessmentV2Response>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetAssessmentsV2, studentProfileId, query],
    async () => {
      return (
        await axios.get<GenericPaginatedResponse<HootAssessmentV2Response>>(
          `${config.apiUrl}/public/hoot-assessment/v2/student/${studentProfileId}`,
          {
            params: query,
          },
        )
      ).data;
    },
    options,
  );
}
