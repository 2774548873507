import { ScheduledLessonLanguage, ScheduledLessonSubject, ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';

export interface LessonOpportunitiesQuery {
  page: number;
  pageSize: number;
  orderBy: OrderBy; // only sortable column is lesson date
}

export interface LessonOpportunityResponse {
  lessonId: string;
  startsAt: number;
  updatedAt: number;
  duration: number;
  lessonType: ScheduledLessonType;
  lessonSubject: ScheduledLessonSubject;
  language: ScheduledLessonLanguage;
}

export interface LessonOpportunitiesQueryResponse {
  lessons: LessonOpportunityResponse[];
  count: number;
}

async function getLessonsOpportunities(query: LessonOpportunitiesQuery) {
  const { data } = await axios.get<LessonOpportunitiesQueryResponse>(
    `${config.apiUrl}/public/lesson-opportunity?page=${query.page}&pageSize=${query.pageSize}`,
  );
  return data;
}

export default function useGetLessonOpportunities(
  query: LessonOpportunitiesQuery,
  options?: Omit<UseQueryOptions<LessonOpportunitiesQueryResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetLessonOpportunities, JSON.stringify(query)], () => getLessonsOpportunities(query), options);
}
