import axios from 'axios';
import { useQuery } from 'react-query';
import { TLStoreSnapshot } from 'tldraw';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { UseGetOptions } from '@hoot/models/api/axios-options';
import { WhiteboardTemplateTypeEnum } from '@hoot/models/api/enums/whiteboard-template-type-enum';

export interface WhiteboardStoreSnapshotResponse {
  whiteboardId: string;
  lessonId: string;
  templateType: WhiteboardTemplateTypeEnum;
  templateName: string;
  storeSnapshot: TLStoreSnapshot;
}

export default function useGetLessonWhiteboardSnapshot(whiteboardId: string, options?: UseGetOptions<WhiteboardStoreSnapshotResponse>) {
  return useQuery(
    [QueryKey.GetLessonWhiteboardSnapshot, whiteboardId],
    async () => {
      return (await axios.get<WhiteboardStoreSnapshotResponse>(`${config.apiUrl}/whiteboard/${whiteboardId}/snapshot`)).data;
    },
    options,
  );
}
