import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';

export interface TeacherInvoiceAdditionalServicesQuery {
  sortBy: string;
  orderBy: OrderBy;
}

export interface AdditionalServicesSummaryRow {
  serviceDate: string;
  projectTag: string;
  description: string;
  amount: number;
}

export interface AdditionalServicesSummaryResponse {
  additionalServicesSummaryRows: AdditionalServicesSummaryRow[];
}

async function getTeacherInvoiceAdditionalServicesSummaryResponse(
  invoiceId: string,
  searchParams: TeacherInvoiceAdditionalServicesQuery,
): Promise<AdditionalServicesSummaryResponse> {
  const { data } = await axios.get<AdditionalServicesSummaryResponse>(
    `${config.apiUrl}/public/teacher-invoicing/${invoiceId}/additional-services-summary`,
    {
      params: searchParams,
    },
  );

  return data;
}

export default function useGetTeacherInvoiceAdditionalServicesSummaryResponse(
  invoiceId: string,
  searchParams: TeacherInvoiceAdditionalServicesQuery,
  options?: Omit<UseQueryOptions<AdditionalServicesSummaryResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetInvoiceAdditionalServicesSummary, invoiceId, searchParams],
    () => getTeacherInvoiceAdditionalServicesSummaryResponse(invoiceId, searchParams),
    options,
  );
}
