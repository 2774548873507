import { SvgIcon, SvgIconProps } from '@mui/material';

const PushPinIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6523 0.576929C10.4199 -0.655511 8.3126 0.217349 8.3126 1.96028C8.3126 2.47914 8.1065 2.97674 7.7396 3.34363L6.11887 4.96433C5.75198 5.33122 5.25438 5.53733 4.73552 5.53733C2.99259 5.53733 2.11972 7.6446 3.35216 8.877L5.72844 11.2533L0.210878 16.7709C-0.0702925 17.0521 -0.0702925 17.5079 0.210878 17.7891C0.492058 18.0703 0.947937 18.0703 1.22912 17.7891L6.74668 12.2716L9.1229 14.6478C10.3554 15.8802 12.4626 15.0074 12.4626 13.2645C12.4626 12.7456 12.6688 12.248 13.0357 11.8811L14.6564 10.2604C15.0232 9.8935 15.5209 9.6874 16.0397 9.6874C17.7826 9.6874 18.6555 7.5801 17.4231 6.34768L11.6523 0.576929Z"
        fill="#28303F"
      />
    </SvgIcon>
  );
};

export default PushPinIcon;
