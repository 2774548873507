import { Box, Stack } from '@mui/material';
import { useLayoutEffect, useRef, useState } from 'react';
import { BookResponse } from '@hoot/events/interfaces/book-response';
import { PageZoom } from '@hoot/events/interfaces/page-zoom';
import Reader from '@hoot/ui/components/v2/library/Reader';
import NextPageButton from '@hoot/ui/pages/v2/student/lesson/library/reader/NextPageButton';
import PreviousPageButton from '@hoot/ui/pages/v2/student/lesson/library/reader/PreviousPageButton';

// We can't really use regular ol' breakpoints (window width) b/c height also has an impact on how a book page will
// render on-screen, so we need to go by aspect ratio instead.
// Note: These are just a approximate values based on typical book page dimensions. Not all book pages are sized the same.
const containerAspectRatioBreakpoint = {
  singlePage: 1.0,
  doublePage: 1.5,
};

interface StudentReaderWithControlsProps {
  book: BookResponse;
  isDoublePage: boolean;
  pageIndex: number;
  pageZoom: PageZoom;
  previousPage: () => void;
  nextPage: () => void;
}

const StudentReaderWithControls = (props: StudentReaderWithControlsProps) => {
  const { isDoublePage } = props;

  const containerRef = useRef<HTMLDivElement>();

  const [stackedControls, setStackedControls] = useState(false);

  const determineBookControlPosition = () => {
    const containerAspectRatio = (containerRef.current?.clientWidth ?? 1) / (containerRef.current?.clientHeight ?? 1);
    const breakpoint = isDoublePage ? containerAspectRatioBreakpoint.doublePage : containerAspectRatioBreakpoint.singlePage;

    setStackedControls(containerAspectRatio < breakpoint);
  };

  // Need to run some calculations in order to figure out where to place the book controls (either below or beside the
  // book content) such that we can make the best use of available screen real estate.
  useLayoutEffect(() => {
    // Determine initial book control position.
    determineBookControlPosition();

    const onWindowResized = () => {
      determineBookControlPosition();
    };
    const onOrientationChange = () => {
      determineBookControlPosition();
    };

    // Redetermine book control position on window resize.
    window.addEventListener('resize', onWindowResized);

    // Redetermine book control position on screen orientation change.
    window.addEventListener('orientationchange', onOrientationChange);

    // Clean up.
    return () => {
      window.removeEventListener('resize', onWindowResized);
      window.removeEventListener('orientationchange', onOrientationChange);
    };
  }, [isDoublePage]);

  return (
    <Box sx={{ width: '100%', height: '100%', flex: 1 }} ref={containerRef}>
      {/* Page controls are just in different layout positions if we're showing single page vs double page. */}
      {stackedControls ? <OpenBookWithStackedControls {...props} /> : <OpenBookWithAdjacentControls {...props} />}
    </Box>
  );
};

const OpenBookWithStackedControls = (props: StudentReaderWithControlsProps) => {
  const { book, isDoublePage, pageIndex, pageZoom, previousPage, nextPage } = props;

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Reader openedBook={book} isDoublePage={isDoublePage} pageIndex={pageIndex} pageZoom={pageZoom} />
      <Stack direction="row" justifyContent="space-between" width="100%">
        <PreviousPageButton onPreviousPage={previousPage} />
        <NextPageButton onNextPage={nextPage} />
      </Stack>
    </Stack>
  );
};

const OpenBookWithAdjacentControls = (props: StudentReaderWithControlsProps) => {
  const { book, isDoublePage, pageIndex, pageZoom, previousPage, nextPage } = props;

  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'end',
        position: 'relative',
      }}
    >
      <PreviousPageButton onPreviousPage={previousPage} />
      <Reader openedBook={book!} isDoublePage={isDoublePage} pageIndex={pageIndex} pageZoom={pageZoom} />
      <NextPageButton onNextPage={nextPage} />
    </Stack>
  );
};

export default StudentReaderWithControls;
