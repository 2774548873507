import { EventType } from '@hoot/events/eventType';
import { CloseLessonBookRequestMessage } from '@hoot/events/messages/close-lesson-book-request.message';
import { LessonActivePageChangeRequestMessage } from '@hoot/events/messages/lesson-active-page-change-request.message';
import { LessonBookDoublePageViewChangeRequestMessage } from '@hoot/events/messages/lesson-book-double-page-view-change-request.message';
import { LessonBookPageChangeRequestMessage } from '@hoot/events/messages/lesson-book-page-change-request.message';
import { LessonBookPageZoomChangeRequestMessage } from '@hoot/events/messages/lesson-book-page-zoom-change-request.message';
import { ToggleLessonFavouriteBookRequestMessage } from '@hoot/events/messages/toggle-lesson-favourite-book-request.message';
import { WhiteboardOpenRequestMessage } from '@hoot/events/messages/whiteboard-open-request.message';
import { useEmit } from '@hoot/hooks/useEmit';
import { ActiveLessonPage } from '@hoot/models/api/enums/active-lesson-page-enum';
import { WhiteboardTemplateTypeEnum } from '@hoot/models/api/enums/whiteboard-template-type-enum';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { defaultPageZoom, useReaders } from '@hoot/redux/reducers/readingSlice';

const useTeacherInLessonReaderController = () => {
  const { inLesson } = useActiveLessonState();
  const lessonId = inLesson!.lessonId;

  const { inLessonReader } = useReaders();
  const { pageZoom, isDoublePage } = inLessonReader;

  const closeBookRequestEmitter = useEmit<CloseLessonBookRequestMessage>(EventType.CloseLessonBookRequest, undefined, {
    enabled: false,
  });
  const lessonActivePageChangeRequestEmitter = useEmit<LessonActivePageChangeRequestMessage>(EventType.LessonActivePageChangeRequest, undefined, {
    enabled: false,
  });
  const toggleFavouriteBookEmitter = useEmit<ToggleLessonFavouriteBookRequestMessage>(EventType.ToggleLessonFavouriteBookRequest, undefined, {
    enabled: false,
  });
  const bookPageChangeRequestEmitter = useEmit<LessonBookPageChangeRequestMessage>(EventType.LessonBookPageChangeRequest, undefined, {
    enabled: false,
  });
  const zoomChangeRequestEmitter = useEmit<LessonBookPageZoomChangeRequestMessage>(EventType.LessonBookPageZoomChangeRequest, undefined, {
    enabled: false,
  });
  const doublePageViewChangeRequestEmitter = useEmit<LessonBookDoublePageViewChangeRequestMessage>(
    EventType.LessonBookDoublePageViewChangeRequest,
    undefined,
    {
      enabled: false,
    },
  );
  const openWhiteboardEmitter = useEmit<WhiteboardOpenRequestMessage>(EventType.WhiteboardOpenRequest, undefined, {
    enabled: false,
  });

  const onGoToPageWithId = (pageId: string) => {
    bookPageChangeRequestEmitter.queueMessage({
      lessonId,
      pageId,
    });
  };

  const onToggleDoublePage = () => {
    doublePageViewChangeRequestEmitter.queueMessage({
      lessonId,
      isDoublePage: !isDoublePage,
    });
  };

  const onZoomAmountChange = (zoomAmount: number) => {
    zoomChangeRequestEmitter.queueMessage({
      lessonId,
      pageZoom: {
        ...pageZoom,
        zoomAmount: zoomAmount / 100,
      },
    });
  };

  const onZoomOffsetChange = (offsetLeft: number, offsetTop: number) => {
    zoomChangeRequestEmitter.queueMessage({
      lessonId,
      pageZoom: {
        ...pageZoom,
        offsetLeft,
        offsetTop,
      },
    });
  };

  const onResetZoom = () => {
    zoomChangeRequestEmitter.queueMessage({
      lessonId,
      pageZoom: {
        ...defaultPageZoom,
      },
    });
  };

  const onCloseBook = () => {
    closeBookRequestEmitter.queueMessage({
      lessonId,
    });
  };

  const onNavigateToLibrary = () => {
    lessonActivePageChangeRequestEmitter.queueMessage({
      lessonId,
      activePage: ActiveLessonPage.Library,
    });
  };

  const onNavigateToPMA = () => {
    lessonActivePageChangeRequestEmitter.queueMessage({
      lessonId,
      activePage: ActiveLessonPage.PMA,
    });
  };

  const onToggleStudentFavourite = (bookId: string, markAsFavourite: boolean) => {
    toggleFavouriteBookEmitter.queueMessage({
      bookId,
      lessonId,
      markAsFavourite,
    });
  };

  const onOpenWhiteboard = (whiteboardTemplateType: WhiteboardTemplateTypeEnum) => {
    openWhiteboardEmitter.queueMessage({
      lessonId,
      templateType: whiteboardTemplateType,
    });
  };

  return {
    onGoToPageWithId,
    onToggleDoublePage,
    onZoomAmountChange,
    onZoomOffsetChange,
    onResetZoom,
    onNavigateToLibrary,
    onCloseBook,
    onNavigateToPMA,
    onToggleStudentFavourite,
    onOpenWhiteboard,
  };
};

export default useTeacherInLessonReaderController;
