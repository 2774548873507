import { SvgIcon, SvgIconProps } from '@mui/material';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export const NetworkFull = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M11.2621 19.9937L0.31437 9.43374C-0.100317 9.03374 -0.100317 8.37374 0.324737 7.99374C1.78651 6.72374 3.42452 5.72374 5.24914 4.99374C7.39515 4.13374 9.65519 3.70374 11.9982 3.70374C14.3412 3.70374 16.6012 4.13374 18.7472 4.99374C20.5718 5.72374 22.2098 6.72374 23.6716 7.99374C24.107 8.37374 24.0967 9.03374 23.682 9.43374L12.7239 20.0037C12.3196 20.3937 11.6561 20.3937 11.2517 20.0037L11.2621 19.9937Z"
      fill={hootTokens.palette.black}
    />
  </SvgIcon>
);

export const NetworkHalf = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M11.285 19.9887L0.715021 9.41873C0.315021 9.01873 0.315021 8.36873 0.725021 7.98873C2.14502 6.69873 3.73502 5.69873 5.49502 4.97873C7.56502 4.12873 9.72502 3.69873 11.995 3.69873C14.265 3.69873 16.455 4.12873 18.525 4.97873C20.285 5.69873 21.865 6.70873 23.275 7.99873C23.685 8.37873 23.685 9.02873 23.285 9.42873L12.705 20.0087C12.315 20.3987 11.685 20.3987 11.295 20.0087L11.285 19.9887ZM5.21502 11.0687C5.54502 11.3987 6.06502 11.4387 6.46502 11.1987C7.15502 10.7787 7.89502 10.4287 8.68502 10.1587C9.73502 9.79873 10.835 9.61873 11.985 9.61873C13.135 9.61873 14.235 9.79873 15.285 10.1587C16.075 10.4287 16.815 10.7687 17.505 11.1987C17.905 11.4487 18.425 11.4087 18.755 11.0687L20.235 9.58873C20.695 9.12873 20.595 8.36873 20.055 8.02873C19.045 7.38873 17.965 6.86873 16.815 6.47873C15.275 5.95873 13.665 5.68873 11.975 5.68873C10.285 5.68873 8.67502 5.94873 7.13502 6.47873C5.98502 6.86873 4.91502 7.38873 3.89502 8.02873C3.35502 8.37873 3.26502 9.13873 3.71502 9.58873L5.19502 11.0687H5.21502Z"
      fill={hootTokens.palette.black}
    />
  </SvgIcon>
);

export const NetworkLow = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M11.29 20.0037L0.720049 9.42374C0.330049 9.03374 0.320049 8.37374 0.730049 7.99374C2.15005 6.70374 3.74005 5.70374 5.50005 4.98374C7.57005 4.13374 9.73005 3.70374 12 3.70374C14.27 3.70374 16.4601 4.13374 18.5201 4.98374C20.2801 5.70374 21.8601 6.71374 23.2701 8.00374C23.6801 8.38374 23.6701 9.03374 23.2801 9.42374L12.7 20.0037C12.31 20.3937 11.68 20.3937 11.29 20.0037ZM8.47005 14.3337C8.77005 14.6337 9.24005 14.7137 9.62005 14.5137C9.87005 14.3837 10.13 14.2737 10.41 14.1837C10.92 14.0137 11.45 13.9337 12 13.9337C12.55 13.9337 13.08 14.0137 13.59 14.1837C13.87 14.2737 14.13 14.3837 14.38 14.5137C14.76 14.7137 15.23 14.6337 15.53 14.3337L20.26 9.60374C20.71 9.15374 20.62 8.39374 20.08 8.04374C19.07 7.40374 17.99 6.88374 16.84 6.49374C15.3 5.96374 13.69 5.70374 12 5.70374C10.31 5.70374 8.70005 5.96374 7.16005 6.49374C6.01005 6.88374 4.93005 7.40374 3.92005 8.04374C3.38005 8.38374 3.29005 9.14374 3.74005 9.60374L8.47005 14.3337Z"
      fill={hootTokens.palette.error[100]}
    />
  </SvgIcon>
);
