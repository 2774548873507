import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { EnrolmentStatus } from '@hoot/models/api/enums/Enrolments';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';

export enum DistrictRepresentativeEnrolmentSearchType {
  Student = 'student',
  School = 'school',
  Product = 'product',
  Enrolment = 'enrolment',
}

export interface DistrictRepresentativeEnrolmentsQuery {
  name?: string;
  enrolmentId?: string;
  studentProfileId?: string;
  locationId?: string;
  productId?: string;
  status?: EnrolmentStatus[];
  startDate?: number;
  endDate?: number;
  searchType: DistrictRepresentativeEnrolmentSearchType;
  page: number;
  pageSize: number;
  sortBy: string;
  orderBy: OrderBy;
}

export interface EnrolmentsResponse {
  id: string;
  friendlyId: string;
  status: EnrolmentStatus;
  startDate: number;
  endDate: number;
  productId?: string;
  productFriendlyId?: string;
  numberOfStudentsRegistered?: number;
}

export interface DistrictRepresentativeEnrolmentsResponse {
  enrolments: EnrolmentsResponse[];
  count: number;
  page: number;
  pageSize: number;
}

async function searchDistrictRepresentativeEnrolments(
  districtRepresentativeId: string,
  searchParams: DistrictRepresentativeEnrolmentsQuery,
): Promise<DistrictRepresentativeEnrolmentsResponse> {
  const { data } = await axios.get<DistrictRepresentativeEnrolmentsResponse>(
    `${config.apiUrl}/public/district-representative/${districtRepresentativeId}/enrolments`,
    { params: searchParams },
  );

  return data;
}

export default function useSearchDistrictRepresentativeEnrolments(
  districtRepresentativeId: string,
  searchParams: DistrictRepresentativeEnrolmentsQuery,
  options?: Omit<UseQueryOptions<DistrictRepresentativeEnrolmentsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.SearchDistrictRepresentativeEnrolments, districtRepresentativeId, searchParams],
    () => searchDistrictRepresentativeEnrolments(districtRepresentativeId, searchParams),
    options,
  );
}
