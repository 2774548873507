import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { StudentGrade } from '@hoot/models/api/student';
import { QueryKey } from '../queryKeys';

export interface StudentProfileSnippetResponse {
  id: string;
  number: number;
  name: string;
  grade: StudentGrade | null;
}

export default function useGetStudentProfileSnippet(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<StudentProfileSnippetResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetStudentProfileSnippet, studentProfileId],
    async () => {
      return (await axios.get<StudentProfileSnippetResponse>(`${config.apiUrl}/teacher/student-profile/v2/${studentProfileId}/snippet`)).data;
    },
    options,
  );
}
