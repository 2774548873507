import React from 'react';
import { LessonWhiteboardTemplateResponse } from '@hoot/hooks/api/whiteboard/useGetLessonWhiteboardTemplates';
import { useStudentMediaState } from '@hoot/hooks/daily/useDailyMediaState';
import AsyncImage from '@hoot/ui/components/v2/AsyncImage';
import GrowList from '@hoot/ui/components/v2/GrowList';
import Card from '@hoot/ui/components/v2/core/Card';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface StudentInLessonWhiteboardLibraryProps {
  whiteboardTemplates: LessonWhiteboardTemplateResponse[];
  selectedWhiteboardTemplate: LessonWhiteboardTemplateResponse | null;
  onWhiteboardTemplateSelected: (wt: LessonWhiteboardTemplateResponse) => void;
}

const StudentInLessonWhiteboardTemplates = (props: StudentInLessonWhiteboardLibraryProps) => {
  const { whiteboardTemplates, selectedWhiteboardTemplate, onWhiteboardTemplateSelected } = props;

  const { isLocked: studentLocked } = useStudentMediaState();

  return (
    <GrowList<LessonWhiteboardTemplateResponse>
      StackProps={{
        direction: 'row',
        sx: {
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 2,
          flexWrap: 'wrap',
        },
      }}
      itemRenderTimeoutMillis={100}
      items={whiteboardTemplates}
      getKey={(wt) => wt.whiteboardTemplate.templateType}
      renderItem={(wt) => {
        const isSelected = wt.whiteboardTemplate.templateType === selectedWhiteboardTemplate?.whiteboardTemplate.templateType;

        const _onWhiteboardTemplateSelected = () => {
          onWhiteboardTemplateSelected(wt);
        };
        return (
          <Card
            sx={{
              width: '248px',
              border: isSelected ? '5px solid' : undefined,
              padding: isSelected ? '11px' : '16px',
              borderColor: isSelected ? hootTokens.palette.success['80'] : undefined,
              cursor: studentLocked ? undefined : 'pointer',
            }}
            onClick={_onWhiteboardTemplateSelected}
          >
            <AsyncImage
              draggable={false}
              width="216px"
              style={{
                objectFit: 'contain',
                width: '100%',
                borderRadius: '8px',
                minHeight: '216px',
                height: '100%',
              }}
              src={wt.whiteboardTemplate.imageUrl ?? undefined}
              alt={wt.whiteboardTemplate.name}
            />
          </Card>
        );
      }}
    />
  );
};

export default StudentInLessonWhiteboardTemplates;
