import { SvgIcon, SvgIconProps } from '@mui/material';

const CameraOnIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5937 3.8906L17 6H18C20.2091 6 22 7.79086 22 10V17C22 19.2091 20.2091 21 18 21H6C3.79086 21 2 19.2091 2 17V10C2 7.79086 3.79086 6 6 6H7L8.40627 3.8906C8.7772 3.3342 9.40166 3 10.0704 3H13.9296C14.5983 3 15.2228 3.3342 15.5937 3.8906ZM9 13.5C9 15.1569 10.3431 16.5 12 16.5C13.6569 16.5 15 15.1569 15 13.5C15 11.8431 13.6569 10.5 12 10.5C10.3431 10.5 9 11.8431 9 13.5ZM12 7C11.4477 7 11 6.55228 11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6C13 6.55228 12.5523 7 12 7Z"
        fill={props.htmlColor ?? 'currentColor'}
      />
    </SvgIcon>
  );
};

export default CameraOnIcon;
