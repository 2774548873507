import { Card, Collapse, IconButton, Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import PopoverMenu, { MenuItem } from '@hoot/ui/components/v2/core/PopoverMenu';
// import { ZoomControl } from "@hoot/ui/components/v2/library/ZoomControl";
import { useFullscreen } from '@hoot/ui/context/FullscreenContext';
import { useToolsContext } from '@hoot/ui/context/ToolsContext';
import { ActiveLessonPage } from '../../../../../../models/api/enums/active-lesson-page-enum';
import { useActiveLessonState } from '../../../../../../redux/reducers/activeLessonSlice';
import { ReaderContext, readerKeyLookup, useReaders } from '../../../../../../redux/reducers/readingSlice';
import { Select, SelectProps } from '../../../../../components/v2/core/Select';
import { Coords, ZoomControl } from '../../../../../components/v2/library/ZoomControl';
import TeacherInLessonReaderPage from '../library/reader/TeacherInLessonReaderPage';
import useTeacherInLessonReaderController from '../library/reader/useTeacherInLessonReaderController';
import useTeacherInLessonLibraryController from '../library/useTeacherInLessonLibraryController';
import LeavePMADialog from './dialogs/LeavePMADialog';
import { PMAForms, pmaInstructions, pmaTitle } from './enum';
import { questionnaires, questionnaires2 } from './test-data';
import { UNIT_1_1 } from './units/UNIT_1_1';
import UNIT_1_5 from './units/UNIT_1_5';
import UNIT_2 from './units/UNIT_2_3';
import { UNIT_4_1 } from './units/UNIT_4_1';
import { UNIT_4_2 } from './units/UNIT_4_2';

const PMAPage = () => {
  const { lessonId } = useParams();

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const [form, setForm] = useState<PMAForms>(PMAForms.Unit_1_1);

  const controller = useTeacherInLessonReaderController();

  const lessonState = useActiveLessonState();
  const bookLoaded = useRef(false);

  // TODO: remove this! The Library controller should only be used in the In-Lesson Library Page!
  const { onOpenBook } = useTeacherInLessonLibraryController({
    // This _must_ exist for this page to even render, so we know it exists.
    inLesson: lessonState.inLesson!,
  });

  useEffect(() => {
    if (!bookLoaded.current) {
      onOpenBook('3f69f9d0-d776-4ed3-9e97-8d2547aafeb0', ActiveLessonPage.PMA);
      bookLoaded.current = true;
    }
  }, [onOpenBook]);

  const handleConfirm = () => {
    setShowConfirmDialog(false);
    if (lessonId) {
      controller.onNavigateToLibrary();
    }
  };

  const handleShowConfirmationClick = (showConfirmation: boolean) => {
    setShowConfirmDialog(showConfirmation);
  };

  const showBook = [PMAForms.Unit_4_1, PMAForms.Unit_4_3A, PMAForms.Unit_4_3B].includes(form);

  const handleOnFormChange = (newForm: PMAForms) => {
    setForm(newForm);
  };

  return (
    <Stack flex={1} sx={{ padding: 2, overflowY: 'scroll' }}>
      <Stack justifyContent="flex-start">
        <Stack justifyContent="flex-start">
          <AssessmentCard
            showToolsMenu={true}
            unitTitle={pmaTitle[form]}
            form={form}
            onShowConfirmationClick={handleShowConfirmationClick}
            onFormChange={handleOnFormChange}
          />
        </Stack>
        {showBook ? <TeacherInLessonReaderPage /> : null}
        <LeavePMADialog open={showConfirmDialog} setOpen={setShowConfirmDialog} confirmLeave={handleConfirm} />
      </Stack>
    </Stack>
  );
};

function AssessmentCard(props: {
  form: PMAForms;
  showToolsMenu: boolean;
  unitTitle: string;
  onShowConfirmationClick: (showConfirmation: boolean) => void;
  onFormChange: (form: PMAForms) => void;
}) {
  const allFormFieldsFilledOut = true;
  const isThereAnotherUnit = false;

  return (
    <Card>
      <Stack>
        <Stack sx={{ height: '100%', gap: 1, justifyContent: 'flex-start', alignItems: 'left' }}>
          <Stack direction="row" justifyContent="space-between">
            <HootTypography isPII={false} variant="titlelarge">
              Progress Monitoring Assessment
            </HootTypography>
            <Stack direction="row">
              <FormSelector value={props.form} onChange={props.onFormChange} />
              <ToolsMenu form={props.form} onShowConfirmationClick={props.onShowConfirmationClick} />
            </Stack>
          </Stack>
          <HootTypography isPII={false} variant="bodylarge">
            {props.unitTitle}
          </HootTypography>
          <Instructions form={props.form} />
          <Content form={props.form} />
        </Stack>
        <Footer allFormFieldsFilledOut={allFormFieldsFilledOut} isThereAnotherUnit={isThereAnotherUnit} />
      </Stack>
    </Card>
  );
}

function FormSelector(props: { value: PMAForms; onChange: (form: PMAForms) => void }) {
  const handleChange: SelectProps['onChange'] = (event) => {
    props.onChange(event.target.value as PMAForms);
  };

  return (
    <Select label="PMA Form" value={props.value} onChange={handleChange}>
      {Object.entries(PMAForms).map((option) => (
        <option value={option[1]} key={`primary-focus-skill-option-${option[0]}`}>
          {option[0]}
        </option>
      ))}
    </Select>
  );
}

function ToolsMenu(props: { form: PMAForms; onShowConfirmationClick: (showConfirmation: boolean) => void }) {
  const {
    isCelebrationsDialogVisible,
    onShowCelebrationsDialog,
    onHideCelebrationsDialog,
    isNotepadVisible,
    onShowNotepad,
    onHideNotepad,
    isTimerVisible,
    onShowTimer,
    onHideTimer,
  } = useToolsContext();

  const readerKey = readerKeyLookup[ReaderContext.InLessonReader];
  const reader = useReaders()[readerKey];

  const controller = useTeacherInLessonReaderController();

  const [showZoomControl, setShowZoomControl] = useState(false);
  const { toggleFullscreen, isFullscreen } = useFullscreen();

  const onToggleFullscreen = async () => {
    dismissToolsMenu();
    await toggleFullscreen();
  };

  const toggleShowTimer = () => {
    if (isTimerVisible) {
      onHideTimer();
    } else {
      onShowTimer();
    }
    dismissToolsMenu();
  };

  const toggleShowNotepad = () => {
    if (isNotepadVisible) {
      onHideNotepad();
    } else {
      onShowNotepad();
    }
    dismissToolsMenu();
  };

  const toggleCelebrationsDialog = () => {
    if (isCelebrationsDialogVisible) {
      onHideCelebrationsDialog();
    } else {
      onShowCelebrationsDialog();
    }
    dismissToolsMenu();
  };

  const onZoomClick = () => {
    setShowZoomControl(true);
    dismissToolsMenu();
  };

  const [toolsIconButtonElement, setToolsIconButtonElement] = useState<null | HTMLElement>(null);

  const dismissToolsMenu = () => {
    setToolsIconButtonElement(null);
  };

  const isModule4 = [PMAForms.Unit_4_1, PMAForms.Unit_4_3A, PMAForms.Unit_4_3B].includes(props.form);
  const disableDoublePage = !isModule4;

  const showToolsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setToolsIconButtonElement(event.currentTarget);
  };

  const doublePageToolTip = () => {
    if (!isModule4) {
      return 'Double Page Disabled for this Module';
    }

    return 'Show Double Page View';
  };

  const handleShowConfirmationClick = () => {
    props.onShowConfirmationClick(true);
  };

  const handleDoublePage = () => {
    controller.onToggleDoublePage();
  };

  const toolsMenuItems = (): MenuItem[] => {
    const toolItemsArray = [
      {
        id: 'go-fullscreen',
        label: isFullscreen ? 'Exit Fullscreen' : 'Go Fullscreen',
        icon: <Icon name={isFullscreen ? 'exit_full_screen' : 'enter_full_screen'} />,
        onClick: onToggleFullscreen,
      },
      { id: 'zoom', label: 'Zoom', icon: <Icon name="zoom_in" />, onClick: onZoomClick },
      {
        id: 'notepad',
        label: 'Notepad',
        icon: <Icon name="pen" />,
        onClick: toggleShowNotepad,
      },
      { id: 'timer', label: 'Timer', icon: <Icon name="clock" />, onClick: toggleShowTimer },
      {
        id: 'celebrations',
        label: 'Celebrations',
        icon: <Icon name="hoot_wheel" />,
        onClick: toggleCelebrationsDialog,
      },
    ];

    return toolItemsArray;
  };

  const {
    inLessonReader: { book, pageIndex, isDoublePage, pageZoom },
  } = useReaders();

  const zoomBoxCoordsRef = useRef<Coords>({ x: 0, y: 0 });

  const pagesToShow = book?.pages.slice(pageIndex, pageIndex + (isDoublePage ? 2 : 1)).map((p) => p.url) ?? [];

  return (
    <Stack direction="row" gap={2}>
      <Tooltip title="Show Tools Menu">
        <IconButton onClick={showToolsMenu}>
          <Icon name="compass" />
        </IconButton>
      </Tooltip>
      <Tooltip title={doublePageToolTip()}>
        <IconButton disabled={!isModule4} onClick={handleDoublePage}>
          <Icon name={reader.isDoublePage ? 'book_double' : 'single_page'} color={disableDoublePage ? 'primary.80' : 'primary'} />
        </IconButton>
      </Tooltip>
      <IconButton onClick={handleShowConfirmationClick}>
        <Icon name={'close_filled_square'} color={'error.80'} sx={{ background: 'error.80' }} />
      </IconButton>

      <PopoverMenu
        id="tools-menu"
        variant="dark"
        anchorEl={toolsIconButtonElement}
        open={!!toolsIconButtonElement}
        onClose={dismissToolsMenu}
        items={toolsMenuItems()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
      {showZoomControl ? (
        <ZoomControl
          onClose={() => setShowZoomControl(false)}
          pages={pagesToShow}
          onReset={controller.onResetZoom}
          onZoomChange={controller.onZoomAmountChange}
          onOffsetChange={controller.onZoomOffsetChange}
          zoomValue={pageZoom.zoomAmount * 100}
          zoomBoxCoordsRef={zoomBoxCoordsRef}
          onToggleDoublePage={controller.onToggleDoublePage}
          readerContext={ReaderContext.InLessonReader}
        />
      ) : null}
    </Stack>
  );
}

function Instructions(props: { form: PMAForms }) {
  const [openInstructions, setOpenInstructions] = useState<boolean>(false);

  const handleClick = () => {
    setOpenInstructions((prevVal) => !prevVal);
  };

  return (
    <>
      <Button
        startIcon={
          openInstructions ? (
            <Icon name="collapse_filled" sx={{ width: 200, height: 20 }} />
          ) : (
            <Icon name="solid_add_rectangle" htmlColor="primary.0" />
          )
        }
        onClick={handleClick}
        sx={{ width: '150px', gap: '8px' }}
      >
        <HootTypography isPII={false} variant="titlesmall">
          Instructions
        </HootTypography>
      </Button>
      <Collapse in={openInstructions} timeout="auto" unmountOnExit>
        <HootTypography isPII={false} variant="bodylarge">
          {pmaInstructions[props.form]}
        </HootTypography>
      </Collapse>
    </>
  );
}

function Content(props: { form: PMAForms }) {
  switch (props.form) {
    case PMAForms.Unit_1_1:
      return (
        <UNIT_1_1
          wordList={[
            { word: 'mug', expectedResponse: '“/m/ /ŭ/ /g/”' },
            { word: 'frame', expectedResponse: '“/fr/ /a/ /me/”' },
            { word: 'bike', expectedResponse: '“/b/ /i/ /ke/”' },
            { word: 'hush', expectedResponse: '“/h/ /ŭ/ /sh/”' },
            { word: 'log', expectedResponse: '“/l/ /o/ /g/”' },
          ]}
        />
      );
    case PMAForms.Unit_1_5:
      return <UNIT_1_5 />;
    case PMAForms.Unit_2_1:
      return <UNIT_2 details={[]} />;
    case PMAForms.Unit_3_1:
      return <UNIT_2 details={[]} />;
    case PMAForms.Unit_4_1:
      return <UNIT_4_1 />;
    case PMAForms.Unit_4_2:
      return <UNIT_4_2 questionnaire={questionnaires} />;
    case PMAForms.Unit_4_3A:
      return null;
    case PMAForms.Unit_4_3B:
      return <UNIT_4_2 questionnaire={questionnaires2} />;
    default:
      return null;
  }
}

function Footer(props: { allFormFieldsFilledOut: boolean; isThereAnotherUnit: boolean }) {
  return (
    <Stack direction="row" justifyContent="space-between" sx={{ borderTop: '1px solid #D3D3D3', paddingTop: 2 }}>
      <Button variant="contained" startIcon={<Icon name="arrow_icon" color="primary.200" rotate="180deg" />} size="small">
        Previous Unit
      </Button>
      <Button variant="contained" startIcon={<Icon name="arrow_icon" color="primary.200" />} size="small" disabled={!props.allFormFieldsFilledOut}>
        Submit and {props.isThereAnotherUnit ? 'Continue' : 'Close'}
      </Button>
    </Stack>
  );
}

export default PMAPage;
