import { Stack } from '@mui/system';
import Draggable from 'react-draggable';
import { zIndexes } from '@hoot/constants/zIndices';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import { hootTokens } from '../../../theme/v2/tokens';
import HootTypography from './HootTypography';
import { Icon } from './Icon';

export function FloatingDialog(props: { title: string; children: React.ReactChild; onClose: () => void; width: string; height?: string }) {
  return (
    <Draggable handle="#header">
      <Stack
        sx={{
          backgroundColor: hootTokens.palette.white,
          borderRadius: '8px',
          position: 'absolute',
          top: 0,
          left: 0,
          padding: '16px',
          boxShadow: hootTokens.elevation.elevation5,
          width: props.width,
          height: props.height,
          zIndex: zIndexes.floatingDialog,
        }}
      >
        <Stack sx={{ cursor: 'move' }} id="header" justifyContent="space-between" alignItems="center" direction="row">
          <HootTypography isPII={false} variant="titlesmall">
            {props.title}
          </HootTypography>
          {/* Note: onTouchStart needed as react-draggable overlays the button functionality */}
          <IconButton onClick={props.onClose} onTouchStart={props.onClose}>
            <Icon name="close" />
          </IconButton>
        </Stack>

        {props.children}
      </Stack>
    </Draggable>
  );
}
