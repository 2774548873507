import { useAudioLevel } from '@daily-co/daily-react';
import { MediaTrackState } from '@daily-co/daily-react/dist/hooks/useMediaTrack';
import { Box, styled } from '@mui/material';
import { Stack } from '@mui/system';
import { useCallback, useRef } from 'react';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import SpeechIcon from '../icons/SpeechIcon';

interface AudioVisualizerProps {
  audioTrack: MediaTrackState;
}

const DailyAudioVisualizer = (props: AudioVisualizerProps) => {
  const volRef1 = useRef<HTMLDivElement>(null);
  const volRef2 = useRef<HTMLDivElement>(null);
  const volRef3 = useRef<HTMLDivElement>(null);

  useAudioLevel(
    props.audioTrack?.persistentTrack,
    useCallback(
      (volume) => {
        if (volRef1.current && volRef2.current && volRef3.current) {
          // Set the 3 volume "bars" to its initial heights if the audio track is muted or disabled.
          if (props.audioTrack.persistentTrack?.muted || props.audioTrack.isOff) {
            volRef1.current.style.height = '4px';
            volRef2.current.style.height = '4px';
            volRef3.current.style.height = '4px';
          } else {
            volRef1.current.style.height = `${Math.min(volume * 50, 20)}px`;
            volRef2.current.style.height = `${Math.min(volume * 100, 20)}px`;
            volRef3.current.style.height = `${Math.min(volume * 50, 20)}px`;
          }
        }
      },
      [props.audioTrack.isOff, props.audioTrack.persistentTrack?.muted],
    ),
  );

  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="center" position="relative" height={24}>
      <SpeechIcon sx={{ position: 'absolute' }} />
      <Bar ref={volRef1} />
      <Bar ref={volRef2} />
      <Bar ref={volRef3} />
    </Stack>
  );
};

const Bar = styled(Box)({
  width: '4px',
  maxHeight: '20px',
  minHeight: '4px',
  margin: '1px',
  border: 'solid 1px white',
  borderRadius: '8px',
  background: hootTokens.palette.white,
  transitionDuration: '0.2s',
  zIndex: 1,
});

export default DailyAudioVisualizer;
