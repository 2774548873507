import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface TeacherAvailablityExceptionDraftsResponse {
  drafts: TeacherAvailablityExceptionDraftResponse[];
}

export interface TeacherAvailablityExceptionDraftResponse {
  id: string;
  startsAt: number;
  endsAt: number;
  createdAt: number;
}

async function getTeacherAvailabilityExceptionDrafts() {
  const { data } = await axios.get<TeacherAvailablityExceptionDraftsResponse>(`${config.apiUrl}/public/availability-exception/active-drafts`);
  return data;
}

export function useGetTeacherAvailabilityExceptionDrafts(
  options?: Omit<UseQueryOptions<TeacherAvailablityExceptionDraftsResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetTeacherAvailablityExceptionDrafts], () => getTeacherAvailabilityExceptionDrafts(), options);
}
