import { Box, BoxProps, Dialog, Paper, PaperProps, useMediaQuery, useTheme } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

interface DoublePanedDialogProps extends PropsWithChildren<any> {
  show: boolean;
  rightContentPane?: ReactNode;
  rootSx?: BoxProps['sx'];
  leftContentPaneSx?: PaperProps['sx'];
  rightContentPaneSx?: PaperProps['sx'];
  rootDialogMaxWidth: () => '900px' | '1200px' | '675px' | '1400px';
}

const DoublePanedDialog = (props: DoublePanedDialogProps) => {
  const {
    show,
    children: leftContentPane,
    leftContentPaneSx = {},
    rightContentPane,
    rightContentPaneSx = {},
    rootSx = {},
    rootDialogMaxWidth,
  } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Dialog
      open={show}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: rootDialogMaxWidth,
          boxShadow: 'unset',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
          justifyContent: 'center',
          width: '100%',
          maxHeight: '80%',
          gap: 1,
          backgroundColor: 'rgba(0,0,0,0.7)',
          overflowY: 'auto',
          ...rootSx,
        }}
      >
        <Paper
          sx={{
            width: '100%',
            maxWidth: '1092px',
            borderRadius: '8px',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            ...leftContentPaneSx,
          }}
        >
          {leftContentPane}
        </Paper>

        {rightContentPane && !isSmallScreen && (
          <Paper
            sx={{
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              width: '254px',
              ...rightContentPaneSx,
            }}
          >
            {rightContentPane}
          </Paper>
        )}
      </Box>
    </Dialog>
  );
};

export default DoublePanedDialog;
