import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { Orientation, useWindowSize } from '../../../../../../hooks/useWindowSize';

export function VideoSkeleton() {
  const theme = useTheme();
  const isBelowDesktop = useMediaQuery(theme.breakpoints.down('md'));
  const { orientation } = useWindowSize();
  const isTabletPortrait = orientation === Orientation.Portrait && isBelowDesktop;

  return <Skeleton variant="rectangular" width="100%" height={isTabletPortrait ? 320 : 240} sx={{ borderRadius: '8px' }} />;
}
