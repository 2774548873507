/*
 * Maintaining a whitelist of local storage items that we do not want removed when the user
 * ends their session. As of Feb 2022 this only includes the user Selected Audio and Video devices.
 * These values are hard-coded as otherwise we would need to involve the api to store and retrieve
 * a small and limited number of values.
 */

let localStorageWhitelist: string[] = ['VIDEO_DEVICE_ID', 'AUDIO_DEVICE_ID', 'HOOT_READING_COOKIE_CONSENT'];

//clears all items in local storage except items in whitelist
export function clearLocalStorage() {
  for (const key in localStorage) {
    if (key && !localStorageWhitelist.includes(key)) {
      localStorage.removeItem(key);
    }
  }
}
