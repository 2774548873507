import React from 'react';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import { useStudentMediaState } from '@hoot/hooks/daily/useDailyMediaState';
import StudentLibraryBook from '@hoot/ui/pages/v2/student/library/StudentLibraryBook';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface StudentInLessonLibraryBookProps {
  book: BookSearch;
  isSelected: boolean;
  onBookSelected: (book: BookSearch) => void;
}

const StudentInLessonLibraryBook = (props: StudentInLessonLibraryBookProps) => {
  const { book, isSelected, onBookSelected } = props;

  const { isLocked: studentLocked } = useStudentMediaState();

  const _onBookSelected = (book: BookSearch) => {
    onBookSelected(book);
  };

  return (
    <StudentLibraryBook
      book={book}
      onBookClicked={_onBookSelected}
      CardProps={{
        sx: {
          border: isSelected ? '5px solid' : undefined,
          borderColor: isSelected ? hootTokens.palette.success['80'] : undefined,
          cursor: studentLocked ? undefined : 'pointer',
        },
        contentSx: {
          padding: isSelected ? '11px' : '16px',
          paddingTop: isSelected ? '7px' : '12px',
        },
      }}
    />
  );
};

export default StudentInLessonLibraryBook;
