import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface FlfVerifyEmailRequest {
  email: string;
  verificationCode: string;
}

export interface FlfVerifiedEmailResponse {
  verificationToken: string;
}

export default function useFlfVerifyEmailRequest() {
  return useMutation<FlfVerifiedEmailResponse, AxiosError, FlfVerifyEmailRequest>(async (request) => {
    return (await axios.post(`${config.apiUrl}/free-lesson/v2/verify-email`, request)).data;
  });
}
