export enum PMAForms {
  Unit_1_1 = 'UNIT_1_1',
  Unit_1_5 = 'UNIT_1_5',
  Unit_2_1 = 'UNIT_2_1',
  Unit_3_1 = 'UNIT_3_1',
  Unit_4_1 = 'UNIT_4_1',
  Unit_4_2 = 'UNIT_4_2',
  Unit_4_3A = 'UNIT_4_3A',
  Unit_4_3B = 'UNIT_4_3B',
}

export const pmaTitle: Record<PMAForms, string> = {
  UNIT_1_1: 'Segmentation (Module 1.1)',
  UNIT_1_5: 'Initial Letter Sounds',
  UNIT_2_1: 'Short Vowels CVC',
  UNIT_3_1: 'Short Vowels CVC',
  UNIT_4_1: 'Fluency',
  UNIT_4_2: 'Fluency',
  UNIT_4_3A: 'Comprehension',
  UNIT_4_3B: 'Comprehension',
};

export const pmaInstructions: Record<PMAForms, string> = {
  UNIT_1_1: 'Ask the students to break apart the sounds in the prompt. Indicate whether they got the sounds correct. Example:',
  UNIT_1_5:
    'Have the student produce the sound for each letter. If they name the letter, redirect them to say the sound. Record the sounds they said correctly.',
  UNIT_2_1: 'As the student reads the words, mark the words they read correctly.',
  UNIT_3_1: 'As the student reads the words, mark the words they read correctly.',
  UNIT_4_1: 'Please time the student reading the selected passage. Enter the errors and time spent reading.',
  UNIT_4_2:
    'From listening to the student read during the Text Reading portion of the assessment, use the rubrics to assess the student’s phrasing and expression scores.',
  UNIT_4_3A: `After the student has finished reading the selected text, ask every question included in the Text Reading Assessment book. Allow and support students to navigate back through the text to best answer these questions. Score student responses using the rubric below. Remember, you'll score vocabulary holistically, thinking about how the student has answered all other questions.`,
  UNIT_4_3B: `After the student has finished reading the selected text, ask every question included in the Text Reading Assessment book. Allow and support students to navigate back through the text to best answer these questions. Score student responses using the rubric below. Remember, you'll score vocabulary holistically, thinking about how the student has answered all other questions.`,
};
