import { Box, Stack, useMediaQuery } from '@mui/material';
import { isAxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import useFlfCreateStudentProfileRequest from '@hoot/hooks/api/free-lesson-flow/useFlfCreateStudentProfileRequest';
import useFlfUpdateStudentProfileRequest from '@hoot/hooks/api/free-lesson-flow/useFlfUpdateStudentProfileRequest';
import useGetFlfStudentProfileRequest, { FlfStudentProfileResponse } from '@hoot/hooks/api/free-lesson-flow/useGetFlfStudentProfileRequest';
import { ErrorResponseDto } from '@hoot/models/api/error-response-dto';
import { StudentGrade, studentGradeLabelDictionary } from '@hoot/models/api/student';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { googleAnalytics } from '@hoot/telemetry/google-analytics';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Select } from '@hoot/ui/components/v2/core/Select';
import TextField from '@hoot/ui/components/v2/core/TextField';
import { FlfWizardStep } from '@hoot/ui/pages/v2/public/free-lesson/registration/FreeLessonRegistrationWizard';
import CoppaConsentDialog, { CoppaConsentDialogProps } from '@hoot/ui/pages/v2/public/free-lesson/registration/steps/CoppaConsentDialog';
import theme from '@hoot/ui/theme/v2';

interface CreateStudentProfileForm {
  studentName: string;
  pronouns: string;
  grade: StudentGrade | '';
  additionalInformation: string;
  isCoppaConsentGranted: boolean;
}

const FreeLessonCreateStudentProfile = () => {
  const [selectedStudentProfile, setSelectedStudentProfile] = useState<FlfStudentProfileResponse>();
  const [showCoppaConsent, setShowCoppaConsent] = useState(false);
  const [hasCoppaConsentBeenShown, setHasCoppaConsentBeenShown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();

  const createStudentProfileRequest = useFlfCreateStudentProfileRequest();
  const updateStudentProfileRequest = useFlfUpdateStudentProfileRequest();

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useGetFlfStudentProfileRequest({
    onSuccess: (response) => {
      setSelectedStudentProfile(response.selectedStudentProfile);
    },
  });

  const { control, handleSubmit, watch, setValue, reset } = useForm<CreateStudentProfileForm>({
    defaultValues: {
      studentName: '',
      pronouns: '',
      grade: '',
      additionalInformation: '',
      isCoppaConsentGranted: false,
    },
    mode: 'onSubmit',
  });

  // Pre-populate the form values if/when the student profile is loaded.
  useEffect(() => {
    reset({
      studentName: selectedStudentProfile?.studentName ?? '',
      pronouns: selectedStudentProfile?.pronouns ?? '',
      grade: selectedStudentProfile?.grade ?? '',
      additionalInformation: selectedStudentProfile?.additionalInformation ?? '',
      isCoppaConsentGranted: selectedStudentProfile?.isCoppaConsentGranted ?? false,
    });
  }, [selectedStudentProfile, reset]);

  const isCoppaConsentGranted = watch('isCoppaConsentGranted');

  const onViewCoppaConsent = () => {
    setShowCoppaConsent(true);
  };

  const onDismissCoppaConsent = () => {
    setHasCoppaConsentBeenShown(true);
    setShowCoppaConsent(false);
  };

  const onApplyCoppaConsent: CoppaConsentDialogProps['onApplyCoppaConsent'] = (isCoppaConsentGranted) => {
    setValue('isCoppaConsentGranted', isCoppaConsentGranted);
    onDismissCoppaConsent();
  };

  const onSubmitForm: SubmitHandler<CreateStudentProfileForm> = (form) => {
    const { studentName, pronouns, grade, additionalInformation, isCoppaConsentGranted } = form;

    setIsSubmitting(true);

    // If we're looking at an active student profile, then we're updating the profile.
    if (selectedStudentProfile) {
      updateStudentProfileRequest.mutate(
        {
          studentProfileId: selectedStudentProfile.id,
          studentName,
          pronouns: pronouns.length ? pronouns : undefined,
          grade: grade as StudentGrade,
          additionalInformation: additionalInformation.length ? additionalInformation : undefined,
          isCoppaConsentGranted,
        },
        {
          onSuccess: () => {
            // Go to the next step.
            setSearchParams({
              step: `${FlfWizardStep.CreateStudent + 1}`,
              studentProfileId: selectedStudentProfile.id,
            });
            googleAnalytics.completedFLFStep4();
          },
          onError: (err) => {
            console.error(err);
            const errorMessage = err ? err.message : 'An error occurred while updating student profile.';
            dispatch(createFlashMessage({ variant: 'error', message: errorMessage }));
          },
          onSettled: () => {
            setIsSubmitting(false);
          },
        },
      );
    } else {
      // Else, we're creating a new profile.
      createStudentProfileRequest.mutate(
        {
          studentName,
          pronouns: pronouns.length ? pronouns : undefined,
          grade: grade as StudentGrade,
          additionalInformation: additionalInformation.length ? additionalInformation : undefined,
          isCoppaConsentGranted,
        },
        {
          onSuccess: (response) => {
            // Go to the next step.
            setSearchParams({
              step: `${FlfWizardStep.CreateStudent + 1}`,
              studentProfileId: response.studentProfileId,
            });
          },
          onError: (err) => {
            console.error(err);
            if (isAxiosError<ErrorResponseDto>(err)) {
              dispatch(
                createFlashMessage({
                  variant: 'error',
                  message: err.response?.data.message ?? 'An error occurred while creating student profile.',
                }),
              );
            } else {
              dispatch(
                createFlashMessage({
                  variant: 'error',
                  message: 'An error occurred while creating student profile.',
                }),
              );
            }
          },
          onSettled: () => {
            setIsSubmitting(false);
          },
        },
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Stack>
        <HootTypography isPII={false} variant="titlelarge">
          Create Your Student’s Profile
        </HootTypography>
        <HootTypography isPII={false} variant="bodylarge" sx={{ marginTop: 3 }}>
          Help us tailor an engaging and effective learning experience for your child.
        </HootTypography>
        <Stack mt={3} gap={2}>
          <Stack sx={{ maxWidth: isDesktop ? '330px' : undefined }} gap={2}>
            <Controller
              control={control}
              name="studentName"
              rules={{
                required: 'Student name is required',
                minLength: {
                  value: 1,
                  message: `Student name must not be empty`,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  label="Student Name"
                  helperText={fieldState.error?.message}
                  error={!!fieldState.error}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="pronouns"
              render={({ field, fieldState }) => (
                <TextField
                  label="Pronouns (Optional)"
                  helperText={fieldState.error?.message}
                  error={!!fieldState.error}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="grade"
              rules={{
                required: 'Student grade is required',
              }}
              render={({ field, fieldState }) => (
                <>
                  <Select
                    label="Grade"
                    fullWidth
                    value={field.value}
                    onChange={field.onChange}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  >
                    <option disabled value="">
                      Select
                    </option>
                    {Object.values(StudentGrade).map((option) => (
                      <option value={option} key={`grade-opt-${option}`}>
                        {studentGradeLabelDictionary[option]}
                      </option>
                    ))}
                  </Select>
                </>
              )}
            />
          </Stack>
          <Controller
            control={control}
            name="additionalInformation"
            render={({ field, fieldState }) => (
              <TextField
                sx={{ maxWidth: isDesktop ? '509px' : undefined }}
                label="Additional Information (Optional)"
                helperText={
                  fieldState.error?.message ??
                  "Include any information that may help your child's first lesson go smoothly, such as learning challenges, hobbies, and interests."
                }
                multiline
                minRows={3}
                maxRows={8}
                error={!!fieldState.error}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Stack>
        <Stack sx={{ mt: 3 }}>
          <HootTypography isPII={false} variant="titlelarge">
            COPPA Consent Notice
          </HootTypography>
          <HootTypography isPII={false} variant="bodylarge" mt={2}>
            Hoot is committed to safeguarding your child's online privacy. We comply with the Children's Online Privacy Protection Act (COPPA) and
            require your consent to collect, use, your child's personal information.
          </HootTypography>
          <Button sx={{ marginTop: 3 }} variant={isCoppaConsentGranted ? 'outlined' : 'contained'} onClick={onViewCoppaConsent}>
            View COPPA Consent Notice
          </Button>
          {(hasCoppaConsentBeenShown || isCoppaConsentGranted) && (
            <Box mt={3}>
              <Controller
                control={control}
                name="isCoppaConsentGranted"
                render={({ field }) => (
                  <Checkbox checked={field.value} onChange={field.onChange} label="I, as the Parent or Guardian, grant my COPPA consent." />
                )}
              />
            </Box>
          )}
        </Stack>
        {hasCoppaConsentBeenShown || isCoppaConsentGranted ? (
          <Button sx={{ marginTop: 3 }} variant="contained" disabled={!isCoppaConsentGranted} type="submit" isLoading={isSubmitting}>
            Next
          </Button>
        ) : null}
      </Stack>
      <CoppaConsentDialog
        defaultChecked={isCoppaConsentGranted}
        show={showCoppaConsent}
        onDismiss={onDismissCoppaConsent}
        onApplyCoppaConsent={onApplyCoppaConsent}
      />
    </form>
  );
};

export default FreeLessonCreateStudentProfile;
