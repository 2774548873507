import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { OrderBy } from '../../../models/api/enums/queryEnums';
import { GenericPaginatedResponse } from '../../../models/api/pagination';
import { StudentGrade } from '../../../models/api/student';
import { QueryKey } from '../queryKeys';

export enum MyStudentsOrderColumn {
  Name = 'name',
  StudentNumber = 'student_number',
  Grade = 'grade',
  School = 'school',
}

export interface MyStudentsQuery {
  query?: string;
  orderColumn: MyStudentsOrderColumn;
  orderBy: OrderBy;
  page: number;
  pageSize: number;
}

export interface MyStudentsResponse {
  studentId: string;
  studentName: string;
  studentNumber: number;
  studentGrade: StudentGrade | null;
  schoolNames: string[];
}

export default function useGetMyStudents(
  query: MyStudentsQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<MyStudentsResponse>, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetMyStudents, query],
    async () => {
      return (await axios.get<GenericPaginatedResponse<MyStudentsResponse>>(`${config.apiUrl}/my-students`, { params: query })).data;
    },
    options,
  );
}
