import Skeleton from '@mui/material/Skeleton';
import { StudentProfileV2Response } from '@hoot/hooks/api/user/useGetStudentProfileV2';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface Props {
  student?: StudentProfileV2Response;
  isLoading: boolean;
}

const StudentLibraryCard = (props: Props) => {
  const { student, isLoading } = props;

  const studentFirstName = student?.name.trim().split(/(\s+)/)[0];

  const onOpenLibraryClick = () => {
    if (!!student) {
      window.open(routesDictionary.myStudents.details.library.url(student.id), '_blank');
    }
  };

  if (!student || isLoading) {
    return (
      <Card title="" isLoading={isLoading}>
        <Skeleton variant="text" sx={{ width: '70px', height: '40px', ...hootTokens.text.bodylarge }} />
        <Skeleton variant="text" sx={{ width: '100%', height: '80px', ...hootTokens.text.bodylarge }} />
        <Skeleton variant="rounded" sx={{ width: '200px', height: '50px', ...hootTokens.text.bodylarge }} />
      </Card>
    );
  }

  return (
    <Card title="Student Library">
      <HootTypography isPII={false} variant="bodylarge">
        Every student has a personalized library, crafted to suit their unique needs, all thanks to your guidance! Within this space, you can access
        their favorites, lesson plans, and essential instructional materials.
      </HootTypography>
      <Button
        data-sentry-mask
        variant="contained"
        sx={{ mt: 1, pl: 1 }}
        onClick={onOpenLibraryClick}
        disabled={!student}
        startIcon={<Icon name="new_tab" htmlColor={hootTokens.palette.white} sx={{ marginLeft: '8px' }} />}
      >
        {!!studentFirstName ? `Open ${studentFirstName}'s Library` : `Open student's Library`}
      </Button>
    </Card>
  );
};

export default StudentLibraryCard;
