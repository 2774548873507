import { SvgIcon, SvgIconProps, SxProps, Theme } from '@mui/material';
import { RotateDirection } from '../utils/icon-utils';

const ArrowIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    sx={
      {
        rotate: props.rotate ?? RotateDirection.Right,
        ...props.sx,
      } as SxProps<Theme>
    }
  >
    <path
      d="M5.5 9.5H13.5V7.70377C13.5 6.84461 14.5119 6.38543 15.1585 6.95119L19.7798 10.9948C20.6905 11.7917 20.6905 13.2083 19.7798 14.0052L15.1585 18.0488C14.5119 18.6146 13.5 18.1554 13.5 17.2962V15.5H5.5C4.39543 15.5 3.5 14.6046 3.5 13.5V11.5C3.5 10.3954 4.39543 9.5 5.5 9.5Z"
      stroke={props.htmlColor ?? '#000'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ArrowIcon;
