import { PropsWithChildren, ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EventType } from '@hoot/events/eventType';
import { LessonReviewSubmittedNotificationMessage } from '@hoot/events/messages/lesson-review-submitted-notification.message';
import useCreateLessonReview, {
  CreateLessonReviewRequest,
  FocusBook,
  LettersAndSoundsFormativeAssessment,
  TextReadingFormativeAssessment,
  WordReadingFormativeAssessment,
} from '@hoot/hooks/api/lesson-reviews/useCreateLessonReview';
import { FormativeAssessmentBook } from '@hoot/hooks/api/lesson-reviews/useGetFormativeAssessmentBookDetails';
import { LessonPlanBook } from '@hoot/hooks/api/lesson-reviews/useGetLessonPlan';
import useGetLessonReviewDetails, { GetLessonReviewDetailsResponse } from '@hoot/hooks/api/lesson-reviews/useGetLessonReviewDetails';
import useUpdateLessonReview from '@hoot/hooks/api/lesson-reviews/useUpdateLessonReview';
import useGetTeacherLessonById, { TeacherLessonResponse } from '@hoot/hooks/api/lesson/useGetTeacherLessonById';
import {
  FormativeAssessmentType,
  LessonReviewEngagement,
  LessonReviewFocus,
  LessonReviewFocusSkill,
  LessonReviewFocusType,
  LessonReviewFocusUnit,
  ResourceReader,
  formativeAssessmentLetters,
} from '@hoot/models/api/enums/lesson-review-enums';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { useSocket } from '@hoot/ui/context/SocketContext';
import LessonReviewCompletedStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewCompletedStep';
import LessonReviewFormativeAssessmentStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewFormativeAssessmentStep';
import LessonReviewInstructionalFocusStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewInstructionalFocusStep';
import LessonReviewIntroductionStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewIntroductionStep';
import LessonReviewLessonNotesStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewLessonNotesStep';
import LessonReviewLessonPlanStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewLessonPlanStep';
import LessonReviewResourceProgressStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewResourceProgressStep';
import LessonReviewStudentEngagementStep from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/steps/LessonReviewStudentEngagementStep';
import { BookAttributeDetails } from '../../../../../../../../events/interfaces/book-search';
import { ResourceProgress } from '../../../../../../../../models/api/library';

export enum LessonReviewWizardStepEnum {
  Introduction = 0,
  FormativeAssessment,
  InstructionalFocus,
  ResourceProgress,
  LessonPlan,
  StudentEngagement,
  LessonNotes,
  Completed,
}

export interface LessonReviewWizardStep {
  id: LessonReviewWizardStepEnum;
  render: () => ReactNode;
}

export interface LessonReviewBook {
  bookId: string;
  title: string;
  coverImageUrl: string | undefined;
  progress?: ResourceProgress;
  reader?: ResourceReader;
  focuses?: BookAttributeDetails[] | undefined;
}

export interface PerformanceMetrics {
  wordCount: number;
  errors: number | undefined;
  runningTime: number | undefined;
}

interface FormativeAssessmentState {
  formativeAssessmentBooks: FormativeAssessmentBook[];
  wordsReadCorrectly: Map<string, Set<string>>;
  lettersReadCorrectly: Map<string, Set<string>>;
  performanceMetrics: Map<string, PerformanceMetrics>;
}

export interface LessonReviewInstructionalFocusState {
  primaryFocus: LessonReviewFocus;
  primaryFocusUnit: LessonReviewFocusUnit | undefined;
  primaryFocusSkill: LessonReviewFocusSkill | undefined;
  secondaryFocus: LessonReviewFocus | undefined;
  secondaryFocusUnit: LessonReviewFocusUnit | undefined;
  secondaryFocusSkill: LessonReviewFocusSkill | undefined;
  primaryFocusBooks: LessonReviewBook[];
  secondaryFocusBooks: LessonReviewBook[];
  noResourcesUsed: boolean;
  lessonReviewVersion: number;
}

interface LessonPlanState {
  lessonPlanBooks: LessonPlanBook[];
}

interface LessonReviewWizardContextProps {
  currentStep: LessonReviewWizardStep;
  isLoadingLesson: boolean;
  lessonToReview: TeacherLessonResponse | undefined;
  existingLessonReview: GetLessonReviewDetailsResponse | undefined;
  lessonReviewNotes: string;
  formativeAssessmentState: FormativeAssessmentState | undefined;
  instructionalFocusState: LessonReviewInstructionalFocusState | undefined;
  lessonPlanState: LessonPlanState | undefined;
  studentEngagementState: LessonReviewEngagement | undefined;
  isSubmittingReview: boolean;
  actions: {
    goToNextStep: (stepId?: number) => void;
    goToPreviousStep: () => void;
    attachFormativeAssessmentData: (
      selectedFormativeAssessmentBook: FormativeAssessmentBook[],
      wordsReadCorrectly: Map<string, Set<string>>,
      performanceMetrics: Map<string, PerformanceMetrics>,
      lettersReadCorrectly: Map<string, Set<string>>,
    ) => void;
    attachInstructionalFocusData: (instructionalFocusData: LessonReviewInstructionalFocusState) => void;
    attachResourceProgressData: (books: LessonReviewBook[]) => void;
    attachLessonPlanData: (lessonPlanBooks: LessonPlanBook[]) => void;
    attachStudentEngagementData: (studentEngagement: LessonReviewEngagement) => void;
    attachLessonNotesData: (notes: string) => void;
    submitLessonReview: () => void;
    dismissLessonReviewWizard: () => void;
    reset: () => void;
  };
}

export const lessonReviewWizardSteps: LessonReviewWizardStep[] = [
  {
    id: LessonReviewWizardStepEnum.Introduction,
    render: () => <LessonReviewIntroductionStep />,
  },
  {
    id: LessonReviewWizardStepEnum.FormativeAssessment,
    render: () => <LessonReviewFormativeAssessmentStep />,
  },
  {
    id: LessonReviewWizardStepEnum.InstructionalFocus,
    render: () => <LessonReviewInstructionalFocusStep />,
  },
  {
    id: LessonReviewWizardStepEnum.ResourceProgress,
    render: () => <LessonReviewResourceProgressStep />,
  },
  {
    id: LessonReviewWizardStepEnum.LessonPlan,
    render: () => <LessonReviewLessonPlanStep />,
  },
  {
    id: LessonReviewWizardStepEnum.StudentEngagement,
    render: () => <LessonReviewStudentEngagementStep />,
  },
  {
    id: LessonReviewWizardStepEnum.LessonNotes,
    render: () => <LessonReviewLessonNotesStep />,
  },
  {
    id: LessonReviewWizardStepEnum.Completed,
    render: () => <LessonReviewCompletedStep />,
  },
];

const LessonReviewWizardContext = createContext<LessonReviewWizardContextProps>(undefined!);

interface LessonReviewWizardContextProviderProps extends PropsWithChildren<any> {
  lessonIdToReview: string | undefined;
  onDismissLessonReviewWizard: () => void;
  onCompleted?: () => void;
}

const LessonReviewWizardContextProvider = (props: LessonReviewWizardContextProviderProps) => {
  const { onDismissLessonReviewWizard, lessonIdToReview, children } = props;
  const { socket } = useSocket();
  const dispatch = useAppDispatch();
  const { studentProfileId } = useParams();
  const getLessonRequest = useGetTeacherLessonById(lessonIdToReview!, {
    enabled: !!lessonIdToReview,
  });
  // If there's already a review for this lesson, then pre-populate all expected fields.
  const getLessonReviewRequest = useGetLessonReviewDetails(lessonIdToReview!, {
    enabled: !!lessonIdToReview,
    retry: false,
    onSuccess: (response) => {
      const wordsReadCorrectly = new Map<string, Set<string>>();
      response.formativeAssessment.wordReadingBooks.forEach((b) => {
        wordsReadCorrectly.set(b.bookId, new Set(b.words.filter((w) => w.readCorrectly).map((w) => w.word)));
      });
      const performanceMetrics = new Map<string, PerformanceMetrics>();
      response.formativeAssessment.textReadingBooks.forEach((b) => {
        performanceMetrics.set(b.bookId, { wordCount: b.wordCount, errors: b.errors, runningTime: b.runningTime });
      });
      const lettersReadCorrectly = new Map<string, Set<string>>();
      response.formativeAssessment.lettersAndSoundsBooks.forEach((b) => {
        lettersReadCorrectly.set(b.bookId, new Set(b.letters.filter((l) => l.readCorrectly).map((l) => l.letter)));
      });

      const textReadingFormativeAssessmentBooks: FormativeAssessmentBook[] = response.formativeAssessment.textReadingBooks.map((book) => ({
        bookId: book.bookId,
        title: book.title,
        coverImageUrl: book.imageUrl,
        formativeAssessmentWords: [],
        wordCount: book.wordCount,
        formativeAssessmentType: FormativeAssessmentType.TextReading,
      }));

      const wordReadingFormativeAssessmentBooks: FormativeAssessmentBook[] = response.formativeAssessment.wordReadingBooks.map((book) => ({
        bookId: book.bookId,
        title: book.title,
        coverImageUrl: book.imageUrl,
        formativeAssessmentWords: book.words.map((w) => w.word),
        wordCount: 0,
        formativeAssessmentType: FormativeAssessmentType.WordReading,
      }));

      const letterSoundsFormativeAssessmentBooks: FormativeAssessmentBook[] = response.formativeAssessment.lettersAndSoundsBooks.map((book) => ({
        bookId: book.bookId,
        title: book.title,
        coverImageUrl: book.imageUrl,
        formativeAssessmentWords: [],
        wordCount: 0,
        formativeAssessmentType: FormativeAssessmentType.LettersAndSounds,
      }));

      // Pre-populate Step 1: Formative Assessment
      setFormativeAssessmentState({
        formativeAssessmentBooks: [
          ...textReadingFormativeAssessmentBooks,
          ...wordReadingFormativeAssessmentBooks,
          ...letterSoundsFormativeAssessmentBooks,
        ],
        wordsReadCorrectly,
        lettersReadCorrectly,
        performanceMetrics,
      });
      // Pre-populate Step 2: Instructional Focus
      setInstructionalFocusState({
        primaryFocus: response.instructionalFocus.primaryFocus.focus,
        primaryFocusUnit: response.instructionalFocus.primaryFocus.unit,
        primaryFocusSkill: response.instructionalFocus.primaryFocus.skill,
        primaryFocusBooks: response.instructionalFocus.primaryFocus.books.map((b) => {
          return {
            bookId: b.bookId,
            title: b.title,
            coverImageUrl: b.imageUrl,
            reader: b.reader,
            progress: b.progress,
          };
        }),
        secondaryFocus: response.instructionalFocus.secondaryFocus?.focus,
        secondaryFocusUnit: response.instructionalFocus.secondaryFocus?.unit,
        secondaryFocusSkill: response.instructionalFocus.secondaryFocus?.skill,
        secondaryFocusBooks:
          response.instructionalFocus.secondaryFocus?.books.map((b) => {
            return {
              bookId: b.bookId,
              title: b.title,
              coverImageUrl: b.imageUrl,
              reader: b.reader,
              progress: b.progress,
            };
          }) ?? [],
        noResourcesUsed: ![...response.instructionalFocus.primaryFocus.books, ...(response.instructionalFocus.secondaryFocus?.books ?? [])].length,
        lessonReviewVersion: response.version,
      });
      // -- Step 4 state is handled internally. --
      // Pre-populate Step 5: setStudentEngagementState
      setStudentEngagementState(response.studentEngagement);
      setLessonReviewNotes(response.lessonNotes);
    },
  });
  const createLessonReviewRequest = useCreateLessonReview(lessonIdToReview!);
  const updateLessonReviewRequest = useUpdateLessonReview(lessonIdToReview!);

  const [currentStep, setCurrentStep] = useState(lessonReviewWizardSteps[0]);
  const [formativeAssessmentState, setFormativeAssessmentState] = useState<FormativeAssessmentState | undefined>(undefined);
  const [instructionalFocusState, setInstructionalFocusState] = useState<LessonReviewInstructionalFocusState>();
  const [lessonPlanState, setLessonPlanState] = useState<LessonPlanState>();
  const [studentEngagementState, setStudentEngagementState] = useState<LessonReviewEngagement>();
  const [lessonReviewNotes, setLessonReviewNotes] = useState('');

  const goToStep = (stepId: LessonReviewWizardStepEnum) => {
    setCurrentStep(lessonReviewWizardSteps[stepId]);
  };

  const clearLessonReviewState = useCallback(() => {
    setLessonReviewNotes('');
    setFormativeAssessmentState(undefined);
    setInstructionalFocusState(undefined);
    setLessonPlanState(undefined);
    setStudentEngagementState(undefined);
    goToStep(0);
  }, []);

  return (
    <LessonReviewWizardContext.Provider
      value={{
        currentStep,
        isLoadingLesson: getLessonRequest.isFetching || getLessonReviewRequest.isFetching,
        lessonToReview: getLessonRequest.data,
        existingLessonReview: getLessonReviewRequest.data,
        formativeAssessmentState,
        instructionalFocusState,
        lessonPlanState,
        studentEngagementState,
        lessonReviewNotes,
        isSubmittingReview: createLessonReviewRequest.isLoading || updateLessonReviewRequest.isLoading,
        actions: {
          goToNextStep: (stepId?: number) => {
            if (currentStep.id >= lessonReviewWizardSteps.length) {
              return;
            } else if (stepId) {
              goToStep(stepId);
            } else {
              goToStep(currentStep.id + 1);
            }
          },
          goToPreviousStep: () => {
            if (currentStep.id <= 0) {
              return;
            } else if (currentStep.id === LessonReviewWizardStepEnum.LessonPlan && !!instructionalFocusState?.noResourcesUsed) {
              // If no resources were used in the lesson, we skip over the Resource Progress step and go back to the Instructional Focus step
              goToStep(LessonReviewWizardStepEnum.InstructionalFocus);
            } else {
              goToStep(currentStep.id - 1);
            }
          },
          attachFormativeAssessmentData: (
            formativeAssessmentBooks: FormativeAssessmentBook[],
            wordsReadCorrectly: Map<string, Set<string>>,
            performanceMetrics: Map<string, PerformanceMetrics>,
            lettersReadCorrectly: Map<string, Set<string>>,
          ) => {
            if (!formativeAssessmentBooks.length) {
              setFormativeAssessmentState(undefined);
            } else {
              setFormativeAssessmentState({
                formativeAssessmentBooks: formativeAssessmentBooks,
                wordsReadCorrectly: wordsReadCorrectly,
                performanceMetrics: performanceMetrics,
                lettersReadCorrectly: lettersReadCorrectly,
              });
            }
          },
          attachInstructionalFocusData: (instructionalFocusData) => {
            setInstructionalFocusState(instructionalFocusData);
          },
          attachResourceProgressData: (books: LessonReviewBook[]) => {
            setInstructionalFocusState((currentState) =>
              currentState
                ? {
                    ...currentState,
                    primaryFocusBooks: currentState.primaryFocusBooks.map((pb) => books.find((b) => b.bookId === pb.bookId) ?? pb),
                    secondaryFocusBooks: currentState.secondaryFocusBooks.map((pb) => books.find((b) => b.bookId === pb.bookId) ?? pb),
                  }
                : undefined,
            );
          },
          attachLessonPlanData: (lessonPlanBooks: LessonPlanBook[]) => {
            setLessonPlanState({
              lessonPlanBooks: lessonPlanBooks,
            });
          },
          attachStudentEngagementData: (studentEngagement: LessonReviewEngagement) => {
            setStudentEngagementState(studentEngagement);
          },
          attachLessonNotesData: (notes) => {
            setLessonReviewNotes(notes);
          },
          submitLessonReview: () => {
            const wordReadingFormativeAssessments: WordReadingFormativeAssessment[] = [];
            const textReadingFormativeAssessments: TextReadingFormativeAssessment[] = [];
            const lettersAndSoundsFormativeAssessments: LettersAndSoundsFormativeAssessment[] = [];
            let index = 0;

            if (formativeAssessmentState) {
              for (const book of formativeAssessmentState.formativeAssessmentBooks) {
                if (book.formativeAssessmentType === FormativeAssessmentType.TextReading) {
                  const bookData = formativeAssessmentState.performanceMetrics.get(book.bookId);
                  if (!bookData) {
                    return;
                  } else {
                    const formativeAssessment: TextReadingFormativeAssessment = {
                      bookId: book.bookId,
                      wordCount: bookData.wordCount,
                      errors: bookData.errors ?? 0,
                      runningTime: bookData.runningTime ?? 0,
                    };
                    textReadingFormativeAssessments.push(formativeAssessment);
                  }
                } else if (book.formativeAssessmentType === FormativeAssessmentType.WordReading) {
                  for (const word of book.formativeAssessmentWords) {
                    const wordsReadCorrectly = formativeAssessmentState.wordsReadCorrectly.get(
                      formativeAssessmentState.formativeAssessmentBooks[index].bookId,
                    );
                    const formativeAssessment: WordReadingFormativeAssessment = {
                      bookId: book.bookId,
                      word: word,
                      readCorrectly: wordsReadCorrectly?.has(word) ?? false,
                    };
                    wordReadingFormativeAssessments.push(formativeAssessment);
                  }
                } else if (book.formativeAssessmentType === FormativeAssessmentType.LettersAndSounds) {
                  for (const letter of formativeAssessmentLetters) {
                    const lettersReadCorrectly = formativeAssessmentState.lettersReadCorrectly.get(
                      formativeAssessmentState.formativeAssessmentBooks[index].bookId,
                    );
                    const formativeAssessment: LettersAndSoundsFormativeAssessment = {
                      bookId: book.bookId,
                      letter: letter,
                      readCorrectly: lettersReadCorrectly?.has(letter) ?? false,
                    };
                    lettersAndSoundsFormativeAssessments.push(formativeAssessment);
                  }
                }
                index++;
              }
            }

            const mapFocusBooks = (books: LessonReviewBook[], focusType: LessonReviewFocusType) => {
              return books.map<FocusBook>((b) => ({
                id: b.bookId,
                focusType,
                reader: b.reader,
                progress: b.progress,
              }));
            };
            const focusBooks = [
              ...mapFocusBooks(instructionalFocusState?.primaryFocusBooks ?? [], LessonReviewFocusType.Primary),
              ...mapFocusBooks(instructionalFocusState?.secondaryFocusBooks ?? [], LessonReviewFocusType.Secondary),
            ];

            const lessonPlanBookIds = lessonPlanState?.lessonPlanBooks.map((book) => book.id) ?? [];

            const lessonReviewData: CreateLessonReviewRequest = {
              studentProfileId: studentProfileId!,
              primaryFocus: instructionalFocusState?.primaryFocus!,
              primaryFocusUnit: instructionalFocusState?.primaryFocusUnit,
              primaryFocusSkill: instructionalFocusState?.primaryFocusSkill,
              secondaryFocus: instructionalFocusState?.secondaryFocus,
              secondaryFocusUnit: instructionalFocusState?.secondaryFocusUnit,
              secondaryFocusSkill: instructionalFocusState?.secondaryFocusSkill,
              focusBooks: focusBooks,
              lessonPlanBookIds: lessonPlanBookIds,
              studentEngagement: studentEngagementState!,
              notes: lessonReviewNotes,
              wordReadingFormativeAssessments: wordReadingFormativeAssessments,
              textReadingFormativeAssessments: textReadingFormativeAssessments,
              lettersAndSoundsFormativeAssessments: lettersAndSoundsFormativeAssessments,
            };
            // If we already have an existing lesson review, then we'll be updating the review.
            // Else this is a brand-new review.
            (getLessonReviewRequest.data ? updateLessonReviewRequest : createLessonReviewRequest).mutate(lessonReviewData, {
              onSuccess: () => {
                goToStep(currentStep.id + 1);
                if (props.onCompleted) {
                  props.onCompleted();
                }
                const lessonReviewSubmittedMessage: LessonReviewSubmittedNotificationMessage = {
                  reviewLessonId: lessonIdToReview!,
                };
                socket.emit(EventType.NotifyLessonReviewSubmitted, lessonReviewSubmittedMessage);
              },
              onError: (err) => {
                console.error(err.response?.data.message);
                dispatch(createFlashMessage({ variant: 'error', message: err.response?.data.message ?? 'Whoops! It looks like an error occurred.' }));
              },
            });
          },
          dismissLessonReviewWizard: () => {
            onDismissLessonReviewWizard();
          },
          reset: useCallback(() => {
            clearLessonReviewState();
          }, [clearLessonReviewState]),
        },
      }}
    >
      {children}
    </LessonReviewWizardContext.Provider>
  );
};

export const useLessonReviewWizardContext = () => {
  const context = useContext(LessonReviewWizardContext);

  if (context === undefined) {
    throw new Error('useLessonReviewWizardContext must be used within a LessonReviewWizardContextProvider');
  }
  return context;
};

export default LessonReviewWizardContextProvider;
