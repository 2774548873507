import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface ThemesResponse {
  categorizedThemes: CategorizedThemes[];
}

interface CategorizedThemes {
  parentCategory: string;
  listItems: {
    label: string;
    value: string;
  }[];
}

async function getThemes(): Promise<ThemesResponse> {
  const { data } = await axios.get<ThemesResponse>(`${config.apiUrl}/library/themes`);
  return data;
}

export default function useGetThemes(options?: Omit<UseQueryOptions<ThemesResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetThemes], () => getThemes(), options);
}
