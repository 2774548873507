import { SvgIcon, SvgIconProps } from '@mui/material';

const BookmarkAddIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.2 19.6L7.65 20.2H7.65L7.2 19.6ZM10.8 16.9L10.35 16.3H10.35L10.8 16.9ZM13.2 16.9L13.65 16.3L13.2 16.9ZM16.8 19.6L16.35 20.2L16.8 19.6ZM4 6.25C3.58579 6.25 3.25 6.58579 3.25 7C3.25 7.41421 3.58579 7.75 4 7.75V6.25ZM20 7.75C20.4142 7.75 20.75 7.41421 20.75 7C20.75 6.58579 20.4142 6.25 20 6.25V7.75ZM3.25 4V18H4.75V4H3.25ZM7.65 20.2L11.25 17.5L10.35 16.3L6.75 19L7.65 20.2ZM12.75 17.5L16.35 20.2L17.25 19L13.65 16.3L12.75 17.5ZM20.75 18V4H19.25V18H20.75ZM18 1.25H6V2.75H18V1.25ZM20.75 4C20.75 2.48122 19.5188 1.25 18 1.25V2.75C18.6904 2.75 19.25 3.30964 19.25 4H20.75ZM16.35 20.2C18.1629 21.5597 20.75 20.2661 20.75 18H19.25C19.25 19.0301 18.074 19.618 17.25 19L16.35 20.2ZM11.25 17.5C11.6944 17.1667 12.3056 17.1667 12.75 17.5L13.65 16.3C12.6722 15.5667 11.3278 15.5667 10.35 16.3L11.25 17.5ZM3.25 18C3.25 20.2661 5.8371 21.5597 7.65 20.2L6.75 19C5.92595 19.618 4.75 19.0301 4.75 18H3.25ZM4.75 4C4.75 3.30964 5.30964 2.75 6 2.75V1.25C4.48122 1.25 3.25 2.48122 3.25 4H4.75ZM4 7.75H20V6.25H4V7.75Z"
        fill={props.htmlColor ?? 'currentColor'}
      />
    </SvgIcon>
  );
};

export default BookmarkAddIcon;
