import { Box, Drawer, Stack } from '@mui/material';
import React from 'react';
import { Icon } from './Icon';
import IconButton from './IconButton';
import NavMenuList, { NavList, ProfileHeaderProps } from './NavMenuList';

//Note: logout and navigation functions cannot be defined within this component as that will cause the story version to break
interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navMenuList: NavList[];
  profileHeaderProps: ProfileHeaderProps;
}

const NavMenuDrawer = (props: Props) => {
  const { navMenuList, isOpen, setIsOpen, profileHeaderProps } = props;

  return (
    <>
      <Drawer anchor="left" open={isOpen} onClose={() => setIsOpen(false)}>
        <Box
          sx={(theme) => ({
            width: 360,
            overflowX: 'hidden',
            padding: theme.spacing(3, 3, 2, 3),
          })}
        >
          {/* Header */}
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <img src="/images/logo-v2.svg" alt="logo" height="40px" width="120px" />
            <IconButton variant="standard" onClick={() => setIsOpen(false)}>
              <Icon name="close" />
            </IconButton>
          </Stack>
          <NavMenuList navList={navMenuList} profileHeaderProps={profileHeaderProps} onClose={() => setIsOpen(false)} />
        </Box>
      </Drawer>
    </>
  );
};

export default NavMenuDrawer;
