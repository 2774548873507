import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface GetSsoLaunchInfoResponse {
  redirectUrl: string;
  state: string;
}

async function getEdlinkSsoLaunchInfo(integrationId: string | null) {
  const url = new URL(`${config.apiUrl}/edlink/auth/sso-launch-info`);
  if (!!integrationId) {
    url.searchParams.append('integrationId', integrationId);
  }
  const { data } = await axios.get<GetSsoLaunchInfoResponse>(url.toString());
  return data;
}

export function useGetEdlinkSsoLaunchInfo(
  integrationId: string | null,
  options?: Omit<UseQueryOptions<GetSsoLaunchInfoResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetEdlinkSsoRedirectInfo], () => getEdlinkSsoLaunchInfo(integrationId), options);
}
