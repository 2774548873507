import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface FlfResendVerificationCodeEmailRequest {
  email: string;
}

export default function useFlfResendVerificationCodeRequest() {
  return useMutation<void, AxiosError, FlfResendVerificationCodeEmailRequest>(async (request) => {
    return (await axios.post(`${config.apiUrl}/free-lesson/v2/resend-verification-code`, request)).data;
  });
}
