import { ListItem, ListItemButton, Stack } from '@mui/material';
import { useMemo } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import Badge from './Badge';
import HootTypography from './HootTypography';
import { Icon } from './Icon';
import { NavItems, NavList, SubListPage, navListApplyStyles } from './NavMenuList';

interface NaveMenuItemProps {
  navItem: NavList;
  onClose: () => void;
  setSubListLoaded: React.Dispatch<React.SetStateAction<SubListPage | null>>;
}

const NavMenuItem = (props: NaveMenuItemProps) => {
  const { navItem, onClose, setSubListLoaded } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isTabActive = useMemo(() => {
    return navItem.subList ? navItem.subList.some((subItem) => !!matchPath(subItem.url, pathname)) : !!matchPath(navItem.url, pathname);
  }, [navItem, pathname]);

  const handleOnclick = (item: NavList | NavItems) => {
    if (item.subList) {
      // sub list
      const subListPage: SubListPage = {
        header: item.label,
        list: item.subList,
      };
      setSubListLoaded(subListPage);
    } else if (item.external) {
      window.open(item.url, '_blank');
      onClose();
    } else if (item.onClick) {
      item.onClick();
      onClose();
    } else if (item.url) {
      // navigation link
      navigate(item.url);
      onClose();
    }
  };

  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={navItem?.disabled ? undefined : () => handleOnclick(navItem)}
        sx={navListApplyStyles({ isActive: isTabActive, isDisabled: navItem.disabled })}
      >
        <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center">
            {navItem.icon ?? null}
            <HootTypography
              isPII={false}
              variant="labellarge"
              sx={{ ml: navItem.icon ? '12px' : undefined, color: navItem?.disabled ? hootTokens.palette.primary[100] : undefined }}
            >
              {navItem.label}
            </HootTypography>
          </Stack>

          {navItem.badgeCount ? (
            <Stack>
              <Badge badgeContent={navItem.badgeCount} />
            </Stack>
          ) : null}

          {navItem.subList ? (
            <Stack>
              <Icon name="chevron" sx={{ rotate: '180deg' }} />
            </Stack>
          ) : null}
        </Stack>
      </ListItemButton>
    </ListItem>
  );
};

export default NavMenuItem;
