import { Box, IconButtonProps, Snackbar as MuiSnackbar, SnackbarProps as MuiSnackbarProps, Stack } from '@mui/material';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { Button } from './Button';
import HootTypography from './HootTypography';

export interface SnackbarProps extends MuiSnackbarProps {
  variant?: SnackbarVariant;
  title?: string;
  message: string;
  actionLabel?: string;
  onAction?: () => void;
  isIndefinite?: boolean;
}

export type SnackbarVariant = 'light' | 'dark' | 'error';

const AUTO_HIDE_DURATION = 6000;

export function Snackbar(props: SnackbarProps) {
  const { variant, title, message, actionLabel, onAction, isIndefinite = false, autoHideDuration = AUTO_HIDE_DURATION, ...rest } = props;

  const handleActionClick = () => {
    if (onAction) {
      onAction();
    }
  };

  const handleDismissClick: IconButtonProps['onClick'] = (event) => {
    props.onClose?.(event, 'escapeKeyDown');
  };

  const getBackgroundColor = () => {
    switch (variant) {
      case 'dark':
        return hootTokens.palette.black;
      case 'error':
        return hootTokens.palette.error[80];
      default:
        return hootTokens.palette.white;
    }
  };

  const getTextColor = () => {
    switch (variant) {
      case 'dark':
        return hootTokens.palette.white;
      case 'error':
        return hootTokens.palette.white;
      default:
        return hootTokens.palette.black;
    }
  };

  const getButtonColor = () => {
    switch (variant) {
      case 'error':
        return hootTokens.palette.black;
      default:
        return hootTokens.palette.secondary[60];
    }
  };

  const getIconColor = () => {
    switch (variant) {
      case 'dark':
        return hootTokens.palette.white;
      case 'error':
        return hootTokens.palette.white;
      default:
        return hootTokens.palette.black;
    }
  };

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={isIndefinite ? undefined : autoHideDuration}
      {...rest}
      disableWindowBlurListener
    >
      <Stack
        sx={{
          ...hootTokens.elevation.elevation4,
          backgroundColor: getBackgroundColor(),
          color: getTextColor(),
          borderRadius: '8px',
          padding: '16px',
          width: '560px',
        }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center">
          <Box sx={{ marginRight: '16px', lineHeight: 0 }}>
            {variant === 'error' ? (
              <Icon name="error" htmlColor={getIconColor()} />
            ) : (
              <img height={24} width={24} src="/images/hoot-wheel-logo.svg" alt="hoot-wheel" />
            )}
          </Box>
          <Stack>
            {title ? (
              <HootTypography isPII={true} variant="bodylarge">
                {title}
              </HootTypography>
            ) : null}
            <HootTypography isPII={true} variant="bodylarge">
              {message}
            </HootTypography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap={2}>
          {actionLabel ? (
            <Button sx={{ color: getTextColor(), backgroundColor: getButtonColor() }} variant="contained" onClick={handleActionClick}>
              {actionLabel ?? 'Button'}
            </Button>
          ) : null}
          <IconButton
            sx={{
              padding: 0,
              height: '44px',
              width: '44px',
            }}
            onClick={handleDismissClick}
          >
            <Icon name="close" htmlColor={getIconColor()} />
          </IconButton>
        </Stack>
      </Stack>
    </MuiSnackbar>
  );
}
