import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { HootAssessmentModule } from '@hoot/models/api/enums/hoot-reading-assessment';
import { QueryKey } from '../queryKeys';

export enum AssessmentReportStatus {
  Bypassed = 'BYPASSED',
  Mastered = 'MASTERED',
  NeedsInstruction = 'NEEDS_INSTRUCTION',
  NeedsReview = 'NEEDS_REVIEW',
  NotStarted = 'NOT_STARTED',
}

export enum InstructionalFocusStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Passed = 'PASSED',
  Mastered = 'MASTERED',
}

export interface InstructionalFocusSubmission {
  date: string;
  score: number;
  status: AssessmentReportStatus;
}

export interface InstructionalFocusUnit {
  order: number;
  label: string;
  maxScore?: number;
  status: AssessmentReportStatus;
  submissions: InstructionalFocusSubmission[];
  instructionalPathUrl?: string;
}

export interface InstructionalFocusModule {
  order: number;
  module: HootAssessmentModule;
  status: InstructionalFocusStatus;
  label: string;
  units: InstructionalFocusUnit[];
}

export function useGetInstructionalFocusReport(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<InstructionalFocusModule[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetStudentInstructionalFocusData, studentProfileId],
    async () => {
      return (await axios.get<InstructionalFocusModule[]>(`${config.apiUrl}/public/hoot-assessment/instructional-units/student/${studentProfileId}`))
        .data;
    },
    options,
  );
}
