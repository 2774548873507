import { SvgIcon, SvgIconProps } from '@mui/material';

const EnterFullScreenFilledIcon = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0H4C1.79086 0 0 1.79086 0 4V16C0 18.2091 1.79086 20 4 20H16C18.2091 20 20 18.2091 20 16V4C20 1.79086 18.2091 0 16 0ZM10.25 6C10.25 6.41421 10.5858 6.75 11 6.75H12.1893L6.75 12.1893V11C6.75 10.5858 6.41421 10.25 6 10.25C5.58579 10.25 5.25 10.5858 5.25 11V14C5.25 14.4142 5.58579 14.75 6 14.75H9C9.41421 14.75 9.75 14.4142 9.75 14C9.75 13.5858 9.41421 13.25 9 13.25H7.81066L13.25 7.81066V9C13.25 9.41421 13.5858 9.75 14 9.75C14.4142 9.75 14.75 9.41421 14.75 9V6C14.75 5.58579 14.4142 5.25 14 5.25H11C10.5858 5.25 10.25 5.58579 10.25 6Z"
      fill={props.htmlColor ?? 'currentColor'}
    />
  </SvgIcon>
);

export default EnterFullScreenFilledIcon;
