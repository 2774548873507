import { useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Page from '@hoot/ui/components/v2/core/Page';
import CloseFilledSquareIcon from '@hoot/ui/components/v2/icons/CloseFilledSquareIcon';
import { useStudentDetailsContext } from '@hoot/ui/pages/v2/teacher/my-students/student-details/StudentDetailsContextProvider';
import HRAForm from './HRAForm';
import HootAssessmentContextProvider, { useHootAssessmentContext } from './HootAssessmentContext';
import { LeaveAssessmentDialog } from './dialogs/LeaveAssessmentDialog';

const HootReadingAssessment = () => {
  const { confirmLeave } = useHootAssessmentContext();
  const {
    studentProfileSnippet: { name, number },
  } = useStudentDetailsContext();

  const theme = useTheme();

  const [leaveDialog, setLeaveDialog] = useState<boolean>(false);

  return (
    <Page pageTitle="Reading Assessment | Hoot Reading">
      <Stack direction="row" justifyContent="space-between" sx={{ mb: theme.spacing(3) }}>
        <div>
          <HootTypography isPII={false} variant="titlelarge">
            Hoot Reading Assessment
          </HootTypography>
          <HootTypography isPII={true} variant="bodysmall">
            {name} ({number})
          </HootTypography>
        </div>
        <Button onClick={() => setLeaveDialog(true)} variant="text" color="error.80" startIcon={<CloseFilledSquareIcon />}>
          Leave
        </Button>
      </Stack>

      <HRAForm />

      <LeaveAssessmentDialog open={leaveDialog} setOpen={setLeaveDialog} confirmLeave={confirmLeave} />
    </Page>
  );
};

const HootReadingAssessmentCtxWrapper = () => {
  return (
    <HootAssessmentContextProvider>
      <HootReadingAssessment />
    </HootAssessmentContextProvider>
  );
};
export default HootReadingAssessmentCtxWrapper;
