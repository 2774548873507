import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';

export interface DistrictRepresentativeSchoolsQuery {
  name?: string;
  page: number;
  pageSize: number;
  sortBy: string;
  orderBy: OrderBy;
}

export interface DistrictRepresentativeSchool {
  id: string;
  friendlyId: string;
  name: string;
}

export interface DistrictRepresentativeSchoolsResponse {
  schools: DistrictRepresentativeSchool[];
  count: number;
  page: number;
  pageSize: number;
}

async function searchDistrictRepresentativeSchools(
  districtRepresentativeId: string,
  searchParams: DistrictRepresentativeSchoolsQuery,
): Promise<DistrictRepresentativeSchoolsResponse> {
  const { data } = await axios.get<DistrictRepresentativeSchoolsResponse>(
    `${config.apiUrl}/public/district-representative/${districtRepresentativeId}/schools`,
    { params: searchParams },
  );

  return data;
}

export default function useSearchDistrictRepresentativeSchools(
  districtRepresentativeId: string,
  searchParams: DistrictRepresentativeSchoolsQuery,
  options?: Omit<UseQueryOptions<DistrictRepresentativeSchoolsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.SearchDistrictRepresentativeSchools, districtRepresentativeId, searchParams],
    () => searchDistrictRepresentativeSchools(districtRepresentativeId, searchParams),
    options,
  );
}
