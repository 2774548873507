import { Stack } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { StudentType } from '@hoot/models/api/student';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieToggleButton from '@hoot/ui/components/v2/lottie/LottieToggleButton';
import StudentDecodableAccessLibraryFilterDialog, {
  StudentDecodableAccessLibraryFilterModalProps,
  StudentDecodableLibraryFilters,
} from '@hoot/ui/pages/v2/student/library/StudentDecodableAccessLibraryFilterDialog';
import { StudentSubLibrarySelection, sublibraryLabels, sublibraryLottie } from '@hoot/ui/pages/v2/student/library/StudentLibraryPage';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import StudentLibraryFilterModal, { StudentLibraryFilterModalProps, StudentLibraryFilters } from './StudentLibraryFilterModal';

export interface StudentLibraryLeftPaneProps {
  subLibrarySelection: StudentSubLibrarySelection;
  bookTitleSearch: string | undefined;
  handleSubLibraryClick: (subLibrary: StudentSubLibrarySelection) => void;
  onSearchButtonClicked: () => void;
  onApplyFilters: StudentLibraryFilterModalProps['onApplyFilters'];
  onApplyDecodableAccessFilters: StudentDecodableAccessLibraryFilterModalProps['onApplyFilters'];
  appliedCustomFilters: Partial<StudentLibraryFilters & StudentDecodableLibraryFilters> | undefined;
  studentType: StudentType;
}

const StudentLibraryLeftPane = (props: StudentLibraryLeftPaneProps) => {
  const {
    subLibrarySelection,
    bookTitleSearch,
    handleSubLibraryClick,
    onSearchButtonClicked,
    onApplyFilters,
    onApplyDecodableAccessFilters,
    appliedCustomFilters,
    studentType,
  } = props;

  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);

  const _handleSubLibraryClick = (subLibrary: StudentSubLibrarySelection) => {
    if (subLibrary !== subLibrarySelection) {
      handleSubLibraryClick(subLibrary);
    }
  };

  return (
    <Stack
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
        width: '304px',
        background: hootTokens.palette.white,
        boxShadow: '1px 0px 3px 0px rgba(0, 0, 0, 0.30), 4px 0px 8px 3px rgba(0, 0, 0, 0.15);',
        padding: 5,
        alignItems: 'center',
        gap: 2,
        justifyContent: studentType === StudentType.HootDecodableAccess ? 'center' : undefined,
      }}
    >
      <Link to={routesDictionary.home.url}>
        <img src="/images/logo-v2.svg" alt="Hoot logo" width="96px" height="36px" />
      </Link>
      <Stack direction={studentType === StudentType.HootTutoring ? 'row' : 'column'} gap={2} width="100%">
        <LottieToggleButton
          fullWidth={studentType !== StudentType.HootTutoring}
          tooltipLabel={sublibraryLabels['all-books']}
          isSelected={subLibrarySelection === StudentSubLibrarySelection.AllBooks}
          lottieFile={sublibraryLottie['all-books']}
          aria-label={sublibraryLabels['all-books']}
          onClick={() => _handleSubLibraryClick(StudentSubLibrarySelection.AllBooks)}
          sx={{
            width: studentType === StudentType.HootTutoring ? 104 : '100%',
            height: studentType === StudentType.HootTutoring ? 104 : 'auto',
          }}
        />
        <LottieToggleButton
          fullWidth={studentType !== StudentType.HootTutoring}
          tooltipLabel={sublibraryLabels['favs']}
          isSelected={subLibrarySelection === StudentSubLibrarySelection.Favourites}
          lottieFile={sublibraryLottie['favs']}
          aria-label={sublibraryLabels['favs']}
          onClick={() => _handleSubLibraryClick(StudentSubLibrarySelection.Favourites)}
          sx={{
            width: studentType === StudentType.HootTutoring ? 104 : '100%',
            height: studentType === StudentType.HootTutoring ? 104 : 'auto',
          }}
        />
      </Stack>
      {studentType === StudentType.HootTutoring && (
        <>
          <LottieToggleButton
            fullWidth
            tooltipLabel={sublibraryLabels['animals']}
            isSelected={subLibrarySelection === StudentSubLibrarySelection.Animals}
            lottieFile={sublibraryLottie['animals']}
            aria-label={sublibraryLabels['animals']}
            onClick={() => _handleSubLibraryClick(StudentSubLibrarySelection.Animals)}
          />
          <LottieToggleButton
            fullWidth
            tooltipLabel={sublibraryLabels['fables-and-fairy-tales']}
            isSelected={subLibrarySelection === StudentSubLibrarySelection.FablesAndFairyTales}
            lottieFile={sublibraryLottie['fables-and-fairy-tales']}
            aria-label={sublibraryLabels['fables-and-fairy-tales']}
            onClick={() => _handleSubLibraryClick(StudentSubLibrarySelection.FablesAndFairyTales)}
          />
          <LottieToggleButton
            fullWidth
            tooltipLabel={sublibraryLabels['sports-and-games']}
            isSelected={subLibrarySelection === StudentSubLibrarySelection.SportsAndGames}
            lottieFile={sublibraryLottie['sports-and-games']}
            aria-label={sublibraryLabels['sports-and-games']}
            onClick={() => _handleSubLibraryClick(StudentSubLibrarySelection.SportsAndGames)}
          />
        </>
      )}
      <Stack direction="row" gap={2}>
        <LottieToggleButton
          tooltipLabel="Search"
          lottieFile={LottieFile.Search}
          aria-label="Search"
          onClick={onSearchButtonClicked}
          isSelected={!!bookTitleSearch}
          sx={{ width: 104, height: 104 }}
        />
        <LottieToggleButton
          tooltipLabel="Filters"
          lottieFile={LottieFile.Funnel}
          aria-label="Filters"
          onClick={() => setShowFilterModal(true)}
          isSelected={Object.keys(appliedCustomFilters ?? {}).length > 0}
          sx={{ width: 104, height: 104 }}
        />
      </Stack>
      <StudentLibraryFilterModal
        isOpen={showFilterModal && studentType !== StudentType.HootDecodableAccess}
        defaultSublibrarySelection={subLibrarySelection}
        onDismiss={() => setShowFilterModal(false)}
        onApplyFilters={onApplyFilters}
        defaultFilters={appliedCustomFilters}
      />
      <StudentDecodableAccessLibraryFilterDialog
        isOpen={showFilterModal && studentType === StudentType.HootDecodableAccess}
        studentType={studentType}
        defaultSublibrarySelection={subLibrarySelection}
        defaultFilters={appliedCustomFilters}
        onApplyFilters={onApplyDecodableAccessFilters}
        onDismiss={() => setShowFilterModal(false)}
      />
    </Stack>
  );
};

export default StudentLibraryLeftPane;
