import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export enum DistrictRepAccessLevel {
  None = 'NONE',
  Everyone = 'EVERYONE',
  SchoolOrDistrict = 'SCHOOL_OR_DISTRICT',
  BillableEntity = 'BILLABLE_ENTITY',
}

export interface GetDistrictRepPermission {
  accessLevel: DistrictRepAccessLevel;
}

async function getDistrictRepPermissions(userId: string): Promise<DistrictRepAccessLevel> {
  const { data } = await axios.get<GetDistrictRepPermission>(`${config.apiUrl}/public/district-representative/${userId}/permissions`);
  return data.accessLevel;
}

export default function useGetDistrictRepPermissions(
  userId: string,
  options?: Omit<UseQueryOptions<DistrictRepAccessLevel>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetDistrictRepPermissions, userId], () => getDistrictRepPermissions(userId), options);
}
