export enum OrderColumn {
  StartsAt = 'STARTS_AT',
  StudentName = 'STUDENT_NAME',
  ParentName = 'PARENT_NAME',
  TeacherName = 'TEACHER_NAME',
}

export enum OrderBy {
  Desc = 'DESC',
  Asc = 'ASC',
}
