import axios from 'axios';
import { useQuery } from 'react-query';
import { MonthlyLessonDatesStats } from '@hoot/models/api/lessons';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface DistrictRepMonthlyEnrolmentsLessonsQuery {
  startDateInMonth: number;
  endDateInMonth: number;
  studentProfileId: string;
}

export interface DistrictRepMonthlyEnrolmentsLessonsQueryResponse {
  lessonDates: MonthlyLessonDatesStats[];
}

export async function fetchDistrictRepMonthlyEnrolmentsLessonsList(districtRepId: string, query: DistrictRepMonthlyEnrolmentsLessonsQuery) {
  const { data } = await axios.post<DistrictRepMonthlyEnrolmentsLessonsQueryResponse>(
    `${config.apiUrl}/public/district-representative/${districtRepId}/lessons/search`,
    query,
  );
  return data;
}

export default function useDistrictRepMonthlyScheduledLessonsQuery(
  districtRepId: string,
  districtRepMonthlyEnrolmentsLessonsQuery: DistrictRepMonthlyEnrolmentsLessonsQuery | undefined,
) {
  return useQuery(
    [QueryKey.DistrictRepMonthlyEnrolmentsLessons, districtRepMonthlyEnrolmentsLessonsQuery],
    () => fetchDistrictRepMonthlyEnrolmentsLessonsList(districtRepId, districtRepMonthlyEnrolmentsLessonsQuery!),
    { enabled: !!districtRepId && !!districtRepMonthlyEnrolmentsLessonsQuery },
  );
}
