import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { HootAssessmentStatus } from '@hoot/models/api/enums/hoot-reading-assessment';
import { SortableQuery } from '@hoot/models/api/order-by';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/models/api/pagination';
import { QueryKey } from '../queryKeys';

export interface ProgressMonitoringAssessmentResponse {
  id: string;
  status: HootAssessmentStatus;
  createdAt: number;
  completedAt: number | undefined;
  startedBy: string;
  units: string[];
}

export enum StudentProgressMonitoringAssessmentsQuerySortKeyEnum {
  StartDate = 'StartDate',
  Status = 'Status',
  CompletionDate = 'CompletionDate',
}

export type GetStudentProgressMonitoringAssessmentsQuery = PaginatableQuery & SortableQuery<StudentProgressMonitoringAssessmentsQuerySortKeyEnum>;

export function useGetProgressMonitoringAssessments(
  studentProfileId: string,
  query: GetStudentProgressMonitoringAssessmentsQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<ProgressMonitoringAssessmentResponse>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetStudentProgressMonitoringAssessments, studentProfileId, query],
    async () => {
      return (
        await axios.get<GenericPaginatedResponse<ProgressMonitoringAssessmentResponse>>(
          `${config.apiUrl}/public/progress-monitoring-assessment/student/${studentProfileId}`,
          {
            params: query,
          },
        )
      ).data;
    },
    options,
  );
}
