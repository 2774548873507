import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { Assessment } from '@hoot/hooks/api/assessment/useGetAssessmentById';
import { QueryKey } from '../queryKeys';

export function useCreateAssessment(studentProfileId: string, options?: Omit<UseQueryOptions<Assessment>, 'queryKey' | 'queryFn'>) {
  return useQuery(
    [QueryKey.CreateAssessment],
    async () => {
      return (await axios.post<Assessment>(`${config.apiUrl}/public/hoot-assessment/student/${studentProfileId}`)).data;
    },
    options,
  );
}
