import {
  ListItemIcon,
  ListItemText,
  MenuItemProps,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  MenuProps as MuiMenuProps,
  SvgIcon,
  SvgIconProps,
} from '@mui/material';
import { ReactElement } from 'react';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export interface MenuProps extends Omit<MuiMenuProps, 'variant' | 'children'> {
  items: MenuItem[];
  variant?: 'light' | 'dark';
}

export interface MenuItem {
  id: string;
  label: string;
  icon?: ReactElement<SvgIconProps, typeof SvgIcon>;
  menuItemProps?: MenuItemProps;
  onClick?: () => void;
  'data-hoot-canary-id'?: string;
}

const PopoverMenu = (props: MenuProps) => {
  const { variant = 'light', items, ...menuProps } = props;

  return (
    <>
      <MuiMenu
        sx={{
          ...(variant === 'dark' && {
            '.MuiList-root': {
              backgroundColor: hootTokens.palette.black,
            },
          }),
        }}
        {...menuProps}
      >
        {items.map((menuItem) => {
          const { id, icon, label, menuItemProps, ...rest } = menuItem;
          return (
            <MuiMenuItem
              key={id}
              onClick={menuItem.onClick}
              sx={{
                ...(variant === 'dark' && {
                  padding: '12px',
                  ':hover': {
                    backgroundColor: hootTokens.palette.primary['40'],
                  },
                }),
              }}
              {...menuItemProps}
              {...rest}
            >
              {icon && (
                <ListItemIcon
                  sx={{
                    ...(variant === 'dark' && {
                      '& > svg': {
                        color: hootTokens.palette.white,
                      },
                    }),
                  }}
                >
                  {icon}
                </ListItemIcon>
              )}
              <ListItemText
                sx={{
                  ...(variant === 'dark' && {
                    color: hootTokens.palette.white,
                  }),
                }}
              >
                {label}
              </ListItemText>
            </MuiMenuItem>
          );
        })}
      </MuiMenu>
    </>
  );
};

export default PopoverMenu;
