import { Stack } from '@mui/system';
import { SectionField } from '@hoot/hooks/api/assessment/useGetAssessmentUnit';
import { SegmentAnswer } from '@hoot/models/api/enums/hoot-reading-assessment';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Tag, { TagColor } from '@hoot/ui/components/v2/core/Tag';
import CheckmarkSquare from '@hoot/ui/components/v2/icons/CheckmarkSquare';
import CloseFilledSquareIcon from '@hoot/ui/components/v2/icons/CloseFilledSquareIcon';
import { useHootAssessmentContext } from '@hoot/ui/pages/v2/teacher/hoot-reading-assessment/HootAssessmentContext';

interface FocusedSegmentFieldCardProps {
  sectionField: SectionField;
}

const FocusedSegmentFieldCard = (props: FocusedSegmentFieldCardProps) => {
  const { sectionField } = props;

  const { form, transitionFocusToNextField } = useHootAssessmentContext();

  const handleAnswerSelect = (selection: SegmentAnswer) => {
    form.setValue(sectionField.name, selection, { shouldDirty: true });
    transitionFocusToNextField();
  };

  return (
    <Card contentSx={{ padding: 4 }}>
      <Stack alignItems="center" gap={4}>
        {sectionField.label && (
          <HootTypography isPII={false} variant="labelsmall">
            Prompt
          </HootTypography>
        )}

        <HootTypography isPII={false} variant="displaysmall" textAlign="center">
          {sectionField.label}
        </HootTypography>

        {sectionField.expectedResponse && (
          <Stack alignItems="center" gap={1}>
            <HootTypography isPII={false} variant="labelsmall">
              Expected Response
            </HootTypography>
            <Tag label={sectionField.expectedResponse} color={TagColor.Success} />
          </Stack>
        )}

        <Stack flexDirection="row" gap={2}>
          <Button
            onClick={() => handleAnswerSelect(SegmentAnswer.Wrong)}
            variant="contained"
            color="error.190"
            disableElevation
            startIcon={<CloseFilledSquareIcon />}
          >
            Incorrect
          </Button>
          <Button
            onClick={() => handleAnswerSelect(SegmentAnswer.Correct)}
            variant="contained"
            color="success.190"
            disableElevation
            startIcon={<CheckmarkSquare />}
          >
            Correct
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default FocusedSegmentFieldCard;
