import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface FlfStudentLessonResponseDto {
  hasLessons: boolean;
  lessonAt?: number;
}

export default function useGetFlfStudentLessons(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<FlfStudentLessonResponseDto>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetFlfStudentHasNoLesson],
    async () => {
      return (await axios.get(`${config.apiUrl}/free-lesson/v2/student-lessons?studentProfileId=${studentProfileId}`)).data;
    },
    options,
  );
}
