import { Stack } from '@mui/material';
import { useRef } from 'react';
import { Editor } from 'tldraw';
import { useStudentMediaState } from '@hoot/hooks/daily/useDailyMediaState';
import { useWhiteboardSyncManager } from '@hoot/hooks/lessons/useWhiteboardSyncManager';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import Whiteboard from '@hoot/ui/components/v2/Whiteboard';

// TODO SC-10778 -> https://app.shortcut.com/hoot-reading/story/10778/wb-student-open-whiteboard
const StudentInLessonWhiteboardPage = () => {
  const editorRef = useRef<Editor>();

  const activeLessonState = useActiveLessonState();
  const lessonId = activeLessonState.inLesson!.lessonId;
  const whiteboardId = activeLessonState.activeWhiteboardId!; // This must exist, else we wouldn't be on this page right now.

  const { isLocked: isStudentLocked } = useStudentMediaState();

  const store = useWhiteboardSyncManager({
    whiteboardId,
    lessonId,
  });

  return (
    <Stack sx={{ width: '100%', height: '100%', justifyContent: 'center', padding: 3 }}>
      <Whiteboard store={store} editorRef={editorRef} lockCanvas={isStudentLocked} />
    </Stack>
  );
};

export default StudentInLessonWhiteboardPage;
