import { Box, Dialog, DialogContent, IconButton, Stack } from '@mui/material';
import { LessonWhiteboardTemplateResponse, LessonWhiteboardTemplateStatus } from '@hoot/hooks/api/whiteboard/useGetLessonWhiteboardTemplates';
import { WhiteboardTemplateTypeEnum } from '@hoot/models/api/enums/whiteboard-template-type-enum';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';
import CloseIcon from '@hoot/ui/components/v2/icons/CloseIcon';
import PlayIcon from '@hoot/ui/components/v2/icons/PlayIcon';
import { wbTemplateStatusLabelDictionary } from './utils';

interface TemplatePreviewDialogProps {
  currentTemplate: LessonWhiteboardTemplateResponse | null | undefined;
  onOpenWhiteboard: (whiteboardTemplateType: WhiteboardTemplateTypeEnum) => void;
  onDismiss: () => void;
}

const WhiteboardTemplatePreviewDialog = (props: TemplatePreviewDialogProps) => {
  const { currentTemplate, onOpenWhiteboard, onDismiss } = props;

  const hasInstructionalUnits = !!currentTemplate?.whiteboardTemplate.instructionalUnits.length;
  const hasInstructionalFocuses = !!currentTemplate?.whiteboardTemplate.instructionalFocuses.length;

  if (!currentTemplate) return null;

  return (
    <Dialog
      open={!!currentTemplate}
      onClose={() => onDismiss()}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          p: 2,
          pb: 6,
          gap: 2,
        },
      }}
    >
      <>
        <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
          <div>
            <HootTypography variant="titlelarge" isPII={false}>
              Whiteboard
            </HootTypography>
            <HootTypography variant="bodysmall" isPII={false}>
              {currentTemplate?.whiteboardTemplate.name}
            </HootTypography>
          </div>
          <IconButton onClick={() => onDismiss()} sx={{ marginLeft: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <DialogContent sx={{ padding: 0 }}>
          <Stack flexDirection="row" gap={2} justifyContent="space-between" alignItems="center">
            <Stack flexDirection="row" gap={6} flex={'1 1 auto'}>
              <Box sx={{ maxWidth: '256px' }}>
                <Box
                  component="img"
                  src={`${currentTemplate?.whiteboardTemplate.imageUrl}`}
                  sx={{ width: '100%', border: '1px solid black', borderRadius: '8px' }}
                />
                <Button
                  onClick={() => onOpenWhiteboard(currentTemplate.whiteboardTemplate.templateType)}
                  variant="contained"
                  fullWidth
                  startIcon={<PlayIcon htmlColor="#FFF" />}
                >
                  {currentTemplate.status === LessonWhiteboardTemplateStatus.InProgress ? 'Resume Whiteboard' : 'Create Whiteboard'}
                </Button>
              </Box>
              <Stack gap={2} width="100%">
                <ReadOnlyTextField
                  label="Template State"
                  body={wbTemplateStatusLabelDictionary[currentTemplate.status]}
                  filledColour={currentTemplate.status === LessonWhiteboardTemplateStatus.InProgress ? 'success.190' : 'neutral.190'}
                  invertLabelStyles
                  sx={{ width: '100%' }}
                />
                {hasInstructionalFocuses && (
                  <ReadOnlyTextField
                    label="Instructional Focuses"
                    body={currentTemplate?.whiteboardTemplate.instructionalFocuses.map((u) => u.name).join(', ')}
                    filledColour="neutral.190"
                    invertLabelStyles
                    sx={{ width: '100%' }}
                  />
                )}
                {hasInstructionalUnits && (
                  <ReadOnlyTextField
                    label="Instructional Units"
                    body={currentTemplate?.whiteboardTemplate.instructionalUnits.map((u) => u.name).join(', ')}
                    filledColour="neutral.190"
                    invertLabelStyles
                    sx={{ width: '100%' }}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
      </>
    </Dialog>
  );
};

export default WhiteboardTemplatePreviewDialog;
