import React, { FunctionComponent, useEffect } from 'react';
import { useDebounce } from '@hoot/hooks/useDebounce';
import TextField, { TextFieldProps } from '@hoot/ui/components/v2/core/TextField';

const SEARCH_INPUT_DEBOUNCE_TIME_MS = 1000;

export interface SearchTextFieldProps extends Omit<TextFieldProps, 'onChange' | 'value'> {
  searchInput: string;
  onSearchInputChanged: (text: string) => void;
  onSearchInputDebounced?: (text: string) => void;
  debounceTime?: number;
  placeHolderText?: string;
}

const SearchTextField: FunctionComponent<SearchTextFieldProps> = (props) => {
  const {
    searchInput,
    onSearchInputChanged,
    onSearchInputDebounced,
    debounceTime = SEARCH_INPUT_DEBOUNCE_TIME_MS,
    placeHolderText = 'Search',
    ...textFieldProps
  } = props;

  const _onSearchInputChanged: TextFieldProps['onChange'] = (event) => {
    const input = event.target.value;
    onSearchInputChanged(input);
  };

  const debouncedSearchInput = useDebounce(searchInput, debounceTime);

  useEffect(() => {
    onSearchInputDebounced?.(debouncedSearchInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchInput]);

  const handleClearSearch = () => {
    onSearchInputChanged('');
    onSearchInputDebounced?.('');
  };

  return (
    <TextField
      value={searchInput}
      onChange={_onSearchInputChanged}
      onClearButtonClicked={handleClearSearch}
      useSearchIcon
      clearable
      type="search"
      placeholder={placeHolderText}
      {...textFieldProps}
    />
  );
};

export default SearchTextField;
