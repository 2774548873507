import { Box, BoxProps, Fade, LinearProgress, Paper, PaperProps } from '@mui/material';
import { ReactNode } from 'react';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { TypographyVariant } from '@hoot/ui/theme/v2/typography';

export interface CardProps extends Pick<PaperProps, 'sx' | 'ref' | 'children'> {
  title?: string | ReactNode;
  titleVariant?: TypographyVariant;
  sectionHeader?: string;
  contentSx?: BoxProps['sx'];
  isLoading?: boolean;
  elevation?: 0 | 1 | 2 | 3 | 4 | 5;
  onClick?: () => void;
}

const Card = (props: CardProps) => {
  const { title, titleVariant, sectionHeader, isLoading, children, elevation = 1, onClick, sx = {}, contentSx = {}, ...muiCardProps } = props;

  return (
    <Paper
      sx={{
        ...hootTokens.elevation.get(elevation),
        ...sx,
      }}
      {...muiCardProps}
      onClick={onClick}
    >
      <Box height="4px">
        <Fade in={isLoading}>
          <LinearProgress color="secondary" />
        </Fade>
      </Box>
      <Box
        sx={{
          padding: '12px 16px 16px 16px',
          ...contentSx,
        }}
      >
        {sectionHeader ? (
          <HootTypography isPII={false} variant="titlelarge" mb={2}>
            {sectionHeader}
          </HootTypography>
        ) : null}
        {title && (
          <HootTypography isPII={false} variant={titleVariant ? titleVariant : 'titlemedium'} mb={2}>
            {title}
          </HootTypography>
        )}
        {children}
      </Box>
    </Paper>
  );
};

export default Card;
