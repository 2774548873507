import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { Tokens } from '@hoot/models/api/auth';
import { User } from '@hoot/models/api/user';

export interface ExchangeSsoTokenRequest {
  code: string;
  state?: string;
}

export interface ExchangeSsoTokenResponse {
  user: User;
  tokens: Tokens;
  // NOTE: Don't accept/add in the profileToAssume property from EndUserLoginResponse to this.
  //       This is because Clever has a certification requirement that we provide a clear way for
  //       the user to know what account they've logged into via SSO
  //       See https://dev.clever.com/docs/district-sso-certification-guide#integration-requirements
  //        - "Must provide clear indication to user that they are logged in to the correct account"
}

async function exchangeSsoToken(request: ExchangeSsoTokenRequest): Promise<ExchangeSsoTokenResponse> {
  const { data } = await axios.post(`${config.apiUrl}/edlink/auth/token-exchange`, request);

  return data;
}

export function useSsoTokenExchange(
  options?: Omit<UseMutationOptions<ExchangeSsoTokenResponse, AxiosError, ExchangeSsoTokenRequest>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation<ExchangeSsoTokenResponse, AxiosError, ExchangeSsoTokenRequest>((request) => exchangeSsoToken(request), options);
}
