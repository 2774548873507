import { useSelector } from 'react-redux';
import { UserProfileType } from '@hoot/models/api/user';
import { RootState } from '@hoot/redux/store';

/**
 * Checks the user's profile type. Ex: Parent/Student/Teacher/District Rep
 * @returns true/false for the type you're accessing.
 *
 * Can also be used to retrieve the Profile object from the state.
 */
function useProfile() {
  const profileState = useSelector((state: RootState) => state.profile.profile);

  const isParent = profileState?.type === UserProfileType.Parent;
  const isStudent = profileState?.type === UserProfileType.Student;
  const isTeacher = profileState?.type === UserProfileType.Teacher;
  const isDistrictRep = profileState?.type === UserProfileType.DistrictRep;
  const profile = profileState;
  const isProfileEnabled = profile?.isEnabled;

  return {
    isParent,
    isStudent,
    isTeacher,
    isDistrictRep,
    profile,
    isProfileEnabled,
  };
}

export default useProfile;
