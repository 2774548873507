import ReactGA from 'react-ga4';
import { config } from '../config';
import { IS_LOCAL } from '../constants/constants';

export enum AnalyticsCategories {
  FreeLessonFlow = 'Free Lesson Flow',
}

async function initGoogleAnalytics() {
  if (!ReactGA.isInitialized) {
    ReactGA.initialize(config.googleAnalyticsMeasurementId, { testMode: IS_LOCAL });
  }
}

const startedFreeLessonFlow = () => {
  event('Started Free Lesson Flow', AnalyticsCategories.FreeLessonFlow);
};

const completedFLFStep1 = () => {
  event('Completed FLF Step 1', AnalyticsCategories.FreeLessonFlow);
};

const completedFLFStep2 = () => {
  event('Completed FLF Step 2', AnalyticsCategories.FreeLessonFlow);
};

const completedFLFStep3 = () => {
  event('Completed FLF Step 3', AnalyticsCategories.FreeLessonFlow);
};

const completedFLFStep4 = () => {
  event('Completed FLF Step 4', AnalyticsCategories.FreeLessonFlow);
};

const completedFLFStep5 = () => {
  event('Completed FLF Step 5', AnalyticsCategories.FreeLessonFlow);
};

const completedFLFStep6 = () => {
  event('Completed FLF Step 6', AnalyticsCategories.FreeLessonFlow);
};

const event = (action: string, category: AnalyticsCategories, label?: string, value?: number) => {
  if (ReactGA.isInitialized) {
    ReactGA.event({
      action: action,
      label: label,
      category: category,
      value: value,
    });
  }
};

export const googleAnalytics = {
  initGoogleAnalytics,
  startedFreeLessonFlow,
  completedFLFStep1,
  completedFLFStep2,
  completedFLFStep3,
  completedFLFStep4,
  completedFLFStep5,
  completedFLFStep6,
};
