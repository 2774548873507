import { Accordion, AccordionDetails, AccordionSummary, Stack, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { capitalCase } from 'change-case';
import * as CSS from 'csstype';
import { Chart } from 'react-google-charts';
import { ChartWrapperOptions } from 'react-google-charts/dist/types';
import {
  AssessmentReportStatus,
  InstructionalFocusModule,
  InstructionalFocusUnit,
} from '@hoot/hooks/api/assessment/useGetStudentInstructionalFocusAreasReport';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import Tag, { TagColor } from '@hoot/ui/components/v2/core/Tag';
import Chevron from '@hoot/ui/components/v2/icons/Chevron';
import InfoFilledIcon from '@hoot/ui/components/v2/icons/InfoFilledIcon';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const statusColorMap: Record<AssessmentReportStatus, CSS.Property.Color> = {
  [AssessmentReportStatus.Bypassed]: hootTokens.palette.success['180'],
  [AssessmentReportStatus.Mastered]: hootTokens.palette.success['100'],
  [AssessmentReportStatus.NeedsInstruction]: hootTokens.palette.warning['100'],
  [AssessmentReportStatus.NeedsReview]: hootTokens.palette.secondary['100'],
  [AssessmentReportStatus.NotStarted]: hootTokens.palette.primary['180'],
};

const SubmissionChart = (props: { instructionalFocusUnit: InstructionalFocusUnit }) => {
  const { instructionalFocusUnit } = props;

  const submissions = instructionalFocusUnit.submissions?.length
    ? instructionalFocusUnit.submissions.map((submission) => {
        return [submission.date, submission.score, statusColorMap[submission.status]];
      })
    : [];

  const data = [['Date', 'Score', { role: 'style' }], ...submissions];

  const options: ChartWrapperOptions['options'] = {
    legend: 'none',
    bar: { groupWidth: 40 },
    chartArea: {
      height: '80%',
      width: '80%',
    },
    vAxis: {
      viewWindow: {
        min: 0,
        max: instructionalFocusUnit.maxScore ?? 5,
      },
    },
  };

  return <Chart chartType="ColumnChart" width="100%" height="218px" data={data} options={options} />;
};

const UnitCard = (props: { unit: InstructionalFocusUnit }) => {
  const { unit } = props;
  const theme = useTheme();

  const isMastered = [AssessmentReportStatus.Mastered, AssessmentReportStatus.Bypassed].includes(unit.status);
  const renderChart = !(isMastered || [AssessmentReportStatus.NotStarted].includes(unit.status));
  const hasUrl = !!unit.instructionalPathUrl;

  const handleNavigate = (url?: string) => {
    if (!url) return;
    window.open(url);
  };

  return (
    <Grid size={{ xs: 12, sm: 6, md: 4, xl: 3 }} key={unit.order}>
      <Card contentSx={{ pb: theme.spacing(3) }}>
        <Stack direction="row" justifyContent="center" alignItems="center" gap={1} sx={{ height: 44 }}>
          <Stack flex={1} />
          <HootTypography isPII={false} variant="titlesmall">
            {unit.label}
          </HootTypography>
          <Stack direction="row" justifyContent="flex-start" flex={1}>
            {hasUrl && (
              <IconButton onClick={() => handleNavigate(unit.instructionalPathUrl)}>
                <InfoFilledIcon htmlColor={hootTokens.palette.black} />
              </IconButton>
            )}
          </Stack>
        </Stack>
        {renderChart && <SubmissionChart instructionalFocusUnit={unit} />}
        {!renderChart && (
          <ViewStateIllustration
            illustration={isMastered ? IllustrationEnum.SetupComplete : IllustrationEnum.EmptyState}
            title={''}
            subtitle={isMastered ? 'This unit has been passed' : 'This unit has not been started'}
            padding={0}
          />
        )}
      </Card>
    </Grid>
  );
};

interface LegendStatus {
  status: AssessmentReportStatus;
  label: string;
}

const legendStatuses: LegendStatus[] = [
  { status: AssessmentReportStatus.NeedsInstruction, label: 'Instruction Required' },
  { status: AssessmentReportStatus.NeedsReview, label: 'Review Required' },
  { status: AssessmentReportStatus.Mastered, label: 'Mastered' },
];

const StudentInProgressModules = (props: { instructionalFocusModule?: InstructionalFocusModule[] }) => {
  const { instructionalFocusModule } = props;
  if (!instructionalFocusModule || instructionalFocusModule.length === 0) return null;

  return (
    <Stack gap={2}>
      {instructionalFocusModule.map((module) => (
        <Card key={module.order}>
          <Accordion elevation={0} defaultExpanded sx={{ p: 0 }}>
            <AccordionSummary expandIcon={<Chevron sx={{ rotate: '90deg' }} />}>
              <Stack gap={2} direction="row" alignItems="center">
                <HootTypography isPII={false} variant="titlemedium">
                  {module.label}
                </HootTypography>
                <Tag label={capitalCase(module.status)} color={TagColor.Neutral} />
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ mt: 2 }}>
              <Stack gap={2}>
                <Grid container direction="row" columnGap={2}>
                  {legendStatuses.map((status) => (
                    <Stack key={status.label} columnGap={2} direction="row" alignItems="center">
                      <div
                        style={{
                          width: 15,
                          height: 15,
                          borderRadius: 2,
                          backgroundColor: statusColorMap[status.status],
                        }}
                      />
                      <HootTypography isPII={false} variant="bodysmall">
                        {status.label}
                      </HootTypography>
                    </Stack>
                  ))}
                </Grid>
                <Grid container spacing={2}>
                  {module.units.map((unit) => (
                    <UnitCard key={unit.order} unit={unit} />
                  ))}
                </Grid>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Card>
      ))}
    </Stack>
  );
};

export default StudentInProgressModules;
