import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { Socket } from 'socket.io-client';
import { UserProfileType } from '@hoot/models/api/user';
import { LOCAL_APP_VERSION } from '../../constants/constants';
import { EventType } from '../../events/eventType';
import { ResyncSessionMessage } from '../../events/messages/resync-session-message';
import { AppDispatch, RootState } from '../store';
import { ModalType, showModal } from './modalsSlice';

export interface ApplicationState {
  isConnected: boolean;
  localAppVersion?: string;
  newAppVersionAvailable: boolean;
  lastAppUpdatePromptTime: number;
}

const initialState: ApplicationState = {
  isConnected: false,
  localAppVersion: LOCAL_APP_VERSION,
  newAppVersionAvailable: false,
  lastAppUpdatePromptTime: Date.now(),
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    resetApplication: () => initialState,
    updateIsConnected: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
    setNewVersionAvailable: (state, action: PayloadAction<boolean>) => {
      state.newAppVersionAvailable = action.payload;
    },
  },
});

export const connected = createAsyncThunk<void, Socket, { dispatch: AppDispatch; state: RootState }>(
  'application/connected',
  async (socket, thunkApi) => {
    thunkApi.dispatch(applicationSlice.actions.updateIsConnected(true));

    // refetch lessons in case user was sitting on library and got disconnected
    const state = thunkApi.getState();
    if (state.profile.profile && [UserProfileType.Teacher, UserProfileType.Student].includes(state.profile.profile.type)) {
      socket.emit(EventType.UpcomingLessonsRequest);
    }
  },
);

export const disconnected = createAsyncThunk<void, Socket, { dispatch: AppDispatch; state: RootState }>(
  'application/disconnected',
  async (socket, thunkApi) => {
    thunkApi.dispatch(applicationSlice.actions.updateIsConnected(false));
    const state = thunkApi.getState();
    const profile = state.profile.profile;
    setTimeout(() => {
      if (socket.disconnected && !!profile) {
        thunkApi.dispatch(showModal(ModalType.Reconnecting));
      }
    }, 3000);
  },
);

export const resyncSession = createAsyncThunk<void, Socket, { dispatch: AppDispatch; state: RootState }>(
  'application/resync-session',
  async (socket, thunkApi) => {
    const state = thunkApi.getState();

    if (state.profile.profile) {
      const resyncSessionMessage: ResyncSessionMessage = {
        profileId: state.profile.profile?.id,
        lessonId: state.activeLesson?.inLesson?.lessonId,
      };

      socket.emit(EventType.ResyncSession, resyncSessionMessage);
    }
  },
);

export const { setNewVersionAvailable } = applicationSlice.actions;

export const useApplicationState = () => useSelector((state: RootState) => state.application);

export default applicationSlice.reducer;
