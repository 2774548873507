import { PaperProps } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import GetNextUnitForAssessmentReminder from '@hoot/hooks/api/my-student/getNextUnitForAssessmentReminder';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { useStudentDetailsContext } from '@hoot/ui/pages/v2/teacher/my-students/student-details/StudentDetailsContextProvider';

const AssessmentReminderCard = (props: Pick<PaperProps, 'sx'>) => {
  const { sx = {} } = props;
  const { studentProfileSnippet } = useStudentDetailsContext();
  const { data } = GetNextUnitForAssessmentReminder(studentProfileSnippet.id);

  if (!data?.nextUnit) {
    return null;
  }
  const reminder = `${studentProfileSnippet.name} will begin their next assessment on unit: ${data.nextUnit}.`;

  return (
    <Card
      title={
        <Stack direction="row" alignItems="center" gap={1}>
          <Icon name="push_pin" color="primary.200" sx={{ fontSize: 18 }} />
          <HootTypography isPII={false} variant="titlemedium">
            Assessment Reminders
          </HootTypography>
        </Stack>
      }
      sx={{ ...sx }}
    >
      <Stack>
        <HootTypography isPII={true} variant="bodylarge">
          {reminder}
        </HootTypography>
      </Stack>
    </Card>
  );
};

export default AssessmentReminderCard;
