import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import { useStudentDetailsContext } from '@hoot/ui/pages/v2/teacher/my-students/student-details/StudentDetailsContextProvider';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const LessonReviewHeader = (props: { lessonNumber?: number }) => {
  const { studentProfileSnippet } = useStudentDetailsContext();

  const title = `Lesson Review ${props.lessonNumber ?? ''}`;

  return (
    <Stack>
      <Stack sx={{ background: hootTokens.palette.white }}>
        <SubHeader
          title={{
            label: title,
            isPII: false,
          }}
          backButton={{
            show: true,
            navigateBackPath: routesDictionary.myStudents.details.reviews.url(studentProfileSnippet.id),
          }}
        />
      </Stack>
      <Outlet />
    </Stack>
  );
};

export default LessonReviewHeader;
