import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface GetLessonReviewNotesResponse {
  notes: string;
  lastUpdatedAt: number;
}

export function useGetLessonReviewNotes(lessonId: string, options?: Omit<UseQueryOptions<GetLessonReviewNotesResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery(
    [QueryKey.GetLessonReviewNotes, lessonId],
    async () => {
      return (await axios.get<GetLessonReviewNotesResponse>(`${config.apiUrl}/public/lesson-review/lesson/${lessonId}/notes`)).data;
    },
    options,
  );
}
