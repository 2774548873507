import { useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import Page from '@hoot/ui/components/v2/core/Page';
import TeacherAnnouncements from '@hoot/ui/pages/v2/teacher/dashboard/announcements/TeacherAnnouncements';
import TeacherUpcomingLessons from '@hoot/ui/pages/v2/teacher/dashboard/upcoming-lessons/TeacherUpcomingLessons';
import ActionRequired from './alerts/ActionRequired';

export function TeacherDashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <SubHeader
        title={{
          label: 'Dashboard',
          isPII: false,
        }}
      />

      <Page pageTitle="Dashboard | Hoot Reading">
        <Grid container spacing={2}>
          <Grid size={{ md: 4, xs: 12 }}>
            <TeacherUpcomingLessons />
          </Grid>
          <Grid size={{ md: 8, xs: 12 }} sx={{ display: 'flex', flexDirection: 'column' }} gap={2}>
            {isMobile ? (
              <>
                <ActionRequired />
                <TeacherAnnouncements />
              </>
            ) : (
              <>
                <TeacherAnnouncements />
                <ActionRequired />
              </>
            )}
          </Grid>
        </Grid>
      </Page>
    </>
  );
}
