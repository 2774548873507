import { SvgIcon, SvgIconProps } from '@mui/material';

const CancelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.2981 15.3588C14.591 15.6517 15.0659 15.6517 15.3588 15.3588C15.6517 15.0659 15.6517 14.591 15.3588 14.2981L14.2981 15.3588ZM9.70191 8.64125C9.40901 8.34835 8.93414 8.34835 8.64125 8.64125C8.34835 8.93414 8.34835 9.40901 8.64125 9.70191L9.70191 8.64125ZM8.64124 14.2981C8.34835 14.591 8.34835 15.0659 8.64124 15.3588C8.93413 15.6517 9.40901 15.6517 9.7019 15.3588L8.64124 14.2981ZM15.3588 9.70191C15.6516 9.40901 15.6516 8.93414 15.3588 8.64125C15.0659 8.34835 14.591 8.34835 14.2981 8.64125L15.3588 9.70191ZM15.3588 14.2981L9.70191 8.64125L8.64125 9.70191L14.2981 15.3588L15.3588 14.2981ZM9.7019 15.3588L15.3588 9.70191L14.2981 8.64125L8.64124 14.2981L9.7019 15.3588ZM12 22.75C17.9371 22.75 22.75 17.9371 22.75 12H21.25C21.25 17.1086 17.1086 21.25 12 21.25V22.75ZM1.25 12C1.25 17.9371 6.06294 22.75 12 22.75V21.25C6.89137 21.25 2.75 17.1086 2.75 12H1.25ZM12 1.25C6.06294 1.25 1.25 6.06294 1.25 12H2.75C2.75 6.89137 6.89137 2.75 12 2.75V1.25ZM12 2.75C17.1086 2.75 21.25 6.89137 21.25 12H22.75C22.75 6.06294 17.9371 1.25 12 1.25V2.75Z"
        fill={props.htmlColor ?? 'currentColor'}
      />
    </SvgIcon>
  );
};

export default CancelIcon;
