import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { HootAssessmentV2Response } from '@hoot/hooks/api/assessment/useGetStudentAssessmentsV2';
import { QueryKey } from '../queryKeys';

export interface HootInProgressAssessmentResponse {
  inProgressAssessment: HootAssessmentV2Response | null;
}

export function useGetStudentInProgressAssessment(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<HootInProgressAssessmentResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetMostRecentAssessment, studentProfileId],
    async () => {
      return (await axios.get<HootInProgressAssessmentResponse>(`${config.apiUrl}/public/hoot-assessment/v2/student/${studentProfileId}/in-progress`))
        .data;
    },
    options,
  );
}
