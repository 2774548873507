import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { HootColor, HootPalette, hootTokens } from './tokens';

// Updating this value will change the button base luminescene for all button color variants
const BASE_LUMINESCENCE = 80 as keyof HootPalette;
const ACTIVE_LUMINESCENCE_OFFSET = 0;
const HOVER_LUMINESCENCE_OFFSET = 20;
const FOCUS_LUMINESCENCE_OFFSET = 20;
const WHITE_FONTS_80_AND_LOWER: ColorPaletteOption[] = ['primary', 'error', 'neutral'];

export interface Colors {
  base: string;
  active: string;
  focus: string;
  hover: string;
  font: string;
  palette: HootPalette;
}

function getColorKeys(paletteType: ColorPaletteOption): { color: keyof HootColor; luminescence: keyof HootPalette } {
  let color: keyof HootColor = 'primary';
  let luminescence = BASE_LUMINESCENCE;
  const paletteParts = paletteType.split('.');
  if (paletteParts.length === 2) {
    color = paletteParts[0] as keyof HootColor;
    luminescence = parseInt(paletteParts[1]) as keyof HootPalette;
  } else if (paletteParts.length === 1) {
    color = paletteParts[0] as keyof HootColor;
  }

  return { color, luminescence };
}

/**
 *
 * @param paletteType - the palette type from palettes available in Hoot Tokens
 * @param luminescence - the base luminescence for your color palette (Default is 60)
 * @returns
 */
export function getColors(paletteType: ColorPaletteOption): Colors {
  const { color, luminescence } = getColorKeys(paletteType);

  if (paletteType === 'primary') {
    return {
      base: hootTokens.palette.primary[0],
      focus: hootTokens.palette.primary[40],
      hover: hootTokens.palette.primary[40],
      active: hootTokens.palette.primary[0],
      font: fontColor(luminescence, paletteType),
      palette: hootTokens.palette.primary,
    };
  } else {
    const palette = hootTokens.palette[color] as HootPalette;
    const activeLuminescenceIndex = (luminescence < 190 ? luminescence + ACTIVE_LUMINESCENCE_OFFSET : 180) as keyof HootPalette;
    const focusLuminescence = (luminescence < 190 ? luminescence + FOCUS_LUMINESCENCE_OFFSET : 180) as keyof HootPalette;
    const hoverLuminescence = (luminescence < 190 ? luminescence + HOVER_LUMINESCENCE_OFFSET : 180) as keyof HootPalette;

    return {
      active: palette[activeLuminescenceIndex],
      base: palette[luminescence],
      focus: palette[focusLuminescence],
      hover: palette[hoverLuminescence],
      font: fontColor(luminescence, paletteType),
      palette,
    };
  }
}

function fontColor(luminescence: number, palette?: Omit<keyof HootColor, 'black' | 'white'>) {
  if (luminescence <= 60) {
    return hootTokens.palette.white;
  } else if (luminescence <= 80 && WHITE_FONTS_80_AND_LOWER.find((colour) => palette?.includes(colour))) {
    return hootTokens.palette.white;
  } else {
    return hootTokens.palette.black;
  }
}
