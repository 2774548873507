import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { UseGetOptions } from '@hoot/models/api/axios-options';
import { BookType } from '@hoot/models/api/library';

export interface InstructionalFocusesResponse {
  instructionalFocuses: InstructionalFocus[];
}

interface InstructionalFocus {
  id: string;
  name: string;
}

export interface InstructionalFocusRequest {
  resourceType: BookType | null; //Note: null cannot actually be passed into backend from enabled option
}

async function getInstructionalFocuses(requestPayload: InstructionalFocusRequest): Promise<InstructionalFocusesResponse> {
  const { data } = await axios.get<InstructionalFocusesResponse>(`${config.apiUrl}/library/instructional-focuses`, { params: requestPayload });
  return data;
}

export default function useGetInstructionalFocuses(requestPayload: InstructionalFocusRequest, options?: UseGetOptions<InstructionalFocusesResponse>) {
  return useQuery(
    [QueryKey.GetInstructionalFocuses, (requestPayload.resourceType as string) ?? 'no-resource-type'],
    () => getInstructionalFocuses(requestPayload),
    {
      enabled: !!requestPayload.resourceType,
      ...options,
    },
  );
}
