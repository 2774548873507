import { Box } from '@mui/system';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PASSWORD_MAXIMUM_LENGTH, PASSWORD_MINIMUM_LENGTH } from '@hoot/constants/constants';
import { ResetPasswordRequest, useResetPassword } from '@hoot/hooks/api/auth/useResetPassword';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { UserProfileType } from '@hoot/models/api/user';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { RootState, useAppDispatch } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import TextField from '@hoot/ui/components/v2/core/TextField';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface Form {
  password: string;
  retryPassword: string;
}

interface Error {
  error: string;
  message: string;
  statusCose: number;
}

const ChangePasswordV2 = () => {
  usePageTitle('Change Password | Hoot Reading');
  const { isAuthenticated, logout } = useAuth();
  const profile = useSelector((state: RootState) => state.profile.profile);
  const isStudentProfile = profile?.type === UserProfileType.Student;

  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  const resetPasswordMutation = useResetPassword();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!query.passwordResetToken) {
      console.log('Missing password reset token.');
      navigate(routesDictionary.home.url, { replace: true });
    }
  }, [navigate, query.passwordResetToken]);

  useEffect(() => {
    if (isAuthenticated) {
      logout(false);
    }
  }, [isAuthenticated, logout]);

  const {
    formState: { isSubmitting, isSubmitSuccessful },
    getValues,
    handleSubmit,
    control,
  } = useForm<Form>({
    defaultValues: {
      password: '',
      retryPassword: '',
    },
  });

  const onSubmit = async (data: Form) => {
    const request: ResetPasswordRequest = {
      password: data.password,
      passwordResetToken: (query.passwordResetToken as string) || '',
    };

    resetPasswordMutation.mutate(request, {
      onSuccess: () => {
        setShowSuccess(true);
        setTimeout(() => {
          navigate(routesDictionary.home.url);
        }, 3000);
      },
      onError: (exc) => {
        const error = exc.response?.data as Error;
        dispatch(createFlashMessage({ message: error.message }));
      },
    });
  };

  const label = String(query.isNewAccount).toLowerCase() === 'true' ? 'Set Password' : 'Change Password';

  if (isStudentProfile) {
    navigate(-1);
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        backgroundImage: 'url("/images/flf-background.webp")',
        backgroundSize: 'cover',
        padding: '24px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          background: hootTokens.palette.white,
          padding: '24px',
          borderRadius: '8px',
          width: '100%',
          maxWidth: '400px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <img src="/images/logo-v2.svg" alt="logo" height="180px" width="216px" />
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              gap: '16px',
            }}
          >
            {showSuccess ? (
              <HootTypography isPII={false} variant="titlesmall">
                You have successfully changed your password. You will be redirected to the login page now...
              </HootTypography>
            ) : (
              <HootTypography isPII={false} variant="titlelarge">
                {label}
              </HootTypography>
            )}
            <Controller
              name="password"
              control={control}
              rules={{
                minLength: {
                  value: PASSWORD_MINIMUM_LENGTH,
                  message: `Minimum length is ${PASSWORD_MINIMUM_LENGTH}`,
                },
                maxLength: {
                  value: PASSWORD_MAXIMUM_LENGTH,
                  message: `Maximum length is ${PASSWORD_MAXIMUM_LENGTH}`,
                },
                validate: (value) => value === getValues('retryPassword') || 'Passwords do not match.',
              }}
              render={({ field: { onChange }, fieldState }) => (
                <TextField
                  data-hoot-canary-id="password"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || ''}
                  label={'New Password'}
                  fullWidth
                  required
                  onChange={onChange}
                  type="password"
                />
              )}
            />
            <Controller
              name="retryPassword"
              control={control}
              rules={{
                minLength: {
                  value: PASSWORD_MINIMUM_LENGTH,
                  message: `Minimum length is ${PASSWORD_MINIMUM_LENGTH}`,
                },
                maxLength: {
                  value: PASSWORD_MAXIMUM_LENGTH,
                  message: `Maximum length is ${PASSWORD_MAXIMUM_LENGTH}`,
                },
                validate: (value) => value === getValues('password') || 'Passwords do not match.',
              }}
              render={({ field: { onChange }, fieldState }) => (
                <TextField
                  data-hoot-canary-id="retryPassword"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message || ''}
                  label={'Re-enter Password'}
                  fullWidth
                  required
                  onChange={onChange}
                  type="password"
                />
              )}
            />
            <Button type="submit" variant="contained" disabled={isSubmitting || isSubmitSuccessful} fullWidth>
              {label}
            </Button>
          </Box>
        </form>
      </Box>
      <Box />
    </Box>
  );
};

export default ChangePasswordV2;
