import { DialogActions, DialogContent, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ReactNode, useMemo, useState } from 'react';
import { featureFlags } from '@hoot/constants/featureFlags';
import {
  LessonReviewFocus,
  LessonReviewFocusSkill,
  LessonReviewFocusUnit,
  lessonReviewFocusLabel,
  lessonReviewFocusSkillLabel,
  lessonReviewFocusUnitLabel,
  lessonReviewFocusesBySubject,
  lessonReviewFocusesBySubjectV2,
  lessonReviewSkillByUnit,
  lessonReviewUnitByFocus,
  lessonReviewUnitByFocusV2,
} from '@hoot/models/api/enums/lesson-review-enums';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Select, SelectProps } from '@hoot/ui/components/v2/core/Select';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';
import {
  LessonReviewBook,
  LessonReviewWizardStepEnum,
  useLessonReviewWizardContext,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';
import { BookPickerModalProps } from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/book-picker/BookPickerModal';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import InstructionalFocusBookPicker from '../book-picker/InstructionalFocusBookPicker';

const MAX_FOCUS_BOOKS = 4;

// From SC-8245:
// If a focus of these types is selected, then a focus book selection is optional.
const focusesNotRequiringBook = [
  LessonReviewFocus.PhonologicalAwareness,
  LessonReviewFocus.PhonemicAwareness,
  LessonReviewFocus.RelationshipAndRapportBuilding,
];

const focusesNotRequiringUnit = [
  LessonReviewFocus.LettersAndSounds,
  LessonReviewFocus.HootReadingAssessment,
  LessonReviewFocus.LanguageStructures,
  LessonReviewFocus.RelationshipAndRapportBuilding,
];

const focusesRequiringUnit = [
  LessonReviewFocus.PhonologicalAwareness,
  LessonReviewFocus.PhonemicAwareness,
  LessonReviewFocus.EarlyWordReading,
  LessonReviewFocus.ComplexWordReading,
  LessonReviewFocus.TextReading,
  LessonReviewFocus.PreWordReading,
];

const unitsRequiringSkill = [LessonReviewFocusUnit.LanguageComprehension, LessonReviewFocusUnit.ReadingComprehension];

const LessonReviewInstructionalFocusStep = () => {
  const {
    lessonToReview,
    instructionalFocusState,
    actions: { goToNextStep, goToPreviousStep, attachInstructionalFocusData },
  } = useLessonReviewWizardContext();

  const { isFeatureEnabled } = useFeatureFlags();
  const isAssessmentSuiteV2Enabled = isFeatureEnabled(featureFlags.SC_11213_AssessmentSuite_v2);

  const [primaryFocus, setPrimaryFocus] = useState<LessonReviewFocus | undefined>(instructionalFocusState?.primaryFocus);
  const [primaryFocusUnit, setPrimaryFocusUnit] = useState<LessonReviewFocusUnit | undefined>(instructionalFocusState?.primaryFocusUnit);
  const [primaryFocusSkill, setPrimaryFocusSkill] = useState<LessonReviewFocusSkill | undefined>(instructionalFocusState?.primaryFocusSkill);
  const [secondaryFocus, setSecondaryFocus] = useState<LessonReviewFocus | undefined>(instructionalFocusState?.secondaryFocus);
  const [secondaryFocusUnit, setSecondaryFocusUnit] = useState<LessonReviewFocusUnit | undefined>(instructionalFocusState?.secondaryFocusUnit);
  const [secondaryFocusSkill, setSecondaryFocusSkill] = useState<LessonReviewFocusSkill | undefined>(instructionalFocusState?.secondaryFocusSkill);
  const [showAddBookModal, setShowAddBookModal] = useState<'primaryFocus' | 'secondaryFocus' | undefined>();

  const [selectedPrimaryFocusBooks, setSelectedPrimaryFocusBooks] = useState<LessonReviewBook[]>(instructionalFocusState?.primaryFocusBooks ?? []);
  const [selectedSecondaryFocusBooks, setSelectedSecondaryFocusBooks] = useState<LessonReviewBook[]>(
    instructionalFocusState?.secondaryFocusBooks ?? [],
  );

  const [noResourcesUsed, setNoResourcesUsed] = useState<boolean>(instructionalFocusState?.noResourcesUsed ?? false);

  const noBooksSelected = [...selectedPrimaryFocusBooks, ...selectedSecondaryFocusBooks].length === 0;

  const onNoResourceUsedChange = () => {
    setNoResourcesUsed((previousValue) => !previousValue);
  };

  const focusOptions = useMemo(() => {
    if (!lessonToReview?.subject) {
      return [];
    }
    const lessonReviewFocuses =
      instructionalFocusState?.lessonReviewVersion === 1 || !isAssessmentSuiteV2Enabled
        ? lessonReviewFocusesBySubject
        : lessonReviewFocusesBySubjectV2;
    return lessonReviewFocuses[lessonToReview.subject].map<{ value: LessonReviewFocus; label: string }>((focus) => {
      return {
        value: focus,
        label: lessonReviewFocusLabel[focus],
      };
    });
  }, [instructionalFocusState?.lessonReviewVersion, isAssessmentSuiteV2Enabled, lessonToReview?.subject]);

  const focusUnitOptions = (lessonReviewFocus: LessonReviewFocus) => {
    // Remove this check and use "lessonReviewUnitByFocus"
    const lessonReviewUnits =
      instructionalFocusState?.lessonReviewVersion === 1 || !isAssessmentSuiteV2Enabled ? lessonReviewUnitByFocus : lessonReviewUnitByFocusV2;
    return lessonReviewUnits[lessonReviewFocus].map<{ value: LessonReviewFocusUnit; label: string }>((focus) => {
      return {
        value: focus,
        label: lessonReviewFocusUnitLabel[focus],
      };
    });
  };

  const focusSkillOptions = (lessonReviewFocusUnit: LessonReviewFocusUnit) => {
    return lessonReviewSkillByUnit[lessonReviewFocusUnit].map<{ value: LessonReviewFocusSkill; label: string }>((skill) => {
      return {
        value: skill,
        label: lessonReviewFocusSkillLabel[skill],
      };
    });
  };

  const canGoToNextStep = () => {
    // Must have at least one book selected overall.
    if ([...selectedPrimaryFocusBooks, ...selectedSecondaryFocusBooks].length === 0 && !noResourcesUsed) {
      return false;
    }

    // Some focuses require a book to be selected where others don't. We'll check that here.
    const isBookSelectionOptional = (focus: LessonReviewFocus) => {
      return focusesNotRequiringBook.some((f) => f === focus) || noResourcesUsed;
    };
    const minRequiredPrimaryFocusBooks = !!primaryFocus && isBookSelectionOptional(primaryFocus) ? 0 : 1;
    const minRequiredSecondaryFocusBooks = (!!secondaryFocus && isBookSelectionOptional(secondaryFocus)) || !secondaryFocus ? 0 : 1;

    // Must have a selected primary focus in order to move on to the next step.
    const primaryFocusSelected = !!primaryFocus;
    // Must have at least one primary focus book selected if the selected primary focus is of a specific type.
    const primaryFocusBookSelectedOrNotRequired = selectedPrimaryFocusBooks.length >= minRequiredPrimaryFocusBooks;
    // Must have a focus unit selected if a primary focus is of a specific type.
    const primaryFocusUnitSelectedOrNotRequired =
      !!primaryFocus && (!focusesRequiringUnit.includes(primaryFocus) || (focusesRequiringUnit.includes(primaryFocus) && !!primaryFocusUnit));
    // Must have a focus skill selected if a primary focus unit is of a specific type
    const primaryFocusSkillSelectedOrNotRequired =
      !isAssessmentSuiteV2Enabled ||
      !primaryFocusUnit ||
      (!!primaryFocusUnit &&
        (!unitsRequiringSkill.includes(primaryFocusUnit) || (unitsRequiringSkill.includes(primaryFocusUnit) && !!primaryFocusSkill)));

    // Must have at least one secondary focus book selected if the selected secondary focus is of a specific type.
    const secondaryFocusBookSelectedOrNotRequired = selectedSecondaryFocusBooks.length >= minRequiredSecondaryFocusBooks;
    // Must have a focus unit selected if a secondary focus is of a specific type.
    const secondaryFocusUnitSelectedOrNotRequired =
      !secondaryFocus ||
      (!!secondaryFocus &&
        (!focusesRequiringUnit.includes(secondaryFocus) || (focusesRequiringUnit.includes(secondaryFocus) && !!secondaryFocusUnit)));
    // Must have a focus skill selected if a secondary focus unit is of a specific type
    const secondaryFocusSkillSelectedOrNotRequired =
      !isAssessmentSuiteV2Enabled ||
      !secondaryFocusUnit ||
      (!!secondaryFocusUnit &&
        (!unitsRequiringSkill.includes(secondaryFocusUnit) || (unitsRequiringSkill.includes(secondaryFocusUnit) && !!secondaryFocusSkill)));

    return (
      primaryFocusSelected &&
      primaryFocusBookSelectedOrNotRequired &&
      primaryFocusUnitSelectedOrNotRequired &&
      primaryFocusSkillSelectedOrNotRequired &&
      secondaryFocusBookSelectedOrNotRequired &&
      secondaryFocusUnitSelectedOrNotRequired &&
      secondaryFocusSkillSelectedOrNotRequired
    );
  };

  const onPrimaryFocusChanged: SelectProps['onChange'] = (event) => {
    const newFocus = !!event.target.value ? (event.target.value as LessonReviewFocus) : undefined;

    // clear other primary fields when focus has changed
    setPrimaryFocus(newFocus);
    setPrimaryFocusUnit(undefined);
    setPrimaryFocusSkill(undefined);

    // If the primary focus is cleared, then also clear out the book selections, and secondary focus/unit/skill.
    if (!newFocus) {
      setSelectedPrimaryFocusBooks([]);
      setSecondaryFocus(undefined);
      setSecondaryFocusUnit(undefined);
      setSecondaryFocusSkill(undefined);
      setSelectedSecondaryFocusBooks([]);
    }
  };

  const onPrimaryFocusUnitChanged: SelectProps['onChange'] = (event) => {
    const newFocusUnit = !!event.target.value ? (event.target.value as LessonReviewFocusUnit) : undefined;
    setPrimaryFocusUnit(newFocusUnit);
    if (newFocusUnit === secondaryFocusUnit) {
      setSecondaryFocusUnit(undefined);
      setSelectedSecondaryFocusBooks([]);
    }
  };

  const onPrimaryFocusSkillChanged: SelectProps['onChange'] = (event) => {
    const newFocusSkill = !!event.target.value ? (event.target.value as LessonReviewFocusSkill) : undefined;
    setPrimaryFocusSkill(newFocusSkill);
    if (newFocusSkill === secondaryFocusSkill) {
      setSecondaryFocusSkill(undefined);
      setSelectedSecondaryFocusBooks([]);
    }
  };

  const onSecondaryFocusChanged: SelectProps['onChange'] = (event) => {
    const newFocus = !!event.target.value ? (event.target.value as LessonReviewFocus) : undefined;

    // clear other secondary fields when focus has changed
    setSecondaryFocus(newFocus);
    setSecondaryFocusUnit(undefined);
    setSecondaryFocusSkill(undefined);

    // If the secondary focus is cleared, then also clear out the book selections.
    if (!newFocus) {
      setSelectedSecondaryFocusBooks([]);
    }
  };

  const onSecondaryFocusUnitChanged: SelectProps['onChange'] = (event) => {
    const newFocusUnit = !!event.target.value ? (event.target.value as LessonReviewFocusUnit) : undefined;
    setSecondaryFocusUnit(newFocusUnit);
  };

  const onSecondaryFocusSkillChanged: SelectProps['onChange'] = (event) => {
    const newFocusSkill = !!event.target.value ? (event.target.value as LessonReviewFocusSkill) : undefined;
    setSecondaryFocusSkill(newFocusSkill);
  };

  const onShowAddBookModal = (type: 'primaryFocus' | 'secondaryFocus') => {
    setShowAddBookModal(type);
  };

  const onDismissAddBookModal = () => {
    setShowAddBookModal(undefined);
  };

  const onAddBook: BookPickerModalProps['onApply'] = (book) => {
    const focusBook: LessonReviewBook = {
      bookId: book.id,
      title: book.title,
      coverImageUrl: book.coverUrl ?? undefined,
      focuses: book.focuses,
    };
    if (showAddBookModal === 'primaryFocus') {
      setSelectedPrimaryFocusBooks([...selectedPrimaryFocusBooks, focusBook]);
    } else if (showAddBookModal === 'secondaryFocus') {
      setSelectedSecondaryFocusBooks([...selectedSecondaryFocusBooks, focusBook]);
    }
    onDismissAddBookModal();
    setNoResourcesUsed(false);
  };

  const onRemovePrimaryFocusBook = (bookId: string) => {
    const updatedSelectedPrimaryFocusBooks = [...selectedPrimaryFocusBooks].filter((book) => book.bookId !== bookId);
    setSelectedPrimaryFocusBooks(updatedSelectedPrimaryFocusBooks);
  };

  const onRemoveSecondaryFocusBook = (bookId: string) => {
    const updatedSelectedSecondaryFocusBooks = [...selectedSecondaryFocusBooks].filter((book) => book.bookId !== bookId);
    setSelectedSecondaryFocusBooks(updatedSelectedSecondaryFocusBooks);
  };

  const submitInstructionalFocusData = () => {
    attachInstructionalFocusData({
      primaryFocus: primaryFocus!,
      secondaryFocus: secondaryFocus,
      primaryFocusBooks: [...selectedPrimaryFocusBooks],
      secondaryFocusBooks: [...selectedSecondaryFocusBooks],
      primaryFocusUnit: primaryFocusUnit,
      secondaryFocusUnit: secondaryFocusUnit,
      primaryFocusSkill: primaryFocusSkill,
      secondaryFocusSkill: secondaryFocusSkill,
      noResourcesUsed,
      lessonReviewVersion: (instructionalFocusState?.lessonReviewVersion ?? isAssessmentSuiteV2Enabled) ? 2 : 1,
    });
    // If no resources were used in the lesson, we can over the Resource Progress step and go straight to the Lesson Plan
    goToNextStep(noResourcesUsed ? LessonReviewWizardStepEnum.LessonPlan : undefined);
  };

  return (
    <>
      <DialogContent>
        <Stack gap={2} pb={2}>
          <HootTypography isPII={false} variant="titlemedium">
            {' '}
            Step 2 - Instructional Focus{' '}
          </HootTypography>
          <HootTypography isPII={false} variant="bodymedium">
            Indicate the primary and secondary (optional) Instructional Focus Areas of the lesson.
          </HootTypography>
          <Stack gap={2}>
            <Card sx={{ flex: 1 }}>
              <Grid container spacing={4}>
                <Grid size={{ xs: 12, md: 4 }}>
                  <Stack gap={3} pt={'6px'}>
                    <HootTypography isPII={false} variant="titlesmall">
                      Primary Focus
                    </HootTypography>
                    <Select label="Primary Focus" name="primaryFocus" value={primaryFocus} onChange={onPrimaryFocusChanged} required fullWidth>
                      <option value="">Select</option>
                      {focusOptions
                        .filter(
                          (f) =>
                            !secondaryFocus || (secondaryFocus && !(focusesNotRequiringUnit.includes(secondaryFocus) && f.value === secondaryFocus)),
                        )
                        .map((option) => (
                          <option value={option.value} key={`primary-focus-option-${option.value}`}>
                            {option.label}
                          </option>
                        ))}
                    </Select>
                    {!!primaryFocus && focusesRequiringUnit.includes(primaryFocus) ? (
                      <Select label="Unit" name="primaryFocusUnit" value={primaryFocusUnit} onChange={onPrimaryFocusUnitChanged} required fullWidth>
                        <option value="">Select</option>
                        {focusUnitOptions(primaryFocus)
                          .filter((f) => (primaryFocus === secondaryFocus && f.value !== secondaryFocusUnit) || primaryFocus !== secondaryFocus)
                          .map((option) => (
                            <option value={option.value} key={`primary-focus-unit-option-${option.value}`}>
                              {option.label}
                            </option>
                          ))}
                      </Select>
                    ) : null}
                    {isAssessmentSuiteV2Enabled && !!primaryFocusUnit && unitsRequiringSkill.includes(primaryFocusUnit) ? (
                      <Select
                        label="Skill"
                        name="primaryFocusSkill"
                        value={primaryFocusSkill}
                        onChange={onPrimaryFocusSkillChanged}
                        required
                        fullWidth
                      >
                        <option value="">Select</option>
                        {focusSkillOptions(primaryFocusUnit)
                          .filter(
                            (f) =>
                              (primaryFocusUnit === secondaryFocusUnit && f.value !== primaryFocusSkill) || primaryFocusUnit !== secondaryFocusUnit,
                          )
                          .map((option) => (
                            <option value={option.value} key={`primary-focus-skill-option-${option.value}`}>
                              {option.label}
                            </option>
                          ))}
                      </Select>
                    ) : null}
                  </Stack>
                </Grid>
                <Grid size={{ xs: 12, md: 8 }}>
                  <Stack width={'100%'} gap={2}>
                    <Stack direction="row" alignItems="center" justifyContent={'space-between'} gap={1}>
                      <HootTypography isPII={false} variant="titlesmall">
                        Primary Resources Used
                      </HootTypography>
                      <Button
                        variant="contained"
                        size="small"
                        color="success.60"
                        disabled={!primaryFocus || selectedPrimaryFocusBooks.length >= MAX_FOCUS_BOOKS || noResourcesUsed}
                        onClick={() => onShowAddBookModal('primaryFocus')}
                      >
                        + Add Resource
                      </Button>
                    </Stack>
                    <FocusAndBooks
                      // Can only add a book once a primary focus has been selected.
                      books={selectedPrimaryFocusBooks}
                      onRemoveBook={onRemovePrimaryFocusBook}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Card>
            <Card sx={{ flex: 1 }}>
              <Grid container spacing={4}>
                <Grid size={{ xs: 12, md: 4 }}>
                  <Stack gap={3} pt={'6px'}>
                    <HootTypography isPII={false} variant="titlesmall">
                      Secondary Focus
                    </HootTypography>
                    <Select
                      label="Secondary Focus (Optional)"
                      name="secondaryFocus"
                      value={secondaryFocus}
                      onChange={onSecondaryFocusChanged}
                      fullWidth
                      // Can only add a book once a secondary focus has been selected.
                      disabled={!primaryFocus}
                    >
                      <option value="">Select</option>
                      {focusOptions
                        .filter((f) => primaryFocus && !(focusesNotRequiringUnit.includes(primaryFocus) && f.value === primaryFocus))
                        .map((option) => (
                          <option value={option.value} key={`secondary-focus-option-${option.value}`}>
                            {option.label}
                          </option>
                        ))}
                    </Select>
                    {!!secondaryFocus && focusesRequiringUnit.includes(secondaryFocus) ? (
                      <Select
                        label="Unit"
                        name="primaryFocusUnit"
                        value={secondaryFocusUnit}
                        onChange={onSecondaryFocusUnitChanged}
                        required
                        fullWidth
                      >
                        <option value="">Select</option>
                        {focusUnitOptions(secondaryFocus)
                          .filter((f) => (primaryFocus === secondaryFocus && f.value !== primaryFocusUnit) || primaryFocus !== secondaryFocus)
                          .map((option) => (
                            <option value={option.value} key={`secondary-focus-unit-option-${option.value}`}>
                              {option.label}
                            </option>
                          ))}
                      </Select>
                    ) : null}
                    {isAssessmentSuiteV2Enabled && !!secondaryFocusUnit && unitsRequiringSkill.includes(secondaryFocusUnit) ? (
                      <Select
                        label="Skill"
                        name="primaryFocusSkill"
                        value={secondaryFocusSkill}
                        onChange={onSecondaryFocusSkillChanged}
                        required
                        fullWidth
                      >
                        <option value="">Select</option>
                        {focusSkillOptions(secondaryFocusUnit)
                          .filter(
                            (f) =>
                              (primaryFocusUnit === secondaryFocusUnit && f.value !== primaryFocusSkill) || primaryFocusUnit !== secondaryFocusUnit,
                          )
                          .map((option) => (
                            <option value={option.value} key={`secondary-focus-skill-option-${option.value}`}>
                              {option.label}
                            </option>
                          ))}
                      </Select>
                    ) : null}
                  </Stack>
                </Grid>
                <Grid size={{ xs: 12, md: 8 }}>
                  <Stack width={'100%'} gap={2}>
                    <Stack direction="row" alignItems="center" justifyContent={'space-between'} gap={1}>
                      <HootTypography isPII={false} variant="titlesmall">
                        Secondary Resources Used
                      </HootTypography>
                      <Button
                        variant="contained"
                        size="small"
                        disabled={!secondaryFocus || selectedSecondaryFocusBooks.length >= MAX_FOCUS_BOOKS || noResourcesUsed}
                        onClick={() => onShowAddBookModal('secondaryFocus')}
                        color="success.60"
                      >
                        + Add Resource
                      </Button>
                    </Stack>
                    <FocusAndBooks
                      // Can only add a book once a primary focus has been selected.
                      books={selectedSecondaryFocusBooks}
                      onRemoveBook={onRemoveSecondaryFocusBook}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Card>
            {noBooksSelected ? (
              // Only show checkbox if no books have been added
              <Grid container size={{ xs: 12, md: 5 }}>
                <Checkbox label="No resources were used in this lesson." checked={noResourcesUsed} onClick={onNoResourceUsedChange} />
              </Grid>
            ) : null}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ boxShadow: hootTokens.elevation.elevationMinus1 }}>
        <Button variant="outlined" onClick={goToPreviousStep}>
          Go Back
        </Button>
        <Button variant="contained" onClick={submitInstructionalFocusData} disabled={!canGoToNextStep()}>
          Next
        </Button>
      </DialogActions>
      <InstructionalFocusBookPicker
        show={!!showAddBookModal}
        onApply={onAddBook}
        onDismiss={onDismissAddBookModal}
        existingBookIds={
          showAddBookModal === 'primaryFocus'
            ? selectedPrimaryFocusBooks.map((x) => x.bookId)
            : showAddBookModal === 'secondaryFocus'
              ? selectedSecondaryFocusBooks.map((x) => x.bookId)
              : []
        }
        scheduledLessonId={lessonToReview?.lessonId}
      />
    </>
  );
};

interface FocusAndBooksProps {
  books: LessonReviewBook[];
  onRemoveBook: (bookId: string) => void;
}

interface InstructionalFocusBookTableRow {
  coverImageUrl: ReactNode;
  title: string;
  action: ReactNode;
}

const FocusAndBooks = (props: FocusAndBooksProps) => {
  const { books, onRemoveBook } = props;

  const [sortOrder, setSortOrder] = useState<OrderBy>(OrderBy.Asc);

  const headers: HeaderData<InstructionalFocusBookTableRow>[] = [
    { name: '', property: 'coverImageUrl', isSortable: false, width: '20%' },
    { name: 'Title', property: 'title', isSortable: true, width: '60%' },
    { name: 'Action', property: 'action', isSortable: false, width: '20%' },
  ];

  const sortedData: InstructionalFocusBookTableRow[] = books
    .map((book) => ({
      coverImageUrl: (
        <img
          src={book.coverImageUrl}
          alt={book.title}
          style={{
            objectFit: 'contain',
            maxWidth: '50px',
            width: '100%',
            borderRadius: '8px',
          }}
        />
      ),
      title: book.title,
      action: (
        <Button variant="outlined" color="error" size="small" onClick={() => onRemoveBook(book.bookId)}>
          Remove
        </Button>
      ),
    }))
    .sort((a, b) => {
      if (sortOrder === OrderBy.Asc) {
        return a.title.localeCompare(b.title);
      } else {
        return b.title.localeCompare(a.title);
      }
    });

  return books.length ? (
    <TableV2
      isPaginated={false}
      isSortable
      data={sortedData}
      headers={headers}
      onSortBy={() => {
        setSortOrder((sortOrder) => (sortOrder === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc));
      }}
      sortOrder={sortOrder}
      sortBy={'title'}
    />
  ) : (
    <Card sx={{ background: hootTokens.palette.primary[195], display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <HootTypography isPII={false}>None</HootTypography>
    </Card>
  );
};

export default LessonReviewInstructionalFocusStep;
