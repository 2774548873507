import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { InvoicePeriodStatus, TeacherInvoiceStatus } from '@hoot/ui/pages/v2/teacher/invoicing/enums';

interface InvoiceTeacherDetails {
  name: string;
  address: string | null;
  phoneNumber: string | null;
}

export interface InvoiceDetailsResponse {
  invoiceId: string;
  invoiceNumber: string;
  status: TeacherInvoiceStatus;
  invoiceMonth: string;
  invoicePeriodStart: string;
  invoicePeriodEnd: string;
  daysLeftInPeriod: number;
  isInvoicePeriodPast: boolean;
  invoiceLastSubmittedAt: string;
  invoicePeriodStatus: InvoicePeriodStatus;
  teacherDetails: InvoiceTeacherDetails;
  isInvoicePartiallySubmitted: boolean;
}

async function getInvoiceDetails(invoiceId: string) {
  const { data } = await axios.get<InvoiceDetailsResponse>(`${config.apiUrl}/public/teacher-invoicing/${invoiceId}/details`);
  return data;
}

export function useGetInvoiceDetails(invoiceId: string, options?: Omit<UseQueryOptions<InvoiceDetailsResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetInvoiceDetails, invoiceId], () => getInvoiceDetails(invoiceId), options);
}
