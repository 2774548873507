import { SvgIcon, SvgIconProps } from '@mui/material';

const BellNotificationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.72281 18.0002H17.2772C19.1056 18.0002 20.1492 16.1611 19.0522 14.8724C18.5666 14.3018 18.2673 13.6251 18.1867 12.9155L18.0227 11.47C17.8512 11.4898 17.6767 11.5 17.4999 11.5C15.0147 11.5 12.9999 9.48528 12.9999 7C12.9999 6.02913 13.3074 5.13007 13.8303 4.39487C13.8102 4.3878 13.7901 4.38081 13.7699 4.37391V4.27019C13.7699 3.01653 12.7537 2.00024 11.5 2.00024C10.2463 2.00024 9.23005 3.01653 9.23005 4.27019V4.37391C7.08766 5.10744 5.5016 6.85088 5.26046 8.97543L4.81328 12.9155C4.73274 13.6251 4.43344 14.3018 3.94779 14.8724C2.85076 16.1611 3.89443 18.0002 5.72281 18.0002ZM20.5 7C20.5 8.65685 19.1569 10 17.5 10C15.8431 10 14.5 8.65685 14.5 7C14.5 5.34315 15.8431 4 17.5 4C19.1569 4 20.5 5.34315 20.5 7ZM11.5 22.0002C12.8565 22.0002 14.0147 21.1995 14.4721 20.0718C14.4912 20.0247 14.5 19.9742 14.5 19.9235C14.5 19.6896 14.3104 19.5 14.0765 19.5H8.92349C8.6896 19.5 8.5 19.6896 8.5 19.9235C8.5 19.9742 8.50883 20.0247 8.5279 20.0718C8.98528 21.1995 10.1435 22.0002 11.5 22.0002Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default BellNotificationIcon;
