import { SvgIcon, SvgIconProps } from '@mui/material';

const DistrictIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        stroke={props.htmlColor ?? 'currentColor'}
        d="M6.5 16.0001C6.5 16.8285 5.82843 17.5001 5 17.5001C4.17157 17.5001 3.5 16.8285 3.5 16.0001C3.5 15.1717 4.17157 14.5001 5 14.5001C5.82843 14.5001 6.5 15.1717 6.5 16.0001Z"
        fill="#28303F"
      />
      <path
        stroke={props.htmlColor ?? 'currentColor'}
        d="M6 5.00012C6 5.5524 5.55228 6.00012 5 6.00012C4.44772 6.00012 4 5.5524 4 5.00012C4 4.44784 4.44772 4.00012 5 4.00012C5.55228 4.00012 6 4.44784 6 5.00012Z"
        fill="#28303F"
      />
      <path
        stroke={props.htmlColor ?? 'currentColor'}
        d="M20 5.00012C20 5.5524 19.5523 6.00012 19 6.00012C18.4477 6.00012 18 5.5524 18 5.00012C18 4.44784 18.4477 4.00012 19 4.00012C19.5523 4.00012 20 4.44784 20 5.00012Z"
        fill="#28303F"
      />
      <path
        stroke={props.htmlColor ?? 'currentColor'}
        d="M9 16.0001C9 18.2092 7 22.0001 5 22.0001C3 22.0001 1 18.2092 1 16.0001C1 13.791 2.79086 12.0001 5 12.0001C7.20914 12.0001 9 13.791 9 16.0001Z"
        strokeWidth="1.5"
      />
      <path
        stroke={props.htmlColor ?? 'currentColor'}
        d="M22 5.00012C22 6.12316 21.0541 7.56151 20.2246 8.60696C19.574 9.42682 18.3837 9.47101 17.7029 8.67608C16.8918 7.72892 16 6.41371 16 5.20926C16 3.00012 17.3431 2.00012 19 2.00012C20.6569 2.00012 22 3.00012 22 5.00012Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        stroke={props.htmlColor ?? 'currentColor'}
        d="M8 5.00012C8 6.12316 7.05408 7.56151 6.22455 8.60696C5.57402 9.42682 4.38368 9.47101 3.70291 8.67608C2.89178 7.72892 2 6.41371 2 5.20926C2 3.00012 3.34315 2.00012 5 2.00012C6.65685 2.00012 8 3.00012 8 5.00012Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        stroke={props.htmlColor ?? 'currentColor'}
        d="M11 18.0001L17.5 11.5001"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0.5 3"
      />
      <path
        stroke={props.htmlColor ?? 'currentColor'}
        d="M9 9.50012H15"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0.5 3"
      />
    </SvgIcon>
  );
};

export default DistrictIcon;
