import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useSelector } from 'react-redux';
import { UserProfileType } from '@hoot/models/api/user';
import { RootState } from '@hoot/redux/store';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import Page from '@hoot/ui/components/v2/core/Page';
import NoStudentProfiles from './upcoming-lessons/NoStudentProfiles';
import ParentUpcomingLessons from './upcoming-lessons/ParentUpcomingLessons';

export function ParentDashboard() {
  const profiles = useSelector((state: RootState) => state.profile.profiles);
  const studentProfiles = profiles.filter((p) => p.type === UserProfileType.Student);
  const hasActiveStudentProfile = !!studentProfiles.length;

  return (
    <>
      <SubHeader
        title={{
          label: 'Dashboard',
          isPII: false,
        }}
      />

      <Page pageTitle="Dashboard | Hoot Reading">
        <Grid container spacing={2}>
          <Grid size={{ md: 4, xs: 12 }}>
            <Card
              title={
                <Stack direction="row" alignItems="center" gap="8px">
                  <Icon name="hoot_wheel" />
                  <HootTypography isPII={false} variant="titlemedium">
                    Welcome to your Hoot Account
                  </HootTypography>
                </Stack>
              }
            >
              <HootTypography isPII={false} variant="bodylarge">
                Here you will find lesson schedules, Hoot App settings, and access to live chat with Hoot Reading's Support Teams.
                <br />
                <br />
                Questions about Hoot or joining lessons?
              </HootTypography>
              <Button
                variant="outlined"
                size="small"
                sx={{ marginTop: '16px' }}
                onClick={() => window.open('https://hootreading.zendesk.com/hc/en-us', '_blank')}
              >
                Visit the Help Center
              </Button>
            </Card>
          </Grid>
          <Grid size={{ md: 8, xs: 12 }}>{hasActiveStudentProfile ? <ParentUpcomingLessons /> : <NoStudentProfiles />}</Grid>
        </Grid>
      </Page>
    </>
  );
}
