import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReaderContext, getCorrectedPageId, useReaders } from '@hoot/redux/reducers/readingSlice';
import TeacherReaderAppBar from '@hoot/ui/components/v2/core/TeacherReaderAppBar';
import { TeacherSandboxReaderPageQueryParams } from '@hoot/ui/pages/v2/teacher/library/reader/TeacherSandboxReaderPage';
import useTeacherOutOfLessonReaderController from '@hoot/ui/pages/v2/teacher/library/reader/useTeacherOutOfLessonReaderController';

const readerContext = ReaderContext.SandboxReader;

const TeacherSandboxReaderAppbar = () => {
  const controller = useTeacherOutOfLessonReaderController({ readerContext });

  const [searchParams, setSearchParams] = useSearchParams();

  const reader = useReaders().sandboxReader;
  const { book, pageIndex, isDoublePage } = reader;

  const _onToggleDoublePage = () => {
    if (!book) {
      return;
    }
    const newSearchParams = new URLSearchParams(searchParams);

    const updatedIsDoublePage = !isDoublePage;
    newSearchParams.set(TeacherSandboxReaderPageQueryParams.DoublePage, Boolean(updatedIsDoublePage).toString());

    // If we're going into double-page mode AND the book is "standard double page", then we may need to update the
    // current page index so that we keep the correct pair of pages visible.
    if (updatedIsDoublePage) {
      const updatedPageId = getCorrectedPageId(book, updatedIsDoublePage, pageIndex);
      newSearchParams.set(TeacherSandboxReaderPageQueryParams.PageId, updatedPageId);
    }
    setSearchParams(newSearchParams, { replace: true });
  };

  return (
    <TeacherReaderAppBar
      readerContext={readerContext}
      onToggleDoublePage={_onToggleDoublePage}
      onZoomAmountChange={controller.onZoomAmountChange}
      onZoomOffsetChange={controller.onZoomOffsetChange}
      onResetZoom={controller.onResetZoom}
      onCloseBook={controller.onCloseBook}
      onToggleFavouriteBook={controller.onToggleFavouriteBook}
    />
  );
};

export default TeacherSandboxReaderAppbar;
