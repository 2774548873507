import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { NotificationMessage, NotificationType } from '@hoot/events/messages/notification-message';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/models/api/pagination';
import { QueryKey } from '../queryKeys';

export interface GetNotificationsQuery extends PaginatableQuery {
  search?: string;
  type?: NotificationType;
}

export default function useGetNotifications(
  query: GetNotificationsQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<NotificationMessage>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetNotifications, query],
    async () => {
      return (
        await axios.get<GenericPaginatedResponse<NotificationMessage>>(`${config.apiUrl}/reader/notifications`, {
          params: query,
        })
      ).data;
    },
    options,
  );
}
