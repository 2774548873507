import { Stack } from '@mui/material';
import { isIOS } from 'react-device-detect';
import useProfile from '@hoot/hooks/useProfile';
import { useReaders } from '@hoot/redux/reducers/readingSlice';
import PlainIconButton from '@hoot/ui/components/v2/core/PlainIconButton';
import DoubleIconLottieButton from '@hoot/ui/components/v2/lottie/DoubleIconLottieButton';
import { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieButton from '@hoot/ui/components/v2/lottie/LottieButton';
import LottieToggleButton from '@hoot/ui/components/v2/lottie/LottieToggleButton';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface StudentLibraryReaderLeftPaneProps {
  toggleFavouriteBook: (bookId: string) => void;
  toggleFullscreen: () => void;
  toggleDoublePage: () => void;
  onGoToFirstPage: () => void;
  closeBook: () => void;
}

const StudentLibraryReaderLeftPane = (props: StudentLibraryReaderLeftPaneProps) => {
  const { toggleFavouriteBook, toggleFullscreen, toggleDoublePage, onGoToFirstPage, closeBook } = props;

  const { studentSpecificLibraryReader } = useReaders();
  const { book, pageIndex, isDoublePage } = studentSpecificLibraryReader;

  const { profile } = useProfile();
  const isFavouriteBook = book?.shelf[profile!.id] ?? false;

  const isDoublePagedDisabled = !book || pageIndex === 0 || !book.isDoublePageEnabled;

  return (
    <Stack
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        width: '184px',
        height: '100%',
        background: hootTokens.palette.white,
        boxShadow: '1px 0px 3px 0px rgba(0, 0, 0, 0.30), 4px 0px 8px 3px rgba(0, 0, 0, 0.15)',
        alignItems: 'center',
        gap: 5,
        padding: 5,
      }}
    >
      <LottieButton
        lottieFile={LottieFile.ErrorCross}
        tooltipLabel="Close book"
        aria-label="Close book"
        onClick={closeBook}
        sx={{ width: 104, height: 104 }}
      />
      <LottieButton
        lottieFile={LottieFile.RestartArrow}
        tooltipLabel="Start from beginning"
        aria-label="Start from beginning"
        onClick={onGoToFirstPage}
        disabled={pageIndex === 0}
        LottieProps={{ style: { transform: 'scale(-1, 1)', height: '64px', width: '64px' } }}
        sx={{ width: 104, height: 104 }}
      />
      {!isDoublePage ? (
        <DoubleIconLottieButton
          lottieFile1={LottieFile.SinglePage}
          lottieFile2={LottieFile.SinglePage}
          tooltipLabel="Show double page"
          aria-label="Show double-Page"
          onClick={toggleDoublePage}
          disabled={isDoublePagedDisabled}
          sx={{ paddingX: '8px', width: 104, height: 104 }}
          LottieProps2={{ style: { width: 48, height: 64 } }}
          LottieProps1={{ style: { transform: 'scale(-1, 1)', width: 48, height: 64 } }}
        />
      ) : (
        <LottieButton
          lottieFile={LottieFile.SinglePage}
          tooltipLabel="Show single page"
          aria-label="Show single page"
          onClick={toggleDoublePage}
          disabled={!book || pageIndex === 0 || pageIndex === book.pages.length - 1}
          sx={{ width: 104, height: 104 }}
        />
      )}
      <LottieToggleButton
        isSelected={isFavouriteBook}
        lottieFile={LottieFile.Heart}
        tooltipLabel={isFavouriteBook ? 'Remove from favorites' : 'Add to favorites'}
        aria-label={isFavouriteBook ? 'Remove from favorites' : 'Add to favorites'}
        disabled={!book}
        onClick={() => toggleFavouriteBook(book!.id)}
        sx={{ width: 104, height: 104 }}
      />
      {!isIOS && (
        <PlainIconButton icon="enter_full_screen_filled" tooltipLabel="Toggle Fullscreen" onClick={toggleFullscreen} sx={{ width: '104px' }} />
      )}
    </Stack>
  );
};

export default StudentLibraryReaderLeftPane;
