import { SvgIcon, SvgIconProps } from '@mui/material';

const FavouriteIcon = (props: SvgIconProps & { isActive?: boolean }) => {
  const { ...rest } = props;
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M17 6.63896C18.1045 6.63896 19 7.53439 19 8.63896M12 5.8415L12.6851 5.13897C14.816 2.95368 18.2709 2.95368 20.4018 5.13896C22.4755 7.26557 22.5392 10.6928 20.5461 12.8989L14.8197 19.2371C13.2984 20.921 10.7015 20.921 9.18026 19.2371L3.45393 12.8989C1.46078 10.6928 1.5245 7.26559 3.5982 5.13898C5.72912 2.95369 9.18404 2.95369 11.315 5.13898L12 5.8415Z"
        fill="none"
        stroke={props.htmlColor ?? '#28303F'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default FavouriteIcon;
