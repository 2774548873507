import { Stack } from '@mui/material';
import { useState } from 'react';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import useCheckStudentInstructionalLibrary from '@hoot/hooks/api/library/useCheckStudentInstructionalLibrary';
import { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import { ShelfType } from '@hoot/models/api/enums/shelf-type-enum';
import { DEFAULT_PAGE, PaginationMode } from '@hoot/redux/reducers/librarySlice';
import { Chip } from '@hoot/ui/components/v2/core/Chip';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { HeaderData } from '@hoot/ui/components/v2/core/Table';
import { filterCount } from '@hoot/ui/pages/v2/teacher/library/TeacherLibraryFilterBar';
import TeacherLibraryFilterDrawer from '@hoot/ui/pages/v2/teacher/library/TeacherLibraryFilterDrawer';
import BookPickerModal, { BookSelectorTableRow } from './BookPickerModal';

export interface LessonPlanBookPickerProps {
  show: boolean;
  onDismiss: () => void;
  onApply: (selectedBook: BookSearch) => void;
  isLoading?: boolean;
  existingBookIds: string[];
  studentProfileId?: string;
}

const PAGE_SIZE = 5;

const LessonPlanBookPicker = (props: LessonPlanBookPickerProps) => {
  const { show, onDismiss, onApply, isLoading = false, existingBookIds, studentProfileId } = props;

  const libraryFilters: QueryLibraryV2 = {
    shelfType: ShelfType.AllBooks,
    page: DEFAULT_PAGE,
    pageSize: PAGE_SIZE,
    title: '',
    studentProfileId: studentProfileId,
    isInstructionalLibrary: true,
  };

  const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
  const [libraryQuery, setLibraryQuery] = useState<QueryLibraryV2>(libraryFilters);
  const numberOfFilters = filterCount(libraryQuery);

  const { data: doesStudentHaveInstructionalLibrary = true } = useCheckStudentInstructionalLibrary(studentProfileId ?? '', {
    onSuccess: (data) => {
      setLibraryQuery({
        ...libraryFilters,
        isInstructionalLibrary: data,
      });
    },
  });

  const headers: HeaderData<BookSelectorTableRow>[] = [
    { name: 'Cover', property: 'cover', isSortable: false },
    { name: 'Book Title', property: 'title', isSortable: true },
    { name: 'Book Level', property: 'bookLevel', isSortable: true },
    { name: 'Instructional Unit', property: 'instructionalUnit', isSortable: false },
    { name: 'Prioritized Skill', property: 'filterLevelPrioritizedSkill', isSortable: false },
    { name: 'Resource Progress', property: 'resourceProgress', isSortable: false },
    { name: 'Resource State', property: 'resourceState', isSortable: false },
  ];

  const applyFilters = (updatedQuery: Partial<QueryLibraryV2>) => {
    const newQuery: QueryLibraryV2 = { ...libraryFilters, ...updatedQuery };
    setLibraryQuery(newQuery);
  };

  const clearFilters = () => {
    setLibraryQuery({
      ...libraryFilters,
      title: libraryQuery.title,
      page: 1,
      isInstructionalLibrary: libraryQuery.isInstructionalLibrary,
    });
  };

  const clearInstructionalLibraryFilter = () => {
    setLibraryQuery({
      ...libraryQuery,
      title: libraryQuery.title,
      page: 1,
      isInstructionalLibrary: undefined,
    });
  };

  const FilterChips = () => {
    return (
      <Stack direction="row" gap={2}>
        {libraryQuery.isInstructionalLibrary ? (
          <Chip label={'Instructional Library: True'} onDelete={clearInstructionalLibraryFilter} deleteIcon={<Icon name="close" />} />
        ) : null}
        {numberOfFilters > 0 ? (
          <Chip label={`Active Filters: ${numberOfFilters}`} onDelete={clearFilters} deleteIcon={<Icon name="close" />} />
        ) : null}
      </Stack>
    );
  };

  return (
    <BookPickerModal
      key={`lesson-plan-book-picker-${show}`}
      show={show}
      onApply={onApply}
      onDismiss={onDismiss}
      libraryQuery={libraryQuery}
      setLibraryQuery={setLibraryQuery}
      headers={headers}
      isLoading={isLoading}
      existingBookIds={existingBookIds}
      filter={
        <TeacherLibraryFilterDrawer
          isOpen={showFilterDialog}
          onCloseFilterDrawer={() => setShowFilterDialog(false)}
          onLibraryQueryChanged={applyFilters}
          query={libraryQuery}
          doesStudentHaveInstructionalLibrary={doesStudentHaveInstructionalLibrary}
          isInLessonReview={true}
          paginationMode={PaginationMode.Replace}
        />
      }
      setShowFilterDialog={setShowFilterDialog}
      filterChips={<FilterChips />}
      showAssessmentReminder={true}
    />
  );
};

export default LessonPlanBookPicker;
