import { SvgIcon, SvgIconProps } from '@mui/material';

const TypeLettersIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M22 14C22 16.2091 20.2091 18 18 18C15.7909 18 14 16.2091 14 14C14 11.7909 15.7909 10 18 10C20.2091 10 22 11.7909 22 14Z"
        stroke={props.htmlColor ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path d="M4 14H10" stroke={props.htmlColor ?? 'currentColor'} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M2 18L7 6L12 18" stroke={props.htmlColor ?? 'currentColor'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 10V18" stroke={props.htmlColor ?? 'currentColor'} strokeWidth="1.5" strokeLinecap="round" />
    </SvgIcon>
  );
};

export default TypeLettersIcon;
