import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Paper, Skeleton, Stack, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { EventType } from '@hoot/events/eventType';
import { ScheduledLessonsMessageRequest } from '@hoot/events/messages/scheduled-lessons-message-request';
import { ScheduledLessonsMessageResponse } from '@hoot/events/messages/scheduled-lessons-message-response';
import { useEmit } from '@hoot/hooks/useEmit';
import { useSocketSubscription } from '@hoot/hooks/useSocketSubscription';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import Card from '@hoot/ui/components/v2/core/Card';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import UpcomingLesson from '@hoot/ui/pages/v2/teacher/dashboard/upcoming-lessons/UpcomingLesson';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const TeacherUpcomingLessons = () => {
  const refreshLessonsQuery: ScheduledLessonsMessageRequest = {
    query: {
      fromDate: DateTime.now().startOf('day').toMillis(),
      toDate: DateTime.now().endOf('day').toMillis(),
      status: [
        // We only want the lessons that are upcoming and active.
        ScheduledLessonStatus.Scheduled,
        ScheduledLessonStatus.Open,
        ScheduledLessonStatus.OpenWaitingForTeacher,
        ScheduledLessonStatus.OpenWaitingForStudent,
        ScheduledLessonStatus.InProgress,
      ],
    },
  };

  const refreshLessonsPublisher = useEmit<ScheduledLessonsMessageRequest>(EventType.ScheduledLessons, refreshLessonsQuery);

  // Refresh the scheduled lessons when the API tells us to.
  useSocketSubscription<void>(EventType.RefreshScheduledLessons, {
    onEventReceived: () => {
      refreshLessonsPublisher.queueMessage(refreshLessonsQuery);
    },
  });

  const { data: scheduledLessonsResponse, isLoaded } = useSocketSubscription<ScheduledLessonsMessageResponse>(EventType.ScheduledLessons);
  const lessons = scheduledLessonsResponse?.lessons ?? [];

  const viewState: ViewStateEnum = !isLoaded ? ViewStateEnum.Loading : (lessons.length ?? 0) === 0 ? ViewStateEnum.EmptyState : ViewStateEnum.Results;

  return (
    <Card
      isLoading={viewState === ViewStateEnum.Loading}
      title={
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <span>Today's Upcoming Lessons</span>
          <Tooltip title="View My Schedule">
            <Link to={routesDictionary.schedule.url}>
              <IconButton>
                <Icon name="calendar" />
              </IconButton>
            </Link>
          </Tooltip>
        </Stack>
      }
      sx={{
        position: { md: 'sticky' },
        top: { md: '24px' },
      }}
    >
      <ViewState state={viewState} loadingContent={<SkeletonItems />}>
        <Stack gap={2}>
          {lessons.map((lesson) => (
            <UpcomingLesson key={lesson.lessonId} lesson={lesson} />
          ))}
        </Stack>
      </ViewState>
    </Card>
  );
};

const SkeletonItems = () => (
  <Stack gap={2}>
    {[...Array(2)].map((_, index) => (
      <Paper key={`skeleton-${index}`} sx={{ padding: 2 }}>
        <Stack gap={2}>
          <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
            <Skeleton variant="text" sx={{ width: '160px', ...hootTokens.text.titlemedium }} />
            <Skeleton variant="rectangular" sx={{ width: '49px', height: '34px', borderRadius: '8px' }} />
          </Stack>
          <Skeleton variant="rectangular" sx={{ height: '58px' }} />
          <Skeleton variant="rectangular" sx={{ height: '58px' }} />
          <Skeleton variant="rectangular" sx={{ height: '60px' }} />
        </Stack>
      </Paper>
    ))}
  </Stack>
);

export default TeacherUpcomingLessons;
