import { CookieConsentStatus } from '@hoot/models/api/auth';
import ThirdPartyTelemetry from '@hoot/telemetry/ThirdPartyTelemetry';
import { useCookieConsent } from '@hoot/ui/context/CookieConsentContext';
import { useAuth } from '../ui/context/AuthContext';
import PublicRouter from './public/PublicRouter';
import SecureRouter from './secure/SecureRouter';

const Router = () => {
  const { isAuthenticated } = useAuth();
  const cookieConsent = useCookieConsent();

  return (
    <>
      {cookieConsent.cookieConsent === CookieConsentStatus.AllowAll && <ThirdPartyTelemetry />}
      {/* Don't render any routes until we know if we're authenticated. */}
      {isAuthenticated === undefined ? null : isAuthenticated ? <SecureRouter /> : <PublicRouter />}
    </>
  );
};

export default Router;
