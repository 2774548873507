import { SvgIcon, SvgIconProps } from '@mui/material';

const ReadIcon = (props: SvgIconProps) => {
  const { ...rest } = props;

  return (
    <SvgIcon width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" inheritViewBox {...rest}>
      <circle cx="4" cy="4.00024" r="4" fill="black" />
    </SvgIcon>
  );
};

export default ReadIcon;
