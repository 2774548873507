import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Outlet } from 'react-router-dom';
import FlashMessage from '@hoot/ui/components/v2/FlashMessage';
import { CookieConsentDialog } from '@hoot/ui/components/v2/dialogs/CookieConsentDialog';
import ScrollToTopProvider from '@hoot/ui/context/ScrollToTopContext';
import { UpcomingLessonsProvider } from '@hoot/ui/context/UpcomingLessonsProvider';
import useSentry from '../../../../hooks/useSentry';
import GetReadyContextWrapper from '../../../context/GetReadyContext';
import hootThemeV2 from '../../../theme/v2';

export const ProvidersLayoutV2 = () => {
  useSentry();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <ThemeProvider theme={hootThemeV2}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <ScrollToTopProvider scrollToTop={scrollToTop}>
          <UpcomingLessonsProvider>
            <GetReadyContextWrapper>
              <Outlet />
              <CookieConsentDialog />
              <FlashMessage />
            </GetReadyContextWrapper>
          </UpcomingLessonsProvider>
        </ScrollToTopProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
