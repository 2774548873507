import { DailyEventObjectFatalError, DailyFatalErrorType } from '@daily-co/daily-js';
import { Link } from '@mui/material';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import useProfile from '@hoot/hooks/useProfile';
import { UserProfileType } from '@hoot/models/api/user';
import Lottie, { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import HootTypography from '../core/HootTypography';
import BasicAlertDialog from './BasicAlertDialog';

export interface ErrorDetails {
  userType: UserProfileType;
  err: DailyEventObjectFatalError;
}

interface DailyErrorDialogProps {
  errorDetails: ErrorDetails | null;
  isOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DailyErrorDialog = (props: DailyErrorDialogProps) => {
  const { errorDetails, isOpen, setDialogOpen } = props;

  const { isStudent } = useProfile();
  const navigate = useNavigate();

  const handleClose = () => {
    setDialogOpen(false);
    navigate('/');
  };

  const errorType = errorDetails?.err?.error?.type as DailyFatalErrorType;

  const getTitle = () => {
    switch (errorType) {
      case 'connection-error':
        return 'Your Connection Has Been Interrupted';
      case 'ejected':
      case 'exp-room':
      case 'exp-token':
      case 'end-of-life':
        return 'The Lesson Has Ended';
      case 'nbf-room':
      case 'nbf-token':
        return 'Unable to Join Lesson';
      case 'not-allowed':
        return 'Technical Difficulties';
      default:
        return 'Technical Difficulties';
    }
  };

  const getDescription = () => {
    switch (errorType) {
      case 'connection-error':
        return "Please check your Internet connection, refresh your browser, and try to rejoin the lesson. If you're unable to join, please contact:";
      case 'ejected':
      case 'exp-room':
      case 'exp-token':
      case 'end-of-life':
        return 'This lesson has already ended. For help, please contact:';
      case 'nbf-room':
      case 'nbf-token':
        return 'Cannot connect before the lessons start time. For help, please contact:';
      case 'not-allowed':
        return 'Something went wrong... For help, please contact:';
      default:
        return 'Something went wrong... For help, please contact:';
    }
  };

  return (
    <BasicAlertDialog
      title={getTitle()}
      show={isOpen}
      onDismiss={handleClose}
      maxWidth="sm"
      content={
        <Stack alignItems="center" gap={2}>
          {isStudent && (
            <Lottie
              lottieFile={LottieFile.BadNetwork}
              loop={true}
              play={true}
              style={{
                width: '200px',
                height: '200px',
              }}
            />
          )}

          <HootTypography variant="bodysmall" isPII={false}>
            Error Type: '{errorType}'
          </HootTypography>

          <HootTypography variant="bodylarge" textAlign="center" isPII={false}>
            {getDescription()}
          </HootTypography>

          <Link href="mailto:help@hootreading.com" variant="bodylarge" textAlign="center">
            help@hootreading.com
          </Link>
        </Stack>
      }
      primaryAction={{
        label: 'Close',
        onClick: handleClose,
        props: {
          variant: 'contained',
        },
      }}
    />
  );
};

export default DailyErrorDialog;
