import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface TopicsResponse {
  categorizedTopics: CategorizedTopics[];
}

interface CategorizedTopics {
  parentCategory: string;
  listItems: {
    label: string;
    value: string;
  }[];
}

async function getTopics(): Promise<TopicsResponse> {
  const { data } = await axios.get<TopicsResponse>(`${config.apiUrl}/library/topics`);
  return data;
}

export default function useGetTopics(options?: Omit<UseQueryOptions<TopicsResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetTopics], () => getTopics(), options);
}
