import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { FormativeAssessmentType } from '@hoot/models/api/enums/lesson-review-enums';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface FormativeAssessmentBook {
  bookId: string;
  title: string;
  coverImageUrl: string | undefined;
  formativeAssessmentWords: string[];
  wordCount: number | null;
  formativeAssessmentType: FormativeAssessmentType;
}

export interface GetFormativeAssessmentBookDetailsResponse {
  formativeAssessmentBook: FormativeAssessmentBook;
}

async function getFormativeAssessmentBookDetails(bookId: string): Promise<GetFormativeAssessmentBookDetailsResponse> {
  const { data } = await axios.get<GetFormativeAssessmentBookDetailsResponse>(
    `${config.apiUrl}/public/lesson-review/formative-assessment/book-details/${bookId}`,
  );
  return data;
}

export default function useGetFormativeAssessmentBookDetails(
  bookId: string,
  options?: Omit<UseQueryOptions<GetFormativeAssessmentBookDetailsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetFormativeAssessmentBookDetails, bookId], () => getFormativeAssessmentBookDetails(bookId), options);
}
