import Grid from '@mui/material/Grid2';
import { useParams } from 'react-router-dom';
import useDistrictRepresentativeGetProduct from '@hoot/hooks/api/district-rep/useDistrictRepresentativeProduct';
import { DistrictRepresentativeEnrolmentSearchType } from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeEnrolments';
import Page from '@hoot/ui/components/v2/core/Page';
import HFSEnrolments from '@hoot/ui/pages/v2/district-rep/enrolments/HFSEnrolments';
import ProductDetailsCard from '@hoot/ui/pages/v2/district-rep/enrolments/product/HFSProductDetailsCard';

const DistrictRepProductPage = (props: { searchType: DistrictRepresentativeEnrolmentSearchType }) => {
  const { productId } = useParams();

  const { data, isFetching } = useDistrictRepresentativeGetProduct(productId!, {
    enabled: !!productId,
  });

  return (
    <Page pageTitle="District Representative Product Details | Hoot Reading">
      <Grid container spacing={2}>
        <Grid size={{ sm: 12, md: 4 }}>
          <ProductDetailsCard product={data} isLoading={isFetching} />
        </Grid>
        <Grid size={{ sm: 12, md: 8 }}>
          <HFSEnrolments searchType={props.searchType} />
        </Grid>
      </Grid>
    </Page>
  );
};

export default DistrictRepProductPage;
