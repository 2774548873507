import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface GenresResponse {
  genres: GenreOption[];
}

interface GenreOption {
  id: string;
  name: string;
}

async function getGenres(): Promise<GenresResponse> {
  const { data } = await axios.get<GenresResponse>(`${config.apiUrl}/library/genres`);
  return data;
}

export default function useGetGenres(options?: Omit<UseQueryOptions<GenresResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetGenres], () => getGenres(), options);
}
