import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import Lottie, { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import { useStudentJoinLessonCtx } from '@hoot/ui/context/StudentJoinLessonContext';
import { useUpcomingLessons } from '@hoot/ui/context/UpcomingLessonsProvider';
import { Button } from '../core/Button';

const CountdownToLesson = () => {
  const { openLesson } = useUpcomingLessons();
  const { handleSetJoinDialog, canDialogBeMinimized } = useStudentJoinLessonCtx();

  const [timer, setTimer] = useState<number | null>(null);

  // Controls the countdown timer interval.
  useEffect(() => {
    if (!openLesson) return;

    const start = openLesson?.startTime;

    const interval = setInterval(() => {
      const now = DateTime.now().toLocal().toMillis();
      const timeUntilLessonStart = start - now;
      const timerHasExpired = timeUntilLessonStart <= 0;

      if (timerHasExpired) {
        clearInterval(interval);
        setTimer(0);
      } else {
        setTimer(timeUntilLessonStart);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [openLesson]);

  return (
    <Button
      onClick={() => handleSetJoinDialog(true)}
      variant="contained"
      color="error.100"
      sx={{
        width: '100%',
        minWidth: 104,
        display: 'flex',
        justifyContent: 'space-evenly',
        gap: 1,
        padding: '8px',
        color: '#FFF',
        animation: !canDialogBeMinimized ? '1750ms infinite pulse' : 'unset',
        '@keyframes pulse': {
          '0%': { transform: 'scale(.9)' },
          '50%': { transform: 'scale(1.2)' },
          '100%': { transform: 'scale(.9)' },
        },
      }}
    >
      <Lottie
        lottieFile={LottieFile.BellWhite}
        color="white"
        loop={true}
        play={true}
        style={{
          width: '24px',
          height: '24px',
        }}
      />
      {timer ? DateTime.fromMillis(timer).toFormat('m:ss') : ''}
    </Button>
  );
};

export default CountdownToLesson;
