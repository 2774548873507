import { Box, CardProps, Divider, Skeleton, Stack } from '@mui/material';
import { useMemo } from 'react';
import { useGetInvoiceSummary } from '@hoot/hooks/api/invoice/useGetInvoiceSummary';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { InvoiceDetailsResponse } from '../../../../../hooks/api/invoice/useGetInvoiceDetails';

interface SummaryProps {
  invoiceDetails: InvoiceDetailsResponse | undefined;
  viewStateInvoiceDetails: ViewStateEnum;
  setShowConfirmSubmitDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setShowConfirmRetractDialog: React.Dispatch<React.SetStateAction<boolean>>;
  submitInvoiceLoading: boolean;
  retractInvoiceLoading: boolean;
  isCurrentPeriod: boolean;
  isSubmitted: boolean;
  isInvoicePeriodSubmitted: boolean;
  sx?: CardProps['sx'];
}

const InvoiceDetailsSummary = (props: SummaryProps) => {
  const {
    sx,
    invoiceDetails,
    viewStateInvoiceDetails,
    setShowConfirmSubmitDialog,
    setShowConfirmRetractDialog,
    submitInvoiceLoading,
    retractInvoiceLoading,
    isCurrentPeriod,
    isSubmitted,
    isInvoicePeriodSubmitted,
  } = props;

  const showSubmitButton = isCurrentPeriod;
  const showRetractButton = isSubmitted && isCurrentPeriod;
  const showDownloadButton = isInvoicePeriodSubmitted;

  const {
    data: summary,
    isLoading,
    isError,
  } = useGetInvoiceSummary(invoiceDetails?.invoiceId ? invoiceDetails?.invoiceId : '', {
    retry: false,
    enabled: !!invoiceDetails?.invoiceId,
    onError: (ex) => {
      console.error(ex);
    },
  });

  const viewState = useMemo<ViewStateEnum>(() => {
    if (isLoading || viewStateInvoiceDetails === ViewStateEnum.Loading) {
      return ViewStateEnum.Loading;
    } else if (isError || viewStateInvoiceDetails === ViewStateEnum.Error) {
      return ViewStateEnum.Error;
    } else {
      return ViewStateEnum.Results;
    }
  }, [isLoading, viewStateInvoiceDetails, isError]);

  return (
    <Card title="Summary" titleVariant="titlelarge" sx={{ height: 'max-content', ...(sx ?? {}) }} contentSx={{ padding: '16px 0px 16px 0px' }}>
      <ViewState state={viewState} loadingContent={<SkeletonItems />}>
        <Stack spacing={2}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <HootTypography isPII={false} variant="labellarge">
              Billable Lessons
            </HootTypography>
            <HootTypography isPII={false} variant="labellarge">
              {summary?.billableLessonsTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
            </HootTypography>
          </Box>
          <Box display={'flex'} justifyContent={'space-between'}>
            {isInvoicePeriodSubmitted ? (
              <HootTypography isPII={false} variant="labellarge">
                Billable Tasks
              </HootTypography>
            ) : (
              <HootTypography isPII={false} variant="labellarge">
                Billable Tasks (Pending)
              </HootTypography>
            )}
            <HootTypography isPII={false} variant="labellarge">
              {summary?.billableTasksTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
            </HootTypography>
          </Box>
          {isInvoicePeriodSubmitted ? (
            <>
              {summary?.administrationTotal ? (
                <Box display={'flex'} justifyContent={'space-between'}>
                  <HootTypography isPII={false} variant="labellarge">
                    Additional Services
                  </HootTypography>
                  <HootTypography isPII={false} variant="labellarge">
                    {summary?.administrationTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </HootTypography>
                </Box>
              ) : null}
              {summary?.rejectedtotal ? (
                <Box display={'flex'} justifyContent={'space-between'}>
                  <HootTypography isPII={false} variant="labellarge" color={hootTokens.palette.error[100]}>
                    Rejected Billable Tasks
                  </HootTypography>
                  <HootTypography isPII={false} variant="labellarge" color={hootTokens.palette.error[100]}>
                    - {summary?.rejectedtotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </HootTypography>
                </Box>
              ) : null}
            </>
          ) : null}
          <Box display={'flex'} justifyContent={'space-between'}>
            <HootTypography isPII={false} variant="labellarge">
              Subtotal
            </HootTypography>
            <HootTypography isPII={false} variant="labellarge">
              {summary?.invoiceTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
            </HootTypography>
          </Box>
          <Divider sx={{ backgroundColor: '#000' }} />
          <Box display={'flex'} justifyContent={'space-between'}>
            <HootTypography isPII={false} variant="titlemedium">
              Total
            </HootTypography>
            <HootTypography isPII={false} variant="titlemedium">
              {summary?.invoiceTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
            </HootTypography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} justifyContent={'space-evenly'} gap={2} paddingTop={'24px'} displayPrint={'none'}>
            {showSubmitButton ? (
              <Stack width={'100%'} gap={1}>
                <Button
                  fullWidth
                  variant="contained"
                  color="success.140"
                  onClick={() => setShowConfirmSubmitDialog(true)}
                  isLoading={submitInvoiceLoading}
                  disabled={isSubmitted}
                >
                  {isSubmitted ? 'Submitted' : 'Submit Invoice'}
                </Button>
                {!isSubmitted ? (
                  <HootTypography isPII={false} variant="bodysmall" color={hootTokens.palette.success[60]} textAlign={'center'}>
                    *You have {invoiceDetails?.daysLeftInPeriod} days left to submit this invoice
                  </HootTypography>
                ) : null}
              </Stack>
            ) : null}
            {showRetractButton ? (
              <Button
                onClick={() => setShowConfirmRetractDialog(true)}
                variant="contained"
                color="error"
                isLoading={retractInvoiceLoading}
                disabled={!isCurrentPeriod}
              >
                <Icon name="redo" htmlColor="white" sx={{ paddingRight: '8px' }} />
                Retract Invoice
              </Button>
            ) : null}
            {showDownloadButton ? (
              <Button onClick={() => window.print()} variant="contained">
                <Icon name="page" />
                <HootTypography isPII={false} variant="labellarge" sx={{ paddingLeft: '10px' }}>
                  Print Invoice
                </HootTypography>
              </Button>
            ) : null}
          </Box>
        </Stack>
      </ViewState>
    </Card>
  );
};

const SkeletonItems = () => (
  <Stack gap={3}>
    <Skeleton variant="rounded" sx={{ ...hootTokens.text.bodysmall }} />
    <Skeleton variant="rounded" sx={{ ...hootTokens.text.bodysmall }} />
    <Skeleton variant="rounded" sx={{ ...hootTokens.text.bodysmall }} />
    <Skeleton variant="rounded" sx={{ ...hootTokens.text.bodysmall }} />
    <Box display={'flex'} flexDirection={'column'} gap={0.5}>
      <Skeleton variant="rounded" sx={{ height: '54px' }} />
      <Skeleton variant="rounded" sx={{ alignSelf: 'center', width: '275px', ...hootTokens.text.bodysmall }} />
    </Box>
  </Stack>
);

export default InvoiceDetailsSummary;
