import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { ScheduledLessonsMessageResponse } from '@hoot/events/messages/scheduled-lessons-message-response';
import { ReaderLesson } from '@hoot/models/api/lessons';
import { RootState } from '@hoot/redux/store';

export interface UpcomingLessonsState {
  scheduledLessons: ReaderLesson[];
  refreshScheduledLessons: boolean;
}

const initialState: UpcomingLessonsState = {
  scheduledLessons: [],
  refreshScheduledLessons: false,
};

export const upcomingLessonsSlice = createSlice({
  name: 'upcomingLessons',
  initialState,
  reducers: {
    resetUpcomingLessons: () => ({ ...initialState }),
    updateScheduledLessons: (state: UpcomingLessonsState, action: PayloadAction<ScheduledLessonsMessageResponse>) => {
      state.scheduledLessons = action.payload.lessons;
    },
    setRefreshScheduledLessons: (state: UpcomingLessonsState, action: PayloadAction<boolean>) => {
      state.refreshScheduledLessons = action.payload;
    },
  },
});

export const useUpcomingLessonsState = () => {
  return useSelector((state: RootState) => state.upcomingLessons);
};

export const { resetUpcomingLessons, updateScheduledLessons, setRefreshScheduledLessons } = upcomingLessonsSlice.actions;

export default upcomingLessonsSlice.reducer;
