import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface EventsImpactedByAvailabilityExceptionRequest {
  startsAt: number;
  endsAt: number;
}

export interface EventImpactedByAvailabilityException {
  eventType: 'LESSON' | 'SHIFT';
  eventId: string;
  startsAt: number;
  endsAt: number;
}

export interface EventsImpactedByAvailabilityExceptionResponse {
  eventsImpacted: EventImpactedByAvailabilityException[];
}

async function getEventsImpactedByAvailabilityExceptionRequest(request: EventsImpactedByAvailabilityExceptionRequest) {
  const { data } = await axios.get<EventsImpactedByAvailabilityExceptionResponse>(`${config.apiUrl}/public/availability-exception/impacted-events`, {
    params: request,
  });
  return data;
}

export function useGetEventsImpactedByAvailabilityExceptionRequest(
  request: EventsImpactedByAvailabilityExceptionRequest,
  options?: Omit<UseQueryOptions<EventsImpactedByAvailabilityExceptionResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetEventsImpactedByAvailabilityException], () => getEventsImpactedByAvailabilityExceptionRequest(request), options);
}
