import { SvgIcon, SvgIconProps } from '@mui/material';

const Chevron = (props: SvgIconProps) => (
  // TODO:Shane - ArrowDirection utils here?
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path d="M14 7L10 12L14 17" stroke={props.htmlColor ?? 'currentColor'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default Chevron;
