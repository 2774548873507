import { SvgIcon, SvgIconProps } from '@mui/material';

const Camera = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.40627 0.890599L5 3H4C1.79086 3 0 4.79086 0 7V14C0 16.2091 1.79086 18 4 18H16C18.2091 18 20 16.2091 20 14V7C20 4.79086 18.2091 3 16 3H15L13.5937 0.8906C13.2228 0.334202 12.5983 0 11.9296 0H8.07037C7.40166 0 6.7772 0.334202 6.40627 0.890599ZM13 10.5C13 12.1569 11.6569 13.5 10 13.5C8.34315 13.5 7 12.1569 7 10.5C7 8.84315 8.34315 7.5 10 7.5C11.6569 7.5 13 8.84315 13 10.5ZM10 4C10.5523 4 11 3.55228 11 3C11 2.44772 10.5523 2 10 2C9.44771 2 9 2.44772 9 3C9 3.55228 9.44771 4 10 4Z"
      fill="#000"
    />
  </SvgIcon>
);

export default Camera;
