import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface LessonReviewStateRequest {
  notepadText: string;
  lessonId: string;
  studentProfileId: string;
}

async function saveLessonReviewState(request: LessonReviewStateRequest): Promise<void> {
  await axios.post<void>(`${config.apiUrl}/public/teacher/lessons/${request.lessonId}/save-lesson-review-state`, request);
}

export function useSaveLessonReviewState(options?: UseMutationOptions<void, AxiosError, LessonReviewStateRequest>) {
  return useMutation<void, AxiosError, LessonReviewStateRequest>((request) => saveLessonReviewState(request), options);
}
