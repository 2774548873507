import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { LoginType } from '@hoot/models/loginType';
import { RootState } from '@hoot/redux/store';
import { Profile } from '../../models/api/user';

export interface ProfileState {
  profiles: Profile[];
  isLoadingProfiles: boolean;
  profile?: Profile;
  loginType: LoginType;
}

const initialState: ProfileState = {
  isLoadingProfiles: false,
  profiles: [],
  loginType: LoginType.Empty,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    hydrateProfile: (state, action: PayloadAction<ProfileState>) => {
      return action.payload;
    },
    resetProfile: () => initialState,
    updateProfile: (state, action: PayloadAction<Profile>) => {
      state.profile = action.payload;
    },
    setIsLoadingProfiles: (state) => {
      state.isLoadingProfiles = true;
    },
    updateProfiles: (state, action: PayloadAction<Profile[]>) => {
      state.profiles = action.payload;
      state.isLoadingProfiles = false;
    },
    setLoginType: (state, action: PayloadAction<LoginType>) => {
      state.loginType = action.payload;
    },
  },
});

export const useProfileState = () => useSelector((state: RootState) => state.profile);

export const { hydrateProfile, setIsLoadingProfiles, resetProfile, updateProfile, updateProfiles, setLoginType } = profileSlice.actions;

export default profileSlice.reducer;
