import { SvgIcon, SvgIconProps } from '@mui/material';

const Microphone = (props: SvgIconProps) => (
  <SvgIcon width="14" height="21" viewBox="0 0 14 21" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0.600098C4.79086 0.600098 3 2.39096 3 4.6001V10.6001C3 12.8092 4.79086 14.6001 7 14.6001C9.20914 14.6001 11 12.8092 11 10.6001V4.6001C11 2.39096 9.20914 0.600098 7 0.600098ZM1 8.8501C1.41421 8.8501 1.75 9.18588 1.75 9.6001V10.6001C1.75 13.4996 4.10051 15.8501 7 15.8501C9.8995 15.8501 12.25 13.4996 12.25 10.6001V9.6001C12.25 9.18588 12.5858 8.8501 13 8.8501C13.4142 8.8501 13.75 9.18588 13.75 9.6001V10.6001C13.75 14.0745 11.125 16.9358 7.75 17.3089V19.1001H10C10.4142 19.1001 10.75 19.4359 10.75 19.8501C10.75 20.2643 10.4142 20.6001 10 20.6001H4C3.58579 20.6001 3.25 20.2643 3.25 19.8501C3.25 19.4359 3.58579 19.1001 4 19.1001H6.25V17.3089C2.87504 16.9358 0.25 14.0745 0.25 10.6001V9.6001C0.25 9.18588 0.585786 8.8501 1 8.8501Z"
      fill="#000"
    />
  </SvgIcon>
);

export default Microphone;
