import { DailyVideo, useAudioTrack, useDaily, useDailyEvent, useLocalSessionId, useVideoTrack } from '@daily-co/daily-react';
import { Box, Stack } from '@mui/system';
import { useCallback } from 'react';
import { STUDENT_VIDEO_HEIGHT, STUDENT_VIDEO_WIDTH } from '@hoot/constants/daily/videoDimensions';
import { DailyMediaState } from '@hoot/hooks/daily/useDailyMediaState';
import Lottie, { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import NetworkVisualizer from '@hoot/ui/components/v2/teacher-blade/ui-components/NetworkVisualizer';
import DailyAudioVisualizer from '@hoot/ui/components/v2/video/DailyAudioVisualizer';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const LocalStudentTile = () => {
  const dailyCallObj = useDaily();
  const localSessionId = useLocalSessionId();
  const localVideoTrack = useVideoTrack(localSessionId);
  const localAudioTrack = useAudioTrack(localSessionId);

  const isVideoEnabled = !localVideoTrack.isOff;

  useDailyEvent(
    'joined-meeting',
    useCallback(() => {
      if (dailyCallObj) {
        const { studentMicrophoneOn, studentCameraOn } = dailyCallObj.meetingSessionState().data as DailyMediaState;
        dailyCallObj.setLocalVideo(studentCameraOn ?? true);
        dailyCallObj.setLocalAudio(studentMicrophoneOn ?? true);
      }
    }, [dailyCallObj]),
  );

  useDailyEvent('meeting-session-state-updated', (event) => {
    const { studentMicrophoneOn, studentCameraOn } = event.meetingSessionState.data as DailyMediaState;
    if (dailyCallObj) {
      dailyCallObj.setLocalVideo(studentCameraOn ?? !localVideoTrack.isOff);
      dailyCallObj.setLocalAudio(studentMicrophoneOn ?? !localAudioTrack.isOff);
    }
  });

  return (
    <Box
      sx={{
        width: '100%',
        height: '178px',
        backgroundColor: localVideoTrack.isOff ? hootTokens.surface['1'] : hootTokens.palette.black,
        padding: 2,
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...hootTokens.elevation.elevation5,
      }}
    >
      <Stack sx={{ position: 'relative' }}>
        {localSessionId && isVideoEnabled ? (
          <DailyVideo
            sessionId={localSessionId}
            type={'video'}
            width={STUDENT_VIDEO_WIDTH}
            height={STUDENT_VIDEO_HEIGHT}
            fit="cover"
            playableStyle={{
              borderRadius: '8px',
            }}
          />
        ) : null}

        {localVideoTrack.isOff && (
          <Lottie
            lottieFile={LottieFile.Camera}
            loop={false}
            play={true}
            style={{
              width: '100px',
              height: '100px',
            }}
          />
        )}

        <Stack flexDirection="row" position="absolute" top={8} right={8} columnGap={2}>
          {isVideoEnabled && <DailyAudioVisualizer audioTrack={localAudioTrack} />}
          {isVideoEnabled && <NetworkVisualizer />}
        </Stack>
      </Stack>
    </Box>
  );
};

export default LocalStudentTile;
