import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface LessonPlanBook {
  id: string;
  title: string;
  resourceProgress: string;
  resourceType: string;
  instructionalFocus: string;
  instructionalUnit: string;
}

export interface GetLessonPlanBookDetailsResponse {
  bookDetails: LessonPlanBook;
}

async function getLessonPlanBookDetails(studentProfileId: string, bookId: string): Promise<GetLessonPlanBookDetailsResponse> {
  const { data } = await axios.get<GetLessonPlanBookDetailsResponse>(
    `${config.apiUrl}/public/lesson-review/lesson-plan/${studentProfileId}/book-details/${bookId}`,
  );
  return data;
}

export default function useGetLessonPlanBookDetails(
  studentProfileId: string,
  bookId: string,
  options?: Omit<UseQueryOptions<GetLessonPlanBookDetailsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetLessonPlanBookDetails, studentProfileId, bookId], () => getLessonPlanBookDetails(studentProfileId, bookId), options);
}
