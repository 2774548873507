import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface BeamerTranslation {
  title: string;
  // Content of the post (plain text).
  content: string;
  // Content of the post (original HTML format).
  contentHtml: string;
  language: string;
  category: string;
  linkUrl: string;
  linkText: string;
  // URLs of the images embedded in this post.
  images: string[];
  postUrl: string;
}

export interface BeamerPost {
  id: number;
  date: string;
  dueDate: string;
  published: boolean;
  pinned: boolean;
  showInWidget: boolean;
  showInStandalone: boolean;
  category: string;
  boostedAnnouncement: boolean;
  translations: BeamerTranslation[];
  filter: string;
  filterUrl: string;
  editionDate: string;
  feedbackEnabled: boolean;
  reactionsEnabled: boolean;
}

async function getAppNotifications(): Promise<BeamerPost[]> {
  const { data } = await axios.get<BeamerPost[]>(`${config.apiUrl}/beamer/latest-posts`);
  return data;
}

export default function useGetAppNotifications(options?: Omit<UseQueryOptions<BeamerPost[]>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetAppNotifications], () => getAppNotifications(), options);
}
