import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useDistrictRepresentativeGetStudent, {
  DistrictRepresentativeStudentLocationResponse,
} from '@hoot/hooks/api/district-rep/useDistrictRepresentativeGetStudent';
import { useBlocker } from '@hoot/hooks/usePrompt';
import { StudentGrade, StudentStatusEnum } from '@hoot/models/api/student';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Page from '@hoot/ui/components/v2/core/Page';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import StudentInfoCard from '@hoot/ui/pages/v2/district-rep/students/details/StudentInfoCard';

export interface StudentDetailsForm {
  name: string;
  number: number | null;
  status: StudentStatusEnum | '';
  grade: StudentGrade | '';
  pronouns: string;
  hfsLocations: DistrictRepresentativeStudentLocationResponse[] | null;
  additionalDetails: string;
}

const StudentDetailsPage = () => {
  const { studentProfileId } = useParams();

  const {
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<StudentDetailsForm>({
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      number: null,
      status: StudentStatusEnum.Active,
      grade: '',
      pronouns: '',
      hfsLocations: null,
      additionalDetails: '',
    },
  });
  const [open, setOpen] = React.useState<boolean>(false);
  const [blocked, proceed] = useBlocker(isDirty);

  const { isFetching, isError } = useDistrictRepresentativeGetStudent(studentProfileId!, {
    onSuccess: (data) => {
      reset({
        name: data.name,
        number: data.number,
        status: data.status,
        grade: data.grade ?? '',
        pronouns: data.pronouns ?? '',
        hfsLocations: data.hfsLocations,
      });
    },
  });

  useEffect(() => {
    if (blocked) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [blocked]);

  return (
    <>
      <Page pageTitle="District Representative Student Details | Hoot Reading" noMaxWidth>
        <StudentInfoCard isLoading={isFetching} isError={isError} watch={watch} />
      </Page>
      <BasicAlertDialog
        show={open}
        onDismiss={() => setOpen(false)}
        title={'Discard?'}
        content={
          <HootTypography isPII={false} variant="bodylarge">
            Closing without saving will discard your changes
          </HootTypography>
        }
        primaryAction={{
          label: 'Save',
          onClick: handleSubmit(() => ''),
          props: {
            variant: 'contained',
          },
        }}
        secondaryAction={{
          label: 'Discard',
          onClick: () => {
            setOpen(false);
            reset();
            proceed();
          },
        }}
      />
    </>
  );
};

export default StudentDetailsPage;
