import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import { Box, Stack } from '@mui/system';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { ControllerFieldState } from 'react-hook-form';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import CheckList, { CheckListItem } from '../CheckList';
import { Button } from './Button';
import { Chip } from './Chip';
import HootTypography from './HootTypography';
import { Icon } from './Icon';
import IconButton from './IconButton';
import SearchTextField from './SearchTextField';

export interface BaseChipGroupItem {
  value: string;
  label: string;
}

type SingleSelect = {
  singleSelect: true;
  value: string | null;
  onChange: (value: string | null) => void;
};

type MultiSelect = {
  singleSelect?: false;
  value: string[];
  onChange: (value: string[]) => void;
};

interface _DetailedChipGroupProps {
  label: string;
  addMoreBtnLabel: string;
  addMoreTitle: string;
  addMoreOptions: AddMoreOptions[];
  helperText?: string;
  disabled?: boolean;
  error?: boolean;
  fieldState: ControllerFieldState;
  dialogSx?: LargeDialogProps['sx'];
}

export type DetailedChipGroupProps = _DetailedChipGroupProps & (SingleSelect | MultiSelect);

const DetailedChipGroup = (props: DetailedChipGroupProps) => {
  const { label, addMoreBtnLabel, addMoreTitle, helperText, disabled, error, singleSelect, value, onChange, addMoreOptions, fieldState, dialogSx } =
    props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedAddMoreValues, setSelectedAddMoreValues] = useState<string | string[] | null>(singleSelect ? null : value);
  const [searchString, setSearchString] = useState<string>('');

  const previousAddMoreValuesRef = useRef<string[]>(value ? [value].flat() : []);

  const handleOnChange = (values: string | string[] | null) => {
    if (singleSelect) {
      onChange(values as string);
    } else {
      onChange([...(values as string[])]);
    }
    previousAddMoreValuesRef.current = values ? [values].flat() : [];
  };

  const getFilterCount = (value: string | string[] | null) => {
    const newValues: string[] = value ? [value].flat() : [];

    if (singleSelect) {
      const n = newValues[0];
      const p = previousAddMoreValuesRef.current[0];
      return n === p ? 0 : 1;
    }

    const newSelections = newValues.reduce((count: number, current: string) => {
      if (previousAddMoreValuesRef.current.find((item) => item === current)) {
        return count;
      }
      return count + 1;
    }, 0);

    const deselections = previousAddMoreValuesRef.current.reduce((count: number, current: string) => {
      if (newValues.find((item) => item === current)) {
        return count;
      }
      return count + 1;
    }, 0);

    return newSelections + deselections;
  };

  const filterCount = useMemo(() => getFilterCount(selectedAddMoreValues), [selectedAddMoreValues, previousAddMoreValuesRef.current]);

  const searchedAddMoreOptions = addMoreOptions.map((e) => {
    return {
      parentCategory: e.parentCategory,
      listItems: e.listItems.filter((i) => i.label.toLowerCase().includes(searchString.toLowerCase())),
    };
  });
  const viewState =
    searchedAddMoreOptions.length > 0 && searchedAddMoreOptions.some((option) => option.listItems.length > 0)
      ? ViewStateEnum.Results
      : ViewStateEnum.NoResults;

  useEffect(() => {
    setSelectedAddMoreValues(value);
  }, [value]);

  const getColor = (): string => {
    if (disabled) {
      return hootTokens.palette.neutral[140];
    }
    if (error) {
      return hootTokens.palette.error[80];
    }
    return hootTokens.palette.black;
  };

  const displayItems: BaseChipGroupItem[] = useMemo(() => {
    const displayItems = addMoreOptions.map((category) => category.listItems).flat(1);
    return displayItems
      .filter((o) => (singleSelect ? value === o.value : value.includes(o.value)))
      .filter((value, index, self) => self.findIndex((v) => v.value === value.value) === index);
  }, [addMoreOptions, singleSelect, value]);

  const handleOnApplyClick = () => {
    handleOnChange(selectedAddMoreValues);
    setIsModalOpen(false);
  };

  const handleOnClearClick = () => {
    setSelectedAddMoreValues(singleSelect ? null : []);
  };

  const handleOnClose = () => {
    setSelectedAddMoreValues(value);
    previousAddMoreValuesRef.current = value ? [value].flat() : [];
    setIsModalOpen(false);
  };

  const handleOnChipDeleteClick = (selectedItem: BaseChipGroupItem) => {
    if (singleSelect) {
      handleOnChange(null);
    } else {
      handleOnChange(value.filter((v) => v !== selectedItem.value));
    }
  };

  return (
    <>
      <Stack
        sx={{
          padding: 2,
          borderRadius: '4px',
        }}
      >
        {label && (
          <HootTypography isPII={false} variant="labelsmall" color={getColor()}>
            {label}
          </HootTypography>
        )}
        <Stack direction="row" gap={1} flexWrap={'wrap'}>
          {displayItems.map((item) => (
            <Chip
              key={`key-${item.value}`}
              label={item.label}
              selected={true}
              onDelete={() => handleOnChipDeleteClick(item)}
              deleteIcon={<Icon name="close" />}
              error={error}
            />
          ))}

          <Button
            onClick={() => setIsModalOpen(true)}
            sx={(theme) => ({
              borderRadius: theme.spacing(1),
              border: `1px dashed ${hootTokens.palette.success[60]}`,
              p: theme.spacing(1),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: theme.spacing(1),
              cursor: 'pointer',
            })}
          >
            <Icon name="solid_add_rectangle" />
            <HootTypography isPII={false} variant="labelsmall" color={hootTokens.palette.success[60]}>
              {addMoreBtnLabel}
            </HootTypography>
          </Button>
        </Stack>
        {helperText ? (
          <FormHelperText
            sx={{
              margin: 1,
              ...hootTokens.text.labelsmall,
              color: getColor(),
            }}
          >
            {helperText}
          </FormHelperText>
        ) : null}
      </Stack>

      {isModalOpen ? (
        <LargeDialog
          show={isModalOpen}
          onDismiss={handleOnClose}
          title={addMoreTitle}
          viewState={viewState}
          headerContent={
            <SearchTextField
              label="Search"
              searchInput={searchString}
              onSearchInputChanged={setSearchString}
              onClearButtonClicked={() => setSearchString('')}
              sx={{ position: 'sticky' }}
            />
          }
          addMoreContent={
            <AddMoreContent
              setSelectedAddMoreValues={setSelectedAddMoreValues}
              selectedAddMoreValues={selectedAddMoreValues}
              searchedAddMoreOptions={searchedAddMoreOptions}
              singleSelect={singleSelect}
              fieldState={fieldState}
            />
          }
          primaryAction={{
            onClick: handleOnApplyClick,
            label: `Apply ${filterCount > 0 ? `(${filterCount})` : ''}`,
          }}
          secondaryAction={{
            onClick: handleOnClose,
            label: 'Cancel',
          }}
          tertiaryAction={{
            onClick: handleOnClearClick,
            label: 'Clear',
          }}
          sx={dialogSx}
        />
      ) : null}
    </>
  );
};

interface AddMoreContentProps {
  setSelectedAddMoreValues: React.Dispatch<React.SetStateAction<string | string[] | null>>;
  selectedAddMoreValues: string | string[] | null;
  searchedAddMoreOptions: AddMoreOptions[];
  singleSelect?: boolean;
  fieldState: ControllerFieldState;
}

export interface AddMoreOptions {
  parentCategory: string;
  listItems: {
    label: string;
    value: string;
  }[];
}

const AddMoreContent = (props: AddMoreContentProps) => {
  const { selectedAddMoreValues, setSelectedAddMoreValues, searchedAddMoreOptions, singleSelect } = props;

  const setValue = (value: string) => {
    if (singleSelect) {
      if (selectedAddMoreValues === value) {
        setSelectedAddMoreValues(null);
      } else {
        setSelectedAddMoreValues(value);
      }
    } else {
      if ((selectedAddMoreValues as string[]).includes(value)) {
        setSelectedAddMoreValues((prevValues) => [...(prevValues as string[]).filter((pv) => pv !== value)]);
      } else {
        setSelectedAddMoreValues((prevValues) => [...(prevValues as string[]), value]);
      }
    }
  };

  const toggleChecklistOption = (option: CheckListItem) => {
    setValue(option.value);
  };

  const toggleRadioOption: RadioGroupProps['onChange'] = (event, value) => {
    setValue(value);
  };

  return (
    <Stack gap={(theme) => theme.spacing(1)}>
      <Stack sx={(theme) => ({ mt: theme.spacing(2) })} gap={(theme) => theme.spacing(3)}>
        {searchedAddMoreOptions.map((checkListSet) =>
          singleSelect ? (
            <FormControl variant="standard">
              <FormLabel>{checkListSet.parentCategory}</FormLabel>
              <RadioGroup
                key={checkListSet.parentCategory}
                name={checkListSet.parentCategory}
                value={!!selectedAddMoreValues ? (selectedAddMoreValues as string) : ''}
                onChange={toggleRadioOption}
              >
                {checkListSet.listItems.map((option) => (
                  <FormControlLabel value={option.value} control={<Radio />} label={option.label} />
                ))}
              </RadioGroup>
            </FormControl>
          ) : (
            <CheckList
              key={checkListSet.parentCategory}
              title={checkListSet.parentCategory}
              items={checkListSet.listItems}
              toggleOption={toggleChecklistOption}
              selectedValues={selectedAddMoreValues as string[]}
              collapsibleItems
            />
          ),
        )}
      </Stack>
    </Stack>
  );
};

export interface LargeDialogProps {
  show: boolean;
  onDismiss: () => void;
  title: string;
  headerContent: ReactNode;
  addMoreContent: ReactNode;
  viewState: ViewStateEnum;
  primaryAction: {
    label: string;
    onClick: () => void;
  };
  secondaryAction?: {
    label: string;
    onClick: () => void;
  };
  tertiaryAction?: {
    label: string;
    onClick: () => void;
  };
  sx?: DialogProps['sx'];
}

const LargeDialog = (props: LargeDialogProps) => {
  const { show, onDismiss, title, headerContent, addMoreContent, primaryAction, secondaryAction, tertiaryAction, viewState, sx = {} } = props;

  return (
    <Dialog open={show} onClose={onDismiss} fullWidth sx={sx}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {title}
        <IconButton onClick={onDismiss}>
          <Icon name="close" />
        </IconButton>
      </DialogTitle>
      <Box
        sx={(theme) => ({
          padding: theme.spacing(0, 2, 0, 2),
        })}
      >
        {headerContent}
      </Box>
      {/* No loadingContent needed here because all the options are already loaded if we're rendering this */}
      <ViewState state={viewState} loadingContent={<></>}>
        <DialogContent>{addMoreContent}</DialogContent>
      </ViewState>
      <DialogActions>
        {tertiaryAction ? (
          <Button onClick={tertiaryAction.onClick} variant="outlined" color="error.80">
            {tertiaryAction.label}
          </Button>
        ) : null}
        {secondaryAction ? (
          <Button onClick={secondaryAction.onClick} variant="outlined">
            {secondaryAction.label}
          </Button>
        ) : null}
        <Button onClick={primaryAction.onClick} variant="contained">
          {primaryAction.label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailedChipGroup;
