import { SvgIcon, SvgIconProps } from '@mui/material';

const ColourOption = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <rect width="24" height="24" rx="8" fill={props.fill ?? '#569436'} />
    </SvgIcon>
  );
};

export default ColourOption;
