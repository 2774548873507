import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect } from 'react';
import { isAndroid, isChrome, isEdge, isFirefox, isSafari } from 'react-device-detect';
import { Button } from '@hoot/ui/components/v2/core/Button';
import ArrowIcon from '@hoot/ui/components/v2/icons/ArrowIcon';
import CloseSquare from '@hoot/ui/components/v2/icons/CloseSquare';
import RedoIcon from '@hoot/ui/components/v2/icons/RedoIcon';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import HootTypography from '../../../core/HootTypography';

interface IDeniedProps {
  handleMediaChange?: () => void;
}

const DeniedPermissions = (props: IDeniedProps) => {
  const { handleMediaChange } = props;

  // To help the user, this will show the Permissions prompt as well as display the Camera/Mic icon in the URL.
  useEffect(() => {
    if (handleMediaChange) {
      handleMediaChange();
    }
  }, [handleMediaChange]);

  const getStepOneScreenshot = (): JSX.Element => {
    if (isSafari) {
      return <img src={`/images/av-permissions/safari-blocked-1.png`} alt="permission-screenshot-safari" width="100%" />;
    }
    if (isEdge) {
      return <img src={`/images/av-permissions/edge-blocked.png`} alt="permission-screenshot-edge" width="320px" />;
    }
    if (isFirefox) {
      return <img src={`/images/av-permissions/firefox-blocked.png`} alt="permission-screenshot-firefox" width="100%" />;
    }
    if (isAndroid) {
      return <img src={`/images/av-permissions/android-chrome-blocked-1.png`} alt="permission-screenshot-android" width="100%" />;
    }

    return <img src={`/images/av-permissions/chrome-blocked-1.png`} alt="permission-screenshot-chrome" width="100%" />; // Default fallback is for Chrome Desktop.
  };

  const getStepOneInstructions = (): string => {
    if (isSafari) {
      return 'Select "Settings for app.hootreading.com" from the Safari menu.';
    }
    if (isFirefox) {
      return 'Click the Camera/Microphone icon in the url bar. Then clear both blocked permissions by clicking the "Blocked Temporarily" labels.';
    }
    if (isAndroid) {
      return 'Tap the Camera icon in the URL bar and select "Permissions".';
    }

    return 'Click the Camera icon in the URL bar and select “Always allow https://app.hootreading.com access".'; // Default fallback is for Chrome Desktop.
  };

  const getStepTwoScreenshot = (): JSX.Element => {
    if (isSafari) {
      return <img src={`/images/av-permissions/safari-blocked-2.png`} alt="permission-screenshot-2-safari" width="250px" />;
    }

    if (isFirefox || isEdge) {
      return (
        <Button onClick={() => window.location.reload()} variant="contained" size="medium" startIcon={<RedoIcon htmlColor="#FFF" />}>
          Refresh
        </Button>
      );
    }

    if (isAndroid) {
      return <img src={`/images/av-permissions/android-chrome-blocked-2.png`} alt="permission-screenshot-2-android" width="100%" />;
    }

    return <img src={`/images/av-permissions/chrome-blocked-2.png`} alt="permission-screenshot-2-chrome" width="240px" />; // Default fallback is for Chrome Desktop.
  };

  const getStepTwoInstructions = (): string => {
    if (isSafari) {
      return 'Choose allow for both Camera and Microphone and then Click the "Refresh" button below.';
    }
    if (isFirefox) {
      return 'After clearing both permissions, click the "Refresh" button above.';
    }
    if (isEdge) {
      return 'Click the "Refresh" button above and re-join the lesson.';
    }
    if (isAndroid) {
      return 'Turn on both Camera and Microphone permissions. When you\'re finished, tap the "Refresh" button below.';
    }

    return 'After pressing “Done”, click the "Refresh" button below.'; // Default fallback is for Chrome Desktop.
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          borderRadius: '8px',
          padding: '12px',
          marginBottom: '24px',
          backgroundColor: hootTokens.palette.error[190],
        }}
      >
        <CloseSquare />
        <div>
          <HootTypography isPII={false} variant="titlesmall" sx={{ color: hootTokens.palette.error[80] }}>
            Hoot cannot access your Camera and Microphone
          </HootTypography>
          <HootTypography isPII={false} variant="titlesmall" sx={{ color: hootTokens.palette.error[80] }}>
            Follow the steps below to give Hoot access to your Camera and Microphone
          </HootTypography>
        </div>
      </Box>

      <Grid container flexWrap="nowrap">
        {/* Step 1 */}
        <Grid size={11} flexDirection="column" justifyContent="flex-start">
          <Box
            sx={{
              width: '100%',
              borderRadius: '8px',
              minHeight: '240px',
              backgroundColor: hootTokens.surface[2],
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              padding: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                backgroundColor: '#FFF',
                borderRadius: '4px',
                padding: '4px',
                ...hootTokens.elevation.elevation2,
                '&:hover': { transform: 'scale(1.4)' },
              }}
            >
              {getStepOneScreenshot()}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" margin="8px 0px">
            <HootTypography variant="titlesmall" isPII={false}>
              Step 1
            </HootTypography>
            <HootTypography isPII={false} variant="bodysmall" align="center">
              {getStepOneInstructions()}
            </HootTypography>
          </Box>
        </Grid>

        <Grid container size={2} justifyContent="center" alignItems="center">
          <ArrowIcon />
        </Grid>

        {/* Step 2 */}
        <Grid container size={11} flexDirection="column" justifyContent="flex-start">
          <Box
            sx={{
              width: '100%',
              borderRadius: '8px',
              minHeight: '240px',
              padding: '8px',
              backgroundColor: hootTokens.surface[2],
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                backgroundColor: isEdge || isFirefox ? 'none' : '#FFF',
                borderRadius: '4px',
                padding: isEdge || isFirefox ? 'none' : '4px',
                boxShadow: isEdge || isFirefox ? 'none' : hootTokens.elevation.elevation2.boxShadow,
                '&:hover': { transform: isEdge || isFirefox ? 'none' : 'scale(1.5)' },
              }}
            >
              {getStepTwoScreenshot()}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop="8px">
            <HootTypography variant="titlesmall" isPII={false}>
              Step 2
            </HootTypography>
            <HootTypography variant="bodysmall" align="center" isPII={false}>
              {getStepTwoInstructions()}
            </HootTypography>
            {(isChrome || isSafari) && (
              <Button
                onClick={() => window.location.reload()}
                variant="contained"
                size="small"
                startIcon={<RedoIcon htmlColor="#FFF" />}
                sx={{ marginTop: '8px' }}
              >
                Refresh
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default DeniedPermissions;
