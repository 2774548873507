import React, { useEffect } from 'react';
import { SHOW_OPEN_BUTTON_STATUSES } from '@hoot/constants/constants';
import { EventType } from '@hoot/events/eventType';
import useProfile from '@hoot/hooks/useProfile';
import { useSocketSubscription } from '@hoot/hooks/useSocketSubscription';
import { scheduledLessonStatusSortPriority } from '@hoot/models/api/enums';
import { UpcomingLesson, UpcomingLessonsQueryResponse } from '@hoot/models/api/lessons';
import { useSocket } from './SocketContext';

interface Props {
  children: React.ReactNode;
}
interface Values {
  openLesson?: UpcomingLesson;
  nextOpenLesson?: UpcomingLesson;
}
const UpcomingLessonsContext = React.createContext<Values>(undefined!);

const UpcomingLessonsProvider = (props: Props) => {
  const { children } = props;
  const { isStudent, isTeacher } = useProfile();
  const { socket, connected } = useSocket();

  const isEnabled = isStudent || isTeacher;
  const { data } = useSocketSubscription<UpcomingLessonsQueryResponse>(EventType.UpcomingLessonsResponse, { isEnabled: isEnabled });
  const orderedLessons = data?.lessons.sort(
    (a, b) =>
      (scheduledLessonStatusSortPriority.get(a.status) || 0) - (scheduledLessonStatusSortPriority.get(b.status) || 0) || a.startTime - b.startTime,
  );
  const onGoingLessons = orderedLessons?.filter((lesson) => SHOW_OPEN_BUTTON_STATUSES.includes(lesson.status)).slice(0, 2);
  const openLesson = onGoingLessons && onGoingLessons.length > 0 ? onGoingLessons[0] : undefined;
  const nextLesson = openLesson && onGoingLessons && onGoingLessons?.length > 1 ? onGoingLessons[1] : undefined;

  useEffect(() => {
    function onRefreshLessons() {
      if (connected) {
        socket.emit(EventType.UpcomingLessonsRequest);
      }
    }

    if (isEnabled && connected) {
      socket.on(EventType.RefreshScheduledLessons, onRefreshLessons);
      socket.emit(EventType.UpcomingLessonsRequest);
    }
    return () => {
      socket.off(EventType.RefreshScheduledLessons, onRefreshLessons);
    };
  }, [socket, isEnabled, connected]);

  return (
    <UpcomingLessonsContext.Provider
      value={{
        openLesson,
        nextOpenLesson: nextLesson,
      }}
    >
      {children}
    </UpcomingLessonsContext.Provider>
  );
};

const useUpcomingLessons = () => {
  const context = React.useContext(UpcomingLessonsContext);

  if (context === undefined) {
    throw new Error('useUpcomingLessons must be used within a UpcomingLessonsProvider');
  }

  return context;
};

export { UpcomingLessonsProvider, useUpcomingLessons };
