import { Box, Stack } from '@mui/material';
import { zIndexes } from '@hoot/constants/zIndices';
import { BookResponse } from '@hoot/events/interfaces/book-response';
import { PageZoom } from '@hoot/events/interfaces/page-zoom';
import BlankBookPage from '@hoot/ui/components/v2/library/BlankBookPage';
import BookPageV2 from '@hoot/ui/components/v2/library/BookPageV2';
import DailyMyMagicFinger from './DailyMyMagicFinger';
import DailyTheirMagicFinger from './DailyTheirMagicFinger';

interface ReaderProps {
  openedBook: BookResponse;
  isDoublePage: boolean;
  pageIndex: number;
  pageZoom: PageZoom;
}

/**
 * Common reader/open-book component used by both teachers and students.
 */
const Reader = (props: ReaderProps) => {
  const { openedBook, pageIndex, isDoublePage: _isDoublePage, pageZoom } = props;

  // If in double-page mode, the front cover may only be shown by itself.
  const isDoublePage = _isDoublePage && pageIndex === 0 ? false : _isDoublePage;

  // Zoom Settings
  const { offsetLeft, offsetTop, zoomAmount } = pageZoom;

  const pagesToShow = openedBook.pages.slice(pageIndex, pageIndex + (isDoublePage ? 2 : 1)) ?? [];

  // If in double mode and only have one page to show, add a blank page
  // This way if we're zoomed-in in landscape mode, we can retain the same "zoom aspect ratio".
  const showBlankPage = isDoublePage && pagesToShow.length === 1;

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        position: 'relative',
        overflow: 'hidden',
        userSelect: 'none',
        zIndex: zIndexes.bookPageOnReader,
        height: '100%',
        width: '100%',
        flex: 1,
      }}
    >
      <Box
        sx={{
          padding: '4px', // Do the box-shadow of each page isn't clipped.
          maxHeight: '100%',
          maxWidth: '100%',
          position: 'relative', // Needed for magic finger which uses position absolute.
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          userSelect: 'none',
          overflow: 'hidden', // When content is zoomed in, we only want the focused content visible within this rect.
        }}
      >
        <DailyMyMagicFinger height="100%" width="100%" />
        <DailyTheirMagicFinger height="100%" width="100%" />

        {/* Zoom container. */}
        <Box
          sx={{
            width: '100%',
            height: '100%',
            transformOrigin: 'top left',
            transform: `scale(${zoomAmount}) translate(-${offsetLeft}%, -${offsetTop}%)`,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {pagesToShow.map((p, index) => (
            <BookPageV2
              key={`${p.id}-${isDoublePage ? 'dbp' : 'sp'}`}
              src={p.url}
              pageSide={!isDoublePage ? 'singlePage' : index === 0 ? 'left' : 'right'}
            />
          ))}
          {showBlankPage ? <BlankBookPage /> : null}
        </Box>
      </Box>
    </Stack>
  );
};

export default Reader;
