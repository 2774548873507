import { Box, Fade, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import Card from '@hoot/ui/components/v2/core/Card';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import { TeacherLibrarySearchResults } from '@hoot/ui/pages/v2/teacher/library/TeacherLibrary';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { toProperCase } from '@hoot/utils/text-display';

interface TableRow {
  id: string;
  title: React.ReactNode;
  resourceState: string;
  resourceProgress: string;
  instructionalFocuses: string;
  instructionalUnits: string;
  format: string;
  readingLevel: string;
}

interface Props {
  query: QueryLibraryV2;
  isLoadingBooks: boolean;
  librarySearchResults: TeacherLibrarySearchResults;
  studentProfileId?: string;
  onFetchPage: (page: number) => void;
  onPageSizeChanged: (pageSize: number) => void;
  handleBookClick: (bookId: string) => void;
}

export const TeacherLibraryTableView = (props: Props) => {
  const { query, librarySearchResults, isLoadingBooks, studentProfileId, handleBookClick, onFetchPage, onPageSizeChanged } = props;
  const theme = useTheme();
  const { total, books } = librarySearchResults;

  const [loadedIndices, setLoadedIndices] = useState<number[]>([]);
  const subMobileScreen = useMediaQuery(theme.breakpoints.down('mobile'));

  const displayData = books.map<TableRow>((b, idx) => {
    const isLoading = !loadedIndices.includes(idx);

    return {
      id: b.id,
      title: (
        <Stack direction="row" alignItems="center">
          {subMobileScreen ? null : (
            <>
              {isLoading ? <Skeleton variant="rectangular" width={20} height={20} /> : null}
              <Fade in={!isLoading}>
                <img
                  onLoad={() => setLoadedIndices((prev) => [...prev, idx])}
                  draggable={false}
                  style={{
                    display: `${isLoading ? 'none' : 'block'}`,
                    objectFit: 'cover',
                    width: '48px',
                    height: '48px',
                    borderRadius: '8px',
                    verticalAlign: 'bottom',
                    ...hootTokens.elevation.elevation1,
                  }}
                  src={b.coverUrl ?? undefined}
                  alt={b.title}
                />
              </Fade>
            </>
          )}
          <Box sx={{ textDecorationLine: 'underline', pl: 3, py: 2, cursor: 'pointer' }} onClick={() => handleBookClick(b.id)}>
            {b.title}
          </Box>
        </Stack>
      ),
      resourceState: b.resourceState ? toProperCase(b.resourceState) : 'N/A',
      resourceProgress: b.resourceProgress ? toProperCase(b.resourceProgress) : 'N/A',
      instructionalFocuses: b.focuses ? b.focuses.map((f) => f.name).join(', ') : 'N/A',
      instructionalUnits: b.units ? b.units.map((u) => u.name).join(', ') : 'N/A',
      format: b.format ? b.format.name : 'N/A',
      readingLevel: b.readingLevel,
    };
  });

  const headers: HeaderData<TableRow>[] = [
    { name: 'id', property: 'id', isHidden: true },
    { name: 'Title', property: 'title', isSortable: false },
    { name: 'State', property: 'resourceState', isSortable: false, isHidden: !studentProfileId },
    { name: 'Progress', property: 'resourceProgress', isSortable: false, isHidden: !studentProfileId },
    { name: 'Focus', property: 'instructionalFocuses', isSortable: false },
    { name: 'Unit', property: 'instructionalUnits', isSortable: false },
    { name: 'Format', property: 'format', isSortable: false, isHidden: subMobileScreen },
    { name: 'Level', property: 'readingLevel', isSortable: false },
  ];

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onFetchPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    onPageSizeChanged(pageSize);
  };

  return (
    <Card sx={{ marginTop: theme.spacing(1), borderRadius: theme.spacing(1) }} isLoading={isLoadingBooks}>
      <TableV2
        isPaginated
        allowRowsPerPage
        rowsPerPage={query.pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        data={displayData}
        headers={headers}
        onRowsPerPageChange={handleChangeRowsPerPage}
        count={total}
        page={query.page - 1}
        onPageChange={handleChangePage}
      />
    </Card>
  );
};
