import { Box } from '@mui/material';
import { Property } from 'csstype';
import { useMemo } from 'react';
import { SectionField, UnitSection } from '@hoot/hooks/api/assessment/useGetAssessmentUnit';
import { HootAssessmentFieldType, SegmentAnswer } from '@hoot/models/api/enums/hoot-reading-assessment';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import CheckmarkSquare from '@hoot/ui/components/v2/icons/CheckmarkSquare';
import CloseFilledSquareIcon from '@hoot/ui/components/v2/icons/CloseFilledSquareIcon';
import PlayIcon from '@hoot/ui/components/v2/icons/PlayIcon';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { useHootAssessmentContext } from '../HootAssessmentContext';

interface ISegmentProps {
  sectionField: SectionField;
  section: UnitSection;
}

// NOTE: This component doesn't show the controls for this field (the "right" or "wrong" buttons). Those are displayed
// in a separate card.
const Segment = (props: ISegmentProps) => {
  const { sectionField, section } = props;
  const { focusedField, setFocusedField, form } = useHootAssessmentContext();

  const value = form.watch(sectionField.name);

  const formValues = form.getValues();
  const sectionFields = section.fields;

  const isFocusedSegment = sectionField.id === focusedField?.sectionField?.id;

  const nextUnansweredSegment = useMemo(() => {
    const segmentFormKeys = new Set(sectionFields.filter((x) => x.type === HootAssessmentFieldType.Segment).map((x) => x.name));

    // Find the next unanswered segment.
    let nextUnansweredSegment: SectionField | undefined;
    Array.from(segmentFormKeys).some((key) => {
      const segmentAnswer = formValues[key];
      if (!segmentAnswer) {
        nextUnansweredSegment = sectionFields.find((x) => x.name === key);
        return true;
      }
      return false;
    });
    return nextUnansweredSegment;
  }, [formValues, sectionFields]);

  const isQuestionDisabled = !value && sectionField.name !== nextUnansweredSegment?.name;

  const handleSegmentFieldClicked = () => {
    // You can only click/select segments that have been answered, or the next unanswered segment.
    if (isQuestionDisabled) {
      return;
    }
    setFocusedField({ sectionField });
  };

  const textColour = (): Property.Color => {
    if (value === SegmentAnswer.Correct) {
      return hootTokens.palette.success[60];
    } else if (value === SegmentAnswer.Wrong) {
      return hootTokens.palette.error[80];
    } else if (nextUnansweredSegment?.id === sectionField.id) {
      return hootTokens.palette.black;
    } else {
      return hootTokens.palette.neutral[140];
    }
  };

  const answerIcon = (value: string): JSX.Element | null => {
    switch (value) {
      case SegmentAnswer.Correct:
        return <CheckmarkSquare />;
      case SegmentAnswer.Wrong:
        return <CloseFilledSquareIcon />;
      case SegmentAnswer.NoAnswer:
        return null;
      default:
        return null;
    }
  };

  return (
    <Box onClick={handleSegmentFieldClicked} display="flex" alignItems="center" gap={2} padding={1} width="fit-content">
      {isFocusedSegment && <PlayIcon sx={{ width: '14px', height: '16px' }} />}
      <HootTypography
        isPII={false}
        variant="bodylarge"
        sx={{
          display: 'inline-block',
          cursor: isQuestionDisabled ? 'not-allowed' : 'pointer',
          color: textColour(),
        }}
      >
        {sectionField.label}
      </HootTypography>
      {answerIcon(value)}
    </Box>
  );
};

export default Segment;
