import { Box, LinearProgress, Stack } from '@mui/material';
import React from 'react';
import { LessonWhiteboardTemplateResponse, LessonWhiteboardTemplateStatus } from '@hoot/hooks/api/whiteboard/useGetLessonWhiteboardTemplates';
import { PaginationMode } from '@hoot/redux/reducers/librarySlice';
import AsyncImage from '@hoot/ui/components/v2/AsyncImage';
import GrowList from '@hoot/ui/components/v2/GrowList';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import theme from '@hoot/ui/theme/v2';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface LessonWhiteboardTemplatesProps {
  query?: string;
  paginationMode: PaginationMode;
  isLoading: boolean;
  lessonWhiteboardTemplates: LessonWhiteboardTemplateResponse[];
  previewLessonWhiteboardTemplate: LessonWhiteboardTemplateResponse | null; // TODO SC-10769: Need to display whiteboard template previewer.
  onClearSearch: () => void;
  onLessonWhiteboardTemplateClicked: (wt: LessonWhiteboardTemplateResponse) => void;
  onDismissWhiteboardTemplatePreview: () => void; // TODO SC-10769: Need to wire this up
}

const LessonWhiteboardTemplates = (props: LessonWhiteboardTemplatesProps) => {
  return props.paginationMode === PaginationMode.Append ? (
    <LessonWhiteboardTemplatesGalleryView {...props} />
  ) : (
    <LessonWhiteboardTemplatesTableView {...props} />
  );
};

const LessonWhiteboardTemplatesGalleryView = (props: Omit<LessonWhiteboardTemplatesProps, 'paginationMode'>) => {
  const { query, onLessonWhiteboardTemplateClicked, lessonWhiteboardTemplates, onClearSearch, isLoading } = props;

  return (
    <>
      {isLoading && <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} />}
      {query && (
        <Box
          sx={(theme) => ({
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            my: theme.spacing(3),
          })}
        >
          <HootTypography isPII={false} variant="titlelarge" sx={{ color: hootTokens.palette.primary['60'] }}>
            Search results for "{query}"
          </HootTypography>

          <Button
            sx={{ marginLeft: '16px' }}
            color="error.80"
            variant="outlined"
            size="small"
            onClick={onClearSearch}
            startIcon={<Icon color="error" name="close_square" />}
          >
            Clear
          </Button>
        </Box>
      )}
      <GrowList<LessonWhiteboardTemplateResponse>
        StackProps={{
          direction: 'row',
          sx: {
            gap: 2,
            flexWrap: 'wrap',
          },
        }}
        itemRenderTimeoutMillis={100}
        items={lessonWhiteboardTemplates}
        getKey={(whiteboardTemplate) => whiteboardTemplate.whiteboardTemplate.templateType}
        renderItem={(whiteboardTemplate) => (
          <Box key={whiteboardTemplate.whiteboardTemplate.templateType} sx={{ width: '248px' }}>
            <WhiteboardTemplateGalleryCell
              lessonWhiteboardTemplate={whiteboardTemplate}
              onLessonWhiteboardTemplateClicked={onLessonWhiteboardTemplateClicked}
            />
          </Box>
        )}
      />
    </>
  );
};

interface WhiteboardTemplateGalleryCellProps {
  lessonWhiteboardTemplate: LessonWhiteboardTemplateResponse;
  onLessonWhiteboardTemplateClicked: (wt: LessonWhiteboardTemplateResponse) => void;
}

const WhiteboardTemplateGalleryCell = (props: WhiteboardTemplateGalleryCellProps) => {
  const { lessonWhiteboardTemplate, onLessonWhiteboardTemplateClicked } = props;

  const _onLessonWhiteboardTemplateClicked = () => {
    onLessonWhiteboardTemplateClicked(lessonWhiteboardTemplate);
  };

  return (
    <Box
      onClick={_onLessonWhiteboardTemplateClicked}
      tabIndex={0}
      sx={(theme) => ({
        position: 'relative',
        borderRadius: theme.spacing(1),
        display: 'flex',
        flexFlow: 'column',
        padding: theme.spacing(2),
        gap: 1,
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: hootTokens.palette.white,
        ...hootTokens.elevation.get(1),
      })}
    >
      <Icon
        sx={{
          width: '8px',
          height: '8px',
          marginLeft: 'auto',
        }}
        name={lessonWhiteboardTemplate.status === LessonWhiteboardTemplateStatus.InProgress ? 'in_progress' : 'not_started'}
      />
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          borderRadius: theme.spacing(1),
          overflow: 'hidden',
        }}
      >
        <AsyncImage
          draggable={false}
          width="216px"
          style={{
            objectFit: 'contain',
            width: '100%',
            borderRadius: '8px',
            minHeight: '216px',
          }}
          src={lessonWhiteboardTemplate.whiteboardTemplate.imageUrl}
          alt={lessonWhiteboardTemplate.whiteboardTemplate.name}
        />
      </Box>
      <HootTypography isPII={false} variant="bodysmall" sx={{ textAlign: 'center' }}>
        {lessonWhiteboardTemplate.whiteboardTemplate.name}
      </HootTypography>
    </Box>
  );
};

interface TableRow {
  id: string;
  title: React.ReactNode;
  state: string;
  instructionalFocuses: string;
  unit: string;
}

const LessonWhiteboardTemplatesTableView = (props: Omit<LessonWhiteboardTemplatesProps, 'paginationMode' | 'query' | 'onClearSearch'>) => {
  const { onLessonWhiteboardTemplateClicked, lessonWhiteboardTemplates, isLoading } = props;

  const headers: HeaderData<TableRow>[] = [
    { name: 'id', property: 'id', isHidden: true },
    { name: 'Title', property: 'title', isSortable: false },
    { name: 'State', property: 'state', isSortable: false },
    { name: 'Focus', property: 'instructionalFocuses', isSortable: false },
    { name: 'Unit', property: 'unit', isSortable: false },
  ];

  const displayData = lessonWhiteboardTemplates.map<TableRow>((wt) => {
    const _onWhiteboardTemplateClicked = () => {
      onLessonWhiteboardTemplateClicked(wt);
    };

    return {
      id: wt.whiteboardTemplate.templateType,
      title: (
        <Stack direction="row" alignItems="center">
          <AsyncImage
            draggable={false}
            width="48px"
            style={{
              objectFit: 'contain',
              width: '48px',
              height: '48px',
              borderRadius: '8px',
            }}
            src={wt.whiteboardTemplate.imageUrl}
            alt={wt.whiteboardTemplate.name}
          />
          <Box sx={{ textDecorationLine: 'underline', pl: 3, py: 2, cursor: 'pointer' }} onClick={_onWhiteboardTemplateClicked}>
            {wt.whiteboardTemplate.name}
          </Box>
        </Stack>
      ),
      state: wt.status === LessonWhiteboardTemplateStatus.InProgress ? 'In progress' : 'Not started',
      instructionalFocuses: wt.whiteboardTemplate.instructionalFocuses.map((x) => x.name).join(', '),
      unit: wt.whiteboardTemplate.instructionalUnits.map((x) => x.name).join(', '),
    };
  });

  return (
    <Card sx={{ marginTop: theme.spacing(1), borderRadius: theme.spacing(1) }} isLoading={isLoading}>
      <TableV2 isPaginated={false} headers={headers} data={displayData} />
    </Card>
  );
};

export default LessonWhiteboardTemplates;
