import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { config } from '../../../config';

interface LessonReviewTaskResponse {
  lessonId: string;
  lessonDate: string;
  lessonNumber: number;
}

async function getActiveReviewTasks(studentProfileId: string): Promise<LessonReviewTaskResponse[]> {
  const { data } = await axios.get<LessonReviewTaskResponse[]>(`${config.apiUrl}/user-task/active-review-tasks`, { params: { studentProfileId } });
  return data;
}

export default function useGetActiveReviewTasks(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<LessonReviewTaskResponse[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetActiveReviewTasks], () => getActiveReviewTasks(studentProfileId), options);
}
