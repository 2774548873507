export const DEFAULT_PAGE_SIZE = 10;

export interface PaginatableQuery {
  page: number;
  pageSize: number;
}

export interface GenericPaginatedResponse<T, U = undefined> {
  page: number;
  pageSize: number;
  count: number;
  data: T[];
  extraData?: U;
}
