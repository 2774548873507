import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useState } from 'react';
import { StepTestAnswer } from '../../../../../../../models/api/enums/hoot-reading-assessment';
import { Button } from '../../../../../../components/v2/core/Button';
import { Icon } from '../../../../../../components/v2/core/Icon';
import { hootTokens } from '../../../../../../theme/v2/tokens';
import { EditAnswerDialog } from '../dialogs/EditAnswerDialog';

export interface Word {
  word: string;
  expectedResponse: string;
}

export function UNIT_1_1(props: { wordList: Word[] }) {
  const [currentIndex, setIndex] = useState(0);
  const [answers, setAnswers] = useState<StepTestAnswer[]>(props.wordList.map<StepTestAnswer>(() => StepTestAnswer.NoAnswer));
  const [showEditAnswerDialog, setShowEditAnswerDialog] = useState<boolean>(false);

  const word = props.wordList[currentIndex];

  const handleCorrectClicked = () => {
    setAnswers((currentState) => currentState.map((a, idx) => (idx === currentIndex ? StepTestAnswer.Correct : a)));
    setIndex((currentState) => (currentState < props.wordList.length - 1 ? (currentState += 1) : currentState));
  };

  const handleIncorrectClicked = () => {
    setAnswers((currentState) => currentState.map((a, idx) => (idx === currentIndex ? StepTestAnswer.Wrong : a)));
    setIndex((currentState) => (currentState < props.wordList.length - 1 ? (currentState += 1) : currentState));
  };

  const handleRowClick = (index: number) => {
    setIndex(index);
    setShowEditAnswerDialog(true);
  };

  const handleEditAnswer = (newAnswer: StepTestAnswer) => {
    setAnswers((currentState) => currentState.map((a, idx) => (idx === currentIndex ? newAnswer : a)));
    setIndex((currentState) => (currentState < props.wordList.length - 1 ? (currentState += 1) : currentState));
    setShowEditAnswerDialog(false);
  };

  const handleCancelEdit = () => {
    setShowEditAnswerDialog(false);
  };

  return (
    <Stack>
      <Stack marginTop="32px" direction="row" justifyContent="space-evenly">
        <WordPrompt
          word={word.word}
          expectedResponse={word.expectedResponse}
          onCorrectClick={handleCorrectClicked}
          onIncorrectClick={handleIncorrectClicked}
        />
        <ScoreCard words={props.wordList} answers={answers} currentIndex={currentIndex} onRowClick={handleRowClick} />
      </Stack>
      <EditAnswerDialog
        open={showEditAnswerDialog}
        questionLabel={props.wordList[currentIndex].word}
        defaultAnswer={answers[currentIndex]}
        onSubmit={handleEditAnswer}
        onCancel={handleCancelEdit}
      />
    </Stack>
  );
}

export function Module_1_1_Example() {
  return (
    <Stack
      width="263px"
      spacing="16px"
      sx={{
        backgroundColor: hootTokens.surface[2],
        borderRadius: '8px',
        padding: '24px',
      }}
    >
      <Stack direction="row" justifyContent="flex-end">
        <TeacherSpeechBubble text='Tell me all the sounds in "rest".' />
      </Stack>

      <Stack direction="row" justifyContent="flex-start">
        <StudentSpeechBubble text="/r/ /ĕ/ /s/ /t/" />
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <TeacherSpeechBubble text="Correct!" />
      </Stack>
    </Stack>
  );
}

function TeacherSpeechBubble(props: { text: string }) {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      sx={{
        padding: '8px',
        backgroundColor: hootTokens.palette.warning[180],
        borderRadius: '8px',
        boxShadow: hootTokens.elevation.elevation1,
      }}
    >
      <Typography variant="labelsmall">{props.text}</Typography>
    </Stack>
  );
}

function StudentSpeechBubble(props: { text: string }) {
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      sx={{
        padding: '8px',
        backgroundColor: hootTokens.palette.success[180],
        borderRadius: '8px',
        boxShadow: hootTokens.elevation.elevation1,
      }}
    >
      <Typography variant="labelsmall">{props.text}</Typography>
    </Stack>
  );
}

function WordPrompt(props: { word: string; expectedResponse: string; onCorrectClick: () => void; onIncorrectClick: () => void }) {
  return (
    <Stack
      flex={1}
      sx={{
        borderRadius: '8px',
        border: `solid 1px ${hootTokens.palette.black}`,
        padding: '24px',
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        padding="16px 0px 16px 0px"
        sx={{
          backgroundColor: hootTokens.surface[2],
          borderRadius: '8px',
        }}
      >
        <Typography variant="bodysmall">Tell me all of the sounds you hear in:</Typography>
        <Typography variant="displaysmall">{props.word}</Typography>
      </Stack>
      <Stack marginTop="16px" alignItems="center">
        <Typography variant="labelsmall">Expected Response</Typography>
        <Box
          sx={{
            backgroundColor: hootTokens.surface[2],
            borderRadius: '8px',
            padding: '8px',
          }}
        >
          <Typography variant="labelsmall">{props.expectedResponse}</Typography>
        </Box>
      </Stack>
      <Stack marginTop="16px" direction="row" justifyContent="center" spacing="16px">
        <Button color="error.190" startIcon={<Icon name="close_filled_square" />} variant="contained" onClick={props.onIncorrectClick}>
          Incorrect
        </Button>
        <Button
          color="success.190"
          startIcon={<Icon htmlColor={hootTokens.palette.success[80]} name="check_box_filled" />}
          variant="contained"
          onClick={props.onCorrectClick}
        >
          Correct
        </Button>
      </Stack>
    </Stack>
  );
}

function ScoreCard(props: { words: Word[]; answers: StepTestAnswer[]; currentIndex: number; onRowClick: (index: number) => void }) {
  return (
    <Stack marginLeft="120px" flex={1} padding="24px" spacing="24px">
      <Typography variant="tableheadingactive">SCORECARD</Typography>
      {props.words.map((w, idx) => (
        <ScoreCardRow
          key={w.word}
          index={idx + 1}
          isCurrentWord={idx === props.currentIndex}
          word={w.word}
          answer={props.answers[idx]}
          onClick={props.onRowClick}
        />
      ))}
    </Stack>
  );
}

function ScoreCardRow(props: { index: number; isCurrentWord: boolean; word: string; answer: StepTestAnswer; onClick: (index: number) => void }) {
  const handleRowClick = () => {
    props.onClick(props.index - 1);
  };

  function Prefix() {
    if (props.isCurrentWord) {
      return <Icon sx={{ height: '16px' }} name="play" />;
    }

    return `${props.index}.`;
  }

  function PostFixIcon() {
    switch (props.answer) {
      case StepTestAnswer.NoAnswer:
        return null;
      case StepTestAnswer.Correct:
        return <Icon sx={{ height: '24px', width: '24x' }} htmlColor={hootTokens.palette.success[80]} name="check_box_filled" />;
      case StepTestAnswer.Wrong:
        return <Icon sx={{ height: '20px', width: '20px' }} htmlColor={hootTokens.palette.error[80]} name="close_filled_square" />;
    }
  }

  return (
    <Stack
      sx={{ cursor: props.answer !== StepTestAnswer.NoAnswer ? 'pointer' : undefined }}
      direction="row"
      alignItems="center"
      spacing="16px"
      onClick={props.answer !== StepTestAnswer.NoAnswer ? handleRowClick : undefined}
    >
      <Typography
        sx={{ color: props.answer === StepTestAnswer.NoAnswer ? hootTokens.palette.neutral[140] : hootTokens.palette.black }}
        variant="bodylarge"
      >
        <Prefix /> {props.word}
      </Typography>
      <PostFixIcon />
    </Stack>
  );
}
