import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTime } from 'luxon';
import { ControllerFieldState } from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types';
import { SectionField } from '@hoot/hooks/api/assessment/useGetAssessmentUnit';
import TimePicker from '@hoot/ui/components/v2/core/TimePicker';

interface ISecondsTimePickerProps {
  sectionField: SectionField;
  onChange: (val: number) => void;
  value: number;
  fieldState: ControllerFieldState;
}

const secondsToDate = (timeInSeconds: number): DateTime | null => {
  if (!timeInSeconds) return null;
  return DateTime.fromSeconds(timeInSeconds);
};

const handleHelperText = (error: FieldError | undefined): string => {
  if (error?.type === 'max') {
    return `Maximum time is 20:00 minutes`;
  }
  if (error) {
    return `Field is required`;
  }
  return 'MM:SS';
};

const SecondsTimePicker = (props: ISecondsTimePickerProps) => {
  const { sectionField, onChange, value, fieldState } = props;

  const handleChange = (newValue: DateTime | null) => {
    const minutes = newValue?.minute || 0;
    const seconds = newValue?.second || 0;
    const timeInSeconds = minutes * 60 + seconds;
    onChange(timeInSeconds);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <TimePicker
        ampm={false}
        views={['minutes', 'seconds']}
        format="mm:ss"
        label={sectionField.label}
        value={secondsToDate(value)}
        onChange={handleChange}
        error={!!fieldState.error}
        helperText={handleHelperText(fieldState.error)}
      />
    </LocalizationProvider>
  );
};

export default SecondsTimePicker;
