import { DistrictRepAccessLevel } from '@hoot/hooks/api/user/useGetDistrictRepPermissions';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { hasDistrictPermission } from '@hoot/routes/secure/ProtectedRoute';
import { NavList } from '@hoot/ui/components/v2/core/NavMenuList';
import { LIVE_CHAT_TITLE, zendeskOpenLiveChat } from '@hoot/utils/zendesk-methods';

const districtRepNavItems = (districtRepPermission?: DistrictRepAccessLevel): NavList[] => {
  const hasEveryonePermission = hasDistrictPermission(DistrictRepAccessLevel.Everyone, districtRepPermission);
  const hasSchoolOrDistrictPermission = hasDistrictPermission(DistrictRepAccessLevel.SchoolOrDistrict, districtRepPermission);

  if (hasSchoolOrDistrictPermission) {
    return [
      { label: routesDictionary.dashboard.label, url: routesDictionary.dashboard.url },
      { label: routesDictionary.schools.label, url: routesDictionary.schools.url },
      { label: routesDictionary.students.label, url: routesDictionary.students.url },
      { label: routesDictionary.schedule.label, url: routesDictionary.schedule.url },
      { label: routesDictionary.enrolments.label, url: routesDictionary.enrolments.url },
      { label: routesDictionary.teamMembers.label, url: routesDictionary.teamMembers.url },
      { label: LIVE_CHAT_TITLE, url: '', onClick: () => zendeskOpenLiveChat() },
      { label: routesDictionary.settings.label, url: routesDictionary.settings.url },
    ];
  } else if (hasEveryonePermission) {
    return [
      { label: routesDictionary.dashboard.label, url: routesDictionary.dashboard.url },
      { label: routesDictionary.students.label, url: routesDictionary.students.url },
      { label: routesDictionary.schedule.label, url: routesDictionary.schedule.url },
      { label: routesDictionary.enrolments.label, url: routesDictionary.enrolments.url },
      { label: LIVE_CHAT_TITLE, url: '', onClick: () => zendeskOpenLiveChat() },
      { label: routesDictionary.settings.label, url: routesDictionary.settings.url },
    ];
  }
  return [
    { label: routesDictionary.dashboard.label, url: routesDictionary.dashboard.url },
    { label: LIVE_CHAT_TITLE, url: '', onClick: () => zendeskOpenLiveChat() },
    { label: routesDictionary.settings.label, url: routesDictionary.settings.url },
  ];
};

export default districtRepNavItems;
