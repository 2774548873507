import { Box, Dialog, DialogContent, DialogTitle, Skeleton, Stack } from '@mui/material';
import { ReactNode, useState } from 'react';
import { Tldraw, loadSnapshot } from 'tldraw';
import 'tldraw/tldraw.css';
import useGetLessonWhiteboardSnapshot, { WhiteboardStoreSnapshotResponse } from '@hoot/hooks/api/whiteboard/useGetLessonWhiteboardSnapshot';
import useGetLessonWhiteboards, { LessonWhiteboardResponse } from '@hoot/hooks/api/whiteboard/useGetLessonWhiteboards';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';

interface LessonWhiteboardCardProps {
  lessonId: string | null;
}

interface LessonWhiteboardTableRow {
  id: string;
  templateImageUrl: string;
  title: ReactNode;
  instructionalFocuses: string;
  instructionalUnits: string;
  viewButton: ReactNode;
}

const headers: HeaderData<LessonWhiteboardTableRow>[] = [
  { name: 'Title', property: 'title' },
  { name: 'Focus', property: 'instructionalFocuses' },
  { name: 'Unit', property: 'instructionalUnits' },
  { name: '', property: 'viewButton' },
];

const LessonWhiteboardsCard = (props: LessonWhiteboardCardProps) => {
  const { lessonId } = props;

  const [whiteboardTableRows, setWhiteboardTableRows] = useState<LessonWhiteboardTableRow[]>([]);
  const [selectedWhiteboardId, setSelectedWhiteboardId] = useState<string>();
  const [whiteboardSnapshotResponse, setWhiteboardSnapshotResponse] = useState<WhiteboardStoreSnapshotResponse>();

  const { isFetching } = useGetLessonWhiteboards(lessonId ?? '', {
    enabled: !!lessonId,
    onSuccess: (response) => {
      const whiteboardTableRows = response.map<LessonWhiteboardTableRow>((wb) => ({
        id: wb.whiteboardId,
        templateImageUrl: wb.whiteboardTemplate.imageUrl,
        title: (
          <Stack direction="row" sx={{ alignItems: 'center', gap: 3 }}>
            <img width={48} height={48} src={wb.whiteboardTemplate.imageUrl} style={{ objectFit: 'contain' }} alt={wb.whiteboardTemplate.name} />
            <span>{wb.whiteboardTemplate.name}</span>
          </Stack>
        ),
        instructionalUnits: wb.whiteboardTemplate.instructionalUnits.map((x) => x.name).join(', '),
        instructionalFocuses: wb.whiteboardTemplate.instructionalFocuses.map((x) => x.name).join(', '),
        viewButton: (
          <Button variant="outlined" size="small" onClick={() => onViewWhiteboard(wb)}>
            View
          </Button>
        ),
      }));
      setWhiteboardTableRows(whiteboardTableRows);
    },
  });

  useGetLessonWhiteboardSnapshot(selectedWhiteboardId!, {
    enabled: !!selectedWhiteboardId,
    onSuccess: (response) => {
      setWhiteboardSnapshotResponse(response);
    },
  });

  const onViewWhiteboard = (wb: LessonWhiteboardResponse) => {
    setSelectedWhiteboardId(wb.whiteboardId);
  };

  const onDismissWhiteboard = () => {
    setWhiteboardSnapshotResponse(undefined);
    setSelectedWhiteboardId(undefined);
  };

  // If the lesson ID is missing, then we haven't had a change to fetch the whiteboards yet. Assume we are in the "loading" state.
  const isLoading = !lessonId || isFetching;

  const viewState: ViewStateEnum = isLoading ? ViewStateEnum.Loading : ViewStateEnum.Results;

  return (
    <>
      <Card isLoading={isLoading}>
        <Stack gap={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Icon name="whiteboard" />
            <HootTypography isPII={false} variant="titlemedium">
              Whiteboards
            </HootTypography>
          </Stack>
          <ViewState state={viewState} loadingContent={<TableSkeleton />}>
            <TableV2
              headers={headers}
              data={whiteboardTableRows}
              emptyViewState={<ViewStateIllustration illustration={IllustrationEnum.EmptyState} />}
            />
          </ViewState>
        </Stack>
      </Card>

      {!!whiteboardSnapshotResponse && (
        <>
          <Dialog open={!!whiteboardSnapshotResponse?.storeSnapshot} onClose={onDismissWhiteboard} fullWidth maxWidth="xl">
            <DialogTitle>Whiteboard - {whiteboardSnapshotResponse.templateName}</DialogTitle>
            <DialogContent>
              <Box
                className="tldraw__editor"
                sx={{
                  height: '80vh',
                }}
              >
                <Tldraw
                  components={{
                    StylePanel: null,
                    PageMenu: null,
                    DebugPanel: null,
                    DebugMenu: null,
                    SharePanel: null,
                    CursorChatBubble: null,
                  }}
                  onMount={(editor) => {
                    loadSnapshot(editor.store, { document: whiteboardSnapshotResponse.storeSnapshot });
                    editor.updateInstanceState({ isReadonly: true });
                    editor.zoomToFit();
                  }}
                />
              </Box>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

const TableSkeleton = () => (
  <>
    <Stack>
      <Stack direction="row" gap={2}>
        <Skeleton variant="rectangular" sx={{ flex: 1 }} />
        <Skeleton variant="rectangular" sx={{ flex: 1 }} />
        <Skeleton variant="rectangular" sx={{ flex: 1 }} />
      </Stack>
      <Stack direction="row" gap={2} mt={2}>
        <Skeleton variant="text" sx={{ flex: 1 }} />
        <Skeleton variant="text" sx={{ flex: 1 }} />
        <Skeleton variant="text" sx={{ flex: 1 }} />
      </Stack>
      <Stack direction="row" gap={2}>
        <Skeleton variant="text" sx={{ flex: 1 }} />
        <Skeleton variant="text" sx={{ flex: 1 }} />
        <Skeleton variant="text" sx={{ flex: 1 }} />
      </Stack>
    </Stack>
  </>
);

export default LessonWhiteboardsCard;
