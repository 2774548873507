import { useState } from 'react';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import { LibraryOrderByEnum, QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import { InstructionalFocus } from '@hoot/models/api/enums/instructional-focus';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { ShelfType } from '@hoot/models/api/enums/shelf-type-enum';
import { DEFAULT_PAGE } from '@hoot/redux/reducers/librarySlice';
import { HeaderData } from '@hoot/ui/components/v2/core/Table';
import BookPickerModal, { BookSelectorTableRow } from './BookPickerModal';

export interface InstructionalFocusBookPickerProps {
  show: boolean;
  onDismiss: () => void;
  onApply: (selectedBook: BookSearch) => void;
  isLoading?: boolean;
  existingBookIds: string[];
  isFormativeAssessment?: boolean;
  isInstructionalFocus?: boolean;
  scheduledLessonId?: string;
}

const PAGE_SIZE = 5;

const FormativeAssessmentBookPicker = (props: InstructionalFocusBookPickerProps) => {
  const { show, onDismiss, onApply, isLoading = false, existingBookIds, scheduledLessonId } = props;

  const libraryFilters: QueryLibraryV2 = {
    shelfType: ShelfType.AllBooks,
    page: DEFAULT_PAGE,
    pageSize: PAGE_SIZE,
    instructionalFocusIds: undefined,
    excludedInstructionalFocusIds: [
      InstructionalFocus.FormativeAssessmentTextReading,
      InstructionalFocus.FormativeAssessmentEarlyWordReading,
      InstructionalFocus.FormativeAssessmentComplexWordReading,
      InstructionalFocus.FormativeAssessmentPreWordReading,
    ],
    title: '',
    scheduledLessonId: scheduledLessonId,
    orderColumn: LibraryOrderByEnum.Title,
    orderBy: OrderBy.Asc,
  };

  const [libraryQuery, setLibraryQuery] = useState<QueryLibraryV2>(libraryFilters);

  const headers: HeaderData<BookSelectorTableRow>[] = [
    { name: 'Cover', property: 'cover', isSortable: false },
    { name: 'Book Title', property: 'title', isSortable: true },
    { name: 'Book Level', property: 'bookLevel', isSortable: true },
  ];

  return (
    <BookPickerModal
      key={`instructional-focus-book-picker-${show}`}
      show={show}
      onApply={onApply}
      onDismiss={onDismiss}
      libraryQuery={libraryQuery}
      setLibraryQuery={setLibraryQuery}
      headers={headers}
      isLoading={isLoading}
      existingBookIds={existingBookIds}
    />
  );
};

export default FormativeAssessmentBookPicker;
