import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { User } from '../../../models/api/user';
import { QueryKey } from '../queryKeys';

async function getUser(userId: string): Promise<User> {
  const { data } = await axios.get<User>(`${config.apiUrl}/user/${userId}`);
  return data;
}

export default function useGetUser(userId: string, options?: Omit<UseQueryOptions<User>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.FetchUser, userId], () => getUser(userId), options);
}
