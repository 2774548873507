import { Stack } from '@mui/material';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';

const InvoicingReminder = () => {
  return (
    <Card>
      <Stack gap={3}>
        <HootTypography isPII={false} variant="titlemedium">
          <img style={{ verticalAlign: 'middle', marginRight: '10px' }} height={24} width={24} src="/images/hoot-wheel-logo.svg" alt="hoot-wheel" />A
          Reminder On Invoicing
        </HootTypography>
        <HootTypography isPII={false} variant="bodylarge">
          Invoices are processed on the first business day of every new month, excluding Canadian civic holidays.
        </HootTypography>
        <HootTypography isPII={false} variant="bodylarge">
          Please submit your invoice before the <strong>last day of the month at 11:59PM Central Time</strong> to ensure payment for the billable
          period.
        </HootTypography>
      </Stack>
    </Card>
  );
};

export default InvoicingReminder;
