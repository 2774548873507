import { ButtonBase, ButtonBaseProps } from '@mui/material';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export const plainButtonStyles = {
  root: {
    ...hootTokens.text.headlinelarge,
    padding: 3,
    minWidth: '248px',
    borderRadius: '4px 0px 0px 4px',
    background: hootTokens.surface['1'],
    boxShadow: hootTokens.elevation.elevation3.boxShadow,
  },
  disabled: {
    opacity: 0.2,
  },
  focused: {
    border: '1px solid',
    borderColor: hootTokens.palette.secondary['80'],
    background: hootTokens.surface['2'],
  },
  hover: {
    background: hootTokens.surface['2'],
  },
  active: {
    background: hootTokens.palette.primary['160'],
  },
};

const PlainButton = (props: ButtonBaseProps) => {
  const { sx, ...rest } = props;
  return (
    <ButtonBase
      disableRipple
      sx={{
        ...plainButtonStyles.root,
        ':disabled': {
          ...plainButtonStyles.disabled,
        },
        '&:focus-visible': {
          ...plainButtonStyles.focused,
        },
        ':hover': {
          ...plainButtonStyles.hover,
        },
        ':active': {
          ...plainButtonStyles.active,
        },
        ...sx,
      }}
      {...rest}
    />
  );
};

export default PlainButton;
