import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { NotificationMessage } from '@hoot/events/messages/notification-message';
import { QueryKey } from '../queryKeys';

export default function useGetBookmarkedNotifications(options?: Omit<UseQueryOptions<NotificationMessage[]>, 'queryKey' | 'queryFn'>) {
  return useQuery(
    [QueryKey.GetBookmarkedNotifications],
    async () => {
      return (await axios.get<NotificationMessage[]>(`${config.apiUrl}/reader/notifications/bookmarks`)).data;
    },
    options,
  );
}
