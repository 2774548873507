import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Box, SxProps } from '@mui/material';
import { lessonStatusBGColor } from '@hoot/ui/components/v2/utils/lessonUtil';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export type CalendarDayValue =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25'
  | '26'
  | '27'
  | '28'
  | '29'
  | '30'
  | '31';

export type CalendarDayProps = {
  selected?: boolean;
  isDisabled?: boolean;
  status?: ScheduledLessonStatus;
  value: CalendarDayValue;
  onClick?: () => void;
};

function applyStyles(props: CalendarDayProps): SxProps {
  const styles: SxProps = {
    ...hootTokens.text.labellarge,
    color: props.isDisabled ? hootTokens.palette.neutral[140] : hootTokens.palette.black,
    borderRadius: '100px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    width: '40px',
    padding: 0,
    margin: 0,
    textAlign: 'center',
    cursor: 'pointer',
  };

  if (props.selected) {
    styles.border = `4px solid ${hootTokens.palette.primary[0]}`;
  }

  if (props.status) {
    styles.backgroundColor = lessonStatusBGColor(props.status);
  }

  return styles;
}

export function CalendarDay(props: CalendarDayProps) {
  const styles = applyStyles(props);
  const onClickFn = props.onClick ? props.onClick : () => null;

  return (
    <Box onClick={onClickFn} sx={styles}>
      {props.value}
    </Box>
  );
}
