import { Skeleton } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import useGetLessonNote from '@hoot/hooks/api/lesson-reviews/useGetLessonNotes';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';

interface ILessonNotesDialogProps {
  showDialog: boolean;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  reviewId: string;
  studentProfileId?: string;
}

const LessonNotesDialog = (props: ILessonNotesDialogProps) => {
  const { showDialog, setShowDialog, reviewId, studentProfileId } = props;

  const { data, isFetching } = useGetLessonNote(studentProfileId!, reviewId);

  const handleDismiss = () => setShowDialog(false);

  const lessonDate = useMemo(() => {
    if (!data?.lessonDate) {
      return undefined;
    }
    return DateTime.fromMillis(data.lessonDate).toFormat('cccc, LLLL dd');
  }, [data?.lessonDate]);

  const dialogContent = () => {
    return (
      <>
        {isFetching ? (
          <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: '8px', height: '160px' }} />
        ) : (
          <ReadOnlyTextField label="Lesson Notes" body={data?.lessonNote ?? ''} sx={{ minHeight: '160px' }} />
        )}
      </>
    );
  };

  return (
    <BasicAlertDialog
      title={lessonDate ? `Lesson Note - ${lessonDate}` : `Lesson Note`}
      show={showDialog}
      onDismiss={handleDismiss}
      content={dialogContent()}
      primaryAction={{
        onClick: handleDismiss,
        label: 'Close',
        props: { variant: 'outlined' },
      }}
    />
  );
};

export default LessonNotesDialog;
