import { Stack } from '@mui/system';
import React from 'react';
import { SectionField } from '@hoot/hooks/api/assessment/useGetAssessmentUnit';
import { HootAssessmentFieldType, StepTestAnswer } from '@hoot/models/api/enums/hoot-reading-assessment';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Tag, { TagColor } from '@hoot/ui/components/v2/core/Tag';
import CheckmarkSquare from '@hoot/ui/components/v2/icons/CheckmarkSquare';
import CloseFilledSquareIcon from '@hoot/ui/components/v2/icons/CloseFilledSquareIcon';
import { useHootAssessmentContext } from '@hoot/ui/pages/v2/teacher/hoot-reading-assessment/HootAssessmentContext';

interface FocusedStepTestFieldCardProps {
  sectionField: SectionField;
  currentStepperIndex: number;
}

const FocusedStepTestFieldCard = (props: FocusedStepTestFieldCardProps) => {
  const { sectionField, currentStepperIndex } = props;

  const { form, transitionFocusToNextStepTestQuestion } = useHootAssessmentContext();
  const value: StepTestAnswer[] = form.watch(sectionField.name);

  const showAmount = sectionField.unfurl!.segments.slice(0, currentStepperIndex + 1).reduce((a, b) => a + b.amount, 0);

  const handleAnswerSelect = (newVal: StepTestAnswer) => {
    const updatedValue = value.map((val, idx) => {
      if (idx === currentStepperIndex) {
        return newVal;
      } else if (idx >= showAmount) {
        return StepTestAnswer.NoAnswer;
      }
      return val;
    });
    form.setValue(sectionField.name, updatedValue, { shouldDirty: true });

    transitionFocusToNextStepTestQuestion();
  };

  const getSubtitle = () => {
    const segment = sectionField.unfurl?.segments.find((s) => {
      return s.start <= currentStepperIndex + 1 && currentStepperIndex + 1 <= s.end;
    });
    return segment?.subtitle;
  };
  const subtitle = getSubtitle();
  const label = sectionField?.allowedValues?.[currentStepperIndex ?? 0]?.toString()?.replace(':', ' ');

  return (
    <Card contentSx={{ padding: 4 }}>
      <Stack alignItems="center" gap={4}>
        {subtitle && <HootTypography isPII={false}>{subtitle}</HootTypography>}

        <HootTypography isPII={false} variant="displaysmall" textAlign="center">
          {sectionField.type === HootAssessmentFieldType.StepTest ? label : sectionField.label}
        </HootTypography>

        {sectionField.expectedResponse && (
          <Stack alignItems="center" gap={1}>
            <HootTypography isPII={false} variant="labelsmall">
              Expected Response
            </HootTypography>
            <Tag label={sectionField.expectedResponse} color={TagColor.Success} />
          </Stack>
        )}

        <Stack flexDirection="row" gap={2}>
          <Button
            onClick={() => handleAnswerSelect(StepTestAnswer.Wrong)}
            variant="contained"
            color="error.190"
            disableElevation
            startIcon={<CloseFilledSquareIcon />}
          >
            Incorrect
          </Button>
          <Button
            onClick={() => handleAnswerSelect(StepTestAnswer.Correct)}
            variant="contained"
            color="success.190"
            disableElevation
            startIcon={<CheckmarkSquare />}
          >
            Correct
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default FocusedStepTestFieldCard;
