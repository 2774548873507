import { useEffect, useRef } from 'react';
import { useAuth } from '@hoot/ui/context/AuthContext';

const Logout = () => {
  const isLoggingOut = useRef(false);

  const auth = useAuth();
  useEffect(() => {
    if (!isLoggingOut.current) {
      isLoggingOut.current = true;
      auth.logout();
    }
  }, [auth]);

  // This component doesn't render anything.
  return null;
};

export default Logout;
