import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

interface Project {
  id: string;
  projectCode: string;
}

export interface InvoiceProjectsResponse {
  projects: Project[];
}

async function getInvoiceProjects() {
  const { data } = await axios.get<InvoiceProjectsResponse>(`${config.apiUrl}/public/teacher-invoicing/projects`);
  return data;
}

export function useGetInvoiceProjects(options?: Omit<UseQueryOptions<InvoiceProjectsResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetInvoiceProjects], () => getInvoiceProjects(), options);
}
