import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface BookCollectionResponse {
  collections: BookCollection[];
}

export interface BookCollection {
  id: string;
  name: string;
}

async function getBookCollections(): Promise<BookCollectionResponse> {
  const { data } = await axios.get<BookCollectionResponse>(`${config.apiUrl}/library/book-collections`);
  return data;
}

export default function useGetBookCollections(options?: Omit<UseQueryOptions<BookCollectionResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetBookCollections], () => getBookCollections(), options);
}
