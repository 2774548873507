import { SvgIcon, SvgIconProps } from '@mui/material';

const BeakerIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.4"
        d="M9.99991 2.00024H13.9999V7.52811C13.9999 7.8386 14.0722 8.14482 14.2111 8.42254L19.5527 19.1058C20.2176 20.4356 19.2506 22.0002 17.7638 22.0002H6.23598C4.74921 22.0002 3.78222 20.4356 4.44712 19.1058L9.78877 8.42253C9.92762 8.14482 9.99991 7.8386 9.99991 7.52811V2.00024Z"
        fill="#6CB944"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 2.00024C8.25 1.58603 8.58579 1.25024 9 1.25024H15C15.4142 1.25024 15.75 1.58603 15.75 2.00024C15.75 2.41446 15.4142 2.75024 15 2.75024H9C8.58579 2.75024 8.25 2.41446 8.25 2.00024Z"
        fill="#6CB944"
      />
      <path
        d="M6.23598 22.0002H17.7638C19.2506 22.0002 20.2176 20.4356 19.5527 19.1058L16.4999 13.0002H7.49991L4.44712 19.1058C3.78222 20.4356 4.74921 22.0002 6.23598 22.0002Z"
        fill="#6CB944"
      />
    </SvgIcon>
  );
};

export default BeakerIcon;
