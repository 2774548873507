import { DialogActions, DialogContent } from '@mui/material';
import { useQueryClient } from 'react-query';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { useLessonReviewWizardContext } from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';

const LessonReviewCompletedStep = () => {
  const {
    actions: { dismissLessonReviewWizard },
  } = useLessonReviewWizardContext();
  const queryClient = useQueryClient();

  const handleDoneClick = () => {
    queryClient.invalidateQueries(QueryKey.GetRecentReviews);
    queryClient.invalidateQueries(QueryKey.GetReviewHistory);
    dismissLessonReviewWizard();
  };

  return (
    <>
      <DialogContent>
        <ViewStateIllustration
          illustration={IllustrationEnum.SetupComplete}
          title="Post Lesson Review Completed!"
          subtitle="You will be able to edit your review for 24 hours"
          padding={0}
        />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button variant="contained" color="secondary.60" onClick={handleDoneClick}>
          Done
        </Button>
      </DialogActions>
    </>
  );
};

export default LessonReviewCompletedStep;
