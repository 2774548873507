import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSendPasswordResetEmail } from '@hoot/hooks/api/auth/useSendPasswordResetEmail';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { IconButton } from '@hoot/ui/components/v2/core/IconButton';
import TextField from '@hoot/ui/components/v2/core/TextField';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Form {
  email: string;
}

const ForgotPasswordModalEmail = (props: IProps) => {
  const { open, setOpen } = props;
  const sendPasswordResetEmailMutation = useSendPasswordResetEmail();
  const dispatch = useAppDispatch();

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const {
    formState: { errors },
    handleSubmit,
    setError,
    reset,
    control,
    getValues,
  } = useForm<Form>({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: Form) => {
    sendPasswordResetEmailMutation.mutateAsync(data.email, {
      onSuccess: () => {
        setIsSubmitted(true);
      },
      onError: () => {
        setError('email', {
          type: 'manual',
          message: 'An unexpected error occurred. Please try again.',
        });
        dispatch(createFlashMessage({ message: 'Failed to send password reset link.', variant: 'error' }));
      },
    });
  };

  const onClose = () => {
    setOpen(false);
    reset();
    setIsSubmitted(false);
  };

  const EmptyState = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          id="dialog-title"
          data-hoot-canary-id="forgot-password-title"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <HootTypography isPII={false} variant="titlelarge">
            Password Reset
          </HootTypography>
          <IconButton onClick={onClose}>
            <Icon name="close" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', gap: '24px' }}>
          <DialogContentText
            id="dialog-description"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              gap: '24px',
            }}
          >
            <HootTypography isPII={false} variant="bodylarge">
              Enter email address to change your password.
            </HootTypography>
          </DialogContentText>
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'This field is required',
              pattern: {
                value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: 'Not a valid email address',
              },
            }}
            render={({ field: { onChange }, fieldState }) => (
              <TextField
                data-hoot-canary-id="email"
                error={!!fieldState.error}
                helperText={fieldState.error?.message || ''}
                label={'Email Address'}
                fullWidth
                required
                onChange={onChange}
                type="email"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" disabled={!!errors.email} isLoading={sendPasswordResetEmailMutation.isLoading}>
            Submit
          </Button>
        </DialogActions>
      </form>
    );
  };

  const SubmittedState = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          id="dialog-title"
          data-hoot-canary-id="forgot-password-title"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <HootTypography isPII={false} variant="titlelarge">
            Email Sent
          </HootTypography>
          <IconButton onClick={onClose}>
            <Icon name="close" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', gap: '24px' }}>
          <DialogContentText
            id="dialog-description"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              gap: '24px',
            }}
          >
            <HootTypography isPII={true} variant="bodylarge">
              We sent an email to <strong>{getValues('email')}</strong> with a link to update your password.
            </HootTypography>
            <HootTypography isPII={false} variant="bodylarge">
              If the email hasn't arrived within two minutes, please check your spam folder.
            </HootTypography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled={!!errors.email} onClick={onClose}>
            Ok
          </Button>
        </DialogActions>
      </form>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title" aria-describedby="dialog-description" fullWidth>
      {isSubmitted ? <SubmittedState /> : <EmptyState />}
    </Dialog>
  );
};

export default ForgotPasswordModalEmail;
