import { FormControl, FormControlProps, InputBase, InputBaseProps } from '@mui/material';
import { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieButton from '@hoot/ui/components/v2/lottie/LottieButton';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export interface StudentTextFieldProps extends FormControlProps {
  tooltipLabel: string;
  InputProps: InputBaseProps;
  onSearchButtonClick: () => void;
}

const StudentSearchTextField = (props: StudentTextFieldProps) => {
  const { tooltipLabel, onSearchButtonClick, InputProps, ...formControlProps } = props;
  return (
    <FormControl
      sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        '&.Mui-disabled': {
          opacity: 0.2,
        },
      }}
      {...formControlProps}
    >
      <InputBase
        fullWidth={formControlProps.fullWidth}
        sx={{
          ...hootTokens.text.headlinelarge,
          p: 3,
          pl: 14,
          borderRadius: '4px 0px 0px 4px',
          background: hootTokens.surface['1'],
          boxShadow: hootTokens.elevation.elevation3,
          ...(!formControlProps.disabled && {
            '&:hover': {
              background: hootTokens.surface['2'],
            },
          }),
          '&.Mui-focused': {
            outline: '1px solid',
            outlineColor: hootTokens.palette.secondary['80'],
          },
        }}
        {...InputProps}
      />
      <LottieButton
        disabled={formControlProps.disabled}
        lottieWidth={64}
        lottieHeight={64}
        lottieFile={LottieFile.Search}
        tooltipLabel={tooltipLabel}
        aria-label={tooltipLabel}
        variant="transparent"
        onClick={onSearchButtonClick}
        rootStyle={{ position: 'absolute', top: 0, bottom: 0, left: 24 }}
        sx={{ p: 0, position: 'absolute', top: 0, bottom: 0 }}
      />
    </FormControl>
  );
};

export default StudentSearchTextField;
