import { SvgIcon, SvgIconProps } from '@mui/material';

const CameraOffIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5303 2.53033C22.8232 2.23744 22.8232 1.76256 22.5303 1.46967C22.2374 1.17678 21.7626 1.17678 21.4697 1.46967L1.46967 21.4697C1.17678 21.7626 1.17678 22.2374 1.46967 22.5303C1.76256 22.8232 2.23744 22.8232 2.53033 22.5303L4.39548 20.6652C4.88664 20.8805 5.42937 21 6 21H18C20.2091 21 22 19.2091 22 17V10C22 8.11738 20.6994 6.53854 18.9477 6.11294L22.5303 2.53033ZM13.8903 11.1704C14.5674 11.7204 15 12.5597 15 13.5C15 15.1569 13.6569 16.5 12 16.5C11.0597 16.5 10.2204 16.0674 9.67034 15.3903L13.8903 11.1704ZM13.9296 3H10.0704C9.40166 3 8.7772 3.3342 8.40627 3.8906L7 6H6C3.79086 6 2 7.79086 2 10V17C2 17.309 2.03503 17.6098 2.10135 17.8987L15.8 4.2L15.5937 3.8906C15.2228 3.3342 14.5983 3 13.9296 3ZM12 7C11.4477 7 11 6.55228 11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6C13 6.55228 12.5523 7 12 7Z"
        fill={props.htmlColor ?? '#EF4823'}
      />
    </SvgIcon>
  );
};

export default CameraOffIcon;
