import { Box, Link, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect } from 'react';
import { isAndroid, isEdge, isFirefox, isIOS, isSafari } from 'react-device-detect';
import { SAFARI_IOS } from '@hoot/constants/audioVideoSupportLinks';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { getAudioVideoSupportLink } from '@hoot/utils/daily/avSupportLink';
import HootTypography from '../../../core/HootTypography';

interface IPromptProps {
  handleMediaChange?: () => void;
  mediaChangeError?: string;
}

const PromptPermissions = (props: IPromptProps) => {
  const { handleMediaChange } = props;

  // To help the user, this will show the Permissions prompt as well as display the Camera/Mic icon in the URL.
  useEffect(() => {
    if (isIOS) {
      if (handleMediaChange) {
        handleMediaChange();
      }
    }
  }, [handleMediaChange]);

  const getPromptScreenshot = (): JSX.Element => {
    if (isSafari) {
      return <img src={`/images/av-permissions/safari-prompt.png`} alt="permission-prompt-safari" height="200px" />;
    }
    if (isEdge) {
      return <img src={`/images/av-permissions/edge-prompt.png`} alt="permission-prompt-edge" />;
    }
    if (isFirefox) {
      return <img src={`/images/av-permissions/firefox-prompt.png`} alt="permission-prompt-firefox" width="100%" style={{ maxWidth: 390 }} />;
    }
    if (isAndroid) {
      return <img src={`/images/av-permissions/android-chrome-prompt.png`} alt="permission-prompt-android" width="100%" />;
    }

    return <img src={`/images/av-permissions/chrome-prompt.png`} alt="permission-prompt-chrome" width="100%" />;
  };

  return (
    <div>
      {isIOS && (
        <>
          <HootTypography isPII={false}>You must grant Hoot permission to use your camera and microphone.</HootTypography>
          <HootTypography isPII={false}>Please follow the guide in the following link to grant permissions then reload this page.</HootTypography>
          <Link
            href={SAFARI_IOS}
            target="_blank"
            variant="bodylarge"
            sx={{ display: 'block', marginTop: '16px', color: hootTokens.palette.secondary[80] }}
          >
            Apple iPad Support
          </Link>
        </>
      )}
      {!isIOS && (
        <>
          {props.mediaChangeError ? (
            <Stack sx={{ marginBottom: '16px' }}>
              <HootTypography isPII={false} sx={{ color: hootTokens.palette.error[80] }} variant="bodysmall">
                {props.mediaChangeError}
              </HootTypography>
              <HootTypography isPII={false} sx={{ marginTop: '16px' }} variant="bodylarge">
                Please check your device connections. If your devices are properly connected, your computer privacy settings may be blocking the
                device. Check the camera and microphone permissions under System Settings -&gt; Privacy.
                <Link target="_blank" href={getAudioVideoSupportLink()} sx={{ marginLeft: '5px' }}>
                  Learn More
                </Link>
              </HootTypography>
              <HootTypography isPII={false} variant="bodysmall" sx={{ marginTop: '16px' }}>
                Need more help? Contact <Link href="mailto:help@hootreading.com">help@hootreading.com</Link>
              </HootTypography>
            </Stack>
          ) : (
            <>
              <HootTypography isPII={false} sx={{ margin: '16px 0px' }}>
                Hoot requires access to your Camera and Microphone
              </HootTypography>
              <Grid container>
                <Grid container size={{ xs: 7 }} flexDirection="column" justifyContent="flex-start">
                  <Stack
                    sx={{
                      width: '100%',
                      borderRadius: '8px',
                      padding: '8px',
                      minHeight: '240px',
                      backgroundColor: hootTokens.surface[2],
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{ display: 'flex', backgroundColor: '#EAEAEA', borderRadius: '4px', padding: '4px', ...hootTokens.elevation.elevation2 }}
                    >
                      {getPromptScreenshot()}
                    </Box>
                  </Stack>
                  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" margin="8px 0px">
                    <HootTypography isPII={false} variant="bodysmall">
                      Press "Allow" on the popup that is displayed.
                    </HootTypography>
                  </Box>
                </Grid>

                <Grid container size={{ xs: 1 }} justifyContent="center" alignItems="center" />

                <Grid container size={{ xs: 4 }} flexDirection="column" justifyContent="flex-start">
                  <Box
                    sx={{
                      width: '100%',
                      borderRadius: '8px',
                      minHeight: '240px',
                      backgroundColor: hootTokens.surface[2],
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Button onClick={handleMediaChange} variant="contained">
                      Request Permission
                    </Button>
                  </Box>
                  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" margin="8px 0px" textAlign="center">
                    <HootTypography isPII={false} variant="bodysmall">
                      If you did not see the popup, click the "Request Permission" button above.
                    </HootTypography>
                  </Box>
                </Grid>

                <HootTypography isPII={false} textAlign="center" sx={{ marginTop: '32px' }}>
                  If you continue to see this prompt after granting permission, your computer privacy settings may be blocking the device. Check the
                  camera and microphone permissions under System Settings -&gt; Privacy.
                  <Link target="_blank" href={getAudioVideoSupportLink()} sx={{ marginLeft: '5px' }}>
                    Learn More
                  </Link>
                </HootTypography>
              </Grid>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PromptPermissions;
