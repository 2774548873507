import DailyAudioVideoSettingsDialogContextProvider, { DailyAudioVideoSettingsControl } from '@hoot/ui/context/AudioVideoSettingsDialogContext';
import DailyGetReadyV2DialogContextProvider from '@hoot/ui/context/GetReadyV2DialogProvider';

const GetReadyContextWrapper = (props: { children: React.ReactNode }) => {
  return <DailyProviders children={props.children} />;
};

const DailyProviders = (props: { children: React.ReactNode }) => {
  return (
    <DailyAudioVideoSettingsDialogContextProvider>
      <DailyGetReadyV2DialogContextProvider>
        <DailyAudioVideoSettingsControl>{props.children}</DailyAudioVideoSettingsControl>
      </DailyGetReadyV2DialogContextProvider>
    </DailyAudioVideoSettingsDialogContextProvider>
  );
};

export default GetReadyContextWrapper;
