import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { StudentProfileSnippetResponse } from '@hoot/hooks/api/user/useGetStudentProfileSnippet';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';

interface StartAssessmentConfirmationDialogProps {
  show: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  studentProfile: StudentProfileSnippetResponse;
  isToResumeExisting: boolean;
}

const StartAssessmentConfirmationDialog = (props: StartAssessmentConfirmationDialogProps) => {
  const { show, onConfirm, onDismiss, studentProfile, isToResumeExisting } = props;

  const [userUnderstandsNotToMessAround, setUserUnderstandsNotToMessAround] = useState(false);

  // Reset checkbox state when modal is launched.
  useEffect(() => {
    if (show) {
      setUserUnderstandsNotToMessAround(false);
    }
  }, [show]);

  const onToggleUserKnowsWhatsUp = () => {
    setUserUnderstandsNotToMessAround((prev) => !prev);
  };

  return (
    <BasicAlertDialog
      show={show}
      onDismiss={onDismiss}
      title="You are about to begin a real assessment"
      content={
        <Stack gap={2}>
          <HootTypography isPII={false}>
            The information you enter must be real assessment data. It will be recorded on {studentProfile.name}’s profile. Please do not use this
            assessment to “Practice”.
          </HootTypography>
          <Checkbox label="I understand that this cannot be undone" checked={userUnderstandsNotToMessAround} onClick={onToggleUserKnowsWhatsUp} />
        </Stack>
      }
      primaryAction={{
        label: isToResumeExisting ? 'Resume Assessment' : 'Start Assessment',
        onClick: onConfirm,
        props: {
          disabled: !userUnderstandsNotToMessAround,
          color: 'success.60',
        },
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: onDismiss,
      }}
    />
  );
};

export default StartAssessmentConfirmationDialog;
