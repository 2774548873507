import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  FormativeAssessmentSearchResult,
  GetStudentFormativeAssessmentsQuery,
  StudentFormativeAssessmentsQuerySortKeyEnum,
  useGetFormativeAssessments,
} from '@hoot/hooks/api/assessment/useGetStudentFormativeAssessments';
import { FormativeAssessmentType } from '@hoot/models/api/enums/lesson-review-enums';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/models/api/pagination';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';

interface FormativeAssessmentTableRow {
  date: string;
  resourceUsed: string;
  score: string;
}

const headers: HeaderData<FormativeAssessmentTableRow>[] = [
  { name: 'Date', property: 'date', isSortable: false, width: '30%' },
  { name: 'Resource Used', property: 'resourceUsed', isSortable: false, width: '40%' },
  { name: 'Score', property: 'score', isSortable: false, width: '30%' },
];

const LessonReviewFormativeAssessmentsCard = (props: { lessonReviewId?: string }) => {
  const { studentProfileId } = useParams();

  const query: GetStudentFormativeAssessmentsQuery = {
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    orderBy: StudentFormativeAssessmentsQuerySortKeyEnum.Date,
    sortDirection: OrderBy.Desc,
    lessonReviewId: props.lessonReviewId,
  };

  const [results, setResults] = useState<GenericPaginatedResponse<FormativeAssessmentTableRow>>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    data: [],
    count: 0,
  });

  useGetFormativeAssessments(studentProfileId!, query, {
    enabled: !!studentProfileId,
    onSuccess: (response) => {
      setResults({
        ...response,
        data: mapAssessmentResponseToTableRows(response.data),
      });
    },
  });

  const handleScore = (resource: FormativeAssessmentSearchResult): string => {
    const { formativeAssessmentType, accuracy, wordsCorrectPerMinute, numWords, numWordsCorrectly, numLettersCorrectly, numLetters } = resource;
    if (formativeAssessmentType === FormativeAssessmentType.TextReading) {
      return `${accuracy}% Accuracy, ${wordsCorrectPerMinute} WCPM`;
    } else if (formativeAssessmentType === FormativeAssessmentType.WordReading) {
      return `${numWordsCorrectly?.toString()}/${numWords?.toString()}`;
    } else {
      return `${numLettersCorrectly?.toString()}/${numLetters?.toString()}`;
    }
  };

  const mapAssessmentResponseToTableRows = (formativeAssessments: FormativeAssessmentSearchResult[]): FormativeAssessmentTableRow[] => {
    return formativeAssessments.map<FormativeAssessmentTableRow>((formativeAssessment) => {
      const lastUpdatedAtFormatted = DateTime.fromMillis(formativeAssessment.lastUpdatedAt).toFormat('cccc, LLLL dd, yyyy');
      return {
        date: lastUpdatedAtFormatted,
        resourceUsed: formativeAssessment.bookTitle,
        score: handleScore(formativeAssessment),
      };
    });
  };

  return (
    <>
      {results.count > 0 ? (
        <Card>
          <Stack gap={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <HootTypography isPII={false} variant="titlemedium">
                Formative Assessments
              </HootTypography>
            </Stack>
            <TableV2 data={results.data} headers={headers} count={results.count} />
          </Stack>
        </Card>
      ) : null}
    </>
  );
};

export default LessonReviewFormativeAssessmentsCard;
