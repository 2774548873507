import { useEffect, useState } from 'react';
import { HistoryEntry, StoreListener, TLRecord, TLStoreWithStatus, createTLStore, defaultShapeUtils, loadSnapshot, throttle } from 'tldraw';
import { EventType } from '@hoot/events/eventType';
import { GetWhiteboardStateResponseMessage } from '@hoot/events/messages/get-whiteboard-state-response.message';
import { WhiteboardGetSnapshotRequestMessage } from '@hoot/events/messages/whiteboard-get-snapshot-request.message';
import { WhiteboardModifyRequestMessage } from '@hoot/events/messages/whiteboard-modify-request.message';
import { WhiteboardSyncMessage } from '@hoot/events/messages/whiteboard-sync.message';
import { useEmit } from '@hoot/hooks/useEmit';
import { useSocketSubscription } from '@hoot/hooks/useSocketSubscription';
import { handleSyncWhiteboard } from '@hoot/redux/reducers/whiteboardSlice';
import { useAppDispatch } from '@hoot/redux/store';

// Publishes a batch of whiteboard events every X milliseconds.
const updateEmitterThrottleTimeMs = 50;

interface UseWhiteboardSyncStoreProps {
  lessonId: string;
  whiteboardId: string;
}

/**
 * Manager for syncing whiteboard amongst lesson participants.
 */
export const useWhiteboardSyncManager = (props: UseWhiteboardSyncStoreProps) => {
  const { lessonId, whiteboardId } = props;

  const dispatch = useAppDispatch();

  const [store] = useState(
    createTLStore({
      shapeUtils: [...defaultShapeUtils],
    }),
  );
  // The `store` state above will eventually be fed into this once a store snapshot response is received.
  const [storeWithStatus, setStoreWithStatus] = useState<TLStoreWithStatus>({
    status: 'loading',
  });
  const status = storeWithStatus.status;

  // Emits request for the latest Tldraw store snapshot.
  const snapshotRequestEmitter = useEmit<WhiteboardGetSnapshotRequestMessage>(EventType.WhiteboardGetStateRequest, undefined, { enabled: false });

  // Emit local changes from the Tldraw canvas to the API.
  const clientUpdateEmitter = useEmit<WhiteboardModifyRequestMessage>(EventType.WhiteboardModifyRequest, undefined, { enabled: false });

  // FIRST:
  // On page-load, request the latest snapshot of the whiteboard.
  useEffect(() => {
    console.log('Requesting whiteboard snapshot...');
    snapshotRequestEmitter.emit({
      lessonId,
      whiteboardId,
    });
  }, [lessonId, whiteboardId]);

  // SECOND:
  // When we receive the whiteboard snapshot response, load it up into the Tldraw canvas.
  useSocketSubscription<GetWhiteboardStateResponseMessage>(EventType.WhiteboardGetStateResponse, {
    onEventReceived: (response) => {
      dispatch(handleSyncWhiteboard(response.whiteboardState));

      const storeSnapshot = response.whiteboardState?.storeSnapshot;

      // If no snapshot was returned, then there's a problem.
      if (!storeSnapshot) {
        console.log('Whiteboard response received, but missing snapshot. Bailing out.');
        setStoreWithStatus({
          status: 'not-synced',
          store,
        });
        return;
      }
      console.log('Whiteboard snapshot received. Loading canvas...');
      // Else, we have a snapshot that we can pass that into the tldraw canvas.
      loadSnapshot(store, { document: storeSnapshot });

      // Set connection status.
      setStoreWithStatus({
        status: 'synced-remote',
        connectionStatus: 'online',
        store,
      });
    },
  });

  // When we're ready (everything is initialized, and we have a valid snapshot), create a handler for local changes on
  // the Tldraw canvas, and push these changes over to the API for the other participant to receive.
  useEffect(() => {
    if (!whiteboardId || !lessonId || !store || status !== 'synced-remote') {
      return;
    }
    // Listen for whiteboard changes, and publish them to the API.
    const pendingChanges: HistoryEntry<TLRecord>[] = [];

    const sendChanges = throttle(() => {
      if (pendingChanges.length === 0) return;

      // Publish updates.
      clientUpdateEmitter.emit({
        whiteboardId,
        lessonId,
        pendingUpdates: pendingChanges,
      });
      pendingChanges.length = 0;
    }, updateEmitterThrottleTimeMs);

    const handleChange: StoreListener<TLRecord> = (event) => {
      if (event.source !== 'user') return;
      pendingChanges.push(event);
      sendChanges();
    };

    const unsubs = [
      store.listen(handleChange, {
        source: 'user',
        scope: 'document',
      }),
      store.listen(handleChange, {
        source: 'user',
        scope: 'presence',
      }),
    ];

    // Unsubscribe events.
    return () => {
      unsubs.forEach((fn) => fn());
    };
  }, [whiteboardId, lessonId, store, status]);

  // Listen for incoming whiteboard changes, and sync them into our local store.
  // Note: All incoming lesson-specific messages are handled in `useLessonMessageHandler` _except_ in this case. We need
  // direct access to the whiteboard store when receiving whiteboard events.
  useSocketSubscription<WhiteboardSyncMessage>(EventType.WhiteboardSyncResponse, {
    onEventReceived(response) {
      // We need to be ready to accept these changes before we can merge anything into the whiteboard.
      if (status !== 'synced-remote') {
        return;
      }
      try {
        for (const update of response.updates) {
          store.mergeRemoteChanges(() => {
            const {
              changes: { added, updated, removed },
            } = update as HistoryEntry<TLRecord>;

            for (const record of Object.values(added)) {
              store.put([record]);
            }
            for (const [, to] of Object.values(updated)) {
              store.put([to]);
            }
            for (const record of Object.values(removed)) {
              store.remove([record.id]);
            }
          });
        }
      } catch (e) {
        // If we failed to sync the canvas, then request a new snapshot.
        console.error('Tldraw sync error', e);
        snapshotRequestEmitter.emit({
          lessonId,
          whiteboardId: response.whiteboardId,
        });
      }
    },
  });

  return storeWithStatus;
};
