import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { UseGetOptions } from '@hoot/models/api/axios-options';

export interface SubTopicsRequest {
  topicIds: string[];
}

export interface SubTopicsResponse {
  categorizedSubTopics: CategorizedSubTopics[];
}

interface CategorizedSubTopics {
  parentCategory: string;
  listItems: {
    label: string;
    value: string;
  }[];
}

//Note: null cannot actually be passed into backend from enabled option
async function getSubTopics(requestPayload: SubTopicsRequest): Promise<SubTopicsResponse> {
  const { data } = await axios.get<SubTopicsResponse>(`${config.apiUrl}/library/sub-topics`, { params: requestPayload });
  return data;
}

export default function useGetSubTopics(requestPayload: SubTopicsRequest, options?: UseGetOptions<SubTopicsResponse>) {
  return useQuery([QueryKey.GetSubTopics, ...requestPayload.topicIds], () => getSubTopics(requestPayload), {
    enabled: requestPayload.topicIds.length > 0,
    ...options,
  });
}
