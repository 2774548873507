import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface DistrictRepProductSnippetResponse {
  id: string;
  friendlyId: string;
}

export default function useDistrictRepGetProductSnippet(
  productId: string,
  options?: Omit<UseQueryOptions<DistrictRepProductSnippetResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetDistrictRepProductSnippet, productId],
    async () => {
      return (await axios.get<DistrictRepProductSnippetResponse>(`${config.apiUrl}/public/district-representative/product/${productId}/snippet`))
        .data;
    },
    options,
  );
}
