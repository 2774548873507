import { CircularProgress, Stack } from '@mui/material';
import qs from 'qs';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { useAuth } from '@hoot/ui/context/AuthContext';

const ImpersonateLoginView = () => {
  const dispatch = useAppDispatch();
  const { impersonateLogin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const impersonationToken = (query.token as string) || '';

  useEffect(() => {
    if (impersonationToken.length > 0) {
      impersonateLogin(impersonationToken).then(() => navigate('/'));
    } else {
      dispatch(createFlashMessage({ message: 'Invalid impersonation token', variant: 'error' }));
      navigate(routesDictionary.home.url);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <CircularProgress />
    </Stack>
  );
};

export default ImpersonateLoginView;
