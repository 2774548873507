import { SvgIcon, SvgIconProps } from '@mui/material';

const CropLandscape = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 5H5c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2m0 12H5V7h14z"
        fill={props.htmlColor ?? 'currentColor'}
      />
    </SvgIcon>
  );
};

export default CropLandscape;
