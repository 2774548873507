import { ButtonBaseProps, CircularProgress, Tooltip } from '@mui/material';
import React, { CSSProperties, useState } from 'react';
import { isIOS } from 'react-device-detect';
import PlainButton from '@hoot/ui/components/v2/core/PlainButton';
import Lottie, { LottieFile, LottieProps } from '@hoot/ui/components/v2/lottie/Lottie';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

type Variant = 'plain' | 'transparent';

const defaultDimension = 64;

export interface LottieButtonProps extends ButtonBaseProps {
  lottieFile: LottieFile;
  tooltipLabel: string;
  LottieProps?: Omit<LottieProps, 'lottieFile'>;
  lottieWidth?: number;
  lottieHeight?: number;
  variant?: Variant;
  'aria-label': string;
  rootStyle?: CSSProperties;
  fullWidth?: boolean;
  isLoading?: boolean;
}

const styles = {
  plain: {
    root: {
      borderRadius: '4px',
      ...hootTokens.elevation.elevation1,
    },
  },
  transparent: {
    // FYI: Need to override defaults from <PlainButton> in some spots.
    root: {
      borderRadius: '8px',
      background: 'unset',
      boxShadow: 'unset',
    },
    hover: {
      background: hootTokens.surface['2'],
    },
  },
};

const LottieButton = (props: LottieButtonProps) => {
  const {
    lottieWidth,
    tooltipLabel,
    lottieHeight,
    LottieProps = {},
    lottieFile,
    variant = 'plain',
    sx = {},
    rootStyle = {},
    fullWidth,
    isLoading,
    onClick,
    disabled,
    ...buttonProps
  } = props;

  const [animate, setAnimate] = useState(false);
  const [isLastLoop, setIsLastLoop] = useState(false);

  const startAnimationLoop = () => {
    if (!isIOS) {
      setAnimate(true);
      setIsLastLoop(false);
    }
  };

  const stopAnimationAfterLoop = () => {
    setIsLastLoop(true);
  };

  const onLoopComplete = () => {
    if (isLastLoop) {
      setAnimate(false);
    }
  };

  const playAnimationOnce = () => {
    setAnimate(true);
    setIsLastLoop(true);
  };

  const _onClick: ButtonBaseProps['onClick'] = (event) => {
    if (isIOS) {
      playAnimationOnce();
    }
    onClick?.(event);
  };

  return (
    <Tooltip title={tooltipLabel}>
      <span
        style={{
          width: fullWidth ? '100%' : undefined,
          ...rootStyle,
        }}
      >
        <PlainButton
          onMouseEnter={startAnimationLoop}
          // Animation will stop when the current loop is finished.
          onMouseLeave={stopAnimationAfterLoop}
          sx={{
            minWidth: '44px',
            width: fullWidth ? '100%' : undefined,
            ...(variant === 'plain' && {
              ...styles.plain.root,
            }),
            ...(variant === 'transparent' && {
              ...styles.transparent.root,
            }),
            ...sx,
          }}
          disabled={disabled || isLoading}
          onClick={_onClick}
          {...buttonProps}
        >
          {isLoading ? (
            <CircularProgress style={{ width: lottieWidth ?? defaultDimension, height: lottieHeight ?? defaultDimension }} />
          ) : (
            <Lottie
              loop
              lottieFile={lottieFile}
              play={animate}
              onLoopComplete={onLoopComplete}
              style={{ width: lottieWidth ?? defaultDimension, height: lottieHeight ?? defaultDimension }}
              {...LottieProps}
            />
          )}
        </PlainButton>
      </span>
    </Tooltip>
  );
};

export default LottieButton;
