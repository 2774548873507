import { Box } from '@mui/material';
import { isAxiosError } from 'axios';
import { FormEventHandler, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useFlfResendVerificationCodeRequest from '@hoot/hooks/api/free-lesson-flow/useFlfResendVerificationCodeRequest';
import useFlfVerifyEmailRequest from '@hoot/hooks/api/free-lesson-flow/useFlfVerifyEmailRequest';
import { ErrorResponseDto } from '@hoot/models/api/error-response-dto';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import TextField, { TextFieldProps } from '@hoot/ui/components/v2/core/TextField';
import { FlfWizardStep } from '@hoot/ui/pages/v2/public/free-lesson/registration/FreeLessonRegistrationWizard';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const FreeLessonRegistrationEmailVerification = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const nameFromSearchParams = searchParams.get('name') ?? '?';
  const emailFromSearchParams = searchParams.get('email') ?? '';
  const accountFromSearchParams = searchParams.get('account') ?? '';
  const referralCodeFromSearchParams = searchParams.get('referralCode') ?? '';

  const navigate = useNavigate();

  const [verificationCodeInput, setVerificationCodeInput] = useState('');
  const [verificationCodeInputError, setVerificationCodeInputError] = useState<string>();

  const [isResendingVerificationCode, setIsResendingVerificationCode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const resendVerificationCodeRequest = useFlfResendVerificationCodeRequest();
  const verifyEmailRequest = useFlfVerifyEmailRequest();

  const dispatch = useAppDispatch();

  // There must be an email query string on this page, else bail out.
  useEffect(() => {
    if (emailFromSearchParams.length === 0) {
      navigate(routesDictionary.freeLesson.url);
    }
  }, [emailFromSearchParams, navigate]);

  const onVerificationCodeInputChanged: TextFieldProps['onChange'] = (e) => {
    setVerificationCodeInput(e.target.value);
  };

  const onResendVerificationCode = () => {
    setIsResendingVerificationCode(true);
    resendVerificationCodeRequest.mutate(
      { email: emailFromSearchParams },
      {
        onSuccess: () => {
          dispatch(createFlashMessage({ message: 'Verification code resent.' }));
        },
        onError: (err) => {
          console.error(err);
          if (isAxiosError<ErrorResponseDto>(err)) {
            setVerificationCodeInputError(err.response?.data.message ?? 'An error occurred while resending verification code.');
          } else {
            dispatch(createFlashMessage({ variant: 'error', message: 'An error occurred while resending verification code.' }));
          }
        },
        onSettled: () => {
          setIsResendingVerificationCode(false);
        },
      },
    );
  };

  const onSubmitVerificationCode: FormEventHandler = (event) => {
    event.preventDefault();

    // Reset errors.
    setVerificationCodeInputError(undefined);

    if (verificationCodeInput.length < 4) {
      setVerificationCodeInputError('Verification code must have four digits.');
      return;
    }

    setIsSubmitting(true);

    verifyEmailRequest.mutate(
      { email: emailFromSearchParams, verificationCode: verificationCodeInput },
      {
        onSuccess: (data) => {
          // Go to the next step.
          setSearchParams({
            step: `${FlfWizardStep.VerifyEmail + 1}`,
            verificationToken: data.verificationToken,
            account: accountFromSearchParams,
            referralCode: referralCodeFromSearchParams,
          });
        },
        onError: (err) => {
          console.error(err);
          if (isAxiosError<ErrorResponseDto>(err)) {
            setVerificationCodeInputError(err.response?.data.message ?? '');
          } else {
            setVerificationCodeInputError('An error occurred while submitting verification code.');
          }
        },
        onSettled: () => {
          setIsSubmitting(false);
        },
      },
    );
  };

  return (
    <form onSubmit={onSubmitVerificationCode}>
      <HootTypography isPII={false}>Verify Your Email Address</HootTypography>
      <HootTypography isPII={true} mt={3}>
        {nameFromSearchParams}, we sent an email to <b>{emailFromSearchParams}</b> with your four-digit verification code.
      </HootTypography>
      <HootTypography isPII={false} mt={2}>
        If the email hasn't arrived within two minutes, please check your spam folder.
      </HootTypography>
      <Box
        gap={2}
        mt={2}
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        })}
      >
        <TextField
          sx={(theme) => ({
            maxWidth: '330px',
            [theme.breakpoints.down('md')]: {
              maxWidth: '100%',
            },
          })}
          type="tel"
          inputProps={{ maxLength: 4 }}
          required
          fullWidth
          variant="outlined"
          label="Verification Code"
          value={verificationCodeInput}
          onChange={onVerificationCodeInputChanged}
          error={!!verificationCodeInputError}
          helperText={verificationCodeInputError}
        />
        <Button variant="contained" isLoading={isSubmitting} type="submit">
          Submit
        </Button>
      </Box>
      <Button
        sx={(theme) => ({
          mt: 2,
          color: hootTokens.palette.secondary['80'],
          paddingLeft: 1.5,
          [theme.breakpoints.down('md')]: {
            width: '100%',
          },
        })}
        variant="contained"
        onClick={onResendVerificationCode}
        color="secondary.190"
        isLoading={isResendingVerificationCode}
      >
        <Icon
          name="redo"
          sx={{
            width: '18px',
            height: '18px',
            marginRight: 1.5,
            color: hootTokens.palette.secondary['60'],
            ...hootTokens.text.labellarge,
          }}
        />
        Resend Email
      </Button>
    </form>
  );
};

export default FreeLessonRegistrationEmailVerification;
