import { Stack } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useDeferAssessmentAction } from '@hoot/hooks/api/my-student/useDeferAssessmentAction';
import { AssessmentActionResponse } from '@hoot/hooks/api/my-student/useGetAssessmentActions';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import TextField from '@hoot/ui/components/v2/core/TextField';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';

interface Form {
  notes: string;
  confirmDeferral: boolean;
}

function DeferAssessmentActionDialog(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  action: AssessmentActionResponse;
}) {
  const { open, setOpen, action } = props;
  const { studentProfileId } = useParams() as { studentProfileId: string };
  const { control, handleSubmit, watch } = useForm<Form>({
    defaultValues: {
      notes: undefined,
      confirmDeferral: false,
    },
    mode: 'onSubmit',
  });
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { notes, confirmDeferral } = watch();

  const deferAssessment = useDeferAssessmentAction({
    onSuccess: () => {
      dispatch(createFlashMessage({ message: `Deferral Submitted Successfully` }));
      queryClient.invalidateQueries(QueryKey.GetStudentAssessmentActions);
      setOpen(false);
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: `Failed to defer assessment`, variant: 'error' }));
    },
  });

  const isEnabledButton = notes && confirmDeferral && !deferAssessment.isLoading;

  const onSubmit = async (form: Form): Promise<void> => {
    if (studentProfileId && action.taskId && action.lessonId && form.notes) {
      deferAssessment.mutate({
        studentProfileId,
        taskId: action.taskId,
        lessonId: action.lessonId,
        notes: form.notes,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BasicAlertDialog
        show={open}
        onDismiss={() => setOpen(false)}
        title={'Defer Assessment'}
        content={
          <Stack spacing={2}>
            <HootTypography isPII={false} variant="bodylarge">
              Are you sure that you want to defer this assessment?
            </HootTypography>
            <Controller
              name="notes"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field?.value ?? ''}
                  fullWidth
                  label="Reason for Deferral"
                  multiline
                  rows={4}
                  variant="outlined"
                  required={true}
                />
              )}
            />
            <Controller
              control={control}
              name="confirmDeferral"
              render={({ field }) => <Checkbox checked={field.value} onChange={field.onChange} label="I understand that this cannot be undone" />}
            />
          </Stack>
        }
        primaryAction={{
          onClick: handleSubmit(onSubmit),
          label: 'Defer Assessment',
          props: {
            variant: 'contained',
            disabled: !isEnabledButton,
          },
        }}
        secondaryAction={{
          onClick: () => setOpen(false),
          label: 'Cancel',
          props: {
            variant: 'outlined',
          },
        }}
      />
    </form>
  );
}

export default DeferAssessmentActionDialog;
