import { SvgIcon } from '@mui/material';

const InvoiceIconBlue = () => {
  return (
    <SvgIcon width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0.0966797H16C17.1046 0.0966797 18 0.99211 18 2.09668V17.251C18 18.6362 16.6259 19.602 15.3226 19.1328L13.7608 18.5706C13.2728 18.3949 12.7356 18.4157 12.2626 18.6285L9.82073 19.7274C9.29878 19.9622 8.70122 19.9622 8.17927 19.7274L5.73737 18.6285C5.26439 18.4157 4.72721 18.3949 4.2392 18.5706L2.67744 19.1328C1.37412 19.602 0 18.6362 0 17.251V2.09668C0 0.992111 0.895431 0.0966797 2 0.0966797ZM5 4.34668C4.58579 4.34668 4.25 4.68247 4.25 5.09668C4.25 5.51089 4.58579 5.84668 5 5.84668H9C9.41421 5.84668 9.75 5.51089 9.75 5.09668C9.75 4.68247 9.41421 4.34668 9 4.34668H5ZM5 8.34668C4.58579 8.34668 4.25 8.68247 4.25 9.09668C4.25 9.51089 4.58579 9.84668 5 9.84668H13C13.4142 9.84668 13.75 9.51089 13.75 9.09668C13.75 8.68247 13.4142 8.34668 13 8.34668H5ZM5 12.3467C4.58579 12.3467 4.25 12.6825 4.25 13.0967C4.25 13.5109 4.58579 13.8467 5 13.8467H13C13.4142 13.8467 13.75 13.5109 13.75 13.0967C13.75 12.6825 13.4142 12.3467 13 12.3467H5Z"
        fill="#0CB2AD"
      />
    </SvgIcon>
  );
};

export default InvoiceIconBlue;
