import { useEffect, useState } from 'react';
import { EventType } from '../events/eventType';
import { useSocket } from '../ui/context/SocketContext';

export type UseEmitOptions = {
  enabled?: boolean;
};

interface QueuedMessage<T> {
  value: T | undefined;
}

export function useEmit<P>(eventType: EventType, params?: P, optionsFoo?: UseEmitOptions) {
  const { socket, connected } = useSocket();

  const [emitted, setEmitted] = useState(false);
  const [queuedMessage, setQueuedMessage] = useState<QueuedMessage<P>>();

  const enabled = optionsFoo?.enabled ?? true;

  const _emit = (params?: P) => {
    if (!connected) {
      console.log('Can not emit event. Socket connection has not yet been established.');
      setEmitted(false);
      return;
    }
    console.log(`Emitted WS event: ${eventType}`);
    socket.emit(eventType, params);
    setEmitted(true);
  };

  // Auto-publisher: Publish/emit the event when we're allowed to.
  useEffect(() => {
    // If the socket is connected, and the caller of this hook has said we can publish the event (enabled = true),
    // and we haven't already published the event, then go ahead of publish/emit.
    if (socket && connected && enabled && !emitted) {
      _emit(params);
    }
  }, [socket, connected, enabled, emitted, params]);

  // Manual publisher: Messages that manually published are queued up. Once the socket connection is ready, then we
  // go ahead and publish the message.
  useEffect(() => {
    if (socket && connected && queuedMessage) {
      _emit(queuedMessage.value);
      setQueuedMessage(undefined);
    }
  }, [socket, connected, queuedMessage]);

  return {
    /**
     * Queues up message to be emitted. The message is emitted only when the socket connection is ready.
     */
    queueMessage: (params?: P) => setQueuedMessage({ value: params }),
    /**
     * Emits a message.
     *
     * Note: this will fail if the socket connection is not ready.
     *
     * Useful if message must be emitted on component un-mount (queueing a message in this case would not work).
     */
    emit: _emit,
  };
}
