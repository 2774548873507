import { Fade, Stack } from '@mui/material';
import { useRef, useState } from 'react';
import { Editor } from 'tldraw';
import { useWhiteboardSyncManager } from '@hoot/hooks/lessons/useWhiteboardSyncManager';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import Whiteboard from '@hoot/ui/components/v2/Whiteboard';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import TeacherInLessonWhiteboardHeader from '@hoot/ui/pages/v2/teacher/lesson/whiteboard/TeacherInLessonWhiteboardHeader';
import useTeacherInLessonWhiteboardController from '@hoot/ui/pages/v2/teacher/lesson/whiteboard/useTeacherInLessonWhiteboardController';

const TeacherInLessonWhiteboardPage = () => {
  usePageTitle('Whiteboard | Hoot Reading');

  const editorRef = useRef<Editor>();

  const [isWhiteboardMounted, setIsWhiteboardMounted] = useState(false);
  const [showResetWhiteboardDialog, setShowResetWhiteboardDialog] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const activeLessonState = useActiveLessonState();
  const lessonId = activeLessonState.inLesson!.lessonId;
  const whiteboardId = activeLessonState.activeWhiteboardId!; // This must exist, else we wouldn't be on this page right now.

  const whiteboardController = useTeacherInLessonWhiteboardController({
    lessonId,
    whiteboardId,
  });
  const store = useWhiteboardSyncManager({
    lessonId,
    whiteboardId,
  });

  const onWhiteboardMounted = () => setIsWhiteboardMounted(true);

  const onShowResetWhiteboardDialog = () => setShowResetWhiteboardDialog(true);
  const onDismissResetWhiteboardDialog = () => setShowResetWhiteboardDialog(false);

  const onShowConfirmCloseDialog = () => setShowCloseConfirmation(true);
  const onDismissConfirmCloseDialog = () => setShowCloseConfirmation(false);

  const onResetWhiteboard = () => {
    whiteboardController.onResetWhiteboard();
    onDismissResetWhiteboardDialog();
  };

  const onCloseWhiteboard = () => {
    whiteboardController.closeWhiteboard();
    onDismissConfirmCloseDialog();
  };

  return (
    <Fade in>
      <Stack sx={{ width: '100%', height: '100%', gap: 3 }}>
        <TeacherInLessonWhiteboardHeader
          isWhiteboardMounted={isWhiteboardMounted}
          editorRef={editorRef}
          onCloseWhiteboard={onShowConfirmCloseDialog}
          onResetWhiteboard={onShowResetWhiteboardDialog}
        />

        <Whiteboard editorRef={editorRef} store={store} onWhiteboardMounted={onWhiteboardMounted} />

        {/* Dialogs */}
        <BasicAlertDialog
          show={showResetWhiteboardDialog}
          onDismiss={onDismissResetWhiteboardDialog}
          title="Reset Whiteboard?"
          content="The whiteboard will be restored to its original state."
          primaryAction={{
            label: 'Reset',
            onClick: onResetWhiteboard,
          }}
          secondaryAction={{
            label: 'Cancel',
            onClick: onDismissResetWhiteboardDialog,
          }}
        />
        <BasicAlertDialog
          show={showCloseConfirmation}
          onDismiss={onDismissConfirmCloseDialog}
          title="Are you sure you want to return to the library?"
          content={<></>}
          primaryAction={{
            label: 'Yes, take me back to the library',
            onClick: onCloseWhiteboard,
          }}
          secondaryAction={{
            label: 'Cancel',
            onClick: onDismissConfirmCloseDialog,
          }}
        />
      </Stack>
    </Fade>
  );
};

export default TeacherInLessonWhiteboardPage;
