import { Box, Stack } from '@mui/material';
import { EventType } from '@hoot/events/eventType';
import { CloseLessonBookRequestMessage } from '@hoot/events/messages/close-lesson-book-request.message';
import { LessonActivePageChangeRequestMessage } from '@hoot/events/messages/lesson-active-page-change-request.message';
import { WhiteboardCloseRequestMessage } from '@hoot/events/messages/whiteboard-close-request.message';
import { useEmit } from '@hoot/hooks/useEmit';
import { ActiveLessonPage } from '@hoot/models/api/enums/active-lesson-page-enum';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { useReaders } from '@hoot/redux/reducers/readingSlice';
import { useWhiteboardState } from '@hoot/redux/reducers/whiteboardSlice';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { Button } from '../core/Button';
import Card from '../core/Card';
import HootTypography from '../core/HootTypography';
import CancelIcon from '../icons/CancelIcon';

const FocusBlade = () => {
  const { inLesson, activePage } = useActiveLessonState();
  const lessonId = inLesson!.lessonId!;

  const { inLessonReader } = useReaders();
  const { book } = inLessonReader;

  const { whiteboard } = useWhiteboardState();
  const thumbnail = whiteboard?.templateThumbnail;

  const lessonActivePageChangeRequestEmitter = useEmit<LessonActivePageChangeRequestMessage>(EventType.LessonActivePageChangeRequest, undefined, {
    enabled: false,
  });
  const closeWhiteboardEmitter = useEmit<WhiteboardCloseRequestMessage>(EventType.WhiteboardCloseRequest, undefined, {
    enabled: false,
  });
  const closeBookRequestEmitter = useEmit<CloseLessonBookRequestMessage>(EventType.CloseLessonBookRequest, undefined, {
    enabled: false,
  });

  const onNavigateToReader = () => {
    lessonActivePageChangeRequestEmitter.queueMessage({
      lessonId,
      activePage: ActiveLessonPage.Reader,
    });
  };

  const onNavigateToWhiteboard = () => {
    lessonActivePageChangeRequestEmitter.queueMessage({
      lessonId,
      activePage: ActiveLessonPage.Whiteboard,
    });
  };

  const onCloseWhiteboard = () => {
    closeWhiteboardEmitter.queueMessage({
      lessonId,
      newActivePage: ActiveLessonPage.Reader,
    });
  };

  const onCloseBook = () => {
    closeBookRequestEmitter.queueMessage({
      lessonId,
      newActivePage: ActiveLessonPage.Whiteboard,
    });
  };

  return (
    <Card sx={{ width: '215px', borderRadius: '8px' }}>
      <Stack alignItems="center" gap={5}>
        {/* Header/Title */}
        <Stack alignItems="center" gap={1}>
          <HootTypography variant="titlesmall" isPII={false}>
            Focus
          </HootTypography>
          <Box sx={{ padding: '4px 8px', backgroundColor: hootTokens.surface[2], borderRadius: '8px' }}>
            <HootTypography variant="bodysmall" textAlign="center" fontSize="10px" isPII={false}>
              Choose content for the main stage: Switch between book and whiteboard activities.
            </HootTypography>
          </Box>
        </Stack>

        {/* Whiteboard Focus */}
        <Stack
          padding={2}
          gap={2}
          sx={{
            outline: activePage === ActiveLessonPage.Whiteboard ? '2px solid' : 'unset',
            outlineColor: hootTokens.palette.secondary[100],
            borderRadius: '8px',
          }}
        >
          <Box
            component="img"
            src={thumbnail}
            onClick={onNavigateToWhiteboard}
            sx={{
              width: '150px',
              boxShadow: hootTokens.elevation.elevation1,
              borderRadius: '8px',
              cursor: 'pointer',
            }}
          />

          <HootTypography variant="bodysmall" textAlign="center" isPII={false}>
            Whiteboard
          </HootTypography>

          {activePage === ActiveLessonPage.Whiteboard && (
            <Button
              onClick={onCloseWhiteboard}
              variant="contained"
              color="error.80"
              size="small"
              sx={{ color: '#FFF' }}
              startIcon={<CancelIcon htmlColor="#FFF" />}
            >
              <HootTypography variant="labelsmall" isPII={false}>
                Close
              </HootTypography>
            </Button>
          )}
        </Stack>

        {/* Book Focus */}
        <Stack
          padding={2}
          gap={2}
          sx={{
            outline: activePage === ActiveLessonPage.Reader ? '2px solid' : 'unset',
            outlineColor: hootTokens.palette.secondary[100],
            borderRadius: '8px',
          }}
        >
          {book?.coverUrl && (
            <Box
              component="img"
              src={book.coverUrl}
              onClick={onNavigateToReader}
              sx={{
                width: '150px',
                boxShadow: hootTokens.elevation.elevation1,
                borderRadius: '8px',
                cursor: 'pointer',
              }}
            />
          )}

          <HootTypography variant="bodysmall" textAlign="center" isPII={false}>
            Book
          </HootTypography>

          {activePage === ActiveLessonPage.Reader && (
            <Button
              onClick={onCloseBook}
              variant="contained"
              color="error.80"
              size="small"
              sx={{ color: '#FFF' }}
              startIcon={<CancelIcon htmlColor="#FFF" />}
            >
              <HootTypography variant="labelsmall" isPII={false}>
                Close
              </HootTypography>
            </Button>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default FocusBlade;
