import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Router from '@hoot/routes/Router';
import ErrorBoundary from '@hoot/ui/components/v2/ErrorBoundary';
import NewAppVersionInquirer from '@hoot/ui/context/NewAppVersionInquirer';
import { store } from '../redux/store';
import { AuthProvider } from './context/AuthContext';
import { CookieConsentProvider } from './context/CookieConsentContext';
import { CurrentTimeProvider } from './context/CurrentTimeContext';
import { FeatureFlagProvider } from './context/FeatureFlagContext';
import { FullscreenProvider } from './context/FullscreenContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <ErrorBoundary>
      <CookieConsentProvider>
        <QueryClientProvider client={queryClient}>
          <CurrentTimeProvider>
            <FeatureFlagProvider>
              <BrowserRouter>
                <ReduxProvider store={store}>
                  <FullscreenProvider>
                    <AuthProvider>
                      <NewAppVersionInquirer />
                      <Router />
                    </AuthProvider>
                  </FullscreenProvider>
                </ReduxProvider>
              </BrowserRouter>
            </FeatureFlagProvider>
          </CurrentTimeProvider>
        </QueryClientProvider>
      </CookieConsentProvider>
    </ErrorBoundary>
  );
}

export default App;
