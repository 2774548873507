import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './ui/App';

// FYI -> https://hootreading.slack.com/archives/C0239S1T8TH/p1715795543963839
// TLDR: In React v18, using `createRoot` and `root.render()` here in Strict Mode triggers clean-up functions, which causes a leave-lesson event upon joining a lesson locally.
// So we'll want to stick with `ReactDOM.render()` for the time being. A console warning will appear, but its safe to ignore.
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
