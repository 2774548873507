import React from 'react';

interface Values {
  scrollToTop: () => void;
}

interface Props extends Values {
  children: React.ReactNode;
}

const ContentContext = React.createContext<Values>({
  scrollToTop: () => null,
});

const ScrollToTopProvider = (props: Props) => {
  const { children, scrollToTop } = props;
  return <ContentContext.Provider value={{ scrollToTop }}>{children}</ContentContext.Provider>;
};

export const useScrollToTop = () => {
  const context = React.useContext(ContentContext);

  if (context === undefined) {
    throw new Error('useScrollToTop must be used within a ScrollToTopProvider');
  }

  return context;
};

export default ScrollToTopProvider;
