import { Close } from '@mui/icons-material';
import { Chip as MuiChip, ChipProps as MuiChipProps, SxProps, Theme } from '@mui/material';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { getColors } from '../../../theme/v2/util';

type ChipProps = Omit<MuiChipProps, 'size'> & {
  elevation?: 0 | 1 | 2 | 3 | 4 | 5;
  color?: ColorPaletteOption;
  selected?: boolean;
  error?: boolean;
};

function applyStyles(props: ChipProps): SxProps<Theme> {
  const { color = 'primary', error, elevation = 0, selected } = props;
  const colors = getColors(color);

  const activeStyle: SxProps<Theme> = {
    backgroundColor: error ? hootTokens.palette.error[180] : colors.palette[180],
  };

  const hoverStyle: SxProps<Theme> = {
    backgroundColor: error ? hootTokens.palette.error[180] : colors.palette[180],
  };

  const focusStyle: SxProps<Theme> = {
    backgroundColor: error ? hootTokens.palette.error[190] : colors.palette[190],
  };

  const deleteIconStyleHover: SxProps<Theme> = {
    color: hootTokens.palette.black,
  };

  const deleteIconStyle: SxProps<Theme> = {
    color: hootTokens.palette.black,
    ':hover': deleteIconStyleHover,
  };

  if (error) {
    const styles: SxProps<Theme> = {
      ...hootTokens.text.labelsmall,
      ...hootTokens.elevation.get(elevation),
      backgroundColor: hootTokens.palette.error[190],
      borderColor: hootTokens.palette.error[80],
      color: hootTokens.palette.error[80],
      ':hover': hoverStyle,
      ':focus': focusStyle,
      ':active': activeStyle,
      '& .MuiChip-deleteIcon': deleteIconStyle,
    };

    return styles;
  }

  const styles: SxProps<Theme> = {
    ...hootTokens.text.labelsmall,
    ...hootTokens.elevation.get(elevation),
    backgroundColor: hootTokens.palette.white,
    color: hootTokens.palette.black,
    ':hover': hoverStyle,
    ':focus': focusStyle,
    ':active': activeStyle,
    '& .MuiChip-deleteIcon': deleteIconStyle,
  };

  if (color !== 'primary') {
    styles.backgroundColor = colors.palette[60];
  }

  if (selected) {
    if (color === 'primary') {
      styles.backgroundColor = hootTokens.palette.black;
      styles.color = hootTokens.palette.white;
      deleteIconStyleHover.color = hootTokens.palette.white;
      deleteIconStyle.color = hootTokens.palette.white;
    }
    activeStyle.backgroundColor = colors.palette[0];
    hoverStyle.backgroundColor = colors.palette[40];
    focusStyle.backgroundColor = colors.palette[40];
  }

  if (!props.onDelete) {
    styles.cursor = 'pointer';
  }

  return styles;
}

export function Chip(props: ChipProps) {
  const styles = applyStyles(props);
  return <MuiChip tabIndex={0} sx={styles} deleteIcon={<Close />} {...props} />;
}
