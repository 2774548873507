import { Skeleton, Stack } from '@mui/material';
import useGetAppNotifications from '@hoot/hooks/api/app-notification/useGetAppNotifications';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import Card from '@hoot/ui/components/v2/core/Card';
import AnnouncementsCarousel from '@hoot/ui/pages/v2/teacher/dashboard/announcements/AnnouncementsCarousel';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const TeacherAnnouncements = () => {
  const { data: announcements, isFetched } = useGetAppNotifications();
  const viewState: ViewStateEnum = !isFetched
    ? ViewStateEnum.Loading
    : (announcements?.length ?? 0) === 0
    ? ViewStateEnum.EmptyState
    : ViewStateEnum.Results;

  return (
    <Card>
      <Stack gap={0.5}>
        <ViewState state={viewState} loadingContent={<LoadingContent />}>
          <AnnouncementsCarousel announcements={announcements} />
        </ViewState>
      </Stack>
    </Card>
  );
};

const LoadingContent = () => {
  return (
    <Card>
      <Stack gap={2}>
        <Stack direction={'row'} gap={2} justifyContent="space-between">
          <Stack direction="row" gap={1} justifyContent="flex-start" alignItems="center">
            <Skeleton variant="circular" sx={{ width: '24px', height: '24px' }} />
            <Skeleton variant="rounded" sx={{ width: '300px', ...hootTokens.text.labellarge }} />
          </Stack>
          <Skeleton variant="rounded" sx={{ height: '35px', width: '90px', borderRadius: '8px', ...hootTokens.text.labellarge }} />
        </Stack>
        <Skeleton variant="rounded" sx={{ width: '50px', ...hootTokens.text.bodysmall }} />
        <Skeleton variant="rounded" sx={{ width: '80%', ...hootTokens.text.bodylarge }} />
        <Skeleton variant="rounded" sx={{ width: '80%', ...hootTokens.text.bodylarge }} />
        <Skeleton variant="rounded" sx={{ width: '60%', ...hootTokens.text.bodylarge }} />
        <Skeleton variant="rounded" sx={{ height: '36px', width: '100px' }} />
        <Stack direction="row" justifyContent="center" gap={2}>
          <Skeleton variant="rounded" sx={{ width: '24px', height: '24px' }} />
          <Skeleton variant="text" sx={{ width: '50px' }} />
          <Skeleton variant="rounded" sx={{ width: '24px', height: '24px' }} />
        </Stack>
      </Stack>
    </Card>
  );
};

export default TeacherAnnouncements;
