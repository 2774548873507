import { NotificationTagMessage } from '@hoot/events/messages/notification-tag';

export enum NotificationType {
  Enrolment = 'ENROLMENT',
  Assessment = 'ASSESSMENT',
  LessonAdded = 'LESSON_ADDED',
  LessonDidNotOccur = 'LESSON_DID_NOT_OCCUR',
  LessonCancelled = 'LESSON_CANCELLED',
  LessonMissed = 'LESSON_MISSED',
  LessonUpdated = 'LESSON_UPDATED',
}

export enum NotificationCallToAction {
  ViewStudents = 'VIEW_STUDENTS',
  ViewSchedule = 'VIEW_SCHEDULE',
}

export interface NotificationMessage {
  id: string;
  title: string;
  notificationType: NotificationType;
  content: string;
  isBookmarked: boolean;
  tags: NotificationTagMessage[];
  sentAt: number;
  callToAction: NotificationCallToAction;
}
