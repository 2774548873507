import { Link as MuiLink } from '@mui/material';
import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  children: ReactNode;
  hasPermission: boolean;
  to: string;
}

const ProtectedLink = (props: Props) => {
  const { children, hasPermission, to } = props;
  const { pathname } = useLocation();

  if (!hasPermission) {
    return <>{children}</>;
  }

  return (
    <MuiLink to={to} component={Link} state={{ previousPath: pathname }}>
      <>{children}</>
    </MuiLink>
  );
};

export default ProtectedLink;
