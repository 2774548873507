import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';
import { Tokens } from '../../../models/api/auth';
import { User } from '../../../models/api/user';

export interface ImpersonateLoginRequest {
  token: string;
}

export interface ImpersonateLoginResponse {
  user: User;
  tokens: Tokens;
}

async function impersonateLogin(request: ImpersonateLoginRequest): Promise<ImpersonateLoginResponse> {
  const { data } = await axios.post(`${config.apiUrl}/auth/impersonate-login`, request);

  return data;
}

export function useImpersonateLogin(
  options?: Omit<UseMutationOptions<ImpersonateLoginResponse, AxiosError, ImpersonateLoginRequest>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation<ImpersonateLoginResponse, AxiosError, ImpersonateLoginRequest>((request) => impersonateLogin(request), options);
}
