import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { WhiteboardTemplateResponse } from '@hoot/hooks/api/whiteboard/useGetWhiteboardTemplates';
import { UseGetOptions } from '@hoot/models/api/axios-options';

export interface LessonWhiteboardResponse {
  whiteboardId: string;
  lessonId: string;
  whiteboardTemplate: WhiteboardTemplateResponse;
}

export default function useGetLessonWhiteboards(lessonId: string, options?: UseGetOptions<LessonWhiteboardResponse[]>) {
  return useQuery(
    [QueryKey.GetLessonWhiteboards, lessonId],
    async () => {
      return (await axios.get<LessonWhiteboardResponse[]>(`${config.apiUrl}/whiteboard/lesson/${lessonId}`)).data;
    },
    options,
  );
}
