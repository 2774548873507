import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserProfileType } from '../models/api/user';
import { RootState } from '../redux/store';
import { routesDictionary } from '../routes/routesDictionary';

const DEFAULT_REDIRECT = {
  [UserProfileType.Parent]: routesDictionary.schedule.url,
  [UserProfileType.Teacher]: routesDictionary.schedule.url,
  [UserProfileType.Student]: routesDictionary.library.url,
  [UserProfileType.DistrictRep]: routesDictionary.dashboard.url,
};

export function useRedirectProfile(profileType: UserProfileType, path: string | null = null) {
  const navigate = useNavigate();
  const profile = useSelector((state: RootState) => state.profile.profile);

  if (!profile) {
    return null;
  }

  if (profile.type === profileType) {
    navigate(path || DEFAULT_REDIRECT[profile.type]);
  }

  return null;
}
