import React, { PropsWithChildren, useState } from 'react';
import { useGetDeviceState } from '../../hooks/daily/useGetDeviceState';
import useProfile from '../../hooks/useProfile';
import DailyAVSettingsDialog from '../components/v2/dialogs/get-ready/daily/DailyAVSettingsDialog';
import DailyStudentGetReadyDialog from '../components/v2/dialogs/get-ready/daily/DailyStudentGetReadyDialog';
import DailyStudentInLessonSettingsDialog from '../components/v2/dialogs/get-ready/daily/DailyStudentInLessonSettingsDialog';

interface AudioVideoSettingsDialogContextProviderProps {
  show: boolean;
  /** Shows Audio/Video Settings Dialog */
  onShow: () => void;
  /** Dismisses Audio/Video Settings Dialog */
  onDismiss: () => void;
  /** Shows Student Get Ready Dialog */
  showStudent: boolean;
  onShowStudent: () => void;
  onDismissStudent: () => void;
  showStudentInLesson: boolean;
  onShowStudentInLesson: () => void;
  onDismissStudentInLesson: () => void;
}

/** Daily Context Providers */

const DailyAudioVideoSettingsDialogContext = React.createContext<AudioVideoSettingsDialogContextProviderProps>(undefined!);

export const DailyAudioVideoSettingsDialogContextProvider = (props: PropsWithChildren<any>) => {
  const { children } = props;

  const [show, setShow] = useState(false);
  const [showStudent, setShowStudent] = useState(false);
  const [showStudentInLesson, setShowStudentInLesson] = useState(false);

  /** Shows AV settings Dialog */
  const onShow = () => {
    setShow(true);
  };

  /** Hides AV settings Dialog */
  const onDismiss = () => {
    setShow(false);
  };

  /** Shows Student Get Ready Dialog */
  const onShowStudent = () => {
    setShowStudent(true);
  };

  /** Dismisses Student Get Ready Dialog */
  const onDismissStudent = () => {
    setShowStudent(false);
  };

  const onShowStudentInLesson = () => {
    setShowStudentInLesson(true);
  };

  const onDismissStudentInLesson = () => {
    setShowStudentInLesson(false);
  };

  return (
    <DailyAudioVideoSettingsDialogContext.Provider
      value={{
        show,
        onShow,
        onDismiss,
        showStudent,
        onShowStudent,
        onDismissStudent,
        showStudentInLesson,
        onShowStudentInLesson,
        onDismissStudentInLesson,
      }}
    >
      {children}
    </DailyAudioVideoSettingsDialogContext.Provider>
  );
};

export const DailyAudioVideoSettingsControl = (props: PropsWithChildren<any>) => {
  const { isTeacher, isStudent, isParent } = useProfile();

  if (isTeacher || isParent) {
    return <PrimaryDailyAudioVideoSettingsControl {...props} />;
  } else if (isStudent) {
    return <StudentDailyAudioVideoSettingsControl {...props} />;
  }

  return props.children;
};

function PrimaryDailyAudioVideoSettingsControl(props: PropsWithChildren<any>) {
  const { show } = useAudioVideoSettingsDialog();

  return (
    <>
      {props.children}
      {show ? <DailyAVSettingsDialog /> : null}
    </>
  );
}

function StudentDailyAudioVideoSettingsControl(props: PropsWithChildren<any>) {
  const { children } = props;

  const deviceState = useGetDeviceState();

  const { show, showStudent, showStudentInLesson } = useAudioVideoSettingsDialog();

  const showDialog = showStudent && (deviceState === 'pending' || deviceState === 'blocked');

  function displayModal() {
    if (showStudent && deviceState === 'idle') {
      return <DailyAVSettingsDialog />;
    } else {
      if (showDialog || show) {
        return <DailyAVSettingsDialog />;
      } else if (showStudentInLesson) {
        return <DailyStudentInLessonSettingsDialog />;
      } else if (showStudent) {
        return <DailyStudentGetReadyDialog isOpen={showStudent} />;
      }
    }
    return null;
  }

  return (
    <>
      {children}
      {displayModal()}
    </>
  );
}

/** Context Provider Hook */

export const useAudioVideoSettingsDialog = () => {
  const context = React.useContext(DailyAudioVideoSettingsDialogContext);

  if (context === undefined) {
    throw new Error(`useAudioVideoSettingsDialog must be used within a DailyAudioVideoSettingsDialogContext`);
  }
  return context;
};

export default DailyAudioVideoSettingsDialogContextProvider;
