import { Box, SxProps, Theme } from '@mui/material';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { getColors } from '@hoot/ui/theme/v2/util';
import { hootTokens } from '../../../theme/v2/tokens';

export type BadgeProps = {
  color?: ColorPaletteOption;
  badgeContent: number;
  sx?: SxProps<Theme>;
};

function applyStyles(props: BadgeProps): SxProps {
  const { color = 'secondary.60' } = props;
  const colors = getColors(color);

  const styles: SxProps = {
    ...hootTokens.text.labelsmall,
    color: colors.font,
    backgroundColor: colors.base,
    borderRadius: '20px',
    minWidth: '20px',
    height: '20px',
    boxSizing: 'border-box',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: 0,
    px: '6px',
    margin: 0,
    textAlign: 'center',
  };

  return styles;
}

export default function Badge(props: BadgeProps) {
  const styles = applyStyles(props);
  const displayVal = props.badgeContent > 99 ? '99+' : props.badgeContent;
  const sxStyles = { ...styles, ...(props.sx ?? {}) };
  return <Box sx={sxStyles}>{displayVal}</Box>;
}
