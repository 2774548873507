import { Skeleton, Stack, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useSelector } from 'react-redux';
import useGetDistrictRepPermissions, { DistrictRepAccessLevel } from '@hoot/hooks/api/user/useGetDistrictRepPermissions';
import { DistrictRepScheduledLesson } from '@hoot/models/api/lessons';
import { RootState } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { hasDistrictPermission } from '@hoot/routes/secure/ProtectedRoute';
import ProtectedLink from '@hoot/ui/components/v2/ProtectedLink';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import TextField from '@hoot/ui/components/v2/core/TextField';
import theme from '@hoot/ui/theme/v2';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { timeFromUnixMillisUpperCase, timeZoneDisplay } from '@hoot/utils/date';

const DistrictRepEnrolmentsLessons = (props: { lesson: DistrictRepScheduledLesson }) => {
  const { lesson } = props;
  const below600px = useMediaQuery(theme.breakpoints.down('sm'));

  const lessonTime = `${timeFromUnixMillisUpperCase(lesson.lessonStartTime)} - ${timeFromUnixMillisUpperCase(lesson.lessonEndTime)}`;
  const lessonTimeZoneDisplay = timeZoneDisplay(lesson.studentTimeZone);

  const districtRepresentativeId = useSelector((state: RootState) => state.profile.profile?.id);
  const { data: districtRepPermission } = useGetDistrictRepPermissions(districtRepresentativeId || '');
  const hasEveryonePermissions = hasDistrictPermission(DistrictRepAccessLevel.Everyone, districtRepPermission);

  return (
    <Card elevation={2}>
      <Stack flexDirection={'row'} gap={1} alignItems={'center'} mb={2}>
        <Icon name="lesson_icon" htmlColor={hootTokens.palette.secondary[100]} />
        <HootTypography isPII={false} variant="titlesmall">
          Lesson {lesson.lessonNumber}
        </HootTypography>
      </Stack>

      <Grid container gap={below600px ? 1 : 3} flexDirection={below600px ? 'column' : 'row'} flexWrap={'nowrap'}>
        <Grid container flexDirection={'column'} size={{ xs: 12, sm: 6 }} rowGap={below600px ? 1 : 2}>
          <TextField
            label={'Lesson Time'}
            value={lessonTime}
            helperText={lessonTimeZoneDisplay}
            variant="filled"
            filledColour="warning.190"
            readOnly
            emphasizeLabel
          />
          <TextField
            label={'Teacher'}
            value={lesson.lessonTeacherName ?? 'No Teacher assigned'}
            helperText={' '}
            variant="filled"
            filledColour="warning.190"
            readOnly
            emphasizeLabel
          />
        </Grid>
        <Grid container flexDirection={'column'} size={{ xs: 12, sm: 6 }} rowGap={below600px ? 1 : 2}>
          <TextField
            label={'Status'}
            value={lesson.lessonStatus}
            helperText={' '}
            variant="filled"
            filledColour="warning.190"
            readOnly
            emphasizeLabel
          />
          <TextField
            label={'Enrolment'}
            value={lesson.enrolmentFriendlyId}
            helperText={' '}
            variant="filled"
            filledColour="warning.190"
            readOnly
            emphasizeLabel
            InputProps={{
              endAdornment: (
                <ProtectedLink to={routesDictionary.enrolments.enrolment.details.url(lesson.enrolmentId)} hasPermission={hasEveryonePermissions}>
                  {hasEveryonePermissions && lesson.enrolmentId ? <Icon name="new_tab" sx={{ mr: 2 }} /> : null}
                </ProtectedLink>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export const DistrictRepEnrolmentsLessonsSkeleton = () => {
  const below600px = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card elevation={2}>
      <Stack flexDirection={'row'} gap={1} alignItems={'center'} mb={2}>
        <Skeleton variant="circular" sx={{ width: '44px', height: '44px' }} />
        <Skeleton variant="text" sx={{ width: '250px', height: '40px' }} />
      </Stack>

      <Grid container gap={below600px ? 1 : 3} flexDirection={below600px ? 'column' : 'row'} flexWrap={'nowrap'}>
        <Grid container flexDirection={'column'} size={{ xs: 12, sm: 6 }} rowGap={below600px ? 1 : 2}>
          <Skeleton variant="text" sx={{ width: '100%', height: '80px' }} />
          <Skeleton variant="text" sx={{ width: '100%', height: '80px' }} />
        </Grid>
        <Grid container flexDirection={'column'} size={{ xs: 12, sm: 6 }} rowGap={below600px ? 1 : 2}>
          <Skeleton variant="text" sx={{ width: '100%', height: '80px' }} />
          <Skeleton variant="text" sx={{ width: '100%', height: '80px' }} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default DistrictRepEnrolmentsLessons;
