import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { config } from '../../../config';

interface LessonNote {
  lessonNote: string;
  lessonDate: number;
}

async function getLessonNote(studentProfileId: string, reviewId: string): Promise<LessonNote> {
  const { data } = await axios.get<LessonNote>(`${config.apiUrl}/public/lesson-review/lesson-note/${studentProfileId}/${reviewId}`);
  return data;
}

export default function useGetLessonNote(
  studentProfileId: string,
  reviewId: string,
  options?: Omit<UseQueryOptions<LessonNote>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetLessonNote, studentProfileId, reviewId], () => getLessonNote(studentProfileId, reviewId), options);
}
