import { Calculation } from '@hoot/hooks/api/assessment/useGetAssessmentUnit';
import { OperandType, Operator } from '@hoot/models/api/enums/hoot-reading-assessment';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';

interface ICalculatedProps {
  label: string;
  calculated?: Calculation;
  watching?: Record<string, any>;
  percentage?: boolean;
  onChange: (val: number) => void;
  value: number;
}

const runCalculation = (calculation: Calculation, fields?: Record<string, any>) => {
  let leftOperand = 0;
  let rightOperand = 0;

  switch (calculation.leftOperand.type) {
    case OperandType.Calculation:
      leftOperand = runCalculation(calculation.leftOperand.value as Calculation, fields);
      break;
    case OperandType.Field:
      leftOperand = fields ? fields[calculation.leftOperand.value as string] || 0 : 0;
      break;
    case OperandType.Number:
      leftOperand = calculation.leftOperand.value as number;
      break;
  }

  switch (calculation.rightOperand.type) {
    case OperandType.Calculation:
      rightOperand = runCalculation(calculation.rightOperand.value as Calculation, fields);
      break;
    case OperandType.Field:
      rightOperand = fields ? fields[calculation.rightOperand.value as string] || 0 : 0;
      break;
    case OperandType.Number:
      rightOperand = calculation.rightOperand.value as number;
      break;
  }

  switch (calculation.operator) {
    case Operator.Add:
      return leftOperand + rightOperand;
    case Operator.Subtract:
      return leftOperand - rightOperand;
    case Operator.Multiply:
      return leftOperand * rightOperand;
    case Operator.Divide:
      return leftOperand / rightOperand;
  }
};

export const Calculated = (props: ICalculatedProps) => {
  const { calculated, watching, label, percentage, onChange, value } = props;

  if (!calculated) {
    return null;
  }

  const result = runCalculation(calculated, watching);
  const roundedCalculation = parseFloat(result.toFixed(0));
  if (!!roundedCalculation && roundedCalculation !== value) {
    onChange(roundedCalculation);
  }

  return (
    <ReadOnlyTextField
      sx={{ flex: 1 }}
      label={label}
      body={isNaN(result) || !isFinite(result) ? '-' : `${roundedCalculation}${percentage ? '%' : ''}`}
    />
  );
};
