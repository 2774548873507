import Grid from '@mui/material/Grid2';
import { Control, Controller, UseFormClearErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { COUNTRIES, VALID_EMAIL_REGEX } from '@hoot/constants/constants';
import usePlaces from '@hoot/hooks/usePlaces';
import { User } from '@hoot/models/api/user';
import Card from '@hoot/ui/components/v2/core/Card';
import { Select } from '@hoot/ui/components/v2/core/Select';
import TextField from '@hoot/ui/components/v2/core/TextField';
import { TeacherProfileForm } from './TeacherProfile';

interface Props {
  control: Control<TeacherProfileForm, object>;
  watch: UseFormWatch<TeacherProfileForm>;
  setValue: UseFormSetValue<TeacherProfileForm>;
  clearErrors: UseFormClearErrors<TeacherProfileForm>;
  user: User;
}

const AccountDetailsEmpty = (props: Props) => {
  const { control, watch, setValue, clearErrors, user } = props;
  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const email = watch('email');
  const country = watch('country');
  const { provinceStateOptions } = usePlaces(country);

  const accountDetailsRequired = !!firstName || !!lastName || !!email || !!country;

  return (
    <Card sx={{ width: '100%' }} title="Account Details">
      <Grid container rowGap={'16px'} columnSpacing={'16px'}>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: {
                value: accountDetailsRequired,
                message: 'Required',
              },
            }}
            render={({ field: { onChange, value }, fieldState }) => (
              <TextField
                variant="outlined"
                fullWidth
                id="firstName"
                label="First Name"
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message || ''}
                disabled={!!user.firstName}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: {
                value: accountDetailsRequired,
                message: 'Required',
              },
            }}
            render={({ field: { onChange, value }, fieldState }) => (
              <TextField
                variant="outlined"
                fullWidth
                id="lastName"
                label="Last Name"
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message || ''}
                disabled={!!user.lastName}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: {
                value: accountDetailsRequired,
                message: 'Required',
              },
              pattern: {
                value: VALID_EMAIL_REGEX,
                message: 'Please enter a valid email',
              },
            }}
            render={({ field: { onChange, value }, fieldState }) => (
              <TextField
                variant="outlined"
                fullWidth
                id="email"
                label="Email"
                value={value}
                onChange={onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message || ''}
                disabled={!!user.email}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="country"
            control={control}
            rules={{
              required: {
                value: accountDetailsRequired,
                message: 'Required',
              },
            }}
            render={({ field, fieldState }) => (
              <Select
                value={field.value}
                onChange={(f) => {
                  clearErrors('institutionNumber');
                  setValue('institutionNumber', '');
                  field.onChange(f);
                }}
                label="Country"
                id="country"
                error={!!fieldState.error}
                helperText={fieldState.error?.message || ''}
                sx={{ width: '100%' }}
                disabled={!!user.country}
              >
                <option disabled value="">
                  Select
                </option>
                {COUNTRIES.map((country, idx) => (
                  <option key={`${country}-${idx}`} value={country.value}>
                    {country.label}
                  </option>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="provinceState"
            control={control}
            render={({ field, fieldState }) => (
              <Select
                error={!!fieldState.error}
                value={field.value}
                onChange={field.onChange}
                label="Province/State (Optional)"
                id="provinceState"
                helperText={fieldState.error?.message || ''}
                sx={{ width: '100%' }}
                disabled={!country || !!user.provinceState}
              >
                <option disabled value="">
                  Select
                </option>
                {provinceStateOptions.map((province, idx) => (
                  <option key={`${province}-${idx}`} value={province.value}>
                    {province.label}
                  </option>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid size={12}>
          <Controller
            name="streetAddress"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                variant="outlined"
                fullWidth
                id="streetAddress"
                label="Street Address (Optional)"
                value={value}
                onChange={onChange}
                disabled={!!user.streetAddress}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="city"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField variant="outlined" fullWidth id="city" label="City (Optional)" value={value} onChange={onChange} disabled={!!user.city} />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="postalZipCode"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                variant="outlined"
                fullWidth
                id="postalZipCode"
                label="Postal/ZIP Code (Optional)"
                value={value}
                onChange={onChange}
                disabled={!!user.postalZipCode}
              />
            )}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default AccountDetailsEmpty;
