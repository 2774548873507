import { FunctionComponent } from 'react';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import PageLayout, { PageLayoutProps } from '@hoot/ui/components/v2/core/PageLayout';

interface PageProps extends PageLayoutProps {
  pageTitle: string;
}

const Page: FunctionComponent<PageProps> = (props) => {
  const { pageTitle, ...PageLayoutProps } = props;

  usePageTitle(pageTitle);

  return <PageLayout {...PageLayoutProps} />;
};

export default Page;
