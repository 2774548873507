import { Box, CardProps, Skeleton, Stack, useMediaQuery } from '@mui/material';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import theme from '@hoot/ui/theme/v2';
import useGetTeacherInvoiceAdditionalServicesSummaryResponse, {
  TeacherInvoiceAdditionalServicesQuery,
} from '../../../../../hooks/api/invoice/useGetTeacherInvoiceAdditionalServicesSummary';

interface BillableLessonsProps {
  sx?: CardProps['sx'];
}

interface AdditionalServicesTableRow {
  serviceDate: string;
  projectTag: string;
  description: string;
  amount: string;
}

interface TableData {
  additionalServicesSummaryRows: AdditionalServicesTableRow[];
}

const AdditionalServices = (props: BillableLessonsProps) => {
  const { sx } = props;
  const { invoiceId } = useParams() as { invoiceId: string };
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [additionalServicesData, setAdditionalServicesData] = useState<TableData>({
    additionalServicesSummaryRows: [],
  });
  const [query, setQuery] = useState<TeacherInvoiceAdditionalServicesQuery>({
    sortBy: 'serviceDate',
    orderBy: OrderBy.Asc,
  });

  const headers: HeaderData<AdditionalServicesTableRow>[] = [
    { name: 'Service Date', property: 'serviceDate', isSortable: true },
    { name: 'Project Tag', property: 'projectTag', isSortable: true },
    { name: 'Description', property: 'description', isSortable: true, isHidden: !isDesktop },
    { name: 'Amount', property: 'amount', isSortable: true },
  ];

  const summaryDataRequest = useGetTeacherInvoiceAdditionalServicesSummaryResponse(invoiceId || '', query, {
    retry: false,
    enabled: true,
    onError: (ex) => {
      console.error(ex);
    },
    onSuccess: (data) => {
      const additionalServicesRows: AdditionalServicesTableRow[] = data.additionalServicesSummaryRows.map((row) => ({
        serviceDate: row.serviceDate,
        projectTag: row.projectTag,
        description: row.description,
        amount: row.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
      }));

      setAdditionalServicesData({
        additionalServicesSummaryRows: additionalServicesRows,
      });
    },
  });

  const viewState = useMemo<ViewStateEnum>(() => {
    if (summaryDataRequest.isFetching || summaryDataRequest.isLoading) {
      return ViewStateEnum.Loading;
    } else if (summaryDataRequest.isError) {
      return ViewStateEnum.Error;
    } else {
      return ViewStateEnum.Results;
    }
  }, [summaryDataRequest, additionalServicesData]);

  return (
    <Card
      sx={{
        height: 'max-content',
        border: 'none',
        ...(sx ?? {}),
      }}
      contentSx={{ padding: '12px 0px 16px 0px' }}
    >
      <Stack spacing={2}>
        <ViewState state={viewState} loadingContent={<SkeletonTableItems />}>
          {additionalServicesData.additionalServicesSummaryRows.length > 0 && (
            <>
              <Box display={'flex'} flexDirection={'row'} flexWrap="wrap" gap={1}>
                <HootTypography isPII={false} variant="titlelarge">
                  Additional Services
                </HootTypography>
              </Box>

              <TableV2
                isPaginated={false}
                isSortable
                data={additionalServicesData.additionalServicesSummaryRows}
                headers={headers}
                onSortBy={(val: any) => {
                  setQuery((prevState) => ({
                    ...prevState,
                    sortBy: val,
                    orderBy: prevState.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
                  }));
                }}
                sortOrder={query.orderBy}
                sortBy={query.sortBy as any}
              />
            </>
          )}
        </ViewState>
      </Stack>
    </Card>
  );
};

const SkeletonTableItems = () => (
  <Stack direction="column" gap={3} sx={{ width: '100%' }}>
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Skeleton variant="rounded" sx={{ minWidth: '200px', width: '100%', minHeight: '60px' }} />
      <Skeleton variant="rounded" sx={{ minWidth: '200px', width: '100%', minHeight: '260px' }} />
      <Skeleton variant="rounded" sx={{ minWidth: '200px', width: '100%', minHeight: '75px' }} />
    </Box>
  </Stack>
);

export default AdditionalServices;
