import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import AppBar from '../core/AppBar';

export const AppBarLayout = () => {
  return (
    <Stack height="100%">
      <AppBar />
      <Outlet />
    </Stack>
  );
};

export default AppBarLayout;
