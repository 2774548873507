import { DialogActions, DialogContent, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useState } from 'react';
import { InstructionalFocus } from '@hoot/models/api/enums/instructional-focus';
import { ResourceReader } from '@hoot/models/api/enums/lesson-review-enums';
import { ResourceProgress } from '@hoot/models/api/library';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { ProgressSelect } from '@hoot/ui/components/v2/selects/LessonReviewProgressSelect';
import { ReaderSelect } from '@hoot/ui/components/v2/selects/LessonReviewReaderSelect';
import {
  LessonReviewBook,
  LessonReviewWizardStepEnum,
  useLessonReviewWizardContext,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';

const LessonReviewResourceProgressStep = () => {
  const {
    lessonToReview,
    instructionalFocusState,
    actions: { goToNextStep, goToPreviousStep, attachResourceProgressData },
  } = useLessonReviewWizardContext();

  const [books, setBooks] = useState(() => {
    if (!instructionalFocusState) {
      return [];
    }
    // Need a way to filter out duplicates. A book that was used in both tghe primary and secondary focus should only appear once in this context.
    const booksDictionary = new Map(
      [...instructionalFocusState.primaryFocusBooks, ...instructionalFocusState.secondaryFocusBooks].map((b) => [b.bookId, b]),
    );
    return Array.from(booksDictionary.values());
  });

  const handleBookChange = (book: LessonReviewBook) => {
    setBooks((currentState) => currentState.map((s) => (s.bookId === book.bookId ? book : s)));
  };

  const submitResourceProgress = () => {
    attachResourceProgressData(books);
    goToNextStep();
  };

  // Disable next button if some book doesn't have reader set
  const nextIsDisabled = books.filter((b) => b.focuses?.some((f) => f.id === InstructionalFocus.InstructionTextReading)).some((b) => !b.reader);

  return (
    <>
      <DialogContent>
        <Stack gap={2}>
          <HootTypography isPII={false} variant="titlemedium">
            {' '}
            Step {LessonReviewWizardStepEnum.ResourceProgress} - Resource Progress
          </HootTypography>
          <HootTypography isPII={true} variant="bodymedium">
            If <strong>{lessonToReview?.student.name}</strong> no longer needs to work on this resource, because they reached proficiency on this
            skill or otherwise, mark the resource as <strong>Finished</strong>.
          </HootTypography>
          <HootTypography isPII={false} variant="bodymedium">
            If they need more practice, leave unchecked.
          </HootTypography>
          <Grid container p={2} spacing={2} sx={{ flexFlow: 'wrap', overflowY: 'scroll', maxHeight: 460 }}>
            {books.map((b) => (
              <Grid key={b.bookId} size={{ xs: 12, md: 6 }}>
                <Book book={b} onChange={handleBookChange} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={goToPreviousStep}>
          Go Back
        </Button>
        <Button disabled={nextIsDisabled} variant="contained" onClick={submitResourceProgress}>
          Next
        </Button>
      </DialogActions>
    </>
  );
};

interface BookProps {
  book: LessonReviewBook;
  onChange: (book: LessonReviewBook) => void;
}

const Book = (props: BookProps) => {
  const { book } = props;
  const { title, coverImageUrl } = book;

  const handleReaderChange = (reader: ResourceReader) => {
    const updatedBook: LessonReviewBook = { ...book, reader: reader };
    props.onChange(updatedBook);
  };

  const handleProgressChange = (progress: ResourceProgress) => {
    const updatedBook: LessonReviewBook = { ...book, progress: progress };
    props.onChange(updatedBook);
  };

  return (
    <Card sx={{ height: '100%' }} contentSx={{ height: '100%' }}>
      <Stack direction={{ xs: 'column', md: 'row' }} height="100%" gap={2} alignItems="center" justifyContent="center">
        <Stack gap={1} alignItems="center" justifyContent="center">
          <img
            src={coverImageUrl}
            alt={title}
            style={{
              objectFit: 'contain',
              height: '133px',
              width: '133px',
            }}
          />
        </Stack>
        <Stack gap={2} width="100%">
          <HootTypography isPII={false} variant="titlesmall">
            {title}
          </HootTypography>
          <ProgressSelect sx={{ width: '100%' }} onChange={handleProgressChange} value={book.progress} />
          {book.focuses?.some((focus) => focus.id === InstructionalFocus.InstructionTextReading) || book.reader ? (
            <ReaderSelect sx={{ width: '100%' }} onChange={handleReaderChange} value={book.reader} />
          ) : null}
        </Stack>
      </Stack>
    </Card>
  );
};

export default LessonReviewResourceProgressStep;
