import { DailyVideo, useAudioTrack, useMeetingSessionState, useParticipantIds, useVideoTrack } from '@daily-co/daily-react';
import { Box, Stack, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { TEACHER_VIDEO_HEIGHT, TEACHER_VIDEO_WIDTH } from '@hoot/constants/daily/videoDimensions';
import { featureFlags } from '@hoot/constants/featureFlags';
import { zIndexes } from '@hoot/constants/zIndices';
import { DailyMediaState, useStudentMediaState } from '@hoot/hooks/daily/useDailyMediaState';
import { useRouteMatch } from '@hoot/hooks/useRouteMatch';
import { RootState } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';
import StudentPMAPage from '@hoot/ui/pages/v2/student/progress-monitoring-assessment/StudentPMAPage';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { Button } from '../core/Button';
import HootTypography from '../core/HootTypography';
import { Icon } from '../core/Icon';
import DailyAudioVisualizer from '../video/DailyAudioVisualizer';
import { CameraOff } from './ui-components/CameraOff';
import { CameraButton, LockButton, MicButton } from './ui-components/VideoComponents';

const RemoteStudentTile = () => {
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });
  const remoteSessionId = remoteParticipantIds.length > 0 ? remoteParticipantIds[0] : null;
  const remoteVideoTrack = useVideoTrack(remoteSessionId ?? '');
  const remoteAudioTrack = useAudioTrack(remoteSessionId ?? '');

  const studentName = useSelector((state: RootState) => state.activeLesson.inLesson?.studentName);
  const studentFirstName = studentName?.split(' ')[0];
  const { book } = useSelector((state: RootState) => state.readers.inLessonReader);

  const isOnStudentLibrary = useRouteMatch([`${routesDictionary.myStudents.details.library.absolutePath}`]);
  const isOnPMA = useRouteMatch([`${routesDictionary.lesson.progressMonitoringAssessment.absolutePath}`]);

  const meetingSessionState = useMeetingSessionState<DailyMediaState>();
  const { toggleMicrophone, toggleCamera, toggleLock } = useStudentMediaState();

  const isVideoOn = !remoteVideoTrack.isOff;
  const isMicrophoneOn = !remoteAudioTrack.isOff;
  const isStudentLocked = meetingSessionState.data?.studentIsLocked;

  const { isFeatureEnabled } = useFeatureFlags();
  const isAssessmentsV2Enabled = isFeatureEnabled(featureFlags.SC_11213_AssessmentSuite_v2);

  return (
    <Box
      sx={{
        padding: '16px',
        borderRadius: '8px',
        boxShadow: hootTokens.elevation.elevation2,
        backgroundColor: isStudentLocked ? hootTokens.palette.warning[180] : hootTokens.palette.white,
        outline: isStudentLocked ? `solid 1px ${hootTokens.palette.warning[100]}` : 'none',
        zIndex: !!isOnStudentLibrary && !!book ? zIndexes.video : undefined,
      }}
    >
      <HootTypography isPII variant="bodysmall" textAlign="center">
        {studentName}
      </HootTypography>

      <Stack gap={1} sx={{ position: 'relative' }}>
        {remoteSessionId && !remoteVideoTrack.isOff ? (
          <DailyVideo
            sessionId={remoteSessionId ?? ''}
            type={'video'}
            automirror={true}
            autoPlay={true}
            width={TEACHER_VIDEO_WIDTH}
            height={TEACHER_VIDEO_HEIGHT}
            fit="cover"
            style={{
              borderRadius: '8px',
            }}
          />
        ) : null}

        {remoteVideoTrack.isOff && <CameraOff />}

        <Box position="absolute" top={8} right={8}>
          {isVideoOn && <DailyAudioVisualizer audioTrack={remoteAudioTrack} />}
        </Box>

        <Stack justifyContent="center" direction="row" columnGap={1}>
          <Tooltip title={`Re-enabling student's video is disabled`}>
            <Box>
              <CameraButton disabled={!isVideoOn} isOn={isVideoOn} onClick={toggleCamera} />
            </Box>
          </Tooltip>

          <MicButton isOn={isMicrophoneOn} onClick={toggleMicrophone} />

          <LockButton isLocked={meetingSessionState.data?.studentIsLocked} onClick={toggleLock} />
        </Stack>

        {isAssessmentsV2Enabled && isOnPMA ? (
          <Stack sx={{ alignItems: 'center' }}>
            <HootTypography isPII={false} variant="titlesmall">
              {studentFirstName}'s Screen
            </HootTypography>
            <StudentPMAPage isThumbnailView={true} />
            <Button size="small" fullWidth variant="contained" color="primary.190" startIcon={<Icon name="new_tab" />} sx={{ marginTop: 2 }}>
              Expand Screen
            </Button>
          </Stack>
        ) : null}
      </Stack>
    </Box>
  );
};

export default RemoteStudentTile;
