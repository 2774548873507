import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ForgotPasswordModalStudent = (props: IProps) => {
  const { open, setOpen } = props;
  return (
    <BasicAlertDialog
      show={open}
      onDismiss={() => setOpen(false)}
      title={'Password Reset'}
      content={
        <HootTypography isPII={false} variant="bodylarge">
          Please contact your district representative to reset your password.
        </HootTypography>
      }
      primaryAction={{
        label: 'OK',
        onClick: () => setOpen(false),
      }}
    />
  );
};

export default ForgotPasswordModalStudent;
