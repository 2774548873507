import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../config';
import { QueryKey } from './queryKeys';

async function latestHootReadingAppVersion(): Promise<string> {
  const { data } = await axios.get<string>(`${config.apiUrl}/app/latest-hoot-reading-app-version`);

  return data;
}

export default function useLatestHootReadingAppVersion(options?: Omit<UseQueryOptions<string>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.LatestHootReadingAppVersion], () => latestHootReadingAppVersion(), options);
}
