import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface MonthlyScheduledLessonsQuery {
  startDateInMonth: number;
  endDateInMonth: number;
  studentProfileFilterIds?: string[];
}

export interface LessonDetails {
  lessonDate: string;
  lessonStartTime: number;
  lessonEndTime: number;
  lessonNumber: string;
  lessonStatus: ScheduledLessonStatus;
  displayedLessonStatus: string;
  studentName: string;
  teacherName: string;
  cancellationReason?: string;
  lessonSubject?: string;
  lessonLanguage?: string;
}

export interface LessonsForDay {
  date: string;
  lessons: LessonDetails[];
}

export interface MonthlyScheduledLessonsQueryResponse {
  lessonsByDate: LessonsForDay[];
}

export async function fetchMonthlyScheduledLessonsList(query: MonthlyScheduledLessonsQuery) {
  const { data } = await axios.post<MonthlyScheduledLessonsQueryResponse>(
    `${config.apiUrl}/public/scheduler/monthly-scheduled-lessons/search-V2`,
    query,
  );
  return data;
}

export default function useMonthlyScheduledLessonsQueryV2(monthlyScheduledLessonsQuery: MonthlyScheduledLessonsQuery | undefined) {
  return useQuery(
    [QueryKey.MonthlySchedulesLessons, monthlyScheduledLessonsQuery],
    () => fetchMonthlyScheduledLessonsList(monthlyScheduledLessonsQuery!),
    { enabled: !!monthlyScheduledLessonsQuery },
  );
}
