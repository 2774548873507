import { Box, Stack } from '@mui/system';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useReaders } from '../../../../../../../redux/reducers/readingSlice';
import ReadOnlyTextField from '../../../../../../components/v2/core/ReadOnlyTextField';
import TextField from '../../../../../../components/v2/core/TextField';

interface Form {
  time: string;
  wordCount: number;
  errors: number;
}

export function UNIT_4_1() {
  return (
    <Stack gap={4} paddingY={3} direction="row" justifyContent="space-evenly">
      <Assessment />
    </Stack>
  );
}

function Assessment() {
  const { control, watch, reset } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      errors: undefined,
      wordCount: undefined,
    },
  });

  const {
    inLessonReader: { book },
  } = useReaders();
  const bookWordCount = book?.wordCount ?? 0;

  useEffect(() => {
    reset({
      wordCount: bookWordCount,
    });
  }, [bookWordCount, reset]);

  const wordCount = watch('wordCount') ?? 0;
  const errors = watch('errors') ?? 0;
  const time = watch('time');

  function extractMinutes() {
    const splitTime = time?.split(':') ?? [];
    if (splitTime.length === 2) {
      return Number(splitTime[0]);
    }
    return 0;
  }

  function extractSeconds() {
    const splitTime = time?.split(':') ?? [];
    if (splitTime.length === 2) {
      return Number(splitTime[1]);
    }
    return 0;
  }

  const timeInMinutes = extractMinutes();
  const timeInSeconds = extractSeconds();

  // Calculation: (Word Count - Errors) * 100 / Word Count
  const accuracyRate = wordCount > 0 ? ((wordCount - errors) * 100) / wordCount : 0;

  // Calculation: (Word Count - Errors) * 60 / (Time in minutes * 60 + Time in seconds)
  const wordsCorrectPerMinute = timeInMinutes > 0 || timeInSeconds > 0 ? ((wordCount - errors) * 60) / (timeInMinutes * 60 + timeInSeconds) : 0;

  return (
    <Stack flex={1} spacing="16px">
      <Stack direction="row" spacing="16px">
        <Controller
          name="time"
          control={control}
          rules={{
            required: 'Time is required.',
            pattern: {
              value: /[0-5]?[0-9]:[0-5][0-9]/,
              message: 'Time needs to be in the format 59:59',
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              placeholder="Minutes"
              onChange={field.onChange}
              value={field.value}
              label="Time"
              required
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />

        <Controller
          name="wordCount"
          control={control}
          rules={{
            required: 'Word count is required',
          }}
          render={({ field, fieldState }) => (
            <TextField
              placeholder="Word Count"
              onChange={field.onChange}
              value={field.value}
              fullWidth
              label="Word Count"
              required
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />

        <Controller
          name="errors"
          control={control}
          rules={{
            required: 'Errors is required',
          }}
          render={({ field, fieldState }) => (
            <TextField
              placeholder="Errors"
              onChange={field.onChange}
              value={field.value}
              fullWidth
              label="Errors"
              required
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </Stack>
      <Stack spacing="16px" direction="row">
        <Box flex={1}>
          <ReadOnlyTextField label="WCPM" body={wordsCorrectPerMinute === 0 ? '-' : wordsCorrectPerMinute.toFixed(2)} />
        </Box>
        <Box flex={1}>
          <ReadOnlyTextField label="Accuracy" body={accuracyRate === 0 ? '-' : accuracyRate.toFixed(2)} />
        </Box>
      </Stack>
    </Stack>
  );
}
