import { Stack } from '@mui/material';
import { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieButton from '@hoot/ui/components/v2/lottie/LottieButton';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface StudentInLessonRightPaneProps {
  onPageUp: () => void;
  onPageDown: () => void;
}

const StudentInLessonRightPane = (props: StudentInLessonRightPaneProps) => {
  const { onPageUp, onPageDown } = props;
  return (
    <Stack
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
        width: '184px',
        padding: 5,
        gap: 5,
        background: hootTokens.palette.white,
        boxShadow: '-1px 0px 3px 0px rgba(0, 0, 0, 0.30), -4px 0px 8px 3px rgba(0, 0, 0, 0.15);',
        justifyContent: 'space-between',
      }}
    >
      <LottieButton tooltipLabel="Page up" lottieFile={LottieFile.ArrowUp} aria-label="Page up" onClick={onPageUp} />
      <LottieButton tooltipLabel="Page down" lottieFile={LottieFile.ArrowDown} aria-label="Page down" onClick={onPageDown} />
    </Stack>
  );
};

export default StudentInLessonRightPane;
