import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface SeriesResponse {
  categorizedSeries: CategorizedSeries[];
}

interface CategorizedSeries {
  parentCategory: string;
  listItems: {
    label: string;
    value: string;
  }[];
}

async function getSeries(): Promise<SeriesResponse> {
  const { data } = await axios.get<SeriesResponse>(`${config.apiUrl}/library/series`);
  return data;
}

export default function useGetSeries(options?: Omit<UseQueryOptions<SeriesResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetSeries], () => getSeries(), options);
}
