import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { Icon, IconProps } from './Icon';

type ChipProps = Omit<MuiChipProps, 'size'>;

const OptionSelectedChip = (props: ChipProps) => {
  const { sx, ...chipProps } = props;

  const onDeleteFromKeyPress: IconProps['onKeyDown'] = (event) => {
    if (['Enter', 'Space'].includes(event.code)) {
      props?.onDelete?.({});
    }
  };
  return (
    <MuiChip
      tabIndex={-1}
      sx={{
        p: 1,
        borderRadius: '8px',
        height: '80px',
        background: hootTokens.surface['2'],
        border: 'none',
        maxWidth: 650,
        '& .MuiChip-label': {
          ...hootTokens.text.headlinelarge,
        },
        '& .MuiChip-deleteIcon': {
          width: '44px',
          height: '44px',
        },
        ...sx,
      }}
      deleteIcon={<Icon tabIndex={0} name="cancel_icon_filled" onKeyDown={onDeleteFromKeyPress} />}
      {...chipProps}
    />
  );
};

export default OptionSelectedChip;
