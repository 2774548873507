import { UserProfileType } from './user';

export interface StudentProfile {
  id: string;
  userId: string;
  name: string;
  number: number;
  type: UserProfileType;
  isEnabled: boolean;
  createdAt: number;
  birthYear: number;
  interests: string;
  focusChallenges: FocusChallenges;
  schedulePreferences: string;
  pronouns: string;
  parentNotes: string;
  status: StudentStatus;
  leadInitiationDate: Date;
  conversionDate: Date;
  notConvertedReason: NotConvertedReason;
  pauseEndDate: Date;
  lapsedDate: Date;
  lapsedReason: LapsedReason;
  studentType: StudentType;
}

export type LapsedReason =
  | 'TOO_YOUNG'
  | 'PAYMENT'
  | 'PROGRAM_CONTENT'
  | 'CHILD_TOO_BUSY'
  | 'LESSON_EXPERIENCE'
  | 'NO_RESPONSE'
  | 'FUNDING_PERIOD'
  | 'PROGRAM_EXPIRATION'
  | 'GRADUATED'
  | 'TEACHER_SCHEDULING'
  | 'OTHER';

export type NotConvertedReason =
  | 'TOO_OLD'
  | 'TOO_YOUNG'
  | 'PAYMENT'
  | 'PROGRAM_CONTENT'
  | 'CHILD_TOO_BUSY'
  | 'LESSON_SCHEDULING'
  | 'LESSON_EXPERIENCE'
  | 'NO_RESPONSE'
  | 'PRIVACY'
  | 'OTHER';

export type StudentStatus = 'ACTIVE' | 'LEAD' | 'COLD' | 'PAUSED' | 'LAPSED';

export enum StudentStatusEnum {
  Active = 'ACTIVE',
  Lead = 'LEAD',
  Cold = 'COLD',
  Paused = 'PAUSED',
  Lapsed = 'LAPSED',
}

export enum FocusChallenges {
  Yes = 'YES',
  No = 'NO',
}

export enum ReadingStrengthsGoals {
  Phonics = 'PHONICS',
  Decoding = 'DECODING',
  Expression = 'EXPRESSION',
  UsingPunctuation = 'USING_PUNCTUATION',
  Fluency = 'FLUENCY',
  ComprehensionSummarizing = 'COMPREHENSION_SUMMARIZING',
  ComprehensionPredicting = 'COMPREHENSION_PREDICTING',
  ComprehensionMakingInferences = 'COMPREHENSION_MAKING_INFERENCES',
  Vocabulary = 'VOCABULARY',
  Other = 'OTHER',
}

export enum MathStrengthsGoals {
  Subitization = 'SUBITIZATION',
  Counting = 'COUNTING',
  OralCounting = 'ORAL_COUNTING',
  NumberRecognition = 'NUMBER_RECOGNITION',
  Sequencing = 'SEQUENCING',
  Money = 'MONEY',
  Addition = 'ADDITION',
  Subtraction = 'SUBTRACTION',
  Multiplication = 'MULTIPLICATION',
  Division = 'DIVISION',
  Fractions = 'FRACTIONS',
  Time = 'TIME',
  Geometry = 'GEOMETRY',
  Other = 'OTHER',
}

export enum StudentType {
  HootDecodableAccess = 'HOOT_DECODABLE_ACCESS',
  HootTutoring = 'HOOT_TUTORING',
}

export enum StudentGrade {
  PreKindergarten = 'PRE-KINDERGARTEN',
  Kindergarten = 'KINDERGARTEN',
  Grade1 = 'GRADE_1',
  Grade2 = 'GRADE_2',
  Grade3 = 'GRADE_3',
  Grade4 = 'GRADE_4',
  Grade5 = 'GRADE_5',
  Grade6 = 'GRADE_6',
  Grade7 = 'GRADE_7',
  Grade8 = 'GRADE_8',
  Grade9 = 'GRADE_9',
  Grade10 = 'GRADE_10',
  Grade11 = 'GRADE_11',
  Grade12 = 'GRADE_12',
}

export const studentGradeLabelDictionary: Record<StudentGrade, string> = {
  [StudentGrade.PreKindergarten]: 'Pre-Kindergarten',
  [StudentGrade.Kindergarten]: 'Kindergarten',
  [StudentGrade.Grade1]: 'Grade 1',
  [StudentGrade.Grade2]: 'Grade 2',
  [StudentGrade.Grade3]: 'Grade 3',
  [StudentGrade.Grade4]: 'Grade 4',
  [StudentGrade.Grade5]: 'Grade 5',
  [StudentGrade.Grade6]: 'Grade 6',
  [StudentGrade.Grade7]: 'Grade 7',
  [StudentGrade.Grade8]: 'Grade 8',
  [StudentGrade.Grade9]: 'Grade 9',
  [StudentGrade.Grade10]: 'Grade 10',
  [StudentGrade.Grade11]: 'Grade 11',
  [StudentGrade.Grade12]: 'Grade 12',
};

export enum PasswordStatus {
  Set = 'SET',
  NotSet = 'NOT_SET',
}
