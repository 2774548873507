import { ButtonBaseProps, Tooltip } from '@mui/material';
import React, { CSSProperties, useState } from 'react';
import Lottie, { LottieProps } from 'react-lottie-player';
import PlainButton from '@hoot/ui/components/v2/core/PlainButton';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { LottieFile, lottiePathPrefix } from './Lottie';

type Variant = 'plain' | 'transparent';

const defaultDimension = 64;

export interface DoubleIconLottieButtonProps extends ButtonBaseProps {
  lottieFile1: LottieFile;
  lottieFile2: LottieFile;
  LottieProps1?: Omit<LottieProps, 'lottieFile'>;
  LottieProps2?: Omit<LottieProps, 'lottieFile'>;
  lottieWidth?: number;
  lottieHeight?: number;
  variant?: Variant;
  'aria-label': string;
  tooltipLabel?: string;
  rootStyle?: CSSProperties;
}

const styles = {
  plain: {
    root: {
      borderRadius: '4px',
      ...hootTokens.elevation.elevation1,
    },
  },
  transparent: {
    // FYI: Need to override defaults from <PlainButton> in some spots.
    root: {
      borderRadius: '8px',
      background: 'unset',
      boxShadow: 'unset',
    },
    hover: {
      background: hootTokens.surface['2'],
    },
  },
};

const DoubleIconLottieButton = (props: DoubleIconLottieButtonProps) => {
  const {
    lottieWidth,
    lottieHeight,
    LottieProps1 = {},
    LottieProps2 = {},
    lottieFile1,
    lottieFile2,
    rootStyle = {},
    tooltipLabel,
    variant = 'plain',
    sx = {},
    ...buttonProps
  } = props;

  const [animate, setAnimate] = useState(false);
  const [isLastLoop, setIsLastLoop] = useState(false);

  const startAnimation = () => {
    setAnimate(true);
    setIsLastLoop(false);
  };

  const stopAnimationAfterLoop = () => {
    setIsLastLoop(true);
  };

  const onLoopComplete = () => {
    if (isLastLoop) {
      setAnimate(false);
    }
  };

  return (
    <Tooltip title={tooltipLabel}>
      <span
        style={{
          ...rootStyle,
        }}
      >
        <PlainButton
          onMouseEnter={startAnimation}
          // Animation will stop when the current loop is finished.
          onMouseLeave={stopAnimationAfterLoop}
          sx={{
            minWidth: 0,
            ...(variant === 'plain' && {
              ...styles.plain.root,
            }),
            ...(variant === 'transparent' && {
              ...styles.transparent.root,
            }),
            ...sx,
          }}
          {...buttonProps}
        >
          <Lottie
            loop
            path={`/${lottiePathPrefix}/${lottieFile1}`}
            play={animate}
            onLoopComplete={onLoopComplete}
            style={{ width: lottieWidth ?? defaultDimension, height: lottieHeight ?? defaultDimension }}
            {...LottieProps1}
          />
          <Lottie
            loop
            path={`/${lottiePathPrefix}/${lottieFile2}`}
            play={animate}
            onLoopComplete={onLoopComplete}
            style={{ width: lottieWidth ?? defaultDimension, height: lottieHeight ?? defaultDimension }}
            {...LottieProps2}
          />
        </PlainButton>
      </span>
    </Tooltip>
  );
};

export default DoubleIconLottieButton;
