import { Stack, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useGetInvoiceDetails } from '@hoot/hooks/api/invoice/useGetInvoiceDetails';
import { useResubmitInvoiceLessons } from '@hoot/hooks/api/invoice/useResubmitInvoiceLessons';
import useRetractInvoiceSubmission from '@hoot/hooks/api/invoice/useRetractInvoiceSubmission';
import useSubmitInvoice, { SubmitInvoiceDto } from '@hoot/hooks/api/invoice/useSubmitInvoice';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import Banner from '@hoot/ui/components/v2/Banner';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import Page from '@hoot/ui/components/v2/core/Page';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import theme from '@hoot/ui/theme/v2';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import HootTypography from '../../../../components/v2/core/HootTypography';
import InvoiceDetails from './InvoiceDetails';
import InvoicingReminder from './InvoicingReminder';
import { InvoicePeriodStatus, TeacherInvoiceStatus } from './enums';

export function Invoice() {
  const { invoiceId } = useParams<{ invoiceId: string }>();

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [showConfirmSubmitDialog, setShowConfirmSubmitDialog] = useState<boolean>(false);
  const [showConfirmRetractDialog, setShowConfirmRetractDialog] = useState<boolean>(false);
  const [showPartiallySubmittedBanner, setShowPartiallySubmittedBanner] = useState(false);

  const submitInvoice = useSubmitInvoice();
  const retractInvoice = useRetractInvoiceSubmission();
  const resubmitInvoiceLessonsMutation = useResubmitInvoiceLessons();

  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const unsubmittedLessonsTableRef = useRef<HTMLDivElement>(null);
  const scrollToUnsubmittedLessonsTable = () => unsubmittedLessonsTableRef?.current?.scrollIntoView();

  const {
    data: invoiceDetails,
    isLoading,
    isError,
  } = useGetInvoiceDetails(invoiceId || '', {
    retry: false,
    enabled: !!invoiceId,
    onError: (ex) => {
      console.error(ex);
      dispatch(createFlashMessage({ variant: 'error', message: 'There was an error while loading invoice details' }));
    },
  });

  const isSubmitted = invoiceDetails?.status
    ? [TeacherInvoiceStatus.Submitted, TeacherInvoiceStatus.Resubmitted].includes(invoiceDetails?.status)
    : false;

  const isCurrentPeriod = !invoiceDetails?.isInvoicePeriodPast;
  const isInvoicePeriodSubmitted = invoiceDetails?.invoicePeriodStatus === InvoicePeriodStatus.Submitted;

  const showSubmitButton = isCurrentPeriod && !isLoading;
  const showRetractButton = isSubmitted && isCurrentPeriod && !isLoading;
  const showDownloadButton = isInvoicePeriodSubmitted && !isLoading;
  const showReminderCard = !isSubmitted && isCurrentPeriod && !isLoading;

  const onSubmit = () => {
    const request: SubmitInvoiceDto = {
      invoiceId: invoiceId ?? '',
    };
    submitInvoice.mutate(request, {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.GetInvoiceDetails);
        queryClient.invalidateQueries(QueryKey.GetInvoiceSummary);
        queryClient.invalidateQueries(QueryKey.GetInvoiceBillableLessonSummary);
        setShowConfirmSubmitDialog(false);
        dispatch(createFlashMessage({ message: 'Invoice Successfully Submitted' }));
      },
      onError: () => {
        dispatch(createFlashMessage({ variant: 'error', message: 'There was an error while submitting invoice' }));
      },
    });
  };

  const onRetract = () => {
    retractInvoice.mutate(invoiceId ?? '', {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.GetInvoiceDetails);
        queryClient.invalidateQueries(QueryKey.GetInvoiceSummary);
        queryClient.invalidateQueries(QueryKey.GetInvoiceBillableLessonSummary);
        setShowConfirmRetractDialog(false);
        dispatch(createFlashMessage({ message: 'Invoice Successfully Retracted' }));
      },
      onError: () => {
        dispatch(createFlashMessage({ variant: 'error', message: 'There was an error while retracting invoice' }));
      },
    });
  };

  const onResubmitInvoiceLessons = (invoiceId: string) => {
    resubmitInvoiceLessonsMutation.mutate(invoiceId, {
      onSuccess: () => {
        setShowPartiallySubmittedBanner(false);

        queryClient.invalidateQueries(QueryKey.GetInvoiceDetails);
        queryClient.invalidateQueries(QueryKey.GetInvoiceSummary);
        queryClient.invalidateQueries(QueryKey.GetInvoiceBillableLessonSummary);

        dispatch(createFlashMessage({ message: 'Invoice Resubmitted Successfully' }));
      },
      onError: () => {
        dispatch(createFlashMessage({ variant: 'error', message: 'There was an error while resubmitting invoice' }));
      },
    });
  };

  const viewState = useMemo<ViewStateEnum>(() => {
    if (isLoading) {
      return ViewStateEnum.Loading;
    } else if (isError) {
      return ViewStateEnum.Error;
    } else {
      return ViewStateEnum.Results;
    }
  }, [isLoading, isError]);

  return (
    <>
      <Stack>
        {showPartiallySubmittedBanner && (
          <Banner
            backgroundColor="warning.160"
            message="New billable lesson line items are available. Resubmit to bill for new items."
            showClose
            onClose={() => setShowPartiallySubmittedBanner(false)}
            primaryAction={{
              label: 'View Changes',
              onClick: () => scrollToUnsubmittedLessonsTable(),
              props: {
                variant: 'outlined',
                sx: { minWidth: '121px', backgroundColor: hootTokens.palette.warning['160'] },
              },
            }}
            secondaryAction={{
              label: 'Resubmit',
              onClick: () => onResubmitInvoiceLessons(invoiceId!),
              props: {
                disabled: !invoiceId,
                isLoading: resubmitInvoiceLessonsMutation.isLoading,
                sx: { minWidth: '121px' },
              },
            }}
          />
        )}

        <SubHeader
          title={{
            label: `Invoice ${isCurrentPeriod ? '(Current Period)' : ''}`,
            isPII: false,
            isLoading,
          }}
          breadcrumbs={['Invoicing', 'Invoice Details']}
          backButton={{
            show: true,
          }}
          primaryAction={
            showRetractButton
              ? {
                  label: 'Retract Invoice',
                  onClick: () => setShowConfirmRetractDialog(true),
                  props: {
                    startIcon: <Icon name="redo" htmlColor="white" />,
                    isLoading: retractInvoice.isLoading,
                    disabled: !isCurrentPeriod,
                    color: 'error.80',
                  },
                }
              : undefined
          }
          secondaryAction={
            showDownloadButton
              ? {
                  label: 'Print Invoice',
                  onClick: () => window.print(),
                  props: {
                    startIcon: <Icon name="page" />,
                    variant: 'contained',
                  },
                }
              : undefined
          }
          tertiaryAction={
            showSubmitButton
              ? {
                  label: isSubmitted ? 'Submitted' : 'Submit Invoice',
                  onClick: () => setShowConfirmSubmitDialog(true),
                  props: {
                    variant: 'contained',
                    isLoading: submitInvoice.isLoading,
                    disabled: isSubmitted,
                    color: 'success.140',
                  },
                }
              : undefined
          }
          sx={{ mb: 0 }}
        />

        {/* Main Content */}
        <Page pageTitle="Invoicing | Hoot Reading" RootBoxProps={{ py: 2 }}>
          <Grid container marginTop={'2px'} spacing={2}>
            {showReminderCard && (
              <Grid size={12}>
                <InvoicingReminder />
              </Grid>
            )}
            <Grid sx={{ width: '100%' }}>
              <InvoiceDetails
                billableLessonsScrollToRefProp={unsubmittedLessonsTableRef}
                invoiceDetails={invoiceDetails}
                viewState={viewState}
                setShowConfirmSubmitDialog={setShowConfirmSubmitDialog}
                setShowConfirmRetractDialog={setShowConfirmRetractDialog}
                submitInvoiceLoading={submitInvoice.isLoading}
                retractInvoiceLoading={retractInvoice.isLoading}
                setShowPartiallySubmittedBanner={setShowPartiallySubmittedBanner}
                resubmitLessonsMutation={resubmitInvoiceLessonsMutation}
                isCurrentPeriod={isCurrentPeriod}
                isSubmitted={isSubmitted}
                isInvoicePeriodSubmitted={isInvoicePeriodSubmitted}
              />
            </Grid>
          </Grid>
        </Page>
      </Stack>

      {/* Confirm Submission Dialog */}
      <BasicAlertDialog
        show={showConfirmSubmitDialog}
        showClose={isDesktop}
        onDismiss={() => setShowConfirmSubmitDialog(false)}
        title="Confirm Submission"
        content={
          <Stack spacing={2}>
            <HootTypography isPII={false} variant="bodylarge">
              You're about to submit your invoice. You can retract and resubmit your invoice up until 11:59 PM Central Time of the last day of the
              month.
            </HootTypography>
            <HootTypography isPII={false} variant="bodylarge">
              Are you sure you want to continue?
            </HootTypography>
          </Stack>
        }
        primaryAction={{
          onClick: onSubmit,
          label: isDesktop ? 'Submit Invoice' : 'Submit',
          props: {
            color: 'success.140',
            variant: 'contained',
            isLoading: submitInvoice.isLoading,
          },
        }}
        secondaryAction={{
          onClick: () => setShowConfirmSubmitDialog(false),
          label: 'Cancel',
          props: {
            variant: 'outlined',
          },
        }}
      />

      {/* Confirm Retract Dialog */}
      <BasicAlertDialog
        show={showConfirmRetractDialog}
        showClose={isDesktop}
        onDismiss={() => setShowConfirmRetractDialog(false)}
        title="Confirm Retraction"
        content={
          <Stack spacing={2}>
            <HootTypography isPII={false} variant="bodylarge">
              You're about to retract your invoice. You can resubmit your invoice for the billable period up until 11:59 PM Central Time of the last
              day of the month.
            </HootTypography>
            <HootTypography isPII={false} variant="bodylarge">
              Are you sure you want to continue?
            </HootTypography>
          </Stack>
        }
        primaryAction={{
          onClick: onRetract,
          label: isDesktop ? 'Retract Invoice' : 'Retract',
          props: {
            color: 'error',
            variant: 'contained',
            isLoading: retractInvoice.isLoading,
          },
        }}
        secondaryAction={{
          onClick: () => setShowConfirmRetractDialog(false),
          label: 'Cancel',
          props: {
            variant: 'outlined',
          },
        }}
      />
    </>
  );
}
