import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';

const TIMER_INTERVAL_MILLIS = 250;

const useStopWatch = () => {
  const intervalIdRef = useRef<number>();

  const timeStarted = useRef(0);
  const millisElapsed = useRef(0);

  const [isRunning, setIsRunning] = useState(false);
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [secondsElapsedDisplay, setSecondsElapsedDisplay] = useState('00:00');

  const timerController = {
    start: () => {
      // We can't just simply assume that this interval will actually trigger _exactly_ every TIMER_INTERVAL_MILLIS
      // milliseconds. Threads get paused if low priority (like if you switch tabs,for example). But we can get around
      // this by referring to the current timestamp at every interval, and doing some simple math.
      timeStarted.current = !millisElapsed.current ? DateTime.now().toMillis() : DateTime.now().toMillis() - millisElapsed.current;

      intervalIdRef.current = window.setInterval(() => {
        millisElapsed.current = DateTime.now().toMillis() - timeStarted.current;
        const newSecondsElapsed = Math.trunc(millisElapsed.current / 1000);

        if (newSecondsElapsed !== secondsElapsed) {
          setSecondsElapsed(newSecondsElapsed);
          setSecondsElapsedDisplay(secondsToLabel(newSecondsElapsed));
        }
      }, TIMER_INTERVAL_MILLIS);

      setIsRunning(true);
    },
    pause: () => {
      window.clearInterval(intervalIdRef.current);
      intervalIdRef.current = undefined;

      setIsRunning(false);
    },
    reset: () => {
      window.clearInterval(intervalIdRef.current);

      intervalIdRef.current = undefined;
      millisElapsed.current = 0;

      setIsRunning(false);
      setSecondsElapsed(0);
      setSecondsElapsedDisplay(secondsToLabel(0));
    },
  };

  const secondsToLabel = (secondsElapsed: number): string => {
    const minutes = Math.floor(secondsElapsed / 60);
    const seconds = Math.trunc(secondsElapsed % 60);

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const startOrPauseTimer = () => {
    if (intervalIdRef.current) {
      timerController.pause();
    } else {
      timerController.start();
    }
  };

  // Kill timer on un-mount.
  useEffect(() => {
    return () => {
      timerController.reset();
    };
  }, []);

  return {
    startOrPauseTimer,
    resetTimer: timerController.reset,
    secondsElapsed,
    secondsElapsedDisplay,
    isRunning,
  };
};

export default useStopWatch;
