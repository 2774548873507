import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';
import { HootAssessmentV2Response } from '@hoot/hooks/api/assessment/useGetStudentAssessmentsV2';

export interface AbandonAssessmentRequest {
  assessmentId: string;
  abandonedReason: string;
}

export function useAbandonAssessmentV2(options?: UseMutationOptions<HootAssessmentV2Response, AxiosError, AbandonAssessmentRequest>) {
  return useMutation<HootAssessmentV2Response, AxiosError, AbandonAssessmentRequest>(async (request) => {
    const { assessmentId, abandonedReason } = request;
    return (await axios.post<HootAssessmentV2Response>(`${config.apiUrl}/public/hoot-assessment/${assessmentId}/abandon`, { abandonedReason })).data;
  }, options);
}
