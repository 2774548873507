import { LessonWhiteboardTemplateStatus } from '@hoot/hooks/api/whiteboard/useGetLessonWhiteboardTemplates';
import { WhiteboardTemplateTypeEnum } from '@hoot/models/api/enums/whiteboard-template-type-enum';

export const wbTemplateStatusLabelDictionary: Record<LessonWhiteboardTemplateStatus, string> = {
  [LessonWhiteboardTemplateStatus.NotStarted]: 'Not Started',
  [LessonWhiteboardTemplateStatus.InProgress]: 'In Progress',
};

export const wbTemplateTypeLabelDictionary: Record<WhiteboardTemplateTypeEnum, string> = {
  [WhiteboardTemplateTypeEnum.ShortVowelsCVC]: 'Short Vowels CVC',
  [WhiteboardTemplateTypeEnum.Digraphs]: 'Digraphs',
  [WhiteboardTemplateTypeEnum.BlendsLongVowels]: 'Blends Long Vowels',
  [WhiteboardTemplateTypeEnum.BasicRControlledVowels]: 'Basic R Controlled Vowels',
  [WhiteboardTemplateTypeEnum.BasicVowelTeams]: 'Basic Vowel Teams',
  [WhiteboardTemplateTypeEnum.SpellingWords4Letters]: 'Spelling Words 4 Letters',
  [WhiteboardTemplateTypeEnum.SpellingWords5Letters]: 'Spelling Words 5 Letters',
  [WhiteboardTemplateTypeEnum.SpellingWords6Letters]: 'Spelling Words 6 Letters',
  [WhiteboardTemplateTypeEnum.VennDiagram]: 'Venn Diagram',
  [WhiteboardTemplateTypeEnum.BasicSituationModel]: 'Basic Situation Model',
};
