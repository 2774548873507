import { SvgIcon, SvgIconProps } from '@mui/material';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const CollectionsColoured = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 3.75024C1.25 3.33603 1.58579 3.00024 2 3.00024H4H15C16.6569 3.00024 18 4.34339 18 6.00024C18 7.6571 16.6569 9.00024 15 9.00024H20H22C22.4142 9.00024 22.75 9.33603 22.75 9.75024C22.75 10.1645 22.4142 10.5002 22 10.5002H20V13.5002H22C22.4142 13.5002 22.75 13.836 22.75 14.2502C22.75 14.6645 22.4142 15.0002 22 15.0002H20H15C16.6569 15.0002 18 16.3434 18 18.0002C18 19.6571 16.6569 21.0002 15 21.0002H4H2C1.58579 21.0002 1.25 20.6645 1.25 20.2502C1.25 19.836 1.58579 19.5002 2 19.5002H4V16.5002H2C1.58579 16.5002 1.25 16.1645 1.25 15.7502C1.25 15.336 1.58579 15.0002 2 15.0002H4H9C7.34315 15.0002 6 13.6571 6 12.0002C6 10.3434 7.34315 9.00024 9 9.00024H4H2C1.58579 9.00024 1.25 8.66446 1.25 8.25024C1.25 7.83603 1.58579 7.50024 2 7.50024H4V4.50024H2C1.58579 4.50024 1.25 4.16446 1.25 3.75024Z"
        fill={`${hootTokens.palette.secondary[60]}`}
      />
    </SvgIcon>
  );
};

export default CollectionsColoured;
