import { SvgIcon, SvgIconProps } from '@mui/material';

const RedoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.75 12C21.75 11.5858 21.4142 11.25 21 11.25C20.5858 11.25 20.25 11.5858 20.25 12H21.75ZM19.796 7.5V8.25C20.2102 8.25 20.546 7.91421 20.546 7.5H19.796ZM20.546 3C20.546 2.58579 20.2102 2.25 19.796 2.25C19.3817 2.25 19.046 2.58579 19.046 3H20.546ZM15.375 6.75C14.9608 6.75 14.625 7.08579 14.625 7.5C14.625 7.91421 14.9608 8.25 15.375 8.25V6.75ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25V21.75C17.3848 21.75 21.75 17.3848 21.75 12H20.25ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75V2.25C6.61522 2.25 2.25 6.61522 2.25 12H3.75ZM12 3.75C15.0527 3.75 17.7192 5.40766 19.1468 7.87554L20.4452 7.12446C18.7605 4.21213 15.6098 2.25 12 2.25V3.75ZM20.546 7.5V3H19.046V7.5H20.546ZM19.796 6.75H15.375V8.25H19.796V6.75Z"
        fill={props.htmlColor ?? 'currentColor'}
      />
    </SvgIcon>
  );
};

export default RedoIcon;
