import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MAXIMUM_NUMBER_CELEBRATIONS } from '@hoot/constants/constants';
import { EventType } from '@hoot/events/eventType';
import { LessonCelebrationPromptMessage } from '@hoot/events/messages/lesson-celebration.message';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { error } from '@hoot/redux/reducers/alertSlice';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { FloatingDialog } from '@hoot/ui/components/v2/core/FloatingDialog';
import { useSocket } from '@hoot/ui/context/SocketContext';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import ChipGroup from '../core/ChipGroup';
import { BaseChipGroupItem } from '../core/DetailedChipGroup';
import HootTypography from '../core/HootTypography';

export interface NotepadDialogProps {
  show: boolean;
  onHide: () => void;
}

export enum CelebrationType {
  Confetti = 'CONFETTI',
  Star = 'STAR',
  Thumb = 'THUMB',
}

const celebrationTyps: BaseChipGroupItem[] = [
  { value: CelebrationType.Confetti, label: '🎉 Confetti' },
  { value: CelebrationType.Star, label: '🌟 Star' },
  { value: CelebrationType.Thumb, label: '👍 Thumb' },
];

export enum CelebrationReason {
  SkillImprovement = 'SKILL_IMPROVEMENT',
  PositiveAttitude = 'POSITIVE_ATTITUDE',
  RapportBuilding = 'RAPPORT_BUILDING',
}

const celebrationReasons: BaseChipGroupItem[] = [
  { value: CelebrationReason.SkillImprovement, label: 'Skill Improvement' },
  { value: CelebrationReason.PositiveAttitude, label: 'Positive Attitude' },
  { value: CelebrationReason.RapportBuilding, label: 'Rapport Building' },
];

const CelebrationsDialog = (props: NotepadDialogProps) => {
  const { show, onHide } = props;
  const { socket } = useSocket();
  const dispatch = useDispatch();

  const { lessonCelebrationCount, inLesson } = useActiveLessonState();

  const [celebrationTypeSelection, setCelebrationTypeSelection] = useState<CelebrationType | null>(null);
  const [celebrationReasonSelection, setCelebrationReasonSelection] = useState<CelebrationReason | null>(null);
  const maximumCelebrationsSent = !!lessonCelebrationCount && lessonCelebrationCount >= MAXIMUM_NUMBER_CELEBRATIONS;

  const sendCelebrationDisabled = !celebrationReasonSelection || !celebrationTypeSelection || maximumCelebrationsSent;

  if (!inLesson) {
    return null;
  }

  const onSubmit = () => {
    if (celebrationTypeSelection && celebrationReasonSelection) {
      const lessonCelebrationMessage: LessonCelebrationPromptMessage = {
        lessonId: inLesson.lessonId,
        celebrationType: celebrationTypeSelection,
        celebrationReason: celebrationReasonSelection,
        teacherAccountId: inLesson.teacherId,
      };
      socket.emit(EventType.LessonCelebration, lessonCelebrationMessage);
    } else {
      dispatch(error(`There was an error while trying to send the celebration!`));
    }

    setCelebrationTypeSelection(null);
    setCelebrationReasonSelection(null);
    onHide();
  };

  const onClose = () => {
    onHide();
  };

  const celebrationTypeDisplay = (type: CelebrationType | null): string => {
    switch (type) {
      case CelebrationType.Confetti:
        return '🎉';
      case CelebrationType.Star:
        return '🌟';
      case CelebrationType.Thumb:
        return '👍';
      default:
        return '';
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCelebrationReasonSelection((event.target as HTMLInputElement).value as CelebrationReason);
  };

  return (
    <>
      {show && (
        <FloatingDialog width="350px" height="auto" title="Celebrations" onClose={onClose}>
          <Tooltip title={maximumCelebrationsSent ? 'Maximum number of celebration messages sent!' : undefined}>
            <span>
              <Stack height={'100%'} alignItems={'space-between'} spacing={4}>
                <ChipGroup
                  label="Celebration Type"
                  items={celebrationTyps}
                  onChange={(val) => setCelebrationTypeSelection(val as CelebrationType)}
                  value={celebrationTypeSelection ?? ''}
                  showBorderDecoration={false}
                  requiredField
                  removePadding
                  disabled={maximumCelebrationsSent}
                />

                <FormControl variant="standard" disabled={maximumCelebrationsSent}>
                  <FormLabel>
                    <HootTypography isPII={false} variant="labelsmall" mb={1}>
                      Reason for celebration <span style={{ color: hootTokens.palette.error[80] }}> *</span>
                    </HootTypography>
                  </FormLabel>

                  <RadioGroup value={celebrationReasonSelection ?? ''} onChange={handleRadioChange}>
                    {celebrationReasons.map((option) => (
                      <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
                    ))}
                  </RadioGroup>
                </FormControl>

                <Tooltip
                  title={
                    sendCelebrationDisabled
                      ? maximumCelebrationsSent
                        ? 'Maximum number of celebration messages sent!'
                        : 'Cannot send celebration until celebration type and reason selected'
                      : 'Send Celebration'
                  }
                >
                  <div style={{ display: 'flex', cursor: true ? 'not-allowed' : 'default' }}>
                    <Button variant="contained" type="button" onClick={onSubmit} disabled={sendCelebrationDisabled} sx={{ width: '100%' }}>
                      {`${celebrationTypeDisplay(celebrationTypeSelection)} Send Celebration`}
                    </Button>
                  </div>
                </Tooltip>
              </Stack>
            </span>
          </Tooltip>
        </FloatingDialog>
      )}
    </>
  );
};

export default CelebrationsDialog;
