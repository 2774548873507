import { SvgIcon, SvgIconProps } from '@mui/material';

const HootWheel = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9149 2.00009C12.6793 1.99292 13.1244 2.42697 13.2518 3.05627C13.2794 3.19305 13.2684 3.35686 13.2684 3.51294V4.29391C13.2684 4.74286 13.3224 5.31204 13.1911 5.64737C13.013 6.10404 12.6661 6.38698 12.1129 6.47246C11.8195 6.51769 11.5525 6.41069 11.3865 6.31307C10.6602 5.88508 10.7319 5.34568 10.7319 4.23379C10.7319 3.84993 10.6806 3.24986 10.7705 2.941C10.8874 2.53838 11.1554 2.2389 11.5239 2.08833C11.6408 2.04035 11.7952 2.04752 11.9143 2.00009H11.9149Z"
      fill="#EF4823"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9423 17.5137C12.672 17.5071 13.1177 17.9467 13.2462 18.5424C13.2765 18.6835 13.2682 18.8551 13.2682 19.0211V19.7745C13.2682 20.1964 13.3228 20.7341 13.2186 21.0783C13.0553 21.6155 12.6737 21.899 12.0582 21.9916C11.7846 22.033 11.5215 21.91 11.3649 21.8157C10.6551 21.3883 10.7323 20.8268 10.7323 19.7309C10.7323 19.3062 10.6771 18.7613 10.7819 18.4161C10.8972 18.0366 11.1752 17.7426 11.5298 17.602C11.6269 17.5761 11.7239 17.5507 11.8216 17.5248C11.8618 17.5209 11.9021 17.5176 11.9423 17.5137Z"
      fill="#EF4823"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1311 19.0108C18.5957 19.5564 17.974 19.5642 17.439 19.2093C17.3227 19.1321 17.2147 19.0085 17.1043 18.8981L16.5521 18.3459C16.2347 18.0284 15.794 17.6642 15.6497 17.3343C15.4527 16.8854 15.498 16.44 15.8287 15.9884C16.0042 15.7489 16.2686 15.6358 16.455 15.5875C17.2713 15.3765 17.602 15.8086 18.3882 16.5948C18.6596 16.8663 19.1202 17.2543 19.275 17.5363C19.4771 17.9036 19.4993 18.305 19.3452 18.6719C19.2965 18.7885 19.1822 18.8927 19.1315 19.0104L19.1311 19.0108Z"
      fill="#F78F1F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.14186 8.06043C7.63058 8.58107 7.00464 8.58536 6.49258 8.25503C6.3713 8.17665 6.25586 8.04951 6.13847 7.93212L5.60574 7.39939C5.3074 7.10105 4.88854 6.75941 4.7189 6.44235C4.45448 5.94706 4.5239 5.47673 4.89361 4.97598C5.0578 4.75329 5.3308 4.65423 5.50824 4.61016C6.31241 4.41049 6.65482 4.8621 7.42974 5.63702C7.73003 5.93731 8.15434 6.28362 8.32438 6.60186C8.51118 6.95168 8.52249 7.3561 8.37118 7.70631C8.32087 7.79328 8.27017 7.87986 8.21947 7.96722C8.19373 7.99842 8.1676 8.02923 8.14186 8.06043Z"
      fill="#0CB2AD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.00009 12.0851C1.99292 11.3207 2.42697 10.8756 3.05627 10.7482C3.19305 10.7206 3.35686 10.7316 3.51294 10.7316H4.29391C4.74286 10.7316 5.31204 10.6776 5.64737 10.8089C6.10404 10.987 6.38698 11.3339 6.47246 11.8871C6.51769 12.1805 6.41069 12.4475 6.31307 12.6135C5.88508 13.3398 5.34568 13.2681 4.23379 13.2681C3.84993 13.2681 3.24986 13.3194 2.941 13.2295C2.53838 13.1126 2.2389 12.8446 2.08833 12.4761C2.04035 12.3592 2.04752 12.2048 2.00009 12.0857V12.0851Z"
      fill="#6CB944"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5137 12.0574C17.5071 11.3277 17.9467 10.8821 18.5424 10.7536C18.6835 10.7233 18.8551 10.7315 19.0211 10.7315H19.7745C20.1964 10.7315 20.7341 10.6769 21.0783 10.7812C21.6155 10.9444 21.899 11.3261 21.9916 11.9416C22.033 12.2152 21.91 12.4782 21.8157 12.6349C21.3883 13.3447 20.8268 13.2675 19.7309 13.2675C19.3062 13.2675 18.7613 13.3226 18.4161 13.2178C18.0366 13.1026 17.7426 12.8246 17.602 12.47C17.5761 12.3729 17.5507 12.2758 17.5248 12.1782C17.5209 12.1379 17.5176 12.0977 17.5137 12.0574Z"
      fill="#6CB944"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0108 4.86887C19.5564 5.40433 19.5642 6.02598 19.2093 6.56105C19.1321 6.67727 19.0085 6.78529 18.8981 6.89566L18.3459 7.44789C18.0284 7.76534 17.6642 8.20604 17.3343 8.35033C16.8854 8.54728 16.44 8.50204 15.9884 8.17133C15.7489 7.99583 15.6358 7.73141 15.5875 7.545C15.3765 6.72874 15.8086 6.39803 16.5948 5.61181C16.8663 5.34037 17.2543 4.87979 17.5363 4.72496C17.9036 4.52295 18.3049 4.50072 18.6719 4.65476C18.7885 4.70351 18.8927 4.81778 19.0104 4.86848L19.0108 4.86887Z"
      fill="#0CB2AD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.06067 15.8581C8.58131 16.3694 8.5856 16.9954 8.25528 17.5074C8.17689 17.6287 8.04975 17.7441 7.93236 17.8615L7.39963 18.3943C7.10129 18.6926 6.75966 19.1115 6.44259 19.2811C5.9473 19.5455 5.47697 19.4761 4.97622 19.1064C4.75354 18.9422 4.65448 18.6692 4.61041 18.4918C4.41073 17.6876 4.86234 17.3452 5.63726 16.5703C5.93755 16.27 6.28387 15.8457 6.6021 15.6756C6.95192 15.4888 7.35635 15.4775 7.70656 15.6288C7.79353 15.6791 7.88011 15.7298 7.96746 15.7805C7.99866 15.8063 8.02947 15.8324 8.06067 15.8581Z"
      fill="#F78F1F"
    />
  </SvgIcon>
);

export default HootWheel;
