import { Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useParams } from 'react-router-dom';
import useGetActiveReviewTasks from '@hoot/hooks/api/lesson-reviews/useGetActiveReviewTasks';
import Badge from '@hoot/ui/components/v2/core/Badge';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Bullet from '@hoot/ui/components/v2/icons/Bullet';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface IReviewTaskProps {
  onStartLessonReview: (lessonId: string) => void;
}

const LessonReviewTasks = (props: IReviewTaskProps) => {
  const { studentProfileId } = useParams() as { studentProfileId: string };
  const { onStartLessonReview } = props;

  const { data: taskData, isFetching } = useGetActiveReviewTasks(studentProfileId);

  const theme = useTheme();
  const isUnder900px = useMediaQuery(theme.breakpoints.down('md'));

  const dataHasLength = !!taskData?.length;

  const renderCardTitle = () => (
    <Stack direction="row" sx={{ gap: '16px', alignItems: 'center' }}>
      <HootTypography isPII={false} variant="titlemedium">
        Actions Required
      </HootTypography>
      {dataHasLength && <Badge badgeContent={taskData.length} color="error.80" sx={{ width: 24, height: 24, color: '#FFF' }} />}
    </Stack>
  );

  if (!dataHasLength) return null;

  return (
    <Card title={renderCardTitle()} isLoading={isFetching}>
      <Grid container spacing={2}>
        {taskData?.map((td, idx) => (
          <Grid key={`${idx}-${td.lessonId}`} size={6}>
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '16px',
                backgroundColor: hootTokens.palette.error[195],
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column',
                  gap: '16px',
                  alignItems: 'flex-start',
                },
              }}
            >
              <Stack gap={1}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Bullet sx={{ height: '12px', width: '12px' }} color="error.100" />
                  <HootTypography isPII={false} variant="tableheadingactive">
                    Post-Lesson Review Required
                  </HootTypography>
                </div>
                <HootTypography isPII={false} variant="bodysmall">
                  Lesson Number: {td.lessonNumber}
                </HootTypography>
                <HootTypography isPII={false} variant="bodysmall">
                  {td.lessonDate}
                </HootTypography>
              </Stack>
              <Button
                onClick={() => onStartLessonReview(td.lessonId)}
                variant="contained"
                fullWidth={isUnder900px}
                size={isUnder900px ? 'small' : 'medium'}
              >
                Start
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default LessonReviewTasks;
