import { Box, Stack } from '@mui/material';
import React, { useRef } from 'react';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import { LessonWhiteboardTemplateResponse } from '@hoot/hooks/api/whiteboard/useGetLessonWhiteboardTemplates';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { LibraryTab, useLibraries } from '@hoot/redux/reducers/librarySlice';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import StudentInLessonLibrary from '@hoot/ui/pages/v2/student/lesson/library/StudentInLessonLibrary';
import StudentInLessonRightPane from '@hoot/ui/pages/v2/student/lesson/library/StudentInLessonRightPane';
import StudentInLessonWhiteboardTemplates from '@hoot/ui/pages/v2/student/lesson/library/StudentInLessonWhiteboardTemplates';
import useStudentLessonController from '@hoot/ui/pages/v2/student/lesson/useStudentLessonController';

// When the user interacts with the buttons to scroll up and down, we scroll up/down by a percentage of the view height
// rather than scrolling the entire page height.
const scrollHeightRatio = 0.9;

// If there are this many books or whiteboard templates, then show scroll buttons on the right-hand-side.
const showScrollButtonsOnCellCount = 9;

const StudentInLessonLibraryPage = () => {
  const { results, query, previewBook, selectedTab, lessonWhiteboardTemplates } = useLibraries().inLessonLibrary;
  const { results: whiteboardTemplates, previewWhiteboardTemplate } = lessonWhiteboardTemplates;

  const { inLesson } = useActiveLessonState();
  const lessonController = useStudentLessonController({ inLesson: inLesson! });

  const libraryContentRef = useRef<HTMLDivElement>(null);

  const onPageUp = () => {
    if (libraryContentRef.current) {
      const scrollOffset = libraryContentRef.current.scrollTop - libraryContentRef.current.offsetHeight * scrollHeightRatio;
      libraryContentRef.current.scrollTo({ top: scrollOffset, behavior: 'smooth' });
    }
  };

  const onPageDown = () => {
    if (libraryContentRef.current) {
      const scrollOffset = libraryContentRef.current.scrollTop + libraryContentRef.current.offsetHeight * scrollHeightRatio;
      libraryContentRef.current.scrollTo({ top: scrollOffset, behavior: 'smooth' });
    }
  };

  const onBookSelected = (book: BookSearch) => {
    lessonController.selectBook(book.id);
  };

  const onWhiteboardTemplateSelected = (wt: LessonWhiteboardTemplateResponse) => {
    lessonController.selectWhiteboardTemplate(wt.whiteboardTemplate.templateType);
  };

  return (
    <Stack direction="row" height="100%" sx={{ overflowY: 'hidden' }}>
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
        }}
        ref={libraryContentRef}
      >
        <PageLayout>
          {selectedTab === LibraryTab.Whiteboard ? (
            <StudentInLessonWhiteboardTemplates
              whiteboardTemplates={whiteboardTemplates}
              selectedWhiteboardTemplate={previewWhiteboardTemplate}
              onWhiteboardTemplateSelected={onWhiteboardTemplateSelected}
            />
          ) : (
            <StudentInLessonLibrary query={query} books={results.books} selectedBook={previewBook} onBookSelected={onBookSelected} />
          )}
        </PageLayout>
      </Box>
      {/* If there are enough cells being displayed in the UI, then show the scroll buttons in the right pane. */}
      {(selectedTab === LibraryTab.Whiteboard && whiteboardTemplates.length > showScrollButtonsOnCellCount) ||
        ((results?.books.length ?? 0) > showScrollButtonsOnCellCount && <StudentInLessonRightPane onPageUp={onPageUp} onPageDown={onPageDown} />)}
    </Stack>
  );
};

export default StudentInLessonLibraryPage;
