import { SvgIcon, SvgIconProps } from '@mui/material';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { getColors } from '@hoot/ui/theme/v2/util';

interface IBulletProps extends Omit<SvgIconProps, 'color'> {
  color?: ColorPaletteOption;
}

const Bullet = (props: IBulletProps) => {
  const { color = 'secondary.60', ...svgIconProps } = props;
  const colors = getColors(color);

  return (
    <SvgIcon width="8" height="8" viewBox="0 0 8 8" fill="none" {...svgIconProps}>
      <rect y="0.000244141" width="8" height="8" rx="4" fill={colors.base ?? '#BF3A1C'} />
    </SvgIcon>
  );
};

export default Bullet;
