import axios, { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { config } from '../../../config';

export interface DeleteTeacherAvailabilityExceptionsRequest {
  teacherAvailabilityExceptionId: string;
}

export interface RevertTeacherAvailabilityExceptionDraftRequest {
  teacherAvailabilityExceptionDraftId: string;
}

async function deleteTeacherAvailabilityException(params: DeleteTeacherAvailabilityExceptionsRequest): Promise<AxiosResponse<void>> {
  return await axios.delete<void>(`${config.apiUrl}/public/availability-exception`, { params });
}

export default function useDeleteTeacherAvailabilityException() {
  return useMutation((data: DeleteTeacherAvailabilityExceptionsRequest) => {
    return deleteTeacherAvailabilityException(data);
  });
}

async function revertTeacherAvailabilityException(params: RevertTeacherAvailabilityExceptionDraftRequest): Promise<AxiosResponse<void>> {
  return await axios.delete<void>(`${config.apiUrl}/public/availability-exception/revert`, { params });
}

export function useRevertTeacherAvailabilityExceptionDraft() {
  return useMutation((data: RevertTeacherAvailabilityExceptionDraftRequest) => {
    return revertTeacherAvailabilityException(data);
  });
}
