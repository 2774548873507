import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface DeferAssessmentActionRequest {
  studentProfileId: string;
  taskId: string;
  lessonId: string;
  notes: string;
}

async function deferAssessmentAction(request: DeferAssessmentActionRequest): Promise<void> {
  const { studentProfileId, taskId, notes, lessonId } = request;
  await axios.post<void>(`${config.apiUrl}/my-students/${studentProfileId}/assessments-actions/${taskId}/defer`, {
    notes: notes,
    lessonId: lessonId,
  });
}

export function useDeferAssessmentAction(options?: UseMutationOptions<void, AxiosError, DeferAssessmentActionRequest>) {
  return useMutation<void, AxiosError, DeferAssessmentActionRequest>((request) => deferAssessmentAction(request), options);
}
