import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { Socket } from 'socket.io-client';
import { LessonState } from '@hoot/events/interfaces/lesson-state';
import { ActiveLessonPage } from '@hoot/models/api/enums/active-lesson-page-enum';
import { LibraryContext, resetLibrary } from '@hoot/redux/reducers/librarySlice';
import { ReaderContext, resetReader } from '@hoot/redux/reducers/readingSlice';
import { CelebrationType } from '@hoot/ui/components/v2/dialogs/CelebrationsDialog';
import { EventType } from '../../events/eventType';
import { ScheduledLesson } from '../../events/interfaces/scheduled-lesson';
import { JoinLessonMessage } from '../../events/messages/join-lesson.message';
import { LeaveLessonMessage } from '../../events/messages/leave-lesson.message';
import { LessonEndedMessage } from '../../events/messages/lesson-ended-message';
import { AppDispatch, RootState } from '../store';

export interface ActiveLessonState {
  inLesson: ScheduledLesson | null;
  activePage: ActiveLessonPage | null;
  lessonCelebrationCount: number;
  sendCelebration: CelebrationType | null;
  activeWhiteboardId: string | null;
}

const initialState: ActiveLessonState = {
  inLesson: null,
  activePage: null,
  lessonCelebrationCount: 0,
  sendCelebration: null,
  activeWhiteboardId: null,
};

export const activeLessonSlice = createSlice({
  name: 'activeLesson',
  initialState,
  reducers: {
    resetActiveLesson: () => ({ ...initialState }),
    updateInLesson: (state: ActiveLessonState, action: PayloadAction<ScheduledLesson>) => {
      state.inLesson = action.payload;
    },
    handleSyncLessonState: (state, action: PayloadAction<LessonState>) => {
      const { activePage, celebrationCount, activeWhiteboardId } = action.payload;
      state.activePage = activePage;
      state.lessonCelebrationCount = celebrationCount;
      state.activeWhiteboardId = activeWhiteboardId;
    },
    setActivePage: (state, action: PayloadAction<ActiveLessonPage>) => {
      state.activePage = action.payload;
    },
    updateLessonCelebrationCount: (state, action: PayloadAction<number>) => {
      state.lessonCelebrationCount = action.payload;
    },
    updateSendCelebration: (state, action: PayloadAction<CelebrationType | null>) => {
      state.sendCelebration = action.payload;
    },
  },
});

export const joinLesson = createAsyncThunk<void, { socket: Socket }, { dispatch: AppDispatch; state: RootState }>(
  'activeLesson/joinLesson',
  async ({ socket }, thunkApi) => {
    const { activeLesson } = thunkApi.getState();

    if (!activeLesson.inLesson) {
      throw new Error('Can not join lesson. No active lesson exists in state.');
    }

    const joinLessonMessage: JoinLessonMessage = {
      lessonId: activeLesson.inLesson.lessonId,
    };
    socket.emit(EventType.JoinLesson, joinLessonMessage);
  },
);

export const leaveLesson = createAsyncThunk<void, { socket: Socket }, { dispatch: AppDispatch; state: RootState }>(
  'activeLesson/leaveLesson',
  async ({ socket }, thunkApi) => {
    const { activeLesson, profile } = thunkApi.getState();

    if (activeLesson.inLesson) {
      const leaveLessonMessage: LeaveLessonMessage = {
        lessonId: activeLesson.inLesson.lessonId,
        profileId: profile.profile?.id,
        profileName: profile.profile?.name,
        forcedDisconnect: true,
      };
      socket.emit(EventType.LeaveLesson, leaveLessonMessage);
      console.log(`Emitting LeaveLesson via leaveLesson thunk`);
    }
  },
);

export const handleLessonEndedMessage = createAsyncThunk<
  void,
  { lessonEndedMessage: LessonEndedMessage | undefined },
  { dispatch: AppDispatch; state: RootState }
>('activeLesson/lessonEnded', async (message, thunkApi) => {
  const { lessonEndedMessage } = message;
  const { wasBooted } = lessonEndedMessage ?? {};

  if (wasBooted) {
    console.log('User was booted from lesson.');
  }
  // Reset lesson state.
  thunkApi.dispatch(resetLesson());
});

export const resetLesson = createAsyncThunk<void, void, { dispatch: AppDispatch; state: RootState }>(
  'activeLesson/lessonEnded',
  async (_, thunkApi) => {
    thunkApi.dispatch(resetActiveLesson());
    thunkApi.dispatch(resetLibrary(LibraryContext.InLessonLibrary));
    thunkApi.dispatch(resetReader(ReaderContext.InLessonReader));
  },
);

export const useActiveLessonState = () => {
  return useSelector((state: RootState) => state.activeLesson);
};

export const { resetActiveLesson, updateInLesson, setActivePage, updateLessonCelebrationCount, updateSendCelebration, handleSyncLessonState } =
  activeLessonSlice.actions;

export default activeLessonSlice.reducer;
