import { Box, Grow, Stack, StackProps } from '@mui/material';
import React, { ReactNode } from 'react';

export interface GrowListProps<T> {
  items: T[];
  getKey: (item: T, index: number) => string;
  renderItem: (item: T) => ReactNode;
  renderEnsuingItems?: ReactNode;
  itemRenderTimeoutMillis?: number;
  StackProps?: StackProps;
  observerRef?: (node: any) => void;
  /**
   * When set, each item animation is cascaded. The default value is true.
   */
  cascadeAnimate?: boolean;
}

const DEFAULT_RENDER_TIMEOUT_MILLIS = 500;

// If the list of items is huge, we'll wait forever for the items near the end of the list to cascade in.
// Don't allow an animation to last longer than this.
const MAX_TIMEOUT_MS = 1000;

const GrowList = <T,>(props: GrowListProps<T>) => {
  const {
    items,
    renderItem,
    getKey,
    itemRenderTimeoutMillis = DEFAULT_RENDER_TIMEOUT_MILLIS,
    StackProps,
    observerRef,
    cascadeAnimate = true,
    renderEnsuingItems,
  } = props;
  return (
    <Stack {...StackProps}>
      {items.map((x, i) => {
        const itemTimeoutMillis = itemRenderTimeoutMillis * i;
        const timeoutMillis = i > 0 ? (itemTimeoutMillis < MAX_TIMEOUT_MS ? itemTimeoutMillis : MAX_TIMEOUT_MS) : undefined;
        return (
          <Grow key={getKey(x, i)} in timeout={cascadeAnimate ? timeoutMillis : undefined}>
            <Box ref={!!observerRef && i === items.length - 1 ? observerRef : null}>{renderItem(x)}</Box>
          </Grow>
        );
      })}
      {renderEnsuingItems}
    </Stack>
  );
};

export default GrowList;
