import React, { useState } from 'react';
import { FEATURE_FLAG_SYNC_PERIOD } from '../../constants/constants';
import useFetchFeatureFlags from '../../hooks/api/useFetchFeatureFlag';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { FeatureFlag } from '../../models/featureFlag';
import { TechnicalDifficulties } from '../../routes/TechnicalDifficulties';

interface Props {
  children: React.ReactNode;
}

export interface FeatureFlagContextProps {
  isFeatureEnabled: (featureName: string) => boolean;
}

const FeatureFlagContext = React.createContext<FeatureFlagContextProps>(undefined!);

export const FeatureFlagProvider = (props: Props) => {
  const { children } = props;

  const [technicalDifficulties, setTechnicalDifficulties] = useState<boolean>(false);
  const [isFinishedLoading, setIsFinishedLoading] = useState(false);
  const [features, setFeatures] = useLocalStorage<FeatureFlag[]>('FEATURE_FLAG', []);

  useFetchFeatureFlags({
    refetchInterval: FEATURE_FLAG_SYNC_PERIOD,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    onSuccess: (featureFlagList) => {
      setTechnicalDifficulties(false);
      setFeatures(featureFlagList.features);
      setIsFinishedLoading(true);
    },
    onError: () => {
      setTechnicalDifficulties(true);
    },
  });

  const isFeatureEnabled = (featureName: string) => {
    const feature = features.find((f) => f.name === featureName);

    if (!feature) {
      return false;
    }

    return feature.isEnabled;
  };

  return (
    <FeatureFlagContext.Provider value={{ isFeatureEnabled }}>
      {isFinishedLoading ? children : null}
      {technicalDifficulties ? <TechnicalDifficulties /> : null}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const context = React.useContext(FeatureFlagContext);

  if (context === undefined) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider');
  }

  return context;
};
