import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Stack } from '@mui/system';
import { DateTime } from 'luxon';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FULL_DATE } from '@hoot/constants/constants';
import { googleAnalytics } from '@hoot/telemetry/google-analytics';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { routesDictionary } from '../../../../../../../routes/routesDictionary';
import { Icon } from '../../../../../../components/v2/core/Icon';
import { useAuth } from '../../../../../../context/AuthContext';
import theme from '../../../../../../theme/v2';
import { FlfWizardStep } from '../FreeLessonRegistrationWizard';

const FreeLessonConfirmation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { getUser } = useAuth();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const studentProfileId = searchParams.get('studentProfileId') ?? '?';
  const lessonDate = DateTime.fromMillis(parseInt(searchParams.get('lessonDate') ?? '?'));

  const studentName = getUser().profiles.find((p) => p.id === studentProfileId)?.name ?? '';

  const handleCreateAnotherStudent = () => {
    setSearchParams({
      step: `${FlfWizardStep.CreateStudent}`,
    });
    googleAnalytics.completedFLFStep6();
  };

  const handleTakeMeToTheApp = () => {
    googleAnalytics.completedFLFStep6();
    navigate(routesDictionary.home.url);
  };

  return (
    <Stack>
      <HootTypography isPII={false} variant="titlelarge">
        Lesson Booked!
      </HootTypography>
      <HootTypography isPII={false} sx={{ marginTop: '24px' }} variant="bodylarge">
        You've successfully booked {studentName} for an evidence-based online reading lesson! We look forward to seeing you on:
      </HootTypography>

      <HootTypography isPII={false} sx={{ marginTop: '16px' }} variant="titlesmall" mt={3}>
        {lessonDate.toFormat(`${FULL_DATE} 'at' h:mm a ZZZZ.`)}
      </HootTypography>

      <Grid sx={{ marginTop: '16px' }} container size={12}>
        <Button fullWidth={!isDesktop} onClick={handleTakeMeToTheApp} variant="contained">
          Take Me To The App
        </Button>
        <Button
          sx={{ marginTop: isDesktop ? undefined : '16px', marginLeft: isDesktop ? '16px' : undefined }}
          fullWidth={!isDesktop}
          startIcon={<Icon name="add" />}
          onClick={handleCreateAnotherStudent}
          variant="outlined"
        >
          Create Another Student
        </Button>
      </Grid>
    </Stack>
  );
};

export default FreeLessonConfirmation;
