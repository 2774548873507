import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { EnrolmentStatus } from '@hoot/models/api/enums/Enrolments';
import { LocationTypeEnum } from '@hoot/models/api/enums/location-type-enum';
import { QueryKey } from '../queryKeys';

export interface Location {
  id: string;
  name: string;
  type: string;
  shortId: string;
  districtName?: string;
}

export interface Enrolment {
  id: string;
  name: string;
  locationName: string;
  locationId: string;
  locationType: LocationTypeEnum;
  shortId: string;
  status: EnrolmentStatus;
  friendlyId: string;
}

export enum DistrictRepresentativeStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface DistrictRepresentativeDetailsResponse {
  districtName: string;
  status: DistrictRepresentativeStatus;
  firstName: string;
  lastName: string;
  email: string | null;
  phoneNumber: string | null;
  locations: Location[];
  enrolments: Enrolment[];
}

export default function useGetDistrictRepresentativeDetails(
  districtRepId: string,
  options?: Omit<UseQueryOptions<DistrictRepresentativeDetailsResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetDistrictRepresentativeDetails, districtRepId],
    async () => {
      return (await axios.get<DistrictRepresentativeDetailsResponse>(`${config.apiUrl}/public/district-representative/${districtRepId}/details`))
        .data;
    },
    options,
  );
}
