import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { UseGetOptions } from '@hoot/models/api/axios-options';

export interface FilterLevelPrioritizedSkillsResponse {
  categorizedPrioritizedSkills: CategorizedFilterLevelPrioritizedSkills[];
}

interface CategorizedFilterLevelPrioritizedSkills {
  parentCategory: string;
  listItems: {
    label: string;
    value: string;
  }[];
}

export interface FilterLevelPrioritizedSkillsRequest {
  instructionalUnits: string[];
}

async function getPrioritizedSkills(requestPayload: FilterLevelPrioritizedSkillsRequest): Promise<FilterLevelPrioritizedSkillsResponse> {
  const { data } = await axios.get<FilterLevelPrioritizedSkillsResponse>(`${config.apiUrl}/library/filter-level-prioritized-skills`, {
    params: requestPayload,
  });
  return data;
}

export default function useGetFilterLevelPrioritizedSkills(
  requestPayload: FilterLevelPrioritizedSkillsRequest,
  options?: UseGetOptions<FilterLevelPrioritizedSkillsResponse>,
) {
  return useQuery([QueryKey.GetFilterLevelPrioritizedSkills, ...requestPayload.instructionalUnits], () => getPrioritizedSkills(requestPayload), {
    enabled: requestPayload.instructionalUnits.length > 0,
    ...options,
  });
}
