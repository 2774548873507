import { Stack } from '@mui/material';
import useStopWatch from '@hoot/hooks/useStopWatch';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { FloatingDialog } from '@hoot/ui/components/v2/core/FloatingDialog';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';

export interface TimerDialogProps {
  show: boolean;
  onHide: () => void;
}

const TimerDialog = (props: TimerDialogProps) => {
  const { show, onHide } = props;

  const { secondsElapsedDisplay, startOrPauseTimer, resetTimer, isRunning } = useStopWatch();

  return (
    <>
      {show && (
        <FloatingDialog width="308px" title="Timer" onClose={onHide}>
          <Stack direction={'column'}>
            <HootTypography isPII={false} variant="headlinelarge" my={1} textAlign="center">
              {secondsElapsedDisplay}
            </HootTypography>
            <Stack direction="row" gap={2} pt={2}>
              <Button sx={{ flex: 1 }} variant="outlined" onClick={resetTimer}>
                Reset
              </Button>
              <Button sx={{ flex: 2 }} variant="contained" onClick={startOrPauseTimer}>
                {isRunning ? 'Pause' : 'Start'}
              </Button>
            </Stack>
          </Stack>
        </FloatingDialog>
      )}
    </>
  );
};

export default TimerDialog;
