import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export enum AssessmentAction {
  Create = 'CREATE',
  Resume = 'RESUME',
}

export interface AssessmentActionResponse {
  header: string;
  who: string;
  when: number;
  action: AssessmentAction;
  taskId?: string;
  lessonId?: string;
  assessmentId?: string;
}

export interface AssessmentActionsResponse {
  actions: AssessmentActionResponse[];
}

export default function useGetAssessmentActions(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<AssessmentActionsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetStudentAssessmentActions, studentProfileId],
    async () => {
      return (await axios.get<AssessmentActionsResponse>(`${config.apiUrl}/my-students/${studentProfileId}/assessments-actions`)).data;
    },
    options,
  );
}
