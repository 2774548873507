import { useDaily } from '@daily-co/daily-react';
import { useCallback } from 'react';

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export function useShallowMergeMeetingSessionData() {
  const dailyCallObj = useDaily();

  const mergeMeetingSessionData = useCallback(
    <T>(data: RecursivePartial<T>) => {
      if (dailyCallObj) {
        dailyCallObj.setMeetingSessionData(data, 'shallow-merge');
      }
    },
    [dailyCallObj],
  );

  return { mergeMeetingSessionData };
}
