import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import useDistrictRepGetProductSnippet from '@hoot/hooks/api/district-rep/useDistrictRepresentativeProductSnippet';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import Page from '@hoot/ui/components/v2/core/Page';

const HFSProductsHeader = () => {
  const { productId } = useParams();
  const { data: enrolmentSnippet, isLoading } = useDistrictRepGetProductSnippet(productId ?? '', {
    enabled: !!productId,
    retry: false,
    onError: (err) => {
      console.error(err);
      // If we don't have permission to view this product, then redirect out of this page.
      if (err.response?.status === 403) {
        navigate(routesDictionary.enrolments.url, { replace: true });
      }
    },
  });
  const navigate = useNavigate();
  const { state } = useLocation();

  const pageTitle = enrolmentSnippet?.friendlyId ? `${enrolmentSnippet.friendlyId} | Hoot Reading` : 'Product | Hoot Reading';

  return (
    <Page pageTitle={pageTitle} noPadding noMaxWidth>
      <SubHeader
        title={{
          label: enrolmentSnippet?.friendlyId ?? 'Product',
          isPII: false,
          isLoading,
        }}
        backButton={{
          show: true,
          navigateBackPath: state?.previousPath ? state.previousPath : undefined,
        }}
        breadcrumbs={['Enrolment Details', 'Products']}
      />
      <Outlet />
    </Page>
  );
};

export default HFSProductsHeader;
