import { Fade } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Card from '@hoot/ui/components/v2/core/Card';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';

interface StudentDetailsCardProps {
  notes: string;
  isLoading: boolean;
}

const LessonReviewNotesCard = (props: StudentDetailsCardProps) => {
  const { notes, isLoading } = props;

  return (
    <Card title="Notes" isLoading={isLoading}>
      <Fade in>
        <Grid container spacing={2}>
          <Grid size={12}>
            <ReadOnlyTextField label="Lesson Notes" body={<span style={{ fontFamily: 'inherit', whiteSpace: 'pre-wrap' }}>{notes}</span>} />
          </Grid>
        </Grid>
      </Fade>
    </Card>
  );
};

export default LessonReviewNotesCard;
