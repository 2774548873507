import { DailyEventObjectNetworkQualityEvent } from '@daily-co/daily-js';
import { useDailyEvent, useNetwork } from '@daily-co/daily-react';
import { useCallback, useRef } from 'react';
import { HIGH_THRESHOLD, MEDIUM_THRESHOLD } from '@hoot/constants/daily/networkStrength';
import { DailyMediaState } from '@hoot/hooks/daily/useDailyMediaState';
import { useShallowMergeMeetingSessionData } from '@hoot/hooks/daily/useShallowMergeMeetingSessionData';
import useProfile from '@hoot/hooks/useProfile';
import { NetworkFull, NetworkHalf, NetworkLow } from '../../icons/NetworkIcons';

// TODO:SC-9884 - This is used by both Blades.. create a shared directory for these?
const NetworkVisualizer = () => {
  const { quality } = useNetwork();
  const { mergeMeetingSessionData } = useShallowMergeMeetingSessionData();
  const { isTeacher, isStudent } = useProfile();

  const hasNetworkEventOccurred = useRef<boolean>(false);

  useDailyEvent(
    'network-quality-change',
    useCallback(
      (e: DailyEventObjectNetworkQualityEvent) => {
        // If the connection sucks
        if (e.quality < MEDIUM_THRESHOLD || e.threshold !== 'good') {
          console.log(`[Low Network Quality Change]: Threshold: ${e.threshold} | Quality: ${e.quality}`);

          // If we haven't recorded a network event in this current lesson.
          if (!hasNetworkEventOccurred.current) {
            hasNetworkEventOccurred.current = true;

            // Set either the Teacher or Student network event to session state.
            if (isTeacher) {
              mergeMeetingSessionData<DailyMediaState>({ teacherDailyNetworkEvent: e });
            }
            if (isStudent) {
              mergeMeetingSessionData<DailyMediaState>({ studentDailyNetworkEvent: e });
            }
          } else return; // Event has already occurred this lesson, bail.
        }
      },
      [isStudent, isTeacher, mergeMeetingSessionData],
    ),
  );

  const NetworkIcon = useCallback(() => {
    if (!quality) return null;
    else if (quality < MEDIUM_THRESHOLD) return <NetworkLow />;
    else if (quality >= MEDIUM_THRESHOLD && quality < HIGH_THRESHOLD) return <NetworkHalf />;
    else if (quality >= HIGH_THRESHOLD) return <NetworkFull />;
    else {
      return null;
    }
  }, [quality]);

  return <NetworkIcon />;
};

export default NetworkVisualizer;
