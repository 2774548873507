import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { HootAssessmentInstructionIndicator } from '@hoot/models/api/enums/hoot-reading-assessment';
import { QueryKey } from '../queryKeys';

export interface GetPMASubmissionDetailsResponse {
  submissionId: string;
  unit: {
    unitId: string;
    unitName: string;
  };
  score: string;
  instructionIndicator: HootAssessmentInstructionIndicator;
}

async function getPMASubmissionDetails(assessmentId: string) {
  const { data } = await axios.get<GetPMASubmissionDetailsResponse[]>(
    `${config.apiUrl}/public/progress-monitoring-assessment/${assessmentId}/details`,
  );
  return data;
}

export function useGetPMASubmissionDetails(
  assessmentId: string,
  options?: Omit<UseQueryOptions<GetPMASubmissionDetailsResponse[]>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetStudentProgressMonitoringAssessmentDetails, assessmentId], () => getPMASubmissionDetails(assessmentId), options);
}
