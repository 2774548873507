import { Box, CardProps, Paper, Skeleton, Stack, TablePagination, TablePaginationProps } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationMessage, NotificationType } from '@hoot/events/messages/notification-message';
import { useDeleteNotificationBookmark } from '@hoot/hooks/api/notification/useDeleteNotificationBookmark';
import useGetNotifications, { GetNotificationsQuery } from '@hoot/hooks/api/notification/useGetNotifications';
import { usePutNotificationBookmark } from '@hoot/hooks/api/notification/usePutNotificationBookmark';
import { DEFAULT_PAGE_SIZE } from '@hoot/models/api/pagination';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import {
  bookmarkNotification,
  removeBookmarkedNotification,
  setPaginatedNotifications,
  useNotifications,
} from '@hoot/redux/reducers/notificationsSlice';
import { useAppDispatch } from '@hoot/redux/store';
import GrowList from '@hoot/ui/components/v2/GrowList';
import { Notification } from '@hoot/ui/components/v2/Notification';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import Card from '@hoot/ui/components/v2/core/Card';
import ChipGroup, { ChipGroupProps } from '@hoot/ui/components/v2/core/ChipGroup';
import SearchTextField, { SearchTextFieldProps } from '@hoot/ui/components/v2/core/SearchTextField';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const DEFAULT_ALL = 'DEFAULT_ALL';

interface NotificationProps {
  sx?: CardProps['sx'];
}

const Notifications = (props: NotificationProps) => {
  const { sx } = props;
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const notifications = useNotifications();

  const putBookmarkRequest = usePutNotificationBookmark();
  const deleteBookmarkRequest = useDeleteNotificationBookmark();
  const [filterSelection, setFilterSelection] = useState<string | NotificationType>(DEFAULT_ALL);

  const filterItems = useMemo<ChipGroupProps['items']>(() => {
    return [
      { value: DEFAULT_ALL, label: 'All' },
      { value: NotificationType.Enrolment, label: 'Enrolment' },
      { value: NotificationType.Assessment, label: 'Assessment' },
      { value: NotificationType.LessonAdded, label: 'Lesson - Added' },
      { value: NotificationType.LessonCancelled, label: 'Lesson - Cancelled' },
      { value: NotificationType.LessonUpdated, label: 'Lesson - Updated' },
      { value: NotificationType.LessonMissed, label: 'Lesson - Missed' },
      { value: NotificationType.LessonDidNotOccur, label: 'Lesson - Did Not Occur' },
    ];
  }, []);

  const [notificationsQuery, setNotificationsQuery] = useState<GetNotificationsQuery>({
    search: undefined,
    type: undefined,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [searchInput, setSearchInput] = useState<string>('');
  const [bookmarkLoadingDictionary, setBookmarkLoadingDictionary] = useState<Set<string>>(new Set());
  const [hasFinishedInitialLoad, setHasFinishedInitialLoad] = useState(false);

  const getNotificationsRequest = useGetNotifications(notificationsQuery, {
    enabled: true,
    retry: false,
    onSuccess: (data) => {
      dispatch(setPaginatedNotifications(data));
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ variant: 'error', message: 'An error occurred while fetching notifications.' }));
    },
    onSettled: () => {
      setHasFinishedInitialLoad(true);
    },
  });

  useEffect(() => {
    setNotificationsQuery((prev) => ({
      ...prev,
      type: filterSelection !== DEFAULT_ALL ? (filterSelection as NotificationType) : undefined,
    }));
  }, [filterSelection]);

  // If no results were returned and no search criteria was provided.
  const hasNoNotifications = useMemo(() => {
    return notifications.data.length === 0 && !notificationsQuery.search && !notificationsQuery.type;
  }, [notifications.data.length, notificationsQuery]);

  const viewState = useMemo<ViewStateEnum>(() => {
    // Only show skeleton items on initial load.
    if (!hasFinishedInitialLoad) {
      return ViewStateEnum.Loading;
    } else if (getNotificationsRequest.isError) {
      return ViewStateEnum.Error;
    } else if (hasNoNotifications) {
      // If no results and no search query, then show the empty state.
      return ViewStateEnum.EmptyState;
    } else if (notifications.data.length === 0) {
      // If no results after applying search criteria, then show the no-results state.
      return ViewStateEnum.NoResults;
    } else {
      return ViewStateEnum.Results;
    }
  }, [hasFinishedInitialLoad, hasNoNotifications, notifications.data.length, getNotificationsRequest.isError]);

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchInput(text);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (text) => {
    setNotificationsQuery((prev) => ({
      ...prev,
      search: text.length > 0 ? text : undefined,
    }));
  };

  const onClearSearchInput = () => {
    setSearchInput('');
    setNotificationsQuery((prev) => ({
      ...prev,
      search: undefined,
    }));
  };

  const onToggleBookmark = (notification: NotificationMessage) => () => {
    setBookmarkLoadingDictionary((prevState) => {
      const newLoadingDictionary = new Set(prevState);
      newLoadingDictionary.add(notification.id);
      return newLoadingDictionary;
    });

    const removeLoadingIndicator = () => {
      setBookmarkLoadingDictionary((prevState) => {
        const newLoadingDictionary = new Set(prevState);
        newLoadingDictionary.delete(notification.id);
        return newLoadingDictionary;
      });
    };

    if (!notification.isBookmarked) {
      putBookmarkRequest.mutate(notification.id, {
        onSuccess: () => {
          dispatch(bookmarkNotification({ notificationId: notification.id }));
          dispatch(createFlashMessage({ message: 'Added to Bookmarks' }));
        },
        onError: (err) => {
          console.error(err);
          dispatch(
            createFlashMessage({
              variant: 'error',
              message: 'An error occurred while bookmarking notification.',
            }),
          );
        },
        onSettled: () => {
          removeLoadingIndicator();
        },
      });
    } else {
      deleteBookmarkRequest.mutate(notification.id, {
        onSuccess: () => {
          dispatch(removeBookmarkedNotification({ notificationId: notification.id }));
          dispatch(createFlashMessage({ message: 'Removed from Bookmarks.' }));
        },
        onError: (err) => {
          console.error(err);
          dispatch(createFlashMessage({ variant: 'error', message: 'An error occurred while removing bookmark.' }));
        },
        onSettled: () => {
          removeLoadingIndicator();
        },
      });
    }
  };

  const onCallToActionClicked = (route: string) => {
    navigate(route);
  };

  const handleChangePage: TablePaginationProps['onPageChange'] = (event, newPage) => {
    setNotificationsQuery((prev) => ({
      ...prev,
      page: newPage + 1,
    }));
  };

  const handleChangeRowsPerPage: TablePaginationProps['onRowsPerPageChange'] = (event) => {
    setNotificationsQuery((prev) => ({
      ...prev,
      pageSize: parseInt(event.target.value, 10),
      page: 1,
    }));
  };

  return (
    <Card title="Notifications" isLoading={getNotificationsRequest.isFetching} sx={{ height: 'max-content', ...(sx ?? {}) }}>
      {!hasFinishedInitialLoad && (
        <Stack>
          <Skeleton variant="rectangular" sx={{ height: '58px' }} />
          <Skeleton variant="rectangular" sx={{ height: '18px', mt: 2 }} />
          <Stack direction="row" gap={1} sx={{ mt: 1 }}>
            <Skeleton variant="rounded" sx={{ width: '40px', height: '40px' }} />
            <Skeleton variant="rounded" sx={{ width: '87px', height: '40px' }} />
            <Skeleton variant="rounded" sx={{ width: '99px', height: '40px' }} />
            <Skeleton variant="rounded" sx={{ width: '67px', height: '40px' }} />
          </Stack>
        </Stack>
      )}
      {/* If we don't have any notifications (without search criteria), then don't bother showing the search nor filters. */}
      {!hasNoNotifications && (
        <>
          <SearchTextField
            label="Search"
            searchInput={searchInput}
            onSearchInputChanged={onSearchInputChanged}
            onSearchInputDebounced={onSearchInputDebounced}
            onClearButtonClicked={onClearSearchInput}
          />
          <Box mt={2}>
            <ChipGroup
              label="Filter notifications by type"
              items={filterItems}
              onChange={(val: any) => setFilterSelection(val as string)}
              value={filterSelection}
              cannotRemoveSelection
            />
          </Box>
        </>
      )}
      <Stack mt={2}>
        <ViewState
          state={viewState}
          loadingContent={<SkeletonItems />}
          NoResultsIllustrationProps={{ title: 'No results found', subtitle: 'Try adjusting your search' }}
          EmptyStateIllustrationProps={{
            title: "You're all caught up!",
            subtitle: 'Check back later for new notifications',
          }}
        >
          <GrowList<NotificationMessage>
            StackProps={{ gap: 2 }}
            items={notifications.data}
            getKey={(x) => x.id}
            renderItem={(notification) => (
              <Notification
                notification={notification}
                isBookmarkToggling={bookmarkLoadingDictionary.has(notification.id)}
                onBookmarked={onToggleBookmark(notification)}
                onCallToActionClicked={onCallToActionClicked}
              />
            )}
          />
        </ViewState>
      </Stack>
      <TablePagination
        component="div"
        count={notifications.count ?? 0}
        page={notificationsQuery.page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={notificationsQuery.pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

const SkeletonItems = () => (
  <Stack gap={2}>
    {[...Array(3)].map((_, index) => (
      <Paper key={`skeleton-${index}`} sx={{ padding: 2 }}>
        <Stack direction="row" justifyContent="space-between" gap={3}>
          <Stack flex={1}>
            <Skeleton variant="text" sx={{ maxWidth: '160px', ...hootTokens.text.bodylarge }} />
            <Skeleton variant="text" sx={{ ...hootTokens.text.bodysmall, mt: 2, width: '100%' }} />
            <Stack direction="row" gap={2} mt={2}>
              <Skeleton variant="rounded" sx={{ maxWidth: '62px', width: '100%' }} />
              <Skeleton variant="rounded" sx={{ maxWidth: '200px', width: '100%' }} />
              <Skeleton variant="rounded" sx={{ maxWidth: '160px', width: '100%' }} />
            </Stack>
            <Skeleton variant="text" sx={{ maxWidth: '150', ...hootTokens.text.bodysmall, mt: 2 }} />
          </Stack>
          <Stack justifyContent="space-between" alignItems="flex-end">
            <Skeleton variant="rounded" width={44} height={44} />
            <Skeleton variant="rounded" width={164} height={60} />
          </Stack>
        </Stack>
      </Paper>
    ))}
  </Stack>
);

export default Notifications;
