import { DailyEventObjectFatalError, DailyFatalErrorType } from '@daily-co/daily-js';
import { DailyAudio, useCPULoad, useDailyEvent, useParticipantIds } from '@daily-co/daily-react';
import { Stack } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EventType } from '@hoot/events/eventType';
import { DailyMediaState } from '@hoot/hooks/daily/useDailyMediaState';
import useProfile from '@hoot/hooks/useProfile';
import { UserProfileType } from '@hoot/models/api/user';
import { LessonErrorMessage, NetworkQualityLogMessage } from '@hoot/models/daily/logMessages';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { RootState } from '@hoot/redux/store';
import PlainIconButton from '@hoot/ui/components/v2/core/PlainIconButton';
import WaitingForUser from '@hoot/ui/components/v2/student-blade/ui-components/WaitingForUser';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { useSocket } from '@hoot/ui/context/SocketContext';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { useAudioVideoSettingsDialog } from '../../../context/AudioVideoSettingsDialogContext';
import DailyErrorDialog, { ErrorDetails } from '../dialogs/DailyErrorDialog';
import HootLogo from '../icons/HootLogo';
import LocalStudentTile from './LocalStudentTile';
import RemoteTeacherTile from './RemoteTeacherTile';

const StudentBlade = () => {
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });
  const { onShowStudentInLesson } = useAudioVideoSettingsDialog();
  const { getUser } = useAuth();
  const { profile } = useProfile();
  const { socket } = useSocket();
  const dispatch = useDispatch();

  const studentNetworkEventLogged = useRef<boolean>(false);
  const errorDetailsRef = useRef<ErrorDetails | null>(null);

  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);

  const [errorDialog, setErrorDialog] = useState<boolean>(false);

  const hasTeacherJoined = !!remoteParticipantIds.length;
  const authUserId = getUser().id;

  // Displays the low network quality snackbar and logs the event to the api.
  useDailyEvent('meeting-session-state-updated', (e) => {
    const { studentDailyNetworkEvent } = e.meetingSessionState.data as DailyMediaState;

    if (studentDailyNetworkEvent && !studentNetworkEventLogged.current) {
      dispatch(
        createFlashMessage({
          variant: 'light',
          message: 'Your internet is unstable.',
          isIndefinite: true,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }),
      );

      if (profile && inLesson) {
        const lowNetworkQualityMessage: NetworkQualityLogMessage = {
          profileSenderType: UserProfileType.Student,
          lessonId: inLesson?.lessonId,
          userId: authUserId,
          profileId: profile.id,
        };

        socket.emit(EventType.LogDailyLessonNetworkQuality, lowNetworkQualityMessage);
      }

      // We set the flag to true here so that we don't display the pop-up too much or flood the event logs.
      studentNetworkEventLogged.current = true;
    }
  });

  useCPULoad({
    onCPULoadChange: (cpuLoadChangeEvent) => {
      // only handle cases of high cpu
      if (cpuLoadChangeEvent.cpuLoadState === 'high') {
        let errorMessage = `Student's hardware experiencing high CPU due to: `;

        switch (cpuLoadChangeEvent.cpuLoadStateReason) {
          case 'decode':
            errorMessage = errorMessage.concat('video decoding. ');
            break;
          case 'encode':
            errorMessage = errorMessage.concat('video encoding. ');
            break;
          default:
            errorMessage = errorMessage.concat('an unknown reason. ');
            break;
        }

        errorMessage = errorMessage.concat(` This may result in poor call quality.`);

        console.log('[StudentBlade]: high-cpu', errorMessage);

        if (profile?.type && inLesson?.lessonId) {
          const lessonErrorMessage: LessonErrorMessage = {
            profileSenderType: profile.type,
            lessonId: inLesson?.lessonId,
            [profile.type]: {
              profileId: profile.id,
              userId: authUserId,
              errorMessage: errorMessage,
              type: 'high-cpu',
              fatal: false,
            },
          };

          socket.emit(EventType.SyncAndLogLessonDailyError, lessonErrorMessage);
        }
      }
    },
  });

  // We catch the Students fatal error here, log the event to the api then display the Error Dialog.
  useDailyEvent(
    'error',
    useCallback(
      (e: DailyEventObjectFatalError) => {
        errorDetailsRef.current = { userType: UserProfileType.Student, err: e };

        console.log('[StudentBlade]: fatal-error', e);

        if (profile?.type && inLesson?.lessonId) {
          const errorMessage: LessonErrorMessage = {
            profileSenderType: profile.type,
            lessonId: inLesson?.lessonId,
            [profile.type]: {
              profileId: profile.id,
              userId: authUserId,
              errorMessage: e.errorMsg,
              type: (e.error?.type as DailyFatalErrorType) ?? e.errorMsg,
              fatal: true,
            },
          };

          socket.emit(EventType.SyncAndLogLessonDailyError, errorMessage);
        }

        setErrorDialog(true);
      },
      [authUserId, inLesson?.lessonId, profile?.id, profile?.type, socket],
    ),
  );

  return (
    <>
      <Stack
        sx={{
          width: '342px',
          boxShadow: '1px 0px 3px 0px rgba(0, 0, 0, 0.30), 4px 0px 8px 3px rgba(0, 0, 0, 0.15)',
          background: hootTokens.palette.white,
          alignItems: 'center',
          padding: 5,
          gap: 5,
        }}
      >
        <HootLogo sx={{ width: '140px', height: '50px' }} />

        {hasTeacherJoined ? <RemoteTeacherTile /> : <WaitingForUser />}

        <LocalStudentTile />

        <PlainIconButton
          icon="gear"
          tooltipLabel="Device settings"
          sx={{
            width: '100%',
            '& > svg': {
              width: '32px',
              height: '32px',
            },
          }}
          onClick={onShowStudentInLesson}
        />

        <DailyAudio maxSpeakers={2} onPlayFailed={(e) => console.error('Failed to play audio from Student blade', e)} />
      </Stack>

      {errorDialog && <DailyErrorDialog errorDetails={errorDetailsRef?.current} isOpen={errorDialog} setDialogOpen={setErrorDialog} />}
    </>
  );
};

export default StudentBlade;
