import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { EnrolmentStatus } from '@hoot/models/api/enums/Enrolments';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { GenericPaginatedResponse } from '@hoot/models/api/pagination';
import { PasswordStatus, StudentGrade } from '@hoot/models/api/student';

export enum DistrictRepresentativeStudentsUnderEnrolmentSortKeyEnum {
  Name = 'name',
  Number = 'number',
}

export interface DistrictRepresentativeStudentsUnderEnrolmentQuery {
  searchText?: string;
  passwordStatus?: PasswordStatus[];
  grade?: StudentGrade[];

  page: number;
  pageSize: number;
  sortBy: DistrictRepresentativeStudentsUnderEnrolmentSortKeyEnum;
  orderBy: OrderBy;
}

interface StudentEnrolment {
  id: string;
  status: EnrolmentStatus;
  friendlyId: string;
}

interface DistrictRepresentativeStudentUnderEnrolmentResponse {
  id: string;
  name: string;
  hfsStudentNumber: string | null;
  enrolments: StudentEnrolment[];
}

async function searchDistrictRepresentativeStudentsUnderEnrolment(
  districtRepresentativeId: string,
  enrolmentId: string,
  searchParams: DistrictRepresentativeStudentsUnderEnrolmentQuery,
): Promise<GenericPaginatedResponse<DistrictRepresentativeStudentUnderEnrolmentResponse>> {
  const { data } = await axios.get<GenericPaginatedResponse<DistrictRepresentativeStudentUnderEnrolmentResponse>>(
    `${config.apiUrl}/public/district-representative/${districtRepresentativeId}/enrolment/${enrolmentId}/students/search`,
    { params: searchParams },
  );

  return data;
}

export default function useSearchDistrictRepresentativeStudentsUnderEnrolment(
  districtRepresentativeId: string,
  enrolmentId: string,
  searchParams: DistrictRepresentativeStudentsUnderEnrolmentQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<DistrictRepresentativeStudentUnderEnrolmentResponse>, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.SearchDistrictRepresenatativeStudentsUnderEnrolment, districtRepresentativeId, enrolmentId, searchParams],
    () => searchDistrictRepresentativeStudentsUnderEnrolment(districtRepresentativeId, enrolmentId, searchParams),
    options,
  );
}
