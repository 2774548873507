import { useSelector } from 'react-redux';
import { ONE_MINUTE_IN_MS } from '@hoot/constants/constants';
import { useCountdownTimer } from '@hoot/hooks/useCountDownTimer';
import { RootState } from '@hoot/redux/store';

export const useRemainingLessonTime = () => {
  const lessonStartsAt = useSelector((state: RootState) => state.activeLesson.inLesson?.startsAt ?? 0);
  const lessonEndsAt = useSelector((state: RootState) => state.activeLesson.inLesson?.endsAt ?? 0);

  const lessonDuration = (lessonEndsAt - lessonStartsAt) / ONE_MINUTE_IN_MS;
  const timeLeft = useCountdownTimer(lessonStartsAt, lessonDuration);

  return timeLeft.toFormat('mm:ss');
};
