import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { EnrolmentStatus } from '@hoot/models/api/enums/Enrolments';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { GenericPaginatedResponse } from '@hoot/models/api/pagination';
import { PasswordStatus, StudentGrade } from '@hoot/models/api/student';

export interface DistrictRepresentativeStudentsQuery {
  searchText?: string;
  passwordStatus?: PasswordStatus[];
  enrolmentId?: string;
  status?: EnrolmentStatus[];
  startDate?: number;
  endDate?: number;
  grade?: StudentGrade[];

  page: number;
  pageSize: number;
  sortBy: string;
  orderBy: OrderBy;
}

export interface StudentEnrolment {
  id: string;
  status: EnrolmentStatus;
  friendlyId: string;
}

export interface DistrictRepresentativeStudentResponse {
  id: string;
  name: string;
  grade: string | undefined;
  hfsStudentNumber: string | null;
  passwordStatus: PasswordStatus;
  enrolments: StudentEnrolment[];
  schoolId: string | null;
  schoolName: string | null;
}

async function searchDistrictRepresentativeStudentsResponse(
  districtRepresentativeId: string,
  searchParams: DistrictRepresentativeStudentsQuery,
): Promise<GenericPaginatedResponse<DistrictRepresentativeStudentResponse>> {
  const { data } = await axios.get<GenericPaginatedResponse<DistrictRepresentativeStudentResponse>>(
    `${config.apiUrl}/public/district-representative/${districtRepresentativeId}/students/search`,
    { params: searchParams },
  );
  return data;
}

export default function useSearchDistrictRepresentativeStudentsResponse(
  districtRepresentativeId: string,
  searchParams: DistrictRepresentativeStudentsQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<DistrictRepresentativeStudentResponse>, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.SearchDistrictRepresentativeStudents, districtRepresentativeId, searchParams],
    () => searchDistrictRepresentativeStudentsResponse(districtRepresentativeId, searchParams),
    options,
  );
}
