import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface InvoiceSummaryResponse {
  billableLessonsTotal: number;
  billableTasksTotal: number;
  invoiceTotal: number;
  administrationTotal?: number;
  rejectedtotal?: number;
}

async function getInvoiceSummary(invoiceId: string) {
  const { data } = await axios.get<InvoiceSummaryResponse>(`${config.apiUrl}/public/teacher-invoicing/${invoiceId}/summary`);
  return data;
}

export function useGetInvoiceSummary(invoiceId: string, options?: Omit<UseQueryOptions<InvoiceSummaryResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetInvoiceSummary, invoiceId], () => getInvoiceSummary(invoiceId), options);
}
