import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface DistrictRepSchoolSnippetResponse {
  id: string;
  number: number;
  name: string;
  districtName?: string;
}

export default function useDistrictRepGetSchoolSnippet(
  schoolId: string,
  options?: Omit<UseQueryOptions<DistrictRepSchoolSnippetResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetDistrictRepSchoolSnippet, schoolId],
    async () => {
      return (await axios.get<DistrictRepSchoolSnippetResponse>(`${config.apiUrl}/public/district-representative/school/${schoolId}/snippet`)).data;
    },
    options,
  );
}
