import { Control, UseFormWatch } from 'react-hook-form';
import BankingDetailsEmpty from './BankingDetailsEmpty';
import BankingDetailsReadonly from './BankingDetailsReadonly';
import { TeacherProfileForm } from './TeacherProfile';

interface Props {
  readonly: boolean;
  control: Control<TeacherProfileForm, object>;
  watch: UseFormWatch<TeacherProfileForm>;
}

const BankingDetails = (props: Props) => {
  const { readonly, control, watch } = props;

  return readonly ? <BankingDetailsReadonly control={control} watch={watch} /> : <BankingDetailsEmpty control={control} watch={watch} />;
};

export default BankingDetails;
