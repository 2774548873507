import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';

// TODO: This file doesn't contain enums any more as they have been moved to hoot-core.
//  We should rename this file to something more appropriate.

export const scheduledLessonStatusSortPriority = new Map<ScheduledLessonStatus, number>([
  [ScheduledLessonStatus.InProgress, 1],
  [ScheduledLessonStatus.Open, 2],
  [ScheduledLessonStatus.OpenWaitingForStudent, 3],
  [ScheduledLessonStatus.OpenWaitingForTeacher, 4],
  [ScheduledLessonStatus.Scheduled, 5],
  [ScheduledLessonStatus.Rescheduled, 6],
  [ScheduledLessonStatus.CompletedSuccessfully, 7],
  [ScheduledLessonStatus.CompletedUnsuccessfully, 8],
  [ScheduledLessonStatus.Cancelled, 9],
]);

//Note: Lexile and Assess are missing here
export enum ReadingLevelType {
  FAndP = 'F_AND_P',
  GBPlus = 'GB_PLUS',
  Math = 'MATH',
  Decodables = 'DECODABLES',
  ATOS = 'ATOS',
}

export enum AvailabilityExceptionLessonImpact {
  Kept = 'KEPT',
  Dropped = 'DROPPED',
}

export enum ProductStatus {
  Requested = 'REQUESTED',
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
}
