import { SvgIcon, SvgIconProps } from '@mui/material';

const SearchFilledIcon = (props: SvgIconProps) => {
  const { ...rest } = props;
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g opacity="0.4">
        <path
          d="M18.6292 21.4219C19.4003 22.193 20.6505 22.193 21.4216 21.4219C22.1927 20.6508 22.1927 19.4005 21.4216 18.6294L17.4999 14.7078L14.7075 17.5002L18.6292 21.4219Z"
          fill={props.htmlColor ?? 'black'}
        />
        <path
          d="M18.6292 21.4219C19.4003 22.193 20.6505 22.193 21.4216 21.4219C22.1927 20.6508 22.1927 19.4005 21.4216 18.6294L17.4999 14.7078L14.7075 17.5002L18.6292 21.4219Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <circle cx="11" cy="11.0002" r="9" transform="rotate(180 11 11.0002)" fill={props.htmlColor ?? 'black'} />
    </SvgIcon>
  );
};

export default SearchFilledIcon;
