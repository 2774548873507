import { SvgIcon, SvgIconProps } from '@mui/material';

const FilterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.6306 5.34564L21.2264 5.80124C21.2354 5.78944 21.2441 5.77738 21.2524 5.76506L20.6306 5.34564ZM14.9089 12.8274L14.3132 12.3718C14.3042 12.3836 14.2955 12.3957 14.2872 12.408L14.9089 12.8274ZM13.8586 19.5307L14.3858 20.0642L13.8586 19.5307ZM11.6811 21.6827L11.1539 21.1492L11.6811 21.6827ZM9.09107 12.8274L9.71283 12.408C9.70452 12.3957 9.69585 12.3836 9.68683 12.3718L9.09107 12.8274ZM3.36937 5.34564L2.74762 5.76506C2.75592 5.77738 2.76459 5.78944 2.77362 5.80124L3.36937 5.34564ZM5.18117 2.75H18.8188V1.25H5.18117V2.75ZM20.0349 4.89003L14.3132 12.3718L15.5047 13.283L21.2264 5.80124L20.0349 4.89003ZM13.4275 15.2148V18.7699H14.9275V15.2148H13.4275ZM13.3314 18.9973L11.1539 21.1492L12.2083 22.2161L14.3858 20.0642L13.3314 18.9973ZM10.5725 20.9219V15.2148H9.07249V20.9219H10.5725ZM9.68683 12.3718L3.96513 4.89003L2.77362 5.80124L8.49531 13.283L9.68683 12.3718ZM10.5725 15.2148C10.5725 14.2151 10.273 13.2384 9.71283 12.408L8.46931 13.2469C8.86298 13.8304 9.07249 14.5152 9.07249 15.2148H10.5725ZM11.1539 21.1492C10.9286 21.3719 10.5725 21.196 10.5725 20.9219H9.07249C9.07249 22.5649 11.0618 23.3492 12.2083 22.2161L11.1539 21.1492ZM13.4275 18.7699C13.4275 18.8535 13.394 18.9355 13.3314 18.9973L14.3858 20.0642C14.7316 19.7225 14.9275 19.2571 14.9275 18.7699H13.4275ZM14.2872 12.408C13.727 13.2384 13.4275 14.2151 13.4275 15.2148H14.9275C14.9275 14.5152 15.137 13.8304 15.5307 13.2469L14.2872 12.408ZM18.8188 2.75C19.9735 2.75 20.6284 4.00775 20.0089 4.92621L21.2524 5.76506C22.5622 3.82335 21.1425 1.25 18.8188 1.25V2.75ZM5.18117 1.25C2.85747 1.25 1.43776 3.82334 2.74762 5.76506L3.99113 4.92621C3.37155 4.00775 4.02653 2.75 5.18117 2.75V1.25Z"
        fill={props.htmlColor ?? 'currentColor'}
      />
    </SvgIcon>
  );
};

export default FilterIcon;
