import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { GenericPaginatedResponse } from '@hoot/models/api/pagination';

export default function useSearchStudentLibrary(
  query: QueryLibraryV2,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<BookSearch>, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.SearchStudentLibrary, query],
    async () => {
      return (await axios.get<GenericPaginatedResponse<BookSearch>>(`${config.apiUrl}/student-library`, { params: query })).data;
    },
    options,
  );
}
