import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';

async function sendPasswordResetEmail(email: string): Promise<void> {
  await axios.post<void>(`${config.apiUrl}/auth/send-password-reset-email`, {
    email,
  });
}

export function useSendPasswordResetEmail(options?: Omit<UseMutationOptions<void, AxiosError, string>, 'mutationKey' | 'mutationFn'>) {
  return useMutation((email) => sendPasswordResetEmail(email), options);
}
