import { HootSubmissionDataField } from '@hoot-reading/hoot-core/dist/enums/hoot-assessment/hoot-submission-data-field';
import { HootSubmissionDataFieldV2 } from '@hoot-reading/hoot-core/dist/enums/hoot-assessment/hoot-submission-data-field-v2';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Autocomplete, Box, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { FieldValues, Path, UseControllerProps, useController } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useParams } from 'react-router-dom';
import { featureFlags } from '@hoot/constants/featureFlags';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import useGetAssessmentBook from '@hoot/hooks/api/library/useGetAssessmentBook';
import useSearchLibrary, { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import { useDebounce } from '@hoot/hooks/useDebounce';
import { InstructionalFocus } from '@hoot/models/api/enums/instructional-focus';
import { ShelfType } from '@hoot/models/api/enums/shelf-type-enum';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';
import TextField from '@hoot/ui/components/v2/core/TextField';
import SearchIcon from '@hoot/ui/components/v2/icons/SearchIcon';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';

const wordCountSubmissionKeys = [
  HootSubmissionDataField.U_4_1A_V1_WordCount.toString(),
  HootSubmissionDataField.U_4_1A_V2_WordCount.toString(),
  HootSubmissionDataFieldV2.U_4_1_WordCount,
];

interface BookAutoSuggestProps {
  label: string;
  value: BookSearch;
  watching: Record<string, any>;
  instructionalFocusIds?: InstructionalFocus[];
  setValue: UseFormSetValue<any>;
}

export function BookAutoSuggest<TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>(
  props: UseControllerProps<TFieldValues, TName> & BookAutoSuggestProps,
) {
  const { label, value, instructionalFocusIds, setValue, watching } = props;
  const { field, fieldState } = useController(props);
  const { assessmentId, studentProfileId } = useParams();

  const [searchTerm, setSearchTerm] = useState<string>();
  const { isFeatureEnabled } = useFeatureFlags();
  const isNewAssessmentSuiteEnabled = isFeatureEnabled(featureFlags.SC_11213_AssessmentSuite_v2);
  const dispatch = useAppDispatch();

  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [query, setQuery] = useState<QueryLibraryV2>({
    title: '',
    shelfType: ShelfType.AllBooks,
    pageSize: 100,
    page: 1,
    instructionalFocusIds,
  });

  useEffect(() => {
    setQuery((current) => ({ ...current, title: debouncedSearchTerm }));
  }, [debouncedSearchTerm]);

  const { data, isLoading } = useSearchLibrary(query, { enabled: !value });
  const bookField = Object.keys(watching).find((key) => wordCountSubmissionKeys.includes(key));

  useGetAssessmentBook(assessmentId!, studentProfileId!, {
    enabled: isNewAssessmentSuiteEnabled && !!assessmentId && !!studentProfileId && !value,
    retry: 1,
    onSuccess: (book) => {
      if (!!book) {
        field.onChange({ id: book.id, title: book.name });
        if (bookField) {
          setValue(bookField, book.wordCount ?? '', { shouldDirty: true });
        }
      }
    },
    onError: () => {
      dispatch(
        createFlashMessage({
          message: 'There was an error in selecting a book for this student. Please close the assessment and contact help@hootreading.com.',
          variant: 'error',
          isIndefinite: true,
        }),
      );
    },
  });

  const onBookSelected = (book: BookSearch | null) => {
    if (book) {
      field.onChange({ id: book.id, title: book.title });
      if (bookField) {
        setValue(bookField, book.wordCount ?? '', { shouldDirty: true });
      }
    } else {
      field.onChange(null);
    }
  };

  if (isNewAssessmentSuiteEnabled) {
    return (
      <Box sx={{ width: '100%', mb: 2 }}>
        <ReadOnlyTextField sx={{ flex: 1 }} label={label} body={field.value?.title ?? 'No book found'} />
      </Box>
    );
  }

  // Todo Remove the autocomplete component and logic when removing the FF SC_11213_AssessmentSuite_v2
  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Autocomplete
        onChange={(_event, value) => onBookSelected(value)}
        size="medium"
        fullWidth
        loading={isLoading}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
        options={data?.data ?? []}
        getOptionLabel={(option) => `${option.title}`}
        value={value || null}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterOptions={(x) => x}
        onInputChange={(_event, val, _reason) => {
          setSearchTerm(val);
        }}
        clearIcon={<HighlightOffIcon />}
        sx={{
          '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '8px 4px 0px 0px', // Temporary fix until Phase 2.
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={!!fieldState.error}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
}
