import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface LessonReviewStateResponse {
  notepadText: string;
  lessonId: string;
  studentProfileId: string;
}

async function getLessonReviewState(lessonId: string): Promise<LessonReviewStateResponse> {
  const { data } = await axios.get<LessonReviewStateResponse>(`${config.apiUrl}/public/teacher/lessons/${lessonId}/get-lesson-review-state`);
  return data;
}

export default function useGetLessonReviewState(
  lessonId: string,
  options?: Omit<UseQueryOptions<LessonReviewStateResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetLessonReviewState, lessonId], () => getLessonReviewState(lessonId), options);
}
