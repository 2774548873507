import { Box } from '@mui/system';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import Lottie, { LottieFile } from '../../lottie/Lottie';

const WaitingForUser = (): JSX.Element => {
  return (
    <Box
      sx={{
        borderRadius: '8px',
        background: hootTokens.surface['1'],
        boxShadow: hootTokens.elevation.elevation1,
        width: '100%',
        height: '178px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Lottie lottieFile={LottieFile.ClockTime} loop play style={{ width: '100px', height: '100px' }} />
    </Box>
  );
};
export default WaitingForUser;
