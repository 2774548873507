import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '@hoot/config';

async function resubmitInvoiceLessons(invoiceId: string): Promise<void> {
  await axios.put(`${config.apiUrl}/public/teacher-invoicing/${invoiceId}/unsubmitted-lessons/resubmit`);
}

export function useResubmitInvoiceLessons(options?: UseMutationOptions<void, AxiosError, string>) {
  return useMutation<void, AxiosError, string>((invoiceId) => resubmitInvoiceLessons(invoiceId), options);
}
