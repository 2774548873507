import { useDevices } from '@daily-co/daily-react';
import { Select } from '../../../core/Select';

export function DailyCameraSelect() {
  const { currentCam, cameras, setCamera } = useDevices();

  const handleCameraChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCamera(event.target.value);
  };

  return (
    <Select label="Camera" value={currentCam?.device.deviceId} onChange={handleCameraChange} required>
      {!currentCam ? (
        <option disabled value="">
          Select
        </option>
      ) : null}
      {cameras.map((cam) => (
        <option value={cam.device.deviceId} key={`cam-${cam.device.deviceId}`}>
          {cam.device.label.trim() === '' ? 'No Video Device(s) Detected' : cam.device.label.trim()}
        </option>
      ))}
    </Select>
  );
}
