import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';
import { HowDidYouHearAboutHoot, SocialMedia } from '@hoot/ui/pages/v2/public/free-lesson/registration/steps/FreeLessonCreateProfile';

export interface FlfCreateProfileRequestDto {
  country: string;
  provinceState: string;
  timeZone: string;
  howDidYouHearAboutHoot?: HowDidYouHearAboutHoot;
  howDidYouHearAboutHootOther?: string;
  socialMedia?: SocialMedia;
  referralCode?: string;
}

export default function useFlfCreateProfileRequest() {
  return useMutation<void, AxiosError, FlfCreateProfileRequestDto>(async (request) => {
    return (await axios.post(`${config.apiUrl}/free-lesson/v2/create-profile`, request)).data;
  });
}
