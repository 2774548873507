import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { GenericPaginatedResponse } from '@hoot/models/api/pagination';
import { QueryKey } from '../queryKeys';

export enum TaskType {
  LessonReview = 'LESSON_REVIEW',
  LessonAssessment = 'LESSON_ASSESSMENT',
}

export interface RequiredTaskMeta {
  key: RequiredTaskKey;
  val: string;
}
export enum RequiredTaskKey {
  AssessmentId = 'ASSESSMENT_ID',
  LessonId = 'LESSON_ID',
}

export interface RequiredTaskResponse {
  taskId: string;
  taskType: TaskType;
  taskAt: number;
  status: string;
  studentProfileName: string;
  studentProfileId: string;
  meta?: RequiredTaskMeta[];
}

export interface RequiredTaskResponseQuery {
  page: number;
  pageSize: number;
}

async function getRequiredTasks(query: RequiredTaskResponseQuery): Promise<GenericPaginatedResponse<RequiredTaskResponse>> {
  const { data } = await axios.get<GenericPaginatedResponse<RequiredTaskResponse>>(`${config.apiUrl}/my-students/action-required-tasks`, {
    params: query,
  });
  return data;
}

export default function useGetRequiredTasks(
  query: RequiredTaskResponseQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<RequiredTaskResponse>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetRequiredTasks, query], () => getRequiredTasks(query), options);
}
