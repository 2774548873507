import { DailyVideo, useDaily, useDevices, useLocalSessionId, useMeetingState, useParticipantProperty } from '@daily-co/daily-react';
import { Box, CircularProgress, Link, Stack, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { DailyMediaState } from '@hoot/hooks/daily/useDailyMediaState';
import { useDailyNetworkTest } from '@hoot/hooks/daily/useDailyNetworkTest';
import { useDailyWebsocketTest } from '@hoot/hooks/daily/useDailyWebsocketTest';
import { useGetDeviceState } from '@hoot/hooks/daily/useGetDeviceState';
import { Orientation, useWindowSize } from '@hoot/hooks/useWindowSize';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { useAudioVideoSettingsDialog } from '@hoot/ui/context/AudioVideoSettingsDialogContext';
import { useGetReadyV2Dialog } from '@hoot/ui/context/GetReadyV2DialogProvider';
import { getAudioVideoSupportLink } from '@hoot/utils/daily/avSupportLink';
import useProfile from '../../../../../../hooks/useProfile';
import { Button } from '../../../core/Button';
import HootTypography from '../../../core/HootTypography';
import { Icon } from '../../../core/Icon';
import Camera from '../../../icons/Camera';
import Chevron from '../../../icons/Chevron';
import Microphone from '../../../icons/Microphone';
import PlayIcon from '../../../icons/PlayIcon';
import DailyVolumeMeter from '../../../video/DailyVolumeMeter';
import BasicAlertDialog from '../../BasicAlertDialog';
import DeniedPermissions from '../av-permissions/DeniedPermissions';
import PromptPermissions from '../av-permissions/PromptPermissions';
import { DailyAudioInputSelect } from './DailyAudioInputSelect';
import { DailyCameraSelect } from './DailyCameraSelect';
import DailyDevicesNotFound from './DailyDevicesNotFound';
import { VideoSkeleton } from './VideoSkeleton';

const DailyAVSettingsDialog = () => {
  const dailyCallObj = useDaily();
  const meetingState = useMeetingState();
  const { cameras, microphones } = useDevices();
  const { lessonToJoin } = useGetReadyV2Dialog();
  const mediaState = useGetDeviceState();

  const [showModals, setShowModals] = useState(false);

  useEffect(() => {
    if (!dailyCallObj) {
      console.log('[DailyAVSettingsDialog]: No call object, bail');
      return;
    }

    setTimeout(() => {
      setShowModals(true);
    }, 1000);

    if (meetingState === 'new' || meetingState === 'left-meeting') {
      dailyCallObj
        .startCamera({})
        .then(() => {
          dailyCallObj.setLocalAudio(true);
          dailyCallObj.setLocalVideo(true);
          if (mediaState === 'idle') {
            setTimeout(() => {
              setShowModals(true);
            }, 500);
          } else {
            setShowModals(true);
          }
        })
        .catch((err) => console.error('Failed to start Camera()', err));
    }
  }, [dailyCallObj, meetingState, mediaState]);

  if (showModals) {
    if (mediaState === 'granted' || mediaState === 'constraints-invalid') {
      // Show get ready if lesson to join is not undefined
      const noDevicesAvailable = cameras.length === 0 || microphones.length === 0;
      if (noDevicesAvailable) {
        return <DailyDevicesNotFound />;
      } else if (!!lessonToJoin) {
        return <GetReadyDialog />;
      } else {
        return <AVSettingsDialog />;
      }
    } else if (mediaState === 'blocked') {
      return <DeniedPermissionsDialog />;
    } else if (mediaState === 'idle') {
      return <PromptPermissionsDialog />;
    }
  }

  return null;
};

export function DeniedPermissionsDialog() {
  const { dismiss } = useGetReadyV2Dialog();
  const { onDismissStudent, onDismiss } = useAudioVideoSettingsDialog();

  const handleDismiss = () => {
    dismiss();
    onDismissStudent();
    onDismiss();
  };

  return <BasicAlertDialog show={true} onDismiss={handleDismiss} maxWidth={'md'} title="Camera and Audio Settings" content={<DeniedPermissions />} />;
}

export function PromptPermissionsDialog() {
  const { dismiss } = useGetReadyV2Dialog();
  const { onDismissStudent, onDismiss } = useAudioVideoSettingsDialog();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setShowModal(true);
    }, 2000);

    return () => {
      clearTimeout(id);
    };
  }, []);

  const handleDismiss = () => {
    onDismissStudent();
    dismiss();
    onDismiss();
  };

  if (showModal) {
    return (
      <BasicAlertDialog show={true} onDismiss={handleDismiss} maxWidth={'md'} title="Camera and Audio Settings" content={<PromptPermissions />} />
    );
  }

  return null;
}

export function AVSettingsDialog() {
  const { dismiss } = useGetReadyV2Dialog();
  const { isStudent, isTeacher } = useProfile();

  const dailyCallObj = useDaily();
  const { inLesson } = useActiveLessonState();

  useEffect(() => {
    if (dailyCallObj) {
      if (dailyCallObj.meetingState() === 'joined-meeting') {
        const meetingSession = dailyCallObj.meetingSessionState();
        if (meetingSession) {
          const data = meetingSession.data as DailyMediaState;
          if (isStudent) {
            dailyCallObj.setLocalAudio(data.studentMicrophoneOn ?? true);
            dailyCallObj.setLocalVideo(data.studentCameraOn ?? true);
          } else if (isTeacher) {
            dailyCallObj.setLocalAudio(data.teacherMicrophoneOn ?? true);
            dailyCallObj.setLocalVideo(data.teacherCameraOn ?? true);
          }
        }
      } else {
        dailyCallObj.setLocalAudio(true);
        dailyCallObj.setLocalVideo(true);
      }
    }
  }, [isStudent, isTeacher, dailyCallObj]);

  const handleCancel = () => {
    // if we're not in a lesson, we want to shut the camera off
    // otherwise, leave the camera in the state it's in
    if (!inLesson) {
      dailyCallObj?.leave();
    }
    dismiss();
  };

  const handleApply = () => {
    // if we're not in a lesson, we want to shut the camera off
    // otherwise, leave the camera in the state it's in
    if (!inLesson) {
      dailyCallObj?.leave();
    }

    dismiss();
  };

  return (
    <BasicAlertDialog
      show={true}
      onDismiss={handleCancel}
      maxWidth={'md'}
      title="Camera and Audio Settings"
      content={<GetReadyDeviceSelection />}
      primaryAction={{
        onClick: handleApply,
        label: 'Apply',
        props: { variant: 'contained' },
      }}
      secondaryAction={{
        onClick: handleCancel,
        label: 'Cancel',
        props: { variant: 'outlined' },
      }}
    />
  );
}

export function GetReadyDialog() {
  const { joinLesson, dismiss, lessonToJoin } = useGetReadyV2Dialog();

  const dailyCallObj = useDaily();
  const { inLesson } = useActiveLessonState();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCancel = () => {
    dailyCallObj?.leave();
    dismiss();
  };

  useEffect(() => {
    dailyCallObj?.setLocalAudio(true);
    dailyCallObj?.setLocalVideo(true);
  }, [dailyCallObj]);

  // only used in the case where you're in Get Ready modal and click join
  useEffect(() => {
    if (isLoading && inLesson) {
      dismiss();
    }
  }, [inLesson, dismiss, isLoading]);

  const handleApply = () => {
    joinLesson();
    setIsLoading(true);
  };

  return (
    <BasicAlertDialog
      show={true}
      onDismiss={handleCancel}
      maxWidth={'md'}
      title="Get Ready for Your Lesson!"
      content={<GetReadyDeviceSelection />}
      primaryAction={{
        onClick: handleApply,
        label: 'Begin Lesson',
        props: { id: `teacher-join-lesson-${lessonToJoin?.lessonNumber}`, variant: 'contained', startIcon: <PlayIcon htmlColor="#FFF" /> },
        isLoading: isLoading,
      }}
      secondaryAction={{
        onClick: handleCancel,
        label: 'Back',
        props: { variant: 'outlined', startIcon: <Chevron /> },
      }}
    />
  );
}

const GetReadyDeviceSelection = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isBelowDesktop = useMediaQuery(theme.breakpoints.down('md'));
  const { orientation } = useWindowSize();
  const isTabletPortrait = orientation === Orientation.Portrait && isBelowDesktop;

  const localSessionId = useLocalSessionId();
  const localParticipantTracks = useParticipantProperty(localSessionId, 'tracks');
  const { currentCam, currentMic } = useDevices();

  return (
    <Grid container flexWrap="nowrap" gap={3}>
      {/* Left Grid for Cam/Mic test */}
      <Grid size={{ xs: 12, md: 6 }}>
        <Stack gap={2}>
          <Stack flexDirection="row" gap={1}>
            <Camera />
            <HootTypography isPII={false} variant="titlesmall">
              Camera Settings
            </HootTypography>
          </Stack>
          <DailyCameraSelect />

          {!!currentCam && localSessionId ? (
            <Box
              sx={{
                '& > video': {
                  height: '100%',
                  width: '100%',
                  borderRadius: '8px',
                  transform: 'scale(-1, 1)',
                  '&::-webkit-media-controls-panel': {
                    transform: 'scale(-1, 1)',
                  },
                },
              }}
            >
              {localParticipantTracks?.video?.state === 'playable' ? (
                <DailyVideo
                  sessionId={localSessionId}
                  type={'video'}
                  automirror={true}
                  autoPlay={true}
                  muted={true}
                  width="100%"
                  height="100%"
                  fit="cover"
                  style={{
                    maxHeight: isTabletPortrait ? '320px' : '240px',
                    borderRadius: '8px',
                    transform: 'scale(-1, 1)',
                  }}
                />
              ) : (
                <VideoSkeleton />
              )}
            </Box>
          ) : null}

          <Stack flexDirection="row" gap={1}>
            <Microphone />
            <HootTypography isPII={false} variant="titlesmall">
              Audio Settings
            </HootTypography>
          </Stack>

          <DailyAudioInputSelect />

          {!!currentMic ? <DailyVolumeMeter /> : null}
        </Stack>
      </Grid>

      {/* Right Grid for Descriptions & Info */}
      {!isTablet && (
        <Grid container size={6}>
          <Stack sx={{ gap: '24px', justifyContent: 'flex-start' }}>
            <Stack sx={{ flexDirection: 'row', alignItems: 'flex-start', gap: '16px' }}>
              <Icon name="hoot_wheel" sx={{ marginTop: '8px' }} />
              <div>
                <HootTypography isPII={false} variant="titlemedium">
                  Camera
                </HootTypography>
                <HootTypography isPII={false} variant="bodymedium">
                  Ensure your camera is working properly and positioned at eye level. A clear and well-lit video helps create a great learning
                  environment.
                </HootTypography>
              </div>
            </Stack>
            <Stack sx={{ flexDirection: 'row', alignItems: 'flex-start', gap: '16px' }}>
              <Icon name="hoot_wheel" sx={{ marginTop: '8px' }} />
              <div>
                <HootTypography isPII={false} variant="titlemedium">
                  Audio
                </HootTypography>
                <HootTypography isPII={false} variant="bodymedium">
                  Test your microphone and make sure you're in a quiet space to minimize distractions during the call.
                </HootTypography>
              </div>
            </Stack>
            <Stack sx={{ flexDirection: 'row', alignItems: 'flex-start', gap: '16px' }}>
              <Icon name="hoot_wheel" sx={{ marginTop: '8px' }} />
              <div>
                <HootTypography isPII={false} variant="titlemedium">
                  Network Connection
                </HootTypography>
                <HootTypography isPII={false} variant="bodymedium" sx={{ marginBottom: '8px' }}>
                  A strong internet connection is required for an optimal Hoot experience.
                </HootTypography>
                <NetworkConnectionTests />
              </div>
            </Stack>
            <Stack sx={{ flexDirection: 'row', alignItems: 'flex-start', gap: '16px' }}>
              <Icon name="hoot_wheel" sx={{ marginTop: '8px' }} />
              <div>
                <HootTypography isPII={false} variant="titlemedium">
                  Video or Audio Issues?
                </HootTypography>
                <HootTypography isPII={false} variant="bodymedium">
                  Your computer privacy settings may be blocking the device. Check the camera and microphone permissions under System Settings -&gt;
                  Privacy.
                  <Link target="_blank" href={getAudioVideoSupportLink()} sx={{ marginLeft: '5px' }}>
                    Learn More
                  </Link>
                </HootTypography>
              </div>
            </Stack>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

function NetworkConnectionTests() {
  const localSessionId = useLocalSessionId();
  const localParticipantTracks = useParticipantProperty(localSessionId, 'tracks');

  const { networkTestResultMessage, networkTestState } = useDailyNetworkTest({
    localParticipantTracks: localParticipantTracks,
  });

  useDailyWebsocketTest({
    localParticipantTracks: localParticipantTracks,
  });

  return (
    <div>
      <Stack direction={'row'} gap={1} sx={{ marginTop: '16px' }}>
        {networkTestState === 'testing' ? <CircularProgress size={20} sx={{ color: 'inherit' }} /> : null}
        {networkTestState === 'completed' ? <Icon name="check_box_filled" color={'success'} /> : null}
        <HootTypography isPII={false} variant="bodymedium">
          {networkTestResultMessage}
        </HootTypography>
      </Stack>

      {/* NM: We are hiding this for the moment, it is flaky because of 429 Too Many Request errors (not an end user issue) */}
      {/*<Stack direction={'row'} gap={1} sx={{ marginTop: '8px' }}>*/}
      {/*  {wsTestState === 'testing' ? <CircularProgress size={20} sx={{ color: 'inherit' }} /> : null}*/}
      {/*  {wsTestState === 'completed' ? <Icon name="check_box_filled" color={'success'} /> : null}*/}
      {/*  <HootTypography isPII={false} variant="bodymedium">*/}
      {/*    {wsTestResultMessage}*/}
      {/*  </HootTypography>*/}
      {/*</Stack>*/}

      <div style={{ display: 'inline-block', marginTop: '16px' }}>
        <a href="https://network-test.daily.co/index.html" target="_blank" rel="noreferrer">
          <Button variant="outlined" size="small" color="primary">
            Troubleshoot
          </Button>
        </a>
      </div>
    </div>
  );
}

export default DailyAVSettingsDialog;
