import { DateTime, Duration } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { ONE_MINUTE_IN_MS, ONE_SECOND_IN_MS } from '@hoot/constants/constants';

export function useCountdownTimer(startTime: number, durationInMinutes: number) {
  const duration = Duration.fromObject({ minute: durationInMinutes }).toMillis();
  const endtime = startTime + duration;

  const timer = useRef<number>();
  const [timeLeft, setTimeLeft] = useState<Duration>(() => {
    return startTime > endtime ? Duration.fromMillis(0) : Duration.fromMillis(endtime - startTime);
  });

  useEffect(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }

    timer.current = window.setInterval(() => {
      const timeToZero = Math.floor(timeLeft.valueOf() / ONE_SECOND_IN_MS) < 2;
      const timerHasNotStarted = (DateTime.now().toMillis() - startTime) / ONE_MINUTE_IN_MS < 0;

      if (timerHasNotStarted) {
        setTimeLeft(Duration.fromObject({ minute: durationInMinutes }));
      } else if (!timeToZero) {
        setTimeLeft(Duration.fromMillis(endtime - DateTime.now().set({ millisecond: 0 }).toLocal().toMillis()));
      } else if (timeToZero) {
        setTimeLeft(Duration.fromMillis(0));
        clearInterval(timer.current);
      }
    }, 500);
    return () => {
      clearInterval(timer.current);
    };
  }, [durationInMinutes, endtime, startTime, timeLeft]);

  return timeLeft;
}
