import { Card } from '@mui/material';
import { Box } from '@mui/system';
import { MutableRefObject, useCallback, useEffect } from 'react';
import { Editor, TLStoreWithStatus, Tldraw } from 'tldraw';
import 'tldraw/tldraw.css';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface TeacherInLessonWhiteboardProps {
  editorRef: MutableRefObject<Editor | undefined>;
  store: TLStoreWithStatus;
  onWhiteboardMounted?: (editor: Editor) => void;
  lockCanvas?: boolean; // Puts the canvas into `read only` mode.
}

/**
 * Tldraw whiteboard at its base (UI controls are stripped out), and wrapped in a card.
 */
const Whiteboard = (props: TeacherInLessonWhiteboardProps) => {
  const { editorRef, store, onWhiteboardMounted, lockCanvas } = props;

  const onCanvasLockedChange = useCallback(() => {
    if (lockCanvas) {
      editorRef.current?.updateInstanceState({ isReadonly: true });
    } else {
      editorRef.current?.updateInstanceState({ isReadonly: false });
    }
  }, [lockCanvas, editorRef]);

  useEffect(() => {
    onCanvasLockedChange();
  }, [onCanvasLockedChange]);

  return (
    <Card
      sx={{
        height: '100%',
        padding: 0,
        ...hootTokens.elevation.get(1),
      }}
    >
      <Box
        className="tldraw__editor"
        sx={{
          height: '100%',
          '& .tl-background': {
            backgroundColor: hootTokens.palette.white,
          },
        }}
      >
        <Tldraw
          hideUi
          autoFocus
          store={store}
          onMount={(e) => {
            editorRef.current = e;

            e.zoomToFit(); // When a whiteboard (template) has been loaded, invoke zoom-to-fit so that we can see the full thing.
            onCanvasLockedChange(); // On Mount we call this to set the initial lock-state for the Student.
            onWhiteboardMounted?.(e);
          }}
          cameraOptions={{
            wheelBehavior: 'none',
          }}
        />
      </Box>
    </Card>
  );
};

export default Whiteboard;
