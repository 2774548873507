import React, { useEffect, useState } from 'react';
import { clearFlashMessage, useFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { Snackbar } from '@hoot/ui/components/v2/core/Snackbar';

const FlashMessage = () => {
  const { message, anchorOrigin, ...rest } = useFlashMessage() ?? {};

  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  // Transitions the toast out of view.
  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // The toast is not visible at this point. Clear the text.
  const handleExited = () => {
    dispatch(clearFlashMessage());
  };

  return (
    <Snackbar
      variant="light"
      open={open}
      onClose={handleClose}
      anchorOrigin={anchorOrigin ?? { vertical: 'bottom', horizontal: 'center' }}
      TransitionProps={{ onExited: handleExited }}
      message={message ?? ''}
      {...(rest ?? {})}
    />
  );
};

export default FlashMessage;
