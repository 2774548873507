import ReactLottie, { LottieProps as ReactLottieProps } from 'react-lottie-player';

export const lottiePathPrefix = 'lottie-files';

export enum LottieFile {
  AddPlus = 'add-plus.json',
  ApprovedCheck = 'approved-check.json',
  ArrowDown = 'arrow-down.json',
  ArrowLeft = 'arrow-left.json',
  ArrowRight = 'arrow-right.json',
  ArrowUp = 'arrow-up.json',
  AsianFemaleTeacher = 'asian-female-teacher.json',
  BadNetwork = 'bad-network.json',
  BasketBall = 'basketball.json',
  Bell = 'bell.json',
  BellWhite = 'bell-white.json',
  BlackFemaleTeacher = 'black-female-teacher.json',
  Camera = 'camera.json',
  Castle = 'castle.json',
  CelebrationStar = 'celebration-star.json',
  Checkmark = 'checkmark.json',
  ClockTime = 'clock-time.json',
  Confetti = 'confetti.json',
  Dictionary = 'dictionary.json',
  ErrorCross = 'error-cross.json',
  Exit = 'exit.json',
  Funnel = 'funnel.json',
  Gear = 'gear.json',
  GreenHorizontalArrow = 'green-horizontal-arrow.json',
  GuineaPig = 'guinea-pig.json',
  Heart = 'heart.json',
  HeartBalloon = 'heart-balloon.json',
  Microphone = 'microphone.json',
  OpenBook = 'open-book.json',
  Play = 'play.json',
  RestartArrow = 'restart-arrow.json',
  Rocket = 'rocket.json',
  Search = 'search.json',
  SinglePage = 'single-page.json',
  Star = 'star.json',
  ThinkingFace = 'thinking-face.json',
  ThumbsUp = 'thumbs-up.json',
  WhiteFemaleTeacher = 'white-female-teacher.json',
}

export type LottieProps = ReactLottieProps & {
  lottieFile: LottieFile;
};

const Lottie = (props: LottieProps) => {
  const { lottieFile, ...LottieProps } = props;
  return <ReactLottie path={`/${lottiePathPrefix}/${lottieFile}`} {...LottieProps} />;
};

export default Lottie;
