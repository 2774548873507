import { Box } from '@mui/system';
import React, { BaseSyntheticEvent, useRef, useState } from 'react';
import { isFirefox, isIOS, isSafari } from 'react-device-detect';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const MAX_ATTEMPTS = 3;

type BookPageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  pageSide: 'left' | 'right' | 'singlePage';
};

const BookPageV2 = (props: BookPageProps) => {
  const { pageSide, ...imgProps } = props;

  const dispatch = useAppDispatch();

  const imgRef = useRef<HTMLImageElement>(null);

  const [widthOverride, setWidthOverride] = useState<number>();
  const attempts = useRef<number>(0);

  function handleOnError() {
    if (imgRef.current) {
      if (attempts.current < MAX_ATTEMPTS) {
        attempts.current += 1;
        imgRef.current.src = `${props.src}?${Date.now()}`;
      } else {
        dispatch(
          createFlashMessage({
            variant: 'error',
            message: 'Oops! There was an issue loading the book page. Try refreshing the page.',
          }),
        );
      }
    }
  }

  const calculateActualImageWidth = (args: { naturalWidth: number; naturalHeight: number; targetHeight: number }): number => {
    const { naturalWidth, naturalHeight, targetHeight } = args;
    const aspectRatio = naturalWidth / naturalHeight;
    return targetHeight * aspectRatio;
  };

  const handleImgLoaded = (event: BaseSyntheticEvent) => {
    // Note about image widths:
    // On iOS, Safari, and Firefox, width 'auto" appears to still take up 100% of its flex parent's width.
    // As a workaround, we're applying a width-override to be the exact width (in pixels) that we need in order
    // to render the image such that the aspect ratio is correct, we can fill 100% of the parent's height, and
    // the image aspect ratio is correct.
    if ((isIOS || isSafari || isFirefox) && event.currentTarget) {
      const actualWidth = calculateActualImageWidth({
        naturalWidth: event.currentTarget.naturalWidth,
        naturalHeight: event.currentTarget.naturalHeight,
        targetHeight: event.currentTarget.height,
      });
      setWidthOverride(actualWidth);
    }
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: '100%',
          maxHeight: '100%',
        }}
      >
        <Box
          component="img"
          sx={{
            objectFit: 'contain',
            WebkitTouchCallout: 'none', // disabling the save/copy option menu for images when holding your finger on it
            boxShadow: hootTokens.elevation.elevation1,
            // Left and right pages have a different border radius.
            borderRadius: pageSide === 'left' ? '10px 0px 0px 10px' : pageSide === 'right' ? '0px 10px 10px 0px' : '10px',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
            // A note about how these images render:
            // The book page (AKA the image of the book page) needs to _only_ fill as much space as it needs for its
            // width, but must fill the height of its parent container. This will ensure that, regardless of window
            // dimensions, users are _always_ seeing the same scaled images with the correct aspect ratio. This is ultra
            // important b/c we need to ensure that the magic finger coordinates always point "at the same spot" for
            // both users in a lesson (the magic finger container wraps the visible pages (one or two pages depending on
            // if we're in single-page mode or double-page mode).
            width: widthOverride ? widthOverride : 'auto',
            height: '100%',
          }}
          onLoad={handleImgLoaded}
          onError={handleOnError}
          ref={imgRef}
          alt={props.alt}
          {...imgProps}
        />
      </Box>
    </>
  );
};

export default BookPageV2;
