import { externalRoutes } from '@hoot/routes/externalRoutes';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { NavList } from '@hoot/ui/components/v2/core/NavMenuList';
import { LIVE_CHAT_TITLE, zendeskOpenLiveChat } from '@hoot/utils/zendesk-methods';
import NewTabIcon from '../components/v2/icons/NewTabIcon';

interface ParentNavOptions {
  showAudioVideoSettingsModal: () => void;
  showChangePasswordModal: () => void;
}

const parentNavItems = (options: ParentNavOptions): NavList[] => {
  const { showChangePasswordModal, showAudioVideoSettingsModal } = options;
  return [
    { label: routesDictionary.dashboard.label, url: routesDictionary.dashboard.url },
    { label: routesDictionary.schedule.label, url: routesDictionary.schedule.url },
    { label: LIVE_CHAT_TITLE, url: '', onClick: () => zendeskOpenLiveChat() },
    {
      label: 'Settings',
      subList: [
        {
          label: 'Account Settings',
          url: routesDictionary.settings.account.url,
        },
        {
          label: 'Audio/Video Settings',
          url: '',
          onClick: showAudioVideoSettingsModal,
          hideOnMobile: true,
        },
        {
          label: 'Change Password',
          url: '',
          onClick: showChangePasswordModal,
        },
        {
          label: externalRoutes.dailyNetworkTest.label,
          url: externalRoutes.dailyNetworkTest.url,
          external: true,
          icon: <NewTabIcon />,
          hideOnMobile: true,
        },
      ],
    },
  ];
};

export default parentNavItems;
