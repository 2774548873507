import { Box, Fade, LinearProgress, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import usePageReadAuditor from '@hoot/hooks/usePageReadAuditor';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { UserProfileType } from '@hoot/models/api/user';
import { ReaderContext, getNextPageId, getPreviousPageId, useReaders } from '@hoot/redux/reducers/readingSlice';
import useTeacherOutOfLessonReaderController from '@hoot/ui/pages/v2/teacher/library/reader/useTeacherOutOfLessonReaderController';
import TeacherReader from '@hoot/ui/pages/v2/teacher/reader/TeacherReader';
import TeacherSandboxReaderAppbar from './TeacherSandboxReaderAppBar';

export enum TeacherSandboxReaderPageQueryParams {
  PageId = 'pageId',
  DoublePage = 'doublePage',
}

const TeacherSandboxReaderPage = () => {
  usePageTitle('Reader | Hoot Reading');

  const { bookId: bookIdPath } = useParams() as { bookId: string };

  const { sandboxReader } = useReaders();
  const { book: loadedBook, pageIndex, pageZoom, isDoublePage } = sandboxReader;
  const loadedBookId = loadedBook?.id;

  const [searchParams, setSearchParams] = useSearchParams();
  const pageIdQuery = searchParams.get(TeacherSandboxReaderPageQueryParams.PageId);
  const isDoublePageQuery = searchParams.get(TeacherSandboxReaderPageQueryParams.DoublePage);

  // The loaded book in redux may not be the book that we want to open.
  // If this is the case, then show a loading indicator.
  const readerBook = bookIdPath === loadedBookId ? loadedBook : null;

  usePageReadAuditor({ book: readerBook ?? undefined, pageIndex, userProfileType: UserProfileType.Teacher });

  const controller = useTeacherOutOfLessonReaderController({
    readerContext: ReaderContext.SandboxReader,
    defaultPageId: pageIdQuery ?? undefined,
  });

  // If no book has been loaded, or if the loaded book's ID doesn't match the book ID in the current path,
  // then load the correct book.
  useEffect(() => {
    if (bookIdPath !== loadedBookId) {
      controller.onLoadBook(bookIdPath);
    } else if (pageIdQuery) {
      // Else if the book is already loaded, and a default page ID was specific, then flip to this page.
      controller.onGoToPageWithId(pageIdQuery);
    } else {
      // Else, flip to the first page.
      controller.onGoToFirstPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookIdPath, loadedBookId, pageIdQuery]);

  // Update double-page mode based on URL query.
  useEffect(() => {
    const isDoublePage = isDoublePageQuery === Boolean(true).toString();
    controller.onChangeDoublePageMode(isDoublePage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDoublePageQuery]);

  const onGoToNextPage = () => {
    const newPageId = getNextPageId(readerBook!, isDoublePage, pageIndex);
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set(TeacherSandboxReaderPageQueryParams.PageId, newPageId);
    setSearchParams(newSearchParams, { replace: true });
  };

  const onGoToPreviousPage = () => {
    const newPageId = getPreviousPageId(readerBook!, isDoublePage, pageIndex);
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set(TeacherSandboxReaderPageQueryParams.PageId, newPageId);
    setSearchParams(newSearchParams, { replace: true });
  };

  return (
    <Stack height="100%" padding={3}>
      {!readerBook && <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} />}
      <TeacherSandboxReaderAppbar />
      <Box flex={1} overflow="hidden">
        {readerBook && (
          <Fade in>
            <Box height="100%">
              <TeacherReader
                openedBook={readerBook}
                pageIndex={pageIndex}
                pageZoom={pageZoom}
                isDoublePage={isDoublePage}
                onGoToNextPage={onGoToNextPage}
                onGoToPreviousPage={onGoToPreviousPage}
              />
            </Box>
          </Fade>
        )}
      </Box>
    </Stack>
  );
};

export default TeacherSandboxReaderPage;
