import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

async function checkStudentInstructionalLibrary(studentProfileId: string): Promise<boolean> {
  const { data } = await axios.get<boolean>(`${config.apiUrl}/teacher/student-profile/v2/${studentProfileId}/check-instructional-library`);
  return data;
}

export default function useCheckStudentInstructionalLibrary(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<boolean>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetStudentInstructionalLibrary, studentProfileId], () => checkStudentInstructionalLibrary(studentProfileId), options);
}
