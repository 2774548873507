import axios, { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';

async function deleteInvoiceBillableTask(billableTaskId: string): Promise<AxiosResponse<void>> {
  return await axios.delete<void>(`${config.apiUrl}/public/teacher-invoicing/${billableTaskId}`);
}

export default function useDeleteInvoiceBillableTask() {
  return useMutation((billableTaskId: string) => {
    return deleteInvoiceBillableTask(billableTaskId);
  });
}
