import { Link } from '@mui/material';
import { Box } from '@mui/system';
import HootTypography from './core/HootTypography';

/**
 * Just an ease of use component to render the PP and TOS easier.
 * @returns 2 Formatted links for Privacy Policy and the Terms Of Service
 */
const PrivacyPolicyTerms = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: '4px',
      }}
    >
      <Link
        href="https://www.hootreading.com/privacy/"
        target="_blank"
        rel="noreferrer"
        variant="bodysmall"
        sx={{
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        Privacy Policy
      </Link>
      <HootTypography isPII={false} variant="bodysmall">
        &
      </HootTypography>
      <Link
        href="https://www.hootreading.com/terms/"
        target="_blank"
        rel="noreferrer"
        variant="bodysmall"
        sx={{
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        Terms of Service
      </Link>
    </Box>
  );
};

export default PrivacyPolicyTerms;
