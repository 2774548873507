import { Box } from '@mui/material';
import { Stack } from '@mui/system';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { StudentProfileSnippetResponse } from '@hoot/hooks/api/user/useGetStudentProfileSnippet';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { useStudentDetailsContext } from '@hoot/ui/pages/v2/teacher/my-students/student-details/StudentDetailsContextProvider';
import LaunchAssessmentButton from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/LaunchAssessmentButton';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import useGetRequiredTasksByStudent, {
  ActionRequiredKey,
  ActionRequiredTaskAction,
  ActionRequiredTaskResponse,
} from '../../../../../../../hooks/api/user-tasks/useGetRequiredTasksByStudent';
import ViewStateIllustration, { IllustrationEnum } from '../../../../../../components/v2/ViewStateIllustration';
import Bullet from '../../../../../../components/v2/icons/Bullet';
import LessonReviewWizardDialog from '../lesson-reviews/lesson-review-wizard/LessonReviewWizardDialog';

const ActionRequiredCard = () => {
  const { studentProfileSnippet } = useStudentDetailsContext();

  const studentTasks = useGetRequiredTasksByStudent(studentProfileSnippet.id);
  const queryClient = useQueryClient();

  const [lessonIdForReview, setLessonIdForReview] = useState<string>();

  function invalidateQueries() {
    // This is kinda janky, but we need to delay re-fetching these endpoints b/c the user tasks aren't updated
    // immediately (they're reactive), so we might see stale data. Adding this timeout "fixes" the problem albeit not
    // the cleanest solution.
    window.setTimeout(() => {
      queryClient.invalidateQueries(QueryKey.GetRequiredTasksByStudent);
    }, 500);
  }

  function handleDismiss() {
    setLessonIdForReview(undefined);
    invalidateQueries();
  }

  function handleShowCreateLessonReview(task: ActionRequiredTaskResponse) {
    setLessonIdForReview(task.lessonId);
  }

  function handleLessonReviewCompleted() {
    invalidateQueries();
  }

  const showFetchingState = studentTasks.isFetched && studentTasks.data && studentTasks.data.length === 0;

  return (
    <Card isLoading={studentTasks.isFetching} title="Action Required">
      <Stack spacing={2}>
        {showFetchingState ? (
          <ViewStateIllustration illustration={IllustrationEnum.Coffee} />
        ) : (
          studentTasks?.data?.map((task, idx) => (
            <ActionRequiredTask
              key={`${task.taskAt}-${idx}`}
              studentProfile={studentProfileSnippet}
              task={task}
              onShowCreateLessonReview={handleShowCreateLessonReview}
            />
          ))
        )}

        {lessonIdForReview ? (
          <LessonReviewWizardDialog show lessonIdToReview={lessonIdForReview} onCompleted={handleLessonReviewCompleted} onDismiss={handleDismiss} />
        ) : null}
      </Stack>
    </Card>
  );
};

function ActionRequiredTask(props: {
  task: ActionRequiredTaskResponse;
  studentProfile: StudentProfileSnippetResponse;
  onShowCreateLessonReview: (task: ActionRequiredTaskResponse) => void;
}) {
  function handleCreateLessonReview() {
    props.onShowCreateLessonReview(props.task);
  }

  function Action() {
    if (props.task.action === ActionRequiredTaskAction.CreateLessonReview) {
      return (
        <Button onClick={handleCreateLessonReview} variant="contained">
          Create
        </Button>
      );
    } else if (props.task.action === ActionRequiredTaskAction.GoToHootReadingAssessment) {
      const existingAssessment = props.task.meta?.find((t) => t.key === ActionRequiredKey.AssessmentId);
      return (
        <LaunchAssessmentButton assessmentIdToResume={existingAssessment?.val} studentProfileSnippet={props.studentProfile} startText={'Go To HRA'} />
      );
    }
    return null;
  }

  return (
    <Card sx={{ backgroundColor: hootTokens.palette.error[195] }}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap="16px">
        <Stack>
          <HootTypography isPII={false} variant="tableheadingactive">
            <Bullet sx={{ height: '8px', width: '8px', marginRight: '8px' }} color="error.80" /> {props.task.header}
          </HootTypography>
          <HootTypography isPII={true} mt="8px" variant="bodysmall">
            {props.task.comment}
          </HootTypography>
          <HootTypography isPII={false} mt="8px" variant="bodysmall">
            {DateTime.fromMillis(props.task.taskAt).toFormat('cccc, LLLL dd, yyyy @ h:mm a')}
          </HootTypography>
        </Stack>
        <Box>
          <Action />
        </Box>
      </Stack>
    </Card>
  );
}

export default ActionRequiredCard;
