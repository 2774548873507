import { Box, Step, StepIcon, StepLabel, Stepper, useMediaQuery, useTheme } from '@mui/material';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import Card from '@hoot/ui/components/v2/core/Card';
import { useAuth } from '@hoot/ui/context/AuthContext';
import FreeLessonCreateStudentProfile from '@hoot/ui/pages/v2/public/free-lesson/registration/steps/FreeLessonCreateStudentProfile';
import FreeLessonRegistrationEmailVerification from '@hoot/ui/pages/v2/public/free-lesson/registration/steps/FreeLessonRegistrationEmailVerification';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import FreeLessonConfirmation from './steps/FreeLessonConfirmation';
import FreeLessonCreatePassword from './steps/FreeLessonCreatePassword';
import FreeLessonCreateProfile from './steps/FreeLessonCreateProfile';
import FreeLessonSchedule from './steps/FreeLessonSchedule';

export enum FlfWizardStep {
  VerifyEmail = 1,
  CreatePassword,
  CreateProfile,
  CreateStudent,
  Schedule,
  Done,
}

const steps = [
  { id: FlfWizardStep.VerifyEmail, label: 'Verify Email', content: () => <FreeLessonRegistrationEmailVerification /> },
  { id: FlfWizardStep.CreatePassword, label: 'Create Password', content: () => <FreeLessonCreatePassword /> },
  { id: FlfWizardStep.CreateProfile, label: 'Create Profile', content: () => <FreeLessonCreateProfile /> },
  { id: FlfWizardStep.CreateStudent, label: 'Create Student', content: () => <FreeLessonCreateStudentProfile /> },
  { id: FlfWizardStep.Schedule, label: 'Schedule', content: () => <FreeLessonSchedule /> },
  { id: FlfWizardStep.Done, label: 'Done', content: () => <FreeLessonConfirmation /> },
];

const FreeLessonRegistrationWizard = () => {
  const { isAuthenticated, getUser } = useAuth();
  const user = useMemo(() => {
    if (isAuthenticated) {
      return getUser();
    }
  }, [getUser, isAuthenticated]);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const stepSearchParam = searchParams.get('step') ?? '1';

  const currentStep = useMemo(() => {
    // Ensure the step query is an actual number.
    const stepNumber = !isNaN(Number(stepSearchParam)) ? Number(stepSearchParam) : FlfWizardStep.VerifyEmail;

    // Ensure the step number is within bounds.
    const flfStep: FlfWizardStep = stepNumber <= FlfWizardStep.Done ? stepNumber : FlfWizardStep.VerifyEmail;

    return steps.find((x) => x.id === flfStep) ?? steps[0];
  }, [stepSearchParam]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    switch (currentStep.id) {
      case FlfWizardStep.CreatePassword:
        document.title = 'Create Password | Free Lesson | Hoot Reading';
        break;
      case FlfWizardStep.CreateStudent:
        document.title = 'Create Student | Free Lesson | Hoot Reading';
        break;
      case FlfWizardStep.CreateProfile:
        document.title = 'Create Profile | Free Lesson | Hoot Reading';
        break;
      case FlfWizardStep.Done:
        document.title = 'Confirmation | Free Lesson | Hoot Reading';
        break;
      case FlfWizardStep.Schedule:
        document.title = 'Schedule | Free Lesson | Hoot Reading';
        break;
      case FlfWizardStep.VerifyEmail:
        document.title = 'Verify Email | Free Lesson | Hoot Reading';
        break;
    }
  }, [currentStep]);

  // Redirect handler.
  useEffect(() => {
    // All steps after the "Create Password" step are secure routes. If an unauthenticated user tries to access these
    // steps, then redirect back to the login page.
    if (!isAuthenticated && currentStep.id > FlfWizardStep.CreatePassword) {
      navigate(routesDictionary.home.url);
    } else if (isAuthenticated && currentStep.id <= FlfWizardStep.CreatePassword) {
      // Else if you're authenticated and trying to access the steps where we would create a new account, then we'll \
      // also want to bounce outta here.
      const hasUserCompletedProfileStep = !!user?.country && !!user?.provinceState && user?.provinceState?.length > 0;

      navigate({
        pathname: routesDictionary.freeLesson.registration.url,
        search: createSearchParams({
          step: !hasUserCompletedProfileStep
            ? // If the user has not completed the "Create Profile" step, then redirect to that step.
              FlfWizardStep.CreateProfile.toString()
            : // Else, navigate to the "Create Student" step.
              FlfWizardStep.CreateStudent.toString(),
        }).toString(),
      });
    }
  }, [isAuthenticated, user, currentStep.id, navigate]);

  return (
    <>
      <Root>
        <Stepper alternativeLabel activeStep={currentStep.id - 1} sx={{ pt: 3, pb: 3, mb: 2 }} orientation={isMobile ? 'vertical' : 'horizontal'}>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel
                StepIconComponent={(props) => (
                  <StepIcon
                    {...props}
                    // Basically overriding the default "checkmark" functionality on completed steps. Our designs don't use them.
                    icon={props.icon}
                    active={props.active || props.completed}
                    completed={false}
                  />
                )}
              >
                {step.label}
              </StepLabel>
              {/* Render content between steps on mobile. */}
              {isMobile && (
                <>
                  {/* Only render the active step. */}
                  {step.id === currentStep.id ? (
                    <Card sx={{ mt: 2, mb: 2 }}>{step.content()}</Card>
                  ) : // Just render the connector for inactive steps (minus the very last step; don't show the connector in that case).
                  step.id !== FlfWizardStep.Done ? (
                    <VerticalConnector />
                  ) : null}
                </>
              )}
            </Step>
          ))}
        </Stepper>
        {!isMobile && currentStep.content()}
      </Root>
    </>
  );
};

const Root = (props: PropsWithChildren<any>) => {
  const { children } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return <>{isMobile ? children : <Card sx={{ maxWidth: '848px' }} contentSx={{ padding: '32px' }} children={children} />}</>;
};

const VerticalConnector = () => {
  return (
    <Box
      sx={(theme) => ({
        width: '1px',
        height: '38px',
        m: theme.spacing(1, '22px', 1, '22px'),
        background: hootTokens.palette.neutral['120'],
      })}
    ></Box>
  );
};

export default FreeLessonRegistrationWizard;
