import { useDaily, useDevices } from '@daily-co/daily-react';
import { Link, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getAudioVideoSupportLink } from '@hoot/utils/daily/avSupportLink';
import { useAudioVideoSettingsDialog } from '../../../../../context/AudioVideoSettingsDialogContext';
import { useGetReadyV2Dialog } from '../../../../../context/GetReadyV2DialogProvider';
import { hootTokens } from '../../../../../theme/v2/tokens';
import BasicAlertDialog from '../../BasicAlertDialog';

function DailyDevicesNotFound() {
  const { dismiss } = useGetReadyV2Dialog();
  const { onDismissStudent, onDismiss } = useAudioVideoSettingsDialog();

  const [show, setShow] = useState(false);

  const dailyCallObj = useDaily();
  const { cameras, microphones } = useDevices();

  const handleClose = () => {
    onDismissStudent();
    dismiss();
    onDismiss();
  };

  // Kind of hacky, but there's a transition between granting access to devices and loading up available devices which doesn't populate the array
  // immediately resulting in this dialog showing for a brief second
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 2000);
  }, []);

  useEffect(() => {
    if (dailyCallObj) {
      dailyCallObj.setLocalAudio(false);
      dailyCallObj.setLocalVideo(false);
    }
  }, [dailyCallObj]);

  const handleRefresh = () => {
    window.location.reload();
  };

  if (!show) {
    return null;
  }

  return (
    <BasicAlertDialog
      title={'Device Not Detected'}
      show={show}
      onDismiss={handleClose}
      maxWidth="md"
      content={
        <Stack>
          <Typography variant="bodylarge" sx={{ color: hootTokens.palette.error[80] }}>
            We were unable to detect one or more required devices:
          </Typography>
          {cameras.length === 0 ? <Typography sx={{ marginTop: '16px' }}>📷 Camera – ❌ Not Detected</Typography> : null}
          {microphones.length === 0 ? <Typography sx={{ marginTop: '16px' }}>🎤 Microphone – ❌ Not Detected </Typography> : null}
          <Typography sx={{ marginTop: '16px' }} variant="bodylarge">
            Please check your device connections. If your devices are properly connected, your computer privacy settings may be blocking the device.
            Check the camera and microphone permissions under System Settings -&gt; Privacy.
            <Link target="_blank" href={getAudioVideoSupportLink()} sx={{ marginLeft: '5px' }}>
              Learn More
            </Link>
          </Typography>
          <Typography sx={{ marginTop: '16px' }} variant="bodylarge">
            Need more help? Contact <Link href="mailto:help@hootreading.com">help@hootreading.com</Link>
          </Typography>
        </Stack>
      }
      primaryAction={{
        label: 'Refresh',
        onClick: handleRefresh,
        props: { variant: 'contained' },
      }}
    />
  );
}

export default DailyDevicesNotFound;
