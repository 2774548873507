import { EventType } from '@hoot/events/eventType';
import { ScheduledLesson } from '@hoot/events/interfaces/scheduled-lesson';
import { LeaveLessonMessage } from '@hoot/events/messages/leave-lesson.message';
import { RefreshLessonRequestMessage } from '@hoot/events/messages/refresh-lesson-request.message';
import { useEmit } from '@hoot/hooks/useEmit';
import { useProfileState } from '@hoot/redux/reducers/profileSlice';

interface UseTeacherLessonControllerProps {
  activeLesson: ScheduledLesson;
}

const useTeacherLessonController = (props: UseTeacherLessonControllerProps) => {
  const { activeLesson } = props;

  const { profile } = useProfileState();

  const refreshLessonRequestStateEmitter = useEmit<RefreshLessonRequestMessage>(EventType.RefreshLessonRequest, undefined, { enabled: false });
  const leaveLessonEmitter = useEmit<LeaveLessonMessage | undefined>(EventType.LeaveLesson, undefined, { enabled: false });

  const refreshLessonState = () => {
    refreshLessonRequestStateEmitter.queueMessage({
      lessonId: activeLesson.lessonId,
    });
  };

  const leaveLesson = () => {
    leaveLessonEmitter.emit({
      lessonId: activeLesson.lessonId,
      profileId: profile!.id,
      profileName: profile!.name,
      forcedDisconnect: false,
    });
  };

  return {
    refreshLessonState,
    leaveLesson,
  };
};

export default useTeacherLessonController;
