import { SxProps } from '@mui/system';
import { ChangeEvent } from 'react';
import { ResourceReader, resourceReaderLabel } from '../../../../models/api/enums/lesson-review-enums';
import { Select } from '../core/Select';

export function ReaderSelect(props: { sx: SxProps; value?: ResourceReader; onChange: (value: ResourceReader) => void; error?: boolean }) {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    props.onChange(event.target.value as ResourceReader);
  };

  return (
    <Select error={props.error} sx={props.sx} required label="Reader" value={props.value} onChange={handleChange}>
      <option value=""></option>
      {Object.entries(resourceReaderLabel).map((l) => (
        <option key={l[0]} value={l[0]}>
          {l[1]}
        </option>
      ))}
    </Select>
  );
}
