import { Outlet } from 'react-router-dom';
import { ToolsContextProvider } from '@hoot/ui/context/ToolsContext';

const TeacherLibraryProviderLayout = () => {
  return (
    <ToolsContextProvider>
      <Outlet />
    </ToolsContextProvider>
  );
};

export default TeacherLibraryProviderLayout;
