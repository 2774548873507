import { Stack } from '@mui/system';
import { DateTime } from 'luxon';
import React from 'react';
import { AssessmentActionResponse } from '@hoot/hooks/api/my-student/useGetAssessmentActions';
import { StudentProfileSnippetResponse } from '@hoot/hooks/api/user/useGetStudentProfileSnippet';
import Card from '@hoot/ui/components/v2/core/Card';
import AssessmentActions from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/action-required-card/AssessmentActionsMenu';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import HootTypography from '../../../../../../../components/v2/core/HootTypography';

const AssessmentActionCard = (props: { action: AssessmentActionResponse; studentProfileSnippet: StudentProfileSnippetResponse }) => {
  const when = DateTime.fromMillis(props.action.when).toFormat('LLL d, yyyy');

  return (
    <Card sx={{ backgroundColor: hootTokens.palette.error[195] }}>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <HootTypography isPII={true} variant="tableheadingactive">
            {props.action.header}
          </HootTypography>
          <HootTypography isPII={true} variant="bodysmall">
            {props.action.who}
          </HootTypography>
          <HootTypography isPII={false} variant="bodysmall">
            {when}
          </HootTypography>
        </Stack>
        <Stack direction="row" gap={2}>
          <AssessmentActions action={props.action} studentProfileSnippet={props.studentProfileSnippet} />
        </Stack>
      </Stack>
    </Card>
  );
};

export default AssessmentActionCard;
