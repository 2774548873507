import { SvgIcon, SvgIconProps } from '@mui/material';

const ShowIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.86971 14.1469L2.32608 14.6636L2.86971 14.1469ZM2.86971 9.8531L2.32608 9.33641L2.86971 9.8531ZM21.1303 14.1469L21.6739 14.6636L21.1303 14.1469ZM21.1303 9.8531L21.6739 9.33641L21.1303 9.8531ZM20.5867 13.6302C18.6467 15.6713 15.48 18.25 12 18.25V19.75C16.151 19.75 19.7023 16.738 21.6739 14.6636L20.5867 13.6302ZM12 18.25C8.51999 18.25 5.35328 15.6713 3.41334 13.6302L2.32608 14.6636C4.2977 16.738 7.84898 19.75 12 19.75V18.25ZM3.41334 10.3698C5.35328 8.32869 8.51999 5.75 12 5.75V4.25C7.84898 4.25 4.2977 7.26198 2.32608 9.33641L3.41334 10.3698ZM12 5.75C15.48 5.75 18.6467 8.32869 20.5867 10.3698L21.6739 9.33641C19.7023 7.26198 16.151 4.25 12 4.25V5.75ZM3.41334 13.6302C2.52889 12.6996 2.52889 11.3004 3.41334 10.3698L2.32608 9.33641C0.891308 10.846 0.891308 13.154 2.32608 14.6636L3.41334 13.6302ZM21.6739 14.6636C23.1087 13.154 23.1087 10.846 21.6739 9.33641L20.5867 10.3698C21.4711 11.3004 21.4711 12.6996 20.5867 13.6302L21.6739 14.6636ZM14.25 12C14.25 13.2426 13.2426 14.25 12 14.25V15.75C14.0711 15.75 15.75 14.0711 15.75 12H14.25ZM12 14.25C10.7574 14.25 9.75 13.2426 9.75 12H8.25C8.25 14.0711 9.92893 15.75 12 15.75V14.25ZM9.75 12C9.75 10.7574 10.7574 9.75 12 9.75V8.25C9.92893 8.25 8.25 9.92893 8.25 12H9.75ZM12 9.75C13.2426 9.75 14.25 10.7574 14.25 12H15.75C15.75 9.92893 14.0711 8.25 12 8.25V9.75Z"
        fill={props.htmlColor ?? 'currentColor'}
      />
    </SvgIcon>
  );
};

export default ShowIcon;
