import { Fade, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { capitalCase } from 'change-case';
import { DateTime } from 'luxon';
import { DistrictRepGetProductResponse } from '@hoot/hooks/api/district-rep/useDistrictRepresentativeProduct';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import Card from '@hoot/ui/components/v2/core/Card';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';

interface Props {
  product: DistrictRepGetProductResponse | undefined;
  isLoading: boolean;
}

const ProductDetailsCard = (props: Props) => {
  const { product, isLoading } = props;

  const getViewState = (): ViewStateEnum => {
    if (isLoading) {
      return ViewStateEnum.Loading;
    } else if (product) {
      return ViewStateEnum.Results;
    } else {
      // If not loading, and we don't have a product, then this is an invalid state.
      return ViewStateEnum.Error;
    }
  };

  const emptyLabel = '---';

  return (
    <Card title="Details" isLoading={isLoading}>
      <ViewState state={getViewState()} loadingContent={<SkeletonContent />}>
        <Fade in>
          <Grid container spacing={2}>
            <Grid size={12}>
              <ReadOnlyTextField label="Status" body={capitalCase(product?.status ?? emptyLabel)} />
            </Grid>
            <Grid size={12}>
              <ReadOnlyTextField
                label="Start Date"
                body={product?.startDate ? (DateTime.fromMillis(product?.startDate).toFormat('LL/dd/yyyy') ?? emptyLabel) : emptyLabel}
              />
            </Grid>
            <Grid size={12}>
              <ReadOnlyTextField
                label="End Date"
                body={product?.endDate ? (DateTime.fromMillis(product?.startDate).toFormat('LL/dd/yyyy') ?? emptyLabel) : emptyLabel}
              />
            </Grid>
            <Grid size={12}>
              <ReadOnlyTextField label="Product Size" body={product?.numberOfStudentsAllowed?.toString() ?? emptyLabel} />
            </Grid>
            <Grid size={12}>
              <ReadOnlyTextField label="Registered Students" body={product?.numberOfStudentsRegistered?.toString() ?? emptyLabel} />
            </Grid>
          </Grid>
        </Fade>
      </ViewState>
    </Card>
  );
};

const SkeletonContent = () => {
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
    </Grid>
  );
};

export default ProductDetailsCard;
