export enum TeacherInvoiceStatus {
  Submitted = 'SUBMITTED',
  NotSubmitted = 'NOT_SUBMITTED',
  Resubmitted = 'RESUBMITTED',
  Retracted = 'RETRACTED',
  ServicesAdded = 'SERVICES_ADDED',
}

export enum InvoiceProjectId {
  OpenSeededTime = '22e0c594-a822-4083-9f0b-a0185ac86536',
  ProfessionalDevelopmentOrientation = 'e1a154a7-4c02-4d3d-a6d7-2751ee00251d',
  ProfessionalDevelopmentMathOrientation = '83c39373-be96-4def-bdb7-bea0a0574a4e',
  ProfessionalDevelopmentAntiOppression = '9833f067-d137-47b0-a6f6-ce1d32aab760',
  ProfessionalDevelopmentTraumaInformed = '2bf84a73-afc7-46f9-932e-e8aa16b6a1b5',
  ProfessionalDevelopmentSORPart1 = '0ed97e04-4885-4435-8af5-27f250b22d89',
  ProfessionalDevelopmentSORPart2 = '762d4a5f-a08d-4b77-bb02-8cd26a3898be',
  ProfessionalDevelopmentAssessmentGuide = 'cc3540ec-239a-40ac-b09e-f32946e0a765',
  ProfessionalDevelopmentPreReadingSkills = '4eedb509-2333-47c4-8920-7e06d160c37d',
  ProfessionalDevelopmentSummer2023Changes = 'cf1175de-5380-4674-a50b-e030a0f0ff36',
  ProfessionalDevelopmentLibraryWalkthrough = '1f0817be-fa63-41eb-9a42-1e33ced2b72a',
  ApprovedAdminTime = 'af8e15bc-87e9-4e23-8935-4af00518d069',
}

export const professionalDevelopmentProjects = [
  InvoiceProjectId.ProfessionalDevelopmentOrientation,
  InvoiceProjectId.ProfessionalDevelopmentMathOrientation,
  InvoiceProjectId.ProfessionalDevelopmentAntiOppression,
  InvoiceProjectId.ProfessionalDevelopmentTraumaInformed,
  InvoiceProjectId.ProfessionalDevelopmentSORPart1,
  InvoiceProjectId.ProfessionalDevelopmentSORPart2,
  InvoiceProjectId.ProfessionalDevelopmentAssessmentGuide,
  InvoiceProjectId.ProfessionalDevelopmentPreReadingSkills,
  InvoiceProjectId.ProfessionalDevelopmentSummer2023Changes,
  InvoiceProjectId.ProfessionalDevelopmentLibraryWalkthrough,
];

export enum InvoicePeriodStatus {
  InProgress = 'IN_PROGRESS',
  Submitted = 'SUBMITTED',
}

export enum InvoiceLineItemStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}
