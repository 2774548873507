import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { BookResponse } from '@hoot/events/interfaces/book-response';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { UseGetOptions } from '@hoot/models/api/axios-options';

const useStudentLibraryGetBook = (bookId: string, options?: UseGetOptions<BookResponse>) => {
  return useQuery(
    [QueryKey.GetStudentBook, bookId],
    async () => {
      return (await axios.get<BookResponse>(`${config.apiUrl}/student-library/book/${bookId}`)).data;
    },
    options,
  );
};

export default useStudentLibraryGetBook;
