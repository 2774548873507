import { Box, Stack, Typography } from '@mui/material';
import { Questionnaire } from './units/UNIT_4_2';

export const questionnaires: Questionnaire[] = [
  {
    title: 'Phrasing',
    options: [
      {
        id: '1',
        text: 'Reads word-by-word, or in short phrases, but these are infrequent and/or are not words you would cluster together in line with good syntax.',
      },
      {
        id: '2',
        text: 'Reads in two-word phrases with some three- or four-word phrases. Some word-by-word reading still occurs. Word groupings may be awkward and unrelated to the larger context of the sentence.',
      },
      {
        id: '3',
        text: 'Reads mainly in three- or four-word phrases. Syntax is attended to when possible (pausing for periods at the end of sentences, etc.)',
      },
      { id: '4', text: 'Reads mainly in longer phrases, in line with the different punctuation present in the text.' },
    ],
  },
  {
    title: 'Expression',
    options: [
      {
        id: '1',
        text: 'Expressive interpretation of the text is not yet evident. The student does not change their voice, tone and overall prosody in line with the meaning of the words they are reading.',
      },
      {
        id: '2',
        text: 'Reads in two-word phrases with some three- or four-word phrases. Some word-by-word reading still occurs. Word groupings may be awkward and unrelated to the larger context of the sentence.',
      },
      {
        id: '3',
        text: 'Reads mainly in three- or four-word phrases. Syntax is attended to when possible (pausing for periods at the end of sentences, etc.)',
      },
      { id: '4', text: 'Reads mainly in longer phrases, in line with the different punctuation present in the text.' },
    ],
  },
];

export const questionnaires2: Questionnaire[] = [
  {
    title: 'Text Specific Knowledge',
    ruberic: (
      <Stack>
        <Typography variant="titlesmall">Where is the family going at the beginning of the story?</Typography>
      </Stack>
    ),

    options: [
      {
        id: '1',
        text: 'Provides incorrect or extremely basic information, doesn’t return to the text to get better information when prompted, or identifies irrelevant information.',
      },
      {
        id: '2',
        text: 'Partial information provided, may return to text for more info when prompted or rely on memory. More precise information was available to report, but the student did not do so',
      },
      {
        id: '3',
        text: 'Specific information provided, including names and other relevant details, returns to text as required to gather or confirm precise information.',
      },
    ],
  },
  {
    title: 'Text Structure',
    ruberic: (
      <Stack spacing="16px">
        <Typography variant="titlesmall">What is the problem in this story?</Typography>
        <Typography variant="titlesmall">How has the problem affected Jasmin’s family specifically so far?</Typography>
      </Stack>
    ),

    options: [
      {
        id: '1',
        text: 'Is unable to discuss genre and text-specific features, elements and overall structure. Is unclear on the likely purpose for reading this text or how the text’s organization supports that purpose.',
      },
      {
        id: '2',
        text: 'Demonstrates partial understanding of genre and text-specific features, elements and overall structure. Can provide a basic purpose for reading, but may struggle to connect how the organization of the text supports their understanding and reading experience.',
      },
      {
        id: '3',
        text: 'Demonstrates understanding of the way the text is organized and their purpose for reading. Can correctly identify and utilize relevant text features or genre-based elements in the text to build comprehension.',
      },
    ],
  },
  {
    title: 'Sentence Analysis',
    ruberic: (
      <Stack spacing="16px">
        <Box>
          <Typography sx={{ fontWeight: 400 }} variant="titlesmall">
            Let’s re-read this sentence (from{' '}
            <Box component="span" sx={{ fontWeight: 700 }}>
              page 2
            </Box>{' '}
            of the story):
          </Typography>
          <Typography sx={{ fontWeight: 400 }} variant="titlesmall">
            “All that remained were blackened stumps and trunks and an occasional tree that had somehow survived the forest fire.”
          </Typography>
        </Box>
        <Typography variant="titlesmall">How bad was the fire? What words in this sentence helped you come up with your answer?</Typography>
      </Stack>
    ),

    options: [
      {
        id: '1',
        text: 'Could not answer sentence-level question or displays confusion over pronouns, direction words or other sentence features.',
      },
      {
        id: '2',
        text: 'Has difficulty in answering the sentence-level question, or demonstrates partial understanding of the focus sentence.',
      },
      {
        id: '3',
        text: 'Answered question correctly, showing command of syntax and sentence comprehension.',
      },
    ],
  },

  {
    title: 'Verbal Reasoning',
    ruberic: (
      <Stack spacing="16px">
        <Typography variant="titlesmall">
          How would you describe the mix of feelings that Jasmin and her family are experiencing as they drive to check on their home?
        </Typography>
        <Typography variant="titlesmall">What emotions are they likely feeling in this moment?</Typography>
      </Stack>
    ),

    options: [
      {
        id: '1',
        text: 'Little to no evidence of verbal reasoning, unable to connect text information and events to logical predictions, inferences, or implications that are not stated in the text explicitly, even with prompting.',
      },
      {
        id: '2',
        text: 'Basic verbal reasoning skills demonstrated through connecting text evidence to reasonable or logical inferences, but may be unable to explain their thinking or require a good amount of scaffolding to answer this question.',
      },
      {
        id: '3',
        text: 'Makes reasonable predictions or inferences and is able to explain what in the text informed their thinking. Demonstrates metacognition, or monitoring their own thinking.',
      },
    ],
  },

  {
    title: 'Vocabulary (Overall)',
    subTitle: 'Consider the student’s use of vocabulary holistically.',

    options: [
      {
        id: '1',
        text: 'Uses general words, does not provide specific vocabulary provided in the text. Words used may reflect some misunderstanding of meaning of words in the text. Example from a book about kangaroos: The mom kangaroo carries the baby in her stomach.',
      },
      {
        id: '2',
        text: 'Uses some specific vocabulary words from the text correctly and/or uses synonyms or other words that show they have grasped the meaning of text-specific vocabulary words. Example from a book about kangaroos: The mom kangaroo carries the baby kangaroo (what was it called?) in her sack.',
      },
      {
        id: '3',
        text: 'Uses names and/or other specific vocabulary words as used in the text and uses them correctly to show meaning has been grasped. Example from a book about kangaroos: The mother kangaroo carries the joey in her pouch.',
      },
    ],
  },
];
