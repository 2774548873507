import { SvgIcon, SvgIconProps } from '@mui/material';

const WhiteboardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M4 11H5C6.10457 11 7 10.1046 7 9V8C7 6.89543 7.89543 6 9 6C10.1046 6 11 6.89543 11 8V14C11 15.1046 11.8954 16 13 16C14.1046 16 15 15.1046 15 14V13C15 11.8954 15.8954 11 17 11H18M5 21H17C19.2091 21 21 19.2091 21 17V5C21 2.79086 19.2091 1 17 1H5C2.79086 1 1 2.79086 1 5V17C1 19.2091 2.79086 21 5 21Z"
        stroke={props.htmlColor ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default WhiteboardIcon;
