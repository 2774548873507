import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import { QueryKey } from '../../../../../../../hooks/api/queryKeys';
import LessonReviewWizardDialog from './lesson-review-wizard/LessonReviewWizardDialog';
import LessonReviewHistory from './reviews-tab/LessonReviewHistory';
import LessonReviewTasks from './reviews-tab/LessonReviewTasks';
import RecentReviews from './reviews-tab/RecentReviews';

const LessonReviewsPage = () => {
  const [lessonIdToReview, setLessonIdToReview] = useState<string>();

  const queryClient = useQueryClient();
  const { state: navigationState } = useLocation();

  // If a Lesson Id was provided via navigation options.state,
  // then automatically open the Post Lesson Review task for the appropriate lessonId.
  useEffect(() => {
    if (navigationState && navigationState.lessonId) {
      onStartLessonReview(navigationState.lessonId);
      // Important to keep - need to clear the navigation state upon 1st render to prevent it from opening the dialog on page reload.
      window.history.replaceState(null, '');
    }
  }, [navigationState]);

  function invalidateQueries() {
    // This is kinda janky, but we need to delay re-fetching these endpoints b/c the user tasks aren't updated in the
    // immediately (they're reactive), so we might see stale data. Adding this timeout "fixes" the problem albeit not
    // the cleanest solution.
    window.setTimeout(() => {
      queryClient.invalidateQueries(QueryKey.GetRecentReviews);
      queryClient.invalidateQueries(QueryKey.GetReviewHistory);
      queryClient.invalidateQueries(QueryKey.GetActiveReviewTasks);
    }, 500);
  }

  const onDismissLessonReviewDialog = () => {
    setLessonIdToReview(undefined);
    invalidateQueries();
  };

  const onStartLessonReview = (lessonId: string) => {
    setLessonIdToReview(lessonId);
  };

  const onEditLessonReview = (lessonId: string) => {
    setLessonIdToReview(lessonId);
  };

  const onReviewSubmitted = () => {
    invalidateQueries();
  };

  return (
    <PageLayout>
      <Stack gap={2}>
        <LessonReviewTasks onStartLessonReview={onStartLessonReview} />
        <RecentReviews onEditLessonReview={onEditLessonReview} />
        <LessonReviewHistory />
      </Stack>
      <LessonReviewWizardDialog
        show={!!lessonIdToReview}
        lessonIdToReview={lessonIdToReview}
        onCompleted={onReviewSubmitted}
        onDismiss={onDismissLessonReviewDialog}
      />
    </PageLayout>
  );
};

export default LessonReviewsPage;
