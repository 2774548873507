import { SvgIcon, SvgIconProps } from '@mui/material';

const LessonIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2C3.79086 2 2 3.79086 2 6V15C2 17.2091 3.79086 19 6 19H11.25V21.25H9C8.58579 21.25 8.25 21.5858 8.25 22C8.25 22.4142 8.58579 22.75 9 22.75H15C15.4142 22.75 15.75 22.4142 15.75 22C15.75 21.5858 15.4142 21.25 15 21.25H12.75V19H18C20.2091 19 22 17.2091 22 15V6C22 3.79086 20.2091 2 18 2H6ZM12 6C13.1046 6 14 6.89543 14 8C14 9.10457 13.1046 10 12 10C10.8954 10 10 9.10457 10 8C10 6.89543 10.8954 6 12 6ZM8 13.5C8 12.6716 8.67157 12 9.5 12H14.5C15.3284 12 16 12.6716 16 13.5C16 14.3284 15.3284 15 14.5 15H9.5C8.67157 15 8 14.3284 8 13.5Z"
      fill={props.htmlColor ?? 'currentColor'}
    />
  </SvgIcon>
);

export default LessonIcon;
