import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { StepTestAnswer } from '@hoot/models/api/enums/hoot-reading-assessment';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import CheckmarkSquare from '@hoot/ui/components/v2/icons/CheckmarkSquare';
import CloseFilledSquareIcon from '@hoot/ui/components/v2/icons/CloseFilledSquareIcon';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import UnitCompleteCard from '../UnitCompleteCard';
import { EditAnswerDialog } from '../dialogs/EditAnswerDialog';
import { Step } from './Step';

interface IStepTestProps {
  prompt: string;
  allowedValues: string[];
  answers: IStepAnswer[];
  setAnswers: React.Dispatch<React.SetStateAction<IStepAnswer[]>>;
  onSubmit?: (answers: IStepAnswer[]) => void;
}

export interface IStepAnswer {
  value: string;
  answer: StepTestAnswer;
}

const StepTest = (props: IStepTestProps) => {
  const { prompt, allowedValues, answers, setAnswers } = props;

  const [currentValue, setCurrentValue] = useState<string>(allowedValues[0]);
  const [showEditAnswerDialog, setShowEditAnswerDialog] = useState<boolean>(false);

  const showAmount = allowedValues.length;
  const selectedIndex = allowedValues.indexOf(currentValue);

  const unitReadyForSubmission = answers.every((a) => !!a.answer);

  const handleSelectedValueChanged = (newIndex: number) => {
    const value = allowedValues[newIndex];
    setCurrentValue(value);
    if (unitReadyForSubmission) {
      setShowEditAnswerDialog(true);
    }
  };

  const handleAnswerSelect = (value: string, answer: StepTestAnswer) => {
    const indexOfValue = allowedValues.indexOf(value);
    const nextIndex = indexOfValue + 1;

    const updatedAnswers = answers.map((a) => {
      if (a.value === value) {
        return { value: a.value, answer: answer };
      } else {
        return a;
      }
    });
    setAnswers(updatedAnswers);

    if (nextIndex < showAmount) {
      setCurrentValue(allowedValues[indexOfValue + 1]);
    } else {
      setCurrentValue('');
    }
  };

  const handleCancelEdit = () => {
    setCurrentValue('');
    setShowEditAnswerDialog(false);
  };

  const handleEditAnswer = (newAnswer: StepTestAnswer) => {
    const updatedAnswers = answers.map((a) => {
      if (a.value === currentValue) {
        return { value: a.value, answer: newAnswer };
      } else {
        return a;
      }
    });
    setAnswers(updatedAnswers);
    handleCancelEdit();
  };

  // Split the Questions into 2 chunks for the UI
  const allowedValuesMax = Math.floor(allowedValues.length / 2);
  const [firstChunk, secondChunk] = [allowedValues.slice(0, allowedValuesMax), allowedValues.slice(allowedValuesMax, allowedValues.length)];

  return (
    <>
      <Stack gap={4} paddingY={3}>
        <Stack
          sx={{
            gap: '16px',
            border: '1px solid black',
            borderRadius: '8px',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: '24px',
          }}
        >
          {unitReadyForSubmission ? (
            <UnitCompleteCard hasBeenSubmitted={false} />
          ) : (
            <>
              <Stack
                sx={{
                  borderRadius: '8px',
                  background: hootTokens.surface[2],
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingY: '16px',
                  width: '100%',
                }}
              >
                <HootTypography isPII={false} variant="bodymedium">
                  {prompt}
                </HootTypography>
                <HootTypography isPII={false} variant="displaymedium">
                  {currentValue}
                </HootTypography>
              </Stack>
              <Stack flexDirection="row" gap={2}>
                <Button
                  onClick={() => handleAnswerSelect(currentValue, StepTestAnswer.Wrong)}
                  variant="contained"
                  color="error.190"
                  disableElevation
                  startIcon={<CloseFilledSquareIcon />}
                >
                  Incorrect
                </Button>
                <Button
                  onClick={() => handleAnswerSelect(currentValue, StepTestAnswer.Correct)}
                  variant="contained"
                  color="success.190"
                  disableElevation
                  startIcon={<CheckmarkSquare />}
                >
                  Correct
                </Button>
              </Stack>
            </>
          )}
        </Stack>
        <Stack gap={2}>
          <HootTypography isPII={false} variant="tableheadingactive">
            Scorecard
          </HootTypography>
          <Stack gap={2}>
            <Stack direction="row" sx={{ justifyContent: 'space-evenly' }}>
              {firstChunk.map((val, idx) => (
                <Step
                  key={val}
                  currentIndex={idx}
                  selectedIndex={selectedIndex}
                  onSelectedIndexChanged={handleSelectedValueChanged}
                  currentValue={val}
                  currentAnswer={answers[idx].answer}
                  answers={answers.map((a) => a.answer)}
                  stepLength={showAmount}
                />
              ))}
            </Stack>
            {showAmount > allowedValuesMax && (
              <Stack direction="row" sx={{ justifyContent: 'space-evenly' }}>
                {secondChunk.map((val, idx) => (
                  <Step
                    key={val}
                    currentIndex={idx + firstChunk.length}
                    selectedIndex={selectedIndex}
                    onSelectedIndexChanged={handleSelectedValueChanged}
                    currentValue={val}
                    currentAnswer={answers[idx + firstChunk.length].answer}
                    answers={answers.map((a) => a.answer)}
                    stepLength={showAmount}
                  />
                ))}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      <EditAnswerDialog
        open={showEditAnswerDialog}
        questionLabel={currentValue}
        defaultAnswer={StepTestAnswer.Correct}
        onSubmit={handleEditAnswer}
        onCancel={handleCancelEdit}
      />
    </>
  );
};

export default StepTest;
