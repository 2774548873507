import { DailyVideo, useAudioTrack, useParticipantIds, useVideoTrack } from '@daily-co/daily-react';
import { Box, Stack } from '@mui/material';
import { STUDENT_VIDEO_HEIGHT, STUDENT_VIDEO_WIDTH } from '@hoot/constants/daily/videoDimensions';
import Lottie, { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import DailyAudioVisualizer from '../video/DailyAudioVisualizer';

const RemoteTeacherTile = () => {
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });
  const remoteSessionId = remoteParticipantIds.length > 0 ? remoteParticipantIds[0] : null;
  const remoteVideoTrack = useVideoTrack(remoteSessionId ?? '');
  const remoteAudioTrack = useAudioTrack(remoteSessionId ?? '');

  const isVideoEnabled = !remoteVideoTrack.isOff;

  return (
    <Box
      sx={{
        width: '100%',
        height: '178px',
        backgroundColor: remoteVideoTrack.isOff ? hootTokens.surface['1'] : hootTokens.palette.black,
        padding: 2,
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...hootTokens.elevation.elevation1,
      }}
    >
      <Stack sx={{ position: 'relative' }}>
        {remoteSessionId && isVideoEnabled ? (
          <DailyVideo
            sessionId={remoteSessionId}
            type={'video'}
            width={STUDENT_VIDEO_WIDTH}
            height={STUDENT_VIDEO_HEIGHT}
            fit="cover"
            playableStyle={{
              borderRadius: '8px',
              transform: 'scale(-1, 1)',
            }}
          />
        ) : null}

        {remoteVideoTrack.isOff && (
          <Lottie
            lottieFile={LottieFile.Camera}
            loop={false}
            play={true}
            style={{
              width: '100px',
              height: '100px',
            }}
          />
        )}

        <Box position="absolute" top={8} right={8}>
          {isVideoEnabled && <DailyAudioVisualizer audioTrack={remoteAudioTrack} />}
        </Box>
      </Stack>
    </Box>
  );
};

export default RemoteTeacherTile;
