import { Stack } from '@mui/system';
import { useEffect } from 'react';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface TeacherReaderBookControlProps {
  onPrevPage: () => void;
  onNextPage: () => void;
  currentPage: number;
  totalPages: number;
}

export const TeacherReaderBookControls = (props: TeacherReaderBookControlProps) => {
  const { onPrevPage, onNextPage, currentPage, totalPages } = props;

  useEffect(() => {
    const handleKeyPress = ({ key }: KeyboardEvent) => {
      switch (key) {
        case 'ArrowLeft':
          onPrevPage();
          break;
        case 'ArrowRight':
          onNextPage();
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [onNextPage, onPrevPage, props]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <IconButton variant="contained" onClick={onPrevPage}>
        <Icon htmlColor={hootTokens.palette.white} name="chevron" />
      </IconButton>

      <HootTypography variant="bodysmall" isPII={false}>
        {currentPage}/{totalPages}
      </HootTypography>

      <IconButton variant="contained" onClick={onNextPage}>
        <Icon htmlColor={hootTokens.palette.white} name="chevron" sx={{ rotate: '180deg' }} />
      </IconButton>
    </Stack>
  );
};
