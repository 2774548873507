import { SvgIcon, SvgIconProps, SxProps, Theme } from '@mui/material';

const UndoRedo = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      sx={
        {
          rotate: props.rotate ?? 'unset',
          ...props.sx,
        } as SxProps<Theme>
      }
    >
      <path
        d="M19 9.50024H9C6.791 9.50024 5 11.2911 5 13.5002C5 15.7093 6.791 17.5002 9 17.5002H19M19 9.50024L15 6.50024M19 9.50024L15 12.5002"
        stroke={props.htmlColor ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default UndoRedo;
