import { UserProfileType } from '@hoot/models/api/user';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { ReaderContext } from '@hoot/redux/reducers/readingSlice';
import TeacherReaderAppBar from '@hoot/ui/components/v2/core/TeacherReaderAppBar';
import useTeacherInLessonReaderController from '@hoot/ui/pages/v2/teacher/lesson/library/reader/useTeacherInLessonReaderController';

const TeacherInLessonReaderAppBar = () => {
  const { inLesson } = useActiveLessonState();

  const controller = useTeacherInLessonReaderController();

  const _onToggleFavouriteBook = (bookId: string, profileId: string, profileType: UserProfileType, markAsFav: boolean) => {
    if (profileType !== UserProfileType.Student) {
      console.error(`Can not toggle favourite book. Profile type must be ${UserProfileType.Student} in this context (in lesson).`);
      return;
    }
    controller.onToggleStudentFavourite(bookId, markAsFav);
  };

  return (
    <TeacherReaderAppBar
      readerContext={ReaderContext.InLessonReader}
      studentProfileId={inLesson!.studentId}
      onToggleDoublePage={controller.onToggleDoublePage}
      onZoomAmountChange={controller.onZoomAmountChange}
      onZoomOffsetChange={controller.onZoomOffsetChange}
      onResetZoom={controller.onResetZoom}
      onCloseBook={controller.onCloseBook}
      onToggleFavouriteBook={_onToggleFavouriteBook}
      onOpenWhiteboard={controller.onOpenWhiteboard}
    />
  );
};

export default TeacherInLessonReaderAppBar;
