import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import usePlaces from '@hoot/hooks/usePlaces';
import Card from '@hoot/ui/components/v2/core/Card';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';
import { TeacherProfileForm } from './TeacherProfile';

interface Props {
  control: Control<TeacherProfileForm, object>;
  watch: UseFormWatch<TeacherProfileForm>;
}

const AccountDetailsReadonly = (props: Props) => {
  const { control, watch } = props;
  const country = watch('country');
  const { provinceStateOptions } = usePlaces(country);

  return (
    <Card
      sx={{ width: '100%' }}
      title={
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <span>Account Details</span>
          <Icon name="locked" color="primary" />
        </Stack>
      }
    >
      <Grid container rowGap={'16px'} columnSpacing={'16px'}>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller name="firstName" control={control} render={({ field: { value } }) => <ReadOnlyTextField label="First Name" body={value} />} />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller name="lastName" control={control} render={({ field: { value } }) => <ReadOnlyTextField label="Last Name" body={value} />} />
        </Grid>
        <Grid size={12}>
          <Controller name="email" control={control} render={({ field: { value } }) => <ReadOnlyTextField label="Email" body={value} />} />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="country"
            control={control}
            render={({ field: { value } }) => <ReadOnlyTextField label="Country" body={value === 'CA' ? 'Canada' : 'United States'} />}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="provinceState"
            control={control}
            render={({ field: { value } }) => (
              <ReadOnlyTextField label="provinceState" body={provinceStateOptions.find((o) => o.value === value)?.label ?? ''} />
            )}
          />
        </Grid>
        <Grid size={12}>
          <Controller
            name="streetAddress"
            control={control}
            render={({ field: { value } }) => <ReadOnlyTextField label="Street Address" body={value} />}
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller name="city" control={control} render={({ field: { value } }) => <ReadOnlyTextField label="City" body={value} />} />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Controller
            name="postalZipCode"
            control={control}
            render={({ field: { value } }) => <ReadOnlyTextField label="Postal/ZIP Code" body={value} />}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default AccountDetailsReadonly;
