import { SvgIcon, SvgIconProps } from '@mui/material';

const FolderDownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        id="Vector"
        d="M8.99945 14.0001L10.5852 15.5859C11.3663 16.367 12.6326 16.367 13.4137 15.5859L14.9994 14.0001M11.9994 16.0001V11.0001M19.9994 6.00012V7.00012H3.99945V4.00012C3.99945 2.34327 5.34259 1.00012 6.99945 1.00012H9.56862C10.2367 1.00012 10.8856 1.2231 11.4126 1.63372L12.353 2.36652C12.88 2.77714 13.5289 3.00012 14.1969 3.00012H16.9994C18.6563 3.00012 19.9994 4.34327 19.9994 6.00012ZM3.47342 7.00012H20.5255C21.7988 7.00012 22.7479 8.17416 22.4811 9.41918L20.677 17.8382C20.2818 19.6825 18.6519 21.0001 16.7658 21.0001H7.23311C5.34696 21.0001 3.7171 19.6825 3.3219 17.8382L1.51782 9.41918C1.25102 8.17416 2.20013 7.00012 3.47342 7.00012Z"
        stroke={props.htmlColor ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default FolderDownIcon;
