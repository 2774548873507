import { DailyEventObjectNetworkQualityEvent } from '@daily-co/daily-js';
import { useMeetingSessionState, useMeetingState } from '@daily-co/daily-react';
import { useCallback } from 'react';
import { useShallowMergeMeetingSessionData } from './useShallowMergeMeetingSessionData';

export interface DailyMediaState {
  teacherCameraOn: boolean;
  teacherMicrophoneOn: boolean;
  teacherDailyNetworkEvent: DailyEventObjectNetworkQualityEvent | null;
  studentCameraOn: boolean;
  studentMicrophoneOn: boolean;
  studentIsLocked: boolean;
  studentDailyNetworkEvent: DailyEventObjectNetworkQualityEvent | null;
}

export const initialSessionState: DailyMediaState = {
  teacherCameraOn: true,
  teacherMicrophoneOn: true,
  teacherDailyNetworkEvent: null,
  studentCameraOn: true,
  studentMicrophoneOn: true,
  studentIsLocked: false,
  studentDailyNetworkEvent: null,
};

export function useTeacherMediaState() {
  const sessionState = useMeetingSessionState<DailyMediaState>();
  const { mergeMeetingSessionData } = useShallowMergeMeetingSessionData();
  const meetingState = useMeetingState();

  const cameraOn = sessionState.data?.teacherCameraOn;
  const microphoneOn = sessionState.data?.teacherMicrophoneOn;

  const toggleCamera = useCallback(() => {
    if (meetingState === 'joined-meeting') {
      console.log(`[Daily]: Toggling camera state from ${cameraOn}`);
      mergeMeetingSessionData<DailyMediaState>({ teacherCameraOn: !cameraOn });
    }
  }, [mergeMeetingSessionData, cameraOn, meetingState]);

  const toggleMicrophone = useCallback(() => {
    if (meetingState === 'joined-meeting') {
      console.log(`[Daily]: Toggling microphone state from ${microphoneOn}`);
      mergeMeetingSessionData<DailyMediaState>({ teacherMicrophoneOn: !microphoneOn });
    }
  }, [mergeMeetingSessionData, microphoneOn, meetingState]);

  return {
    toggleCamera,
    toggleMicrophone,
  };
}

export function useStudentMediaState() {
  const sessionState = useMeetingSessionState<DailyMediaState>();
  const { mergeMeetingSessionData } = useShallowMergeMeetingSessionData();
  const meetingState = useMeetingState();

  const cameraOn = sessionState.data?.studentCameraOn;
  const microphoneOn = sessionState.data?.studentMicrophoneOn;
  const isLocked = sessionState.data?.studentIsLocked;

  const toggleCamera = useCallback(() => {
    if (meetingState === 'joined-meeting') {
      console.log(`[Daily]: Toggling student camera state from ${cameraOn}`);
      mergeMeetingSessionData<DailyMediaState>({ studentCameraOn: !cameraOn });
    }
  }, [mergeMeetingSessionData, cameraOn, meetingState]);

  const toggleMicrophone = useCallback(() => {
    if (meetingState === 'joined-meeting') {
      console.log(`[Daily]: Toggling student microphone state from ${microphoneOn}`);
      mergeMeetingSessionData<DailyMediaState>({ studentMicrophoneOn: !microphoneOn });
    }
  }, [mergeMeetingSessionData, microphoneOn, meetingState]);

  const toggleLock = useCallback(() => {
    if (meetingState === 'joined-meeting') {
      console.log(`[Daily]: Toggling student locked state from ${isLocked}`);
      mergeMeetingSessionData<DailyMediaState>({ studentIsLocked: !isLocked });
    }
  }, [mergeMeetingSessionData, isLocked, meetingState]);

  return {
    isLocked,
    toggleCamera,
    toggleMicrophone,
    toggleLock,
  };
}
