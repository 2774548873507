import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import Banner from '../Banner';

export function NewAppVersionAvailableBanner() {
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const newAppVersionAvailable = useSelector((state: RootState) => state.application.newAppVersionAvailable);

  const handleUpdate = () => {
    window.location.reload();
  };

  if (newAppVersionAvailable && !inLesson) {
    return (
      <Banner
        backgroundColor="warning.160"
        message={`A New Version of Hoot Reading is Available`}
        primaryAction={{
          label: 'Update Now',
          onClick: () => handleUpdate(),
          props: {
            variant: 'contained',
            color: 'warning.100',
            sx: { minWidth: '121px' },
          },
        }}
      />
    );
  }
  return null;
}
