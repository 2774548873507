import { EventType } from '@hoot/events/eventType';
import { ScheduledLesson } from '@hoot/events/interfaces/scheduled-lesson';
import { OpenLessonBookRequestMessage } from '@hoot/events/messages/open-lesson-book-request.message';
import { PreviewLessonLibraryBookRequestMessage } from '@hoot/events/messages/preview-lesson-library-book-request-message';
import { PreviewLessonWhiteboardTemplateRequestMessage } from '@hoot/events/messages/preview-lesson-whiteboard-template-request.message';
import { SearchLessonLibraryRequestMessage } from '@hoot/events/messages/search-lesson-library-request.message';
import { SearchLessonWhiteboardTemplatesRequestMessage } from '@hoot/events/messages/search-lesson-whiteboard-templates-request.message';
import { ToggleLessonFavouriteBookRequestMessage } from '@hoot/events/messages/toggle-lesson-favourite-book-request.message';
import { WhiteboardOpenRequestMessage } from '@hoot/events/messages/whiteboard-open-request.message';
import { QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import { useEmit } from '@hoot/hooks/useEmit';
import { WhiteboardTemplateTypeEnum } from '@hoot/models/api/enums/whiteboard-template-type-enum';
import {
  LibraryContext,
  LibraryTab,
  PaginationMode,
  clearInLessonWhiteboardTemplateResults,
  clearLibraryResults,
  setInLessonWhiteboardTemplateQuery,
  setLessonLibraryLoading,
  setLessonLibraryTab,
  setLibraryQuery,
  setWhiteboardTemplateLoading,
  useLibraries,
} from '@hoot/redux/reducers/librarySlice';
import { useAppDispatch } from '@hoot/redux/store';
import { getDefaultLibraryQuery } from '@hoot/ui/pages/v2/teacher/library/TeacherLibraryFilterBar';

interface UseTeacherLessonControllerProps {
  inLesson: ScheduledLesson;
}

const libraryContext = LibraryContext.InLessonLibrary;

const useTeacherInLessonLibraryController = (props: UseTeacherLessonControllerProps) => {
  const { inLesson } = props;

  const { query: libraryQuery, paginationMode, lessonWhiteboardTemplates, selectedTab } = useLibraries().inLessonLibrary;
  const { query: whiteboardTemplatesQuery } = lessonWhiteboardTemplates;

  const dispatch = useAppDispatch();

  const searchLibraryRequestEmitter = useEmit<SearchLessonLibraryRequestMessage>(EventType.SearchLessonLibraryRequest, undefined, { enabled: false });
  const previewBookEmitter = useEmit<PreviewLessonLibraryBookRequestMessage>(EventType.PreviewLessonLibraryBookRequest, undefined, {
    enabled: false,
  });
  const toggleFavouriteBookEmitter = useEmit<ToggleLessonFavouriteBookRequestMessage>(EventType.ToggleLessonFavouriteBookRequest, undefined, {
    enabled: false,
  });
  const openBookEmitter = useEmit<OpenLessonBookRequestMessage>(EventType.OpenLessonBookRequest, undefined, { enabled: false });

  const searchWhiteboardTemplatesEmitter = useEmit<SearchLessonWhiteboardTemplatesRequestMessage>(
    EventType.SearchLessonWhiteboardTemplatesRequest,
    undefined,
    {
      enabled: false,
    },
  );
  const previewWhiteboardTemplateEmitter = useEmit<PreviewLessonWhiteboardTemplateRequestMessage>(
    EventType.PreviewLessonWhiteboardTemplateRequest,
    undefined,
    {
      enabled: false,
    },
  );
  const openWhiteboardEmitter = useEmit<WhiteboardOpenRequestMessage>(EventType.WhiteboardOpenRequest, undefined, {
    enabled: false,
  });

  const onSearchLibrary = (newQuery: QueryLibraryV2, newPaginationMode?: PaginationMode) => {
    dispatch(setLessonLibraryLoading(true));
    dispatch(setLibraryQuery({ libraryContext: libraryContext, query: newQuery }));

    searchLibraryRequestEmitter.queueMessage({
      lessonId: inLesson.lessonId,
      libraryQuery: {
        ...newQuery,
        studentProfileId: inLesson.studentId,
        teacherProfileId: inLesson.teacherId,
      },
      paginationMode: newPaginationMode ?? paginationMode,
    });
  };

  // Note: results aren't paginated here, but we use the pagination mode to determine if we're looking at the results
  // in a table or in a gallery.
  const onSearchWhiteboardTemplates = (query?: string, newPaginationMode?: PaginationMode) => {
    dispatch(setWhiteboardTemplateLoading(true));
    dispatch(clearInLessonWhiteboardTemplateResults());
    dispatch(setInLessonWhiteboardTemplateQuery({ query }));

    searchWhiteboardTemplatesEmitter.queueMessage({
      lessonId: inLesson.lessonId,
      paginationMode: newPaginationMode ?? paginationMode,
      query,
    });
  };

  const onPreviewWhiteboardTemplate = (whiteboardTemplateType: WhiteboardTemplateTypeEnum) => {
    previewWhiteboardTemplateEmitter.queueMessage({
      lessonId: inLesson.lessonId,
      whiteboardTemplateType,
    });
  };

  const onDismissWhiteboardTemplatePreview = () => {
    previewWhiteboardTemplateEmitter.queueMessage({
      lessonId: inLesson.lessonId,
      whiteboardTemplateType: null,
    });
  };

  const onOpenWhiteboard = (whiteboardTemplateType: WhiteboardTemplateTypeEnum) => {
    openWhiteboardEmitter.queueMessage({
      lessonId: inLesson.lessonId,
      templateType: whiteboardTemplateType,
    });
  };

  const onPaginationModeChanged = (paginationMode: PaginationMode) => {
    if (selectedTab === LibraryTab.Whiteboard) {
      dispatch(clearInLessonWhiteboardTemplateResults());

      // Refresh/reset the library results when the view have been changed.
      onSearchWhiteboardTemplates(whiteboardTemplatesQuery, paginationMode);
    } else {
      // Toggling the views will result in a total refresh (pagination is lost), so reset the data.
      dispatch(clearLibraryResults(libraryContext));

      // Refresh/reset the library results when the view have been changed.
      onSearchLibrary(
        {
          ...libraryQuery,
          ...getDefaultLibraryQuery(paginationMode),
        },
        paginationMode,
      );
    }
  };

  const onFetchNextPage = () => {
    if (libraryQuery) {
      onSearchLibrary({
        ...libraryQuery,
        page: libraryQuery.page + 1,
      });
    }
  };

  const onToggleStudentFavourite = (bookId: string, markAsFavourite: boolean) => {
    toggleFavouriteBookEmitter.queueMessage({
      bookId,
      lessonId: inLesson.lessonId,
      markAsFavourite,
    });
  };

  const onPreviewBook = (bookId: string) => {
    previewBookEmitter.queueMessage({
      bookId,
      lessonId: inLesson.lessonId,
    });
  };

  const onDismissBookPreview = () => {
    previewBookEmitter.queueMessage({
      bookId: null,
      lessonId: inLesson.lessonId,
    });
  };

  const onOpenBook = (bookId: string, defaultPageId?: string) => {
    openBookEmitter.queueMessage({
      bookId,
      lessonId: inLesson.lessonId,
      defaultPageId,
    });
  };

  const onTabChanged = (newSelectedTab: LibraryTab) => {
    dispatch(setLessonLibraryTab(newSelectedTab));

    // Use the same `search` criteria when changing from a library tabs to the whiteboard tab, and vice versa.
    const defaultSearchQuery =
      newSelectedTab !== LibraryTab.Whiteboard && selectedTab === LibraryTab.Whiteboard ? whiteboardTemplatesQuery : libraryQuery?.title ?? undefined;

    if (newSelectedTab === LibraryTab.Whiteboard) {
      dispatch(clearInLessonWhiteboardTemplateResults());
      onSearchWhiteboardTemplates(defaultSearchQuery, paginationMode);
    } else {
      dispatch(clearLibraryResults(LibraryContext.InLessonLibrary));
      onSearchLibrary({
        ...libraryQuery!,
        ...getDefaultLibraryQuery(paginationMode, newSelectedTab),
        title: defaultSearchQuery,
      });
    }
  };

  return {
    onSearchLibrary,
    onPaginationModeChanged,
    onFetchNextPage,
    onToggleStudentFavourite,
    onPreviewBook,
    onDismissBookPreview,
    onOpenBook,
    onSearchWhiteboardTemplates,
    onPreviewWhiteboardTemplate,
    onDismissWhiteboardTemplatePreview,
    onOpenWhiteboard,
    onTabChanged,
  };
};

export default useTeacherInLessonLibraryController;
