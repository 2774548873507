import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';
import { Tokens } from '../../../models/api/auth';
import { Profile, User } from '../../../models/api/user';

export enum CredentialType {
  Email = 'EMAIL',
  HFSNumber = 'HFS_NUMBER',
}

export interface LoginRequest {
  credentials: string;
  password: string;
  friendlyId?: string;
  credentialType: CredentialType;
}

export interface LoginResponse {
  user: User;
  tokens: Tokens;
  profileToAssume?: Profile;
}

async function login(request: LoginRequest): Promise<LoginResponse> {
  const { data } = await axios.post<LoginResponse>(`${config.apiUrl}/auth/login`, request);

  return data;
}

export default function useLogin(options?: Omit<UseMutationOptions<LoginResponse, AxiosError, LoginRequest>, 'mutationKey' | 'mutationFn'>) {
  return useMutation((request) => login(request), options);
}
