import { Control, UseFormClearErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { User } from '@hoot/models/api/user';
import AccountDetailsEmpty from './AccountDetailsEmpty';
import AccountDetailsReadonly from './AccountDetailsReadonly';
import { TeacherProfileForm } from './TeacherProfile';

interface Props {
  readonly: boolean;
  control: Control<TeacherProfileForm, object>;
  watch: UseFormWatch<TeacherProfileForm>;
  setValue: UseFormSetValue<TeacherProfileForm>;
  clearErrors: UseFormClearErrors<TeacherProfileForm>;
  user: User;
}

const AccountDetails = (props: Props) => {
  const { readonly, control, watch, setValue, clearErrors, user } = props;

  return readonly ? (
    <AccountDetailsReadonly control={control} watch={watch} />
  ) : (
    <AccountDetailsEmpty control={control} watch={watch} setValue={setValue} clearErrors={clearErrors} user={user} />
  );
};

export default AccountDetails;
