import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';
import { Tokens } from '../../../models/api/auth';

async function logout(): Promise<Tokens> {
  const { data } = await axios.post<Tokens>(`${config.apiUrl}/auth/logout`);
  return data;
}

export default function useLogout(options?: Omit<UseMutationOptions<Tokens, AxiosError, void>, 'mutationKey' | 'mutationFn'>) {
  return useMutation(() => logout(), options);
}
