import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Skeleton, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useGetLessonReviewNotes } from '@hoot/hooks/api/lesson-reviews/useGetLessonReviewNotes';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';

interface FormativeAssessmentsNoteModalProps extends Pick<DialogProps, 'open' | 'onClose'> {
  lessonId: string | undefined;
}

const FormativeAssessmentsNoteModal = (props: FormativeAssessmentsNoteModalProps) => {
  const { lessonId, ...dialogProps } = props;

  const getLessonReviewNotesRequest = useGetLessonReviewNotes(lessonId!, {
    enabled: !!lessonId,
  });

  const lastUpdatedAtFormatted = useMemo(() => {
    if (!getLessonReviewNotesRequest.data?.lastUpdatedAt) {
      return undefined;
    }
    return DateTime.fromMillis(getLessonReviewNotesRequest.data.lastUpdatedAt).toFormat('cccc, MMMM d kkkk, h:mma ZZZZ');
  }, [getLessonReviewNotesRequest.data?.lastUpdatedAt]);

  const _onClose = () => {
    dialogProps.onClose?.({}, 'escapeKeyDown');
  };

  const LessonReviewContent = () => (
    <Stack gap={2}>
      <ReadOnlyTextField label="Date" body={lastUpdatedAtFormatted ?? ''} />
      <HootTypography isPII={false} variant="titlemedium">
        Notes
      </HootTypography>
      <ReadOnlyTextField label="Notes" body={getLessonReviewNotesRequest?.data?.notes ?? ''} sx={{ minHeight: '160px' }} />
    </Stack>
  );

  const LoadingContent = () => (
    <Stack gap={2}>
      <Skeleton variant="rectangular" width="100%" height="58px" />
      <Skeleton variant="rectangular" width="48px" height="24px" />
      <Skeleton variant="rectangular" width="100%" height="160px" />
    </Stack>
  );

  return (
    <Dialog fullWidth maxWidth="md" {...dialogProps}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Formative Assessment Notes
        <IconButton onClick={_onClose}>
          <Icon name="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent>{getLessonReviewNotesRequest.isFetching ? <LoadingContent /> : <LessonReviewContent />}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={_onClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormativeAssessmentsNoteModal;
