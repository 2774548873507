import { ScheduledLessonLanguage, ScheduledLessonSubject } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface LessonPlanBook {
  id: string;
  title: string;
  resourceProgress: string;
  resourceType: string;
  instructionalFocus: string;
  instructionalUnit: string;
}

export interface LessonPlanQuery {
  lessonSubject: ScheduledLessonSubject;
  lessonLanguage: ScheduledLessonLanguage;
}

export interface GetLessonPlanResponse {
  lessonPlanBookDetails: LessonPlanBook[];
}

async function getLessonPlan(studentProfileId: string, query: LessonPlanQuery): Promise<GetLessonPlanResponse> {
  const { data } = await axios.get<GetLessonPlanResponse>(`${config.apiUrl}/public/lesson-review/lesson-plan/${studentProfileId}`, { params: query });
  return data;
}

export default function useGetLessonPlan(
  studentProfileId: string,
  query: LessonPlanQuery,
  options?: Omit<UseQueryOptions<GetLessonPlanResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetLessonPlan, studentProfileId], () => getLessonPlan(studentProfileId, query), options);
}
