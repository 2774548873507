import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { UseGetOptions } from '@hoot/models/api/axios-options';

export interface SubGenresResponse {
  subGenres: SubGenreOption[];
}

interface SubGenreOption {
  id: string;
  name: string;
}

//Note: null cannot actually be passed into backend from enabled option
async function getSubGenres(genreId: string | null): Promise<SubGenresResponse> {
  const { data } = await axios.get<SubGenresResponse>(`${config.apiUrl}/library/${genreId}/sub-genres`);
  return data;
}

export default function useGetSubGenres(genreId: string | null, options?: UseGetOptions<SubGenresResponse>) {
  return useQuery([QueryKey.GetSubGenres, genreId ?? 'no-genre-id'], () => getSubGenres(genreId), {
    enabled: !!genreId,
    ...options,
  });
}
