import { DailyParticipantTracks } from '@daily-co/daily-js';
import { useDaily } from '@daily-co/daily-react';
import { useEffect, useState } from 'react';

export function useDailyNetworkTest(props: { localParticipantTracks: DailyParticipantTracks }) {
  const [networkTestState, setNetworkTestState] = useState<'testing' | 'failed' | 'completed'>('testing');

  // NM: revert back to "Network test 1..." if/when we add the other network test back to the UI
  const [networkTestResultMessage, setNetworkTestResultMessage] = useState('Network test...');

  const dailyCallObj = useDaily();

  useEffect(() => {
    async function testNetworkConnectivity() {
      if (dailyCallObj) {
        const videoTrackState = props?.localParticipantTracks?.video;
        console.log(videoTrackState);
        if (videoTrackState && videoTrackState.persistentTrack) {
          const networkConnectivityTestStats = await dailyCallObj.testNetworkConnectivity(videoTrackState.persistentTrack);

          console.log('[Daily] Network connectivity test results', networkConnectivityTestStats);
          switch (networkConnectivityTestStats.result) {
            case 'aborted':
              setNetworkTestResultMessage('Test aborted.');
              setNetworkTestState('failed');
              break;
            case 'failed':
              setNetworkTestResultMessage('Connection issues detected. Click "Troubleshoot" below.');
              setNetworkTestState('failed');
              break;
            default:
            case 'passed':
              // NM: revert back to "Test 1" if/when we add the other network test back to the UI
              setNetworkTestResultMessage('Network Test: No connection issues detected!');
              setNetworkTestState('completed');
          }
        }
      }
    }

    testNetworkConnectivity().catch((err) => {
      console.error('Daily Network Connectivity test failed: ', err);
    });
  }, [dailyCallObj, props.localParticipantTracks]);

  return {
    networkTestState,
    networkTestResultMessage,
  };
}
