import { Stack } from '@mui/system';
import { Unit } from '@hoot/hooks/api/assessment/useGetAssessmentUnit';
import { HootAssessmentModule } from '@hoot/models/api/enums/hoot-reading-assessment';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import PlayIcon from '@hoot/ui/components/v2/icons/PlayIcon';
import RedoIcon from '@hoot/ui/components/v2/icons/RedoIcon';
import { useHootAssessmentContext } from '@hoot/ui/pages/v2/teacher/hoot-reading-assessment/HootAssessmentContext';

const IGNORE_MODULE_LIST: HootAssessmentModule[] = [
  HootAssessmentModule.SDQ,
  HootAssessmentModule.Four,
  HootAssessmentModule.ATR,
  HootAssessmentModule.HRAC,
];

interface IUnitCompleteProps {
  unit: Unit;
  hasOutstandingQuestions: boolean;
  submitForm: () => void;
  isSubmitting: boolean;
}

const UnitCompleteCard = (props: IUnitCompleteProps) => {
  const { form } = useHootAssessmentContext();
  const { unit, hasOutstandingQuestions, submitForm, isSubmitting } = props;

  const handleSubmit = () => submitForm();

  const renderStartIcon = () => {
    if (readyToSubmit) return <PlayIcon htmlColor="#FFF" />;
    if (isSubmitted) return <RedoIcon htmlColor="#FFF" />;
    return <PlayIcon htmlColor="#FFF" />;
  };

  const isSubmitted = !!unit.submissionId;
  const readyToSubmit = !unit.submissionId && !hasOutstandingQuestions; // Hasn't been submitted yet, but all fields have values.

  const ignoreRender = !!unit && IGNORE_MODULE_LIST.includes(unit.module);

  if (ignoreRender) return null;

  return (
    <Card>
      <Stack alignItems="center">
        <ViewStateIllustration
          title={readyToSubmit ? 'Unit Ready For Submission!' : 'This Unit Has Been Submitted!'}
          subtitle={readyToSubmit ? "Review & Submit when you're ready to save and continue" : 'You can still make changes and Resubmit'}
          illustration={IllustrationEnum.SetupComplete}
        />
        <Button onClick={handleSubmit} variant="contained" startIcon={renderStartIcon()} isLoading={isSubmitting} disabled={!form.formState.isDirty}>
          {readyToSubmit ? 'Submit & Continue' : 'Resubmit'}
        </Button>
      </Stack>
    </Card>
  );
};

export default UnitCompleteCard;
