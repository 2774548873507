import { useRedirectProfile } from '@hoot/hooks/useRedirectProfile';
import { UserProfileType } from '@hoot/models/api/user';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import Page from '@hoot/ui/components/v2/core/Page';
import PastInvoices from './PastInvoices';

export function Invoicing() {
  useRedirectProfile(UserProfileType.Student);
  useRedirectProfile(UserProfileType.Parent);

  return (
    <>
      <SubHeader title={{ label: 'Invoicing', isPII: false }} />
      <Page pageTitle="Invoicing | Hoot Reading">
        <PastInvoices sx={{ paddingBottom: '50px' }} />
      </Page>
    </>
  );
}
