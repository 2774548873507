import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { UseGetOptions } from '@hoot/models/api/axios-options';
import { Grade, ReadingLevelType } from '@hoot/models/api/library';

export interface ReadingLevelsRequest {
  readingLevelType: ReadingLevelType[];
  grade?: Grade;
}

export interface ReadingLevelsResponse {
  categorizedReadingLevels: CategorizedReadingLevel[];
}

interface CategorizedReadingLevel {
  parentCategory: ReadingLevelType;
  listItems: {
    label: string;
    value: string;
  }[];
}

async function getReadingLevels(requestPayload: ReadingLevelsRequest): Promise<ReadingLevelsResponse> {
  const { data } = await axios.get<ReadingLevelsResponse>(`${config.apiUrl}/library/reading-levels`, { params: requestPayload });
  return data;
}

export default function useGetReadingLevels(requestPayload: ReadingLevelsRequest, options?: UseGetOptions<ReadingLevelsResponse>) {
  return useQuery([QueryKey.GetReadingLevels, ...(requestPayload.readingLevelType as string[])], () => getReadingLevels(requestPayload), {
    enabled: requestPayload.readingLevelType.length > 0,
    ...options,
  });
}
