import axios from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { UseMutationOptions } from '@hoot/models/api/axios-options';

const usePutBookmark = (bookId: string, options: UseMutationOptions<{ pageId: string }, void>) => {
  return useMutation(
    [QueryKey.PutBookmark],
    async ({ pageId }) => {
      return (await axios.put<void>(`${config.apiUrl}/student-library/book/${bookId}/bookmark/${pageId}`)).data;
    },
    options,
  );
};

export default usePutBookmark;
