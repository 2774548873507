import { Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import SearchTextField, { SearchTextFieldProps } from '@hoot/ui/components/v2/core/SearchTextField';

export interface Props {
  show: boolean;
  isInLesson: boolean;
  defaultSearchCriteria?: string;
  onDismiss: () => void;
  onSearch: (search: string) => void;
  searchTitle?: string;
  searchLabel?: string;
  searchPlaceholderText?: string;
}

const TeacherLibrarySearchModal = (props: Props) => {
  const { show, isInLesson, defaultSearchCriteria, onDismiss, onSearch, searchTitle, searchPlaceholderText, searchLabel } = props;

  const [searchInput, setSearchInput] = useState<string>('');

  useEffect(
    () => {
      if (show) {
        setSearchInput(defaultSearchCriteria ?? '');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [show],
  );

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchInput(text);
  };

  const onClearButtonClicked = () => {
    setSearchInput('');
  };

  const _onSearch = () => {
    onSearch(searchInput);
    onDismiss();
  };

  return (
    <Dialog open={show} onClose={onDismiss} fullWidth PaperProps={isInLesson ? { sx: { marginLeft: '288px', marginBottom: '128px' } } : undefined}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {searchTitle ?? 'Search Book Title'}
        <IconButton onClick={onDismiss}>
          <Icon name="close" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" gap={2} mb={2}>
          <SearchTextField
            label={searchLabel ?? 'Search Book Title'}
            placeHolderText={searchPlaceholderText ?? 'Book Title'}
            searchInput={searchInput}
            onSearchInputChanged={onSearchInputChanged}
            onClearButtonClicked={onClearButtonClicked}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                _onSearch();
                ev.preventDefault();
              }
            }}
            // removing focus on mobile so that the modal and keyboard properly readjust on scren sc-8704
            inputRef={(input) => !isMobile && input && input.focus()}
          />
          <Button variant="contained" size="medium" onClick={_onSearch} sx={{ height: '58px' }}>
            Search
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default TeacherLibrarySearchModal;
