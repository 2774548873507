import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import Lottie, { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieButton from '@hoot/ui/components/v2/lottie/LottieButton';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface StudentInLessonSettingsDialogProps {
  show: boolean;
  handleClose: () => void;
}

const StudentInLessonExitDialog = (props: StudentInLessonSettingsDialogProps) => {
  const { show, handleClose } = props;
  const navigate = useNavigate();

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '24px 28px 32px 48px',
          ...hootTokens.text.displaysmall,
        }}
      >
        <span>Exit</span>
        <LottieButton
          tooltipLabel="Cancel"
          lottieFile={LottieFile.ErrorCross}
          aria-label={'close'}
          sx={{ boxShadow: undefined, background: 'primary.190', padding: 0, margin: 0 }}
          onClick={handleClose}
          lottieWidth={80}
          lottieHeight={80}
        />
      </DialogTitle>
      <DialogContent>
        <Stack padding={'16px 16px 40px 16px'} gap={'10px'} alignItems="center">
          <Lottie lottieFile={LottieFile.Exit} loop play style={{ width: '250px', height: '250px', marginRight: '65px' }} />
          <LottieButton
            fullWidth
            lottieFile={LottieFile.Checkmark}
            tooltipLabel="Exit lesson"
            aria-label="Exit lesson"
            lottieWidth={80}
            lottieHeight={80}
            sx={{
              backgroundColor: hootTokens.palette.success[190],
              '&:hover': {
                backgroundColor: hootTokens.palette.success[180],
              },
            }}
            onClick={() => navigate(routesDictionary.home.url, { replace: true })}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default StudentInLessonExitDialog;
