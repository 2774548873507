import { Box, DialogActions, DialogContent, Stack, TextField, TextFieldProps, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { FULL_DATE, TEXT_INPUT_DEBOUNCE_TIME_MS } from '@hoot/constants/constants';
import { EventType } from '@hoot/events/eventType';
import { LessonReviewStateResponseMessage, SetLessonReviewStateMessage } from '@hoot/events/messages/lesson-review-state.message';
import useGetLessonReviewState from '@hoot/hooks/api/lesson/useGetLessonReviewState';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import useProfile from '@hoot/hooks/useProfile';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { useSocket } from '@hoot/ui/context/SocketContext';
import {
  LessonReviewWizardStepEnum,
  useLessonReviewWizardContext,
} from '@hoot/ui/pages/v2/teacher/my-students/student-details/lesson-reviews/lesson-review-wizard/LessonReviewContextProvider';

const LessonReviewLessonNotesStep = () => {
  const {
    existingLessonReview,
    isSubmittingReview,
    lessonReviewNotes,
    lessonToReview,
    actions: { submitLessonReview, goToPreviousStep },
  } = useLessonReviewWizardContext();

  const [hasLessonEndTimeSurpassed, setHasLessonEndTimeSurpassed] = useState(false);
  const endsAt = lessonToReview?.endsAt;

  const lessonEndTimeFormatted = useMemo(() => {
    if (!endsAt) {
      return undefined;
    }
    return DateTime.fromMillis(endsAt).toFormat(`${FULL_DATE}, h:mm a ZZZZZ`);
  }, [endsAt]);

  useEffect(() => {
    // If for whatever reason, we don't have an end time, then don't lock the user out from submitting the review (this
    // should never really happen though).
    if (!endsAt) {
      setHasLessonEndTimeSurpassed(true);
      return;
    }
    function handleEnableSubmit() {
      setHasLessonEndTimeSurpassed(true);
    }
    const triggerTimeoutAt = endsAt - Date.now();
    if (triggerTimeoutAt <= 0) {
      setHasLessonEndTimeSurpassed(true);
    } else {
      window.setTimeout(handleEnableSubmit, triggerTimeoutAt);
    }
  }, [endsAt]);

  return (
    <>
      <DialogContent>
        <Stack gap={2} height="100%">
          <HootTypography isPII={true} variant="titlelarge">
            Step {LessonReviewWizardStepEnum.LessonNotes} – Lesson Notes
          </HootTypography>
          <HootTypography isPII={false} variant="bodymedium">
            Some Description for things you might want to include in the lesson notes.
          </HootTypography>
          <Box width="100%" minHeight="240px">
            <LessonReviewNotesTextField label="Lesson Notes" />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ gap: 2 }}>
        <Button variant="outlined" onClick={goToPreviousStep}>
          Go Back
        </Button>
        <Tooltip
          title={
            !hasLessonEndTimeSurpassed
              ? `Lesson review can not be submitted until ${lessonEndTimeFormatted ?? '?'}`
              : !lessonReviewNotes.length
                ? 'You must provide some lesson notes.'
                : undefined
          }
        >
          {/* Need this wrapper for the tooltip to show for disabled child elements. */}
          <span>
            <Button
              variant="contained"
              onClick={submitLessonReview}
              isLoading={isSubmittingReview}
              disabled={!hasLessonEndTimeSurpassed || !lessonReviewNotes.length}
            >
              {existingLessonReview ? 'Update' : 'Submit'}
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </>
  );
};

interface LessonReviewNotesProps {
  label?: string;
}

export const LessonReviewNotesTextField = (props: LessonReviewNotesProps) => {
  const {
    lessonReviewNotes,
    existingLessonReview,
    lessonToReview,
    actions: { attachLessonNotesData },
  } = useLessonReviewWizardContext();
  const { socket } = useSocket();
  const { profile } = useProfile();
  const queryClient = useQueryClient();
  const { isLoading, isFetched } = useGetLessonReviewState(lessonToReview?.lessonId ?? '', {
    enabled: !!lessonToReview && !existingLessonReview,
    onSuccess: (data) => {
      if (!existingLessonReview && !!data) {
        attachLessonNotesData(data.notepadText);
      }
    },
  });
  const timerRef = useRef<number>();

  useEffect(() => {
    return () => {
      //must reset query cache into initial state as invalidation only marks cache as stale data
      queryClient.resetQueries(QueryKey.GetLessonReviewState);
    };
  }, [queryClient]);

  useEffect(() => {
    function handleUpdateLessonReviewNoteDisplay(response: LessonReviewStateResponseMessage) {
      if (!existingLessonReview) {
        if (response.lessonId === lessonToReview?.lessonId) {
          attachLessonNotesData(response.notepadText);
        }
      }
    }

    socket.on(EventType.UpdateLessonReviewNoteDisplay, handleUpdateLessonReviewNoteDisplay);

    return () => {
      socket.off(EventType.UpdateLessonReviewNoteDisplay, handleUpdateLessonReviewNoteDisplay);
    };
  }, [socket, attachLessonNotesData, existingLessonReview, lessonToReview?.lessonId]);

  const _onLessonReviewNotesChanged: TextFieldProps['onChange'] = (event) => {
    attachLessonNotesData(event.target.value);

    timerRef.current = window.setTimeout(() => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (!!lessonToReview && !!profile && !isLoading && isFetched) {
        const requestMessage: SetLessonReviewStateMessage = {
          notepadText: event.target.value,
          lessonId: lessonToReview.lessonId,
          studentProfileId: lessonToReview?.student.id,
          teacherProfileId: profile.id,
        };

        socket.emit(EventType.SetLessonReviewState, requestMessage);
      }
    }, TEXT_INPUT_DEBOUNCE_TIME_MS);
  };

  return (
    <>
      {/* NOTE: Had to really mess with the CSS in order to get this to fill the height of its parent. */}
      <TextField
        required
        multiline
        minRows={10}
        placeholder="Use this space to record your notes and observations"
        label={props.label}
        variant="outlined"
        value={lessonReviewNotes}
        onChange={_onLessonReviewNotesChanged}
        sx={{
          height: '100%',
          display: 'grid',
          '& > div': {
            padding: `${props.label ? '26px' : '8px'} 16px 8px 16px !important`,
          },
          '& > div > textarea': {
            height: '100% !important',
            padding: `0!important`,
            width: '100% !important',
          },
        }}
      />
    </>
  );
};

export default LessonReviewLessonNotesStep;
