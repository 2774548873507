import { Dialog, DialogProps } from '@mui/material';
import React from 'react';

/**
 * Just a regular dialog without its internal backdrop applied. Intended to be used when the backdrop is applied from
 * outside/parent component.
 */
const BackdroplessDialog = (props: DialogProps) => {
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      slotProps={{
        backdrop: {
          sx: {
            // We're going to use a single backdrop for all dialogs here so we don't see the screen flash.
            display: 'none',
          },
        },
      }}
      {...props}
    ></Dialog>
  );
};

export default BackdroplessDialog;
