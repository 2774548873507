import { IconButton, TextFieldProps, useMediaQuery, useTheme } from '@mui/material';
import { DatePickerProps, DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';
import CalendarIcon from '../icons/CalendarIcon';

type CalendarFieldProps = Omit<DatePickerProps<DateTime>, 'onChange' | 'value'> & {
  onChange: (val: DateTime | null) => void;
  value: DateTime | null;
  helperText?: string;
  error?: boolean;
  textFieldProps?: Partial<TextFieldProps>;
};

function DatePicker(props: CalendarFieldProps) {
  const { helperText, error, textFieldProps, ...datePickerProps } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MUIDatePicker
      format="MM/dd/yyyy"
      {...datePickerProps}
      slots={{
        openPickerIcon: isMobile
          ? undefined
          : () => (
              <IconButton sx={{ marginRight: '0px' }}>
                <CalendarIcon />
              </IconButton>
            ),
      }}
      slotProps={{
        textField: {
          ...textFieldProps,
          InputProps: {
            placeholder: textFieldProps?.placeholder,
            sx: {
              paddingTop: 1,
            },
          },
          helperText: helperText,
          error: error,
        },
      }}
    />
  );
}

export default DatePicker;
