import { Box } from '@mui/material';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface SVGTextComponentProps {
  text: string;
  isThumbnailView: boolean;
  isLetterUnit?: boolean;
}

const PMATextUnit = (props: SVGTextComponentProps) => {
  const { text, isThumbnailView, isLetterUnit } = props;
  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'hidden',
        ...(isThumbnailView
          ? {
              boxShadow: hootTokens.elevation.elevation1,
              borderRadius: '10px',
            }
          : {}),
      }}
    >
      <svg width="100%" height="100%" viewBox="0 0 1280 1280" preserveAspectRatio="xMinYMid meet" xmlns="http://www.w3.org/2000/svg">
        <foreignObject x="0%" y="0%" width="100%" height="100%">
          <Box
            sx={{
              height: '100%',
              borderRadius: '10px',
              backgroundColor: '#FFF',
              boxShadow: hootTokens.elevation.elevation1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                fontSize: isLetterUnit ? '300px' : '100px', // Verifies the desired font sizes
                textTransform: isLetterUnit ? 'lowercase' : undefined,
                color: 'black',
                textAlign: 'center',
                wordWrap: 'break-word',
              }}
            >
              {text}
            </Box>
          </Box>
        </foreignObject>
      </svg>
    </Box>
  );
};

export default PMATextUnit;
