import { Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle, PaperProps, Stack, SxProps } from '@mui/material';
import { ReactNode } from 'react';
import { Button, ButtonProps } from '../core/Button';
import { Icon } from '../core/Icon';
import IconButton from '../core/IconButton';

export interface BasicAlertDialogProps {
  show: boolean;
  onDismiss: () => void;
  title: string;
  content: ReactNode;
  showClose?: boolean;
  backgroundSx?: SxProps;
  primaryAction?: {
    label: string;
    onClick: () => void;
    props?: ButtonProps;
    autoFocus?: boolean;
    dataHootCanaryId?: string;
    isLoading?: boolean;
  };
  secondaryAction?: {
    label: string;
    onClick: () => void;
    props?: ButtonProps;
    autoFocus?: boolean;
    dataHootCanaryId?: string;
  };
  tertiaryAction?: {
    label: string;
    onClick: () => void;
    props?: ButtonProps;
    autoFocus?: boolean;
    dataHootCanaryId?: string;
  };
  maxWidth?: Breakpoint;
  dialogSx?: SxProps;
  dialogActionsSx?: SxProps;
  stackDialogActions?: boolean;
  paperProps?: Partial<PaperProps>;
  centerDialogActionButtons?: boolean;
}

const DialogActionsStack = (props: { stackDialogActions: boolean; children: ReactNode }) => {
  if (props.stackDialogActions) {
    return (
      <Stack width="100%" direction="column-reverse" alignItems="stretch" gap="8px">
        {props.children}
      </Stack>
    );
  } else {
    return props.children;
  }
};

const BasicAlertDialog = (props: BasicAlertDialogProps) => {
  const {
    show,
    onDismiss,
    title,
    content,
    primaryAction,
    secondaryAction,
    tertiaryAction,
    maxWidth,
    dialogActionsSx,
    stackDialogActions,
    showClose = true,
  } = props;

  return (
    <Dialog
      slotProps={{
        backdrop: {
          sx: props.backgroundSx,
        },
      }}
      PaperProps={props.paperProps}
      open={show}
      onClose={onDismiss}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {title}
        {showClose ? (
          <IconButton onClick={onDismiss}>
            <Icon name="close" />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: '4px' }}>{content}</DialogContent>
      <DialogActions sx={{ justifyContent: 'none', ...dialogActionsSx }}>
        <DialogActionsStack stackDialogActions={stackDialogActions ?? false}>
          {tertiaryAction ? (
            <Button
              data-hoot-canary-id={tertiaryAction.dataHootCanaryId}
              onClick={tertiaryAction.onClick}
              variant="outlined"
              color="error.80"
              {...(tertiaryAction.props ?? {})}
              autoFocus={tertiaryAction.autoFocus ?? false}
            >
              {tertiaryAction.label}
            </Button>
          ) : null}

          {secondaryAction ? (
            <Button
              data-hoot-canary-id={secondaryAction.dataHootCanaryId}
              onClick={secondaryAction.onClick}
              variant="outlined"
              {...(secondaryAction.props ?? {})}
              autoFocus={secondaryAction.autoFocus ?? false}
            >
              {secondaryAction.label}
            </Button>
          ) : null}

          {primaryAction && (
            <Button
              data-hoot-canary-id={primaryAction?.dataHootCanaryId}
              onClick={primaryAction?.onClick}
              variant="contained"
              {...(primaryAction?.props ?? {})}
              autoFocus={primaryAction?.autoFocus ?? false}
              isLoading={primaryAction?.isLoading ?? false}
            >
              {primaryAction?.label}
            </Button>
          )}
        </DialogActionsStack>
      </DialogActions>
    </Dialog>
  );
};

export default BasicAlertDialog;
