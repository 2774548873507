import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface NextUnitForAssessmentReminder {
  nextUnit: string | null;
}

export default function GetNextUnitForAssessmentReminder(
  studentProfileId: string,
  options?: Omit<UseQueryOptions<NextUnitForAssessmentReminder, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetStudentAssessmentReminder, studentProfileId],
    async () => {
      return (await axios.get<NextUnitForAssessmentReminder>(`${config.apiUrl}/my-students/${studentProfileId}/assessments-reminder`)).data;
    },
    options,
  );
}
