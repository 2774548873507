import { Dialog, DialogContent, InputBaseProps, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { StudentType } from '@hoot/models/api/student';
import StudentSearchTextField from '@hoot/ui/components/v2/StudentSearchTextField';
import SublibrarySelector from '@hoot/ui/components/v2/SublibrarySelector';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieButton from '@hoot/ui/components/v2/lottie/LottieButton';
import { StudentSubLibrarySelection } from '@hoot/ui/pages/v2/student/library/StudentLibraryPage';

export interface StudentLibrarySearchModalProps {
  show: boolean;
  studentType: StudentType;
  defaultSearchTerm: string | undefined;
  defaultSublibrarySelection: StudentSubLibrarySelection;
  onDismiss: () => void;
  onSearchBooks: (searchTerm: string, sublibrary: StudentSubLibrarySelection) => void;
}

const StudentLibrarySearchModal = (props: StudentLibrarySearchModalProps) => {
  const { show, studentType, defaultSearchTerm, defaultSublibrarySelection, onDismiss, onSearchBooks } = props;

  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm ?? '');
  const [sublibrarySelection, setSublibrarySelection] = useState(defaultSublibrarySelection);

  useEffect(() => {
    if (show) {
      // Reset default sublibrary selection when this modal is opened.
      setSublibrarySelection(defaultSublibrarySelection);

      // Reset the visible search term any time this modal is opened.
      setSearchTerm(defaultSearchTerm ?? '');
    }
  }, [defaultSearchTerm, defaultSublibrarySelection, show]);

  const onSearchTermChanged: InputBaseProps['onChange'] = (e) => {
    setSearchTerm(e.target.value);
  };

  const onSublibrarySelectionChanged = (sublibrarySelection: StudentSubLibrarySelection) => {
    setSublibrarySelection(sublibrarySelection);
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    _onSearchBooks();
  };

  const _onSearchBooks = () => {
    onSearchBooks(searchTerm, sublibrarySelection);
  };

  return (
    <Dialog open={show} onClose={onDismiss} fullWidth maxWidth="md">
      <form onSubmit={onSubmit}>
        <Stack
          direction="row"
          sx={{
            pl: 5,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <HootTypography isPII={false} variant="displaysmall">
            Search
          </HootTypography>
          <Stack direction="row">
            <LottieButton
              sx={{ width: '104px', height: '104px' }}
              lottieFile={LottieFile.ErrorCross}
              tooltipLabel="Cancel"
              aria-label="Cancel"
              variant="transparent"
              onClick={onDismiss}
            />
            <LottieButton
              type="submit"
              sx={{ width: '104px', height: '104px' }}
              lottieFile={LottieFile.ApprovedCheck}
              tooltipLabel="Search library"
              aria-label="Search library"
              variant="transparent"
            />
          </Stack>
        </Stack>
        <DialogContent sx={{ p: 5 }}>
          <StudentSearchTextField
            fullWidth
            tooltipLabel="Search books"
            InputProps={{
              // For some reason, autofocusing on a TextField within a Dialog on iOS will cause the virtual keyboard
              // to be overlaid on top of the dialog content instead of pushing the content up. We have to disable
              // autofocus here just for iOS.
              // See: https://github.com/mui/material-ui/issues/23032
              autoFocus: !isIOS,
              placeholder: 'Search books',
              value: searchTerm,
              onChange: onSearchTermChanged,
            }}
            onSearchButtonClick={_onSearchBooks}
          />
          <HootTypography isPII={false} variant="headlinesmall" mt={5} mb={0.5}>
            Only show results from
          </HootTypography>
          <SublibrarySelector fullWidth value={sublibrarySelection} onChange={onSublibrarySelectionChanged} studentType={studentType} />
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default StudentLibrarySearchModal;
