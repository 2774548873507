import { useEffect } from 'react';
import { useStudentMediaState } from '@hoot/hooks/daily/useDailyMediaState';
import { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieButton from '@hoot/ui/components/v2/lottie/LottieButton';

interface NextPageButtonProps {
  onNextPage: () => void;
}

const NextPageButton = (props: NextPageButtonProps) => {
  const { onNextPage } = props;

  const { isLocked: studentLocked } = useStudentMediaState();

  useEffect(() => {
    const keyPressHandler = ({ key }: KeyboardEvent) => {
      switch (key) {
        case 'ArrowRight':
          if (!studentLocked) {
            onNextPage();
          }
          break;
      }
    };
    window.addEventListener('keydown', keyPressHandler);

    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
  }, [studentLocked, onNextPage]);

  return (
    <LottieButton disabled={studentLocked} lottieFile={LottieFile.ArrowRight} tooltipLabel="Next page" aria-label="Next page" onClick={onNextPage} />
  );
};

export default NextPageButton;
