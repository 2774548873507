import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/models/api/pagination';
import { TeacherInvoiceStatus } from '@hoot/ui/pages/v2/teacher/invoicing/enums';

export interface PastInvoicesQuery extends PaginatableQuery {
  sortDirection: OrderBy;
  orderBy: string;
}

export interface PastInvoicesListingResponse {
  invoiceId: string;
  invoiceNumber: string;
  date: string;
  amount: number;
  status: TeacherInvoiceStatus;
}

async function getPastInvoicesListing(searchParams: PastInvoicesQuery) {
  const { data } = await axios.get<GenericPaginatedResponse<PastInvoicesListingResponse>>(`${config.apiUrl}/public/teacher-invoicing/past-invoices`, {
    params: searchParams,
  });
  return data;
}

export function useGetPastInvoicesListing(
  teacherAccountId: string,
  searchParams: PastInvoicesQuery,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<PastInvoicesListingResponse>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetPastInvoicesListing, teacherAccountId, searchParams], () => getPastInvoicesListing(searchParams), options);
}
