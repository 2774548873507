import { ThemeOptions } from '@mui/material';
import React from 'react';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export type TypographyVariant =
  | 'displaylarge'
  | 'displaymedium'
  | 'displaysmall'
  | 'headlinelarge'
  | 'headlinemedium'
  | 'headlinesmall'
  | 'titlelarge'
  | 'titlemedium'
  | 'titlesmall'
  | 'tableheading'
  | 'tableheadingactive'
  | 'tablevalue'
  | 'labellarge'
  | 'labelmedium'
  | 'labelsmall'
  | 'bodylarge'
  | 'bodymedium'
  | 'bodysmall';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    displaylarge: React.CSSProperties;
    displaymedium: React.CSSProperties;
    displaysmall: React.CSSProperties;
    headlinelarge: React.CSSProperties;
    headlinemedium: React.CSSProperties;
    headlinesmall: React.CSSProperties;
    titlelarge: React.CSSProperties;
    titlemedium: React.CSSProperties;
    titlesmall: React.CSSProperties;
    tableheading: React.CSSProperties;
    tableheadingactive: React.CSSProperties;
    tablevalue: React.CSSProperties;
    labellarge: React.CSSProperties;
    labelmedium: React.CSSProperties;
    labelsmall: React.CSSProperties;
    bodylarge: React.CSSProperties;
    bodymedium: React.CSSProperties;
    bodysmall: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    displaylarge?: React.CSSProperties;
    displaymedium?: React.CSSProperties;
    displaysmall?: React.CSSProperties;
    headlinelarge?: React.CSSProperties;
    headlinemedium?: React.CSSProperties;
    headlinesmall?: React.CSSProperties;
    titlelarge?: React.CSSProperties;
    titlemedium?: React.CSSProperties;
    titlesmall?: React.CSSProperties;
    tableheading?: React.CSSProperties;
    tableheadingactive?: React.CSSProperties;
    tablevalue?: React.CSSProperties;
    labellarge?: React.CSSProperties;
    labelmedium?: React.CSSProperties;
    labelsmall?: React.CSSProperties;
    bodylarge?: React.CSSProperties;
    bodymedium?: React.CSSProperties;
    bodysmall?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    displaylarge: true;
    displaymedium: true;
    displaysmall: true;
    headlinelarge: true;
    headlinemedium: true;
    headlinesmall: true;
    titlelarge: true;
    titlemedium: true;
    titlesmall: true;
    tableheading: true;
    tableheadingactive: true;
    tablevalue: true;
    labellarge: true;
    labelmedium: true;
    labelsmall: true;
    bodylarge: true;
    bodymedium: true;
    bodysmall: true;
  }
}

const typography: ThemeOptions['typography'] = {
  fontFamily: ['Poppins', 'sans-serif'].join(','),
  allVariants: {
    margin: 0,
    padding: 0,
  },
  displaylarge: {
    ...hootTokens.text.displaylarge,
  },
  displaymedium: {
    ...hootTokens.text.displaymedium,
  },
  displaysmall: {
    ...hootTokens.text.displaysmall,
  },
  headlinelarge: {
    ...hootTokens.text.headlinelarge,
  },
  headlinemedium: {
    ...hootTokens.text.headlinemedium,
  },
  headlinesmall: {
    ...hootTokens.text.headlinesmall,
  },
  titlelarge: {
    ...hootTokens.text.titlelarge,
  },
  titlemedium: {
    ...hootTokens.text.titlemedium,
  },
  titlesmall: {
    ...hootTokens.text.titlesmall,
  },
  tableheading: {
    ...hootTokens.text.tableheading,
  },
  tableheadingactive: {
    ...hootTokens.text.tableheadingactive,
  },
  tablevalue: {
    ...hootTokens.text.tablevalue,
  },
  labellarge: {
    ...hootTokens.text.labellarge,
  },
  labelmedium: {
    ...hootTokens.text.labelmedium,
  },
  labelsmall: {
    ...hootTokens.text.labelsmall,
  },
  bodylarge: {
    ...hootTokens.text.bodylarge,
  },
  bodymedium: {
    ...hootTokens.text.bodymedium,
  },
  bodysmall: {
    ...hootTokens.text.bodysmall,
  },
};

export default typography;
