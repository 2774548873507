import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface FormatsResponse {
  formats: FormatOption[];
}

interface FormatOption {
  id: string;
  name: string;
}

async function getFormats(): Promise<FormatsResponse> {
  const { data } = await axios.get<FormatsResponse>(`${config.apiUrl}/library/formats`);
  return data;
}

export default function useGetFormats(options?: Omit<UseQueryOptions<FormatsResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetFormats], () => getFormats(), options);
}
