import React, { useEffect } from 'react';
import { sentry } from '@hoot/telemetry/sentry';
import { config } from '../config';
import { googleAnalytics } from './google-analytics';

function ThirdPartyTelemetry() {
  useEffect(() => {
    initSentry();
    initGoogleAnalytics();
  }, []);

  function initSentry() {
    sentry.initSentry().catch((error) => console.log(`Failed to initialize Sentry. Extension may be blocking it. Error: ${error}`));
  }

  function initGoogleAnalytics() {
    if (config.googleAnalyticsEnabled && config.googleAnalyticsMeasurementId) {
      googleAnalytics
        .initGoogleAnalytics()
        .catch((error) => console.log(`Failed to initialize Google Analytics. Extension may be blocking it. Error: ${error}`));
    }
  }
  return <></>;
}

export default ThirdPartyTelemetry;
