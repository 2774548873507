import { useMemo } from 'react';
import countriesStates from '../constants/countriesStates.json';

interface CountryOption {
  value: string;
  label: string;
}

export const usePlaces = (country: string) => {
  const countryOptionsCanadaFirst = useMemo(() => {
    const countryOptions = countriesStates.reduce((acc: CountryOption[], val) => {
      acc = [
        ...acc,
        {
          value: val.code2,
          label: val.name,
        },
      ];
      return acc;
    }, []);

    return [
      ...countryOptions.filter((item) => item.value === 'CA' || item.value === 'US'),
      ...countryOptions.filter((item) => item.value !== 'CA' && item.value !== 'US'),
    ];
  }, []);

  const provinceStateOptions = useMemo(() => {
    interface CountryToProvinceMap {
      [countryName: string]: string[];
    }

    const countryToProvinceMap: CountryToProvinceMap = countriesStates.reduce((acc, val) => {
      acc = {
        ...acc,
        [val.code2]: val.states,
      };

      return acc;
    }, {});

    return countryToProvinceMap?.[country] !== undefined
      ? countryToProvinceMap[country].map((province: string) => {
          return {
            value: province.toUpperCase(),
            label: province,
          };
        })
      : [];
  }, [country]);

  return { countryOptionsCanadaFirst, provinceStateOptions };
};

export default usePlaces;
