import { TeacherShiftStatus } from '@hoot-reading/hoot-core/dist/enums/teacher-shifts';
import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface GetTeacherShiftsRequest {
  startsAt: number;
  endsAt: number;
}

export interface TeacherAccountShiftsResponse {
  shifts: TeacherAccountShiftResponse[];
}

export interface TeacherAccountShiftResponse {
  id: string;
  number: number;
  startsAt: number;
  endsAt: number;
  status: TeacherShiftStatus;
}

async function getTeacherShifts(query: GetTeacherShiftsRequest): Promise<TeacherAccountShiftsResponse> {
  const { data } = await axios.post<TeacherAccountShiftsResponse>(`${config.apiUrl}/public/teacher-shift`, query);
  return data;
}

export function useGetTeacherShifts(
  query: GetTeacherShiftsRequest,
  options?: Omit<UseQueryOptions<TeacherAccountShiftsResponse>, 'mutationKey' | 'mutationFn'>,
) {
  return useQuery([QueryKey.GetTeacherShifts, query], () => getTeacherShifts(query), options);
}
