import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Switch } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PASSWORD_MAXIMUM_LENGTH, PASSWORD_MINIMUM_LENGTH } from '@hoot/constants/constants';
import { EventType } from '@hoot/events/eventType';
import { useChangePassword } from '@hoot/hooks/api/auth/useChangePassword';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import TextField from '@hoot/ui/components/v2/core/TextField';
import { useSocket } from '@hoot/ui/context/SocketContext';

export interface ChangePasswordForm {
  oldPassword: string;
  newPassword: string;
}

interface Props {
  show: boolean;
  onClose: () => void;
}

const ChangePasswordDialog = (props: Props) => {
  const { show, onClose } = props;

  const [logoutAllActiveSessions, setLogoutAllActiveSessions] = useState<boolean>(false);
  const changePasswordMutation = useChangePassword();
  const { socket } = useSocket();
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm<ChangePasswordForm>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
    },
  });

  const onSubmit = (data: ChangePasswordForm) => {
    const { oldPassword, newPassword } = data;

    changePasswordMutation.mutate(
      {
        oldPassword: oldPassword,
        newPassword: newPassword,
        logoutAllActiveSessions: logoutAllActiveSessions,
      },
      {
        onSuccess: () => {
          dispatch(createFlashMessage({ message: 'Password Updated Successfully' }));
          if (logoutAllActiveSessions) {
            socket.emit(EventType.DisconnectAllOtherProfiles);
          }
          reset({ oldPassword: '', newPassword: '' });
          onClose();
        },
        onError: (exc) => {
          const errorText = exc?.response?.data?.message ?? 'There was a problem changing your password. \n Contact help@hootreading.com';
          console.error(exc.message);
          dispatch(createFlashMessage({ message: errorText, variant: 'error' }));
        },
      },
    );
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={show} onClose={handleClose} fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          Change Password
          <IconButton onClick={handleClose}>
            <Icon name="close" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '4px' }}>
          <Stack
            sx={{
              gap: 2,
              overflowY: 'hidden',
            }}
          >
            <Controller
              control={control}
              name="oldPassword"
              rules={{
                required: {
                  value: true,
                  message: 'Current Password is required',
                },
                minLength: {
                  value: PASSWORD_MINIMUM_LENGTH,
                  message: `Minimum length is ${PASSWORD_MINIMUM_LENGTH}`,
                },
                maxLength: {
                  value: PASSWORD_MAXIMUM_LENGTH,
                  message: `Maximum length is ${PASSWORD_MAXIMUM_LENGTH}`,
                },
              }}
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  variant="outlined"
                  type="password"
                  label="Current Password"
                  error={!!errors.oldPassword}
                  helperText={errors.oldPassword?.message ?? 'Passwords must be a minimum of 8 characters.'}
                />
              )}
            />
            <Controller
              control={control}
              name="newPassword"
              rules={{
                required: { value: true, message: 'New Password is required' },
                minLength: {
                  value: PASSWORD_MINIMUM_LENGTH,
                  message: `Minimum length is ${PASSWORD_MINIMUM_LENGTH}`,
                },
                maxLength: {
                  value: PASSWORD_MAXIMUM_LENGTH,
                  message: `Maximum length is ${PASSWORD_MAXIMUM_LENGTH}`,
                },
                validate: (value) => !value || value !== getValues('oldPassword') || 'Password must be different than current password',
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="outlined"
                  onChange={onChange}
                  value={value}
                  type="password"
                  label="New Password"
                  error={!!errors.newPassword}
                  helperText={errors.newPassword?.message ?? 'Passwords must be a minimum of 8 characters.'}
                />
              )}
            />
            <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
              <Switch
                checked={logoutAllActiveSessions}
                onChange={() => setLogoutAllActiveSessions((logoutAllActiveSessions) => !logoutAllActiveSessions)}
                sx={{ margin: '6px 0px 0px 6px' }}
              />
              <HootTypography isPII={false} variant="bodylarge" sx={{ margin: '6px 0px 0px 6px' }}>
                Log me out of all active sessions
              </HootTypography>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" isLoading={changePasswordMutation.isLoading}>
            Apply
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangePasswordDialog;
