import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '../queryKeys';

export interface DistrictRepEnrolmentSnippetResponse {
  id: string;
  friendlyId: string;
}

export default function useDistrictRepGetEnrolmentSnippet(
  enrolmentId: string,
  options?: Omit<UseQueryOptions<DistrictRepEnrolmentSnippetResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetDistrictRepEnrolmentSnippet, enrolmentId],
    async () => {
      return (
        await axios.get<DistrictRepEnrolmentSnippetResponse>(`${config.apiUrl}/public/district-representative/enrolment/${enrolmentId}/snippet`)
      ).data;
    },
    options,
  );
}
