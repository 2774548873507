export const SAFARI_DESKTOP = 'https://support.apple.com/en-ca/guide/safari/ibrwe2159f50/mac';
export const SAFARI_IOS = 'https://support.apple.com/en-ca/guide/ipad/ipade3312ebe/ipados';

export const FIREFOX = 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions';

export const EDGE =
  'https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857#:~:text=How%20to%20allow%20a%20website%20to%20use%20your%20camera%20or%20microphone%20while%20browsing%20in%20Microsoft%20Edge';

export const CHROME_DESKTOP = 'https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop&oco=1';
export const CHROME_IOS = 'https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DiOS&oco=1';
export const CHROME_ANDROID = 'https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DAndroid&oco=1';
