import { Paper, PaperProps } from '@mui/material';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { Button } from './Button';
import { Icon, IconProps } from './Icon';

interface EmptyCardProps extends Pick<PaperProps, 'sx' | 'ref' | 'children' | 'elevation'> {
  disabled?: boolean;
  button: {
    onClick: () => void;
    text?: string;
    size?: 'small' | 'medium';
    color?: ColorPaletteOption;
    variant?: 'text' | 'outlined' | 'contained';
    icon?: IconProps;
  };
}

const EmptyCard = (props: EmptyCardProps) => {
  const { sx = {}, button, disabled, ...muiCardProps } = props;

  return (
    <Paper
      sx={{
        border: '1px dashed #000',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: 1,
        ...sx,
      }}
      elevation={0}
      {...muiCardProps}
    >
      <Button
        variant={button.variant ?? 'contained'}
        color={button.color ?? undefined}
        size={button.size ?? 'medium'}
        onClick={button.onClick}
        disabled={disabled ?? false}
        sx={{ padding: button.icon ? '17px 24px 17px 8px' : '8px 16px' }}
      >
        {button.icon && <Icon name={button.icon.name ?? 'add'} sx={{ marginRight: '8px' }} />}
        {button.text ?? 'Add'}
      </Button>
    </Paper>
  );
};

export default EmptyCard;
