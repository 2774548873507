import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';
import { Tokens } from '../../../models/api/auth';

async function assumeProfile(profileId: string): Promise<Tokens> {
  const { data } = await axios.post<Tokens>(`${config.apiUrl}/auth/assume-profile`, { profileId });

  return data;
}

export default function useAssumeProfile(options?: Omit<UseMutationOptions<Tokens, AxiosError, string>, 'mutationKey' | 'mutationFn'>) {
  return useMutation((profileId) => assumeProfile(profileId), options);
}
