import { Stack } from '@mui/system';
import { TEACHER_VIDEO_HEIGHT, TEACHER_VIDEO_WIDTH } from '@hoot/constants/daily/videoDimensions';
import HootTypography from '../../core/HootTypography';

export const CameraOff = (): JSX.Element => (
  <Stack
    justifyContent="center"
    alignItems="center"
    sx={{
      width: TEACHER_VIDEO_WIDTH,
      height: TEACHER_VIDEO_HEIGHT,
      backgroundColor: '#000',
      color: '#FFF',
      borderRadius: '8px',
    }}
  >
    <HootTypography isPII={false}>Camera is Off</HootTypography>
  </Stack>
);
