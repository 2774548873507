import { ControllerFieldState } from 'react-hook-form';
import TextField from '@hoot/ui/components/v2/core/TextField';

interface INumberFieldProps {
  name: string;
  label: string;
  onChange: (val: number | string) => void;
  value: number;
  fieldState: ControllerFieldState;
}

export const NumberField = (props: INumberFieldProps) => {
  const { label, onChange, value, fieldState } = props;

  const handleChange = (newValue: string) => {
    const value = newValue ? parseInt(newValue) : '';
    onChange(value);
  };

  return (
    <TextField
      fullWidth
      type="number"
      required
      label={label}
      value={value}
      onChange={(e) => handleChange(e.currentTarget.value)}
      error={!!fieldState.error}
      helperText={!!fieldState.error ? 'Field is required' : null}
    />
  );
};
