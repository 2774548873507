import { Skeleton, Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import useGetLocationDetails from '@hoot/hooks/api/district-rep/useGetLocationDetails';
import useProfile from '@hoot/hooks/useProfile';
import { LocationTypeEnum } from '@hoot/models/api/enums/location-type-enum';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import Card from '@hoot/ui/components/v2/core/Card';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { copyToClipboard } from '@hoot/utils/clipboard';
import { formatStudentLoginUrl, toProperCase } from '@hoot/utils/text-display';

interface LocationDetails {
  billableEntityId: string;
  studentAccessLink: string;
  locationType: LocationTypeEnum;
}

const LocationDetails = () => {
  const dispatch = useAppDispatch();
  const { profile } = useProfile();
  const districtRepId = profile?.id;

  const [locationDetails, setLocationDetails] = useState<LocationDetails>();

  const getLocationDetailsRequest = useGetLocationDetails(districtRepId ?? '', {
    enabled: !!districtRepId,
    onSuccess: (data) => {
      setLocationDetails({
        billableEntityId: data.billableEntityId,
        studentAccessLink: formatStudentLoginUrl(data.billableEntityFriendlyId),
        locationType: data.locationType,
      });
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: 'An error occurred while fetching location details', variant: 'error' }));
    },
  });

  const viewState = useMemo<ViewStateEnum>(() => {
    if (getLocationDetailsRequest.isFetching) {
      return ViewStateEnum.Loading;
    } else {
      return ViewStateEnum.Results;
    }
  }, [getLocationDetailsRequest.isFetching]);

  const copy = () => {
    if (locationDetails?.studentAccessLink) {
      copyToClipboard(locationDetails.studentAccessLink);
      dispatch(createFlashMessage({ message: 'Copied to Clipboard' }));
    }
  };

  return (
    <Card title={`${toProperCase(locationDetails?.locationType ?? 'Location')} Details`} isLoading={getLocationDetailsRequest.isFetching}>
      <Stack gap={2}>
        <ViewState
          state={viewState}
          loadingContent={<SkeletonItems />}
          EmptyStateIllustrationProps={{
            title: 'Your Bookmark card is empty',
            subtitle: 'All saved notifications will be listed here',
          }}
        >
          <ReadOnlyTextField
            label={`${toProperCase(locationDetails?.locationType ?? 'Location')} ID`}
            body={locationDetails?.billableEntityId ?? ''}
            sx={{ background: hootTokens.palette.neutral[190] }}
            invertLabelStyles
          />
          <ReadOnlyTextField
            label={'Student Access Link'}
            body={locationDetails?.studentAccessLink ?? ''}
            sx={{
              background: hootTokens.palette.neutral[190],
              '&:hover': {
                cursor: 'pointer',
              },
              alignItems: 'center',
            }}
            endAdornment={<Icon name="clipboard" />}
            onClick={copy}
            invertLabelStyles
          />
        </ViewState>
      </Stack>
    </Card>
  );
};

const SkeletonItems = () => (
  <Stack gap={2}>
    <Skeleton variant="rounded" sx={{ width: '100%', height: '60px' }} />
    <Skeleton variant="rounded" sx={{ width: '100%', height: '60px' }} />
  </Stack>
);

export default LocationDetails;
