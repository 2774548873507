import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface InstructionalUnitsFilterResponse {
  instructionalUnits: InstructionalUnitFilterOption[];
}

interface InstructionalUnitFilterOption {
  label: string;
  value: string;
}

async function getInstructionalUnitsFilterOptions(): Promise<InstructionalUnitsFilterResponse> {
  const { data } = await axios.get<InstructionalUnitsFilterResponse>(`${config.apiUrl}/library/instructional-units-filter`);
  return data;
}

export default function useGetInstructionalUnitsFilterOptions(
  options?: Omit<UseQueryOptions<InstructionalUnitsFilterResponse>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.GetInstructionalUnitsFilter], () => getInstructionalUnitsFilterOptions(), options);
}
