import * as semver from 'semver';
import { useInterval } from '@hoot/hooks/useInterval';
import { setNewVersionAvailable, useApplicationState } from '@hoot/redux/reducers/applicationSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { IS_PROD, IS_STAGING, LOCAL_APP_VERSION, VERSION_CHECK_PERIOD } from '../../constants/constants';
import useLatestHootReadingAppVersion from '../../hooks/api/useLatestHootReadingAppVersion';

const NewAppVersionInquirer = () => {
  const latestHootReadingAppVersion = useLatestHootReadingAppVersion();
  const applicationState = useApplicationState();

  const dispatch = useAppDispatch();

  const checkAppVersion = async () => {
    try {
      const { data } = await latestHootReadingAppVersion.refetch();
      return data;
    } catch (err) {
      console.error(err);
      return LOCAL_APP_VERSION || '0.0.0';
    }
  };

  const isNewAppVersion = async () => {
    const version = await checkAppVersion();
    if (!version) return false;
    return semver.gt(version, LOCAL_APP_VERSION);
  };

  useInterval(() => {
    if (!IS_PROD && !IS_STAGING) {
      return;
    }
    (async function () {
      if ((await isNewAppVersion()) && !applicationState.newAppVersionAvailable) {
        dispatch(setNewVersionAvailable(true));
      } else {
        dispatch(setNewVersionAvailable(false));
      }
    })();
  }, VERSION_CHECK_PERIOD);

  // This component does not render anything.
  return null;
};

export default NewAppVersionInquirer;
