import { Box, Fade, LinearProgress } from '@mui/material';
import usePageReadAuditor from '@hoot/hooks/usePageReadAuditor';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { UserProfileType } from '@hoot/models/api/user';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { getNextPageId, getPreviousPageId, useReaders } from '@hoot/redux/reducers/readingSlice';
import useTeacherInLessonReaderController from '@hoot/ui/pages/v2/teacher/lesson/library/reader/useTeacherInLessonReaderController';
import TeacherReader from '@hoot/ui/pages/v2/teacher/reader/TeacherReader';

const TeacherInLessonReaderPage = () => {
  usePageTitle('Lesson | Hoot Reading');

  const { inLessonReader } = useReaders();
  const { book, pageIndex, pageZoom, isDoublePage } = inLessonReader;

  const { inLesson } = useActiveLessonState();

  const controller = useTeacherInLessonReaderController();

  usePageReadAuditor({
    book: book ?? undefined,
    pageIndex,
    userProfileType: UserProfileType.Teacher,
    lessonId: inLesson!.lessonId,
  });

  const onGoToNextPage = () => {
    const newPageId = getNextPageId(book!, isDoublePage, pageIndex);
    controller.onGoToPageWithId(newPageId);
  };

  const onGoToPreviousPage = () => {
    const newPageId = getPreviousPageId(book!, isDoublePage, pageIndex);
    controller.onGoToPageWithId(newPageId);
  };

  return (
    <>
      {!book && <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} />}
      {book && (
        <Fade in>
          <Box
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <TeacherReader
              openedBook={book}
              pageIndex={pageIndex}
              pageZoom={pageZoom}
              isDoublePage={isDoublePage}
              onGoToNextPage={onGoToNextPage}
              onGoToPreviousPage={onGoToPreviousPage}
            />
          </Box>
        </Fade>
      )}
    </>
  );
};

export default TeacherInLessonReaderPage;
