import { ButtonBase, ButtonBaseProps, Tooltip } from '@mui/material';
import { Icon, IconName } from '@hoot/ui/components/v2/core/Icon';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import HootTypography from './HootTypography';

interface PlainIconProps extends Omit<ButtonBaseProps, 'children'> {
  icon: IconName;
  tooltipLabel: string;
  text?: string;
}

const PlainIconButton = (props: PlainIconProps) => {
  const { icon, tooltipLabel, text, sx = {}, ...rest } = props;
  return (
    <Tooltip title={tooltipLabel}>
      <ButtonBase
        disableRipple
        aria-label={tooltipLabel}
        sx={{
          width: '44px',
          height: '44px',
          minWidth: 0,
          borderRadius: '4px',
          background: hootTokens.palette.primary['190'],
          ':disabled': {
            opacity: 0.2,
          },
          '&:focus-visible': {
            border: '1px solid',
            borderColor: hootTokens.palette.secondary['80'],
            background: hootTokens.palette.primary['100'],
          },
          ':hover': {
            background: hootTokens.palette.primary['180'],
          },
          ':active': {
            background: hootTokens.palette.primary['160'],
          },
          ...sx,
        }}
        {...rest}
      >
        <Icon name={icon} color="primary.100" />{' '}
        {text ? (
          <HootTypography isPII={false} variant="bodymedium">
            {text}
          </HootTypography>
        ) : null}
      </ButtonBase>
    </Tooltip>
  );
};

export default PlainIconButton;
