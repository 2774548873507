import { Fade, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { capitalCase } from 'change-case';
import { DistrictRepEnrolmentResponse } from '@hoot/hooks/api/district-rep/useDistrictRepresentativeGetEnrolment';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import Card from '@hoot/ui/components/v2/core/Card';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';

interface Props {
  enrolment: DistrictRepEnrolmentResponse | undefined;
  isLoading: boolean;
}

const EnrolmentStatusCard = (props: Props) => {
  const { enrolment, isLoading } = props;

  const getViewState = (): ViewStateEnum => {
    if (isLoading) {
      return ViewStateEnum.Loading;
    } else if (enrolment) {
      return ViewStateEnum.Results;
    } else {
      // If not loading, and we don't have a product, then this is an invalid state.
      return ViewStateEnum.Error;
    }
  };

  const emptyLabel = '---';

  return (
    <Card title="Status" isLoading={isLoading} sx={{ width: '100%' }}>
      <ViewState state={getViewState()} loadingContent={<SkeletonContent />}>
        <Fade in>
          <Grid container spacing={2}>
            <Grid size={12}>
              <ReadOnlyTextField label="Enrolment Status" body={enrolment?.type ? capitalCase(enrolment.status) : emptyLabel} />
            </Grid>
            <Grid size={12}>
              <ReadOnlyTextField label="Roster Status" body={enrolment?.type ? capitalCase(enrolment.rosterStatus) : emptyLabel} />
            </Grid>
          </Grid>
        </Fade>
      </ViewState>
    </Card>
  );
};

const SkeletonContent = () => {
  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={12}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
    </Grid>
  );
};

export default EnrolmentStatusCard;
