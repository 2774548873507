import { Stack } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AbandonAssessmentRequest, useAbandonAssessmentV2 } from '@hoot/hooks/api/assessment/useAbandonAssessmentV2';
import { HootAssessmentV2Response } from '@hoot/hooks/api/assessment/useGetStudentAssessmentsV2';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { error } from '@hoot/redux/reducers/alertSlice';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import TextField from '@hoot/ui/components/v2/core/TextField';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';

interface Form {
  abandonedReason: string;
  confirmAbandonment: boolean;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onAssessmentChanged: (assessment: HootAssessmentV2Response) => void;
  assessmentId: string;
}

const AbandonAssessmentModal = (props: Props) => {
  const { open, onClose, onAssessmentChanged, assessmentId } = props;
  const { control, handleSubmit, watch } = useForm<Form>({
    defaultValues: {
      abandonedReason: undefined,
      confirmAbandonment: false,
    },
    mode: 'onSubmit',
  });
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { abandonedReason, confirmAbandonment } = watch();

  const abandonAssessmentRequest = useAbandonAssessmentV2();

  const isEnabledButton = abandonedReason && confirmAbandonment;

  const onSubmit = async (form: Form): Promise<void> => {
    const request: AbandonAssessmentRequest = {
      assessmentId: assessmentId,
      abandonedReason: form.abandonedReason,
    };

    abandonAssessmentRequest.mutate(request, {
      onSuccess: (updatedAssessment) => {
        queryClient.invalidateQueries(QueryKey.GetStudentAssessmentActions);
        onAssessmentChanged(updatedAssessment);
        dispatch(createFlashMessage({ message: 'Assessment ended' }));
        onClose();
      },
      onError: (ex) => {
        console.error(ex);
        dispatch(error(`An error occurred while attempting to end assessment.`));
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BasicAlertDialog
        show={open}
        onDismiss={onClose}
        title="Confirm End Assessment"
        content={
          <Stack gap={2}>
            <HootTypography isPII={false} variant="bodymedium">
              Are you sure you want to end the assessment?
            </HootTypography>
            <Controller
              name="abandonedReason"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={field?.value ?? ''}
                  fullWidth
                  label="Reason for End"
                  multiline
                  rows={4}
                  variant="outlined"
                  required={true}
                />
              )}
            />
            <Controller
              control={control}
              name="confirmAbandonment"
              render={({ field }) => <Checkbox checked={field.value} onChange={field.onChange} label="I understand that this cannot be undone" />}
            />
          </Stack>
        }
        primaryAction={{
          label: 'End Assessment',
          onClick: handleSubmit(onSubmit),
          props: {
            color: 'error.80',
            isLoading: abandonAssessmentRequest.isLoading,
            disabled: !isEnabledButton,
          },
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: onClose,
        }}
      />
    </form>
  );
};

export default AbandonAssessmentModal;
