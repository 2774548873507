import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useLocation } from 'react-router-dom';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { AppDispatch, RootState } from '../store';

export enum ModalType {
  DisconnectSession = 'DISCONNECT_SESSION',
  Debug = 'DEBUG',
  None = 'NONE',
  Reconnecting = 'RECONNECTING',
  NewAppVersion = 'NEW_APP_VERSION',
  UserMediaPermission = 'GRANT_ACCESS_TO_USER_MEDIA',
}

export interface ModalsState {
  modal: ModalType;
}

const initialState: ModalsState = {
  modal: ModalType.None,
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<ModalType>) => {
      state.modal = action.payload;
    },
  },
});

export const showReconnectModal = createAsyncThunk<void, void, { dispatch: AppDispatch; state: RootState }>(
  'modals/showReconnectModal',
  async (x, thunkApi) => {
    const state = thunkApi.getState();
    const profile = state.profile.profile;

    const location = useLocation();
    const isFreeLessonWizard = location.pathname.includes(routesDictionary.freeLesson.path);

    if (state.modals.modal !== ModalType.Reconnecting && !state.application.isConnected && !!profile && !isFreeLessonWizard) {
      thunkApi.dispatch(modalsSlice.actions.showModal(ModalType.Reconnecting));
    }
    return Promise.resolve();
  },
);

export const { showModal } = modalsSlice.actions;

export default modalsSlice.reducer;
