import axios from 'axios';
import { useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { WhiteboardTemplateResponse } from '@hoot/hooks/api/whiteboard/useGetWhiteboardTemplates';
import { UseGetOptions } from '@hoot/models/api/axios-options';

export enum LessonWhiteboardTemplateStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
}

export interface LessonWhiteboardTemplateResponse {
  whiteboardTemplate: WhiteboardTemplateResponse;
  status: LessonWhiteboardTemplateStatus;
}

export default function useGetLessonWhiteboardTemplates(
  lessonId: string,
  query?: string,
  options?: UseGetOptions<LessonWhiteboardTemplateResponse[]>,
) {
  return useQuery(
    [QueryKey.GetWhiteboardTemplates, query ?? ''],
    async () => {
      return (await axios.get<LessonWhiteboardTemplateResponse[]>(`${config.apiUrl}/whiteboard/template/lesson/${lessonId}`, { params: query })).data;
    },
    options,
  );
}
