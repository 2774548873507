import { useState } from 'react';
import { useRemainingLessonTime } from '@hoot/hooks/lessons/useRemainingLessonTime';
import DisconnectFromSessionModal from '@hoot/ui/pages/v2/teacher/reader/DisconnectFromSessionModal';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { Button } from '../../core/Button';
import HootTypography from '../../core/HootTypography';
import { Icon } from '../../core/Icon';

const LeaveLessonButton = (): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleLeaveLessonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Button onClick={handleLeaveLessonClick} variant="contained" color="error.80" size="small" fullWidth startIcon={<Icon name="phone" />}>
        <HootTypography isPII={false} variant="labelsmall" sx={{ color: hootTokens.palette.white }}>
          Leave Lesson ({useRemainingLessonTime()})
        </HootTypography>
      </Button>

      {showModal ? <DisconnectFromSessionModal closeModal={handleCloseModal} /> : null}
    </>
  );
};

export default LeaveLessonButton;
