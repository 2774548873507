import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

const getLessonCSVReport = async (invoiceId: string) =>
  await axios.get<any>(`${config.apiUrl}/public/teacher-invoicing/${invoiceId}/lesson-details-csv`);

export default function useDownloadLessonCSVReport(invoiceId: string, options?: Omit<UseQueryOptions<any, AxiosError>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetLessonsCSVReport, invoiceId], () => getLessonCSVReport(invoiceId), options);
}
