import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableFooter as MuiTableFooter,
  TableHead as MuiTableHead,
  TablePagination as MuiTablePagination,
  TableRow as MuiTableRow,
  Stack,
  styled,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import ViewStateIllustration, { IllustrationEnum } from '../ViewStateIllustration';
import { Checkbox } from './Checkbox';

export interface HeaderData<T> {
  name: string;
  property: keyof T;
  isSortable?: boolean;
  width?: string;
  isHidden?: boolean;
}

export type SelectableRow = {
  id: string;
  selected: boolean;
  disabled?: boolean;
};

type Paginated =
  | {
      isPaginated: true;
      hideRowsPerPage?: boolean;
      allowRowsPerPage?: boolean;
      onPageChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
      onRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
      count: number;
      page: number;
      rowsPerPage?: number;
      rowsPerPageOptions?: number[];
    }
  | {
      isPaginated?: false;
    };

type Sortable<T> =
  | {
      isSortable?: false;
    }
  | {
      isSortable: true;
      sortBy: keyof T;
      sortOrder: OrderBy;
      onSortBy: (property: keyof T) => void;
    };

export type SelectableData<T> = T extends SelectableRow ? SelectableRow : undefined;

type FieldsForSelectable<T> = SelectableRow & Record<keyof T, any>;

type Selectable<T> =
  | {
      isSelectable?: false;
      data: T[];
    }
  | {
      isSelectable: true;
      data: FieldsForSelectable<T>[];
      isRowSelectable?: boolean;
      onSelectAll?: () => void;
      onSelect?: (val: T, selected: boolean) => void;
    };

export type TableProps<T> = {
  headers: HeaderData<T>[];
  emptyViewState?: JSX.Element;
} & Sortable<T> &
  Paginated &
  Selectable<T>;

const TableCellStyled = styled(MuiTableCell)(({ sx }) => ({
  ...hootTokens.text.tablevalue,
  color: hootTokens.palette.black,
  borderStyle: 'solid',
  borderRightWidth: '0px',
  borderLeftWidth: '0px',
  borderTopWidth: '0px',
  borderBottomWidth: '1px',
  borderColor: hootTokens.palette.primary[190],
  margin: 0,
  padding: '16px 12px',
  sx,
}));

type SelectState = 'none' | 'all' | 'indeterminate';

interface NoResultsProps {
  colspan: number;
  emptyViewState?: JSX.Element;
}

function NoResults(props: NoResultsProps) {
  return (
    <MuiTableRow>
      <MuiTableCell colSpan={props.colspan}>
        <Stack direction="row" justifyContent="center">
          {props.emptyViewState ?? <ViewStateIllustration illustration={IllustrationEnum.NoResults} />}
        </Stack>
      </MuiTableCell>
    </MuiTableRow>
  );
}

function TableHeader<T>(props: {
  headers: HeaderData<T>[];
  isSelectable?: boolean;
  selectedState?: SelectState;
  sortBy?: keyof T;
  sortOrder?: OrderBy;
  onSortBy?: (property: keyof T) => void;
  onSelectAll?: () => void;
}) {
  const sortIcon = props.sortOrder === OrderBy.Asc ? <ArrowDropUp /> : <ArrowDropDown />;

  const handleSelectAll = () => {
    if (props.onSelectAll) {
      props.onSelectAll();
    }
  };

  return (
    <MuiTableHead>
      <MuiTableRow>
        {props.isSelectable ? (
          <MuiTableCell
            sx={{
              ...hootTokens.text.tableheading,
              color: hootTokens.palette.black,
              borderStyle: 'solid',
              borderRightWidth: '0px',
              borderLeftWidth: '0px',
              borderTopWidth: '0px',
              borderBottomWidth: '1px',
              borderColor: hootTokens.palette.primary[100],
              margin: 0,
              padding: '16px 12px',
            }}
            padding="checkbox"
          >
            {props.onSelectAll ? (
              <Checkbox
                onClick={handleSelectAll}
                indeterminate={props.selectedState === 'indeterminate'}
                checked={props.selectedState === 'all'}
                sx={{ margin: 0, padding: 0 }}
                color="primary"
              />
            ) : null}
          </MuiTableCell>
        ) : null}
        {props.headers
          .filter((h) => !h.isHidden)
          .map((h) => (
            <MuiTableCell
              key={h.name}
              sx={{
                ...(props.sortBy === h.property ? hootTokens.text.tableheadingactive : hootTokens.text.tableheading),
                color: hootTokens.palette.black,
                borderStyle: 'solid',
                borderRightWidth: '0px',
                borderLeftWidth: '0px',
                borderTopWidth: '0px',
                borderBottomWidth: '1px',
                borderColor: hootTokens.palette.primary[100],
                cursor: h.isSortable ? 'pointer' : 'default',
                margin: 0,
                padding: '16px 12px',
              }}
              width={h.width}
            >
              <Stack
                sx={{
                  '&:hover svg.isSortable': {
                    opacity: 0.25,
                  },
                }}
                direction="row"
                alignItems="center"
                onClick={() => {
                  if (h.isSortable && props.onSortBy) {
                    props.onSortBy(h.property);
                  }
                }}
              >
                {h.name}
                {props.sortBy === h.property ? sortIcon : h.isSortable ? <ArrowDropUp className="isSortable" sx={{ opacity: 0 }} /> : null}
              </Stack>
            </MuiTableCell>
          ))}
      </MuiTableRow>
    </MuiTableHead>
  );
}

function TableV2Body<T>(props: {
  properties: (keyof T)[];
  data: T[];
  isSelectable?: boolean;
  isRowSelectable?: boolean;
  onSelect?: (val: T, selected: boolean) => void;
}) {
  const handleCheckboxChange = (val: T) => (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (props.onSelect) {
      props.onSelect(val, checked);
    }
  };

  const onRowClick = (val: T) => () => {
    if (props.isSelectable && props.onSelect) {
      props.onSelect(val, (val as SelectableData<T>)!.selected);
    }
  };

  return (
    <>
      {props.data.map((d, idx) => (
        <MuiTableRow
          data-sentry-mask
          key={`${(d as SelectableRow).id}-${idx}`}
          onClick={props.isRowSelectable ? onRowClick(d) : undefined}
          sx={{
            '&:hover': {
              cursor: props.isRowSelectable ? 'pointer' : undefined,
            },
          }}
        >
          {props.isSelectable ? (
            <TableCellStyled padding="checkbox">
              <Checkbox
                sx={{ margin: 0, padding: 0 }}
                checked={(d as SelectableRow).selected}
                onChange={handleCheckboxChange(d)}
                color="primary"
                disabled={(d as SelectableRow).disabled}
              />
            </TableCellStyled>
          ) : null}

          {props.properties.map((p) =>
            d && p ? (
              <TableCellStyled key={p.toString()}>{d[p as keyof (NonNullable<SelectableData<T>> | NonNullable<T>)] as ReactNode}</TableCellStyled>
            ) : null,
          )}
        </MuiTableRow>
      ))}
    </>
  );
}

export function TableV2<T>(props: TableProps<T>) {
  const defaultRowPerPage = props.isPaginated && !!props.rowsPerPageOptions && props.rowsPerPageOptions.length > 0 ? props.rowsPerPageOptions[0] : 10;
  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    if (props.isPaginated && props.onPageChange) {
      props.onPageChange(event, page);
    }
  };

  let selectedState: SelectState = 'none';
  if (props.isSelectable) {
    if (props.data.length > 0 && props.data.every((d) => d?.selected)) {
      selectedState = 'all';
    } else if (props.data.some((d) => d?.selected)) {
      selectedState = 'indeterminate';
    }
  }

  const colspan = props.isSelectable ? props.headers.length + 1 : props.headers.length;

  return (
    <MuiTable
      sx={{
        ...hootTokens.elevation.elevation1,
        outline: `solid 1px ${hootTokens.palette.primary[100]}`,
        borderRadius: '8px',
      }}
    >
      <TableHeader
        selectedState={selectedState}
        isSelectable={props.isSelectable}
        headers={props.headers}
        sortBy={props.isSortable ? props.sortBy : undefined}
        sortOrder={props.isSortable ? props.sortOrder : undefined}
        onSortBy={props.isSortable ? props.onSortBy : undefined}
        onSelectAll={props.isSelectable ? props.onSelectAll : undefined}
      />
      <MuiTableBody>
        {props.data.length === 0 ? (
          <NoResults colspan={colspan} emptyViewState={props.emptyViewState} />
        ) : (
          <TableV2Body
            properties={props.headers.filter((p) => !p.isHidden).map((h) => h.property)}
            data={props.data}
            isSelectable={props.isSelectable}
            isRowSelectable={props.isSelectable ? props.isRowSelectable : undefined}
            onSelect={props.isSelectable ? props.onSelect : undefined}
          />
        )}
      </MuiTableBody>
      {props.isPaginated ? (
        <MuiTableFooter>
          <MuiTableRow>
            <MuiTablePagination
              colSpan={colspan}
              rowsPerPageOptions={props.rowsPerPageOptions ?? [10, 25]}
              count={props.count ?? 0}
              rowsPerPage={props.rowsPerPage ?? defaultRowPerPage}
              slotProps={{ select: { disabled: !props.allowRowsPerPage, sx: props.hideRowsPerPage ? { visibility: 'hidden' } : undefined } }}
              page={props.page ?? 0}
              onPageChange={handlePageChange}
              onRowsPerPageChange={props.onRowsPerPageChange}
              sx={{
                borderBottom: 'none',
                '& .MuiTablePagination-selectLabel': props.hideRowsPerPage
                  ? {
                      visibility: 'hidden',
                    }
                  : undefined,
              }}
            />
          </MuiTableRow>
        </MuiTableFooter>
      ) : null}
    </MuiTable>
  );
}
