import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface FlfSignUpRequestDto {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  agreeToMarketing: boolean;
  agreeToPrivacyPolicy: boolean;
}

export default function useFlfSignupRequest() {
  return useMutation<void, AxiosError, FlfSignUpRequestDto>(async (request) => {
    return (await axios.post(`${config.apiUrl}/free-lesson/v2/sign-up`, request)).data;
  });
}
