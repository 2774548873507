import { CircularProgress, IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { forwardRef } from 'react';
import { IconButtonVariant } from '@hoot/ui/theme/v2/iconButton';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { getColors } from '@hoot/ui/theme/v2/util';

type IconButtonProps = Omit<MuiIconButtonProps, 'color' | 'variant'> & {
  variant?: IconButtonVariant;
  color?: ColorPaletteOption;
  isLoading?: boolean;
  loadingIconSize?: number;
};

export const IconButton = forwardRef<HTMLButtonElement | null, IconButtonProps>((props: IconButtonProps, ref) => {
  const { isLoading, loadingIconSize = 24, color = 'primary', variant = 'standard', children, sx, ...muiIconProps } = props;
  const buttonPalette = getColors(props.color || 'primary');
  let backgroundColor: string = '';
  let border: string = '';
  let iconColor: string = '';
  let activeStyle = {};
  let focusStyle = {};
  let hoverStyle = {};

  if (color === 'primary') {
    if (variant === 'standard') {
      backgroundColor = 'none';
      iconColor = hootTokens.palette.black;
      activeStyle = {
        backgroundColor: hootTokens.palette.primary[160],
      };
      focusStyle = {
        backgroundColor: hootTokens.palette.primary[180],
      };
      hoverStyle = {
        backgroundColor: hootTokens.palette.primary[190],
      };
    } else if (variant === 'contained') {
      backgroundColor = hootTokens.palette.primary[0];
      border = `solid 1px ${hootTokens.palette.primary[0]}`;
      iconColor = hootTokens.palette.white;
      activeStyle = {
        backgroundColor: hootTokens.palette.primary[60],
      };
      focusStyle = {
        backgroundColor: hootTokens.palette.primary[80],
      };
      hoverStyle = {
        backgroundColor: hootTokens.palette.primary[80],
      };
    } else if (variant === 'outlined') {
      backgroundColor = 'none ';
      border = `solid 1px ${hootTokens.palette.black}`;
      iconColor = hootTokens.palette.black;
      activeStyle = {
        backgroundColor: hootTokens.palette.primary[180],
      };
      focusStyle = {
        backgroundColor: hootTokens.palette.primary[180],
      };
      hoverStyle = {
        backgroundColor: hootTokens.palette.primary[190],
      };
    }
  } else {
    if (variant === 'standard') {
      iconColor = buttonPalette.base;
      activeStyle = {
        backgroundColor: buttonPalette.palette[160],
      };
      focusStyle = {
        backgroundColor: buttonPalette.palette[190],
      };
      hoverStyle = {
        backgroundColor: buttonPalette.palette[180],
      };
    } else if (variant === 'contained') {
      iconColor = buttonPalette.font;
      backgroundColor = buttonPalette.base;
      border = `solid 1px ${buttonPalette.base}`;

      activeStyle = {
        backgroundColor: buttonPalette.palette[60],
      };
      focusStyle = {
        backgroundColor: buttonPalette.focus,
      };
      hoverStyle = {
        backgroundColor: buttonPalette.hover,
      };
    } else if (variant === 'outlined') {
      backgroundColor = hootTokens.palette.white;
      border = `solid 1px ${buttonPalette.base}`;
      iconColor = buttonPalette.base;
      activeStyle = {
        backgroundColor: buttonPalette.palette[160],
      };
      focusStyle = {
        backgroundColor: buttonPalette.palette[190],
      };
      hoverStyle = {
        backgroundColor: buttonPalette.palette[180],
      };
    }
  }
  const disabledStyle = (() => {
    // Note: cannot apply "cursor: not-allowed" here because of "pointer-events: none". Need to wrap the button in a div and apply there
    if (variant === 'standard') {
      return {
        '& > svg': {
          color: hootTokens.palette.neutral[140],
        },
        backgroundColor: hootTokens.palette.white,
      };
    } else if (variant === 'contained') {
      return {
        '& > svg': {
          color: hootTokens.palette.white,
        },
        backgroundColor: hootTokens.palette.primary[120],
        border: `solid 1px ${hootTokens.palette.primary[120]}`,
      };
    } else if (variant === 'outlined') {
      return {
        '& > svg': {
          color: hootTokens.palette.neutral[140],
        },
        border: `solid 1px ${hootTokens.palette.neutral[140]}`,
      };
    }
  })();

  return (
    <MuiIconButton
      ref={ref}
      sx={{
        backgroundColor,
        border,
        borderRadius: '8px',
        boxSizing: 'border-box',
        '& > svg': {
          color: iconColor,
        },
        padding: '10px',
        '&:disabled': disabledStyle,
        '&:focus': focusStyle,
        '&:hover': hoverStyle,
        '&:active': activeStyle,
        ...(sx ?? {}),
      }}
      {...muiIconProps}
    >
      {isLoading ? <CircularProgress sx={{ color: iconColor }} size={loadingIconSize} /> : children}
    </MuiIconButton>
  );
});

export default IconButton;
