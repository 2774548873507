import { Property } from 'csstype';
import React from 'react';
import { plainButtonStyles } from '@hoot/ui/components/v2/core/PlainButton';
import LottieButton, { LottieButtonProps } from '@hoot/ui/components/v2/lottie/LottieButton';

interface LottieToggleButtonProps extends LottieButtonProps {
  isSelected: boolean;
  selectedColour?: Property.Color;
}

const LottieToggleButton = (props: LottieToggleButtonProps) => {
  const { isSelected, sx = {}, selectedColour, ...lottieButtonProps } = props;

  return (
    <LottieButton
      sx={{
        ...(isSelected && {
          // Kinda hacky, but we want to ensure the "toggled" style is applied and not overridden by any of the internal <PlainButton> styles when focused.
          background: `${selectedColour ?? plainButtonStyles.active.background} !important`,
          boxShadow: 'unset',
        }),
        ...sx,
      }}
      {...lottieButtonProps}
    />
  );
};

export default LottieToggleButton;
