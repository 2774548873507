import { Box, Skeleton, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { capitalCase } from 'change-case';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useGetDistrictRepresentativeDetails, {
  DistrictRepresentativeStatus,
  Enrolment,
  Location,
} from '@hoot/hooks/api/district-rep/useGetDistrictRepresentativeDetails';
import { LocationTypeEnum } from '@hoot/models/api/enums/location-type-enum';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import Page from '@hoot/ui/components/v2/core/Page';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';
import Tag from '@hoot/ui/components/v2/core/Tag';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { formatPhoneNumber } from '@hoot/utils/text-display';

interface DistrictRepresentativeDetails {
  districtName: string;
  status: DistrictRepresentativeStatus;
  firstName: string;
  lastName: string;
  email: string | null;
  phoneNumber: string | null;
  locations: Location[];
  enrolments: Enrolment[];
}

const TeamMemberDetails = () => {
  const { districtRepId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [districtRepresentativeDetails, setDistrictRepresentativeDetails] = useState<DistrictRepresentativeDetails>();

  const { isLoading } = useGetDistrictRepresentativeDetails(districtRepId ?? '', {
    enabled: !!districtRepId,
    onSuccess: (data) => {
      setDistrictRepresentativeDetails({
        districtName: data.districtName,
        status: data.status,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        locations: data.locations,
        enrolments: data.enrolments,
      });
    },
    onError: (err) => {
      console.error(err);
      dispatch(createFlashMessage({ message: 'An error occurred while district representative details', variant: 'error' }));
    },
  });

  const districtRepDistrict = districtRepresentativeDetails?.locations.filter((location) => location.type === LocationTypeEnum.District)[0];
  const districtRepSchools = districtRepresentativeDetails?.locations.filter((location) => location.type === LocationTypeEnum.School);
  const districtRepEnrolments = districtRepresentativeDetails?.enrolments;

  const handleSchoolClick = (schoolId: string) => {
    navigate(routesDictionary.schools.school.students.url(schoolId), { state: { districtRepId: districtRepId } });
  };

  const handleEnrolmentClick = (enrolmentId: string) => {
    navigate(routesDictionary.enrolments.enrolment.details.url(enrolmentId), { state: { districtRepId: districtRepId } });
  };

  const districtRepFullName = districtRepresentativeDetails
    ? `${districtRepresentativeDetails?.firstName} ${districtRepresentativeDetails?.lastName}`
    : '';

  return (
    <>
      <SubHeader
        title={{
          label: districtRepFullName,
          isPII: true,
          isLoading: isLoading,
        }}
        backButton={{
          show: true,
          navigateBackPath: routesDictionary.teamMembers.url,
        }}
        breadcrumbs={['Team Members', 'Team Member Details']}
      />

      <Page pageTitle="Hoot for Schools Team Members | Hoot Reading" RootBoxProps={{ px: 0 }}>
        {isLoading ? (
          <SkeletonItems />
        ) : (
          <Grid container spacing={2} sx={{ paddingX: 2, paddingBottom: 2 }}>
            <Grid size={{ xs: 12, md: 4 }}>
              <Card title="Status">
                <ReadOnlyTextField label="Account Status" body="Active" />
              </Card>
            </Grid>
            <Grid size={{ xs: 12, md: 8 }} rowSpacing={2}>
              <Stack gap={2}>
                <Card title="Details">
                  <Grid container spacing={2}>
                    <Grid size={{ xs: 12, md: 6 }}>
                      <ReadOnlyTextField label="First Name" body={districtRepresentativeDetails?.firstName ?? ''} />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                      <ReadOnlyTextField label="Last Name" body={districtRepresentativeDetails?.lastName ?? ''} />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                      <ReadOnlyTextField label="Email Address" body={districtRepresentativeDetails?.email ?? ''} />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                      <ReadOnlyTextField label="Phone" body={formatPhoneNumber(districtRepresentativeDetails?.phoneNumber ?? '') ?? ''} />
                    </Grid>
                  </Grid>
                </Card>
                {districtRepDistrict ? (
                  <Card title="District">
                    <Card sx={{ background: hootTokens.palette.neutral[195] }}>
                      <Stack direction="row" gap={4} alignItems="center">
                        <Icon name="district" />
                        <Stack gap={1}>
                          <HootTypography variant="bodylarge" isPII={true}>
                            {districtRepDistrict.name}
                          </HootTypography>
                          <Box>
                            <Tag label={`ID: ${districtRepDistrict.shortId}`} />
                          </Box>
                        </Stack>
                      </Stack>
                    </Card>
                  </Card>
                ) : null}
                {!!districtRepSchools?.length ? (
                  <Card title="School">
                    <Stack direction="column" gap={2}>
                      {districtRepSchools.map((school) => (
                        <Card sx={{ background: hootTokens.palette.neutral[195] }}>
                          <Stack direction="row" gap={4} alignItems="center">
                            <Icon name="location_pin" />
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Stack gap={1}>
                                <HootTypography variant="bodylarge" isPII={false}>
                                  {school.name}
                                </HootTypography>
                                <Box>
                                  <Tag label={`ID: ${school.shortId}`} />
                                </Box>
                              </Stack>
                              <Button variant="contained" size="small" onClick={() => handleSchoolClick(school.id)}>
                                View
                              </Button>
                            </Stack>
                          </Stack>
                        </Card>
                      ))}
                    </Stack>
                  </Card>
                ) : null}
                {!!districtRepEnrolments?.length ? (
                  <Card title="Enrolments">
                    <Stack direction="column" gap={2}>
                      {districtRepEnrolments.map((enrolment) => (
                        <Card sx={{ background: hootTokens.palette.neutral[195] }}>
                          <Stack direction="row" gap={4} alignItems="center">
                            <Icon name="page_white" />
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Stack gap={1}>
                                <HootTypography variant="bodylarge" isPII={false}>
                                  {enrolment.friendlyId}
                                </HootTypography>
                                <Stack direction="row" gap={1} alignItems="center">
                                  <Tag label={enrolment.locationName} />
                                  <Tag label={capitalCase(enrolment.status)} />
                                </Stack>
                              </Stack>
                              <Button variant="contained" size="small" onClick={() => handleEnrolmentClick(enrolment.id)}>
                                View
                              </Button>
                            </Stack>
                          </Stack>
                        </Card>
                      ))}
                    </Stack>
                  </Card>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        )}
      </Page>
    </>
  );
};

const SkeletonItems = () => (
  <Grid container spacing={2} sx={{ paddingX: 2, paddingBottom: 2 }}>
    <Grid size={{ xs: 12, md: 4 }}>
      <Card title="Status">
        <Skeleton variant="text" sx={{ ...hootTokens.text.bodylarge, minHeight: '90px' }} />
      </Card>
    </Grid>
    <Grid size={{ xs: 12, md: 8 }}>
      <Stack gap={2}>
        <Card title="Details">
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Skeleton variant="text" sx={{ ...hootTokens.text.bodylarge, minHeight: '90px' }} />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Skeleton variant="text" sx={{ ...hootTokens.text.bodylarge, minHeight: '90px' }} />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Skeleton variant="text" sx={{ ...hootTokens.text.bodylarge, minHeight: '90px' }} />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Skeleton variant="text" sx={{ ...hootTokens.text.bodylarge, minHeight: '90px' }} />
            </Grid>
          </Grid>
        </Card>
        <Card title="District">
          <Card sx={{ background: hootTokens.palette.neutral[195] }}>
            <Skeleton variant="text" sx={{ ...hootTokens.text.bodylarge, minHeight: '90px' }} />
          </Card>
        </Card>
        <Card title="School">
          <Card sx={{ background: hootTokens.palette.neutral[195] }}>
            <Skeleton variant="text" sx={{ ...hootTokens.text.bodylarge, minHeight: '90px' }} />
          </Card>
          <Card sx={{ background: hootTokens.palette.neutral[195] }}>
            <Skeleton variant="text" sx={{ ...hootTokens.text.bodylarge, minHeight: '90px' }} />
          </Card>
          <Card sx={{ background: hootTokens.palette.neutral[195] }}>
            <Skeleton variant="text" sx={{ ...hootTokens.text.bodylarge, minHeight: '90px' }} />
          </Card>
        </Card>
      </Stack>
    </Grid>
  </Grid>
);

export default TeamMemberDetails;
