import { SvgIcon, SvgIconProps } from '@mui/material';

const AccountCircleFilledIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill={props.htmlColor ?? 'currentColor'}
      fillRule="evenodd"
      d="M22 12c0 3.01-1.34 5.71-3.45 7.54 -1.76 1.52-4.05 2.45-6.56 2.45s-4.81-.93-6.56-2.46c-2.11-1.84-3.45-4.54-3.45-7.55 0-5.53 4.47-10 10-10 5.52 0 10 4.47 10 10Zm-10 7c1.75 0 3.36-.65 4.59-1.72 .14-.13.27-.26.4-.39 -1.09-1.75-2.917-2.9-5.01-2.9 -2.09 0-3.92 1.15-5.01 2.89 .13.13.26.26.4.38 1.22 1.06 2.83 1.71 4.59 1.71Zm0-7c1.65 0 3-1.35 3-3 0-1.66-1.35-3-3-3 -1.66 0-3 1.34-3 3 0 1.65 1.34 3 3 3Z"
    />
  </SvgIcon>
);

export default AccountCircleFilledIcon;
