import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';

export interface DistrictRepresentativeEnrolmentsFilters {
  id: string;
  friendlyId: string;
}

async function searchDistrictRepresentativeEnrolmentsFilters(districtRepresentativeId: string): Promise<DistrictRepresentativeEnrolmentsFilters[]> {
  const { data } = await axios.get<DistrictRepresentativeEnrolmentsFilters[]>(
    `${config.apiUrl}/public/district-representative/${districtRepresentativeId}/enrolments/filters`,
  );

  return data;
}

export default function useSearchDistrictRepresentativeEnrolmentsFilters(
  districtRepresentativeId: string,
  options?: Omit<UseQueryOptions<DistrictRepresentativeEnrolmentsFilters[], AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.SearchDistrictRepresentativeEnrolmentsFilters, districtRepresentativeId],
    () => searchDistrictRepresentativeEnrolmentsFilters(districtRepresentativeId),
    options,
  );
}
