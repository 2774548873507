import { Stack } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { InstructionalFocusStatus, useGetInstructionalFocusReport } from '@hoot/hooks/api/assessment/useGetStudentInstructionalFocusAreasReport';
import { HootAssessmentModule } from '@hoot/models/api/enums/hoot-reading-assessment';
import StudentCompletedOrNotStartedModules from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/instructional-focus-areas/StudentCompletedOrNotStartedModules';
import StudentInProgressModules from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/instructional-focus-areas/StudentInProgressModules';
import StudentTextReadingsCard from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/instructional-focus-areas/StudentTextReadingsCard';

const StudentInstructionalFocusAreas = () => {
  const { studentProfileId } = useParams();
  const { data } = useGetInstructionalFocusReport(studentProfileId!);

  const masteredInstructionalFocusAreas = data?.filter(
    (module) =>
      [InstructionalFocusStatus.Passed, InstructionalFocusStatus.Mastered].includes(module.status) && module.module !== HootAssessmentModule.Four,
  );
  const inProgressInstructionalFocusAreas = data?.filter(
    (module) => module.status === InstructionalFocusStatus.InProgress && module.module !== HootAssessmentModule.Four,
  );
  const notStartedInstructionalFocusAreas = data?.filter((module) => module.status === InstructionalFocusStatus.NotStarted);
  const isModuleFourInProgress = data?.find(
    (module) => module.module === HootAssessmentModule.Four && module.status === InstructionalFocusStatus.InProgress,
  );
  const isAllModulesNotStarted = !data || data.every((module) => module.status === InstructionalFocusStatus.NotStarted);

  return (
    <Stack gap={2}>
      <StudentCompletedOrNotStartedModules
        completed={true}
        title="Completed Instructional Focus Areas"
        instructionalFocusModule={masteredInstructionalFocusAreas}
      />
      <StudentInProgressModules instructionalFocusModule={inProgressInstructionalFocusAreas} />
      <StudentCompletedOrNotStartedModules
        isAllNotStarted={isAllModulesNotStarted}
        title="Not Started Instructional Focus Areas"
        instructionalFocusModule={notStartedInstructionalFocusAreas}
      />
      {isModuleFourInProgress ? <StudentTextReadingsCard /> : null}
    </Stack>
  );
};

export default StudentInstructionalFocusAreas;
