import createPalette, { PaletteColorOptions } from '@mui/material/styles/createPalette';
import { HootColor, HootPalette, hootTokens } from '@hoot/ui/theme/v2/tokens';

// Color
type ColorOption = keyof Omit<HootColor, 'black' | 'white'>;
type PaletteOption = keyof HootPalette;
export type ColorPaletteOption = `${ColorOption}.${PaletteOption}` | ColorOption;

const colorKeys = Object.keys(hootTokens.palette).filter((p) => p !== 'black' && p !== 'white');

const colorPaletteOptions = colorKeys.flatMap((p) => Object.keys(hootTokens.palette.primary).map((c) => `${p}.${c}`)) as ColorPaletteOption[];

export const paletteOptions = [...colorKeys, ...colorPaletteOptions];

// generate all options for color
const paletteOverride = paletteOptions.reduce((a, b) => {
  const parts = b.split('.');
  const color: ColorOption = parts[0] as any;
  const luminescence: PaletteOption = parts.length <= 2 ? (parts[1] as any) : 80;
  return {
    ...a,
    [b]: {
      main: hootTokens.palette[color][luminescence],
    } as PaletteColorOptions,
  };
}, {}) as Record<ColorPaletteOption, PaletteColorOptions>;

const palette = createPalette({
  ...paletteOverride,
  background: {
    default: hootTokens.palette.neutral['190'],
  },
  primary: {
    main: hootTokens.palette.primary['0'],
  },
  secondary: {
    main: hootTokens.palette.secondary['80'],
  },
  error: {
    main: hootTokens.palette.error['80'],
  },
  warning: {
    main: hootTokens.palette.warning['80'],
  },
  success: {
    main: hootTokens.palette.success['80'],
  },
  info: {
    main: hootTokens.palette.secondary['80'],
  },
  action: {
    disabled: hootTokens.palette.neutral['140'],
  },
  text: {
    primary: hootTokens.palette.primary['0'],
    disabled: hootTokens.palette.neutral['140'],
  },
});
export default palette;
