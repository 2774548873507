import { SvgIcon, SvgIconProps } from '@mui/material';

const NewTabIcon2 = (props: SvgIconProps) => {
  return (
    <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M5 11.0002H7C9.20914 11.0002 11 12.7911 11 15.0002V17.0002M5 11.0002C2.79086 11.0002 1 12.7911 1 15.0002V17.0002C1 19.2094 2.79086 21.0002 5 21.0002H7C9.20914 21.0002 11 19.2094 11 17.0002M5 11.0002V5.00024C5 2.79111 6.79086 1.00024 9 1.00024H17C19.2091 1.00024 21 2.79111 21 5.00024V13.0002C21 15.2094 19.2091 17.0002 17 17.0002H11M11 6.00024H16M16 6.00024V11.0002M16 6.00024L10 12.0002"
        stroke={props.htmlColor ?? '#000'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default NewTabIcon2;
