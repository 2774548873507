import axios, { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';

async function retractInvoiceSubmission(invoiceId: string): Promise<AxiosResponse<void>> {
  return await axios.put<void>(`${config.apiUrl}/public/teacher-invoicing/${invoiceId}/retract-invoice`);
}

export default function useRetractInvoiceSubmission() {
  return useMutation((invoiceId: string) => {
    return retractInvoiceSubmission(invoiceId);
  });
}
