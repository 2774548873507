import { SvgIcon, SvgIconProps } from '@mui/material';

const Copy = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0_12732_28356)">
        <path
          d="M8 8.00024V6.00024C8 3.7911 9.79086 2.00024 12 2.00024H18C20.2091 2.00024 22 3.7911 22 6.00024V12.0002C22 14.2093 20.2091 16.0002 18 16.0002H16M8 8.00024H6C3.79086 8.00024 2 9.7911 2 12.0002V18.0002C2 20.2093 3.79086 22.0002 6 22.0002H12C14.2091 22.0002 16 20.2093 16 18.0002V16.0002M8 8.00024H12C14.2091 8.00024 16 9.7911 16 12.0002V16.0002"
          stroke={props.htmlColor ?? 'currentColor'}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12732_28356">
          <rect width="24" height="24" fill="white" transform="translate(0 0.000244141)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Copy;
