import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Outlet } from 'react-router-dom';
import FlashMessage from '@hoot/ui/components/v2/FlashMessage';
import { CookieConsentDialog } from '@hoot/ui/components/v2/dialogs/CookieConsentDialog';
import { hootThemeV2Public } from '@hoot/ui/theme/v2';

const PublicRouterLayoutV2 = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ThemeProvider theme={hootThemeV2Public}>
        <CssBaseline />
        <Outlet />
        <FlashMessage />
        <CookieConsentDialog />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default PublicRouterLayoutV2;
