import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';

export interface TeacherInvoiceBillableLessonsQuery {
  sortBy: string;
  orderBy: OrderBy;
}

export interface BillableLessonsSummaryRow {
  language: string;
  month: string;
  type: string;
  duration: number | '-';
  quantity: number;
  total: number;
}

export interface BillableLessonsSummaryResponse {
  invoiceId: string;
  isInvoiceSubmitted: boolean;
  isInvoicePartiallySubmitted: boolean;
  submittedLessonSummaryRows: BillableLessonsSummaryRow[];
  unsubmittedLessonSummaryRows: BillableLessonsSummaryRow[];
}

async function getBillableLessonSummary(
  invoiceId: string,
  searchParams: TeacherInvoiceBillableLessonsQuery,
): Promise<BillableLessonsSummaryResponse> {
  const { data } = await axios.get<BillableLessonsSummaryResponse>(`${config.apiUrl}/public/teacher-invoicing/${invoiceId}/billable-lesson-summary`, {
    params: searchParams,
  });

  return data;
}

export default function useGetBillableLessonSummary(
  invoiceId: string,
  searchParams: TeacherInvoiceBillableLessonsQuery,
  options?: Omit<UseQueryOptions<BillableLessonsSummaryResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetInvoiceBillableLessonSummary, invoiceId, searchParams],
    () => getBillableLessonSummary(invoiceId, searchParams),
    options,
  );
}
