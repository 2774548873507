import React from 'react';

export interface WindowSize {
  height: number;
  width: number;
}

export enum Orientation {
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT',
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState<WindowSize>({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });
  const [orientation, setOrientation] = React.useState<Orientation>(Orientation.Landscape);

  React.useEffect(() => {
    function handleResize() {
      const width = document.documentElement.clientWidth;
      const height = document.documentElement.clientHeight;
      setWindowSize({ width, height });

      if (width < height) {
        setOrientation(Orientation.Portrait);
      } else {
        setOrientation(Orientation.Landscape);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    orientation,
    windowSize,
  };
}
