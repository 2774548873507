import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReaderContext, getCorrectedPageId, useReaders } from '@hoot/redux/reducers/readingSlice';
import TeacherReaderAppBar from '@hoot/ui/components/v2/core/TeacherReaderAppBar';
import useTeacherOutOfLessonReaderController from '@hoot/ui/pages/v2/teacher/library/reader/useTeacherOutOfLessonReaderController';
import { TeacherStudentSpecificLibraryReaderPageQueryParams } from '@hoot/ui/pages/v2/teacher/my-students/student-details/library/reader/TeacherStudentSpecificLibraryReaderPage';

export interface TeacherStudentSpecificReaderAppBarProps {
  studentProfileId: string;
}

const readerContext = ReaderContext.StudentSpecificLibraryReader;

const TeacherStudentSpecificReaderAppBar = (props: TeacherStudentSpecificReaderAppBarProps) => {
  const { studentProfileId } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const controller = useTeacherOutOfLessonReaderController({ readerContext, studentProfileId });

  const reader = useReaders().studentSpecificLibraryReader;
  const { book, pageIndex, isDoublePage } = reader;

  const _onToggleDoublePage = () => {
    if (!book) {
      return;
    }
    const newSearchParams = new URLSearchParams(searchParams);

    const updatedIsDoublePage = !isDoublePage;
    newSearchParams.set(TeacherStudentSpecificLibraryReaderPageQueryParams.DoublePage, Boolean(updatedIsDoublePage).toString());

    // If we're going into double-page mode AND the book is "standard double page", then we may need to update the
    // current page index so that we keep the correct pair of pages visible.
    if (updatedIsDoublePage) {
      const updatedPageId = getCorrectedPageId(book, updatedIsDoublePage, pageIndex);
      newSearchParams.set(TeacherStudentSpecificLibraryReaderPageQueryParams.PageId, updatedPageId);
    }
    setSearchParams(newSearchParams, { replace: true });
  };

  return (
    <TeacherReaderAppBar
      readerContext={readerContext}
      studentProfileId={studentProfileId}
      onToggleDoublePage={_onToggleDoublePage}
      onZoomAmountChange={controller.onZoomAmountChange}
      onZoomOffsetChange={controller.onZoomOffsetChange}
      onResetZoom={controller.onResetZoom}
      onCloseBook={controller.onCloseBook}
      onToggleFavouriteBook={controller.onToggleFavouriteBook}
    />
  );
};

export default TeacherStudentSpecificReaderAppBar;
