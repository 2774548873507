export enum WhiteboardTemplateTypeEnum {
  ShortVowelsCVC = 'SHORT_VOWELS_CVC',
  Digraphs = 'DIGRAPHS',
  BlendsLongVowels = 'BLENDS_LONG_VOWELS',
  BasicRControlledVowels = 'BASIC_R_CONTROLLED_VOWELS',
  BasicVowelTeams = 'BASIC_VOWEL_TEAMS',
  SpellingWords4Letters = 'SPELLING_WORDS_4_LETTERS',
  SpellingWords5Letters = 'SPELLING_WORDS_5_LETTERS',
  SpellingWords6Letters = 'SPELLING_WORDS_6_LETTERS',
  VennDiagram = 'VENN_DIAGRAM',
  BasicSituationModel = 'BASIC_SITUATION_MODEL',
}
