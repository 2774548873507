import { Box, Card, Stack, Switch } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PASSWORD_MAXIMUM_LENGTH, PASSWORD_MINIMUM_LENGTH, SNACKBAR_AUTO_HIDE_DURATION } from '@hoot/constants/constants';
import { EventType } from '@hoot/events/eventType';
import { useChangePassword } from '@hoot/hooks/api/auth/useChangePassword';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Page from '@hoot/ui/components/v2/core/Page';
import { Snackbar } from '@hoot/ui/components/v2/core/Snackbar';
import TextField from '@hoot/ui/components/v2/core/TextField';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import { useSocket } from '@hoot/ui/context/SocketContext';
import HootTypography from '../../../../components/v2/core/HootTypography';
import { useCookieConsent } from '../../../../context/CookieConsentContext';

export interface DistrictRepSettingsForm {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export function DistrictRepSettings() {
  const { socket } = useSocket();
  const changePasswordMutation = useChangePassword();

  const { showModal } = useCookieConsent();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showError, setShowError] = useState<string>('');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [logoutAllActiveSessions, setLogoutAllActiveSessions] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    getFieldState,
    formState,
    formState: { isDirty, errors },
  } = useForm<DistrictRepSettingsForm>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  const { isDirty: passwordIsDirty } = getFieldState('newPassword', formState);

  const onSave = async () => {
    const { oldPassword, newPassword, confirmNewPassword } = getValues();

    if (passwordIsDirty) {
      changePasswordMutation.mutate(
        {
          oldPassword: oldPassword,
          newPassword: newPassword,
          confirmNewPassword: confirmNewPassword,
          logoutAllActiveSessions: logoutAllActiveSessions,
        },
        {
          onSuccess: () => {
            setShowSuccess(true);
            if (logoutAllActiveSessions) {
              socket.emit(EventType.DisconnectAllOtherProfiles);
            }
            reset({ oldPassword: '', newPassword: '', confirmNewPassword: '' });
          },
          onError: (exc) => {
            const errorText = 'There was a problem changing your password. \n Contact help@hootreading.com';
            console.error(exc.message);
            setShowError(errorText);
          },
        },
      );
    }
  };

  const handleClose = () => {
    setShowDialog(false);
    setShowError('');
  };

  const handleManageCookies = () => {
    showModal();
  };

  return (
    <>
      <SubHeader
        title={{
          label: 'Settings',
          isPII: false,
        }}
      />

      <Page pageTitle="District Representative Settings | Hoot Reading">
        <form onSubmit={handleSubmit(onSave)} id="district-rep-settings-form">
          <Snackbar
            variant="dark"
            message={'Settings saved successfully'}
            open={showSuccess}
            onClose={() => setShowSuccess(false)}
            autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          />
          <Snackbar
            variant="error"
            message={'An unexpected error occurred'}
            actionLabel="View Error"
            open={!!showError}
            onAction={() => setShowDialog(true)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          />
          <BasicAlertDialog
            show={showDialog}
            onDismiss={handleClose}
            title={'Error'}
            content={showError}
            primaryAction={{
              onClick: handleClose,
              label: 'OK',
            }}
          />
          <Stack>
            <Grid container gap="16px">
              <Grid size={{ xs: 12, md: 8 }}>
                <Stack>
                  <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: '16px' }}>
                    <HootTypography isPII={false} variant="titlemedium">
                      Change Password
                    </HootTypography>
                    <Controller
                      control={control}
                      name="oldPassword"
                      rules={{
                        required: {
                          value: !!getValues('newPassword') || !!getValues('confirmNewPassword'),
                          message: 'Current Password is required',
                        },
                        minLength: {
                          value: PASSWORD_MINIMUM_LENGTH,
                          message: `Minimum length is ${PASSWORD_MINIMUM_LENGTH}`,
                        },
                        maxLength: {
                          value: PASSWORD_MAXIMUM_LENGTH,
                          message: `Maximum length is ${PASSWORD_MAXIMUM_LENGTH}`,
                        },
                      }}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          value={value}
                          onChange={onChange}
                          variant="outlined"
                          type="password"
                          label="Current Password"
                          sx={{ maxWidth: { sm: '100%', md: '50%' } }}
                          error={!!errors.oldPassword}
                          helperText={errors.oldPassword?.message ?? ''}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="newPassword"
                      rules={{
                        required: { value: !!getValues('oldPassword'), message: 'New Password is required' },
                        minLength: {
                          value: PASSWORD_MINIMUM_LENGTH,
                          message: `Minimum length is ${PASSWORD_MINIMUM_LENGTH}`,
                        },
                        maxLength: {
                          value: PASSWORD_MAXIMUM_LENGTH,
                          message: `Maximum length is ${PASSWORD_MAXIMUM_LENGTH}`,
                        },
                        validate: (value) => !value || value !== getValues('oldPassword') || 'Password must be different than current password',
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          type="password"
                          label="New Password"
                          sx={{ maxWidth: { sm: '100%', md: '50%' } }}
                          error={!!errors.newPassword}
                          helperText={errors.newPassword?.message ?? 'Passwords must be a minimum of 8 characters.'}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="confirmNewPassword"
                      rules={{
                        minLength: {
                          value: PASSWORD_MINIMUM_LENGTH,
                          message: `Minimum length is ${PASSWORD_MINIMUM_LENGTH}`,
                        },
                        maxLength: {
                          value: PASSWORD_MAXIMUM_LENGTH,
                          message: `Maximum length is ${PASSWORD_MAXIMUM_LENGTH}`,
                        },
                        validate: (value) => value === getValues('newPassword') || 'Passwords do not match.',
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          variant="outlined"
                          type="password"
                          onChange={onChange}
                          value={value}
                          label="Repeat New Password"
                          sx={{ maxWidth: { sm: '100%', md: '50%' } }}
                          error={!!errors.confirmNewPassword}
                          helperText={errors.confirmNewPassword?.message ?? 'Passwords must be a minimum of 8 characters.'}
                        />
                      )}
                    />
                    <HootTypography isPII={false} variant="titlemedium">
                      Options
                    </HootTypography>
                    <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                      <Switch
                        checked={logoutAllActiveSessions}
                        onChange={() => setLogoutAllActiveSessions((logoutAllActiveSessions) => !logoutAllActiveSessions)}
                        sx={{ margin: '6px 0px 0px 6px' }}
                      />
                      <HootTypography isPII={false} variant="bodylarge" sx={{ margin: '6px 0px 0px 6px' }}>
                        Log me out of all active sessions
                      </HootTypography>
                    </Box>
                    <Grid size={12} sx={{ marginTop: '16px' }}>
                      <Button variant="contained" children="Save" type="submit" disabled={!isDirty} />
                    </Grid>
                  </Card>
                </Stack>
              </Grid>
              <Grid size={{ xs: 12, md: 8 }}>
                <Stack>
                  <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: '16px' }}>
                    <HootTypography isPII={false} variant="titlemedium">
                      A Note on Cookies
                    </HootTypography>
                    <HootTypography isPII={false} variant="bodylarge">
                      We use cookies and other identifiers to ensure the functionality of our app and offer the best possible in-app experience. You
                      can choose for each category to opt in or out and can view your settings. You can learn more about how we use cookies and other
                      identifiers in the Cookies section of our{' '}
                      <a href="https://www.hootreading.com/app-privacy" target="_blank" rel="noreferrer">
                        Web App Privacy Policy
                      </a>
                      .
                    </HootTypography>

                    <Grid size={12}>
                      <Button variant="outlined" onClick={handleManageCookies}>
                        Manage Cookies
                      </Button>
                    </Grid>
                  </Card>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </Page>
    </>
  );
}
