import { SvgIcon, SvgIconProps } from '@mui/material';

const Collections = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4 15.0002H15C16.6569 15.0002 18 16.3434 18 18.0002C18 19.6571 16.6569 21.0002 15 21.0002H4M4 15.0002V21.0002M4 15.0002H2M4 21.0002H2M4 3.00024H15C16.6569 3.00024 18 4.34339 18 6.00024C18 7.6571 16.6569 9.00024 15 9.00024H4M4 3.00024V9.00024M4 3.00024H2M4 9.00024H2M20 9.00024H9C7.34315 9.00024 6 10.3434 6 12.0002C6 13.6571 7.34315 15.0002 9 15.0002H20M20 9.00024V15.0002M20 9.00024H22M20 15.0002H22"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default Collections;
