import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';
import { config } from '../../../config';
import { Tokens } from '../../../models/api/auth';

async function refreshUserToken(refreshToken: string): Promise<Tokens> {
  const { data } = await axios.post<Tokens>(`${config.apiUrl}/auth/refresh-token`, {
    refreshToken: refreshToken,
  });

  return data;
}

export function useRefreshToken(options?: Omit<UseMutationOptions<Tokens, AxiosError, string>, 'mutationKey' | 'mutationFn'>) {
  return useMutation<Tokens, AxiosError, string>((refreshToken) => refreshUserToken(refreshToken), options);
}
