import { DailyVideo, useAudioTrack, useLocalSessionId, useVideoTrack } from '@daily-co/daily-react';
import { Dialog, DialogContent, DialogTitle, useMediaQuery } from '@mui/material';
import { Box, Stack, useTheme } from '@mui/system';
import { useState } from 'react';
import { useStudentMediaState } from '@hoot/hooks/daily/useDailyMediaState';
import { Orientation, useWindowSize } from '@hoot/hooks/useWindowSize';
import PlainIconButton from '@hoot/ui/components/v2/core/PlainIconButton';
import Lottie, { LottieFile } from '@hoot/ui/components/v2/lottie/Lottie';
import LottieButton from '@hoot/ui/components/v2/lottie/LottieButton';
import { useAudioVideoSettingsDialog } from '@hoot/ui/context/AudioVideoSettingsDialogContext';
import { useGetReadyV2Dialog } from '@hoot/ui/context/GetReadyV2DialogProvider';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import StudentInLessonExitDialog from '../../StudentInLessonExitDialog';

type ModalState = 'in-lesson-settings' | 'exit';

const DailyStudentInLessonSettingsDialog = () => {
  const localSessionId = useLocalSessionId();
  const videoState = useVideoTrack(localSessionId);
  const audioState = useAudioTrack(localSessionId);

  const theme = useTheme();
  const isBelowDesktop = useMediaQuery(theme.breakpoints.down('md'));
  const { orientation } = useWindowSize();
  const isTabletPortrait = orientation === Orientation.Portrait && isBelowDesktop;

  const { toggleCamera, toggleMicrophone, isLocked: studentLocked } = useStudentMediaState();

  const { dismissStudent } = useGetReadyV2Dialog();
  const { onShow: showAvSettingsDialog, onDismissStudentInLesson } = useAudioVideoSettingsDialog();
  const [modalState, setModalState] = useState<ModalState>('in-lesson-settings');

  const isCameraEnabled = !videoState.isOff;
  const isMicrophoneEnabled = !audioState.isOff;

  const [startCameraAnimation, setStartCameraAnimation] = useState<boolean>(!isCameraEnabled);
  const [startMicrophoneAnimation, setStartMicrophoneAnimation] = useState<boolean>(!isMicrophoneEnabled);

  const toggleVideo = () => {
    setStartCameraAnimation(true);
    toggleCamera();
  };

  const toggleAudio = () => {
    setStartMicrophoneAnimation(true);
    toggleMicrophone();
  };

  const handleExit = () => {
    setStartCameraAnimation(!isCameraEnabled);
    setStartMicrophoneAnimation(!isMicrophoneEnabled);
  };

  const handleSettingsClick = () => {
    dismissStudent();
    showAvSettingsDialog();
  };

  switch (modalState) {
    case 'in-lesson-settings':
      return (
        <Dialog open={true} onClose={onDismissStudentInLesson} TransitionProps={{ onExited: handleExit }}>
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '24px 28px 32px 48px',
              ...hootTokens.text.displaysmall,
            }}
          >
            <span>Menu</span>
            <LottieButton
              tooltipLabel="Apply"
              lottieFile={LottieFile.Checkmark}
              aria-label={'apply'}
              sx={{ boxShadow: undefined, background: 'primary.190', padding: 0, margin: 0 }}
              onClick={onDismissStudentInLesson}
              lottieWidth={80}
              lottieHeight={80}
            />
          </DialogTitle>
          <DialogContent>
            <Stack padding={'0px 32px 16px 32px'} gap={'10px'} alignItems="center">
              <Stack
                sx={{
                  padding: 4,
                  gap: 2,
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    background: isCameraEnabled ? hootTokens.palette.black : hootTokens.surface['1'],
                    padding: 2,
                    borderRadius: '8px',
                    width: '320px',
                    height: '210px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    ...hootTokens.elevation.elevation1,
                  }}
                  onClick={!isCameraEnabled ? toggleVideo : undefined}
                >
                  {/* TODO: Add Audio Visualizer */}
                  {!videoState.isOff ? (
                    <DailyVideo
                      sessionId={localSessionId}
                      type={'video'}
                      automirror={true}
                      autoPlay={true}
                      muted={true}
                      width="100%"
                      height="100%"
                      fit="cover"
                      style={{
                        maxHeight: isTabletPortrait ? '320px' : '240px',
                        borderRadius: '8px',
                        transform: 'scale(-1, 1)',
                      }}
                    />
                  ) : (
                    <Lottie lottieFile={LottieFile.Camera} loop={false} play={true} style={{ width: '102px', height: '101px' }} />
                  )}
                </Box>
                <PlainIconButton
                  onClick={handleSettingsClick}
                  icon="gear"
                  tooltipLabel="Device settings"
                  sx={{ width: '100%', gap: 1, display: 'flex', alignItems: 'center' }}
                  text="Device Settings"
                />
              </Stack>
              <Stack gap={6} sx={{ paddingBottom: 4 }}>
                <Stack direction="row" gap={2}>
                  <LottieButton
                    fullWidth
                    lottieFile={LottieFile.Camera}
                    tooltipLabel={`${isCameraEnabled ? 'Disable' : 'Enable'} Camera`}
                    aria-label={`Toggle Camera`}
                    lottieWidth={80}
                    lottieHeight={80}
                    variant="plain"
                    onClick={toggleVideo}
                    LottieProps={{
                      play: startCameraAnimation || !isCameraEnabled,
                      loop: false,
                      direction: isCameraEnabled ? -1 : 1,
                    }}
                  />
                  <LottieButton
                    fullWidth
                    lottieFile={LottieFile.Microphone}
                    tooltipLabel={`${isMicrophoneEnabled ? 'Disable' : 'Enable'} Microphone`}
                    aria-label="Toggle Microphone"
                    lottieWidth={80}
                    lottieHeight={80}
                    variant="plain"
                    disabled={studentLocked}
                    onClick={toggleAudio}
                    LottieProps={{
                      play: startMicrophoneAnimation || !isMicrophoneEnabled,
                      loop: false,
                      direction: isMicrophoneEnabled ? -1 : 1,
                    }}
                  />
                  <LottieButton
                    fullWidth
                    lottieFile={LottieFile.Exit}
                    tooltipLabel="Exit lesson"
                    aria-label="Exit lesson"
                    lottieWidth={80}
                    lottieHeight={80}
                    variant="plain"
                    onClick={() => setModalState('exit')}
                  />
                </Stack>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      );

    case 'exit':
      return <StudentInLessonExitDialog show={true} handleClose={onDismissStudentInLesson} />;
  }
};

export default DailyStudentInLessonSettingsDialog;
