import { Stack } from '@mui/system';
import React, { useState } from 'react';
import { AssessmentActionResponse } from '@hoot/hooks/api/my-student/useGetAssessmentActions';
import { StudentProfileSnippetResponse } from '@hoot/hooks/api/user/useGetStudentProfileSnippet';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import PopoverMenu from '@hoot/ui/components/v2/core/PopoverMenu';
import LaunchAssessmentButton from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/LaunchAssessmentButton';
import DeferAssessmentActionDialog from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/action-required-card/DeferAssessmentActionDialog';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

const AssessmentActions = (props: { action: AssessmentActionResponse; studentProfileSnippet: StudentProfileSnippetResponse }) => {
  const { action, studentProfileSnippet } = props;
  const [assessmentActionsAnchorElement, setAssessmentActionsAnchorElement] = useState<null | HTMLElement>(null);
  const showAssessmentActionsMenu = Boolean(assessmentActionsAnchorElement);
  const [showDeferAssessmentDialog, setShowDeferAssessmentDialog] = useState<boolean>(false);

  const onShowAssessmentActionsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAssessmentActionsAnchorElement(event.currentTarget);
  };

  const onDismissAssessmentActionsMenu = () => {
    setAssessmentActionsAnchorElement(null);
  };

  const showDeferDialog = () => {
    setShowDeferAssessmentDialog(true);
    setAssessmentActionsAnchorElement(null);
  };

  return (
    <Stack direction="row" gap={2}>
      <PopoverMenu
        id="assessment-actions-menu"
        variant="dark"
        anchorEl={assessmentActionsAnchorElement}
        open={showAssessmentActionsMenu}
        onClose={onDismissAssessmentActionsMenu}
        items={[
          {
            icon: <Icon name="minus_circle" htmlColor={hootTokens.palette.white} />,
            id: 'defer-assessment',
            label: 'Defer Assessment',
            onClick: showDeferDialog,
          },
        ]}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
      {showDeferAssessmentDialog ? (
        <DeferAssessmentActionDialog action={action} open={showDeferAssessmentDialog} setOpen={setShowDeferAssessmentDialog} />
      ) : null}
      {action.lessonId ? (
        <Button
          onClick={onShowAssessmentActionsMenu}
          variant="outlined"
          id="assessment-actions-button"
          startIcon={<Icon name="chevron" sx={{ rotate: '270deg' }} />}
        >
          Actions
        </Button>
      ) : null}
      <LaunchAssessmentButton assessmentIdToResume={action.assessmentId} studentProfileSnippet={studentProfileSnippet} />
    </Stack>
  );
};

export default AssessmentActions;
