import { ChipGroupProps } from '@hoot/ui/components/v2/core/ChipGroup';
import { BookResponse } from '../../events/interfaces/book-response';

export type BookOrderBy = keyof BookResponse;

export enum Subject {
  Reading = 'READING',
  Math = 'MATH',
}

export const subjectTypes = (subject?: Subject | null): string | undefined => {
  if (!subject) return undefined;
  const map = {
    [Subject.Reading]: 'Reading',
    [Subject.Math]: 'Math',
  };
  return map[subject] ?? undefined;
};

export enum Language {
  English = 'EN',
  Spanish = 'ES',
  French = 'FR',
}

export enum ResourceState {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Read = 'READ',
}

export const resourceStateTypes = (resourceState?: ResourceState | null): string | undefined => {
  if (!resourceState) return undefined;
  const map = {
    [ResourceState.NotStarted]: 'Not Started',
    [ResourceState.InProgress]: 'In Progress',
    [ResourceState.Read]: 'Read',
  };
  return map[resourceState] ?? undefined;
};

export enum ResourceProgress {
  NotStarted = 'NOT_STARTED',
  Practicing = 'PRACTICING',
  Finished = 'FINISHED',
}

export const resourceProgressTypes = (resourceProgress?: ResourceProgress | null): string | undefined => {
  if (!resourceProgress) return undefined;
  const map = {
    [ResourceProgress.NotStarted]: 'Not Started',
    [ResourceProgress.Practicing]: 'Practicing',
    [ResourceProgress.Finished]: 'Finished',
  };
  return map[resourceProgress] ?? undefined;
};

export enum BookType {
  HootReadingAssessment = 'HOOT_READING_ASSESSMENT',
  Instruction = 'INSTRUCTION',
  FormativeAssessment = 'FORMATIVE_ASSESSMENT',
  SpecialProjects = 'SPECIAL_PROJECTS',
  ProgressMonitoringAssessment = 'PROGRESS_MONITORING_ASSESSMENT',
}

export enum DecodableFormat {
  WiseWords = 'WISE_WORDS',
  DecodablePassages = 'DECODABLE_PASSAGES',
  DecodableBooks = 'DECODABLE_BOOKS',
  QuickChecks = 'QUICK_CHECKS',
}

export enum SubLibrary {
  Animals = 'ANIMALS',
  FablesAndFairyTales = 'FABLES_AND_FAIRY_TALES',
  SportsAndGames = 'SPORTS_AND_GAMES',
}

export const decodableFormatTypes = (decodableFormat?: DecodableFormat | null): string | undefined => {
  if (!decodableFormat) return undefined;
  const map = {
    [DecodableFormat.WiseWords]: 'Wise Words',
    [DecodableFormat.DecodablePassages]: 'Decodable Passages',
    [DecodableFormat.DecodableBooks]: 'Decodable Books',
    [DecodableFormat.QuickChecks]: 'Quick Checks',
  };
  return map[decodableFormat] ?? undefined;
};

export enum Grade {
  EarlyChildhood = 'EARLY_CHILDHOOD',
  Kindergarten = 'KINDERGARTEN',
  Grade1 = 'GRADE_1',
  Grade2 = 'GRADE_2',
  Grade3 = 'GRADE_3',
  Grade4 = 'GRADE_4',
  Grade5 = 'GRADE_5',
  Grade6To8 = 'GRADE_6_TO_8',
  Grade9To12 = 'GRADE_9_to_12',
}

export interface ReadingLevel {
  id: string;
  name: string;
  type: ReadingLevelType;
  numericValue: number | null;
}

export interface IncomingCallRequest {
  profileId: string;
  name: string;
}

export enum ReadingLevelType {
  FAndP = 'F_AND_P',
  GBPlus = 'GB_PLUS',
  Math = 'MATH',
  Decodables = 'DECODABLES',
  Lexile = 'LEXILE',
  Assess = 'ASSESS',
  ATOS = 'ATOS',
}

export const bookTypeOptions: ChipGroupProps['items'] = [
  { value: BookType.HootReadingAssessment, label: 'Hoot Reading Assessment' },
  { value: BookType.Instruction, label: 'Instruction' },
  { value: BookType.FormativeAssessment, label: 'Formative Assessment' },
  { value: BookType.SpecialProjects, label: 'Special Projects' },
];

export const resourceProgressValues: ChipGroupProps['items'] = [
  { value: ResourceProgress.NotStarted, label: 'Not Started' },
  { value: ResourceProgress.Practicing, label: 'Practicing' },
  { value: ResourceProgress.Finished, label: 'Finished' },
];

export const resourceStateValues: ChipGroupProps['items'] = [
  { value: ResourceState.NotStarted, label: 'Not Started' },
  { value: ResourceState.InProgress, label: 'In-Progress' },
  { value: ResourceState.Read, label: 'Read' },
];

export const decodableFormatOptions: ChipGroupProps['items'] = [
  { value: DecodableFormat.WiseWords, label: 'Wise Words' },
  { value: DecodableFormat.DecodableBooks, label: 'Decodable Books' },
  { value: DecodableFormat.DecodablePassages, label: 'Decodable Passages' },
  // Note: Quick Checks hidden on filter drawer
  // { value: DecodableFormat.QuickChecks, label: 'Quick Checks' },
];

export const ageOptions: ChipGroupProps['items'] = [
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 15, label: '15' },
  { value: 16, label: '16' },
  { value: 17, label: '17' },
];

export const gradeOptions: ChipGroupProps['items'] = [
  { value: Grade.EarlyChildhood, label: 'Early Childhood' },
  { value: Grade.Kindergarten, label: 'Kindergarten' },
  { value: Grade.Grade1, label: 'Grade 1' },
  { value: Grade.Grade2, label: 'Grade 2' },
  { value: Grade.Grade3, label: 'Grade 3' },
  { value: Grade.Grade4, label: 'Grade 4' },
  { value: Grade.Grade5, label: 'Grade 5' },
  { value: Grade.Grade6To8, label: 'Grade 6 - 8' },
  { value: Grade.Grade9To12, label: 'Grade 9 - 12' },
];

export interface BookSearchResponse {
  page: number;
  limit: number;
  total: number;
  books: BookResponse[];
}

export interface BookPages {
  pageCount: number;
  pages: Page[];
}

export interface Page {
  id: string;
  page: number;
  url: string;
}

export interface BookUpdate {
  book: BookResponse;
  isSuccess: boolean;
  message: string;
}

export interface Publisher {
  id: string;
  name: string;
}

export enum BookmarkType {
  InLesson = 'IN_LESSON',
  OutOfLesson = 'OUT_OF_LESSON',
}
