import { Fade, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { StudentProfileV2Response } from '@hoot/hooks/api/user/useGetStudentProfileV2';
import { studentGradeLabelDictionary } from '@hoot/models/api/student';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import Card from '@hoot/ui/components/v2/core/Card';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';

interface StudentDetailsCardProps {
  student: StudentProfileV2Response | undefined;
  isLoading: boolean;
}

const StudentDetailsCard = (props: StudentDetailsCardProps) => {
  const { student, isLoading } = props;

  const getViewState = (): ViewStateEnum => {
    if (isLoading) {
      return ViewStateEnum.Loading;
    } else if (student) {
      return ViewStateEnum.Results;
    } else {
      // If not loading, and we don't have a student, then this is an invalid state.
      return ViewStateEnum.Error;
    }
  };

  // Technically, a student can be assigned to multiple locations, but they should only ever really be assigned to one.
  // Since we're assuming there will only be _one_ assigned location, just grab the first index.
  const studentAssignedHfsLocation = student?.hfsLocations?.[0];

  const emptyLabel = '---';

  return (
    <>
      <Card title="Student Details" isLoading={isLoading}>
        <ViewState state={getViewState()} loadingContent={<SkeletonContent />}>
          <Fade in>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, sm: 6 }}>
                <ReadOnlyTextField label="Student Name" body={student?.name ?? emptyLabel} />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <ReadOnlyTextField label="Student Pronouns" body={student?.pronouns ?? emptyLabel} />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <ReadOnlyTextField label="Birth Year" body={student?.birthYear?.toString() ?? emptyLabel} />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <ReadOnlyTextField label="Grade" body={student?.grade ? (studentGradeLabelDictionary[student.grade] ?? emptyLabel) : emptyLabel} />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <ReadOnlyTextField label="Student Number" body={student?.number?.toString() ?? emptyLabel} />
              </Grid>
              {studentAssignedHfsLocation ? (
                <Grid size={12}>
                  <ReadOnlyTextField label="School" body={studentAssignedHfsLocation.school?.name ?? emptyLabel} />
                </Grid>
              ) : null}
              {studentAssignedHfsLocation ? (
                <Grid size={12}>
                  <ReadOnlyTextField label="Division" body={studentAssignedHfsLocation?.district?.name ?? emptyLabel} />
                </Grid>
              ) : null}
              {student?.hootNotes ? (
                <Grid size={12}>
                  <ReadOnlyTextField label="Hoot Notes" body={student?.hootNotes ?? emptyLabel} />
                </Grid>
              ) : null}
            </Grid>
          </Fade>
        </ViewState>
      </Card>
    </>
  );
};

const SkeletonContent = () => {
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
      <Grid size={12}>
        <Skeleton variant="rectangular" height="58px" />
      </Grid>
    </Grid>
  );
};

export default StudentDetailsCard;
