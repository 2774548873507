export const config = {
  apiUrl: import.meta.env.VITE_API_URL || 'http://127.0.0.1:7000',
  webSocket: import.meta.env.VITE_WEBSOCKET || 'ws://127.0.0.1:7000',
  googleAnalyticsEnabled: import.meta.env.VITE_GOOGLE_ANALYTICS_ENABLED || false,
  googleAnalyticsMeasurementId: import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID || '',
  sentryDSN: import.meta.env.VITE_SENTRY_DSN || '',
  zendeskLiveChatIdTeacher: import.meta.env.VITE_ZENDESK_LIVE_CHAT_ID_TEACHER || '',
  zendeskLiveChatIdParent: import.meta.env.VITE_ZENDESK_LIVE_CHAT_ID_PARENT || '',
  zendeskLiveChatIdDistrictRep: import.meta.env.VITE_ZENDESK_LIVE_CHAT_ID_DISTRICT_REP || '',
};
