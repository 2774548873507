import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { ProductStatus } from '@hoot/models/api/enums';
import { EnrolmentStatus } from '@hoot/models/api/enums/Enrolments';
import { EnrolmentType } from '@hoot/models/api/lessons';
import { QueryKey } from '../queryKeys';

export enum EnrolmentRosterStatus {
  Pending = 'PENDING',
  Open = 'OPEN',
  Waitlist = 'WAITLIST',
  Closed = 'CLOSED',
}

export interface DistrictRepEnrolmentProductResponse {
  id: string;
  friendlyId: string;
  status: ProductStatus;
}

export interface DistrictRepEnrolmentResponse {
  id: string;
  status: EnrolmentStatus;
  rosterStatus: EnrolmentRosterStatus;
  numberOfStudentsAllowed: number;
  numberOfStudentsRegistered: number;
  type: EnrolmentType;
  startDate: number;
  endDate: number;
  product: DistrictRepEnrolmentProductResponse;
}

export default function useDistrictRepGetEnrolmentSnippet(
  enrolmentId: string,
  options?: Omit<UseQueryOptions<DistrictRepEnrolmentResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery(
    [QueryKey.GetDistrictRepEnrolment, enrolmentId],
    async () => {
      return (await axios.get<DistrictRepEnrolmentResponse>(`${config.apiUrl}/public/district-representative/enrolment/${enrolmentId}`)).data;
    },
    options,
  );
}
