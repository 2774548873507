import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Stack } from '@mui/system';
import { isAxiosError } from 'axios';
import { DateTime, WeekdayNumbers } from 'luxon';
import { useEffect, useRef } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { FULL_DATE, WINNIPEG_TIMEZONE } from '@hoot/constants/constants';
import { ErrorResponseDto } from '@hoot/models/api/error-response-dto';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { googleAnalytics } from '@hoot/telemetry/google-analytics';
import { Button } from '@hoot/ui/components/v2/core/Button';
import { Chip } from '@hoot/ui/components/v2/core/Chip';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { useAuth } from '@hoot/ui/context/AuthContext';
import useFlfScheduleRequest, { FlfScheduleRequestDto } from '../../../../../../../hooks/api/free-lesson-flow/useFlfCreateScheduleRequest';
import useGetFlfStudentLessons from '../../../../../../../hooks/api/free-lesson-flow/useGetFlfStudentLessons';
import useGetUser from '../../../../../../../hooks/api/user/useGetUser';
import Calendar from '../../../../../../components/v2/core/Calendar';
import DatePicker from '../../../../../../components/v2/core/DatePicker';
import theme from '../../../../../../theme/v2';
import { FlfWizardStep } from '../FreeLessonRegistrationWizard';

interface Form {
  lessonDate: number | undefined;
  lessonTime: string | undefined;
}

interface Time {
  hour: number;
  minutes: number;
}

// Times are set in CST
// Monday = 1
// Sunday = 7
const endTimes: Record<WeekdayNumbers, Time> = {
  1: { hour: 19, minutes: 30 },
  2: { hour: 19, minutes: 30 },
  3: { hour: 19, minutes: 30 },
  4: { hour: 19, minutes: 30 },
  5: { hour: 17, minutes: 30 },
  6: { hour: 14, minutes: 30 },
  7: { hour: 14, minutes: 30 },
};

function getTimeSlots(selectedDate: DateTime) {
  // sets starting time based on America/Winnipeg then adjusts it to the local timezone

  function getStartsAt() {
    const TwentyFourHoursFromNow = DateTime.now().plus({ days: 1 });

    const startsAt = selectedDate
      .set({
        hour: 9,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .setZone(WINNIPEG_TIMEZONE, { keepLocalTime: true })
      .toLocal();

    if (startsAt > TwentyFourHoursFromNow) {
      return startsAt;
    } else {
      return startsAt.set({
        hour: TwentyFourHoursFromNow.minute > 30 ? TwentyFourHoursFromNow.hour + 1 : TwentyFourHoursFromNow.hour,
        minute: TwentyFourHoursFromNow.minute > 30 ? 0 : 30,
      });
    }
  }

  let startsAt = getStartsAt();

  const endsAt = startsAt
    .set(endTimes[selectedDate.weekday as keyof typeof endTimes])
    .setZone(WINNIPEG_TIMEZONE, { keepLocalTime: true })
    .toLocal();
  const times: string[] = [];
  while (startsAt.toMillis() <= endsAt.toMillis()) {
    times.push(startsAt.toFormat('h:mma'));
    startsAt = startsAt.plus({ minutes: 30 });
  }
  return times;
}

export function setTimeOnDateTime(date: DateTime, time: string): DateTime {
  return DateTime.fromFormat(`${date.toISODate()} ${time}`, `yyyy-LL-dd h:mma`);
}

function AlreadyHasLessons(params: { studentName: string; lessonAt: number }) {
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleCreateAnotherStudent = () => {
    setSearchParams({
      step: `${FlfWizardStep.CreateStudent}`,
    });
  };

  const handleTakeMeToTheApp = () => {
    navigate(routesDictionary.home.url);
  };

  return (
    <Stack>
      <HootTypography isPII={false} variant="titlelarge">
        Select a Date & Time
      </HootTypography>
      <HootTypography isPII={false} mt={3}>
        It looks like you've already booked a lesson for {params.studentName} on{' '}
        {DateTime.fromMillis(params.lessonAt).toFormat(`${FULL_DATE} 'at' h:mm a ZZZZ`)}. To reschedule the lesson, please contact{' '}
        <a href="email:enrollment@hootreading.com">enrollment@hootreading.com</a>.
      </HootTypography>
      <Grid sx={{ marginTop: '16px' }} container size={12}>
        <Box>
          <Button onClick={handleTakeMeToTheApp} variant="contained">
            Take Me To The App
          </Button>
        </Box>
        <Box>
          <Button
            startIcon={<Icon name="add" />}
            onClick={handleCreateAnotherStudent}
            sx={{
              marginLeft: '16px',
            }}
            variant="outlined"
          >
            Create Another Student
          </Button>
        </Box>
      </Grid>
    </Stack>
  );
}

function TimeSlots(params: { selectedDate: DateTime; value?: string; onChange: (val: string) => void }) {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleClick = (timeslot: string) => () => {
    params.onChange(timeslot);
  };

  const timeslots = getTimeSlots(params.selectedDate);

  return (
    <Grid sx={{ marginTop: '12px' }} container size={12} justifyContent="center">
      <Stack flex={1}>
        {isDesktop ? (
          <HootTypography isPII={false} variant="titlesmall" textAlign="center">
            {params.selectedDate.toFormat('cccc, LLLL d, yyyy')}
          </HootTypography>
        ) : null}
        <HootTypography isPII={false} sx={{ marginTop: '16px' }} variant="labelsmall">
          Available Slots (Timezone: {params.selectedDate.toFormat('ZZZZ')})
        </HootTypography>

        <Grid
          sx={{
            marginTop: '8px',
          }}
          flex={1}
          container
          rowGap="8px"
          size={12}
        >
          {timeslots.map((d) => (
            <Grid size={3}>
              <Chip onClick={handleClick(d)} key={d} sx={{ minWidth: '76px' }} selected={params.value === d} label={d} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Grid>
  );
}

const FreeLessonSchedule = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { getUser } = useAuth();
  const user = getUser();

  const studentProfileId = searchParams.get('studentProfileId') ?? '?';

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const scheduleRequest = useFlfScheduleRequest();
  const studentHasNoLessonsRequest = useGetFlfStudentLessons(studentProfileId);
  const fetchUser = useGetUser(user.id, {
    cacheTime: 0,
    onSuccess: (data) => {
      const profileExists = data.profiles.some((p) => p.id === studentProfileId);
      if (!profileExists) {
        setSearchParams({
          step: `${FlfWizardStep.CreateStudent}`,
        });
      }
    },
  });

  const studentName = fetchUser.data?.profiles.find((p) => p.id === studentProfileId)?.name ?? '';

  const now = useRef(DateTime.now());

  const dispatch = useAppDispatch();

  const { control, handleSubmit, watch, reset, formState } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      lessonDate: undefined,
      lessonTime: undefined,
    },
  });

  const { lessonDate } = watch();

  useEffect(() => {
    reset({
      lessonDate: lessonDate,
      lessonTime: undefined,
    });
  }, [lessonDate, reset]);

  const onSubmit: SubmitHandler<Form> = (data) => {
    if (!data.lessonDate || !data.lessonTime) {
      return false;
    }

    const requestLessonAt = setTimeOnDateTime(DateTime.fromMillis(data.lessonDate), data.lessonTime).toMillis();
    const request: FlfScheduleRequestDto = {
      requestLessonAt: requestLessonAt,
      studentProfileId: studentProfileId,
    };
    scheduleRequest.mutate(request, {
      onSuccess: () => {
        setSearchParams({
          step: `${FlfWizardStep.Schedule + 1}`,
          studentProfileId: studentProfileId,
          lessonDate: requestLessonAt.toString(),
        });
        googleAnalytics.completedFLFStep5();
      },
      onError: (err) => {
        console.error(err);
        if (isAxiosError<ErrorResponseDto>(err)) {
          dispatch(createFlashMessage({ variant: 'error', message: err.response?.data.message ?? 'An error occurred while scheduling a lesson.' }));
        } else {
          dispatch(createFlashMessage({ variant: 'error', message: 'An error occurred while scheduling a lesson.' }));
        }
      },
    });
  };

  if (!user) {
    return <Navigate to={routesDictionary.freeLesson.registration.url} />;
  }

  const handleGoBack = () => {
    setSearchParams({
      step: `${FlfWizardStep.Schedule - 1}`,
    });
  };

  if (studentHasNoLessonsRequest.isLoading) {
    return <CircularProgress />;
  }

  if (studentHasNoLessonsRequest.data && studentHasNoLessonsRequest.data.hasLessons && studentHasNoLessonsRequest.data.lessonAt) {
    return <AlreadyHasLessons lessonAt={studentHasNoLessonsRequest.data.lessonAt} studentName={studentName} />;
  }

  const minDate = now.current.plus({ day: 7 }).startOf('day');
  const maxDate = now.current.plus({ weeks: 3 }).endOf('day');
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <HootTypography isPII={false} variant="titlelarge">
          Select a Date & Time
        </HootTypography>
        <HootTypography isPII={false} mt={3}>
          When would you like the free lesson for {studentName}?
        </HootTypography>
        <Grid sx={{ marginTop: '24px' }} container>
          <Grid container size={{ xs: 12, md: 6 }}>
            <Controller
              control={control}
              name="lessonDate"
              rules={{
                required: true,
              }}
              render={({ field }) =>
                isDesktop ? (
                  <Calendar minDate={minDate.toMillis()} maxDate={maxDate.toMillis()} onChange={field.onChange} value={field.value} />
                ) : (
                  <DatePicker
                    textFieldProps={{ required: true, placeholder: 'mm/dd/yyy' }}
                    label="Select Date"
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(val) => field.onChange(val?.toMillis())}
                    value={field.value ? DateTime.fromMillis(field.value) : null}
                  />
                )
              }
            />
          </Grid>
          <Grid sx={{ paddingLeft: isDesktop ? '64px' : undefined }} container size={{ xs: 12, md: 6 }}>
            {lessonDate ? (
              <Controller
                control={control}
                name="lessonTime"
                rules={{
                  required: true,
                }}
                render={({ field }) => <TimeSlots selectedDate={DateTime.fromMillis(lessonDate)} onChange={field.onChange} value={field.value} />}
              />
            ) : null}
          </Grid>
        </Grid>
        <Grid sx={{ marginTop: '32px' }} container size={12} justifyContent="space-between">
          <Button fullWidth={!isDesktop} startIcon={<Icon name="chevron" />} onClick={handleGoBack} variant="outlined">
            Go Back
          </Button>

          <Button
            sx={{ marginTop: !isDesktop ? '16px' : undefined }}
            fullWidth={!isDesktop}
            type="submit"
            variant="contained"
            isLoading={scheduleRequest.isLoading}
            disabled={!formState.isValid}
          >
            Complete
          </Button>
        </Grid>
      </Stack>
    </form>
  );
};

export default FreeLessonSchedule;
