import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useSessionStorage } from '@hoot/hooks/useSessionStorage';
import { SessionStorageKey } from '@hoot/models/sessionStorageKey';
import { sentry } from '@hoot/telemetry/sentry';
import { RootState } from '../redux/store';
import { useAuth } from '../ui/context/AuthContext';

const useSentry = () => {
  const [hootBrowserSessionId] = useSessionStorage<string>(SessionStorageKey.HOOT_SESSION_ID, uuidv4());
  const { getUser } = useAuth();

  const user = getUser();
  const profile = useSelector((state: RootState) => state.profile.profile);

  useEffect(() => {
    (async () => {
      await sentry.setSentryUser(user.id);
    })();
  }, [user.id]);

  useEffect(() => {
    (async () => {
      await sentry.manuallyStartNewReplay();
      await sentry.setProfileTags(profile);
    })();
  }, [profile]);

  useEffect(() => {
    (async () => {
      await sentry.setHootSessionTag(hootBrowserSessionId);
    })();
  }, [hootBrowserSessionId]);
};

export default useSentry;
