export interface Tokens {
  accessToken: string;
  refreshToken: string;
  zendeskToken?: string;
}

export interface ChangePasswordRequest {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  logoutAllActiveSessions: boolean;
}

export enum CookieConsentStatus {
  Unknown = 'UNKNOWN',
  AllowAll = 'ALLOW_ALL',
  StrictlyNecessary = 'STRICTLY_NECESSARY',
}
