import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useMonthlyScheduledLessonsQueryV2, { MonthlyScheduledLessonsQuery } from '@hoot/hooks/api/lesson/useMonthlyScheduledLessonsQueryV2';
import { UserProfileType } from '@hoot/models/api/user';
import { RootState } from '@hoot/redux/store';
import CalendarV2 from '@hoot/ui/components/v2/CalendarV2';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import Card from '@hoot/ui/components/v2/core/Card';
import { Checkbox } from '@hoot/ui/components/v2/core/Checkbox';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import Page from '@hoot/ui/components/v2/core/Page';
import PopoverMenu from '@hoot/ui/components/v2/core/PopoverMenu';

const Schedule = () => {
  const profile = useSelector((state: RootState) => state.profile.profile);
  const profiles = useSelector((state: RootState) => state.profile.profiles);
  const studentProfiles = profiles.filter((p) => p.type === UserProfileType.Student);

  const defaultDate = DateTime.now().toLocal();
  const disallowDateBefore = DateTime.local(2022, 5, 1).toMillis();
  const disallowDateAfter = DateTime.local(defaultDate.plus({ years: 1 }).year, 12, 31).toMillis();

  const [profilesToFilterBy, setProfilesToFilterBy] = useState<string[]>([]);
  const [monthlyScheduledLessonsQuery, setMonthlyScheduledLessonsQuery] = useState<MonthlyScheduledLessonsQuery>();
  const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now());
  const [selectedCalendarMonth, setSelectedCalendarMonth] = useState<number>();
  const [menu, setMenu] = useState<boolean>(false);

  const monthlyScheduledLessons = useMonthlyScheduledLessonsQueryV2(monthlyScheduledLessonsQuery);
  const data = monthlyScheduledLessons?.data?.lessonsByDate ?? [];

  useEffect(() => {
    if (selectedCalendarMonth) {
      setMonthlyScheduledLessonsQuery({
        startDateInMonth: selectedCalendarMonth,
        endDateInMonth: DateTime.fromMillis(selectedCalendarMonth).endOf('month').toMillis(),
        studentProfileFilterIds: !!profilesToFilterBy.length ? profilesToFilterBy : undefined,
      });
    }
  }, [selectedCalendarMonth, profilesToFilterBy]);

  if (!profile) {
    return null;
  }

  const handleProfileFilter = (profileId: string) => {
    if (profilesToFilterBy.includes(profileId)) {
      setProfilesToFilterBy((current) => [...current.filter((p) => p !== profileId)]);
    } else {
      setProfilesToFilterBy((current) => [...current, profileId]);
    }
  };

  return (
    <>
      <SubHeader
        title={{ label: 'Schedule', isPII: false }}
        primaryAction={
          studentProfiles.length >= 1
            ? {
                label: 'Show',
                onClick: () => setMenu(true),
                props: {
                  startIcon: <Icon name="chevron" sx={{ rotate: '270deg' }} color="primary.200" />,
                },
              }
            : undefined
        }
      />

      <Page pageTitle={'Schedule | Hoot Reading'}>
        <Card>
          <CalendarV2
            setSelectedCalendarMonth={setSelectedCalendarMonth}
            populatedDates={data}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            disallowDateBefore={disallowDateBefore}
            disallowDateAfter={disallowDateAfter}
          />
        </Card>
      </Page>

      <PopoverMenu
        id="tools-menu"
        open={menu}
        onClose={() => setMenu(false)}
        items={studentProfiles.map((p) => ({
          label: p.name,
          id: p.id,
          onClick: () => handleProfileFilter(p.id),
          icon: <Checkbox checked={profilesToFilterBy.includes(p.id)} />,
        }))}
        anchorOrigin={{
          vertical: 160,
          horizontal: 'right',
        }}
      />
    </>
  );
};

export default Schedule;
