import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DistrictRepDailyEnrolmentsLessonsMessageResponse } from '@hoot/events/messages/district-rep-daily-enrolments-lessons-message-response';
import { DistrictRepScheduledLesson } from '@hoot/models/api/lessons';

export interface DistrictRepState {
  districtRepEnrolmentsLessons: DistrictRepScheduledLesson[];
  refreshDistrictRepEnrolmentsLessons: boolean;
  isDistrictRepEnrolmentsLessonsLoading: boolean;
}

const initialState: DistrictRepState = {
  districtRepEnrolmentsLessons: [],
  refreshDistrictRepEnrolmentsLessons: false,
  isDistrictRepEnrolmentsLessonsLoading: false,
};

export const districtRepSlice = createSlice({
  name: 'districtRep',
  initialState,
  reducers: {
    updateDistrictRepEnrolmentsLessons: (state: DistrictRepState, action: PayloadAction<DistrictRepDailyEnrolmentsLessonsMessageResponse>) => {
      state.districtRepEnrolmentsLessons = action.payload.lessons;
    },
    setRefreshDistrictRepEnrolmentsLessons: (state: DistrictRepState, action: PayloadAction<boolean>) => {
      state.refreshDistrictRepEnrolmentsLessons = action.payload;
    },
    updateIsDistrictRepEnrolmentsLessonsLoading: (state: DistrictRepState, action: PayloadAction<boolean>) => {
      state.isDistrictRepEnrolmentsLessonsLoading = action.payload;
    },
  },
});

export const { updateDistrictRepEnrolmentsLessons, setRefreshDistrictRepEnrolmentsLessons, updateIsDistrictRepEnrolmentsLessonsLoading } =
  districtRepSlice.actions;

export default districtRepSlice.reducer;
