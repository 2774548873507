import { SvgIcon, SvgIconProps, SxProps, Theme } from '@mui/material';
import { RotateDirection } from '../utils/icon-utils';

const PlayIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    {...props}
    sx={
      {
        rotate: props.rotate ?? RotateDirection.Right,
        ...props.sx,
      } as SxProps<Theme>
    }
  >
    <path
      d="M10.9611 8.73649L2.99228 13.2901C1.65896 14.052 0 13.0893 0 11.5536V7V2.44636C0 0.910713 1.65896 -0.052021 2.99228 0.709873L10.9611 5.26351C12.3048 6.0313 12.3048 7.9687 10.9611 8.73649Z"
      fill={props.htmlColor ?? '#000'}
    />
  </SvgIcon>
);

export default PlayIcon;
