import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { DayOfWeek } from '@hoot/utils/date';
import { QueryKey } from '../queryKeys';

export interface Timeblock {
  id: string;
  dayOfWeek: DayOfWeek;
  startTime: string;
  endTime: string;
}

export interface GetTeacherAvailabilityResponse {
  timezone: string;
  timeblocks: Timeblock[];
}

async function getTeacherAvailability() {
  const { data } = await axios.get<GetTeacherAvailabilityResponse>(`${config.apiUrl}/public/availability`);
  return data;
}

export default function useTeacherAvailabilityQuery(options?: Omit<UseQueryOptions<GetTeacherAvailabilityResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetTeacherAvailability], () => getTeacherAvailability(), options);
}
