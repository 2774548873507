import { SvgIcon, SvgIconProps } from '@mui/material';

const CollapseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="2 2 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2L6 2C3.79086 2 2 3.79086 2 6L2 18C2 20.2091 3.79086 22 6 22L18 22C20.2091 22 22 20.2091 22 18L22 6C22 3.79086 20.2091 2 18 2ZM16 12.75C16.4142 12.75 16.75 12.4142 16.75 12C16.75 11.5858 16.4142 11.25 16 11.25L8 11.25C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75L16 12.75Z"
        fill={props.htmlColor ?? 'currentColor'}
      />
    </SvgIcon>
  );
};

export default CollapseIcon;
