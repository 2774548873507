import { Stack } from '@mui/system';
import { BookResponse } from '@hoot/events/interfaces/book-response';
import { PageZoom } from '@hoot/events/interfaces/page-zoom';
import Reader from '@hoot/ui/components/v2/library/Reader';
import { TeacherReaderBookControls } from '@hoot/ui/pages/v2/teacher/reader/TeacherReaderBookControls';

interface TeacherReaderProps {
  openedBook: BookResponse;
  pageIndex: number;
  isDoublePage: boolean;
  pageZoom: PageZoom;
  onGoToPreviousPage: () => void;
  onGoToNextPage: () => void;
}

const TeacherReader = (props: TeacherReaderProps) => {
  const { openedBook, pageIndex, pageZoom, isDoublePage, onGoToNextPage, onGoToPreviousPage } = props;
  const totalPages = openedBook.pages.length;

  return (
    <Stack
      direction="column"
      justifyContent="center"
      sx={{
        height: '100%',
        pt: 3,
        gap: 3,
      }}
    >
      <Reader openedBook={openedBook} pageIndex={pageIndex} isDoublePage={isDoublePage} pageZoom={pageZoom} />
      <TeacherReaderBookControls onPrevPage={onGoToPreviousPage} onNextPage={onGoToNextPage} currentPage={pageIndex + 1} totalPages={totalPages} />
    </Stack>
  );
};

export default TeacherReader;
