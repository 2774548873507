import axios from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';
import { BookResponse } from '@hoot/events/interfaces/book-response';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { UseMutationOptions } from '@hoot/models/api/axios-options';

export default function useToggleStudentFavouriteBook(options: UseMutationOptions<{ bookId: string }, BookResponse>) {
  return useMutation(
    [QueryKey.ToggleStudentFavouriteBook],
    async ({ bookId }) => {
      return (await axios.put<BookResponse>(`${config.apiUrl}/student-library/book/${bookId}/toggle-favourite`)).data;
    },
    options,
  );
}
