import { DailyParticipantTracks } from '@daily-co/daily-js';
import { useDaily } from '@daily-co/daily-react';
import { useEffect, useState } from 'react';

export function useDailyWebsocketTest(props: { localParticipantTracks: DailyParticipantTracks }) {
  const [wsTestState, setWsTestState] = useState<'testing' | 'failed' | 'completed'>('testing');
  const [wsTestResultMessage, setWsTestResultMessage] = useState('Network test 2...');

  const dailyCallObj = useDaily();

  useEffect(() => {
    async function testWebsocketConnectivity() {
      if (dailyCallObj) {
        const websocketConnectivityTestResults = await dailyCallObj.testWebsocketConnectivity();

        console.log(`WS Connectivity results`, websocketConnectivityTestResults);
        switch (websocketConnectivityTestResults.result) {
          case 'warning':
            setWsTestResultMessage(`Connection issues detected. Click "Troubleshoot" below.`);
            setWsTestState('failed');
            break;
          case 'failed':
            setWsTestResultMessage('Connection issues detected. Click "Troubleshoot" below.');
            setWsTestState('failed');
            break;
          default:
          case 'passed':
            setWsTestResultMessage('Test 2: No connection issues detected!');
            setWsTestState('completed');
        }
      }
    }

    testWebsocketConnectivity().catch((err) => {
      console.error('Daily Websocket Connectivity test failed: ', err);
    });
  }, [dailyCallObj, props.localParticipantTracks]);

  return {
    wsTestState,
    wsTestResultMessage,
  };
}
