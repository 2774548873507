import { Stack } from '@mui/system';
import React from 'react';
import Card from '@hoot/ui/components/v2/core/Card';
import { useStudentDetailsContext } from '@hoot/ui/pages/v2/teacher/my-students/student-details/StudentDetailsContextProvider';
import AssessmentActionCard from '@hoot/ui/pages/v2/teacher/my-students/student-details/assessments/action-required-card/AssessmentActionCard';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import useGetAssessmentActions from '../../../../../../../../hooks/api/my-student/useGetAssessmentActions';
import ViewStateIllustration, { IllustrationEnum } from '../../../../../../../components/v2/ViewStateIllustration';
import Badge from '../../../../../../../components/v2/core/Badge';
import HootTypography from '../../../../../../../components/v2/core/HootTypography';

const AssessmentActionRequiredCards = () => {
  const { studentProfileSnippet } = useStudentDetailsContext();

  const actionsRequest = useGetAssessmentActions(studentProfileSnippet.id);

  const actions = actionsRequest.data?.actions ?? [];

  function Content() {
    if (actionsRequest.isFetched) {
      if (actions.length === 0) {
        return <ViewStateIllustration illustration={IllustrationEnum.NoResults} />;
      } else {
        return (
          <Stack spacing="16px">
            {actions.map((action, index) => (
              <AssessmentActionCard key={`action-${action.assessmentId ?? index}`} action={action} studentProfileSnippet={studentProfileSnippet} />
            ))}
          </Stack>
        );
      }
    }
    return null;
  }

  if (actions.length === 0) return null;

  return (
    <Card
      isLoading={actionsRequest.isFetching}
      title={
        <Stack direction="row">
          <HootTypography isPII={false} variant="titlemedium">
            Action Required
          </HootTypography>
          <Badge sx={{ marginLeft: '16px', color: hootTokens.palette.white }} color="error.80" badgeContent={actions.length} />
        </Stack>
      }
    >
      <Stack spacing={2}>
        <Content />
      </Stack>
    </Card>
  );
};

export default AssessmentActionRequiredCards;
