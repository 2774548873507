import { useAudioLevel, useAudioTrack, useLocalSessionId } from '@daily-co/daily-react';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { useInterval } from '@hoot/hooks/useInterval';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { dailyAudioThrottle } from '@hoot/utils/daily/dailyAudioThrottle';

const MAX_VOLUME = 1;
const NUM_OF_BARS = 20;

const DailyVolumeMeter = () => {
  const barSize = MAX_VOLUME / NUM_OF_BARS; // Split the max volume by the number of bars

  const theme = useTheme();
  const is900px = useMediaQuery(theme.breakpoints.up('md'));
  const localParticipantId = useLocalSessionId();
  const audioTrack = useAudioTrack(localParticipantId);

  const [testingMic, setTestingMic] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(0);

  const maxSampledVolume = useRef(0);

  useAudioLevel(
    audioTrack?.persistentTrack,
    dailyAudioThrottle((vol: number) => {
      const newVolume = vol;
      if (newVolume > maxSampledVolume.current) {
        maxSampledVolume.current = newVolume;
      }
    }),
  );

  useInterval(() => {
    setVolume(maxSampledVolume.current);
    maxSampledVolume.current = 0;
  }, 300);

  const toggleTestMic = () => {
    if (testingMic) {
      setVolume(0);
      setTestingMic(false);
      maxSampledVolume.current = 0;
    } else {
      setTestingMic(true);
    }
  };

  const array = [];
  for (let i = 0; i < NUM_OF_BARS; i++) {
    array.push(i);
  }

  return (
    <Stack flexDirection="row" gap={2} sx={{ width: '100%' }} alignItems="center">
      <Button
        onClick={toggleTestMic}
        variant={testingMic ? 'outlined' : 'contained'}
        color={testingMic ? 'error' : 'primary'}
        size="small"
        sx={{ minWidth: '90px' }}
      >
        <HootTypography isPII={false} variant="labelsmall">
          {testingMic ? 'Stop' : 'Test Mic'}
        </HootTypography>
      </Button>

      <Stack flexDirection="row" gap={!is900px ? '1vw' : 0}>
        {array.map((position) => {
          const showAudioBars = testingMic && volume > barSize && volume >= barSize * position;
          return (
            <div
              key={position}
              style={{
                width: '8px',
                height: '24px',
                margin: '3px',
                borderRadius: '8px',
                transitionDuration: '0.2s',
                background: showAudioBars ? hootTokens.palette.secondary[100] : hootTokens.palette.primary[160],
              }}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default DailyVolumeMeter;
