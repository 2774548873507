import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface CreateInvoiceBillableTaskDto {
  invoiceId: string;
  projectId: string;
  date: number;
  duration?: string | null;
  quantity?: number | null;
  comment?: string | null;
  lessonNumber?: number | null;
}

export default function useCreateInvoiceBillableTask() {
  return useMutation<void, AxiosError, CreateInvoiceBillableTaskDto>(async (request) => {
    return (await axios.post(`${config.apiUrl}/public/teacher-invoicing/billable-task`, request)).data;
  });
}
