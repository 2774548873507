import React, { PropsWithChildren, useState } from 'react';
import TimerDialog from '../components/v2/TimerDialog';
import CelebrationsDialog from '../components/v2/dialogs/CelebrationsDialog';
import NotepadDialog from '../components/v2/dialogs/NotepadDialog';

export interface ToolsContextProps {
  //celebrations tool
  isCelebrationsDialogVisible: boolean;
  onShowCelebrationsDialog: () => void;
  onHideCelebrationsDialog: () => void;
  //time tool
  isTimerVisible: boolean;
  onShowTimer: () => void;
  onHideTimer: () => void;
  //notepad tool
  isNotepadVisible: boolean;
  onShowNotepad: () => void;
  onHideNotepad: () => void;
}

const ToolsContext = React.createContext<ToolsContextProps>(undefined!);

export const ToolsContextProvider = (props: PropsWithChildren<any>) => {
  const { children } = props;
  const [showCelebrationsDialog, setShowCelebrationsDialog] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [showNotepad, setShowNotepad] = useState(false);

  const onShowCelebrationsDialog = () => {
    setShowCelebrationsDialog(true);
  };

  const onHideCelebrationsDialog = () => {
    setShowCelebrationsDialog(false);
  };

  const onShowTimer = () => {
    setShowTimer(true);
  };

  const onHideTimer = () => {
    setShowTimer(false);
  };

  const onShowNotepad = () => {
    setShowNotepad(true);
  };

  const onHideNotepad = () => {
    setShowNotepad(false);
  };

  return (
    <ToolsContext.Provider
      value={{
        isCelebrationsDialogVisible: showCelebrationsDialog,
        onShowCelebrationsDialog,
        onHideCelebrationsDialog,
        isTimerVisible: showTimer,
        onShowTimer,
        onHideTimer,
        isNotepadVisible: showNotepad,
        onShowNotepad,
        onHideNotepad,
      }}
    >
      {children}
      <CelebrationsDialog show={showCelebrationsDialog} onHide={onHideCelebrationsDialog} />
      <TimerDialog show={showTimer} onHide={onHideTimer} />
      <NotepadDialog show={showNotepad} onHide={onHideNotepad} />
    </ToolsContext.Provider>
  );
};

export const useToolsContext = () => {
  const context = React.useContext(ToolsContext);

  if (context === undefined) {
    throw new Error('useToolsContext must be used within a ToolsContextProvider');
  }
  return context;
};
