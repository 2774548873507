import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';
import { FlfCreateStudentProfileRequest } from '@hoot/hooks/api/free-lesson-flow/useFlfCreateStudentProfileRequest';

export interface FlfUpdateStudentProfileRequest extends FlfCreateStudentProfileRequest {
  studentProfileId: string;
}

export default function useFlfUpdateStudentProfileRequest() {
  return useMutation<void, AxiosError, FlfUpdateStudentProfileRequest>(async (request) => {
    const { studentProfileId, ...requestBody } = request;
    return (await axios.put(`${config.apiUrl}/free-lesson/v2/student-profile/${studentProfileId}`, requestBody)).data;
  });
}
