import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { HootAssessmentInstructionIndicator, HootAssessmentStatus } from '@hoot/models/api/enums/hoot-reading-assessment';
import { config } from '../../../config';
import { QueryKey } from '../queryKeys';

export interface Assessment {
  id: string;
  status: HootAssessmentStatus;
  currentUnitId: string;
  createdAt: number;
  updatedAt: number;
  startedBy: string;
  assessedByYou: boolean;
  countOfOtherAssessors: number;
  lastCompletedUnit?: {
    unitId: string;
    unitName: string;
    instructionIndicator?: HootAssessmentInstructionIndicator;
  };
}

async function getAssessmentById(assessmentId: string) {
  const { data } = await axios.get<Assessment>(`${config.apiUrl}/public/hoot-assessment/${assessmentId}`);
  return data;
}

export function useGetAssessmentById(assessmentId: string, options?: Omit<UseQueryOptions<Assessment>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetAssessmentById, assessmentId], () => getAssessmentById(assessmentId), options);
}
