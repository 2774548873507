import { CardProps, useMediaQuery } from '@mui/material';
import { capitalCase } from 'change-case';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PastInvoicesQuery, useGetPastInvoicesListing } from '@hoot/hooks/api/invoice/useGetPastInvoicesListing';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import Tag from '@hoot/ui/components/v2/core/Tag';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { handleTagColor } from '@hoot/ui/pages/v2/teacher/invoicing/util';
import theme from '@hoot/ui/theme/v2';
import palette from '@hoot/ui/theme/v2/palette';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface PastInvoicesProps {
  sx?: CardProps['sx'];
}

interface PastInvoiceTableRow {
  key: string;
  invoiceNumber: React.ReactNode;
  date: React.ReactNode;
  amount: React.ReactNode;
  status: React.ReactNode;
}

export interface PastInvoicesListing {
  count: number;
  page: number;
  pageSize: number;
  pageRows: PastInvoiceTableRow[];
}

const PastInvoices = (props: PastInvoicesProps) => {
  const { sx } = props;
  const { getUser } = useAuth();
  const teacherAccount = getUser().teacherAccount;
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [pastInvoicesData, setPastInvoicesData] = useState<PastInvoicesListing>();
  const [query, setQuery] = useState<PastInvoicesQuery>({
    page: 1,
    pageSize: 10,
    orderBy: 'date',
    sortDirection: OrderBy.Desc,
  });

  const headers: HeaderData<PastInvoiceTableRow>[] = [
    { name: 'Number', property: 'invoiceNumber', isSortable: true },
    { name: 'Date', property: 'date', isSortable: true },
    { name: 'Amount', property: 'amount', isSortable: false },
    { name: 'Status', property: 'status', isSortable: true, isHidden: !isDesktop },
  ];

  const pastInvoicesRequest = useGetPastInvoicesListing(teacherAccount?.id || '', query, {
    retry: false,
    enabled: !!teacherAccount?.id,
    onError: (ex) => {
      console.error(ex);
    },
    onSuccess: (response) => {
      const rows = response.data.map((row): PastInvoiceTableRow => {
        const linkStyle: React.CSSProperties = {
          color: palette.text.primary,
        };
        return {
          key: row.invoiceId,
          invoiceNumber: (
            <Link to={routesDictionary.invoicing.details.url(row.invoiceId)} style={linkStyle}>
              <HootTypography isPII={true} variant="tablevalue">
                {row.invoiceNumber}
              </HootTypography>
            </Link>
          ),
          date: (
            <HootTypography isPII={false} variant="tablevalue">
              {row.date}
            </HootTypography>
          ),
          amount: (
            <HootTypography isPII={false} variant="tablevalue">
              {row.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
            </HootTypography>
          ),
          status: <Tag label={capitalCase(row.status)} color={handleTagColor(row.status)} sx={{ ...hootTokens.text.labellarge }} />,
        };
      });
      setPastInvoicesData({
        count: response.count,
        page: response.page,
        pageSize: response.pageSize,
        pageRows: rows,
      });
    },
  });

  const viewState = useMemo<ViewStateEnum>(() => {
    if (pastInvoicesRequest.isError) {
      return ViewStateEnum.Error;
    } else if (!pastInvoicesData || pastInvoicesData.pageRows.length === 0) {
      return ViewStateEnum.EmptyStateInbox;
    } else {
      return ViewStateEnum.Results;
    }
  }, [pastInvoicesRequest.isError, pastInvoicesData]);

  return (
    <Card title="Past Invoices" titleVariant="titlemedium" sx={{ height: 'max-content', ...(sx ?? {}) }}>
      <ViewState
        state={viewState}
        loadingContent={null}
        EmptyStateIllustrationProps={{ title: 'No Past Invoices', subtitle: 'Your past invoices will be listed here' }}
      >
        <TableV2
          allowRowsPerPage
          isPaginated
          isSortable
          count={pastInvoicesData?.count ?? 0}
          data={pastInvoicesData?.pageRows ?? []}
          page={pastInvoicesData?.page ? pastInvoicesData.page - 1 : 0}
          headers={headers}
          sortOrder={query.sortDirection}
          sortBy={query.orderBy as any}
          rowsPerPage={query.pageSize}
          onSortBy={(val: any) => {
            setQuery((prevState) => ({
              ...prevState,
              page: 1,
              orderBy: val,
              sortDirection: prevState.sortDirection === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
            }));
          }}
          onPageChange={(event, page) => {
            setQuery((prevState) => ({ ...prevState, page: page + 1 }));
          }}
          onRowsPerPageChange={(event) => {
            setQuery((prevState) => ({ ...prevState, page: 1, pageSize: +event.target.value }));
          }}
        />
      </ViewState>
    </Card>
  );
};

export default PastInvoices;
