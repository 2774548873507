import { Option } from '@hoot/models/formOption';
import { Select } from '@hoot/ui/components/v2/core/Select';

interface IDropdownProps {
  name: string;
  label?: string;
  options: Option[];
  onChange: (val: string | string[] | undefined) => void;
  value: string | string[] | undefined;
  multiple?: boolean;
}

export const Dropdown = (props: IDropdownProps) => {
  const { name, options, onChange, value, label } = props;

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;
    onChange(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Select label={label} name={name} value={value} onChange={handleChange} required sx={{ minWidth: '300px' }}>
      <option value="" disabled>
        Select
      </option>
      {options.map((option) => (
        <option key={`option-${option.value}`} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};
