import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { FormVariant, ProgressMonitoringAssessmentStatus } from '@hoot/models/api/enums/pma-enums';
import { QueryKey } from '../queryKeys';

export interface PublicSearchStudentTeacherPMAsRequest {
  studentProfileId: string;
  teacherId: string;
}

export interface PublicProgressMonitoringAssessmentsResponse {
  progressMonitoringAssessments: PublicProgressMonitoringAssessmentResponse[];
}

export interface PublicProgressMonitoringAssessmentResponse {
  id: string;
  studentId: string;
  teacherId: string;
  status: ProgressMonitoringAssessmentStatus;
  currentUnitId: string;
  statusUpdateDate: string | null;
  fieldFormVariant: FormVariant;
  createdAt: number;
  updatedAt: number;
}

export async function searchStudnetTeacherPMAs(query: PublicSearchStudentTeacherPMAsRequest) {
  const { data } = await axios.post<PublicProgressMonitoringAssessmentsResponse>(
    `${config.apiUrl}/public/progress-monitoring-assessment/search-student-teacher-PMAs`,
    query,
  );
  return data;
}

export default function useSearchStudentTeacherPMAs(
  query: PublicSearchStudentTeacherPMAsRequest,
  options?: Omit<UseQueryOptions<PublicProgressMonitoringAssessmentsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.SearchStudentTeacherPMAs, query], () => searchStudnetTeacherPMAs(query), options);
}
