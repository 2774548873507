import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Fade, LinearProgress, Stack } from '@mui/material';
import { capitalCase } from 'change-case';
import React, { useEffect, useState } from 'react';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import useSearchLibrary, { LibraryOrderByEnum, QueryLibraryV2 } from '@hoot/hooks/api/library/useSearchLibrary';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { GenericPaginatedResponse } from '@hoot/models/api/pagination';
import { error } from '@hoot/redux/reducers/alertSlice';
import ViewState, { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import IconButton from '@hoot/ui/components/v2/core/IconButton';
import SearchTextField, { SearchTextFieldProps } from '@hoot/ui/components/v2/core/SearchTextField';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import AssessmentReminderCard from '@hoot/ui/pages/v2/teacher/my-students/student-details/AssessmentReminder';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export interface BookPickerModalProps {
  show: boolean;
  onDismiss: () => void;
  onApply: (selectedBook: BookSearch) => void;
  libraryQuery: QueryLibraryV2;
  setLibraryQuery: React.Dispatch<React.SetStateAction<QueryLibraryV2>>;
  headers: HeaderData<BookSelectorTableRow>[];
  existingBookIds: string[];
  isLoading?: boolean;
  filter?: React.ReactNode;
  setShowFilterDialog?: (value: React.SetStateAction<boolean>) => void;
  filterChips?: React.ReactNode;
  showAssessmentReminder?: boolean;
}

const PAGE_SIZE = 5;

export interface BookSelectorTableRow {
  id: string;
  cover: React.ReactNode;
  title: string;
  selected: boolean;
  disabled: boolean;
  bookLevel: string;
  bookNumber: string;
  instructionalUnit: string;
  filterLevelPrioritizedSkill: string;
  resourceProgress: string;
  resourceState: string;
}

const BookPickerModal = (props: BookPickerModalProps) => {
  const {
    show,
    onDismiss,
    onApply,
    isLoading = false,
    existingBookIds,
    filter,
    setShowFilterDialog,
    libraryQuery,
    setLibraryQuery,
    headers,
    filterChips,
    showAssessmentReminder = false,
  } = props;

  const [viewState, setViewState] = useState<ViewStateEnum>(ViewStateEnum.EmptyState);
  const [searchResults, setSearchResults] = useState<GenericPaginatedResponse<BookSearch>>({
    data: [],
    count: 0,
    page: 1,
    pageSize: PAGE_SIZE,
  });
  const [selectedBook, setSelectedBook] = useState<BookSearch>();
  const [searchInput, setSearchInput] = useState<string>('');

  const searchRequest = useSearchLibrary(libraryQuery, {
    retry: false,
    onSuccess: (data) => {
      setSearchResults(data);
      setViewState(data.count === 0 ? ViewStateEnum.NoResults : ViewStateEnum.Results);
    },
    onError: (err) => {
      console.error(err);
      error(`An error occurred while loading books.`);
      setViewState(ViewStateEnum.Error);
    },
  });

  useEffect(() => {
    setLibraryQuery(libraryQuery);
  }, [libraryQuery, setLibraryQuery]);

  // When we show this modal, reset all values.
  useEffect(() => {
    if (show) {
      setSelectedBook(undefined);
    }
  }, [show]);

  const handleToggleOption = (bookId: string) => {
    if (existingBookIds.includes(bookId)) {
      return;
    } else if (bookId === selectedBook?.id) {
      setSelectedBook(undefined);
    } else {
      const newSelectedBook = searchResults.data.find((book) => book.id === bookId);
      setSelectedBook(newSelectedBook);
    }
  };

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchInput(text);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (text) => {
    if (searchResults.data.length === 0 && !text) {
      setViewState(ViewStateEnum.EmptyState);
    }
    setLibraryQuery((prevState) => {
      return {
        ...prevState,
        title: text,
        page: 1,
      };
    });
  };

  const onClearSearchInput = () => {
    setSearchInput('');
    setSelectedBook(undefined);
    setViewState(ViewStateEnum.EmptyState);
    setLibraryQuery((prevState) => {
      return {
        ...prevState,
        title: '',
        page: 1,
      };
    });
  };

  const onClickApply = () => {
    onApply(selectedBook!);
    onClearSearchInput();
  };

  const onClickDismiss = () => {
    onDismiss();
    onClearSearchInput();
  };

  const handleSortBy = (column: keyof BookSelectorTableRow) => {
    function orderColumn() {
      switch (column) {
        case 'title':
          return LibraryOrderByEnum.Title;
        case 'bookLevel':
          return LibraryOrderByEnum.BookLevel;
        case 'bookNumber':
          return LibraryOrderByEnum.BookNumber;
        default:
          return LibraryOrderByEnum.Title;
      }
    }

    setLibraryQuery((prev) => ({
      ...prev,
      orderColumn: orderColumn(),
      orderBy: prev.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
    }));
  };

  function sortBy(): keyof BookSelectorTableRow {
    switch (libraryQuery.orderColumn) {
      case LibraryOrderByEnum.Title:
        return 'title';
      case LibraryOrderByEnum.BookLevel:
        return 'bookLevel';
      case LibraryOrderByEnum.BookNumber:
        return 'bookNumber';
      default:
        return 'title';
    }
  }

  const tableData: BookSelectorTableRow[] = searchResults.data.map((d) => ({
    cover: (
      <img
        alt={d.title}
        src={d?.coverUrl ?? undefined}
        style={{
          width: '40px',
          maxWidth: '40px',
          objectFit: 'contain',
        }}
      />
    ),
    id: d.id,
    title: d.title,
    bookLevel: d.readingLevel,
    bookNumber: d.sequenceNumber,
    instructionalUnit: d.units?.map((unit) => unit.name).join(', ') ?? '',
    filterLevelPrioritizedSkill: d.focuses?.map((focus) => focus.name).join(', ') ?? '',
    resourceProgress: capitalCase(d.resourceProgress ?? ''),
    resourceState: capitalCase(d.resourceState ?? ''),
    selected: existingBookIds.includes(d.id) || selectedBook?.id === d.id,
    disabled: existingBookIds.includes(d.id),
  }));

  return (
    <Dialog open={show} onClose={onClickDismiss} PaperProps={{ sx: { height: '900px' } }} maxWidth="md" fullWidth>
      <Box height="2px">
        <Fade in={searchRequest.isFetching}>
          <LinearProgress color="secondary" />
        </Fade>
      </Box>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Book Picker
        <IconButton onClick={onClickDismiss}>
          <Icon name="close" />
        </IconButton>
      </DialogTitle>
      <Stack direction="column" gap={2} padding={(theme) => theme.spacing(0, 2, 2, 2)}>
        {showAssessmentReminder ? <AssessmentReminderCard sx={{ backgroundColor: hootTokens.palette.warning['190'] }} /> : null}
        <Stack direction="row" gap={2}>
          <SearchTextField
            label="Book Title"
            searchInput={searchInput}
            onSearchInputChanged={onSearchInputChanged}
            onClearButtonClicked={onClearSearchInput}
            onSearchInputDebounced={onSearchInputDebounced}
            inputRef={(input) => input && input.focus()}
            clearable
            required
            placeholder="Search"
          />
          {filter && setShowFilterDialog ? (
            <Button variant="contained" onClick={() => setShowFilterDialog(true)} startIcon={<Icon name="filter" color="primary.200" />}>
              Filter
            </Button>
          ) : null}
        </Stack>
        <Stack direction="column" gap={2}>
          {!!searchInput.length && !searchRequest.isFetching ? (
            <HootTypography isPII={false} variant="titlesmall">{`Showing ${searchResults.count} Results for "${
              libraryQuery.title ?? ''
            }"`}</HootTypography>
          ) : null}
          {filterChips ? filterChips : null}
        </Stack>
      </Stack>
      <DialogContent>
        <ViewState
          state={viewState}
          EmptyStateIllustrationProps={{
            title: 'Search for a Book',
            subtitle: 'To apply it to your lesson',
            illustration: IllustrationEnum.NoResults,
          }}
        >
          <TableV2
            isPaginated={true}
            allowRowsPerPage={true}
            rowsPerPage={libraryQuery.pageSize}
            count={searchResults.count}
            page={libraryQuery.page - 1}
            data={tableData}
            headers={headers}
            isSortable
            onSortBy={handleSortBy}
            sortOrder={libraryQuery.orderBy ?? OrderBy.Asc}
            sortBy={sortBy()}
            onPageChange={(_event, page) => {
              setLibraryQuery((prevState) => ({ ...prevState, page: page + 1 }));
            }}
            onRowsPerPageChange={(event) => {
              setLibraryQuery((prevState) => ({ ...prevState, page: 1, pageSize: +event.target.value }));
            }}
            emptyViewState={<ViewStateIllustration illustration={IllustrationEnum.EmptyState} />}
            isSelectable
            isRowSelectable
            onSelect={(book) => handleToggleOption(book.id)}
          />
        </ViewState>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickDismiss} variant="outlined">
          Cancel
        </Button>
        <Button onClick={onClickApply} variant="contained" disabled={!selectedBook} isLoading={isLoading}>
          Apply
        </Button>
      </DialogActions>
      {filter ? filter : null}
    </Dialog>
  );
};

export default BookPickerModal;
