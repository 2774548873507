import { Stack, StackProps } from '@mui/material';
import React, { ReactNode } from 'react';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { ColorPaletteOption } from '@hoot/ui/theme/v2/palette';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { getColors } from '@hoot/ui/theme/v2/util';

interface ReadOnlyTextFieldProps {
  label: string;
  body: ReactNode;
  variant?: 'filled' | 'outlined';
  filledColour?: ColorPaletteOption;
  sx?: StackProps['sx'];
  invertLabelStyles?: boolean;
  endAdornment?: ReactNode;
  onClick?: () => void;
}

const ReadOnlyTextField = (props: ReadOnlyTextFieldProps) => {
  const { label, body, variant = 'filled', filledColour, invertLabelStyles = false, endAdornment, sx = {}, onClick } = props;
  return (
    <Stack
      onClick={onClick}
      direction="row"
      sx={{
        paddingX: 2,
        paddingY: 1,
        borderRadius: '4px',
        background:
          variant === 'filled' ? `${getColors(filledColour ?? 'warning.190')?.base}` : filledColour ? getColors(filledColour)?.base : undefined,
        border: variant === 'outlined' ? `1px solid ${hootTokens.palette.primary['100']}` : undefined,
        justifyContent: 'space-between',
        minHeight: '58px',
        ...sx,
      }}
    >
      <Stack height="100%">
        <HootTypography isPII={false} variant={invertLabelStyles ? 'bodylarge' : 'labelsmall'}>
          {label}
        </HootTypography>
        <HootTypography isPII={true} variant={invertLabelStyles ? 'labelsmall' : 'bodylarge'}>
          {body}
        </HootTypography>
      </Stack>
      {endAdornment}
    </Stack>
  );
};

export default ReadOnlyTextField;
