import { SvgIcon, SvgIconProps } from '@mui/material';

const LocationPinIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 14.0001C13.6569 14.0001 15 12.657 15 11.0001C15 9.34327 13.6569 8.00012 12 8.00012C10.3431 8.00012 9 9.34327 9 11.0001C9 12.657 10.3431 14.0001 12 14.0001Z"
        stroke={props.htmlColor ?? 'currentColor'}
        strokeWidth="1.5"
      />
      <path
        d="M21 10.889C21 15.7982 15.375 22.0001 12 22.0001C8.625 22.0001 3 15.7982 3 10.889C3 5.97981 7.02944 2.00012 12 2.00012C16.9706 2.00012 21 5.97981 21 10.889Z"
        stroke={props.htmlColor ?? 'currentColor'}
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
};

export default LocationPinIcon;
