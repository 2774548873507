import { SvgIcon, SvgIconProps } from '@mui/material';

const AddIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6H12.75ZM11.25 18C11.25 18.4142 11.5858 18.75 12 18.75C12.4142 18.75 12.75 18.4142 12.75 18H11.25ZM18 12.75C18.4142 12.75 18.75 12.4142 18.75 12C18.75 11.5858 18.4142 11.25 18 11.25V12.75ZM6 11.25C5.58579 11.25 5.25 11.5858 5.25 12C5.25 12.4142 5.58579 12.75 6 12.75L6 11.25ZM11.25 6V18H12.75V6H11.25ZM18 11.25L6 11.25L6 12.75L18 12.75V11.25Z"
      fill={props.htmlColor ?? 'currentColor'}
    />
  </SvgIcon>
);

export default AddIcon;
