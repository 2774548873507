import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { DEFAULT_PAGE_SIZE } from '@hoot/models/api/pagination';
import { studentGradeLabelDictionary } from '@hoot/models/api/student';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Page from '@hoot/ui/components/v2/core/Page';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import useGetMyStudents, { MyStudentsOrderColumn, MyStudentsQuery } from '../../../../../hooks/api/my-student/useGetMyStudents';
import Card from '../../../../components/v2/core/Card';
import SearchTextField, { SearchTextFieldProps } from '../../../../components/v2/core/SearchTextField';

interface MyStudentTableRow {
  id: string;
  name: React.ReactNode;
  studentNumber: number;
  grade: string;
  school: string;
}

export function MyStudents() {
  const theme = useTheme();
  const sub600px = useMediaQuery(theme.breakpoints.down('sm'));

  const [query, setQuery] = useState<MyStudentsQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    query: '',
    orderColumn: MyStudentsOrderColumn.Name,
    orderBy: OrderBy.Asc,
  });

  const [searchInput, setSearchInput] = useState<string>('');

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchInput(text);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (text) => {
    setQuery((prev) => ({
      ...prev,
      query: text.length > 0 ? text : undefined,
    }));
  };

  const onClearSearchInput = () => {
    setSearchInput('');
    setQuery((prev) => ({
      ...prev,
      query: undefined,
    }));
  };

  const myStudentsQuery = useGetMyStudents(query);

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize }));
  };

  const handleSortBy = (selectedColumn: keyof MyStudentTableRow) => {
    function orderColumn() {
      switch (selectedColumn) {
        case 'name':
          return MyStudentsOrderColumn.Name;
        case 'grade':
          return MyStudentsOrderColumn.Grade;
        case 'studentNumber':
          return MyStudentsOrderColumn.StudentNumber;
        default:
          return MyStudentsOrderColumn.Name;
      }
    }

    setQuery((currentState) => ({
      ...currentState,
      orderColumn: orderColumn(),
      orderBy: currentState.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
    }));
  };

  const headers: HeaderData<MyStudentTableRow>[] = [
    { name: 'Id', property: 'id', isHidden: true },
    { name: 'Name', property: 'name', isSortable: true },
    { name: 'Student Number', property: 'studentNumber', isSortable: true, isHidden: sub600px },
    { name: 'Grade', property: 'grade', isSortable: true, isHidden: sub600px },
    { name: 'School', property: 'school', isSortable: false },
  ];

  const data =
    myStudentsQuery.data?.data.map<MyStudentTableRow>((d) => ({
      id: d.studentId,
      name: (
        <Link to={routesDictionary.myStudents.details.profile.url(d.studentId)} style={{ color: hootTokens.palette.black }}>
          <HootTypography isPII={true} variant="tablevalue" sx={{ color: hootTokens.palette.black }}>
            {d.studentName}
          </HootTypography>
        </Link>
      ),
      studentNumber: d.studentNumber,
      grade: d.studentGrade ? studentGradeLabelDictionary[d.studentGrade] : '',
      school: d.schoolNames.join(', '),
    })) ?? [];

  const count = myStudentsQuery.data?.count ?? 0;

  function sortBy(): keyof MyStudentTableRow {
    switch (query.orderColumn) {
      case MyStudentsOrderColumn.Grade:
        return 'grade';
      case MyStudentsOrderColumn.StudentNumber:
        return 'studentNumber';
      case MyStudentsOrderColumn.Name:
      default:
        return 'name';
    }
  }

  return (
    <>
      <SubHeader
        title={{
          label: 'My Students',
          isPII: false,
        }}
      />
      <Page pageTitle="My Students | Hoot Reading" RootBoxProps={{ py: 0 }}>
        <Card sx={{ marginTop: '16px' }} isLoading={myStudentsQuery.isFetching}>
          <Stack spacing={2}>
            <SearchTextField
              label="Student Search"
              searchInput={searchInput}
              onSearchInputChanged={onSearchInputChanged}
              onSearchInputDebounced={onSearchInputDebounced}
              onClearButtonClicked={onClearSearchInput}
            />

            {query.query ? (
              <HootTypography isPII={false} variant="titlesmall">
                Showing {count} results for "{query.query}"
              </HootTypography>
            ) : null}

            <TableV2
              isPaginated
              isSortable
              data={data}
              headers={headers}
              rowsPerPage={query.pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              count={count}
              page={query.page - 1}
              onPageChange={handleChangePage}
              onSortBy={handleSortBy}
              sortOrder={query.orderBy}
              sortBy={sortBy()}
            />
          </Stack>
        </Card>
      </Page>
    </>
  );
}
