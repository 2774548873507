import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, Stack, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { capitalCase } from 'change-case';
import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DistrictRepresentativeStatus } from '@hoot/hooks/api/district-rep/useGetDistrictRepresentativeDetails';
import useSearchDistrictRepresentativeTeamMembers, {
  DistrictRepresentativeTeamMembersQuery,
} from '@hoot/hooks/api/district-rep/useSearchDistrictRepTeamMembers';
import useSearchDistrictRepresentativeEnrolmentsFilters from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeEnrolmentsFilters';
import { useDebounce } from '@hoot/hooks/useDebounce';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { RootState } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import SubHeader from '@hoot/ui/components/v2/SubHeader';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import { Chip } from '@hoot/ui/components/v2/core/Chip';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Page from '@hoot/ui/components/v2/core/Page';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import Tag, { TagColor } from '@hoot/ui/components/v2/core/Tag';
import TextField from '@hoot/ui/components/v2/core/TextField';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { formatPhoneNumber } from '@hoot/utils/text-display';
import TeamMembersFiltersDialog from './TeamMembersFiltersDialog';

export interface TeamMemberRow {
  id: string;
  name: ReactNode;
  email: ReactNode;
  phoneNumber: ReactNode;
  enrolmentIds: ReactNode;
  status: ReactNode;
}

export interface DistrictRepresentativeTeamMembers {
  count: number;
  page: number;
  pageSize: number;
  teamMembers: TeamMemberRow[];
}

const IGNORE_FILTERS = ['name', 'page', 'pageSize', 'sortBy', 'orderBy', 'studentProfileId', 'locationId'];
export type DistrictRepresentativeEnrolmentsQueryKeys = keyof DistrictRepresentativeTeamMembersQuery;

const FilterChips = (props: {
  badgesWithMultiSelectors: any[];
  query: DistrictRepresentativeTeamMembersQuery;
  setQuery: React.Dispatch<React.SetStateAction<DistrictRepresentativeTeamMembersQuery>>;
  districtRepresentativeId?: string;
}) => {
  const { badgesWithMultiSelectors, query, setQuery, districtRepresentativeId } = props;
  const enrolments = useSearchDistrictRepresentativeEnrolmentsFilters(districtRepresentativeId!, {
    enabled: !!districtRepresentativeId,
    keepPreviousData: true,
  });

  return (
    <Grid container gap={2}>
      {badgesWithMultiSelectors?.map((badge) => {
        const [filterKey, filterValue]: [DistrictRepresentativeEnrolmentsQueryKeys, any] = badge;
        let key = filterKey;
        let value;

        if (filterKey === 'enrolmentId') {
          value = enrolments.data?.find((enrolment) => enrolment.id === filterValue)?.friendlyId;
        } else {
          value = capitalCase(filterValue);
        }

        return (
          <Chip
            key={`${key}-${value}`}
            label={`${capitalCase(key)}: ${value}`}
            onDelete={() => {
              setQuery((prevState) => {
                const value = query[filterKey];
                return {
                  ...prevState,
                  [filterKey]: Array.isArray(value) ? (value as any)?.filter((val: string) => val !== filterValue) : undefined,
                };
              });
            }}
          />
        );
      })}
    </Grid>
  );
};

function HFSTeamMembers() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const districtRepresentativeId = useSelector((state: RootState) => state.profile.profile?.id);
  const [searchText, setSearchText] = useState<string>();
  const debouncedSearchText = useDebounce(searchText, 1000);
  const [query, setQuery] = useState<DistrictRepresentativeTeamMembersQuery>({
    page: 1,
    pageSize: 10,
    sortBy: 'name',
    orderBy: OrderBy.Asc,
  });
  const [data, setData] = useState<DistrictRepresentativeTeamMembers>();
  const [showFiltersDialog, setShowFiltersDialog] = useState(false);

  const { isFetching } = useSearchDistrictRepresentativeTeamMembers(districtRepresentativeId || '', query, {
    enabled: !!districtRepresentativeId,
    onSuccess: (data) => {
      const teamMembers = data.teamMembers.map(
        (teamMember): TeamMemberRow => ({
          id: teamMember.id,
          name: (
            <HootTypography
              isPII={true}
              variant="bodymedium"
              sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => navigate(routesDictionary.teamMembers.districtRep.details.url(teamMember.id))}
            >
              {teamMember.name}
            </HootTypography>
          ),
          phoneNumber: (
            <HootTypography variant="bodymedium" isPII={true}>
              {formatPhoneNumber(teamMember.phoneNumber) ?? ''}
            </HootTypography>
          ),
          email: (
            <HootTypography variant="bodymedium" isPII={true}>
              {teamMember.email}
            </HootTypography>
          ),
          enrolmentIds: (
            <Stack gap={'4px'}>
              {teamMember.enrolments.map((enrolment) => (
                <HootTypography
                  isPII={false}
                  variant="bodymedium"
                  sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => navigate(routesDictionary.enrolments.enrolment.details.url(enrolment.id), { state: { districtRepId: null } })}
                >
                  {enrolment.friendlyId}
                </HootTypography>
              ))}
            </Stack>
          ),
          status: (
            <Tag
              label={capitalCase(teamMember?.status ?? '')}
              color={teamMember?.status === DistrictRepresentativeStatus.Active ? TagColor.Success : TagColor.Warning}
              sx={{ ...hootTokens.text.labelsmall }}
            />
          ),
        }),
      );

      const teamMembersResponse = {
        count: data.count,
        page: data.page,
        pageSize: data.pageSize,
        teamMembers: teamMembers,
      };
      setData(teamMembersResponse);
    },
  });

  const badges = () => {
    return Object.entries(query).filter(([k, v]) => !IGNORE_FILTERS.includes(k) && v !== undefined && (Array.isArray(v) ? v.length !== 0 : true)) as [
      DistrictRepresentativeEnrolmentsQueryKeys,
      any,
    ][];
  };

  const badgesWithMultiSelectors = badges().reduce((acc: any[], badge) => {
    if (Array.isArray(badge[1])) return [...acc, ...badge[1].map((val: any) => [badge[0], val])];
    return [...acc, badge];
  }, []);

  useEffect(() => {
    setQuery((current) => ({ ...current, name: debouncedSearchText }));
  }, [debouncedSearchText]);

  const headers: HeaderData<TeamMemberRow>[] = [
    { name: 'Name', property: 'name', isSortable: true, width: '10%' },
    { name: 'Email', property: 'email', isSortable: false, width: '25%' },
    { name: 'Phone', property: 'phoneNumber', isSortable: false, width: '20%', isHidden: !isDesktop },
    { name: 'Enrolments', property: 'enrolmentIds', isSortable: false, width: '35%', isHidden: !isDesktop },
    { name: 'Status', property: 'status', isSortable: true, width: '10%', isHidden: !isDesktop },
  ];

  const clearInput = () => {
    setSearchText('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <>
      <SubHeader title={{ label: 'Team Members', isPII: false }} />

      <Page pageTitle="Hoot for Schools Enrolments | Hoot Reading">
        <Stack>
          <Card title="Team Members" isLoading={isFetching} sx={{ overflowX: 'scroll' }}>
            <Stack gap={2}>
              <Stack direction="row" alignItems="center" gap={2}>
                <TextField
                  clearable
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: 'black' }} />
                      </InputAdornment>
                    ),
                  }}
                  label="Team Members Search"
                  onClearButtonClicked={clearInput}
                  variant="outlined"
                  value={searchText}
                  onChange={handleInputChange}
                  sx={{ margin: 0 }}
                />

                <Button
                  color="primary"
                  onFocusVisible={() => {}}
                  variant="contained"
                  size="medium"
                  startIcon={
                    <InputAdornment position="start">
                      <FilterAltOutlinedIcon sx={{ color: 'white' }} />
                    </InputAdornment>
                  }
                  onClick={() => setShowFiltersDialog(true)}
                >
                  Filter
                </Button>
              </Stack>

              {query.name ? (
                <HootTypography variant="titlesmall" isPII={false}>
                  Showing {data?.count} results for "{query.name}"
                </HootTypography>
              ) : null}
              {badgesWithMultiSelectors.length ? (
                <FilterChips
                  badgesWithMultiSelectors={badgesWithMultiSelectors}
                  query={query}
                  setQuery={setQuery}
                  districtRepresentativeId={districtRepresentativeId}
                />
              ) : null}

              <TableV2
                allowRowsPerPage
                rowsPerPageOptions={[1, 2, 3, 4, 5]}
                isPaginated
                isSortable
                count={data?.count ?? 0}
                data={data?.teamMembers ?? []}
                headers={headers}
                onPageChange={(_event, page) => {
                  setQuery((prevState) => ({ ...prevState, page: page + 1 }));
                }}
                onRowsPerPageChange={(event) => {
                  setQuery((prevState) => ({ ...prevState, page: 1, pageSize: +event.target.value }));
                }}
                onSortBy={(val: any) => {
                  setQuery((prevState) => ({
                    ...prevState,
                    page: 1,
                    sortBy: val,
                    orderBy: prevState.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
                  }));
                }}
                page={data?.page ? data.page - 1 : 0}
                sortOrder={query.orderBy}
                sortBy={query.sortBy as any}
                rowsPerPage={query.pageSize}
              />
            </Stack>
          </Card>
          {showFiltersDialog ? (
            <TeamMembersFiltersDialog show={showFiltersDialog} onDismiss={() => setShowFiltersDialog(false)} query={query} onChange={setQuery} />
          ) : null}
        </Stack>
      </Page>
    </>
  );
}

export default HFSTeamMembers;
