import { Box } from '@mui/system';
import { Option } from '@hoot/models/formOption';

interface ICheckBoxGroupProps {
  name: string;
  options: Option[];
  onChange: (val: string[]) => void;
  value: string[];
  label?: string;
}

export const CheckBoxGroup = (props: ICheckBoxGroupProps) => {
  const { name, options, onChange, value = [], label } = props;

  const handleClick = (selectedValue: string) => () => {
    const isFound = value.findIndex((v) => v === selectedValue) >= 0;
    if (isFound) {
      const update = value.filter((v) => v !== selectedValue);
      onChange(update);
    } else {
      const update = [...value, selectedValue];
      onChange(update);
    }
  };

  return (
    <div>
      <div>{label}</div>
      {options.map((o) => (
        <Box sx={{ flexBasis: '33%' }}>
          <input
            id={`${name}-${o.value}`}
            style={{ cursor: 'pointer' }}
            type="checkbox"
            checked={value.some((v) => v === o.value)}
            onClick={handleClick(o.value)}
          />
          <label style={{ cursor: 'pointer' }} htmlFor={`${name}-${o.value}`}>
            {o.label}
          </label>
        </Box>
      ))}
    </div>
  );
};
