import {
  ScheduledLessonLanguage,
  ScheduledLessonStatus,
  ScheduledLessonSubject,
  ScheduledLessonType,
  lessonLanguagesLookup,
  lessonStatusesLookup,
  lessonSubjectLookup,
} from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReaderLesson } from '@hoot/models/api/lessons';
import { RootState } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import ReadOnlyTextField from '@hoot/ui/components/v2/core/ReadOnlyTextField';
import Tag, { TagColor } from '@hoot/ui/components/v2/core/Tag';
import { useAuth } from '@hoot/ui/context/AuthContext';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { formatTimeZoneForSchedule } from '@hoot/utils/timeZones';

interface UpcomingLessonProps {
  lesson: ReaderLesson;
}

const UpcomingLesson = (props: UpcomingLessonProps) => {
  const { lesson } = props;
  const profiles = useSelector((state: RootState) => state.profile.profiles);
  const { assumeProfile } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const isActiveLesson = lesson.status !== ScheduledLessonStatus.Scheduled;
  const userTimeZone = moment.tz.guess();
  const timeZone = formatTimeZoneForSchedule(userTimeZone);

  const handleJoinLesson = () => {
    const studentProfileToAssume = profiles.find((p) => p.id === lesson.studentId);
    if (!studentProfileToAssume) {
      return;
    }
    assumeProfile(studentProfileToAssume, () => navigate(routesDictionary.library.url, { state: lesson }));
  };

  const lessonTimeLabel = useMemo(() => {
    const startDateTime = DateTime.fromMillis(lesson.startTime, { zone: userTimeZone });
    const endDateTime = startDateTime.plus({ minute: lesson.durationInMinutes });
    return `${startDateTime.toFormat('h:mma')} - ${endDateTime.toFormat('h:mma')}`;
  }, [userTimeZone, lesson.startTime, lesson.durationInMinutes]);

  const headerLabel = useMemo(() => {
    if (lesson.subject === ScheduledLessonSubject.Math) {
      return 'Math Lesson';
    } else if (
      lesson.type === ScheduledLessonType.Assessment ||
      lesson.type === ScheduledLessonType.PreAssessment ||
      lesson.type === ScheduledLessonType.PostAssessment
    ) {
      return 'Assessment';
    } else if (lesson.type === ScheduledLessonType.FirstLesson) {
      return 'First Lesson';
    } else {
      const language = lesson.language !== ScheduledLessonLanguage.English ? lessonLanguagesLookup[lesson.language] : '';

      return [language, 'Reading Lesson'].join(' ').trim();
    }
  }, [lesson.subject, lesson.type, lesson.language]);

  const statusLabel = useMemo(() => {
    switch (lesson.status) {
      case ScheduledLessonStatus.Open:
      case ScheduledLessonStatus.OpenWaitingForTeacher:
        return lessonStatusesLookup[ScheduledLessonStatus.Open];
      default:
        return lessonStatusesLookup[lesson.status];
    }
  }, [lesson.status]);

  const handleTextFieldColor = (status: ScheduledLessonStatus) => {
    if (status === ScheduledLessonStatus.InProgress) {
      return 'success.190';
    } else if (status === ScheduledLessonStatus.Scheduled) {
      return 'neutral.190';
    } else {
      return 'secondary.190';
    }
  };

  const handleTagColor = (status: ScheduledLessonStatus) => {
    if (status === ScheduledLessonStatus.InProgress) {
      return TagColor.Success;
    } else if (status === ScheduledLessonStatus.Scheduled) {
      return TagColor.Neutral;
    } else {
      return TagColor.Info;
    }
  };

  const handleIconColor = (status: ScheduledLessonStatus) => {
    if (status === ScheduledLessonStatus.InProgress) {
      return hootTokens.palette.success[100];
    } else if (status === ScheduledLessonStatus.Scheduled) {
      return hootTokens.palette.black;
    } else {
      return hootTokens.palette.secondary[100];
    }
  };

  const textFieldColor = handleTextFieldColor(lesson.status);

  return (
    <Card
      title={
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Icon name="bell_solid" sx={{ color: handleIconColor(lesson.status) }} />
            <span>{headerLabel}</span>
          </Stack>
          <Tag label={statusLabel} color={handleTagColor(lesson.status)} />
        </Stack>
      }
      sx={{ background: hootTokens.palette.neutral['195'] }}
      elevation={2}
    >
      <Grid container spacing={2}>
        <Grid size={{ md: 6, xs: 12 }}>
          <ReadOnlyTextField label="Student" body={lesson.studentName} filledColour={textFieldColor} />
        </Grid>
        <Grid size={{ md: 6, xs: 12 }}>
          <Box>
            <ReadOnlyTextField label={'Lesson Time'} body={lessonTimeLabel} filledColour={textFieldColor} />
            <HootTypography isPII={false} sx={{ marginLeft: '16px' }} variant="labelsmall">
              {timeZone}
            </HootTypography>
          </Box>
        </Grid>
        <Grid size={{ md: 6, xs: 12 }}>
          <ReadOnlyTextField label={'Subject'} body={lessonSubjectLookup[lesson.subject as ScheduledLessonSubject]} filledColour={textFieldColor} />
        </Grid>
        <Grid size={{ md: 6, xs: 12 }}>
          <ReadOnlyTextField label={'Teacher'} body={lesson.teacherName ?? 'A Hoot Teacher'} filledColour={textFieldColor} />
        </Grid>
        <Grid size={12}>
          <ReadOnlyTextField label={'Lesson Number'} body={lesson.lessonNumber.toString()} filledColour={textFieldColor} />
        </Grid>
        <Grid size={12}>
          {isActiveLesson && isDesktop && (
            <Button variant="contained" onClick={handleJoinLesson} startIcon={<Icon name="arrow_icon" color="primary.200" />}>
              Join Lesson
            </Button>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default UpcomingLesson;
