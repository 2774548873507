import { CircularProgress, Divider, Stack, useTheme } from '@mui/material';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { DISTRICT_SCHOOL_ID_REGEX } from '@hoot/constants/constants';
import { GetSsoLaunchInfoResponse, useGetEdlinkSsoLaunchInfo } from '@hoot/hooks/api/auth/useGetEdlinkSsoLaunchInfo';
import { CredentialType } from '@hoot/hooks/api/auth/useLogin';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { SessionStorageKey } from '@hoot/models/sessionStorageKey';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { useAppDispatch } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import EdlinkSsoRedirectHandler from '@hoot/ui/components/v2/EdlinkSsoRedirectHandler';
import PrivacyPolicyTerms from '@hoot/ui/components/v2/PrivacyPolicyTerms';
import { Button } from '@hoot/ui/components/v2/core/Button';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import TextField from '@hoot/ui/components/v2/core/TextField';
import ArrowIcon from '@hoot/ui/components/v2/icons/ArrowIcon';
import CollectionsFilled from '@hoot/ui/components/v2/icons/CollectionsFilled';
import HootLogo from '@hoot/ui/components/v2/icons/HootLogo';
import { RotateDirection } from '@hoot/ui/components/v2/utils/icon-utils';
import { useAuth } from '@hoot/ui/context/AuthContext';
import ForgotPasswordModalEmail from '@hoot/ui/pages/v2/public/login/ForgotPasswordModalEmail';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface Form {
  email: string;
  password: string;
  districtSchoolId: string;
}

const LoginPage = () => {
  usePageTitle('Login | Hoot Reading');

  const { login, isPerformingSsoExchange } = useAuth();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const [openForgotPassword, setOpenForgotPassword] = useState<boolean>(false);
  const [ssoLaunchInfo, setSsoLaunchInfo] = useState<GetSsoLaunchInfoResponse | undefined>(undefined);
  const [isSchoolLogin, setIsSchoolLogin] = useState<boolean>(false);

  useGetEdlinkSsoLaunchInfo(null, {
    cacheTime: 0,
    onSuccess: (data: GetSsoLaunchInfoResponse) => {
      setSsoLaunchInfo(data);
    },
    onError: () => {
      window.sessionStorage.removeItem(SessionStorageKey.EDLINK_SSO_STATE);
    },
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    setError,
    control,
    getValues,
  } = useForm<Form>({
    defaultValues: {
      email: '',
      password: '',
      districtSchoolId: '',
    },
  });

  const onSubmit = async (data: Form) => {
    const redirectPath = location.state?.redirectPath;
    const redirectSearch = location.state?.redirectSearch;
    const redirectUrl = redirectPath ? redirectPath.concat(redirectSearch ?? '') : undefined;
    window.sessionStorage.setItem(SessionStorageKey.LOGIN_REDIRECT_URL, redirectUrl);

    await login({
      credentials: data.email,
      password: data.password,
      credentialType: CredentialType.Email,
    }).catch((err) => {
      const axiosError = isAxiosError(err) ? err : undefined;

      dispatch(
        createFlashMessage({
          variant: 'error',
          message: axiosError?.response?.data.message ?? 'Login failed.',
        }),
      );

      // If unauthorized, then we're assuming it's due to wrong username or pw.
      if (axiosError?.response?.status === 401) {
        setError('email', {
          type: 'manual',
        });
        setError('password', {
          type: 'manual',
          message: 'Incorrect email or password.',
        });
      }
    });
  };

  const onProceedClick = () => {
    let isProperFriendlyID = DISTRICT_SCHOOL_ID_REGEX.test(getValues('districtSchoolId'));

    if (!isProperFriendlyID) {
      setError('districtSchoolId', {
        type: 'pattern',
        message: 'Incorrect District or School ID',
      });
    } else {
      navigate(routesDictionary.login.district.url(getValues('districtSchoolId')));
    }
  };

  return (
    <>
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '24px',
          padding: '24px',
          backgroundImage: 'url("/images/flf-background.webp")',
          backgroundSize: 'cover',
          '@media (max-height: 700px)': {
            height: '800px',
            justifyContent: 'flex-start',
          },
        }}
      >
        <Stack
          sx={{
            width: '100%',
            maxWidth: '400px',
            padding: '24px',
            borderRadius: '8px',
            background: hootTokens.palette.white,
          }}
        >
          {!!ssoLaunchInfo ? <EdlinkSsoRedirectHandler /> : null}

          <HootLogo sx={{ width: 134, height: 48, margin: theme.spacing(4, 'auto', 5) }} />

          {isPerformingSsoExchange ? (
            <Stack alignItems="center" gap={3}>
              <HootTypography variant="bodylarge" textAlign="center" isPII={false}>
                Logging you in...
              </HootTypography>
              <CircularProgress />
            </Stack>
          ) : isSchoolLogin ? (
            <Stack gap={2}>
              <HootTypography variant="titlelarge" textAlign="center" mb={2} isPII={false}>
                School Login
              </HootTypography>

              {/* District or School ID */}
              <Controller
                name="districtSchoolId"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <TextField
                    data-hoot-canary-id="districtSchoolId"
                    label={'District or School ID'}
                    value={value}
                    helperText={fieldState.error?.message || ''}
                    error={!!fieldState.error}
                    onChange={onChange}
                    type="text"
                    fullWidth
                    required
                    autoFocus
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onProceedClick();
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
              <Button onClick={onProceedClick} variant="contained" fullWidth>
                Proceed
              </Button>

              <Button
                onClick={() => setIsSchoolLogin(false)}
                variant="text"
                color="secondary.60"
                fullWidth
                startIcon={<ArrowIcon rotate={RotateDirection.Left} htmlColor="#076B68" />}
              >
                Back
              </Button>

              <PrivacyPolicyTerms />
            </Stack>
          ) : (
            <>
              <HootTypography variant="titlelarge" isPII={false} mb={2} textAlign="center">
                Login
              </HootTypography>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack gap={2} justifyContent="center">
                  {/* Email */}
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, value }, fieldState }) => (
                      <TextField
                        data-hoot-canary-id="email"
                        label={'Email Address'}
                        value={value}
                        helperText={fieldState.error?.message || ''}
                        error={!!fieldState.error}
                        onChange={onChange}
                        type="email"
                        autoComplete="true"
                        fullWidth
                        required
                        autoFocus
                      />
                    )}
                  />

                  {/* Password */}
                  <Controller
                    name="password"
                    control={control}
                    render={({ field: { onChange, value }, fieldState }) => (
                      <TextField
                        data-hoot-canary-id="password"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || ''}
                        label={'Password'}
                        fullWidth
                        required
                        onChange={onChange}
                        type="password"
                        value={value}
                      />
                    )}
                  />

                  <Button data-hoot-canary-id="btnLogin" color="primary" isLoading={isSubmitting} type="submit" variant="contained" fullWidth>
                    Login
                  </Button>

                  <Button data-hoot-canary-id="forgot-password" variant="text" onClick={() => setOpenForgotPassword(true)}>
                    I forgot my password
                  </Button>

                  <Divider
                    sx={{
                      marginBottom: '24px',
                      '&::before, &::after': {
                        borderColor: hootTokens.palette.black,
                      },
                    }}
                  >
                    <HootTypography variant="bodysmall" isPII={false}>
                      Or continue with
                    </HootTypography>
                  </Divider>

                  <Button
                    onClick={() => setIsSchoolLogin(true)}
                    variant="outlined"
                    fullWidth
                    color="secondary.60"
                    startIcon={<CollectionsFilled fill={hootTokens.palette.secondary[60]} />}
                    sx={{ mb: 3 }}
                  >
                    Find my School to Login
                  </Button>

                  <PrivacyPolicyTerms />
                </Stack>
              </form>
            </>
          )}
        </Stack>
      </Stack>

      <ForgotPasswordModalEmail open={openForgotPassword} setOpen={setOpenForgotPassword} />
    </>
  );
};

export default LoginPage;
