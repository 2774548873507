import { ScheduledLessonStatus } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

export function lessonStatusBGColor(status: ScheduledLessonStatus) {
  switch (status) {
    case ScheduledLessonStatus.OpenWaitingForTeacher:
    case ScheduledLessonStatus.OpenWaitingForStudent:
    case ScheduledLessonStatus.Open:
    case ScheduledLessonStatus.InProgress:
    case ScheduledLessonStatus.Scheduled:
      return hootTokens.palette.primary[190];
    case ScheduledLessonStatus.Rescheduled:
    case ScheduledLessonStatus.Cancelled:
      return hootTokens.palette.error[190];
    case ScheduledLessonStatus.CompletedSuccessfully:
      return hootTokens.palette.success[190];
    case ScheduledLessonStatus.CompletedUnsuccessfully:
      return hootTokens.palette.warning[190];
  }
}
