// Kind of a hacky solution, but at the time of writing this, there is no easy way to distinguish the difference between
// some instructional focuses (some share the same name but are used on different book types). To get around this, we'll
// keep a hardcoded list of the instructional focus IDs.
export enum InstructionalFocus {
  HootReadingAssessment = '5e7b3045-c5f7-97c0-0eea-ccf481065ecd',
  AssessmentTextReading = '5f72f592-e479-2cef-c8be-341e2d0e2fec',
  PreWordResource = '062ff663-d269-00ac-7703-c944874cde5e',
  EarlyWord = 'd21bfa7f-7ae4-1847-d931-668c388b02e9',
  ComplexWordReading = '72e9e1a2-56c4-d876-3f54-e1f84945548d',
  InstructionTextReading = '53a6971a-d545-465d-6029-c50a525ccd90',
  FormativeAssessmentTextReading = '8a1211b0-62f3-488d-b284-10e980be0659',
  FormativeAssessmentEarlyWordReading = 'cdf02ebb-b2a0-4e1c-a876-7aa17c2bf746',
  FormativeAssessmentComplexWordReading = '3351ce0e-333b-4ae1-ad24-3ba0aafdfb54',
  FormativeAssessmentPreWordReading = 'cb63ef48-241a-468a-82ef-8b436736bdfd',
}
