import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { InvoiceLineItemStatus } from '../../../ui/pages/v2/teacher/invoicing/enums';

export interface BillableTask {
  id: string;
  taskType: string;
  projectId: string;
  date: string;
  total: number;
  status: InvoiceLineItemStatus;
  duration?: string;
  quantity?: string;
  teacherComment?: string;
  reviewerComment?: string;
  lessonNumber?: string;
}

export interface InvoiceBillableTasksResponse {
  billableTasks: BillableTask[];
}

async function getInvoiceBillableTasks(invoiceId: string) {
  const { data } = await axios.get<InvoiceBillableTasksResponse>(`${config.apiUrl}/public/teacher-invoicing/${invoiceId}/billable-tasks`);
  return data;
}

export function useGetBillableTasks(invoiceId: string, options?: Omit<UseQueryOptions<InvoiceBillableTasksResponse>, 'queryKey' | 'queryFn'>) {
  return useQuery([QueryKey.GetInvoiceBillableTasks, invoiceId], () => getInvoiceBillableTasks(invoiceId), options);
}
