import { Stack } from '@mui/material';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';

export function TechnicalDifficulties() {
  return (
    <Stack top={0} bottom={0} left={0} right={0} position="fixed" alignItems="center" justifyContent="center">
      <Stack>
        <Stack direction="row" justifyContent="center">
          <img src="/images/logo-v2.svg" alt="logo" height="40px" width="120px" />
        </Stack>

        <HootTypography isPII={false} variant="bodymedium" sx={{ marginTop: '16px', fontSize: '32px' }}>
          Sorry! We're currently experiencing technical difficulties. Please try again in a moment.
        </HootTypography>
      </Stack>
    </Stack>
  );
}
