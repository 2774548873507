import { SxProps } from '@mui/system';
import { ChangeEvent } from 'react';
import { ResourceProgress, resourceProgressValues } from '../../../../models/api/library';
import { Select } from '../core/Select';

export function ProgressSelect(props: { sx: SxProps; value?: ResourceProgress; onChange: (value: ResourceProgress) => void }) {
  const options = resourceProgressValues
    .filter((v) => [ResourceProgress.Practicing, ResourceProgress.Finished].some((onlyShow) => v.value === onlyShow))
    .map((l) => (
      <option key={l.value as string} value={l.value as string}>
        {l.label}
      </option>
    ));

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    props.onChange(event.target.value as ResourceProgress);
  };

  return (
    <Select sx={props.sx} label="Progress" value={props.value} onChange={handleChange}>
      {options}
    </Select>
  );
}
