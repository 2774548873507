import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { InputAdornment, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { capitalCase } from 'change-case';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSearchDistrictRepresentativeEnrolmentsFilters from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeEnrolmentsFilters';
import useSearchDistrictRepresentativeStudentsUnderSchool, {
  DistrictRepresentativeStudentsUnderLocationQueryKey,
  DistrictRepresentativeStudentsUnderSchoolQuery,
} from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeStudentsUnderSchool';
import useGetDistrictRepPermissions, { DistrictRepAccessLevel } from '@hoot/hooks/api/user/useGetDistrictRepPermissions';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { RootState } from '@hoot/redux/store';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { hasDistrictPermission } from '@hoot/routes/secure/ProtectedRoute';
import ProtectedLink from '@hoot/ui/components/v2/ProtectedLink';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import { Chip } from '@hoot/ui/components/v2/core/Chip';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import Page from '@hoot/ui/components/v2/core/Page';
import SearchTextField, { SearchTextFieldProps } from '@hoot/ui/components/v2/core/SearchTextField';
import { Snackbar } from '@hoot/ui/components/v2/core/Snackbar';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import BasicAlertDialog from '@hoot/ui/components/v2/dialogs/BasicAlertDialog';
import StudentsFiltersDialog, { DialogFormFields, FieldsToShow } from '../../students/StudentsFiltersDialog';

interface StudentRow {
  id: string;
  name: React.ReactNode;
  grade: string;
  number: string;
  enrolmentFriendlyIds: React.ReactNode;
}

interface DistrictRepresentativeStudents {
  count: number;
  page: number;
  pageSize: number;
  students: StudentRow[];
}

const headers: HeaderData<StudentRow>[] = [
  { name: 'Name', property: 'name', isSortable: true },
  { name: 'Student Number', property: 'number', isSortable: true },
  { name: 'Grade', property: 'grade', isSortable: true },
  { name: 'Enrolment ID(s)', property: 'enrolmentFriendlyIds' },
];

const filterFieldToShow: FieldsToShow = {
  status: true,
  startDate: false,
  endDate: false,
  enrolmentId: true,
  grade: true,
  passwordStatus: false,
};

const IGNORE_FILTERS = ['page', 'pageSize', 'sortBy', 'orderBy', 'searchText'];
type DistrictRepresentativeStudentsQueryKeys = keyof DistrictRepresentativeStudentsUnderSchoolQuery;

const FilterChips = (props: {
  badgesWithMultiSelectors: any[];
  query: DistrictRepresentativeStudentsUnderSchoolQuery;
  setQuery: React.Dispatch<React.SetStateAction<DistrictRepresentativeStudentsUnderSchoolQuery>>;
  districtRepresentativeId?: string;
}) => {
  const { badgesWithMultiSelectors, query, setQuery, districtRepresentativeId } = props;
  const enrolments = useSearchDistrictRepresentativeEnrolmentsFilters(districtRepresentativeId!, {
    enabled: !!districtRepresentativeId,
    keepPreviousData: true,
  });

  return (
    <Grid container gap={2}>
      {badgesWithMultiSelectors?.map((badge) => {
        const [filterKey, filterValue]: [DistrictRepresentativeStudentsQueryKeys, any] = badge;
        let key = filterKey;
        let value;

        if (filterKey === 'enrolmentId') {
          value = enrolments.data?.find((enrolment) => enrolment.id === filterValue)?.friendlyId;
        } else {
          value = capitalCase(filterValue);
        }

        return (
          <Chip
            key={`${key}-${value}`}
            label={`${capitalCase(key)}: ${value}`}
            onDelete={() => {
              setQuery((prevState) => {
                const value = query[filterKey];
                return {
                  ...prevState,
                  [filterKey]: Array.isArray(value) ? (value as any)?.filter((val: string) => val !== filterValue) : undefined,
                };
              });
            }}
          />
        );
      })}
    </Grid>
  );
};

export function DistrictRepSchoolStudents() {
  const { schoolId } = useParams();
  const districtRepresentativeId = useSelector((state: RootState) => state.profile.profile?.id);
  const [searchText, setSearchText] = useState<string>('');
  const [query, setQuery] = useState<DistrictRepresentativeStudentsUnderSchoolQuery>({
    page: 1,
    pageSize: 10,
    sortBy: DistrictRepresentativeStudentsUnderLocationQueryKey.Name,
    orderBy: OrderBy.Asc,
  });
  const [students, setStudents] = useState<DistrictRepresentativeStudents>({ count: 0, page: 1, pageSize: 10, students: [] });
  const [showFiltersDialog, setShowFiltersDialog] = useState(false);
  const [showError, setShowError] = useState<string>('');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { data: districtRepPermission } = useGetDistrictRepPermissions(districtRepresentativeId || '');
  const hasEveryonePermission = hasDistrictPermission(DistrictRepAccessLevel.Everyone, districtRepPermission);

  const { isLoading } = useSearchDistrictRepresentativeStudentsUnderSchool(districtRepresentativeId!, schoolId!, query, {
    onSuccess: (data) => {
      const students = data.data.map(
        (student): StudentRow => ({
          id: student.id,
          name: (
            <ProtectedLink to={routesDictionary.students.student.details.url(student.id)} hasPermission={hasEveryonePermission}>
              <p>{student.name}</p>
            </ProtectedLink>
          ),
          grade: capitalCase(student?.grade ?? 'Not Set'),
          number: student.hfsStudentNumber ?? 'Not Set',
          enrolmentFriendlyIds: student.enrolments.map((enrolment) => (
            <ProtectedLink to={routesDictionary.enrolments.enrolment.details.url(enrolment.id)} hasPermission={hasEveryonePermission}>
              <p>{enrolment.friendlyId}</p>
            </ProtectedLink>
          )),
        }),
      );

      const studentResponse = {
        count: data.count,
        page: data.page,
        pageSize: data.pageSize,
        students: students,
      };
      setStudents(studentResponse);
    },
  });

  const badges = () => {
    return Object.entries(query).filter(([k, v]) => !IGNORE_FILTERS.includes(k) && v !== undefined && (Array.isArray(v) ? v.length !== 0 : true)) as [
      DistrictRepresentativeStudentsQueryKeys,
      any,
    ][];
  };

  const badgesWithMultiSelectors = badges().reduce((acc: any[], badge) => {
    if (Array.isArray(badge[1])) return [...acc, ...badge[1].map((val: any) => [badge[0], val])];
    return [...acc, badge];
  }, []);

  const onSearchInputChanged: SearchTextFieldProps['onSearchInputChanged'] = (text) => {
    setSearchText(text);
  };

  const onSearchInputDebounced: SearchTextFieldProps['onSearchInputDebounced'] = (text) => {
    setQuery((prev) => ({
      ...prev,
      searchText: text.length > 0 ? text : undefined,
    }));
  };

  const onClearSearchInput = () => {
    setSearchText('');
    setQuery((prev) => ({
      ...prev,
      searchText: undefined,
    }));
  };

  const handleClose = () => {
    setShowDialog(false);
    setShowError('');
  };

  const onFilterChange = (updatedFields: DialogFormFields) => {
    setQuery((current) => ({ ...current, ...updatedFields }));
  };

  return (
    <Page pageTitle="Hoot for Schools Students | Hoot Reading">
      <Stack>
        <Card isLoading={isLoading}>
          <Stack gap={2}>
            <HootTypography isPII={false} variant="titlemedium">
              Students
            </HootTypography>
            <Stack direction="row" alignItems="center" gap={2}>
              <SearchTextField
                label="Student Search"
                searchInput={searchText}
                onSearchInputChanged={onSearchInputChanged}
                onSearchInputDebounced={onSearchInputDebounced}
                onClearButtonClicked={onClearSearchInput}
              />
              <Button
                color="primary"
                variant="contained"
                startIcon={
                  <InputAdornment position="start">
                    <FilterAltOutlinedIcon sx={{ color: 'white' }} />
                  </InputAdornment>
                }
                onClick={() => setShowFiltersDialog(true)}
              >
                Filter
              </Button>
            </Stack>
            {query.searchText ? (
              <HootTypography isPII={true} variant="titlesmall">
                Showing {students?.count} results for "{query.searchText}"
              </HootTypography>
            ) : null}
            {!!badgesWithMultiSelectors.length ? (
              <FilterChips
                badgesWithMultiSelectors={badgesWithMultiSelectors}
                query={query}
                setQuery={setQuery}
                districtRepresentativeId={districtRepresentativeId}
              />
            ) : null}
            <TableV2
              allowRowsPerPage
              isPaginated
              isSortable
              count={students?.count ?? 0}
              data={students?.students ?? []}
              headers={headers}
              onPageChange={(event, page) => {
                setQuery((prevState) => ({ ...prevState, page: page + 1 }));
              }}
              onRowsPerPageChange={(event) => {
                setQuery((prevState) => ({ ...prevState, page: 1, pageSize: +event.target.value }));
              }}
              onSortBy={(val: any) => {
                setQuery((prevState) => ({
                  ...prevState,
                  page: 1,
                  sortBy: val,
                  orderBy: prevState.orderBy === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
                }));
              }}
              page={students?.page ? students.page - 1 : 0}
              sortOrder={query.orderBy}
              sortBy={query.sortBy as any}
              rowsPerPage={query.pageSize}
            />
          </Stack>
        </Card>
      </Stack>
      {showFiltersDialog ? (
        <StudentsFiltersDialog
          show={showFiltersDialog}
          onDismiss={() => setShowFiltersDialog(false)}
          showFields={filterFieldToShow}
          values={query}
          onChange={onFilterChange}
        />
      ) : null}
      <Snackbar
        variant="error"
        message={'An unexpected error occurred'}
        actionLabel="View Error"
        open={!!showError}
        onAction={() => setShowDialog(true)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
      <BasicAlertDialog
        show={showDialog}
        onDismiss={handleClose}
        title={'Error'}
        content={showError}
        primaryAction={{
          onClick: handleClose,
          label: 'OK',
        }}
      />
    </Page>
  );
}
