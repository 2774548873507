import { BookSearch } from '@hoot/events/interfaces/book-search';
import AsyncImage from '@hoot/ui/components/v2/AsyncImage';
import Card, { CardProps } from '@hoot/ui/components/v2/core/Card';

interface StudentLibraryBookProps {
  book: BookSearch;
  onBookClicked: (book: BookSearch) => void;
  CardProps?: CardProps;
}

const StudentLibraryBook = (props: StudentLibraryBookProps) => {
  const { book, onBookClicked, CardProps = {} } = props;
  const { sx = {}, ...otherCardProps } = CardProps;

  const _onBookClicked = () => onBookClicked(book);

  return (
    <Card
      sx={{
        width: '248px',
        cursor: 'pointer',
        ...sx,
      }}
      onClick={_onBookClicked}
      {...otherCardProps}
    >
      <AsyncImage
        draggable={false}
        width="216px"
        style={{
          objectFit: 'contain',
          width: '100%',
          borderRadius: '8px',
          minHeight: '216px',
        }}
        src={book.coverUrl ?? undefined}
        alt={book.title}
      />
    </Card>
  );
};

export default StudentLibraryBook;
