import axios, { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { config } from '@hoot/config';

export interface FlfCreatePasswordRequestDto {
  password: string;
  verificationToken: string;
  account?: string;
}

export interface FlfCreatePasswordResponseDto {
  email: string;
}

export default function useFreeLessonFlowCreatePasswordRequest() {
  return useMutation<FlfCreatePasswordResponseDto, AxiosError, FlfCreatePasswordRequestDto>(async (request) => {
    return (await axios.post(`${config.apiUrl}/free-lesson/v2/create-password`, request)).data;
  });
}
