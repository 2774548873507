import { SvgIconProps } from '@mui/material';

const MenuDropDownIcon = (props: SvgIconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2635 15.9611L5.70987 7.99228C4.94798 6.65896 5.91071 5 7.44636 5L12 5L16.5536 5C18.0893 5 19.052 6.65897 18.2901 7.99228L13.7365 15.9611C12.9687 17.3048 11.0313 17.3048 10.2635 15.9611Z"
        fill={props.htmlColor ?? 'currentColor'}
      />
    </svg>
  );
};

export default MenuDropDownIcon;
