import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

const FreeLessonRegistrationLayout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const mobileLayout = () => (
    <>
      <Stack>
        <Box p={3}>
          <img src="/images/logo-v2.svg" alt=" logo" width="120px" height="42px" />
        </Box>
        <Box sx={(theme) => ({ padding: theme.spacing(0, 2, 3, 2) })}>
          <Outlet />
        </Box>
      </Stack>
    </>
  );

  const desktopLayout = () => (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '100vh',
          position: 'fixed',
          zIndex: '-1',
          backgroundSize: 'cover',
          overflow: 'hidden',
        }}
      >
        <a href="https://hootreading.com">
          <img src="/images/flf-background.webp" alt="Hoot logo" style={{ minHeight: '100%', minWidth: '100%' }} />
        </a>
      </Box>
      <Stack direction="row" justifyContent="center">
        <Box p={3}>
          <a href="https://www.hootreading.com">
            <img src="/images/logo-v2-white.svg" alt="logo" width="120px" height="42px" />
          </a>
        </Box>
      </Stack>
      <Stack direction="row" justifyContent="center">
        <Box p={3}>
          <Outlet />
        </Box>
      </Stack>
    </>
  );
  return isMobile ? mobileLayout() : desktopLayout();
};

export default FreeLessonRegistrationLayout;
