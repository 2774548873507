import { SvgIcon, SvgIconProps } from '@mui/material';

const SmileRectangle = (props: SvgIconProps) => {
  const { sx = {}, ...rest } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="none"
      sx={{ width: '112px', height: '112px', ...sx }}
      {...rest}
    >
      <path
        opacity="0.4"
        d="M22.7999 0.666626H89.1999C101.424 0.666626 111.333 10.5761 111.333 22.8V89.2C111.333 101.424 101.424 111.333 89.1999 111.333H22.7999C10.5759 111.333 0.666504 101.424 0.666504 89.2V22.8C0.666504 10.5761 10.5759 0.666626 22.7999 0.666626Z"
        fill="white"
      />
      <path
        d="M83.6665 44.9334C83.6665 47.9893 81.1892 50.4667 78.1332 50.4667C75.0772 50.4667 72.5999 47.9893 72.5999 44.9334C72.5999 41.8774 75.0772 39.4 78.1332 39.4C81.1892 39.4 83.6665 41.8774 83.6665 44.9334Z"
        fill="#28303F"
      />
      <path
        d="M39.3999 44.9334C39.3999 47.9893 36.9226 50.4667 33.8666 50.4667C30.8106 50.4667 28.3333 47.9893 28.3333 44.9334C28.3333 41.8774 30.8106 39.4 33.8666 39.4C36.9226 39.4 39.3999 41.8774 39.3999 44.9334Z"
        fill="#28303F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.1363 69.48C40.4229 67.9768 37.8147 68.1367 36.2981 69.8429C34.7753 71.5559 34.9296 74.179 36.6427 75.7017L39.3998 72.6C36.6427 75.7018 36.6414 75.7006 36.6427 75.7017L36.648 75.7065L36.6539 75.7117L36.6675 75.7237L36.7019 75.7536L36.7991 75.8365C36.8765 75.9017 36.979 75.9862 37.1063 76.0873C37.3607 76.2894 37.7148 76.5584 38.1647 76.8713C39.0629 77.4962 40.3517 78.3024 41.9986 79.1009C45.2928 80.6981 50.0612 82.2833 55.9998 82.2833C61.9384 82.2833 66.7068 80.6981 70.001 79.1009C71.648 78.3024 72.9367 77.4962 73.835 76.8713C74.2848 76.5584 74.6389 76.2894 74.8933 76.0873C75.0206 75.9862 75.1232 75.9017 75.2006 75.8365L75.2977 75.7536L75.3321 75.7237L75.3457 75.7117L75.3516 75.7065C75.3529 75.7053 75.3569 75.7018 72.5998 72.6L75.3569 75.7017C77.07 74.179 77.2243 71.5559 75.7016 69.8429C74.185 68.1367 71.5767 67.9768 69.8633 69.4801L69.8584 69.4843C69.8396 69.5002 69.794 69.538 69.7309 69.5882C69.6043 69.6887 69.391 69.852 69.0951 70.0578C68.502 70.4705 67.5861 71.0476 66.3799 71.6324C63.9679 72.8019 60.4362 73.9833 55.9998 73.9833C51.5634 73.9833 48.0318 72.8019 45.6197 71.6324C44.4136 71.0476 43.4977 70.4705 42.9045 70.0578C42.6086 69.852 42.3953 69.6887 42.2688 69.5882C42.2056 69.538 42.1643 69.5037 42.1454 69.4878C42.1413 69.4843 42.1383 69.4817 42.1363 69.48C42.1363 69.4801 42.1363 69.48 42.1363 69.48ZM69.8633 69.4801L69.8584 69.4843L69.8517 69.4903L69.8475 69.494C69.8535 69.4887 69.8572 69.4854 69.8633 69.4801Z"
        fill="#28303F"
      />
    </SvgIcon>
  );
};

export default SmileRectangle;
