import { Box, Card } from '@mui/material';
import React from 'react';
import { NotificationMessage } from '@hoot/events/messages/notification-message';
import { useDeleteNotificationBookmark } from '@hoot/hooks/api/notification/useDeleteNotificationBookmark';
import { createFlashMessage } from '@hoot/redux/reducers/flashMessageSlice';
import { removeBookmarkedNotification } from '@hoot/redux/reducers/notificationsSlice';
import { useAppDispatch } from '@hoot/redux/store';
import {
  formatNotificationTimestamp,
  isDisplayableTag,
  notificationTypeColorDictionary,
  notificationTypeLabelDictionary,
} from '@hoot/ui/components/v2/Notification';
import { hootTokens } from '../../theme/v2/tokens';
import HootTypography from './core/HootTypography';
import { Icon } from './core/Icon';
import IconButton from './core/IconButton';
import Tag, { TagColor } from './core/Tag';

interface BookmarkedNotificationProps {
  notification: NotificationMessage;
}

const BookmarkedNotification = (props: BookmarkedNotificationProps) => {
  const { notification } = props;
  const { content, title, tags, sentAt } = notification;

  const dispatch = useAppDispatch();

  const deleteBookmarkRequest = useDeleteNotificationBookmark();

  const onRemoveBookmark = () => {
    deleteBookmarkRequest.mutate(notification.id, {
      onSuccess: () => {
        dispatch(removeBookmarkedNotification({ notificationId: notification.id }));
        dispatch(createFlashMessage({ message: 'Removed from Bookmarks.' }));
      },
      onError: (err) => {
        console.error(err);
        dispatch(createFlashMessage({ variant: 'error', message: 'An error occurred while bookmarking notification.' }));
      },
    });
  };

  return (
    <Card
      sx={{
        ...hootTokens.elevation.elevation2,
        backgroundColor: hootTokens.palette.neutral[195],
        padding: '16px',
        position: 'relative',
      }}
    >
      <Box>
        <HootTypography isPII={true} variant="bodylarge">
          {title}
        </HootTypography>
        <IconButton isLoading={deleteBookmarkRequest.isLoading} sx={{ position: 'absolute', right: '4px', top: '4px' }} onClick={onRemoveBookmark}>
          <Icon name="cancel" />
        </IconButton>
        <HootTypography isPII={true} mt={2.25} variant="bodysmall">
          {content}
        </HootTypography>
        <Box sx={{ marginTop: 1, display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap' }}>
          <Tag
            label={notificationTypeLabelDictionary[notification.notificationType]}
            color={notificationTypeColorDictionary[notification.notificationType] ?? TagColor.Neutral}
          />
          {tags
            .filter((tag) => isDisplayableTag(tag))
            .map((tag) => (
              <Tag key={`nt-${tag.notificationId}-${tag.type}`} label={tag.tag} />
            ))}
        </Box>
      </Box>
      <Box mt={1}>
        <HootTypography isPII={false} variant="bodysmall">
          {formatNotificationTimestamp(sentAt)}
        </HootTypography>
      </Box>
    </Card>
  );
};

export default BookmarkedNotification;
