import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { WhiteboardSnapshotState } from '@hoot/events/interfaces/whiteboard-snapshot.state';
import { WhiteboardTemplateTypeEnum } from '@hoot/models/api/enums/whiteboard-template-type-enum';
import { RootState } from '../store';

export interface WhiteboardState {
  whiteboard: Whiteboard | null;
}

export interface Whiteboard {
  whiteboardId: string;
  templateType: WhiteboardTemplateTypeEnum;
  templateThumbnail: string;
}

const initialState: WhiteboardState = {
  whiteboard: null,
};

export const whiteboardSlice = createSlice({
  name: 'whiteboard',
  initialState,
  reducers: {
    handleSyncWhiteboard: (_, action: PayloadAction<WhiteboardSnapshotState | null>) => {
      const whiteboard = action.payload;
      return {
        whiteboard: !whiteboard
          ? null
          : {
              whiteboardId: whiteboard.whiteboardId,
              templateThumbnail: whiteboard.templateThumbnail,
              templateType: whiteboard.templateType,
            },
      };
    },
  },
});

export const { handleSyncWhiteboard } = whiteboardSlice.actions;

export const useWhiteboardState = () => {
  return useSelector((state: RootState) => state.whiteboard);
};

export default whiteboardSlice.reducer;
