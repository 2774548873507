import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { config } from '@hoot/config';
import { BookSearch } from '@hoot/events/interfaces/book-search';
import { QueryKey } from '@hoot/hooks/api/queryKeys';
import { ReadingLevelType } from '@hoot/models/api/enums';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { ShelfType } from '@hoot/models/api/enums/shelf-type-enum';
import { BookType, DecodableFormat, Grade, ResourceProgress, ResourceState, SubLibrary } from '@hoot/models/api/library';
import { GenericPaginatedResponse, PaginatableQuery } from '@hoot/models/api/pagination';

export enum LibraryOrderByEnum {
  Title = 'title',
  BookLevel = 'bookLevel',
  BookNumber = 'bookNumber',
}

export interface QueryLibraryV2 extends PaginatableQuery {
  title?: string;
  resourceStateValues?: ResourceState[];
  resourceProgressValues?: ResourceProgress[];
  resourceType?: BookType;
  excludedResourceTypes?: BookType[];
  instructionalFocusIds?: string[];
  excludedInstructionalFocusIds?: string[];
  instructionalUnitIds?: string[];
  filterLevelPrioritizedSkillIds?: string[];
  decodableFormats?: DecodableFormat[];
  hiLo?: boolean;
  nonConforming?: boolean;
  seriesId?: string;
  age?: number;
  grade?: Grade;
  genreId?: string;
  subGenreId?: string;
  topicIds?: string[];
  subTopicIds?: string[];
  themeIds?: string[];
  subThemeIds?: string[];
  formatId?: string;
  readingLevelSystem?: ReadingLevelType;
  readingLevelIds?: string[];
  shelfType: ShelfType;
  studentProfileId?: string;
  teacherProfileId?: string;
  bookCollectionIds?: string[];
  sequenceNumber?: number;
  scheduledLessonId?: string;
  orderBy?: OrderBy;
  orderColumn?: LibraryOrderByEnum;
  subLibrary?: SubLibrary;
  isInstructionalLibrary?: boolean;
  isDecodableLibrary?: boolean;
}

async function searchLibrary(query: QueryLibraryV2): Promise<GenericPaginatedResponse<BookSearch>> {
  const { data } = await axios.get<GenericPaginatedResponse<BookSearch>>(`${config.apiUrl}/library/v2`, { params: query });
  return data;
}

export default function useSearchLibrary(
  query: QueryLibraryV2,
  options?: Omit<UseQueryOptions<GenericPaginatedResponse<BookSearch>, AxiosError>, 'queryKey' | 'queryFn'>,
) {
  return useQuery([QueryKey.SearchLibraryV2, query], () => searchLibrary(query), options);
}
