import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useMemo } from 'react';
import { Unit } from '@hoot/hooks/api/assessment/useGetAssessmentUnit';
import { StepTestAnswer } from '@hoot/models/api/enums/hoot-reading-assessment';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import CheckmarkSquare from '@hoot/ui/components/v2/icons/CheckmarkSquare';
import CloseSquare from '@hoot/ui/components/v2/icons/CloseSquare';
import PlayIcon from '@hoot/ui/components/v2/icons/PlayIcon';
import { RotateDirection } from '@hoot/ui/components/v2/utils/icon-utils';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface IStepProps {
  onSelectedIndexChanged: (newIndex: number) => void;
  selectedIndex: number | undefined;
  currentIndex: number;
  currentValue: string;
  currentAnswer: StepTestAnswer;
  answers: StepTestAnswer[];
  stepLength: number;
  unit?: Unit;
}

export const Step = (props: IStepProps) => {
  const { currentValue, currentAnswer, answers, currentIndex, selectedIndex, onSelectedIndexChanged, stepLength } = props;

  const nextUnansweredQuestionIndex = useMemo(() => {
    return answers.findIndex((x) => x === StepTestAnswer.NoAnswer);
  }, [answers]);

  // You can not jump ahead and answers questions out of order. You can only go back to edit previously answered Qs.
  const isQuestionDisabled = nextUnansweredQuestionIndex >= 0 && currentIndex > nextUnansweredQuestionIndex;

  const handleLetterClick = () => () => {
    if (isQuestionDisabled) {
      return;
    }
    onSelectedIndexChanged(currentIndex);
  };

  const renderCursorIcon = () => <PlayIcon rotate={RotateDirection.Up} sx={{ height: '24px', width: '16px' }} />;

  const renderAnswerIcon = () => {
    if (currentAnswer === StepTestAnswer.Correct) {
      return <CheckmarkSquare />;
    }
    if (currentAnswer === StepTestAnswer.Wrong) {
      return <CloseSquare />;
    }
  };

  const showIcon = () => {
    // Show cursor on clicked/selected letter.
    if (currentIndex === selectedIndex) {
      // If we're on the last Step.
      if (currentIndex + 1 === stepLength) {
        if (!currentAnswer) {
          return renderCursorIcon();
        }
      }
      return renderCursorIcon();
    }
    if (currentAnswer) {
      return renderAnswerIcon();
    }
    return null;
  };

  const value = currentValue.split(':');
  const isVisible = currentIndex < stepLength;

  return (
    <Box
      onClick={handleLetterClick()}
      sx={{
        visibility: isVisible ? 'visible' : 'hidden',
        display: 'flex',
        flex: '1',
        cursor: isQuestionDisabled ? 'not-allowed' : 'pointer',
        borderRight: '1px solid #CAC4D0',
        '&:last-child': {
          borderRight: '0px',
        },
      }}
    >
      <Stack style={{ flex: 1, alignItems: 'center', justifyContent: 'space-between' }}>
        <HootTypography
          isPII={false}
          variant="bodysmall"
          sx={{
            color:
              currentAnswer === StepTestAnswer.Correct
                ? hootTokens.palette.success['60']
                : currentAnswer === StepTestAnswer.Wrong
                ? hootTokens.palette.error['80']
                : currentIndex <= nextUnansweredQuestionIndex
                ? hootTokens.palette.black
                : hootTokens.palette.neutral['140'],
          }}
        >
          {value[0]}
        </HootTypography>
        <Box width={24} height={24} display="flex" justifyContent="center">
          {showIcon()}
        </Box>
      </Stack>
    </Box>
  );
};
