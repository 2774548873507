import { SvgIcon, SvgIconProps } from '@mui/material';

const BookDoubleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4 6.25476C5.26578 6.45079 6.67778 6.77669 8 7.288M4 10.2548C4.63949 10.3538 5.3163 10.486 6 10.6585M11 4.05349V18.3026M2.99433 1.01139C5.21271 1.26207 8.19313 1.93644 10.3168 3.42457C10.725 3.71057 11.275 3.71057 11.6832 3.42457C13.8069 1.93644 16.7873 1.26207 19.0057 1.01139C20.1036 0.887331 21 1.80415 21 2.9353V14.2001C21 15.3312 20.1036 16.2484 19.0057 16.3724C16.7873 16.6231 13.8069 17.2975 11.6832 18.7856C11.275 19.0716 10.725 19.0716 10.3168 18.7856C8.19313 17.2975 5.21271 16.6231 2.99433 16.3724C1.89642 16.2484 1 15.3312 1 14.2001V2.9353C1 1.80415 1.89642 0.887331 2.99433 1.01139Z"
        stroke={props.htmlColor ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default BookDoubleIcon;
