import { useNavigate, useParams } from 'react-router-dom';
import { useCreateAssessment } from '@hoot/hooks/api/assessment/useCreateAssessment';
import { useGetStudentInProgressAssessment } from '@hoot/hooks/api/assessment/useGetStudentInProgressAssessment';
import { routesDictionary } from '@hoot/routes/routesDictionary';

const CreateAssessmentPage = () => {
  const { studentProfileId } = useParams();
  const navigate = useNavigate();

  useGetStudentInProgressAssessment(studentProfileId!, {
    retry: false,
    enabled: !!studentProfileId,
    onSuccess: (inProgressAssessmentResponse) => {
      // If there is no ongoing assessment at this time, then create a new one.
      if (!inProgressAssessmentResponse?.inProgressAssessment) {
        createAssessmentRequest.refetch();
      } else {
        // Else, redirect to the ongoing assessment.
        navigate(
          routesDictionary.myStudents.details.assessments.resume.url(studentProfileId!, inProgressAssessmentResponse.inProgressAssessment.id),
          { replace: true },
        );
      }
    },
  });

  const createAssessmentRequest = useCreateAssessment(studentProfileId!, {
    enabled: false,
    retry: false,
    onSuccess: (assessment) => {
      navigate(routesDictionary.myStudents.details.assessments.resume.url(studentProfileId!, assessment.id), { replace: true });
    },
  });

  // This page does not render anything.
  return null;
};

export default CreateAssessmentPage;
