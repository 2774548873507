import { DistrictRepresentativeEnrolmentSearchType } from '@hoot/hooks/api/district-rep/useSearchDistrictRepresentativeEnrolments';
import Page from '@hoot/ui/components/v2/core/Page';
import HFSEnrolments from '@hoot/ui/pages/v2/district-rep/enrolments/HFSEnrolments';

const DistrictRepEnrolmentsPage = (props: { searchType: DistrictRepresentativeEnrolmentSearchType }) => {
  return (
    <Page pageTitle="Hoot for Schools Enrolments | Hoot Reading">
      <HFSEnrolments searchType={props.searchType} />
    </Page>
  );
};

export default DistrictRepEnrolmentsPage;
