import { SvgIcon, SvgIconProps } from '@mui/material';

const SinglePageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5 14.0001H15M5 17.0001H10M5 11.0001H15.5M5 8.00012H15.5M5 5.50012H11M4 21.0001H16C17.6569 21.0001 19 19.657 19 18.0001V4.00012C19 2.34327 17.6569 1.00012 16 1.00012H4C2.34315 1.00012 1 2.34327 1 4.00012V18.0001C1 19.657 2.34315 21.0001 4 21.0001Z"
        stroke={props.htmlColor ?? 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default SinglePageIcon;
