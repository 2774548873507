import { LinearProgress } from '@mui/material';
import { useActiveLessonState } from '@hoot/redux/reducers/activeLessonSlice';
import { useReaders } from '@hoot/redux/reducers/readingSlice';
import Page from '@hoot/ui/components/v2/core/Page';
import StudentReaderWithControls from '@hoot/ui/pages/v2/student/lesson/library/reader/StudentReaderWithControls';
import useStudentLessonController from '@hoot/ui/pages/v2/student/lesson/useStudentLessonController';

const StudentLessonReaderPage = () => {
  const { inLesson } = useActiveLessonState();

  const { inLessonReader } = useReaders();
  const { book, pageIndex, isDoublePage, pageZoom } = inLessonReader;

  const lessonController = useStudentLessonController({ inLesson: inLesson! });

  return (
    <Page
      pageTitle="Hoot Reading | Lesson"
      RootBoxProps={{
        sx: (theme) => ({
          height: '100%',
          position: 'relative',
          p: 2,
          [theme.breakpoints.down('xl')]: {
            p: 1,
          },
        }),
      }}
      sx={{ height: '100%' }}
    >
      {!book && <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0 }} />}
      {book && (
        <StudentReaderWithControls
          book={book}
          pageIndex={pageIndex}
          pageZoom={pageZoom}
          isDoublePage={isDoublePage}
          previousPage={lessonController.previousPage}
          nextPage={lessonController.nextPage}
        />
      )}
    </Page>
  );
};

export default StudentLessonReaderPage;
