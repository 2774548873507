import Grid from '@mui/material/Grid2';
import { Box, Stack } from '@mui/system';
import { Fragment, useState } from 'react';
import { Button } from '@hoot/ui/components/v2/core/Button';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import CheckmarkSquare from '@hoot/ui/components/v2/icons/CheckmarkSquare';
import CloseFilledSquareIcon from '@hoot/ui/components/v2/icons/CloseFilledSquareIcon';
import PlayIcon from '@hoot/ui/components/v2/icons/PlayIcon';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import UnitCompleteCard from '../UnitCompleteCard';

// Move to a common PMA dir?
export enum PMAAnswer {
  Correct = 'CORRECT',
  Incorrect = 'INCORRECT',
  NoAnswer = '',
}

const answerRecord: Record<PMAAnswer, JSX.Element> = {
  [PMAAnswer.Correct]: <Icon name="checkmark_square" />,
  [PMAAnswer.Incorrect]: <Icon name="close_filled_square" />,
  [PMAAnswer.NoAnswer]: <></>,
};

interface ModuleDetails {
  fullPrompt: string;
  prompt: string;
  answer: PMAAnswer;
}

interface UNIT_2_Props {
  details: ModuleDetails[];
}

const UNIT_2 = (props: UNIT_2_Props) => {
  const { details } = props;

  const [currentPrompt, setCurrentPrompt] = useState<number>(1);
  const [answers, setAnswers] = useState<PMAAnswer[]>(details?.map((d) => d.answer));

  const _handleSubmit = () => {}; // TODO - When we have the submission endpoint set up.

  const handleCorrectClick = () => {
    setAnswers((prev) => ({
      ...prev,
      [currentPrompt - 1]: PMAAnswer.Correct,
    }));
    advanceToNextQuestion();
  };

  const handleIncorrectClick = () => {
    setAnswers((prev) => ({
      ...prev,
      [currentPrompt - 1]: PMAAnswer.Incorrect,
    }));
    advanceToNextQuestion();
  };

  const advanceToNextQuestion = () => {
    if (currentPrompt + 1 <= props.details.length) {
      setCurrentPrompt((prev) => prev + 1);
    }
  };

  // Emphasizes the current word within the full prompt.
  const formatFullPrompt = (): JSX.Element => {
    if (!details) return <></>;

    let sentence = details[currentPrompt - 1].fullPrompt;
    let shortPrompt = details[currentPrompt - 1].prompt;
    let toArray = sentence.split(shortPrompt);

    return (
      <HootTypography variant="displaysmall" textAlign="center" fontWeight={400} isPII={false}>
        {toArray.map((x, idx) => (
          <Fragment key={`${x}-${idx}`}>
            {x}
            {idx !== toArray.length - 1 && <span style={{ fontWeight: 700, textDecorationLine: 'underline' }}>{shortPrompt}</span>}
          </Fragment>
        ))}
      </HootTypography>
    );
  };

  // Combine details array w/ answers array from state. Allows easier time mapping it over in the scorecard below.
  const toKeyValPair = (details: ModuleDetails[], answers: PMAAnswer[]) => {
    return details.map(({ prompt }, idx) => [prompt, answers[idx]]);
  };

  const readyForSubmission = Object.values(answers).every((val) => !!val);

  return (
    <Grid container gap={10}>
      {/* Left - Student View/Full prompt */}
      <Grid size={'auto'}>
        <Card elevation={0} sx={{ height: 460, border: '1px solid black', borderRadius: '8px' }} contentSx={{ height: '100%', padding: 3 }}>
          <Stack justifyContent="center" alignItems="center" gap={2} height="100%">
            {readyForSubmission ? (
              <UnitCompleteCard hasBeenSubmitted={false} />
            ) : (
              <>
                <Box sx={{ backgroundColor: hootTokens.surface[2], borderRadius: '8px', padding: '16px 32px' }}>{formatFullPrompt()}</Box>
                <Box display="flex" justifyContent="center" gap={2}>
                  <Button onClick={handleIncorrectClick} variant="contained" color="error.190" startIcon={<CloseFilledSquareIcon />}>
                    Incorrect
                  </Button>
                  <Button onClick={handleCorrectClick} variant="contained" color="success.190" startIcon={<CheckmarkSquare />}>
                    Correct
                  </Button>
                </Box>
              </>
            )}
          </Stack>
        </Card>
      </Grid>

      {/* Right - Scorecard/Word List */}
      <Grid size={'auto'}>
        <HootTypography variant="tableheadingactive" isPII={false}>
          Scorecard
        </HootTypography>
        <Stack component="ol" sx={{ paddingInlineStart: '8px', marginBottom: 0 }}>
          {toKeyValPair(details, answers).map((keyVal, idx) => (
            <Stack key={`${keyVal[0]}-${idx}`} component="li" flexDirection="row" alignItems="center" gap={1}>
              {/* Current Indicator icon */}
              {currentPrompt === idx + 1 && <PlayIcon sx={{ width: '14px', height: '16px' }} />}

              {/* Prompt */}
              <Button
                onClick={() => setCurrentPrompt(idx + 1)}
                sx={{ display: 'flex', flexDirection: 'row', minHeight: '42px', alignItems: 'center', gap: '4px', padding: '8px 16px' }}
              >
                <HootTypography variant="bodylarge" isPII={false}>{`${idx + 1}. ${keyVal[0]}`}</HootTypography>
              </Button>

              {/* Correct/Incorrect icon */}
              {answerRecord[keyVal[1] as PMAAnswer]}
            </Stack>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default UNIT_2;
