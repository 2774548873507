import { ScheduledLessonType } from '@hoot-reading/hoot-core/dist/enums/scheduled-lesson';
import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { featureFlags } from '@hoot/constants/featureFlags';
import useSearchStudentTeacherPMAs, { PublicSearchStudentTeacherPMAsRequest } from '@hoot/hooks/api/pma/useSearchStudentTeacherPMAs';
import { ProgressMonitoringAssessmentStatus } from '@hoot/models/api/enums/pma-enums';
import { RootState } from '@hoot/redux/store';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';
import { Button } from '../../core/Button';
import HootTypography from '../../core/HootTypography';
import { Icon } from '../../core/Icon';
import PMANotificationDialog from '../../dialogs/PMANotificationDialog';

const LessonNotification = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const isAssessmentsV2Enabled = isFeatureEnabled(featureFlags.SC_11213_AssessmentSuite_v2);
  const inLesson = useSelector((state: RootState) => state.activeLesson.inLesson);
  const [notificationCount, setNotificationCount] = useState<number>(0);

  // === PMA Notification Logic Start ===
  const [isPMANotificationModalOpen, setIsPMANotificationModalOpen] = useState<boolean>(false);
  const isLessonPMAType = inLesson?.type === ScheduledLessonType.ProgressMonitoring;

  const searchStudentTeacherPMAsQuery: PublicSearchStudentTeacherPMAsRequest = {
    studentProfileId: inLesson?.studentId ?? '',
    teacherId: inLesson?.teacherId ?? '',
  };

  const { data: studentTeacherPMAs } = useSearchStudentTeacherPMAs(searchStudentTeacherPMAsQuery, {
    enabled: !!inLesson && isLessonPMAType,
  });

  const inProgressPMA = studentTeacherPMAs?.progressMonitoringAssessments.find((pma) => pma.status === ProgressMonitoringAssessmentStatus.InProgress);
  const completedTodayPMA = studentTeacherPMAs?.progressMonitoringAssessments.find(
    (pma) =>
      pma.status === ProgressMonitoringAssessmentStatus.Completed && DateTime.now().toISODate() === DateTime.fromMillis(pma.updatedAt).toISODate(),
  );

  const shouldNotifyForPMA = !!inLesson && isLessonPMAType && (!!inProgressPMA || !completedTodayPMA);

  useEffect(() => {
    if (shouldNotifyForPMA) {
      setNotificationCount((prev) => prev + 1);
    }
  }, [shouldNotifyForPMA]);

  // === PMA Notification Logic End ===

  if (!isAssessmentsV2Enabled || notificationCount === 0) {
    return null;
  }

  const onViewClick = () => {
    setIsPMANotificationModalOpen(true);
  };

  return (
    <>
      <Stack
        sx={{
          backgroundColor: '#FFF',
          borderRadius: '8px',
          boxShadow: hootTokens.elevation.elevation2,
          padding: '24px',
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Stack gap={1} sx={{ width: '100%' }}>
          <Stack direction={'row'} justifyContent={'center'} gap={1}>
            <Icon name="bell_notification" />
            <HootTypography isPII={false} variant="titlesmall" textAlign="center">
              {`${notificationCount} Notification(s)`}
            </HootTypography>
          </Stack>
          <Button
            onClick={onViewClick}
            variant="contained"
            color="warning.140"
            fullWidth
            sx={{
              px: '16px',
              py: '8px',
            }}
          >
            View
          </Button>
        </Stack>
      </Stack>

      <PMANotificationDialog isOpen={isPMANotificationModalOpen} setIsOpen={setIsPMANotificationModalOpen} isResumingAssessment={!!inProgressPMA} />
    </>
  );
};

export default LessonNotification;
